// mixins
.centered {
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
}

.center-block {
  .start-page-header,
  .pull-left {
    text-align: left !important;
  }
}

.transition {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.transition-long {
  -webkit-transition: ease-out 1s;
  -moz-transition: ease-out 1s;
  -o-transition: ease-out 1s;
  transition: ease-out 1s;
}

.no-transition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.box-shadow-light {
  //box-shadow: 0 1px 4px @tables-box-shadow !important;
  box-shadow: none !important;
}

.box-shadow-dark {
  // box-shadow: 0 1px 4px @tables-box-shadow !important;
  box-shadow: none !important;
}

.box-shadow-heavy {
  //box-shadow: 0 0 1px @tables-box-shadow-light, 0 1px 1px 0 @tables-box-shadow-light;
  box-shadow: 0px 0px 12px @fixed-btn-shadow !important;
}

.box-shadow-medium {
  box-shadow: 0 1px 4px @tables-box-shadow !important;
}

.box-shadow-very-heavy {
  //box-shadow: 0 0 1px @tables-box-shadow-light, 0 1px 1px 0 @tables-box-shadow-light;
  box-shadow: 0px 0px 40px @fixed-btn-shadow !important;
}

.box-shadow-none {
  box-shadow: none !important;
  .ul-inline-row,
  .analytics-container,
  .table-container-wrapper,
  .well {
    box-shadow: none !important;
  }
}
.border-radius-25 {
  border-radius: 0.25em;
}

.border-radius-55 {
  border-radius: 0.55em;
}

.rotate {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rotate-15 {
  transform: rotate(15deg);
  -webkit-transform: rotate(15deg);
  -moz-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -o-transform: rotate(15deg);
}

.centered-text {
  text-align: center !important;
}
.nowrap {
  white-space: nowrap !important;
}
.yeswrap {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.line-throught {
  text-decoration: line-through;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hidden-element {
  display: none !important;
}
.visible {
  display: block !important;
}
.visible-inline {
  display: inline-block !important;
}
.visible-table {
  display: table !important;
}
.visible-flex {
  display: inline-flex !important;
}
.hidden-el {
  visibility: hidden !important;
}
.shown-el {
  visibility: visible !important;
}

.chart-template {
  .centered;
  width: 75% !important;
  display: none;
}
.filter-template {
  border-radius: 2px;
  border: 0;
  font-size: @font-size;
  .btn {
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
  }
  .btn-primary {
    background: transparent !important;
    border-bottom: 2px solid @label-info-color-border !important;
  }
}
.modal {
  overflow-y: hidden;
}
.modal-template {
  .modal-dialog {
    width: 100% !important;
  }
  .modal-header {
    border-bottom: 0 !important;
    .btn-fixed {
      box-shadow: none !important;
    }
  }
  .modal-body {
    background: @modal-background !important;
    .start-page-header {
      font-size: calc(@font-size * 1.6);
    }
    .details-wrapper {
      margin-top: 25px;
    }
  }
  .modal-content {
    background: @modal-header-background !important;
    color: @modal-content-color;
    border-radius: 0.25em;
    border: 0 !important;
    .btn-fixed {
      border-width: 1px !important;
    }
    .alert-info {
      .box-shadow-medium;
      padding: 10px 30px !important;
      border-radius: 14px;
      margin-top: 10px;
      margin-bottom: 10px;
      // position: fixed;
    }
  }
  .filter-template {
    background: @navbar-background !important;
    margin-top: 15px;
    .btn-primary,
    .btn-primary:hover,
    .btn-primary:focus {
      background: @modal-background !important;
      border-color: transparent !important;
      border-top-color: transparent !important;
      border-radius: 0 !important;
    }
  }
}

.modal-template-half-width {
  .modal-dialog {
    width: 50% !important;
    float: right;
  }
}
.modal-template-third-width {
  .modal-dialog {
    width: 35% !important;
    float: right;
  }
}
.modal-template-three-thirds-width {
  .modal-dialog {
    width: 75% !important;
    float: right;
  }
}
.modal-footer {
  border-top: 0;
  margin-top: 0;
  // background: @navbar-background !important;
  // width: 100%;
  // position: fixed;
}

.modal-dialog {
  width: 100%;
  padding: 0;
  margin: 0;
}

.contract_details {
  font-size: calc(@font-size / 1.5) !important;
}
.updated_time {
  font-size: @font-size !important;
}

.contract_executed {
  color: @contract-executed-color;
}
// .contract_processing {
//     color: @contract-processing-color!important;
// }
.contract_cancelled {
  color: @contract-cancelled-color!important;
  text-decoration: line-through !important;
}
// .contract_new >td, .vessel_new > td {
//     color: @contract-new-color!important;
// }

// .contract_executed > td, .vessel_loaded > td {
//     color: @contract-executed-color!important;font-weight:normal;
// }

// .contract_processing > td, .vessel_shipment > td {
//     color: @contract-processing-color!important;
// }

// .contract_cancelled > td, .vessel_washout > td {
//     color: @contract-cancelled-color!important;
//     text-decoration: line-through;
//     font-weight:normal;
// }

.contract_executed > td:first-child,
.vessel_loaded > td:first-child {
  text-align: left;
  color: @contract-executed-color!important;
}

.contract_cancelled > td:first-child,
.vessel_washout > td:first-child {
  color: @contract-cancelled-color!important;
  text-align: left;
}

.lower-zero-number {
  color: @lower-zero-color!important;
}
.higher-zero-number {
  color: @higher-zero-color!important;
}

.text-stroke {
  -webkit-text-stroke: 0.2px @stroke-color;
}
.no-text-stroke {
  -webkit-text-stroke: 0;
}

//tables
.main-table-template {
  border-collapse: separate;
  // .table-strip;
  th {
    font-size: calc(@font-size / 1.2) !important;
    text-align: center !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
    cursor: pointer;
    border-top: 0 !important;
    height: 50px !important;
    word-break: normal;
    letter-spacing: @letter-spacing-huge;
  }
  th:hover {
    background: @color-black-opacity;
  }
  tr td {
    // text-align: center !important;
    font-size: calc(@font-size / 1.2) !important;
    padding: 4px !important;
    vertical-align: middle !important;
    // text-shadow: @text-shadow-font;
    line-height: 1 !important;
    .text-stroke;
    .btn-link {
      font-family: @font-family-regular !important;
    }
    // span,
    // .label {
    //   vertical-align: middle !important;
    // }
  }
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  tr td:nth-child(1) {
    vertical-align: middle;
    span {
      font-family: @font-family-semibold !important;
    }
  }

  tr td:nth-child(1) {
    .smaller-label span {
      font-size: calc(@font-size / 1.5) !important;
      padding: 0;
      margin: 0;
    }
  }
  .label {
    /*display: inline-block !important;*/
    &::first-letter {
      text-transform: uppercase !important;
    }
    display: inline-block;
    font-size: calc(@font-size / 1.6) !important;
    overflow-x: hidden;
    white-space: normal;
    margin-bottom: 0 !important;
    padding: 4px !important;
    margin: 0 auto;
    vertical-align: middle !important;
    .fa {
      margin-right: 3px;
    }
  }
  .label-opacity {
    font-size: @font-size / 1.8 !important;
  }
  .badge {
    padding: 4px !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
  }
  a {
    cursor: pointer;
  }
  .btn-link {
    text-transform: initial !important;
    text-decoration: none;
    line-height: 1;
  }
  .item-settings-dropdown {
    li > a {
      line-height: 5px;
    }
  }
}

.wrap-text {
  white-space: normal !important;
  word-wrap: break-word;
}

table.table-right-align {
  tbody {
    tr > td {
      text-align: right !important;
    }
    tr > td:first-child {
      text-align: left !important;
    }
  }
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-6 {
  border-radius: 6px !important;
}
