@font-face {
  font-family: 'FontAwesome';
  src: url('fonts/fontawesome-webfont.eot');
  src:
    url('fonts/fontawesome-webfont.eot') format('embedded-opentype'),
    url('fonts/fontawesome-webfont.woff') format('woff'),
    url('fonts/fontawesome-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-family-light:
  'Light',
  sans-serif !important;
@font-family-regular:
  'Regular',
  sans-serif !important;
@font-family-semibold:
  'Semibold',
  sans-serif !important;
@font-family-bold:
  'Bold',
  sans-serif !important;
@font-family-extrabold:
  'Extrabold',
  sans-serif !important;
@font-family-black:
  'Black',
  sans-serif !important;

@letter-spacing-huge: var(--letter-spacing-huge, 0.04em);
@letter-spacing-normal: var(--letter-spacing-normal, 0.02em);
@letter-spacing-mega: var(--letter-spacing-mega, 0.05em);
@letter-spacing-very-huge: var(--letter-spacing-very-huge, 0.07em);

//general styles
html,
body {
  overflow-x: hidden !important;
  height: 100%;
}

body {
  background: @main-background;
  font-size: @font-size;
  font-family: @font-family-regular;
  line-height: 1;
  margin: 0 !important;
  width: 100% !important;
  overflow-x: hidden;
  padding-bottom: 200px;
}

body.modal-open {
  overflow: hidden;
}

h1,
h2,
h5,
h3,
h4,
h6 {
  color: @headers-color;
  .no-text-stroke;
  font-family: @font-family-bold;
  letter-spacing: @letter-spacing-huge;

  small {
    font-family: @font-family-regular;
  }
}

h1,
h2 {
  font-family: @font-family-bold;
}

textarea {
  resize: none;
}

label {
  font-weight: 400 !important;
  font-size: calc(@small-font / 1.1);
  font-family: @font-family-regular !important;
  color: @label-color;
  .no-text-stroke;
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: @letter-spacing-huge;
}

a {
  color: @links-color;
  cursor: pointer !important;
  text-decoration: none !important;

  :hover {
    text-decoration: none !important;
  }
}

img,
canvas,
.fa,
br {
  .noselect;
  .no-text-stroke;
}

.user-avatar {
  display: inline;
  font-size: @font-size;
  border-radius: 3px;

  img {
    height: 15px;
    width: 15px;
    .border-radius-4;
  }
}

.gt-table__visiblehovering {
  overflow-x: inherit !important;
  overflow-y: inherit !important;

  .sticky-table-first-td,
  .sticky-table-first-td-condensed,
  .sticky-table-first-td-ultra-condensed,
  .sticky-table-first-td-expanded,
  .sticky-table-first-td-expanded-position {
    tfoot > tr > td:first-child,
    tbody > tr > td:first-child,
    thead > tr > th:first-child {
      z-index: initial !important;
    }
  }
}

.gt-table__hovering {
  padding-bottom: 300px !important;

  .tooltip {
    position: inherit !important;
  }

  .sticky-table-first-td,
  .sticky-table-first-td-condensed,
  .sticky-table-first-td-ultra-condensed,
  .sticky-table-first-td-expanded,
  .sticky-table-first-td-expanded-position {
    tfoot > tr > td:first-child,
    tbody > tr > td:first-child,
    thead > tr > th:first-child {
      .item-settings-dropdown .dropdown-menu {
        left: calc(100% + 1px) !important;
      }
    }
  }

  .counterparty_detail_table tbody > tr > td:first-child {
    background: inherit !important;
  }
}

.gt-table__nohovering {
  .gt-table__hovering {
    padding-bottom: 0 !important;
    overflow-x: auto !important;
    overflow-y: visible !important;
  }
}

.no-sticky-table {
  .sticky-table-first-td,
  .sticky-table-first-td-condensed,
  .sticky-table-first-td-ultra-condensed,
  .sticky-table-first-td-expanded,
  .sticky-table-first-td-expanded-position {
    margin-left: 0 !important;

    tr > td {
      height: auto !important;
    }

    tfoot > tr > td:first-child,
    tbody > tr > td:first-child,
    thead > tr > th:first-child {
      z-index: inherit !important;
      width: auto !important;
      left: 0;
      position: relative !important;
    }
  }
}

.no-user-avatar {
  display: inline-block;
  height: 15px;
  width: 15px;
  line-height: 1.1;
  border-radius: 3px;
  background: @dark-blue;
  color: @white;
  font-size: @font-size;
  font-family: @font-family-bold !important;
  text-transform: uppercase !important;
  text-align: center !important;
}

.big-user-avatar {
  .user-avatar;
  display: inline;

  img {
    height: 100px;
    width: 100px;
  }
}

.big-no-user-avatar {
  .no-user-avatar;
  display: inline-block;
  height: 100px;
  width: 100px;
  font-size: calc(@font-size * 5);
  line-height: 1.3 !important;
}

::selection {
  background: darken(@highlighted-area, 23%);
}

::-moz-selection {
  background: darken(@highlighted-area, 23%);
}

.initial-transform {
  text-transform: initial !important;
}

.center-block {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
}

.logo {
  width: 70px;
  height: 35px;
  position: absolute;
  left: 2px;
  top: 2px;
  margin-left: 2px;
  border: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logo-login {
  margin: auto;
  width: 200px;
  height: 150px;
  display: block;
}

.start-logo-login {
  height: 100px;
  background-size: 65% !important;
}

.cabinets-login {
  .cabinets-header {
    color: @white;
    font-size: 2em;
    font-family: @font-family-light;
  }

  .logo-login {
    width: 150px;
    height: 100px;
    display: inline-block;
    vertical-align: bottom;
  }
}

/* WebKit Scrollbar */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.custom-scroll-table .overflow-x::-webkit-scrollbar,
.custom-scroll-table .ul-inline-row::-webkit-scrollbar,
.custom-scroll-table .table-container-wrapper::-webkit-scrollbar {
  height: 13px;
}

/* Track */

::-webkit-scrollbar-thumb {
  background: @navbar-background !important;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}

.small-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0 !important;
}

.main_alert {
  position: relative;
  display: block;
  top: 40px;
  z-index: 9998;
  padding: 2px;
  padding-left: 12px;
  padding-right: 12px;
  right: 0;
  left: 0;
  margin-bottom: 0 !important;

  p {
    margin-left: 50px;
  }

  .btn {
    padding: 6px 12px !important;
  }
}

.alert-success {
  background: @status-success-background !important;
  color: @label-success-color !important;

  .filter-template {
    background: @status-success-background !important;
    border-right: 0 !important;

    .btn {
      border-right: 0 !important;
    }

    .btn-primary {
      background: @btn-success-color !important;
      color: @tables-background !important;
    }
  }

  .form-control {
    border-color: @btn-success-color !important;
    background: @status-success-background !important;
    margin-bottom: 4px;
  }

  .input-group-addon {
    background: @btn-success-color !important;
    color: @tables-background !important;
  }

  td.edit,
  .edit {
    background: @status-success-background !important;
    color: @label-success-color !important;
  }

  .label-strong {
    color: @label-success-color;
  }

  .ui-select-container .form-control {
    border-color: @btn-success-color !important;
    background: @status-success-background !important;
  }

  h1,
  h2,
  h5,
  h3,
  h4,
  h6,
  small {
    margin-top: 0;
    margin-bottom: 0;
    color: @label-success-color !important;
  }
}

.alert-success.label-strong {
  color: @label-success-color !important;
}

.alert-contrast {
  background: @headers-color !important;
  color: @tables-background !important;

  h1,
  h2,
  h5,
  h3,
  h4,
  h6,
  span,
  a,
  li,
  small {
    margin-top: 0;
    margin-bottom: 0;
    color: @tables-background !important;
  }

  li {
    font-family: @font-family-bold !important;
  }
}

.alert-default {
  background: @highlighted-area !important;

  .label-strong {
    color: @contract-executed-color;
  }

  h1,
  h2,
  h5,
  h3,
  h4,
  h6,
  small {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.alert-warning {
  color: @alert-warning-color !important;
  background: @alert-warning-background !important;

  h1,
  h2,
  h5,
  h3,
  h4,
  h6,
  small {
    color: @alert-warning-color !important;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.alert-filter {
  top: 15px;
  left: 0px;
  z-index: 9999 !important;
  overflow-x: auto;
  padding: 6px;
  max-width: 450px;

  label {
    margin-left: 0;
  }

  .main-table {
    tr td:last-child {
      text-align: left;
    }

    tr td {
      padding: 2px;
      border: 0 !important;
    }
  }
}

.navbar-brand {
  padding: 0;
  margin-top: 3px;
  margin-left: 10px;
}

.clients-logo {
  img {
    max-height: 33px;
    display: inline;
  }
}

.clients-logo-start {
  display: block;

  img {
    max-height: 150px;
    margin: 20px;
  }
}

.client-name-header {
  margin: 0;
  margin-left: 2px;
  display: inline;
  padding: 0;
  font-size: @font-size !important;

  .label {
    color: @contract-executed-color !important;
    font-family: @font-family-bold !important;
  }
}

.client-name-header-dark {
  .label {
    color: @tables-background !important;
    font-size: calc(@font-size / 1.3) !important;
  }
}

/*LEFT NAVBAR*/

.navbar-fixed-left {
  width: auto;
  position: fixed;
  border-radius: 0;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.navbar-fixed-left:hover {
  overflow-y: auto;
}

.navbar-fixed-left .logo {
  width: 40px;
}

.navbar-fixed-left .navbar-collapse {
  padding-right: 0 !important;
}

.navbar-fixed-left .navbar-nav {
  width: 45px;
  transition: all 0.5s ease-in-out;
  position: relative;
  margin-left: 0 !important;
}

.navbar-fixed-left .navbar-brand {
  padding: 0 !important;
}

.navbar-fixed-left .navbar-nav:hover {
  width: 200px;
  position: relative;
  transition: all 0.5s ease-in-out;

  li {
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
    border-left: 10px solid !important;
  }
}

.navbar-fixed-left .navbar-nav:not(:hover) {
  transition: all 0.5s ease-in-out;
  position: relative;
}

.navbar-fixed-left .navbar-nav > li {
  float: none;
  width: 100% !important;
  clear: both;
  display: block;
  height: 40px !important;
  overflow-x: hidden;
  overflow-y: hidden;
  border-left: 6px solid;
  font-family: @font-family-bold !important;
}

.navbar-fixed-left:hover .navbar-nav > li {
  border-bottom: 1px solid darken(@navbar-background, 9%) !important;
  border-top: 1px solid darken(@navbar-background, 9%) !important;
}

.navbar-fixed-left .navbar-nav > li:hover {
  border-left: 20px solid !important;

  a {
    background: @navbar-background * 1.1 !important;
  }
}

/* colorization of navbar */

.navbar-fixed-left .navbar-nav > li.info_tab {
  border-left-color: @info-tab !important;
}

.navbar-fixed-left .navbar-nav > li.prices_tab {
  border-left-color: @prices-tab !important;
}

.navbar-fixed-left .navbar-nav > li.logistics_tab {
  border-left-color: @logistics-tab !important;
}

.navbar-fixed-left .navbar-nav > li.clients_tab {
  border-left-color: @clients-tab !important;
}

.navbar-fixed-left .navbar-nav > li.payments_tab {
  border-left-color: @payments-tab !important;
}

.navbar-fixed-left .navbar-nav > li.pl_tab {
  border-left-color: @pl-tab !important;
}

.navbar-fixed-left .navbar-nav > li.crm_tab {
  border-left-color: @crm-tab !important;
}

.navbar-fixed-left .navbar-nav > li.info_tab.active a {
  background: @info-tab !important;
}

.navbar-fixed-left .navbar-nav > li.prices_tab.active a {
  background: @prices-tab !important;
}

.navbar-fixed-left .navbar-nav > li.logistics_tab.active a {
  background: @logistics-tab !important;
}

.navbar-fixed-left .navbar-nav > li.clients_tab.active a {
  background: @clients-tab !important;
}

.navbar-fixed-left .navbar-nav > li.payments_tab.active a {
  background: @payments-tab !important;
}

.navbar-fixed-left .navbar-nav > li.pl_tab.active a {
  background: @pl-tab !important;
}

.navbar-fixed-left .navbar-nav > li.crm_tab.active a {
  background: @crm-tab !important;
}

.navbar-fixed-left .navbar-nav > li > a {
  height: 100% !important;
  width: 100% !important;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 13px !important;
}

.navbar-fixed-left .navbar-nav > li.active > a {
  border-color: @navbar-hover-background !important;
}

.navbar-fixed-left .navbar-nav > li.active > a > span {
  display: none !important;
}

.navbar-fixed-left .navbar-nav:hover > li.active > a > span {
  display: inline-block !important;
}

.navbar-fixed-left .navbar-nav:hover > li > a {
  text-align: left;
}

.navbar-fixed-left .navbar-nav > li > a > .fa {
  vertical-align: top !important;
}

.navbar-fixed-left .navbar-nav > li > a > span {
  visibility: visible !important;
  display: inline;
  margin-left: 25px;
  font-size: calc(@font-size / 1.3) !important;
  bottom: 0px !important;
}

.navbar-fixed-left .navbar-nav:hover > li > a > span {
  right: 0;
  margin-left: 10px;
  bottom: 3px !important;
}

.navbar-fixed-left .navbar-nav > li:hover > a > span {
  margin-left: 10px;
  font-size: calc(@font-size / 1.3) !important;
}

.navbar-fixed-left + .container {
  padding-left: 160px;
}

.if-navbar-left .central-container {
  padding-top: 0 !important;
  padding-right: 15px;
  padding-left: 60px;
}

.if-navbar-top .central-container {
  padding-top: 35px !important;
  padding-right: 35px;
  padding-left: 35px;
}

.if-navbar-top .central-container.gt-detail-page {
  padding-top: 35px !important;
  padding-right: 15px;
  padding-left: 15px;
}

.if-navbar-left .left-sidebar {
  padding-left: 50px;
  padding-top: 45px;
}

.if-navbar-top .left-sidebar {
  padding-left: 10px;
  padding-top: 45px;
}

.if-navbar-left .contract-page {
  padding-left: 10px !important;
}

.if-navbar-top .contract-page {
  padding-left: 10px !important;
}

/* On using dropdown menu (To right shift popuped) */

.navbar-fixed-left .navbar-nav > li > .dropdown-menu {
  margin-top: -50px;
  margin-left: 140px;
}

.navbar-fixed-left .navbar-header {
  float: none;
  clear: both;
}

.navbar-header {
  width: 100%;
}

.navbar-top-grouped {
  .open > a,
  .open > a:focus,
  .open > a:hover,
  li > a:focus,
  li > a:hover,
  li > a:active,
  li > a:visited {
    background-color: transparent !important;
  }
}

.navbar-user-dark {
  .label,
  div,
  .fa {
    color: @tables-background !important;
  }
}

.if-navbar-left .header-margin-top {
  margin-top: 0 !important;
}

.navbar.navbar-default.header.navbar-fixed-left {
  border: 0 !important;

  .no-user-avatar,
  .user-avatar {
    height: 35px;
    width: 35px;
    line-height: 2.5;
    display: block;
    float: none;
    margin: 0 auto;
  }

  .logo {
    width: 35px;
  }
}

.navbar-user-dark > .open > a,
.navbar-user-dark > .open > a:focus,
.navbar-user-dark > .open > a:hover,
.navbar-user-dark > li > a:focus,
.navbar-user-dark > li > a:hover {
  background-color: transparent !important;
}

.if-navbar-left.navbar-user {
  background: @main-background !important;
  display: none;

  li > a {
    color: @btn-default-color !important;
  }
}

.navbar-default .navbar-nav > li > a {
  color: @navbar-default-color;
  width: 75px;
  overflow: hidden;
  height: 55px;
  font-size: 14px;
  text-align: center;

  span {
    clear: both;
    visibility: hidden;
    position: absolute;
    bottom: -299px;
  }
}

.navbar-default .navbar-nav > li:hover,
.navbar-default .navbar-nav > li.active a,
.navbar-default .navbar-nav > li > a:focus {
  width: auto;
  height: 55px;
  font-size: 14px;

  span {
    visibility: visible;
    position: relative;
    bottom: 0px;
  }
}

.navbar-default .navbar-nav > li.active a,
.navbar-default .navbar-nav > li > a:focus {
  color: @main-background-color !important;
}

.navbar-default .navbar-nav:hover li {
  span {
    visibility: visible;
    position: relative;
    bottom: 0px;
  }
}

.dropdown-menu li a:hover {
  background: @highlighted-area !important;
}

.ui-select-match,
.ui-select-container {
  outline: 0 !important;
  box-shadow: none !important;
  background: transparent !important;

  &::-webkit-input-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }

  &::-moz-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }

  &:-moz-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }

  &:-ms-input-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }

  .form-control {
    color: @select-color;
    margin-top: 0px;
    border: 0;
    outline: 0;
    cursor: pointer;
    box-shadow: none !important;
    border-radius: 4px !important;
    background: darken(@borders-color, 17%) !important;
  }
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  font-family: @font-family-regular;
  color: @placeholder-color;
  font-size: @font-size;
  height: auto;

  &::-webkit-input-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }

  &::-moz-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }

  &:-moz-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }

  &:-ms-input-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }
}

.ui-select-bootstrap .ui-select-choices-row > span {
  line-height: 1;
  white-space: normal;
  padding: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 1px solid darken(@borders-color, 17%) !important;
  font-family: @font-family-regular !important;
  .no-text-stroke;
}

.ui-select-bootstrap .ui-select-choices-row > a {
  text-align: left !important;
  font-size: @font-size !important;
}

.ui-select-bootstrap .ui-select-choices-row.active > a {
  background: darken(@highlighted-area, 5%) !important;
  color: @white !important;
}

input.form-control.ui-select-search::before {
  font-family: 'FontAwesome';
  content: '&#128269;';
  font-size: calc(@font-size / 1.3);
}

.navbar-right {
  li > a {
    font-size: calc(@font-size * 1.1) !important;
    width: auto !important;
    padding-bottom: 5px;
  }
}

/*GROUPED NAVBAR*/

.grouped-navbar {
  .collapse-button {
    border-left: 0 !important;

    a {
      border: 0 !important;
    }
  }
}

.grouped-navbar:hover {
  .navbar-nav > li {
    border-top: 0 !important;
  }
}

.grouped-navbar li.info_tab.half-active a {
  border-left: 8px solid @info-tab !important;
  color: @info-tab !important;
}

.grouped-navbar li.info_tab.half-active.active a {
  color: @white !important;
}

.grouped-navbar li.info_tab.inside-navbar {
  border-left: 3px solid @info-tab !important;
}

.grouped-navbar li.prices_tab.half-active a {
  border-left: 8px solid @prices-tab !important;
  color: @prices-tab !important;
}

.grouped-navbar li.prices_tab.inside-navbar {
  border-left: 3px solid @prices-tab !important;
}

.grouped-navbar li.logistics_tab.half-active a {
  border-left: 8px solid @logistics-tab !important;
  color: @logistics-tab !important;
}

.grouped-navbar li.logistics_tab.inside-navbar {
  border-left: 3px solid @logistics-tab !important;
}

.grouped-navbar li.clients_tab.half-active a {
  border-left: 8px solid @clients-tab !important;
  color: @clients-tab !important;
}

.grouped-navbar li.clients_tab.inside-navbar {
  border-left: 3px solid @clients-tab !important;
}

.grouped-navbar li.payments_tab.half-active a {
  border-left: 8px solid @payments-tab !important;
  color: @payments-tab !important;
}

.grouped-navbar li.payments_tab.inside-navbar {
  border-left: 3px solid @payments-tab !important;
}

.grouped-navbar li.pl_tab.half-active a {
  border-left: 8px solid @pl-tab !important;
  color: @pl-tab !important;
}

.grouped-navbar li.pl_tab.inside-navbar {
  border-left: 3px solid @pl-tab !important;
}

.grouped-navbar li.crm_tab.half-active a {
  border-left: 8px solid @crm-tab !important;
  color: @crm-tab !important;
}

.grouped-navbar li.crm_tab.inside-navbar {
  border-left: 3px solid @crm-tab !important;
}

.navbar-user .navbar-top-grouped {
  float: left;
  margin-left: 10px !important;
  right: initial !important;

  li.inside-navbar {
    border-right: 0 !important;
  }

  li > a {
    color: @tables-background;
  }

  li:last-child {
    border-right: 0 !important;
  }

  li:nth-last-child(2) {
    border-right: 0 !important;
  }

  li.inside-navbar > a {
    color: @navbar-font-color;
  }

  li > a span {
    position: relative !important;
    display: inline-block;
    visibility: visible !important;
    bottom: 0 !important;
    font-family: @font-family-regular !important;
    font-size: @font-size !important;
  }

  li.info_tab.half-active a {
    border-bottom: 4px solid @info-tab !important;
  }

  li.prices_tab.half-active a {
    border-bottom: 4px solid @prices-tab !important;
  }

  li.logistics_tab.half-active a {
    border-bottom: 4px solid @logistics-tab !important;
  }

  li.pl_tab.half-active a {
    border-bottom: 4px solid @pl-tab !important;
  }

  li.crm_tab.half-active a {
    border-bottom: 4px solid @crm-tab !important;
  }

  li.clients_tab.half-active a {
    border-bottom: 4px solid @clients-tab !important;
  }

  li.payments_tab.half-active a {
    border-bottom: 4px solid @payments-tab !important;
  }

  li.info_tab.half-active.active > a {
    background: @info-tab !important;
  }

  li.prices_tab.half-active.active > a {
    background: @prices-tab !important;
  }

  li.logistics_tab.half-active.active > a {
    background: @logistics-tab !important;
  }

  li.pl_tab.half-active.active > a {
    background: @pl-tab !important;
  }

  li.crm_tab.half-active.active > a {
    background: @crm-tab !important;
  }

  li.clients_tab.half-active.active > a {
    background: @clients-tab !important;
  }

  li.payments_tab.half-active.active > a {
    background: @payments-tab !important;
  }

  li.info_tab.inside-navbar a {
    border-left: 6px solid @info-tab !important;
  }

  li.prices_tab.inside-navbar a {
    border-left: 6px solid @prices-tab !important;
  }

  li.logistics_tab.inside-navbar a {
    border-left: 6px solid @logistics-tab !important;
  }

  li.pl_tab.inside-navbar a {
    border-left: 6px solid @pl-tab !important;
  }

  li.crm_tab.inside-navbar a {
    border-left: 6px solid @crm-tab !important;
  }

  li.clients_tab.inside-navbar a {
    border-left: 6px solid @clients-tab !important;
  }

  li.payments_tab.inside-navbar a {
    border-left: 6px solid @payments-tab !important;
  }

  li.inside-navbar:hover a {
    border-left-width: 10px !important;
    background: transparent !important;
  }

  li.half-active.active {
    li.inside-navbar a {
      color: @navbar-font-color-active !important;
    }
  }

  li.info_tab.inside-navbar.active > a {
    color: @white !important;
    background: @info-tab !important;
  }

  li.prices_tab.inside-navbar.active > a {
    color: @white !important;
    background: @prices-tab !important;
  }

  li.logistics_tab.inside-navbar.active > a {
    color: @white !important;
    background: @logistics-tab !important;
  }

  li.pl_tab.inside-navbar.active > a {
    color: @white !important;
    background: @pl-tab !important;
  }

  li.crm_tab.inside-navbar.active > a {
    color: @white !important;
    background: @crm-tab !important;
  }

  li.clients_tab.inside-navbar.active > a {
    color: @white !important;
    background: @clients-tab !important;
  }

  li.payments_tab.inside-navbar.active > a {
    color: @white !important;
    background: @payments-tab !important;
  }

  li.half-active:hover a {
    border-bottom-width: 6px !important;
    .transition;
  }

  li.half-active li.inside-navbar a {
    border-bottom: 0 !important;
    border-right: 0 !important;
  }
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  color: @tables-background;
}

/*TOP USER NAVBAR*/

.navbar-user {
  top: 0 !important;
  margin-top: 0 !important;
  min-height: 40px;
  border-bottom: 0;

  .logo {
    width: 40px;
    top: 0 !important;
  }

  .user-dropdown {
    position: relative;
    float: right;
    font-family: @font-family-semibold;

    .dropdown-menu {
      right: 0;
      left: inherit;
    }
  }

  .no-user-avatar,
  .user-avatar,
  .user-avatar img {
    height: 20px;
    width: 20px;
    line-height: 1.8;
  }

  li {
    border: 0 !important;
    height: 40px !important;
    min-width: 40px !important;
    display: block;
  }

  li > a {
    font-size: @font-size !important;
    width: auto !important;
    padding: 0;
    border: 0 !important;
    height: 40px !important;
    min-width: 40px !important;
    border-radius: 0 !important;
    line-height: 3;
    padding-left: 10px !important;
    padding-right: 10px !important;
    color: @btn-default-color;

    .fa {
      font-size: calc(@font-size / 1.3) !important;
      line-height: 2;
    }
  }

  .user-dropdown-li {
    .fa {
      font-size: @font-size !important;
      line-height: 2.5;
    }

    input[type='checkbox'],
    input[type='radio'] {
      height: 20px !important;
      width: 20px !important;
      margin-right: 5px;
    }
  }

  .dropdown-menu {
    min-width: 260px;

    li {
      height: 25px !important;
    }

    li > a {
      font-size: @font-size !important;
      height: 25px !important;
      line-height: 1.7;

      .fa {
        line-height: 1;
      }
    }
  }

  .pagination {
    li {
      display: inline !important;
    }
  }

  .table-template {
    td:first-child {
      color: @btn-default-color !important;
    }
  }

  .dropdown-menu .divider {
    height: 2px !important;
    margin: 0;
    display: block;
  }
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  color: darken(@borders-color, 17%);
  border: 1px solid darken(@borders-color, 17%) !important;
  background: @tables-background !important;
  border-color: transparent !important;
  .box-shadow-light;
  .border-radius-4;
}

.table-container-wrapper,
.ul-inline-row,
.analytics-container,
.well {
  border-radius: 8px;

  .pagination > .active > a,
  .pagination > .active > a:focus,
  .pagination > .active > a:hover,
  .pagination > .active > span,
  .pagination > .active > span:focus,
  .pagination > .active > span:hover {
    background: @navbar-background !important;
    color: @tables-background !important;
    border-color: transparent !important;
    .box-shadow-light;
    .border-radius-4;
  }

  .page-total-analytics-container {
    margin-bottom: 10px;
    text-align: left;
  }

  .start-page-header {
    font-size: 18px;

    small {
      font-size: 12px;
    }
  }
}

.nav-tabs {
  border-bottom: 1px solid transparent !important;
  width: 100%;

  li {
    font-size: @font-size !important;
    cursor: pointer;
    color: @contract-executed-color !important;
  }

  a {
    font-size: @font-size !important;
    cursor: pointer;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background: transparent !important;
    margin-right: 5px !important;
    font-family: @font-family-regular !important;
  }

  a:hover {
    background: @alt-background * 1.1 !important;
  }

  li.active a {
    background: @tables-background !important;
    color: @contract-executed-color !important;
    font-size: @font-size !important;
    bottom: -2px !important;
  }

  .month-selector {
    a {
      background: transparent !important;
    }

    a:hover {
      background: transparent !important;
    }
  }
}

.main-nav-tabs {
  border-bottom: 0 !important;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;

  .label_start {
    margin-left: 5px;
  }

  li {
    margin-bottom: 0;
    float: none;
    display: inline-block;
  }

  .payment-details-container {
    display: none;
  }

  li > a {
    &::first-letter {
      text-transform: uppercase !important;
    }

    font-size: @font-size !important;
    cursor: pointer;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
    padding-right: 4px;
    padding-left: 4px;
    font-family: @font-family-light !important;
  }

  li.active a {
    background: @main-background !important;
    border-bottom-color: @main-background-color !important;
    box-shadow: none !important;
    font-size: @font-size !important;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }
}

.nav-tabs-cut {
  .thead-filter-btn {
    display: block !important;
  }

  li > a {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  li.active > a {
    span {
      display: inline-block;
    }
  }
}

.nav-tabs::-webkit-scrollbar {
  height: 0;
}

.nav-tabs > li > a {
  border-radius: 0.45em 0.45em 0 0;
}

.table-nav-tabs {
  border-bottom: 1px solid @borders-color !important;

  a {
    padding: 4px !important;
    color: @contract-executed-color !important;
    border-radius: 0 !important;
    font-size: @font-size !important;
    font-family: @font-family-light;
  }

  li.active a {
    border: 0 !important;
    border-bottom: 2px solid @btn-link-color !important;
    bottom: 0 !important;
    font-size: @font-size !important;
    border-top-left-radius: 0.25em !important;
    border-top-right-radius: 0.25em !important;
    font-family: @font-family-regular;
  }

  li {
    margin-right: 10px;
  }
}

.no-nav-tabs {
  .table-nav-tabs {
    display: none;
  }
}

.nav-tabs.subtabs-container {
  li > a,
  li a,
  a {
    line-height: 1 !important;
    padding: 2px !important;
    font-size: calc(@font-size / 1.3) !important;
  }

  li.active a {
    font-size: calc(@font-size / 1.3) !important;
    bottom: -3px !important;
  }
}

@-moz-document url-prefix() {
  .reports-dropdown {
    li > a {
      display: inline-block !important;
    }
  }
}

.prices-nav {
  width: 95% !important;
  margin-top: 140px;
  .centered;

  li a {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.modal-nav-tabs {
  .main-nav-tabs;

  li.active a {
    background: @modal-background !important;
    border-bottom-color: @modal-background !important;
    bottom: -2px !important;
  }

  li > a {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.main-nav-tabs-colored {
  li.active a {
    background: @tables-background !important;
    border-bottom-color: @tables-background !important;
  }
}

.nav-tabs-condensed {
  li > a {
    padding-left: 4px;
    padding-right: 4px;
  }

  li {
    margin-bottom: 0 !important;
  }

  li.active {
    margin-bottom: -1px !important;
  }
}

.global-search-container {
  .ui-select-container {
    padding: 2px;
  }

  .label {
    padding: 2px;
    color: @contract-executed-color !important;
    margin-right: 5px;
  }

  .ui-select-bootstrap > .ui-select-match > .btn,
  .form-control {
    height: 30px !important;
    padding: 6px !important;
    color: @tables-background !important;
    border: 0 !important;
    border-radius: 0 !important;
    width: 50px !important;
    border-bottom: 1px solid @borders-color !important;
    overflow: hidden;
  }

  .form-control:focus {
    min-width: 150px !important;
    border-bottom: 1px solid @borders-color !important;
    .transition;
  }

  .ui-select-container .form-control {
    min-width: 150px !important;
    color: @white !important;
    border-radius: 0 !important;
  }

  .text-muted {
    color: @white !important;
  }

  .dropdown-menu li {
    height: auto !important;
    max-height: 400px !important;
  }
}

.trader-nav-tabs {
  display: inline-flex;

  li {
    display: inline-block;
  }

  li a {
    padding-left: 4px;
    padding-right: 4px;

    .label_start {
      background: transparent !important;
    }
  }
}

.details-nav-tabs {
  display: block;
  margin-top: 0 !important;

  li,
  span,
  a {
    text-align: left;
    border: 0 !important;
  }

  li,
  a {
    display: block;
    width: 100%;
    border: 0 !important;
    .border-radius-4;
    font-size: calc(@font-size / 1.3) !important;
  }

  a {
    padding: 6px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-family: @font-family-bold;
    text-transform: uppercase;
    border-bottom: 1px solid @borders-color !important;

    .label_start {
      background: transparent !important;
    }
  }

  li:last-child a {
    border-bottom: 0 !important;
  }

  li.active a {
    border: 0 !important;
    border-bottom: 1px solid @borders-color !important;
    box-shadow: @tables-box-shadow;
  }
}

.user-dropdown:hover li span {
  visibility: visible;
  position: relative;
  bottom: 0px !important;
}

.item-settings-dropdown {
  padding: 1px;
  float: none;
  text-align: left;
  display: inline-flex;

  .show-menu.dropdown-menu {
    display: block !important;
    left: calc(100% + 1px) !important;
    overflow: visible;
  }
  a:nth-of-type(1) {
    padding: 0;
    line-height: 1 !important;
    vertical-align: middle;
    &::first-letter {
      text-transform: uppercase !important;
    }
    .text-stroke;
    font-family: @font-family-regular !important;
  }

  li:hover > a {
    opacity: 0.8;
  }

  .dropdown-menu {
    padding: 20px !important;
    border-radius: 8px !important;
    border: 0 !important;
    min-width: 300px;
    overflow: hidden;

    .btn,
    a {
      padding: 6px !important;
      font-size: calc(@font-size / 1.2) !important;
      .no-text-stroke;
      line-height: 1.1;
      border-bottom: 1px solid @borders-color !important;
      color: @form-control-color * 1.3 !important;
      font-family: @font-family-regular !important;
      letter-spacing: @letter-spacing-huge;
      white-space: normal;

      &:hover {
        background: @color-black-opacity-very-dark !important;
      }

      .fa {
        font-size: calc(@font-size / 1.3);
        margin-right: 15px;
      }
    }

    li:last-child {
      .btn,
      a {
        border-bottom: 0 !important;
      }
    }

    .horizontal-divider {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .dropdown-menu.wrapped-title {
    top: -15px !important;
  }

  .tooltip:hover::before {
    display: none !important;
    content: none;
  }

  .long-title {
    width: 250px;
    white-space: wrap;
  }
}

.hover-nav:hover,
.item-settings-dropdown:hover {
  .dropdown-menu {
    display: block;
    height: auto;
    overflow: visible;
    .transition;
  }
  .ui-select-choices-content {
    overflow: auto;
  }
}

.counterparties-dropdown {
  width: 100%;

  .btn {
    font-size: @font-size;
    color: @main-font-color;
    padding: 2px;

    .fa {
      font-size: @font-size;
    }

    .label_start {
      float: right;
      padding: 0 !important;
    }
  }

  li:nth-child(1),
  li:nth-child(6),
  li:nth-last-child(3) {
    margin-bottom: 10px;
    border-bottom: 1px solid @borders-color !important;
  }
}

@-moz-document url-prefix() {
  .counterparties-dropdown {
    li > a {
      display: inline-block !important;
    }
  }
}

.reports-dropdown {
  .counterparties-dropdown;

  li:nth-child(1),
  li:nth-child(6),
  li:nth-last-child(2) {
    margin-bottom: 0;
    border-bottom: 0 !important;
  }
}

.crop-filter-dropdown {
  .main-li {
    padding: 0 !important;
    float: none;

    a {
      padding-bottom: 5px !important;
      padding-top: 0px !important;
    }
  }

  .center-block;
  margin-top: 0;
}

//tables mixins

.no-header {
  thead,
  .nav-tabs {
    display: none !important;
  }
}

.table-fixed-layout {
  table-layout: fixed;

  tr td {
    white-space: normal !important;
  }
}

.table-min-column-width {
  tr td {
    min-width: 70px !important;
  }
}

.table-no-borders {
  tr td {
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    vertical-align: middle;
    text-align: left;
    line-height: 1;
    border-bottom: 1px solid @borders-color;

    .alert,
    .alert-success,
    .alert-danger {
      padding: 5px;
      padding-left: 15px;
      padding-right: 15px;
      .box-shadow-light;

      .label {
        font-size: @font-size !important;
        line-height: 1 !important;
        padding: 0 !important;
      }
    }

    .property {
      font-size: calc(@font-size / 1.3) !important;
      line-height: 1 !important;
      font-family: @font-family-light;

      span {
        line-height: 1 !important;
      }
    }
  }

  tr:last-child td,
  tr td.no-border {
    border-bottom: 0;
  }

  td.td-center-align {
    text-align: center !important;
  }
}

.details-wrapper .table-with-border,
.table-with-border {
  border-collapse: collapse;

  tr th,
  tr td {
    border: 1px solid @borders-color !important;
  }

  tr th:last-child,
  tr td:last-child {
    border-right: 1px solid @borders-color !important;
  }

  tr th.thick-border,
  tr td.thick-border {
    border-right: 3px solid @borders-color !important;
  }
}

.table-fixed {
  tbody {
    display: block;
    height: 200px;
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tr td {
    text-overflow: ellipsis;
  }

  thead {
    width: calc(100% - 1em);
  }
}

.ui-grid-header {
  border: 0 !important;
}

.ui-grid-cell-contents {
  padding: 2px !important;
  font-family: @font-family-regular;
  font-size: @font-size !important;
  line-height: 2;
  white-space: normal;

  .btn {
    background: @tables-background !important;
    border-right: 0 !important;
  }
}

.ui-grid-column-menu-button {
  background: @tables-background !important;
}

.ui-grid-render-container {
  .border-radius-4;
  padding: 0px;
}

.ui-grid-header-cell {
  border-right: 0 !important;
  color: @th-main-color !important;
  background: @tables-background !important;
  white-space: normal;
}

.dashboard-start-container-block {
  .ui-grid {
    height: auto !important;
  }

  .ui-grid-viewport,
  .ui-grid-header {
    overflow: visible !important;
    width: auto !important;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .ui-grid-viewport {
    height: 300px;
  }

  .no_content_text {
    padding: 0 !important;
    padding-left: 10px !important;
  }
}

.ui-grid {
  border: 0 !important;
}

.ui-grid-header-cell-label {
  font-size: calc(@font-size / 1.2) !important;
  text-transform: uppercase;
  text-align: center;
  font-family: @font-family-semibold !important;
  color: @th-main-color !important;
}

.ui-grid-row:nth-child(odd) .ui-grid-cell {
  background: @tables-background !important;
}

.ui-grid-row:nth-child(even) .ui-grid-cell {
  background: @tables-background !important;
}

.ui-grid-cell {
  border-right: 0 !important;
  border-bottom: 0 !important;
  vertical-align: middle !important;

  img,
  .no-user-avatar {
    height: 15px;
    width: 15px;
    text-align: center;
    .border-radius-4;
    display: block;
    margin: 0 auto;
    margin-top: 7px;
  }

  .label {
    text-align: center;
    padding: 2px !important;
    vertical-align: bottom;
    font-size: calc(@font-size / 1.2) !important;
    border-radius: 0 !important;
  }

  .tooltip {
    font-size: calc(@font-size / 1.2) !important;
    padding: 2px;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    position: relative;
    display: block;
    text-overflow: ellipsis;
    line-height: 2;
    font-family: @font-family-regular !important;
  }

  .negative-number {
    color: @label-danger-color !important;
  }

  .positive-number {
    color: @label-success-color !important;
  }
}

.ui-grid-top-panel {
  background: transparent !important;
}

.btn-link.start-btn-link {
  font-size: calc(@font-size / 1.3) !important;
  margin-bottom: 1px;
  text-transform: lowercase !important;
  color: @btn-link-color !important;

  &:hover {
    text-decoration: underline;
  }
}

.transfer-table {
  tr td {
    border-right: 1px solid @borders-color !important;
  }
}

.transfer-container {
  tr td {
    input[type='checkbox'],
    input[type='radio'] {
      margin: 0 !important;
      width: 12px;
      height: 12px;
    }
  }
}

.cabinet-container,
.passport-costs-edit,
.position-report-page,
.kpitrader-report-container,
.transfer-container,
.signings-report-page,
.confirmations-report-page,
.contract-group-container {
  div.ui-grid-cell input[type='checkbox'] {
    margin: 0;
  }

  .ui-grid-render-container {
    background: @tables-background !important;
    .border-radius-4;
    padding: 20px !important;
    max-height: 100%;
  }

  .ui-grid-viewport {
    overflow-y: visible !important;
  }

  .ui-grid-header-cell {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 0 !important;
  }

  .ui-grid-cell {
    padding: 2px;
    font-size: calc(@font-size / 1.3);
    overflow-y: visible !important;
    text-align: left;
    height: auto !important;
    position: relative;
    border: 0 !important;

    .label {
      font-size: calc(@font-size / 1.3);
      padding: 2px !important;
      margin: 0 !important;
      display: inline-block;
      vertical-align: middle;
    }

    .tooltip {
      padding: 0;
      line-height: 1;
    }

    img {
      margin: 0 !important;
    }
  }

  .ui-grid-cell:nth-child(1) {
    text-align: left;
  }

  .ui-grid-menu .ui-grid-menu-inner ul li button {
    padding: 2px;
    font-size: @font-size;
    border-bottom: 1px solid @borders-color;
    background: @tables-background;
    outline: none !important;
  }

  .ui-grid-cell-contents {
    line-height: 1;
    padding: 0;
  }

  .ui-grid-row {
    border-top: 1px solid @borders-color !important;
    height: auto !important;
    position: relative;
  }
}

.cabinet-container {
  .main-table {
    tr td {
      border-right: 1px solid @borders-color * 1.05 !important;
      padding: 2px !important;
      white-space: nowrap;
      word-wrap: break-word;

      .btn,
      .btn-xs,
      a {
        padding: 0 !important;
        line-height: 1.1 !important;
      }

      .label {
        font-size: 100% !important;
        display: inline-block;
        margin: 0 !important;
      }
    }

    tr th {
      text-align: center !important;
      height: 51px !important;
      min-height: 51px !important;
    }

    tr td:last-child {
      border-right: none !important;
    }

    tr td.stages {
      min-width: 500px;
    }

    tr td.td-white-space-normal {
      white-space: normal !important;
    }
  }

  .contract-status-block-td {
    display: inline !important;
  }

  .contract-status-block-td .contract-status-block {
    width: auto !important;
  }

  .ui-grid-cell .tooltip {
    font-family: @font-family-regular !important;
  }

  .contract-status-block {
    padding: 2px !important;
    margin: 0 !important;
    max-width: 60px;
    word-break: break-word;
    white-space: normal;
    vertical-align: top;
  }

  .ui-grid {
    height: auto !important;
  }
}

.position-report-page,
.kpi-traders-container {
  .no-wrap {
    white-space: nowrap !important;
  }

  .ui-grid-footer-aggregates-row {
    height: auto !important;
    background: @highlighted-area !important;

    .ui-grid-cell-contents {
      font-size: calc(@font-size / 1.3) !important;
      font-family: @font-family-semibold;
    }
  }
}

.contract-group-container {
  .ui-grid-cell {
    text-align: right;
    border-right: 1px solid @borders-color !important;
  }

  .ui-grid-cell:first-child {
    text-align: left;
  }

  .ui-grid-header-cell {
    border-right: 1px solid @borders-color !important;
  }

  .ui-grid-header-cell:nth-last-child {
    border-right: 0 !important;
  }

  .ui-grid {
    height: 700px !important;
  }

  .ui-grid-viewport {
    height: 700px !important;
  }
}

.unscrollable-grid {
  .ui-grid {
    border: 0 !important;
    height: auto !important;
    position: relative;
    overflow-y: visible !important;
  }

  .ui-grid-contents-wrapper {
    height: auto !important;
  }

  .ui-grid-viewport,
  .ui-grid-header {
    width: auto !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.scrollable-grid {
  .ui-grid {
    height: 600px !important;
  }

  .ui-grid-viewport,
  .ui-grid-header {
    width: auto !important;
  }

  .ui-grid-viewport {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

.ui-grid-footer-aggregates-row {
  height: auto !important;
}

.ui-grid-footer-cell {
  background: @highlighted-area !important;
  font-family: @font-family-bold !important;
  white-space: normal;
  padding: 2px;
  font-size: @font-size !important;
}

.ui-grid-footer-panel {
  border: 0 !important;
  border-top: 1px solid @borders-color !important;
  background: @tables-background !important;
}

.kpitrader-report-container {
  .ui-grid-cell {
    text-align: right;
    vertical-align: middle;
    border-right: 1px solid @borders-color !important;

    .label {
      padding: 2px !important;
      padding-left: 2px !important;
      padding-right: 2px !important;
      vertical-align: middle;
    }

    .badge {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  .ui-grid-cell:first-child {
    text-align: left;
  }

  .ui-grid-header-cell {
    padding-right: 0;
    padding-left: 0;
  }

  .ui-grid-cell:last-child {
    border-right: 0 !important;
  }

  .ui-grid-category {
    text-align: center;
    border-right: 1px solid @borders-color !important;
  }

  .ui-grid-category:last-child {
    border-right: 0 !important;
  }

  .ui-grid-footer-cell {
    text-align: right !important;
  }
}

.table thead > tr > th,
.table tbody > tr > td,
.table tfoot > tr > td {
  border-color: @table-border-color;
}

table {
  caption {
    display: none;
  }
  tr th {
    word-wrap: break-word;
    white-space: normal;

    div {
      padding: 2px !important;
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      text-align: center;
      word-wrap: break-word;
      white-space: normal;
    }

    .thead-filters {
      padding-top: 2px !important;
      padding-bottom: 2px !important;

      div {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        text-align: left;
      }

      .form-control {
        padding: 2px !important;
        min-width: 100px;
        background: @borders-color !important;
        border: 0 !important;
      }

      .filter-template {
        background: transparent !important;
        border: 0 !important;
      }

      .filter-template .btn:last-child {
        background: @borders-color !important;
      }

      .filter-template .btn.btn-clear:last-child {
        border: 0 !important;
      }

      .filter-template .btn {
        padding: 0 !important;
        border: 0 !important;
        margin-right: 5px;
      }

      .form-group {
        margin-bottom: 2px !important;
      }

      .input-group {
        margin-bottom: 2px !important;
      }
    }
  }
}

.table tbody > tr > td,
.table tbody > tr > th {
  line-height: 1;
}

.table-template {
  border-collapse: separate;
  .centered;
  font-size: @small-font;
  text-align: center;

  tr > td {
    border-top: 1px solid @borders-color !important;
    border-right: 1px solid @borders-color * 1.07 !important;
    line-height: 1 !important;
    vertical-align: middle !important;

    .hover-element {
      transition:
        transform 0.4s ease-in-out,
        opacity 0.4s ease-in;
      opacity: 0.001;
      transform: translate3d(0, 8px, 0);
      visibility: hidden;
    }

    .fa-arrow-right.hover-element {
      font-size: calc(@font-size / 1.4) !important;
    }

    .actions-btn {
      display: inline-flex;
      padding: 1px !important;

      .fa-ellipsis {
        font-size: calc(@font-size * 1.1) !important;
      }
    }

    .fa.show-on-hover {
      display: none;
    }

    .text-stroke;
    letter-spacing: @letter-spacing-huge;

    .btn-link {
      font-family: @font-family-regular !important;
    }

    span {
      line-height: 1 !important;
    }

    .text-muted {
      line-height: 1 !important;
    }

    .input-group-table-edit-btn {
      display: flex;
    }

    .table-edit-btn {
      padding: 0 !important;
      .border-radius-4;
      border: 1px solid transparent !important;
      margin: 0;
      line-height: 1 !important;
      margin-left: 5px;
      font-size: @font-size !important;

      .fa {
        font-size: @font-size !important;
      }
    }

    .table-edit-btn:first-of-type {
      margin-left: 0;
    }

    .info-table-btn:hover {
      border: 1px solid @label-info-color-border !important;
    }

    .danger-table-btn:hover {
      border: 1px solid @label-danger-background !important;
    }

    .warning-table-btn:hover {
      border: 1px solid @label-warning-color-border !important;
    }

    .small {
      color: @btn-default-color !important;
      margin-top: 5px;
      text-align: left;

      .fa,
      .updated_time,
      a,
      span {
        color: @btn-default-color !important;
        font-size: calc(@font-size / 1.3) !important;
      }

      .btn-link {
        color: @btn-link-color !important;
        font-size: calc(@font-size / 1.3) !important;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  tr:hover {
    .fa.hide-on-hover {
      display: none;
    }

    .fa.show-on-hover {
      display: inline-block;
    }
  }

  tr:hover td {
    .hover-element {
      .transition;
      opacity: 1;
      transform: translate3d(0, 0, 0);
      visibility: visible;
      width: auto;
    }

    .fa-sort-desc.hover-element {
      font-size: calc(@font-size * 1.2) !important;
    }
  }

  tr > th {
    border-bottom: 1px solid @borders-color !important;
    border-right: 0;
    &::first-letter {
      text-transform: uppercase !important;
    }
    letter-spacing: @letter-spacing-mega;
  }

  tr th.filter-th {
    background: @highlighted-area !important;
    border-bottom: 0 !important;
  }

  tr:hover th.filter-th {
    background: @highlighted-area !important;
  }

  tr > td:last-child,
  tr > th:last-child {
    border-right: 0 !important;
  }

  th {
    color: @th-main-color;
    font-size: calc(@font-size / 1.3);
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    word-break: normal;
    font-family: @font-family-regular !important;
    cursor: pointer !important;
    vertical-align: middle !important;

    .filter-template .ui-select-match .form-control,
    .filter-template .ui-select-container .form-control,
    .form-control {
      font-size: calc(@font-size / 1.3) !important;
      padding: 0 !important;
      /*  height:20px !important;*/
      width: 100%;
      padding-left: 5px !important;
      max-width: 100%;
      min-width: 100%;
      .border-radius-4;
    }

    .fa.fa-sort {
      display: none;
    }

    .filter-template .ui-select-match .form-control {
      line-height: 2.4;
      width: 100% !important;
    }

    .input-group-addon {
      font-size: calc(@font-size / 1.3) !important;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 2px;
      padding-bottom: 2px;
      text-transform: none !important;
    }

    .form-group {
      padding: 2px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-bottom: 5px !important;
      width: 100% !important;
    }

    .input-group {
      margin-bottom: 5px;
    }

    select {
      margin-top: 0 !important;
      margin-bottom: 5px !important;
    }

    .caret {
      display: none;
    }

    .text-muted {
      text-transform: none !important;
      color: @form-control-color !important;
      line-height: 1;
    }

    .big-font {
      font-size: calc(@font-size * 1.2) !important;
    }

    .btn-link {
      .no-text-stroke;
      padding: 0 !important;
    }
  }

  th:hover {
    color: @table-headers-hover;
  }

  .filter-th:hover {
    background: @tables-background !important;
  }

  td:first-child {
    text-align: left;
    font-family: @font-family-regular !important;

    .btn-link {
      color: @btn-link-color !important;

      &:hover {
        text-decoration: underline;
      }

      border-bottom: 0 !important;
      .no-text-stroke;

      .fa {
        font-size: calc(@font-size / 1.3) !important;
      }
    }
  }

  td {
    color: @td-main-color;
    text-align: center;
    vertical-align: top;
    border-radius: 0;
    font-family: @font-family-regular;
    line-height: 1;
  }
}

.table-strip {
  tbody tr:nth-child(even) {
    background-color: @color-black-opacity-dark !important;
  }

  tr td {
    border: 0;
  }
}

.cut-contract-table {
  padding-left: 5px;
  padding-right: 5px;
}

#cut-contract-table {
  width: @left-sidebar-item-width;
}

.cut-table {
  .table-template;
  font-size: @small-font / 1.1;
  overflow: auto;
  width: 100% !important;
  border-color: @table-border-color-light !important;

  td {
    color: @td-main-color;
    padding-left: 2px !important;
    padding-right: 2px !important;
    text-align: center;
    border-color: @borders-color !important;
    width: auto !important;
    line-height: 1;
  }

  tr > td {
    border-top: 1px solid @borders-color !important;
  }

  tr > th {
    border-bottom: 2px solid @borders-color !important;
  }

  th:first-child {
    text-align: center;
  }

  th {
    text-align: center;
    border-color: @borders-color !important;
  }

  tr.selected {
    text-decoration: none !important;
    cursor: pointer;
    background: @color-black-opacity !important;

    td {
      opacity: 1 !important;
      text-decoration: none !important;
      background: @color-black-opacity !important;
    }
  }

  td:nth-child(1) {
    color: @td-main-color !important;
    text-align: center;
    width: 15%;
  }

  td:last-child {
    padding-right: 5px !important;
  }

  span {
    margin-top: 2px !important;
  }

  .lower-zero-number {
    color: @lower-zero-color !important;
  }

  .higher-zero-number {
    color: @higher-zero-color !important;
  }

  .contract_executed > td,
  .contract_executed > td.status-cell {
    color: @contract-executed-color !important;
    font-weight: normal;

    .btn-link {
      color: @btn-link-color !important;
      font-size: calc(@font-size / 1.3) !important;

      &:hover {
        text-decoration: underline;
      }
    }

    .label,
    .badge {
      .label_default;

      a {
        color: @tables-background !important;
      }
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
    }
  }

  .contract_calculate > td:first-child,
  .contract_calculate > td.status-cell {
    color: @contract-calculate-color !important;

    .btn-link,
    a {
      color: @contract-calculate-color !important;
      border-bottom-color: @contract-calculate-color !important;
    }

    .label,
    .badge {
      .label_calculate;

      a {
        color: @tables-background !important;
      }
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
    }
  }

  .contract_new > td:first-child,
  .contract_new > td.status-cell {
    color: @contract-new-color !important;

    .btn-link,
    a {
      color: @contract-new-color !important;
      border-bottom-color: @contract-new-color !important;
    }

    .label,
    .badge {
      .label_success;

      a {
        color: @tables-background !important;
      }
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
    }
  }

  .contract_processing > td:first-child,
  .contract_processing > td.status-cell {
    color: @contract-processing-color !important;

    .btn-link,
    a {
      color: @contract-processing-color !important;
      border-bottom-color: @contract-processing-color !important;
    }

    .label,
    .badge {
      .label_warning;

      a {
        color: @tables-background !important;
      }
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
    }
  }

  .contract_cancelled > td,
  .contract_cancelled > td.status-cell {
    color: @contract-cancelled-color !important;
    opacity: 0.5;
    text-decoration: line-through;
    font-weight: normal;

    .btn-link,
    a {
      color: @contract-cancelled-color !important;
      border-bottom-color: @contract-cancelled-color !important;
    }

    .label,
    .badge {
      .label_cancelled;

      a {
        color: @tables-background !important;
      }
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
    }
  }

  .label {
    display: inline-block !important;
    margin-top: 1px;
    text-align: center;
    white-space: normal;
    font-size: 1em !important;
    color: @label-cut-table-color;
    font-family: @font-family-light;
    font-weight: 400 !important;
  }

  .label-dashed {
    color: @label-dashed-color;
  }

  .attached_label {
    display: block !important;
  }

  td:nth-child(3) {
    word-break: break-word;
  }
}

.table-details {
  .table-template;

  td,
  td:first-child {
    text-align: center;
    border-color: @table-border-color !important;
  }

  tr td {
    border-color: @table-border-color !important;
  }

  tr:first-child td {
    border: none;
  }
}

.big-table {
  .table-template;
  font-size: @small-font / 1.3;
}

.main-table {
  margin-bottom: 0 !important;
  .table-template;
  font-size: @small-font / 1.1;
  text-overflow: ellipsis;

  td:first-child {
    border-left: 5px solid rgba(255, 255, 255, 0) !important;
  }

  tbody > tr > th,
  tfoot > tr > th,
  thead > tr > th {
    border-top: 0;
  }

  td {
    border-color: @borders-color !important;
    color: @main-table-td;
    vertical-align: middle !important;
  }

  tr td,
  tr th {
    .form-control,
    .form-control .ui-select-match-text {
      min-height: 34px;
      min-width: 50px !important;
      overflow: hidden;
      margin-bottom: 2px;
      font-size: calc(@font-size / 1.2) !important;
    }

    input.valid-date,
    .gt-date-select input.form-control {
      margin-bottom: 0 !important;
    }

    .ui-select-bootstrap > .ui-select-match > .btn {
      min-width: 50px !important;
      min-height: 34px;
      height: auto;
      overflow: hidden;
      line-height: 1 !important;
      margin-bottom: 2px;
      letter-spacing: @letter-spacing-huge;
      font-size: calc(@font-size / 1.2) !important;
    }
  }

  tr th {
    .form-control,
    .form-control .ui-select-match-text,
    .text-muted {
      font-family: @font-family-regular !important;
    }

    .ui-select-bootstrap > .ui-select-match > .btn,
    .form-control {
      min-height: auto;
      height: auto;
      padding: 4px !important;
    }
  }

  .td-with-border {
    border-right: 1px solid @borders-color !important;
  }

  td:last-child {
    border-right: 0 !important;
  }

  .highlighted-tr {
    td {
      background: @highlighted-area !important;
      border-color: darken(@highlighted-area, 4%) !important;
      color: @contract-executed-color !important;
      border-radius: 0 !important;
    }
    td:first-child {
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
      border-left: 0 !important;
    }
    td:last-child {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
      border-right: 0 !important;
    }
  }

  td.highlighted {
    background: @highlighted-area !important;
    border-right: 1px solid darken(@highlighted-area, 4%) !important;
    border-left: 1px solid darken(@highlighted-area, 4%) !important;
    border-bottom: 1px solid darken(@highlighted-area, 4%) !important;
    border-radius: 0 !important;
  }

  td.th-left-align {
    text-align: left !important;

    div {
      text-align: left !important;
    }

    .no-user-avatar {
      text-align: center !important;
    }
  }

  td.th-center-align {
    text-align: center !important;

    div {
      text-align: center !important;
    }
  }

  td.td-left-align {
    text-align: left !important;
  }

  td.td-right-align {
    text-align: right !important;
  }

  .highlighted-tr-first-row {
    td {
      font-family: @font-family-bold !important;
      border-right-color: transparent !important;
    }

    td:first-child {
      background: @alt-background !important;
      border-bottom: 1px solid darken(@highlighted-area, 9%) !important;
      color: @contract-executed-color !important;
      border-right-color: @borders-color !important;
    }
  }

  .highlighted-td {
    background: @highlighted-area !important;
    border-radius: 0 !important;
  }

  .highlighted-th {
    background: @color-black-opacity !important;
    vertical-align: top !important;
  }

  .highlighted-td.danger-highlighted {
    background: @status-danger-background !important;
  }

  .highlighted-td.warning-highlighted {
    background: @status-exclamation-background !important;
  }

  .highlighted-td.success-highlighted {
    background: @status-success-background !important;
  }

  tr td {
    .ui-select-bootstrap .ui-select-toggle > .caret {
      display: none !important;
    }
  }
}

.extended-table {
  .main-table;
  font-size: @small-font / 1.3;

  td:first-child {
    vertical-align: middle;
    text-align: right;
    color: @td-main-color;
    border-color: @table-border-color !important;
  }
}

.table tfoot {
  tr:nth-last-child(2) {
    td {
      border-bottom: 0 !important;
    }
  }

  tr:nth-last-child(1) {
    td {
      font-family: @font-family-semibold !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}

.table-with-total {
  border-color: @table-border-color !important;

  tbody tr:nth-last-child(2) {
    td {
      border-bottom: 0 !important;
    }
  }

  tbody tr:nth-last-child(1),
  tfoot tr:nth-last-child(1) {
    td,
    td.highlighted-tr,
    td.highlighted-td {
      background: @tables-background !important;
      font-family: @font-family-bold !important;
      border-radius: 0 !important;
    }
  }

  tbody tr:nth-child(1) {
    background: transparent !important;
  }

  .lower-zero-number {
    color: @lower-zero-color !important;
  }

  .higher-zero-number {
    color: @higher-zero-color !important;
  }

  .total-row {
    .label {
      font-size: @font-size !important;
      font-family: @font-family-bold !important;
    }
  }
}

.table-no-total {
  tbody tr:nth-last-child(1) {
    td {
      background: transparent !important;
      color: @label-default-color-border;
      padding: 2px !important;
    }
  }

  tr td {
    color: @main-table-td !important;
    border: 0 !important;
    padding: 2px !important;
  }
}

.table-inside-table {
  background: @color-black-opacity-dark;
}

.table-hover {
  tr:hover {
    td {
      opacity: 1 !important;
      background: @hover-color;
      text-decoration: none !important;
      border-radius: 0 !important;
    }
  }
}

.big-progressbars-container {
  label,
  label.contract-description-label {
    font-size: @font-size !important;
    margin: 0;
    line-height: 2.3;
  }

  .progress .progress-bar {
    font-size: @font-size !important;
    padding: 0 !important;
  }

  .col-xs-6 {
    padding-left: 10px;
    padding-right: 0;
  }
}

.cabinets-counters.big-progressbars-container {
  label,
  label.contract-description-label {
    font-size: calc(@font-size * 1.2) !important;
    margin: 0;
    line-height: 1.1;
  }

  .progress .progress-bar {
    font-size: calc(@font-size * 1.2) !important;
    padding: 0 !important;
  }
}

.object-details-container {
  .modal-header {
    display: none;
  }
  .form-group-container .form-group .col-xs-12 {
    display: none;
  }
  .form-group-container input[type='checkbox'].ng-empty,
  .form-group-container input[type='radio'].ng-empty {
    display: none;
  }
  .form-group-container.col-md-6,
  .form-group-container.col-sm-3,
  .form-group-container.col-sm-4 {
    width: 100% !important;
  }
}
.object-details-container,
.position-row-detail-container {
  label {
    pointer-events: none;
  }

  .modal-body {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .modal-form-block .ul-inline-row {
    box-shadow: none !important;
    padding: 0 !important;

    .btn-add-dark {
      display: none;
    }
  }

  .help-inline-hint {
    display: none;
  }

  .form-group-container.main-block-wide {
    box-shadow: none !important;
    width: 98%;
    margin: 0 !important;

    .modal-form-block {
      width: 98% !important;
    }

    .formly-field .form-group .control-label {
      font-size: calc(@font-size / 1.2) !important;
      color: @form-control-color * 1.3 !important;
    }

    .formly-field .form-group .form-control {
      font-size: calc(@font-size / 1.2) !important;
      background: transparent !important;
      color: @form-control-color * 1.3 !important;
    }
  }

  .form-group-container {
    padding-right: 0 !important;
    padding-left: 10px;
  }

  .contract-description-label {
    background: @blue-background !important;
    color: @tables-background !important;
    letter-spacing: 2px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding: 2px;
    font-size: calc(@font-size / 1.3) !important;
    display: none !important;
  }

  .form-group-container .form-group .col-xs-12.not-empty {
    display: block;
    border-bottom: 1px dashed @borders-color !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
  }

  .form-group-container .form-group.not-empty {
    background: transparent !important;
    border: 0 !important;
    border-bottom: 0 !important;
  }

  .form-group-container .form-group .ui-select-container {
    padding-left: 0 !important;
  }

  .form-group-container .form-group .control-label {
    padding: 2px !important;
    word-wrap: break-word;
    margin: 0 !important;
    float: left;
    color: @smoke !important;
    font-size: calc(@font-size / 1.2) !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
    font-family: @font-family-regular !important;
    text-align: right !important;
    white-space: nowrap;
    overflow: hidden;
    height: 18px !important;
    min-height: 18px !important;
    display: block !important;
    width: 40%;

    .check-label {
      display: none;
    }

    .tooltip-modal {
      display: none;
    }
    &:hover {
      .transition;
      white-space: normal;
    }
  }

  .form-group-container .form-group .input-wrapper {
    float: right;
    width: 60%;

    .object-title,
    textarea.form-control,
    select,
    input,
    input[type='checkbox'],
    .ui-select-multiple .ui-select-match-item,
    .form-control {
      font-size: calc(@font-size / 1.2) !important;
      cursor: pointer !important;
      text-align: left;
      line-height: 1 !important;
      font-family: @font-family-regular;
      color: @form-control-color * 1.3 !important;
    }
  }

  .object-title {
    text-align: left;
    font-family: @font-family-regular;
    font-size: calc(@font-size / 1.2) !important;
  }

  .form-group-container .form-group.col-xs-12 {
    margin-bottom: 0 !important;
    padding: 0 !important;
    border: 0 !important;
  }

  .form-group-container .form-group .input-wrapper .input-group {
    padding-left: 0;
    line-height: 1;
  }

  .form-group-container .form-group.not-empty .input-wrapper,
  .form-group-container .form-group .input-wrapper {
    padding: 0 !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    white-space: normal;
    word-wrap: break-word;
    margin: 0 !important;
    border: 0 !important;
    background: @tables-background !important;
    border-radius: 0 !important;
    text-align: left;
    overflow: visible;
    height: auto !important;
    min-height: auto !important;
  }

  .form-group-container .form-group {
    padding: 0 !important;
  }

  .form-group-container .form-group .form-control,
  .form-control {
    border: 0 !important;
    background: transparent !important;
    padding: 0 !important;
    overflow: hidden;
    white-space: normal;
    pointer-events: none;

    .ui-select-placeholder,
    .text-muted {
      display: none !important;
      color: transparent !important;
      font-size: calc(@font-size / 1.2) !important;
    }

    &::-webkit-input-placeholder {
      display: none !important;
      color: transparent !important;
      font-size: calc(@font-size / 1.2) !important;
    }

    &::-moz-placeholder {
      display: none !important;
      color: transparent !important;
      font-size: calc(@font-size / 1.2) !important;
    }

    &:-moz-placeholder {
      display: none !important;
      color: transparent !important;
      font-size: calc(@font-size / 1.2) !important;
    }

    &:-ms-input-placeholder {
      display: none !important;
      color: transparent !important;
      font-size: calc(@font-size / 1.2) !important;
    }
    .alert {
      width: 80%;
      border-radius: 10px !important;
      border-bottom-right-radius: 0 !important;
      margin-bottom: 5px;
      padding: 5px;
    }
  }

  .copy-clipboard-btn {
    pointer-events: all !important;
  }

  .form-group-container .form-group select {
    margin-left: -4px !important;
  }
  input[type='checkbox'],
  input[type='radio'] {
    margin: 0;
    margin-left: -4px !important;
  }
  .input-group-addon-search {
    display: none;
  }

  .clear-btn,
  .clear-title-btn {
    display: none;
  }

  .form-group .input-group-addon {
    display: none;
  }

  .add-btn {
    display: none;
  }

  .ui-select-match-close {
    display: none;
  }

  .help-inline {
    display: none;
  }

  .select-with-btn {
    width: 100% !important;
  }

  .contract-price-widget {
    background: @tables-background !important;

    .btn-primary {
      display: none;
    }
  }

  .not-empty .form-control,
  .not-empty .control-label {
    color: @form-control-color * 1.3 !important;
    background: @tables-background !important;
  }

  .not-empty .btn-default {
    color: @form-control-color * 1.3 !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
    white-space: normal;
    overflow: visible;
    line-height: 1;
  }

  .not-empty {
    .form-add-btn {
      display: none;
    }

    .select-with-btn {
      width: 100%;
    }
  }

  .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    display: none;
  }

  a.btn-success,
  a.btn_success,
  .fa-files-o,
  .fa-trash,
  .fa-pencil,
  .fa-file-word-o,
  .fa-remove {
    display: none;
  }

  .edit-mode {
    display: none;
  }

  textarea.form-control,
  select,
  input,
  input[type='checkbox'],
  .ui-select-multiple .ui-select-match-item,
  .form-control {
    border: 0 !important;
    font-size: calc(@font-size / 1.3) !important;
    cursor: pointer !important;
  }
  .form-control .ui-select-match-text:hover .clear-btn {
    display: none;
  }
  .form-control .ui-select-match-text:hover .copy-btn {
    display: inline-block;
  }
  select,
  input[type='checkbox'] {
    pointer-events: none !important;
    user-select: auto;
  }
  .positions-modal {
    .form-group-container {
      width: 100% !important;
    }
  }
}
.object-details-container {
  .form-group-container.col-sm-3,
  .form-group-container.col-sm-4 {
    width: 100% !important;
  }
}
.counterparty_detail_table {
  td {
    padding: 4px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    white-space: normal;
    word-wrap: break-word;
    text-align: left !important;
    font-size: calc(@font-size / 1.2) !important;
    font-family: @font-family-regular !important;
    vertical-align: middle !important;

    .form-control {
      height: 22px !important;
      min-height: 22px !important;
    }

    h3,
    h4,
    h5 {
      margin: 0;
    }
  }

  tr:first-child {
    border-top: 0 !important;

    td {
      border-top: 0 !important;
    }
  }

  tr td:first-child {
    color: @btn-default-color !important;
    font-size: calc(@font-size / 1.2) !important;
    vertical-align: middle;
    &::first-letter {
      text-transform: uppercase !important;
    }
    font-family: @font-family-regular !important;
    width: 42%;
    word-break: normal;
  }

  .label {
    display: inline-block !important;
    margin-right: 2px;
    vertical-align: middle;
    font-size: calc(@font-size / 1.2) !important;
  }

  .btn-link {
    font-size: calc(@font-size / 1.2) !important;
  }
}

.counterparty-details-table {
  tr td:first-child {
    background: @color-black-opacity !important;
    text-transform: uppercase !important;
    vertical-align: middle;
    font-family: @font-family-light !important;
  }

  tr td {
    font-size: calc(@font-size / 1.2);
    color: @contract-executed-color;
    padding: 2px !important;
    word-break: break-word;
    white-space: normal;

    .label {
      display: inline-flex;
    }
  }
}

.tariffs-table {
  width: 100% !important;

  tr td {
    text-align: left !important;
    border-right: 1px solid @borders-color !important;
    vertical-align: middle;
    padding: 2px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    font-size: calc(@font-size / 1.2);

    .bigger-label {
      font-family: @font-family-semibold !important;
      font-size: @font-size !important;
    }
  }

  tr:nth-child(1) td:nth-child(1) {
    border-left: 5px solid @contract-new-color !important;
  }

  tr td.edit {
    .form-control {
      margin-bottom: 2px;
    }
  }

  td.td-right-align {
    text-align: right !important;
  }

  td.td-center-align {
    text-align: center !important;
  }

  tr th {
    text-align: center !important;
  }

  td.additional_info {
    word-wrap: break-word;
    white-space: normal;
  }

  td:last-child {
    text-align: center !important;
  }

  td:first-child {
    font-family: @font-family-regular !important;
    text-transform: initial !important;

    .label_success {
      padding: 10px !important;
      display: block;
      text-align: left;
    }
  }

  .label {
    white-space: nowrap;
  }

  .btn {
    .border-radius-4;
  }

  .btn-success {
    border-width: 1px;
    color: @white !important;
    padding: 6px !important;
  }

  .btn-success:hover {
    color: @white !important;
  }

  .input-group-addon-search {
    font-size: calc(@font-size / 1.3);
    color: @contract-executed-color;
    padding: 10px;
    width: 2%;
    float: left;
  }

  .form-control {
    margin: 0;
    padding: 2px;
    height: 30px;
    font-size: @font-size;
  }

  .ui-select-bootstrap > .ui-select-match > .btn,
  .form-control .ui-select-match-text {
    line-height: 2 !important;
  }
}

.input-group {
  padding-bottom: 0;
}

.page-filter-modal .title-column,
.page-filter-modal .new-title-column,
.page-filter-modal .select-column {
  color: @label-color !important;
  text-transform: capitalize !important;
  line-height: 1 !important;
  font-family: 'Regular', sans-serif !important;
  font-size: calc(14px / 1.2) !important;
}

.page-filter-modal .row,
.page-filter-modal .input-wrapper,
.page-filter-modal .input-group {
  display: flex;
  align-items: center;
  width: 100%;
}

.page-filter-modal .input-wrapper {
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
}

.page-filter-modal .input-group {
  min-width: 175px;
}

.page-filter-modal .input-group input {
  flex-grow: 1;
  margin-right: 10px;
}

.page-filter-modal .input-group .copy-clipboard-btn {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.page-filter-modal .select-all-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-filter-modal .select-all-wrapper span {
  color: @label-color !important;
  text-transform: capitalize !important;
  line-height: 1 !important;
  font-family: 'Regular', sans-serif !important;
  font-size: calc(14px / 1.2) !important;
}

.page-filter-modal .select-all-wrapper input {
  margin-right: 10px;
}

.page-filter-modal .column-headers {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.page-filter-modal .column-headers div {
  text-align: center;
  font-weight: bold;
}

.page-filter-modal .control-label-wrapper {
  left: 60px;
  padding-right: 15px;
}

.page-filter-modal .form-group-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.page-filter-modal .col-xs-4 {
  flex: 1;
  padding: 0;
}

.page-filter-modal .select-column {
  justify-content: center;
  padding-right: 10px;
}

.page-filter-modal .row {
  margin: 0 !important;
}

.bill-of-lading-table {
  tr td {
    padding: 2px !important;
    font-size: calc(@font-size / 1.2) !important;

    .label {
      display: inline-block !important;
    }

    .item-settings-dropdown .dropdown-menu {
      top: -20px !important;
    }

    .fa {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  tr td:first-child {
    font-family: @font-family-regular !important;
    font-size: @font-size !important;
  }

  td.small-font-cell {
    font-size: calc(@font-size / 1.3) !important;

    a,
    span,
    .btn-link {
      font-size: calc(@font-size / 1.2) !important;
    }
  }
}

.commentaries-table {
  tr td.edit {
    background: @highlighted-area;

    .form-control {
      margin-bottom: 2px;
    }
  }

  tr td {
    padding: 2px !important;
    white-space: nowrap;

    .btn {
      padding: 2px;
      padding-left: 4px;
      padding-right: 4px;
      margin: 0;
      line-height: 1;
    }
  }

  tr td.comments {
    white-space: normal !important;
    word-wrap: break-word !important;
    max-width: 400px;
    text-align: left !important;
  }
}

.crops-table {
  .main-table-template;
  margin: 0 auto;
  overflow-x: auto;
  padding: 2px;
  padding-left: 8px !important;
  padding-right: 8px !important;

  .label {
    text-align: left !important;
    display: inline-block !important;
  }

  tr th {
    border-top: 1px solid darken(@borders-color, 17%) !important;
    border-bottom: 2px solid darken(@borders-color, 17%) !important;
    text-align: center !important;
  }

  td {
    width: auto;
    text-align: center;

    label {
      text-align: left;
    }
  }

  td:nth-child(1) {
    width: 10% !important;
  }

  td:nth-child(2) {
    width: 15% !important;
  }

  td:nth-child(3) {
    width: 10% !important;
  }

  td:nth-child(4) {
    width: 10% !important;
  }

  td:nth-child(5) {
    width: 10% !important;
  }

  td:nth-child(6) {
    width: 5% !important;
  }

  td:nth-child(7) {
    width: 10% !important;
  }

  td:nth-child(8) {
    width: 10% !important;
  }

  td:nth-child(9) {
    width: 10% !important;
  }

  td:nth-child(10) {
    width: 5% !important;
  }

  td:nth-child(11) {
    width: 5% !important;
  }

  .form-control {
    font-size: calc(@font-size / 1.3) !important;
  }

  .update_time {
    white-space: nowrap !important;
    text-align: left !important;
    color: @contract-executed-color !important;
  }

  .fa-times-circle {
    color: @label-danger-color;
    font-size: calc(@font-size * 1.2);
    cursor: pointer;
  }

  .fa-pencil-square,
  .fa-floppy-o {
    font-size: calc(@font-size * 1.2);
    cursor: pointer;
  }
}

.add-new-inline,
.ul-inline-row.add-new-inline {
  border-left: 6px solid @label-success-color !important;
}

.crops-edit-table {
  .ui-select-bootstrap > .ui-select-match > .btn {
    display: block !important;
  }

  .form-control {
    font-size: @font-size !important;
    text-align: left;
    vertical-align: middle !important;
    border-color: darken(@borders-color, 9%) !important;
    .border-radius-4;
    width: 100% !important;

    &::-webkit-input-placeholder {
      color: @placeholder-color !important;
      font-size: @font-size !important;
      letter-spacing: @letter-spacing-normal;
    }

    &::-moz-placeholder {
      color: @placeholder-color !important;
      font-size: @font-size !important;
      letter-spacing: @letter-spacing-normal;
    }

    &:-moz-placeholder {
      color: @placeholder-color !important;
      font-size: @font-size !important;
      letter-spacing: @letter-spacing-normal;
    }

    &:-ms-input-placeholder {
      color: @placeholder-color !important;
      font-size: @font-size !important;
      letter-spacing: @letter-spacing-normal;
    }

    .text-muted {
      color: @placeholder-color !important;
      font-size: @font-size !important;
      letter-spacing: @letter-spacing-normal;
    }

    .caret {
      display: none;
    }
  }

  .ui-select-match {
    .form-control {
      line-height: 1;
    }

    .clear-btn {
      background: @tables-background !important;
    }
  }

  tr td {
    .input-group {
      width: 100% !important;
    }
  }

  h5 {
    .input-group {
      float: left;
      margin-left: 5px;
      margin-top: -4px !important;
    }
  }

  .input-group .has-error.form-control:first-child,
  .input-group .ng-invalid-required.form-control:first-child {
    border-bottom-left-radius: 0.25em !important;
    border-bottom-right-radius: 0.25em !important;
  }

  .form-control:focus {
    box-shadow: 0 0 10px @label-success-color !important;
  }

  input:focus {
    box-shadow: 0 0 10px @label-success-color !important;
  }

  table-layout: fixed !important;
  width: 100% !important;

  tbody > tr > td {
    border-color: transparent !important;
  }

  .ui-select-bootstrap > .ui-select-choices {
    text-align: left !important;
  }

  td {
    padding: 2px;
    width: auto;
  }

  tr th {
    text-align: center;
  }
}

.passport-inline-items-table {
  .main-table;
  width: 98% !important;

  .crops-edit-table {
    .input-group-addon {
      display: none;
    }

    .ui-select-match,
    .ui-select-container {
      max-width: 100px;
    }
  }

  .table {
    margin-bottom: 0 !important;
  }

  tr th {
    font-size: calc(@font-size / 1.2);
    padding: 2px !important;
    padding-bottom: 12px !important;
    padding-top: 12px !important;
    text-align: right;
  }

  tr td {
    padding: 2px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    text-align: right;

    .label {
      display: inline-flex;
      padding: 2px;
      margin-bottom: 2px;

      .fa {
        margin-right: 3px;
      }
    }

    color: @contract-executed-color;
  }
}

.transshipment-table {
  tr th {
    text-align: center !important;

    div {
      text-align: center !important;
    }
  }

  tr td {
    border-right: 1px solid @borders-color !important;

    .btn {
      padding: 0;
      margin: 0;
      line-height: 1;
    }
  }
}

.payments-edit-table {
  .form-control {
    font-size: @font-size !important;
    text-align: left;
    vertical-align: middle !important;
  }

  tbody > tr > td {
    border-color: transparent !important;
  }

  .ui-select-bootstrap > .ui-select-choices {
    text-align: left !important;
  }

  th {
    text-align: center;
  }

  td {
    width: auto !important;
  }

  td:nth-child(1) {
    width: 15% !important;
  }
}

.payment-partial-table {
  .clients-table;
  .table-hover;

  tr th {
    padding-right: 6px !important;
    padding-left: 6px !important;
    text-align: center !important;
    border-right: 1px solid @borders-color * 1.07 !important;
  }

  tr td.company {
    white-space: normal !important;

    span {
      font-size: calc(@font-size / 1.2) !important;
    }
  }

  tr > td .text-muted {
    line-height: 1.1 !important;
  }

  tr td {
    border: 0 !important;
    text-align: center !important;
    vertical-align: middle !important;
    padding: 2px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    white-space: normal;
    word-wrap: break-word;
    border-right: 1px solid @borders-color * 1.07 !important;
    border-bottom: 1px solid @borders-color * 1.07 !important;

    .fa {
      font-size: calc(@font-size / 1.2) !important;
      white-space: nowrap;
    }
  }

  tr td.nowrap {
    white-space: nowrap !important;
  }

  td.alert-success {
    background: @status-success-background !important;
  }

  td.alert-danger {
    background: @status-danger-background !important;
  }

  tr {
    border-bottom: 1px solid @borders-color !important;
  }

  tbody {
    border: 0 !important;
  }

  tfoot {
    tr.highlighted-tr > td {
      background: @highlighted-area !important;
    }
  }

  .bigger-label.positive-number {
    font-size: @font-size !important;
    font-family: @font-family-regular !important;
  }

  .bigger-label.negative-number {
    font-size: @font-size !important;
    font-family: @font-family-regular !important;
  }

  .span-left {
    float: left !important;
  }

  .span-right {
    float: right !important;
  }

  .span-center {
    float: none !important;
  }

  .form-control {
    height: auto !important;
    font-size: @font-size;
    font-family: @font-family-semibold, 'FontAwesome' !important;
    line-height: 1.5;
    overflow: visible;
    white-space: normal;

    span {
      font-size: @font-size;
    }
  }

  tr td:first-child {
    text-align: left !important;

    .form-control {
      line-height: 1.5;
      font-family: @font-family-semibold 'FontAwesome' !important;

      span {
        font-size: @font-size;
        font-family: @font-family-semibold 'FontAwesome' !important;
      }
    }
  }

  .ui-select-bootstrap > .ui-select-match > .btn {
    line-height: 1 !important;
  }
}

.facts-edit-table {
  td.nowrap {
    white-space: nowrap;
  }

  tr td {
    padding: 2px !important;
    border-right: 1px solid @borders-color !important;

    input[type='checkbox'],
    input[type='radio'] {
      text-align: center !important;
      float: none !important;
      margin: 0 auto;
    }
  }
}
.fixed-width-column {
  width: 105px !important;
}
.crops-table-container {
  overflow-x: auto;
}

.manager-table-details {
  .label,
  .badge {
    font-size: @font-size;
  }

  tr td {
    font-size: @font-size;
  }

  .contract-description-label {
    margin-bottom: 0 !important;
  }
}

.manager-table {
  .clients-table;

  tr td {
    text-align: left !important;
  }

  .margin {
    text-align: center !important;
  }
}

.contract-charges-table {
  tr th {
    min-width: 30px;
    word-wrap: break-word !important;
  }

  tr td {
    padding: 2px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    vertical-align: middle !important;
    white-space: nowrap;
    font-size: calc(@font-size / 1.2) !important;

    .secondary-text {
      white-space: nowrap !important;
    }

    .secondary-text.wrap-text {
      white-space: normal !important;
    }

    .badge {
      margin-bottom: 2px;
      margin-top: 2px;
    }

    .open > .dropdown-menu {
      position: relative;
    }

    .label {
      display: inline-block;
      font-size: calc(@font-size / 1.2);
      padding: 4px !important;
      line-height: 1;
      margin: 0;
    }

    .fa-plus,
    .fa-minus {
      margin-right: 2px;
    }

    .fa {
      font-size: calc(@font-size / 1.4) !important;
    }

    ul {
      margin-bottom: 0;
    }

    .form-control {
      height: auto !important;
      font-family: @font-family-regular, 'FontAwesome' !important;
      overflow: visible;
      white-space: normal;
      width: 100% !important;
      max-width: 100% !important;
      border-color: darken(@borders-color, 23%) !important;

      span {
        font-size: calc(@font-size / 1.2);
      }
    }

    .alert-success .ui-select-container .form-control {
      border-color: darken(@borders-color, 23%) !important;
    }

    .ui-select-bootstrap .ui-select-choices-row > span {
      white-space: normal;
      min-width: 200px;
    }

    input[type='checkbox'] {
      margin: 0;
    }

    .badge-block {
      width: 100%;
      display: block;
      padding: 2px !important;
    }
  }

  td.minWidth {
    min-width: 120px !important;
  }

  .inside-table-ul {
    span.smaller-label {
      font-size: calc(@font-size / 1.3) !important;
    }

    li {
      text-align: left !important;
    }

    li:last-child {
      border-bottom: 0 !important;
    }
  }

  tr td.edit {
    background: @highlighted-area;

    .form-control .ui-select-match-text {
      font-family: @font-family-regular !important;
    }
  }

  tbody:nth-of-type(1) tr td.edit:nth-child(1) {
    border-left: 5px solid @contract-new-color !important;
  }

  tr td.nowrap {
    white-space: nowrap !important;
  }
}

ul.inside-table {
  margin-bottom: 2px !important;
  border-radius: 8px;
  background: darken(@highlighted-area, 23%);
  padding: 2px;
}

.contract-costs-table,
.contract-quality-table {
  tr td {
    font-size: calc(@font-size / 1.2);
    white-space: normal;
    vertical-align: middle !important;
    text-align: right;

    .label,
    label,
    .badge {
      font-size: calc(@font-size / 1.2);
    }

    .btn {
      line-height: 1;
      font-size: calc(@font-size / 1.2);
    }
  }

  tr td:first-child {
    font-size: calc(@font-size / 1.2);
    font-family: @font-family-regular !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
    color: @main-table-td;

    .fa-arrows {
      visibility: hidden;
    }
  }

  tr:hover {
    td:first-child {
      .fa-arrows {
        visibility: visible;
      }
    }
  }

  .label {
    width: auto !important;
  }

  .ui-select-bootstrap > .ui-select-match > .btn {
    line-height: 2 !important;
  }

  .form-control {
    height: 22px;
  }

  .modal-content .input-group-addon {
    background: @highlighted-area !important;
  }

  tr th.thick-border,
  tr td.thick-border {
    border-right: 3px solid @borders-color !important;
  }

  td.small-font-cell {
    font-size: calc(@font-size / 1.3) !important;

    a,
    span,
    .btn-link {
      font-size: calc(@font-size / 1.3) !important;
    }
  }
}

.contract-quality-table {
  .form-control {
    height: 20px;
  }

  .input-group-addon {
    padding: 2px !important;
    font-size: calc(@font-size / 1.3) !important;
  }

  .highlighted-tr {
    td {
      background: @payments-tab !important;
    }
  }

  tr td {
    white-space: normal !important;
  }
}

.prices-calc-table {
  tr td {
    text-align: left !important;

    .badge,
    .label {
      font-size: @font-size;
    }
  }
}

.booking-confirmations-table {
  tr th {
    white-space: normal;
  }

  tr td {
    white-space: normal;
    word-break: break-all;
    text-align: left !important;

    .btn {
      padding: 2px !important;
      padding-left: 3px !important;
    }

    .bigger-label {
      font-size: @font-size !important;
      font-family: @font-family-semibold !important;
    }
  }

  tr td:nth-child(1) {
    font-size: @font-size !important;
    font-family: @font-family-semibold;
    color: @contract-executed-color !important;

    .btn-link {
      font-family: @font-family-semibold;
      color: @btn-link-color !important;
      font-size: calc(@font-size / 1.3) !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  tr.highlighted-tr td {
    background: @color-black-opacity-dark !important;
  }

  tr.add-new-inline td {
    background: @status-success-background !important;
  }

  .ui-select-bootstrap > .ui-select-match > .btn,
  .form-control {
    height: 20px !important;
    line-height: 1 !important;
  }

  .child-padding-1 {
    padding-left: 25px !important;
  }

  .child-padding-2 {
    padding-left: 50px !important;
  }

  .child-padding-3 {
    padding-left: 75px !important;
  }

  .child-padding-n {
    padding-left: 100px !important;
  }

  .crops-edit-table .form-control .ui-select-match-text {
    padding-top: 0 !important;
  }

  .crops-edit-table .ui-select-match .clear-btn {
    padding-top: 2px !important;
  }
}

.table-with-fixed-columns {
  table-layout: initial !important;

  thead > tr th,
  tbody > tr td {
    width: 150px !important;
    min-width: 150px !important;
  }

  thead > tr th:nth-child(1),
  tbody > tr td:nth-child(1) {
    width: 300px !important;
    min-width: 300px !important;
  }
}

.debit-credit-table {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 10px;

  tr th {
    white-space: nowrap;
    padding: 2px !important;
    text-align: center !important;
    border-right: 1px solid @borders-color !important;

    .th-right-align {
      text-align: center !important;
    }
  }

  tr th.thick-border,
  tr td.thick-border {
    border-right: 1px solid @borders-color !important;
  }

  thead > tr > th,
  tbody > tr > td {
    width: 80px !important;
    min-width: 80px !important;
    overflow: hidden;
  }

  tr td {
    white-space: nowrap;
    padding: 2px !important;
    text-align: right !important;
    border-right: 1px solid @borders-color !important;
    font-size: calc(@font-size / 1.2);
    line-height: 1 !important;

    .alert {
      padding: 2px !important;
      margin: 0 !important;
    }

    .bigger-label,
    .label-strong,
    .label {
      font-family: @font-family-semibold !important;
      font-size: calc(@font-size / 1.2) !important;
    }

    .btn {
      padding: 2px !important;
      padding-left: 4px !important;
      margin: 0 !important;
      line-height: 1 !important;
      font-size: calc(@font-size / 1.2) !important;
    }

    h5 {
      padding: 0 !important;
      margin: 0 !important;
      display: inline;
      font-family: @font-family-regular !important;
      color: @contract-executed-color !important;
      font-size: calc(@font-size / 1.1) !important;
    }

    .label-no-padding-right {
      padding-right: 0 !important;
    }

    .label {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  tr th:nth-child(2),
  tr td:nth-child(2) {
    width: 150px !important;
    min-width: 150px !important;
  }

  tr th:nth-child(1),
  tr td:nth-child(1) {
    text-align: left !important;
    width: 150px !important;
    white-space: normal !important;

    .btn {
      font-size: calc(@font-size / 1.2) !important;
      font-family: @font-family-semibold !important;
      text-align: left !important;
      white-space: normal !important;
    }
  }

  tr th:last-child {
    border-right: 0 !important;
  }
}

.sticky-table-first-td,
table.sticky-table-first-td {
  tr > td {
    height: 30px;
    min-height: 30px;
    padding: 2px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;

    .label {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  thead > tr > th div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .contract-column {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .number-column {
    max-width: 195px;
    white-space: nowrap;
  }
  .number-column:hover::after {
    content: attr(data-fulltext);
    position: absolute;
    left: 0;
    top: 100%;
    white-space: nowrap;
    background: #fff;
    border: 1px solid #ddd;
    padding: 5px;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  tfoot > tr > td:first-child,
  tbody > tr > td:first-child,
  thead > tr > th:first-child {
    position: sticky;
    left: 0;
    z-index: 30;
    vertical-align: middle !important;
    background: @tables-background;
    min-width: 195px;

    .item-settings-dropdown,
    .initial-transform {
      white-space: nowrap;
      font-family: @font-family-regular;

      .fa {
        font-size: calc(@font-size / 1.1);
      }
    }
    .item-settings-dropdown > .dropdown-menu {
      z-index: 9999 !important;
    }
    .item-settings-dropdown {
      width: 93%;
    }
    .item-settings-dropdown.actions-btn {
      width: auto;
    }

    .label {
      margin-top: 10px !important;
    }
  }

  .contract_detail_table,
  .inside-table,
  .counterparty_detail_table {
    tbody > tr > td:first-child {
      position: relative;
      left: 0;
      width: 70px !important;
      display: table-cell;
      z-index: 0 !important;
    }
  }

  tbody > tr > td:first-child.no-data-row {
    position: relative;
    left: 0;
    width: auto !important;
  }

  thead > tr > th:first-child {
    background: @contract-errors-background !important;
    padding-bottom: 3px !important;
    padding-top: 3px !important;
    height: 51px;
    min-height: 51px;
  }

  tfoot > tr > td:first-child {
    padding-bottom: 3px !important;
    padding-top: 3px !important;
    height: 70px;
    min-height: 70px;
  }

  tr.highlighted-tr > td {
    padding-bottom: 3px !important;
    padding-top: 3px !important;
    height: 70px;
    min-height: 70px;
  }

  tr.sub-total-row.highlighted-tr > td {
    height: 30px !important;
    min-height: 30px !important;
  }

  tfoot {
    tr td {
      height: 70px;
      min-height: 70px;
      max-height: 70px;
      padding-bottom: 3px !important;
      padding-top: 3px !important;
    }
  }

  .navbar-nav > li > .dropdown-menu {
    z-index: 999;
    width: 100%;
  }

  table.basis-port-prices-table thead > tr > th:first-child,
  table.basis-port-prices-table tbody > tr > td:first-child {
    z-index: 0 !important;
  }
}

.sticky-table-first-td-condensed {
  .sticky-table-first-td;

  tr > td {
    height: 25px !important;
  }

  tbody > tr > td:first-child {
    .item-settings-dropdown {
      padding-top: 3px !important;
      margin-top: 0 !important;
    }

    .label,
    span {
      margin-top: 3px !important;
    }
  }
}

.sticky-table-first-td-ultra-condensed {
  .sticky-table-first-td;

  tr > td {
    height: 20px !important;
  }

  tbody > tr > td:first-child {
    .item-settings-dropdown {
      padding-top: 2px !important;
      margin-top: 0 !important;
    }

    .label,
    span {
      margin-top: 2px !important;
    }
  }
}

.sticky-table-first-td-ultra-condensed-tiny-column {
  margin-left: 148px !important;

  tfoot > tr > td:first-child,
  tbody > tr > td:first-child,
  thead > tr > th:first-child {
    position: absolute;
    left: 10px;
    width: 160px !important;
    white-space: normal;
  }
}

.sticky-table-first-td-expanded {
  .sticky-table-first-td;

  tr > td {
    height: 45px !important;
  }

  tbody > tr > td:first-child {
    .item-settings-dropdown {
      width: 70%;
      padding-top: 8px !important;
    }

    .label,
    span {
      margin-top: 8px !important;
    }
  }
}

.sticky-table-first-td-expanded-position {
  .sticky-table-first-td;
  margin-left: 140px !important;

  tr > td {
    height: 45px !important;
  }

  tbody > tr > td:first-child {
    left: 16px;
    width: 150px !important;

    .item-settings-dropdown {
      padding-top: 8px !important;
    }

    .label,
    span {
      margin-top: 8px !important;
    }
  }

  thead > tr > th:first-child {
    left: 16px;
    width: 150px !important;
  }
}

.sticky-table-first-second-third-td {
  .sticky-table-first-td-ultra-condensed;

  tfoot > tr > td:nth-child(1),
  tbody > tr > td:nth-child(1),
  thead > tr > th:nth-child(1) {
    position: absolute;
    left: 16px !important;
    width: 35px !important;
    z-index: 30;
    vertical-align: middle !important;
    background: @tables-background;

    .item-settings-dropdown {
      padding-top: 10px !important;
    }

    .label,
    span {
      margin-top: 10px !important;
    }
  }

  tfoot > tr > td:nth-child(2),
  tbody > tr > td:nth-child(2),
  thead > tr > th:nth-child(2) {
    position: absolute;
    left: 50px;
    width: 70px !important;
    z-index: 30;
    vertical-align: middle !important;
    background: @tables-background;

    .item-settings-dropdown {
      padding-top: 10px !important;
    }

    .label,
    span {
      margin-top: 10px !important;
    }
  }

  tfoot > tr > td:nth-child(3),
  tbody > tr > td:nth-child(3),
  thead > tr > th:nth-child(3) {
    position: absolute;
    left: 120px;
    width: 200px !important;
    z-index: 30;
    vertical-align: middle !important;
    background: @tables-background;

    .item-settings-dropdown {
      padding-top: 10px !important;
    }

    .label,
    span {
      margin-top: 10px !important;
    }
  }

  thead > tr > th:nth-child(1),
  thead > tr > th:nth-child(2),
  thead > tr > th:nth-child(3) {
    border-bottom: 0 !important;
  }
}

.sticky-table-first-second-td {
  .sticky-table-first-td-ultra-condensed;

  tfoot > tr > td:nth-child(1),
  tbody > tr > td:nth-child(1),
  thead > tr > th:nth-child(1) {
    position: absolute;
    left: 16px !important;
    width: 35px !important;
    z-index: 30;
    vertical-align: middle !important;
    background: @tables-background;

    .item-settings-dropdown {
      padding-top: 10px !important;
    }

    .label,
    span {
      margin-top: 10px !important;
    }
  }

  tfoot > tr > td:nth-child(2),
  tbody > tr > td:nth-child(2),
  thead > tr > th:nth-child(2) {
    position: absolute;
    left: 50px;
    width: 70px !important;
    z-index: 30;
    vertical-align: middle !important;
    background: @tables-background;

    .item-settings-dropdown {
      padding-top: 10px !important;
    }

    .label,
    span {
      margin-top: 10px !important;
    }
  }

  thead > tr > th:nth-child(1),
  thead > tr > th:nth-child(2) {
    border-bottom: 0 !important;
  }
}

.sticky-table-no-shadow {
  tbody {
    box-shadow: none !important;
  }
}

.sticky-block {
  position: fixed;
  z-index: 30;
  top: 40px;
  right: auto;
  left: auto;
  max-width: 1240px;
  margin: 0 auto;
}

.stations-table {
  table-layout: fixed;
  width: 100%;

  tr td {
    border-right: 1px solid @borders-color !important;
  }

  tr td.highlighted-td {
    background: darken(@highlighted-area, 5%) !important;
  }
}

.reassignment-table {
  tr td,
  tr th {
    text-align: left;
  }
}

.supplier-statistic-table table {
  .table-hover;

  tr th {
    white-space: nowrap;
    padding: 6px !important;
    text-align: center !important;
    border-right: 1px solid @borders-color !important;
  }

  tr td {
    white-space: nowrap;
    padding: 2px !important;
    text-align: right !important;
    border-right: 1px solid @borders-color !important;
    vertical-align: middle !important;
  }

  tr td.supplier-name {
    text-align: left !important;
    font-family: @font-family-bold !important;
    text-transform: uppercase;
    padding-left: 10px;
  }

  tr th:last-child {
    border-right: 0 !important;
  }

  tr td:first-child {
    input[type='checkbox'],
    input[type='radio'] {
      margin: 0 !important;
      width: 15px;
      height: 15px;
    }
  }
}

.additional-agreement-table {
  tr td {
    padding: 2px !important;
    white-space: nowrap;
    border-bottom: 0 !important;

    .form-control,
    input,
    .btn,
    .ui-select-bootstrap > .ui-select-match > .btn {
      font-size: calc(@font-size / 1.3);
      min-height: 22px !important;
    }

    input[type='checkbox'],
    input[type='radio'] {
      height: 22px !important;
      width: 22px !important;
    }
  }

  .fa-pencil-square {
    display: block !important;
  }

  tbody:nth-last-child(1) tr td {
    background: @highlighted-area;

    .fa.fa-pencil-square {
      display: none !important;
    }
  }

  tbody:nth-of-type(1) > tr td {
    background: transparent !important;
  }

  .additional_info {
    word-wrap: break-word;
    white-space: normal;
  }

  tbody {
    border-top: 0 !important;
  }

  tr {
    border-bottom: 1px solid @borders-color !important;
  }
}

.table-comments {
  td {
    color: @contract-executed-color;
  }

  td:first-child {
    width: 40%;
    color: @contract-executed-color;
  }

  .label {
    margin-bottom: 2px !important;
    display: inline-block;
    padding: 2px !important;
  }
}

.row_inactive > td {
  color: @contract-cancelled-color !important;
  opacity: 0.6;
}

.modal-content {
  .contract-costs-table {
    tr th {
      text-align: center;
    }
  }

  .btn-group > .btn:last-child:not(:first-child),
  .btn-group > .dropdown-toggle:not(:first-child) {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .btn-group > .btn {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background: @very-dark-blue;
    &::first-letter {
      text-transform: uppercase !important;
    }
  }

  .analytics-container {
    .btn-group > .btn {
      background: transparent !important;
    }
  }

  .modal-header {
    padding-bottom: 0 !important;
    background: @modal-header-background;

    .filter-template {
      background: @navbar-background;
      width: 100% !important;
      border-radius: 0 !important;
      border: 0 !important;
      box-shadow: none !important;

      .btn {
        border-right: 0 !important;
        padding: 6px 10px !important;
        font-size: @font-size !important;
        color: @white !important;
        background: @very-dark-blue;
        margin-right: 10px;

        .fa {
          font-size: calc(@font-size / 1.3) !important;
        }

        &:hover {
          color: @white !important;
          background: @very-dark-blue * 1.1;
        }
      }

      .btn-primary {
        border-top-left-radius: 0.25em !important;
        border-top-right-radius: 0.25em !important;
        color: @contract-executed-color !important;
      }
    }

    button {
      color: @contract-executed-color-alt;
      background-color: @label-default-color-border;
    }

    .label_default {
      text-transform: lowercase;
      background: transparent !important;
      font-size: calc(@font-size / 1.3) !important;
      color: @tables-background !important;

      .updated_time {
        text-transform: lowercase;
        background: transparent !important;
      }
    }
  }

  .modal-body {
    padding-top: 5px !important;
    padding-bottom: 300px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    background: @main-background;
    clear: both;
    display: block;
    padding-left: 35px !important;
    padding-right: 35px !important;

    .action-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .icon-padding {
        padding-top: 20px;
      }

      > div:first-child {
        padding-top: 0px;
      }

      button {
        position: relative;
        z-index: 1 !important;
      }

      .tooltip {
        z-index: 100 !important;
      }

      .tooltip:hover::before {
        z-index: 10000 !important;
      }
    }
  }

  .modal-title {
    margin-bottom: 10px;
    color: @tables-background;
  }

  .updated_time {
    color: @tables-background !important;
    margin-left: 15px;

    .label {
      display: inline-flex;
      padding: 6px !important;
    }
  }

  .ul-inline-row {
    background: @tables-background !important;
    .box-shadow-none;
  }

  .analytics-container,
  .table-container-wrapper {
    .box-shadow-none;
  }

  #log-properties-table {
    .extended-changed-fields {
      display: table-cell;
    }

    .changed-fields {
      display: none;
    }
  }

  .form-group .btn-default {
    background: transparent !important;
  }

  .input-group-addon {
    background: transparent !important;
  }

  .inset-container {
    .ul-inline-row,
    .table-container-wrapper {
      background: @tables-background !important;
    }
  }

  .docs-uploaded-span-small {
    font-size: calc(@font-size / 1.3) !important;
    color: @contract-executed-color !important;
  }
}

.client-filters-block {
  .tooltip:hover::before {
    width: auto !important;
    min-width: 100px;
    max-width: 150px;
    position: fixed;
    margin-top: 15px !important;
    margin-left: -100px;
    top: inherit;
    right: inherit;
    font-size: @font-size !important;
  }
}

.modal-update-table {
  tr th {
    text-align: left !important;
  }

  .form-control {
    .border-radius-4;
    border: 1px solid darken(@borders-color, 17%) !important;
    background: @highlighted-area !important;
  }

  .form-control:focus {
    border: 2px solid darken(@borders-color, 9%) !important;
  }
}

#contract-properties-activity {
  .modal-log-table {
    width: 100% !important;
  }

  .btn-link {
    font-size: calc(@font-size / 1.3) !important;
  }

  .container {
    width: 100% !important;
  }

  .center-block {
    padding: 0;
  }

  .filter-template {
    .btn {
      padding: 2px 1px !important;
    }
  }
}

#report-confirm-table {
  overflow-x: auto;

  td:last-child {
    background: transparent !important;
  }

  td:first-child,
  th:first-child {
    text-align: left !important;
  }

  tr th {
    border-right: 1px solid @borders-color !important;
    word-wrap: break-word;
    white-space: normal;
    vertical-align: top;
  }

  tr td {
    text-align: center;
    cursor: default;
    border-right: 1px solid @borders-color !important;
    padding: 2px !important;
    padding-left: 4px !important;

    .label {
      padding: 2px !important;
    }

    .btn-link {
      padding: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

#report-traders-table {
  width: 100% !important;
  margin-bottom: 10px !important;

  .main-table {
    width: 100% !important;
  }

  td:nth-child(3) {
    text-align: left !important;
    background: transparent !important;
  }

  tr td {
    font-size: @font-size;
    text-align: left !important;
    padding: 2px !important;
    color: @contract-executed-color !important;

    .label {
      padding: 2px !important;
      display: inline-block;
      font-size: calc(@font-size / 1.3);
    }
  }

  tr th {
    text-transform: uppercase;
    text-align: left;
    font-size: calc(@font-size / 1.3);
  }

  tr th:nth-child(3) {
    background: @color-black-opacity !important;
  }

  tr td:nth-child(1) {
    font-family: @font-family-regular !important;
    text-transform: initial !important;
  }

  tr td:nth-child(3) {
    text-transform: uppercase !important;
    background: @color-black-opacity !important;
    vertical-align: middle;

    span {
      font-size: 0.7em;
    }

    .btn-link {
      font-family: @font-family-bold !important;

      .fa {
        font-size: calc(@font-size / 1.3);
      }
    }
  }
}

#cpt-prices-properties-table {
  tr td {
    text-align: right !important;
    border-color: @borders-color !important;
  }

  tr th {
    text-align: right !important;
  }

  tr td:first-child {
    text-align: left !important;
  }

  tr th:first-child {
    text-align: left !important;
  }
}

#log-properties-table {
  .table-hover;
  width: 100% !important;
  margin-top: 5px;

  tr th {
    cursor: pointer;
    padding-left: 15px !important;
    padding-right: 15px !important;
    text-align: left;
  }

  tr th:hover {
    background: @color-black-opacity;
  }

  .label {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-size: calc(@font-size / 1.2) !important;
    max-width: 200px !important;
    display: inline-block;
  }

  td {
    color: @contract-executed-color;
    text-align: left;
    font-size: calc(@font-size / 1.2) !important;
    padding: 4px !important;
    vertical-align: middle;
    line-height: 1.2 !important;

    .btn-link {
      padding: 0;
    }
  }

  .event-td {
    text-align: left;

    .label_success {
      max-width: 400px;
      white-space: normal;
    }

    .label_danger {
      max-width: 400px;
      white-space: normal;
      text-decoration: line-through;

      span {
        color: @label-danger-font-color !important;
      }
    }

    ul {
      margin: 0 !important;
    }

    &:hover {
      .label_danger {
        text-decoration: none;
      }
    }
  }

  .extended-changed-fields {
    display: none;
  }

  td:nth-child(5) {
    white-space: normal !important;
  }

  th:nth-child(5) {
    text-align: center;
  }

  tr:last-child {
    td:last-child {
      text-align: right !important;
    }
  }

  .action_addition > td {
    background: @action-addition;
    color: @contract-new-color;
    border-radius: 0 !important;
  }

  .action_delete > td {
    background: @action-delete;
    color: @label-danger-color !important;
    border-radius: 0 !important;
  }

  .action_process > td {
    background: @action-process;
    color: @label-warning-color !important;
    border-radius: 0 !important;
  }
}

#update-properties-table {
  .clients-table;
  .table-hover;
  width: 100% !important;

  tr th {
    text-align: center !important;
    vertical-align: middle;
    white-space: nowrap !important;
    padding: 6px;
    border-right: 0 !important;
    font-size: calc(@font-size / 1.2) !important;
    height: auto !important;
  }

  tr td {
    text-align: center !important;
    vertical-align: middle;
    padding: 6px !important;
    border-right: 0 !important;
    overflow-x: visible !important;
    color: @contract-executed-color !important;
    letter-spacing: @letter-spacing-huge;

    .fa {
      margin-right: 0 !important;
    }

    .btn-link {
      padding: 0 !important;
    }

    .bigger-label {
      .fa {
        font-size: calc(@font-size * 1.1) !important;
      }
    }
  }

  tr td.message {
    white-space: normal !important;
    word-break: break-word !important;
    font-size: @font-size !important;
  }

  tr td.related {
    white-space: nowrap !important;
  }

  .inside-table-btn {
    text-transform: uppercase !important;
    font-family: @font-family-bold !important;
  }

  .form-control {
    font-size: calc(@font-size / 1.3);
  }

  .form-group {
    margin: 0;
    padding: 0;

    .col-sm-12 {
      margin: 0;
      padding: 0;
    }
  }

  .update_time {
    white-space: nowrap !important;
    font-family: @font-family-regular !important;
    text-transform: inherit !important;
  }

  select {
    font-size: @font-size;
    margin-top: 2px;
    color: @contract-executed-color;
    width: 100%;
  }
}

.updates-inside-table {
  #update-properties-table {
    .update_time {
      font-size: calc(@font-size / 1.3) !important;
      background: transparent !important;
      font-family: @font-family-regular !important;
    }

    tr td:nth-last-child(1),
    tr td:nth-last-child(2),
    tr td:nth-last-child(3) {
      display: none;
    }

    tr th:nth-last-child(1),
    tr th:nth-last-child(2) {
      display: none;
    }

    tr td {
      font-size: @font-size !important;
      text-transform: initial;
      background: transparent !important;

      .fa {
        font-size: calc(@font-size / 1.3) !important;
      }
    }
  }
}

#crop-properties-table {
  tr td {
    vertical-align: middle !important;
    white-space: nowrap !important;
  }

  tr td:nth-child(2) {
    .form-control {
      font-size: calc(@font-size * 1.4) !important;
    }
  }
}

#person-properties-table {
  th {
    text-transform: uppercase;
    text-align: left;
    font-size: calc(@font-size / 1.2) !important;
  }

  .label {
    max-width: 250px;
    display: inline-flex;
    width: auto !important;
    padding: 2px !important;
    font-size: calc(@font-size / 1.3) !important;

    .fa {
      margin-right: 2px;
    }
  }

  td {
    text-align: left !important;
    padding: 2px !important;
  }
}

.task-table {
  margin-bottom: 0px;
}

#task-properties-table {
  th {
    text-transform: uppercase;
    text-align: center;
  }

  .label {
    display: inline-block;
    padding: 2px !important;
    font-size: calc(@font-size / 1.3) !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .task_type_label {
    font-size: calc(@font-size * 1.5) !important;
    cursor: pointer;
  }

  .task_type_label:hover {
    opacity: 0.8;
  }

  .label-date {
    border: 0 !important;
  }

  td {
    color: @contract-executed-color;
    overflow-x: hidden;
    white-space: normal !important;
    word-break: normal !important;
    text-align: left !important;
    vertical-align: middle;
    width: 70%;
    padding: 2px !important;
  }

  td:first-child {
    text-align: left;
    width: 30% !important;
  }

  .td-commentary,
  .td-response {
    word-wrap: break-word;
    max-width: 100px;
  }

  .add_text {
    font-size: calc(@font-size / 1.3);
    vertical-align: super;
  }

  .status_new > .task-status {
    background: @action-addition;
    color: @contract-new-color;
    border-radius: 0 !important;
  }

  .status_process > .task-status {
    background: @action-process;
    color: @contract-processing-color !important;
    border-radius: 0 !important;
  }

  .status_review > .task-status {
    background: @action-approved;
    color: @label-calculate-color-border !important;
    border-radius: 0 !important;
  }

  .status_failed > .task-status {
    background: @action-delete;
    color: @label-danger-color !important;
    border-radius: 0 !important;
  }

  .status_executed > .task-status {
    background: @action-executed;
    color: @contract-executed-color !important;
    border-radius: 0 !important;
  }

  td:nth-child(2) {
    background: @color-black-opacity !important;
  }

  .status_new td:nth-child(2) {
    background: @action-addition-alt !important;
  }

  .status_process td:nth-child(2) {
    background: @action-process-alt !important;
  }

  .status_review td:nth-child(2) {
    background: @action-approved !important;
  }

  .status_failed td:nth-child(2) {
    background: @action-delete !important;
  }
}

.task-block,
.task-item-container {
  .ul-inline-row {
    .border-radius-4;
    margin-bottom: 5px !important;
    padding: 6px !important;
    width: 100%;
    overflow-x: hidden;
    .box-shadow-light;
  }

  tr td {
    font-size: calc(@font-size / 1.3);
    vertical-align: middle !important;
    color: @contract-executed-color !important;
    border-top: 0 !important;
    white-space: nowrap;

    .btn-default {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  tr td.comments {
    white-space: normal;
    word-wrap: break-word;
    font-family: @font-family-semibold !important;
    max-width: 200px;
    min-width: 100px;
  }

  tr td.task-type {
    min-width: 10em;
    max-width: 10em;
    white-space: normal;
    word-wrap: break-word;
  }

  .label {
    display: inline-block;
    font-size: calc(@font-size / 1.3) !important;
  }

  .smaller-label {
    font-size: calc(@font-size / 1.3) !important;
    text-align: center !important;
    margin: 0 auto !important;
    margin-top: 5px !important;
    letter-spacing: @letter-spacing-huge;
  }

  .task-update-time {
    font-size: calc(@font-size / 1.3) !important;
    font-family: @font-family-bold !important;
  }

  .bigger-label {
    font-family: @font-family-bold !important;
  }

  .status_new {
    .task-status {
      background: @action-addition-alt !important;
      color: @contract-new-color !important;
      text-align: center !important;
    }

    tr td {
      background: @action-addition !important;
      color: @contract-new-color !important;
      border-color: @action-addition-alt !important;
    }
  }

  .status_process {
    .task-status {
      background: @action-process-alt !important;
      color: @contract-processing-color !important;
      text-align: center !important;
    }

    tr td {
      background: @action-process !important;
      color: @contract-processing-color !important;
      border-color: @action-process-alt !important;
    }
  }

  .status_review {
    .task-status {
      background: @action-approved !important;
      color: @label-calculate-color-border !important;
      text-align: center !important;
    }

    tr td {
      background: @action-approved !important;
      color: @label-calculate-color-border !important;
      border-color: @action-approved-alt !important;
    }
  }

  .status_failed {
    .task-status {
      background: @action-delete !important;
      color: @label-danger-color !important;
      text-align: center !important;
    }

    tr td {
      background: @action-delete !important;
      color: @label-danger-color !important;
      border-color: @action-delete !important;
    }
  }

  .status_executed {
    .task-status {
      background: @action-executed !important;
      color: @contract-executed-color !important;
      text-align: center !important;
    }

    tr td {
      background: @tables-background !important;
      color: @contract-executed-color !important;
      border-color: @action-executed;
    }
  }
}

#logistics-properties-table {
  margin-top: 10px;
  margin-bottom: 10px;

  tr td {
    padding: 2px;
  }
}

#purchase-properties-table {
  width: 100% !important;
  margin-top: 0px;
  margin-bottom: 0px;

  tr td:first-child {
    text-align: left;
    border-color: @borders-color !important;
    text-transform: uppercase;
    font-family: @font-family-bold !important;
  }

  tr td {
    text-align: right;
    border-color: @borders-color !important;
    padding: 2px !important;
    font-size: @font-size;
  }

  tr:first-child td {
    border: none !important;
  }

  .label {
    font-size: @font-size;
  }
}

.indicators-table {
  .clients-table;

  tr td {
    text-align: left !important;
    padding: 2px !important;
    vertical-align: middle !important;
    font-size: @font-size !important;
  }

  .user-dropdown-btn {
    font-size: @font-size !important;
  }

  .item-settings-dropdown .dropdown-menu {
    left: calc(100% - 8px) !important;
  }
}

.offers-table {
  width: 100% !important;

  .sortorder:after {
    content: '\25b2';
  }

  .sortorder.reverse:after {
    content: '\25bc';
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  tr td {
    white-space: nowrap;
    width: auto;

    .input-group {
      margin-bottom: 2px;
    }

    .form-control {
      padding: 2px !important;
      height: 25px;
      min-width: 70px;
    }

    .label {
      text-align: left;
      padding: 2px;
    }
  }

  .hover-td {
    &:hover {
      background: @color-black-opacity;
      cursor: pointer;
    }
  }

  .currency-group {
    display: block !important;

    .form-control:last-child {
      width: 100%;
      display: block;
    }
  }

  td.highlighted {
    background: @color-black-opacity !important;
    border-right: 1px solid @borders-color !important;
    border-left: 1px solid @borders-color !important;
    border-radius: 0 !important;
  }

  td:first-child {
    .btn-link {
      font-family: @font-family-bold !important;
    }
  }
}

.places-table {
  tr td {
    white-space: nowrap;
  }

  .inner-span {
    font-size: @font-size !important;
    font-family: @font-family-regular !important;
  }
}

.competitors-table {
  table {
    width: 100% !important;
  }

  tr td {
    white-space: nowrap;
    text-align: left;
    border-right: 1px solid @borders-color !important;
    padding: 2px !important;
    min-width: 150px;
  }

  tr td.highlighted {
    background: @highlighted-area !important;
    border-right: 0 !important;
    border-top: 2px solid @borders-color !important;
    border-bottom: 2px solid @borders-color !important;
    font-family: @font-family-bold !important;

    .fa-bookmark {
      display: none;
    }
  }

  tr td:nth-child(1),
  tr td:nth-child(2) {
    padding: 2px !important;
    padding-right: 6px !important;
    padding-left: 6px !important;
  }

  tr td:nth-child(1) {
    border-right: 0 !important;
    min-width: 200px;
  }

  .alert-success {
    font-family: @font-family-bold !important;
  }

  .with-comment {
    border: dashed @brown 1px;
    display: block;
  }
}

.places-calc-table {
  tr td {
    padding: 2px !important;
    vertical-align: top !important;

    .label {
      display: block !important;
      max-width: 500px !important;
    }

    tr td:first-child {
      vertical-align: middle !important;
    }
  }
}

.warehouse-table {
  .clients-table;

  .label,
  .badge {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
    padding: 2px !important;
    font-size: @font-size !important;
  }

  .region-td {
    max-width: 200px !important;
    overflow-x: auto !important;
  }

  .item-settings-dropdown {
    float: left !important;
  }

  .item-settings-dropdown .dropdown-menu {
    left: 0 !important;
  }

  tr td {
    border-right: 1px solid @borders-color !important;
    vertical-align: middle !important;
    text-align: left !important;
    font-size: @font-size !important;

    .btn-link {
      text-align: left !important;
      float: left;
      font-size: @font-size !important;
      padding: 0;

      .label {
        font-size: @font-size !important;
      }
    }
  }

  tfoot {
    tr td {
      font-size: @font-size !important;

      .label {
        font-size: @font-size !important;
      }
    }
  }

  tr th {
    border-right: 1px solid @borders-color !important;
    overflow-x: hidden !important;
    text-align: center !important;
  }

  tr th:last-child {
    border-right: 0 !important;
  }

  tr td:nth-child(1) {
    vertical-align: middle !important;
    text-align: left;
  }
}

.warehouse-trade-table {
  table-layout: fixed;

  tr td {
    .label-strong {
      font-family: @font-family-semibold !important;
    }

    .bigger-label {
      font-size: @font-size !important;
      font-family: @font-family-semibold !important;

      .btn {
        padding: 0;
      }
    }

    border-right: 1px solid @borders-color !important;
  }
}

.contract-status-table {
  .highlighted-td {
    font-family: @font-family-semibold !important;
  }

  thead {
    tr > th {
      background: @tables-background !important;
      border-right: 0 !important;
    }
  }

  tbody {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  tfoot {
    tr.highlighted-tr > td {
      background: @highlighted-area !important;
    }
  }
}

.new-statistics-table {
  table-layout: fixed;

  tr th div {
    padding: 0 !important;
    font-size: calc(@font-size / 1.3) !important;
  }

  tbody > tr > td {
    height: 15px !important;
  }

  tbody > tr.sub-total-row.highlighted-tr > td {
    height: 15px !important;
    min-height: 15px !important;
  }

  tbody > tr > td:nth-child(1) {
    padding: 0 !important;
  }

  tr > td,
  tr > th {
    width: 150px !important;
    padding: 2px !important;
    vertical-align: middle !important;
    font-size: @font-size !important;
    border-right: 1px solid @borders-color !important;

    .fa {
      font-size: calc(@font-size / 1.3) !important;
    }

    .label-strong {
      font-family: @font-family-semibold !important;
    }

    .bigger-label {
      font-size: @font-size !important;
      font-family: @font-family-semibold !important;
    }
  }

  tr th {
    font-size: calc(@font-size / 1.3) !important;
    border-right: 0 !important;
  }

  tr td:nth-child(1),
  tr th:nth-child(1) {
    width: 350px !important;
    left: 15px !important;
    white-space: normal;
    vertical-align: middle !important;

    .btn {
      font-family: @font-family-semibold !important;
    }

    .label {
      padding: 2px !important;
    }

    a {
      font-size: @font-size !important;
      padding: 0 !important;
      padding-left: 5px !important;
    }

    a.btn-xs pull-right {
      margin-top: 7px !important;
    }

    input {
      margin: 2px;
      height: 15px;
      width: 15px;
    }
  }

  tr td:nth-child(2),
  tr th:nth-child(2) {
    width: 150px !important;
    white-space: normal;
  }

  tr td:nth-child(3),
  tr th:nth-child(3) {
    width: 150px !important;
  }

  tbody + tbody {
    border-top: 1px solid @borders-color !important;
  }

  .highlighted-tr {
    td {
      background: @highlighted-area !important;
      border-bottom: 0 !important;
    }
  }

  .total-row {
    td {
      .bigger-label {
        font-size: @font-size !important;
      }
    }
  }

  .sub-total-row {
    td {
      .bigger-label {
        font-size: calc(@font-size / 1.3) !important;
      }
    }
  }
}

.statistics-nav-tabs {
  .nav-tabs li a {
    font-size: calc(@font-size * 1.1) !important;
    padding: 6px !important;
  }
}

.consolidated-contracts-table {
  th {
    text-align: center !important;
  }

  th:last-child,
  td:last-child {
    background: @highlighted-area !important;
  }

  td {
    font-size: 0.9em;
    text-align: center !important;
  }

  tr:last-child {
    font-size: calc(@font-size / 1.3);
  }

  td:nth-child(2) {
    word-break: break-all;
    width: 8%;
  }

  td:nth-child(3) {
    word-break: break-word;
    width: 15%;
  }

  tr:hover {
    text-decoration: none !important;
    cursor: pointer;

    td {
      opacity: 1 !important;
      text-decoration: none !important;
    }
  }

  .label {
    font-size: 0.9em;
    text-align: center !important;
  }
}

.email-template-table {
  th {
    text-align: left !important;
  }

  td {
    color: @contract-executed-color;
    text-align: left !important;
    padding: 10px !important;
    padding-left: 10px !important;
    white-space: normal;
    word-break: break-all;

    input[type='checkbox'],
    input[type='radio'] {
      margin: 0 !important;
      width: 25px;
      height: 25px;
    }

    .fa {
      font-size: @font-size !important;
    }
  }

  td:last-child {
    text-align: right !important;
  }
}

.email-modal {
  .ul-inline-row {
    padding: 0 !important;
    background: transparent !important;
  }

  .gt_test_wrapper_subject {
    textarea.form-control {
      min-height: 60px !important;
    }
  }

  .gt_test_wrapper_body {
    textarea.form-control {
      min-height: 400px !important;
    }
  }

  .input-group {
    width: 100%;
  }

  .form-group-container textarea.form-control.body {
    min-height: 400px;
  }

  .copy-clipboard-btn {
    display: none !important;
  }
}

.tags-list {
  li {
    display: inline-block !important;
    margin: 2px;

    .label {
      padding: 2px !important;
    }
  }
}

.fixing-hedging-report {
  .inside-table-ul {
    margin-top: 0 !important;
  }
  thead {
    display: none;
  }

  .table-inside-table {
    margin-bottom: 0;
    margin-top: 0;
    table-layout: fixed;
    thead {
      display: none;
    }
    tr th {
      font-size: calc(@font-size / 1.2) !important;
      padding: 2px !important;
    }
    tr td {
      text-align: left !important;
      font-size: calc(@font-size / 1.2) !important;
      word-wrap: break-word;
      white-space: normal;
      border-bottom: 1px solid @borders-color !important;
      border-left: 0 !important;
      background: @tables-background;
      padding: 2px !important;
      .inside-table-ul li {
        padding: 0;
        border-bottom: 0 !important;
        white-space: nowrap;
        font-size: calc(@font-size / 1.1) !important;
      }
    }
    tr.with-no-derivative td {
      background-color: #fffee0;
    }
  }
  tbody + tbody {
    border-top: 0;
  }

  tr:nth-child(1) {
    .table-inside-table {
      thead {
        display: table-header-group;
      }
    }
  }

  tr td {
    padding: 6px !important;
    vertical-align: middle !important;
    white-space: nowrap;
    text-align: left !important;
    font-size: calc(@font-size / 1.2) !important;
    border-top: 0 !important;
    .label {
      display: inline-flex;
    }
  }
  .inside-table-ul li {
    padding: 1px;
    border-top: 0 !important;
    white-space: nowrap;
    font-size: calc(@font-size / 1.2) !important;
  }
}

.statistics-report-table {
  border-bottom: 1px solid @borders-color !important;

  tbody {
    border-bottom: 0 !important;
  }

  h5,
  h6 {
    font-family: @font-family-regular !important;
    color: @contract-executed-color !important;
    font-size: calc(@font-size / 1.1) !important;
    margin: 0;
  }

  tbody + tbody {
    border-top: 1px solid @borders-color !important;
  }

  ul {
    margin-bottom: 0;
  }

  .no-border {
    tr td {
      border-top: 0 solid @borders-color !important;
    }
  }

  .super-highlighted-tr {
    background: darken(@highlighted-area, 5%) !important;

    td {
      background: darken(@highlighted-area, 5%) !important;
      border-right: 1px solid darken(@highlighted-area, 9%) !important;
      font-family: @font-family-semibold !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      .bigger-label {
        font-size: calc(@font-size / 1.1) !important;
      }
    }

    span {
      font-family: @font-family-semibold !important;
    }
  }

  .mega-highlighted-tr {
    background: darken(@highlighted-area, 9%) !important;

    td {
      background: darken(@highlighted-area, 9%) !important;
      border-right: 1px solid darken(@highlighted-area, 13%) !important;
      font-family: @font-family-bold !important;
      white-space: nowrap !important;
      overflow: hidden !important;
    }

    span {
      font-family: @font-family-bold !important;
    }
  }

  thead > tr > th {
    text-transform: capitalize !important;
  }

  tbody > tr > td,
  thead > tr > th {
    vertical-align: middle !important;
    width: 120px !important;
    min-width: 120px !important;
    max-width: 120px !important;
    white-space: normal !important;
    padding: 2px;
  }

  tbody > tr > td:nth-child(1),
  thead > tr > th:nth-child(1) {
    width: 180px !important;
    min-width: 180px !important;
    max-width: 180px !important;
    white-space: normal !important;
  }

  tbody > tr > td.passport,
  thead > tr > th.passport {
    width: 140px !important;
    min-width: 140px !important;
    max-width: 140px !important;
    white-space: normal !important;
  }

  tbody > tr > td.dates,
  thead > tr > th.dates {
    width: 120px !important;
    min-width: 120px !important;
    max-width: 120px !important;
    white-space: nowrap !important;
  }

  tbody > tr > td.product,
  thead > tr > th.product {
    width: 90px !important;
    min-width: 90px !important;
    max-width: 90px !important;
    white-space: normal !important;
  }

  tbody > tr > td.counterparty,
  thead > tr > th.counterparty {
    width: 120px !important;
    min-width: 120px !important;
    max-width: 120px !important;
    white-space: normal !important;
  }

  tbody > tr > td.price,
  thead > tr > th.price {
    width: 90px !important;
    min-width: 90px !important;
    max-width: 90px !important;
    white-space: normal !important;
  }
}

// ALL MAIN TABLES TEMPLATES
.farm-table {
  a {
    text-align: left;
    white-space: nowrap;
  }

  .main-table-template;
  width: 100% !important;

  tr th:nth-last-child(4) {
    background: @color-black-opacity;
  }

  tr th:nth-last-child(2),
  tr th:nth-last-child(3) {
    background: @color-black-opacity;
    border-radius: 0 !important;
    text-align: right;
  }

  tr td:nth-last-child(1) {
    width: 15%;
  }

  tr td:nth-last-child(4) {
    background: @color-black-opacity;

    .label {
      white-space: nowrap;
      max-width: 140px;
    }
  }

  tr td:nth-last-child(5) {
    width: 10%;
  }

  tr td:nth-last-child(2),
  tr td:nth-last-child(3) {
    background: @color-black-opacity;
    border-radius: 0 !important;
    width: 8%;
    text-align: left;

    .label {
      text-align: left !important;
      white-space: nowrap;
    }
  }

  tr td:first-child {
    text-align: left !important;
    word-wrap: normal;
    white-space: normal;
    word-break: break-word;
    color: @contract-executed-color !important;
    width: 10%;

    .label {
      white-space: nowrap !important;
    }
  }

  tr td:nth-child(2) {
    .label {
      width: 100%;
    }

    label {
      font-size: calc(@font-size / 1.3) !important;
    }
  }
}

.logistics-table {
  .table-hover;
  .main-table-template;

  tr > th {
    border-right: 1px solid @borders-color !important;
    border-color: @borders-color !important;
    background: @tables-background !important;
    min-width: 40px;
    border-bottom: 0 !important;
  }

  tr th:nth-child(1),
  tr th:nth-child(2) {
    background: @tables-background !important;
  }

  tr th:last-child {
    border-right: 0 !important;
  }

  tr td {
    padding: 2px !important;
    border-top: 1px solid @borders-color !important;
    border-right: 1px solid @borders-color !important;
    text-align: left !important;
    font-size: @font-size !important;
    white-space: nowrap !important;

    .open > .dropdown-menu {
      position: relative;
    }

    .btn-blue-border,
    .btn-orange-border {
      padding: 1px;
      font-size: calc(@font-size / 1.4);
    }

    .label {
      font-size: calc(@font-size / 1.1) !important;
    }

    .form-control {
      padding: 2px !important;
      height: 24px !important;
      min-height: 24px !important;
      font-size: @font-size !important;
      min-width: 85px !important;
      text-align: left;
    }

    input[type='checkbox'] {
      height: 14px !important;
    }

    .smaller-label.label-strong {
      margin-left: 10px;
    }
  }

  tr td:nth-child(1) {
    font-size: calc(@font-size / 1.1) !important;
    color: @main-table-td !important;
    font-family: @font-family-semibold !important;

    span {
      font-family: @font-family-regular !important;
    }

    .btn-xs {
      font-size: calc(@font-size / 1.4) !important;
      line-height: 1;
    }
  }

  .exporter_set {
    span {
      display: block;
      line-height: 1;
    }
  }

  td.td-right-align {
    text-align: right !important;
  }

  td.td-center-align {
    text-align: center !important;
  }

  tr td:first-child {
    word-break: break-all;
    white-space: normal;

    .btn-link {
      text-transform: initial !important;
    }
  }

  .label {
    white-space: nowrap !important;
    padding: 2px !important;
  }

  .label-opacity {
    background: transparent !important;
    z-index: 998;

    .smaller-label {
      display: none;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .counterparty_detail_table {
    tr td {
      font-size: calc(@font-size / 1.4) !important;
      padding: 2px !important;
      font-family: @font-family-regular !important;
    }
    tr td:nth-child(1) {
      min-width: auto !important;
      font-family: @font-family-regular !important;
    }
    tr:nth-last-child(1) td {
      background: transparent !important;
      font-family: @font-family-regular !important;
    }
  }

  tr th {
    font-size: calc(@font-size / 1.3) !important;
    z-index: 998;

    .form-control {
      padding: 2px !important;
      height: 22px !important;
      font-size: @font-size !important;
      min-width: 75px !important;
    }

    .text-muted {
      font-size: @font-size !important;
    }
  }

  tr th.filter-th {
    border: 0 !important;
    border-bottom: 0 !important;
    background: @tables-background !important;

    .ui-select-choices-row {
      div {
        text-align: left !important;
      }
    }
  }

  tr:hover th.filter-th {
    background: @tables-background !important;
  }

  th.filter-th:hover {
    background: @tables-background !important;
  }

  .item-settings-dropdown a:nth-of-type(1) .fa-cog {
    font-size: @font-size !important;
  }

  input[type='checkbox'],
  input[type='radio'] {
    margin: 0 !important;
    width: 14px;
    height: 14px;
    margin-right: 5px !important;
  }

  .highlighted-td {
    background: @highlighted-area !important;
    border-right: 1px solid @borders-color !important;
    border-left: 1px solid @borders-color !important;
  }

  .highlighted-td.danger-highlighted {
    background: @status-danger-background !important;
  }

  .highlighted-td.warning-highlighted {
    background: @status-exclamation-background !important;
  }

  .highlighted-td.success-highlighted {
    background: @status-success-background !important;
  }

  .ag-header-cell {
    font-size: calc(@font-size / 1.3) !important;
    padding: 2px !important;
    font-family: @font-family-bold !important;
    border-right: 1px solid @borders-color;

    .ag-header-cell-text {
      white-space: normal;
    }
  }

  .ag-cell {
    font-size: calc(@font-size / 1.3) !important;
    color: @main-table-td !important;
    word-wrap: break-word;
    white-space: normal;
    font-family: @font-family-regular !important;
    padding: 2px !important;

    .fa {
      font-size: calc(@font-size / 1.4) !important;
    }
  }
}

.logistic-connect-document-input,
label:hover .logistic-connect-document-input,
label:focus .logistic-connect-document-input {
  width: 100% !important;
  background: darken(@alt-background, 9%) !important;
  .border-radius-4;
  padding: 5px !important;
  font-size: calc(@font-size / 1.3) !important;
  border: 1px dashed @main-font-color !important;
  margin-top: 1px;
  margin-bottom: 1px;
}

input.logistic-connect-document-input:hover,
input.logistic-connect-document-input:focus {
  background: darken(@alt-background, 17%) !important;
  border: 2px dashed @main-font-color !important;
  margin-top: 0px;
  margin-bottom: 0px;
}

logistics-documents-container {
  .logistic-connect-document-input::file-selector-button {
    white-space: normal;
    width: 100%;
  }

  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }

  .display-flex-card {
    height: calc(100% + 4px);
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding: 4px;
    white-space: normal;
    word-wrap: break-word;
  }

  .align-heading {
    text-align: center;
  }
}

.connect-documents-checkbox {
  width: 20px !important;
  height: 20px !important;
  margin: 0 !important;
}

.table-with-fixed-column {
  td:nth-child(1),
  th:nth-child(1),
  td:nth-child(2),
  th:nth-child(2) {
    position: absolute;
    border-radius: 0 !important;
    background: @highlighted-area !important;
    border-color: darken(@highlighted-area, 9%) !important;
    z-index: 999 !important;
    width: 190px;
    line-height: 1.4 !important;

    span {
      font-family: @font-family-regular !important;
      text-transform: initial !important;
    }
  }

  thead {
    background: @tables-background !important;
  }

  th:nth-child(1),
  th:nth-child(2) {
    height: 60px !important;
    padding-top: 20px !important;
    border-color: @borders-color !important;
  }

  tr:nth-last-child(1) {
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3) {
      line-height: 3 !important;
    }
  }

  td:nth-child(2),
  th:nth-child(2) {
    margin-left: 190px !important;
  }

  tr:hover td:nth-child(1),
  tr:hover td:nth-child(2) {
    background: @highlighted-area !important;
  }

  td:nth-child(3),
  th:nth-child(3) {
    margin-left: 380px;
    display: block;
  }

  th:nth-child(3) {
    margin-top: 6px;
  }

  tr > td .table-edit-btn {
    padding: 2px !important;
    font-size: calc(@font-size / 1.3) !important;

    .fa {
      font-size: calc(@font-size / 1.3) !important;
    }
  }
}

.planning-table {
  margin-bottom: 10px !important;
  .main-table-template;

  .label {
    font-size: calc(@font-size / 1.2) !important;
    white-space: nowrap;
  }

  .item-details-block-transparent .item-details-el {
    float: none;
    margin: 0 auto;
  }

  tr th {
    text-align: middle !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    font-size: calc(@font-size / 1.3) !important;
  }

  tr th div {
    padding: 0 !important;
  }

  tr td:nth-child(1) span {
    white-space: normal !important;
    word-wrap: break-word !important;
    text-align: left !important;
  }

  .label-opacity {
    background: transparent !important;
    z-index: 998;
  }

  .inside-table-ul {
    margin-bottom: 0;
    padding: 0;

    li {
      display: block;
      width: 100%;
      clear: both;
      height: 15px;
      padding: 2px;
      text-align: left;
      font-size: calc(@font-size / 1.3) !important;

      .label {
        padding: 2px !important;
        font-size: calc(@font-size / 1.3) !important;
      }
    }

    li:last-child {
      border-bottom: 0 !important;
    }
  }

  .ul-inside-table {
    margin-bottom: 0;

    li {
      display: block;
      width: 100%;
      height: 15px;
      padding: 2px;
      clear: both;
      font-size: calc(@font-size / 1.3) !important;

      .label {
        padding: 2px !important;
        font-size: calc(@font-size / 1.3) !important;
      }
    }

    li:last-child {
      border-bottom: 0 !important;
    }
  }

  tr > th {
    border-bottom: 2px solid @borders-color !important;
    border-right: 1px solid @borders-color !important;
  }

  tr td:first-child {
    text-align: left !important;
    vertical-align: top !important;
    padding: 4px !important;

    span {
      word-wrap: break-word;
      white-space: normal;
    }
  }

  .label.additional_info {
    width: 100%;
    padding: 2px;
    background: @label-danger-color;
    font-size: calc(@font-size / 1.4) !important;
    color: @white !important;
  }

  .item-settings-dropdown .dropdown-menu {
    left: 0;
  }

  tr td {
    text-align: left !important;
    padding: 2px !important;
    white-space: nowrap;
    max-width: 300px;
    vertical-align: top !important;

    .label-danger,
    .label_danger {
      color: @white !important;
    }

    .label-invisible {
      color: @borders-color !important;
    }

    .form-control {
      min-width: 20px;
    }
  }

  td {
    font-size: calc(@font-size / 1.2) !important;
    white-space: nowrap !important;
    border-right: 1px solid @borders-color !important;

    .form-control {
      padding: 2px !important;
      height: auto !important;
      font-size: calc(@font-size / 1.2) !important;
      width: 22px !important;
      margin-bottom: 2px;

      &::-webkit-input-placeholder {
        font-family: @font-family-regular;
        color: @placeholder-color;
        font-size: @font-size;
        letter-spacing: @letter-spacing-normal;
      }

      &::-moz-placeholder {
        font-family: @font-family-regular;
        color: @placeholder-color;
        font-size: @font-size;
        letter-spacing: @letter-spacing-normal;
      }

      &:-moz-placeholder {
        font-family: @font-family-regular;
        color: @placeholder-color;
        font-size: @font-size;
        letter-spacing: @letter-spacing-normal;
      }

      &:-ms-input-placeholder {
        font-family: @font-family-regular;
        color: @placeholder-color;
        font-size: @font-size;
        letter-spacing: @letter-spacing-normal;
      }
    }

    .form-control.inactive {
      color: @borders-color !important;
    }

    .label {
      padding: 4px !important;
      margin-bottom: 2px !important;
      text-align: left !important;
      .border-radius-4;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .plan-calendar-date {
      color: @label-default-color !important;
      float: right;
      position: relative;
      font-size: calc(@font-size / 1.3) !important;
      z-index: 999 !important;
      background: transparent;
      border-radius: 100%;
    }
  }

  tr td.smaller-font-cell {
    font-size: calc(@font-size / 1.3) !important;
  }

  .progress-bar {
    padding: 2px !important;
    font-size: calc(@font-size / 1.3) !important;
  }

  .inline {
    .label {
      padding: 2px !important;
      display: inline-block !important;
    }
  }

  .date-cell {
    white-space: nowrap !important;
  }

  .item-settings-dropdown {
    float: left !important;
  }

  .label.request,
  .request {
    background: @action-executed-alt !important;
    text-align: center !important;
    color: @contract-executed-color !important;
  }

  .label.approved,
  .approved {
    background: @action-process-alt !important;
    text-align: center !important;
    color: @contract-executed-color !important;
  }

  .label.shipped,
  .shipped {
    background: @action-addition-alt !important;
    text-align: center !important;
    color: @contract-executed-color !important;
  }

  .highlighted-td {
    background: @highlighted-area;
  }

  td.td-left-align {
    text-align: left !important;
  }

  td.td-right-align {
    text-align: right !important;
  }

  td.td-center-align {
    text-align: center !important;
  }
}

.planning-table-aggregated {
  .planning-table;

  tr th {
    border-bottom: 2px solid @borders-color !important;
    border-right: 1px solid @borders-color !important;
  }

  tr td {
    vertical-align: middle !important;

    .label {
      text-align: center !important;
    }

    .span {
      vertical-align: middle !important;
    }
  }

  tr td:nth-child(1) {
    vertical-align: middle !important;
  }

  .buyer-td {
    width: 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }

  .crop-td {
    width: 70px !important;
    max-width: 70px !important;
    min-width: 70px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }

  .terminal-td {
    width: 70px !important;
    max-width: 70px !important;
    min-width: 70px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }

  .days-cell {
    width: 20px !important;
    max-width: 20px !important;
  }
}

.my-plans-checkbox {
  line-height: 1;
  margin-left: 10px;

  input {
    margin: 0 !important;
    width: 20px !important;
    height: 20px !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
  }

  label {
    padding-left: 5px;
    padding-top: 5px;
    margin: 0 !important;
  }
}

.logistics-table-cut {
  tr td {
    background: transparent !important;
  }

  tr th {
    background: transparent !important;
    border-bottom: 2px solid darken(@highlighted-area, 9%) !important;
  }
}

.vessels-table {
  tbody > tr > td {
    font-size: @font-size !important;
    text-align: left !important;
    padding: 2px !important;
    white-space: normal !important;
    word-break: keep-all !important;

    .label {
      white-space: nowrap !important;
      padding: 2px !important;
      display: inline-block;
      margin-bottom: 2px !important;
    }
  }

  .counterparty_detail_table,
  .table-inside-table {
    tr td:first-child {
      min-width: auto !important;
      background: inherit !important;
      z-index: 0 !important;
    }
  }

  tr td.nowrap {
    white-space: nowrap !important;
  }

  tr td:first-child {
    text-align: left !important;
    background: @tables-background !important;
    font-family: @font-family-bold !important;
    z-index: 99 !important;
  }

  td.highlighted {
    background: @color-black-opacity !important;
  }

  .item-settings-dropdown a:nth-of-type(1) .fa-cog {
    font-size: @font-size !important;
  }

  .vessel_new > td:first-child {
    color: @contract-new-color !important;
    border-left: 5px solid @contract-new-color !important;

    .btn-link,
    a {
      color: @contract-new-color !important;
    }

    .label,
    .badge {
      .label_success;
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
    }

    .dropdown-menu a {
      color: @contract-executed-color !important;
    }
  }

  .vessel_calculate > td:first-child {
    color: @contract-calculate-color !important;
    border-left: 5px solid @label-calculate-color !important;

    .btn-link,
    a {
      color: @contract-calculate-color !important;
    }

    .label,
    .badge {
      .label_calculate;
    }

    .label_danger {
      .label_danger !important;
    }

    .dropdown-menu a {
      color: @contract-executed-color !important;
    }
  }

  .vessel_loaded > td:first-child {
    color: @contract-executed-color !important;
    border-left: 5px solid @label-default-background !important;
    font-weight: normal;

    .btn-link {
      color: @contract-executed-color !important;
    }

    .label,
    .badge {
      .label_default;
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
    }

    .dropdown-menu a {
      color: @contract-executed-color !important;
    }
  }

  .vessel_shipment > td:first-child {
    color: @contract-processing-color !important;
    border-left: 5px solid @label-warning-color !important;

    .btn-link,
    a {
      color: @contract-processing-color !important;
    }

    .label,
    .badge {
      .label_warning;
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
    }

    .dropdown-menu a {
      color: @contract-executed-color !important;
    }
  }

  .vessel_washout > td {
    color: @contract-cancelled-color !important;
    opacity: 0.5;
    text-decoration: line-through;
    font-weight: normal;

    .btn-link,
    a {
      color: @contract-cancelled-color !important;
    }

    .label,
    .badge {
      .label_warning;
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
    }

    .dropdown-menu a {
      color: @contract-executed-color !important;
    }
  }

  .progress {
    border: 1px solid @borders-color !important;
  }
}

.documents-table {
  background: @tables-background;
  margin-bottom: 10px;
  .border-radius-4;
  .box-shadow-light;

  .label {
    padding: 2px !important;
  }
}

.document_form {
  .form-group {
    float: left !important;
    text-align: left !important;
  }
}

.quality_rule_templates_table {
  tr {
    th div {
      text-align: left;
    }
  }
}

.payments_table {
  tr th {
    padding: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
    height: 50px !important;
    min-height: 50px !important;
    max-height: 50px !important;
    border-right: 1px solid @borders-color * 1.07 !important;

    div {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  tr td {
    font-size: @font-size !important;
    border-right: 1px solid @borders-color * 1.07 !important;
  }

  tfoot tr:nth-last-child(1) td {
    vertical-align: top !important;
  }

  tfoot tr td {
    text-align: left !important;
  }

  .to-pay-btn {
    display: block;
    box-shadow: none !important;
    padding: 0 !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    font-size: calc(@font-size / 1.3);
    font-family: @font-family-regular !important;
    border-radius: 1em !important;
  }

  .bigger-label.positive-number {
    font-size: @font-size !important;
    font-family: @font-family-semibold !important;
  }

  .bigger-label.negative-number {
    font-size: @font-size !important;
    font-family: @font-family-semibold !important;
  }

  .span-left {
    float: left !important;
  }

  .span-right {
    float: right !important;
  }

  td.alert-success {
    background: @status-success-background !important;
  }

  td.alert-danger {
    background: @status-danger-background !important;
  }

  .new_payment > td.gt-featured {
    border-left: 5px solid @contract-new-color !important;
    background: @status-success-background !important;
    color: @contract-new-color !important;

    span {
      color: @contract-new-color !important;
    }

    .btn-link {
      color: @contract-new-color !important;
      border-bottom-color: @contract-new-color !important;
    }

    .label,
    .badge {
      .label_success;
    }

    .label_danger {
      .label_danger !important;
    }
  }

  .process_payment > td.gt-featured {
    border-left: 5px solid @label-warning-color !important;
    background: @status-exclamation-background !important;
    color: @contract-processing-color !important;

    span {
      color: @contract-processing-color !important;
    }

    .btn-link {
      color: @contract-processing-color !important;
      border-bottom-color: @contract-processing-color !important;
    }

    .label,
    .badge {
      .label_warning;
    }

    .label_danger {
      .label_danger !important;
    }
  }

  .executed_payment > td.gt-featured {
    border-left: 5px solid @label-default-background !important;
    color: @contract-executed-color !important;
    background: @color-black-opacity !important;

    .btn-link {
      color: @contract-executed-color !important;
      border-bottom-color: @contract-executed-color !important;
    }

    .label,
    .badge {
      .label_default;
    }

    .label_danger {
      .label_danger !important;
    }
  }
}

.payment-details-container {
  .col-xs-4 {
    padding-left: 5px;
    padding-right: 5px;
    overflow-x: auto;
  }

  padding-left: 0;
  padding-right: 0;
  text-align: left !important;
  overflow-x: auto;
}

.payment-details-table {
  width: 100% !important;
  margin-bottom: 1px !important;

  tr td {
    color: @contract-executed-color !important;
    font-size: @font-size !important;
    padding: 2px !important;
  }

  tr td:first-child {
    color: @contract-processing-color !important;
    font-size: calc(@font-size / 1.3) !important;
    font-family: @font-family-bold !important;
    text-transform: uppercase;
  }

  tr:first-child td {
    border-top: 0 !important;
  }
}

.table-payments {
  width: 100% !important;
}

.credit-note-table {
  tr td {
    vertical-align: middle;
    font-size: @font-size !important;

    .label {
      font-size: @font-size !important;
      font-family: @font-family-regular !important;
      margin: 0 !important;
      display: inline-flex;
    }

    .hide-btn {
      transition:
        transform 0.4s ease-in-out,
        opacity 0.4s ease-in;
      opacity: 0.001;
      transform: translate3d(0, 8px, 0);
      height: 1px;
      display: block;
    }

    &:hover {
      .hide-btn {
        .transition;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        height: auto;
      }

      .transform-element {
        transform: translate3d(0, 0, 0);
        font-size: 75%;
      }
    }
  }
}

.finance-client-details-table {
  tr td {
    font-size: @font-size !important;
    vertical-align: middle;

    .label {
      font-size: @font-size !important;
      font-family: @font-family-semibold !important;
      margin: 0 !important;
    }
  }
}

.request-prices-table {
  .table-hover;

  tr th {
    text-align: center !important;
  }

  tr td {
    border-right: 1px solid @borders-color !important;

    .label {
      font-size: calc(@font-size / 1.3) !important;
      font-family: @font-family-semibold !important;
    }
  }
}

.elevator-table {
  .main-table-template;

  .label {
    max-width: 150px;
    white-space: nowrap;
  }

  tr td:first-child {
    text-align: left !important;
    word-wrap: normal;
    white-space: normal;
    word-break: break-word;
    color: @contract-executed-color !important;
    width: 10%;

    .label {
      white-space: nowrap !important;
    }
  }

  td.additional_info {
    width: 10%;
  }

  tr td:nth-last-child(2),
  tr td:nth-last-child(3) {
    background: @color-black-opacity;
    border-radius: 0 !important;
    text-align: right;
    width: 16%;

    .label {
      white-space: nowrap;
    }
  }

  tr th:nth-last-child(2),
  tr th:nth-last-child(3) {
    background: @color-black-opacity;
    border-radius: 0 !important;
    text-align: right;

    .label {
      display: inline-block;
      text-align: left;
    }
  }

  tr td:nth-child(2) {
    label {
      font-size: calc(@font-size / 1.3) !important;
    }
  }
}

.request-table {
  width: 100% !important;
  .column-min-width {
    min-width: 100px;
  }
  .passport-label {
    margin-left: 2px !important;
    display: inline-flex !important;
    margin-top: 0;

    .fa {
      margin-right: 2px;
    }
  }

  td.alert-success {
    background: @status-success-background !important;
  }

  td.alert-danger {
    background: @status-danger-background !important;
  }

  .big-font {
    font-size: calc(@font-size * 1.2) !important;
  }

  tr td {
    .btn:hover,
    .btn:focus {
      z-index: 9999;
    }

    white-space: nowrap !important;
    font-size: calc(@font-size / 1.1) !important;
    vertical-align: middle !important;
    text-align: center;
    line-height: 1 !important;
    padding: 2px 6px !important;

    .fa.show-on-hover {
      display: none;
    }

    .label,
    .badge {
      margin: 0 !important;
      display: inline-block;

      &.indent-left {
        margin-left: 10px !important;
      }
    }

    .fa {
      font-size: calc(@font-size / 1.4) !important;
    }

    .fa-ellipsis {
      font-size: calc(@font-size * 1.1) !important;
    }

    .btn-link {
      font-family: @font-family-regular !important;
    }

    .contract-small-label {
      padding: 2px !important;
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  td.td-left-align {
    text-align: left !important;
  }

  td.td-right-align {
    text-align: right !important;
  }

  td.td-center-align {
    text-align: center !important;
  }

  td.contents-display {
    .label {
      display: contents;
      float: left;
    }
  }

  td.update_time {
    white-space: nowrap !important;
    color: @contract-executed-color !important;
    font-family: @font-family-regular !important;
    text-transform: initial;
    min-width: 140px;

    .label {
      margin-left: 0;
      text-align: left !important;
    }
  }

  td.wrap-text {
    white-space: normal !important;
    word-wrap: break-word;
  }

  .item-settings-dropdown {
    width: auto;

    li:nth-of-type(1) {
      width: auto;
    }
  }

  .item-settings-dropdown:hover {
    .transition;

    .dropdown-menu {
      .transition;
    }
  }

  .updated_time {
    padding: 2px;
    width: auto !important;
    background: transparent !important;
    text-align: left !important;
  }

  .exporter_set {
    span {
      display: block;
      line-height: 1;
    }
  }

  tr th {
    background: transparent !important;
    font-size: calc(@font-size / 1.3) !important;
    text-align: center !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
    height: 50px !important;
    min-width: 50px;
  }

  tr:hover {
    .fa.hide-on-hover {
      display: none;
    }

    .fa.show-on-hover {
      display: inline-block;
    }
  }

  .fa-cog {
    font-size: calc(@font-size / 1.1) !important;
  }

  td.highlighted {
    background: @highlighted-area !important;
    border-right: 1px solid darken(@highlighted-area, 4%) !important;
    border-left: 1px solid darken(@highlighted-area, 4%) !important;
    border-bottom: 1px solid darken(@highlighted-area, 4%) !important;
    border-radius: 0 !important;
  }

  td.small-font-cell {
    font-size: calc(@font-size / 1.1) !important;

    a,
    span,
    .btn-link {
      font-size: calc(@font-size / 1.1) !important;
    }
  }

  .highlighted.danger-highlighted {
    background: @status-danger-background !important;
  }

  .highlighted.warning-highlighted {
    background: @status-exclamation-background !important;
  }

  .highlighted.success-highlighted {
    background: @status-success-background !important;
  }

  tr td:first-child {
    text-align: left !important;
    white-space: nowrap;
    font-family: @font-family-regular !important;
  }

  tr td.word-wrap {
    word-wrap: break-word !important;
    white-space: normal !important;
  }

  .label,
  .badge {
    font-size: calc(@font-size / 1.1) !important;
    vertical-align: middle;
  }

  .smaller-label,
  .smaller-label.label {
    font-size: calc(@font-size / 1.3) !important;
    padding: 2px !important;
  }

  .label-opacity {
    font-size: calc(@font-size / 1.3) !important;
    background: transparent !important;
  }

  .executed_request > td:first-child,
  .executed_request > td.status-cell {
    border-left: 5px solid @label-default-background !important;
    background: @tables-background !important;

    .btn-link,
    a,
    span {
      color: @contract-executed-color;
    }

    .label,
    .badge {
      .label_default;
    }

    .label_calculate {
      .label_calculate !important;
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
      background-color: transparent !important;
      color: @contract-executed-color !important;
    }
  }

  .approved_request > td:first-child,
  .approved_request > td.status-cell {
    border-left: 5px solid @label-warning-color !important;
    background: @status-exclamation-background !important;

    .btn-link,
    a,
    span {
      color: @contract-processing-color;
    }

    .label,
    .badge {
      .label_warning;
    }

    .label_calculate {
      .label_calculate !important;
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
      background-color: transparent !important;
      color: @contract-executed-color !important;
    }
  }

  .new_request > td:first-child,
  .new_request > td.status-cell {
    border-left: 5px solid @contract-new-color !important;
    background: @status-success-background !important;

    .btn-link,
    a {
      color: @contract-new-color;
    }

    .label,
    .badge {
      .label_success;
    }

    .label_calculate {
      .label_calculate !important;
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
      background-color: transparent !important;
      color: @contract-executed-color !important;
    }
  }

  .cancelled_request > td:first-child,
  .cancelled_request > td.status-cell {
    border-left: 5px solid @label-default-background;
    background: @tables-background !important;

    .btn-link,
    a {
      color: @contract-executed-color;
      text-decoration: none !important;
    }

    .label,
    .badge {
      .label_default;
    }

    .label_calculate {
      .label_calculate !important;
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
      background-color: transparent !important;
      color: @contract-executed-color !important;
    }
  }

  .cancelled_request > td,
  .cancelled_request > td.status-cell {
    color: @contract-cancelled-color !important;
    text-decoration: line-through;
  }

  .cancelled_request:hover > td {
    text-decoration: none !important;
    opacity: 1 !important;

    .btn-link {
      opacity: 1 !important;
    }
  }

  td.contract_executed,
  .contract_executed > td:first-child {
    border-left: 5px solid @label-default-background !important;
    background: @tables-background !important;
  }

  td.contract_executed,
  .contract_executed > td,
  .contract_executed > td.status-cell {
    color: @contract-executed-color !important;
    font-weight: normal;

    .btn-link {
      color: @contract-executed-color !important;
    }

    .label,
    .badge {
      .label_default;

      a {
        color: @tables-background !important;
      }
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
      background-color: transparent !important;
      color: @contract-executed-color !important;
    }
  }

  td.contract_calculate,
  .contract_calculate > td:first-child {
    border-left: 5px solid @label-calculate-color !important;
  }

  td.contract_calculate,
  .contract_calculate > td,
  .contract_calculate > td.status-cell {
    color: @contract-calculate-color !important;
    background: @tables-background !important;

    .btn-link,
    a {
      color: @contract-calculate-color !important;
    }

    .label,
    .badge {
      .label_calculate;

      a {
        color: @tables-background !important;
      }
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
      background-color: transparent !important;
      color: @contract-executed-color !important;
    }
  }

  td.contract_new,
  .contract_new > td:first-child,
  .contract_new > td.status-cell {
    border-left: 5px solid @contract-new-color !important;
    background: @status-success-background !important;

    .btn-link,
    a {
      color: @contract-new-color !important;
    }

    .label,
    .badge {
      .label_success;

      a {
        color: @tables-background !important;
      }
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
      background-color: transparent !important;
      color: @contract-executed-color !important;
    }
  }

  td.contract_processing,
  .contract_processing > td:first-child,
  .contract_processing > td.status-cell {
    border-left: 5px solid @label-warning-color !important;
    background: @tables-background !important;

    .btn-link,
    a {
      color: @contract-processing-color !important;
    }

    .label,
    .badge {
      .label_warning;

      a {
        color: @tables-background !important;
      }
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
      background-color: transparent !important;
      color: @contract-executed-color !important;
    }
  }

  td.contract_cancelled,
  .contract_cancelled > td:first-child,
  .contract_cancelled > td.status-cell {
    border-left: 5px solid @label-default-background !important;
    background: @tables-background !important;
  }

  td.contract_cancelled,
  .contract_cancelled > td {
    color: @contract-cancelled-color !important;
    text-decoration: line-through;
    font-weight: normal;

    .btn-link,
    a {
      color: @contract-cancelled-color !important;
    }

    .label,
    .badge {
      .label_cancelled;

      a {
        color: @tables-background !important;
      }
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
      background-color: transparent !important;
      color: @contract-executed-color !important;
    }
  }

  .inside-table-ul {
    li {
      font-size: calc(@font-size / 1.1);
      border-bottom: 0 !important;
      text-align: left;

      span {
        font-size: calc(@font-size / 1.1);
      }
    }
  }

  .progress {
    margin: 0 !important;
    background-color: @color-black-opacity;
    box-shadow: none !important;
    height: auto !important;
    border-radius: 2em !important;
    text-transform: uppercase;
    min-width: 80px;

    .progress-bar {
      font-size: @font-size;
      padding: 0px;
      padding-left: 5px;
      text-align: left;
    }

    .progress_bar_default {
      background-color: @progressbar-default;
      color: @label-default-color-border;
    }

    .progress_bar_success {
      background-color: @progressbar-success;
      color: @label-success-color-border;
    }

    .progress_bar_warning {
      background-color: @progressbar-warning;
      color: @label-warning-color-border;
    }

    .progress_bar_calculate {
      background-color: @progressbar-calculate;
      color: @label-calculate-color-border;
    }

    .progress_bar_danger {
      background-color: @progressbar-danger;
      color: @white;
    }

    .avg-price {
      display: inline;
      position: absolute;
      right: 20px;
    }
  }

  .progress-consisted {
    .progress-bar {
      overflow-x: hidden !important;
    }
  }

  tr td .currency-totals {
    .label {
      display: initial !important;
    }
  }

  .passports-wrapper-light-mode {
    overflow-x: auto;
    display: flex;
    padding: 5px;

    .contract-details-container-cut h2 {
      font-size: calc(@font-size * 1.1) !important;
      font-family: @font-family-semibold !important;
    }

    .passports-container {
      min-width: 50px;
      margin-right: 10px;
      float: left;
      overflow: hidden;
    }

    .request-list {
      min-width: 70px;
      padding: 5px !important;
      margin: 0 !important;
      margin-right: 5px !important;

      .counterparty_detail_table {
        tr td {
          font-size: calc(@font-size / 1.3) !important;
          background: inherit !important;
          font-family: @font-family-regular !important;
        }
      }
    }
  }
}

.limits-table {
  tr td {
    padding: 6px !important;

    .label {
      float: right;
    }
  }
}

.approvers-table {
  tr td:first-child {
    border-left: 0 !important;
  }

  tr th {
    min-width: 40px;
    height: 20px !important;
  }

  tr td.wrap-text {
    white-space: normal !important;
    word-wrap: break-word;
  }
}

.bank-accounts-list {
  border-top: 0 !important;
  text-align: left !important;
  font-size: @font-size !important;

  h1 {
    padding: 0;
    margin: 0;
  }

  h3 {
    padding: 0;
    margin: 0;
  }

  div {
    white-space: normal;
    word-wrap: break-word !important;
    word-break: break-all;
  }

  ul li a {
    text-align: left !important;
    cursor: pointer;
  }

  .nav {
    font-family: @font-family-bold !important;
  }

  .label-strong {
    font-family: @font-family-bold !important;
  }
}

.budget-table {
  tr td {
    border-right: 1px solid @borders-color !important;
    text-align: right !important;
    font-size: @font-size !important;
    vertical-align: middle !important;
    padding: 2px !important;
    white-space: nowrap;

    .label,
    .badge {
      display: inline-flex;
    }

    line-height: 1 !important;
  }

  tr td:first-child {
    text-align: left !important;
    font-size: @font-size !important;
  }

  tr td:last-child,
  tr th:last-child {
    border-right: 0 !important;
  }

  .form-control {
    height: 16px;
    font-size: @font-size !important;
  }

  .income-row {
    background: @status-success-background !important;
    color: @contract-new-color;
    font-family: @font-family-bold !important;
    font-size: @font-size !important;
  }

  .expense-row {
    background: @status-danger-background !important;
    color: @label-danger-color;
    font-family: @font-family-bold !important;
    font-size: @font-size !important;
  }

  th[column-name='q1'],
  th[column-name='q2'],
  th[column-name='q3'],
  th[column-name='q4'] {
    background: @highlighted-area !important;
  }

  .label {
    padding: 2px !important;
    font-size: calc(@font-size / 1.3) !important;
  }

  .bigger-label {
    font-size: @font-size !important;
    font-family: @font-family-bold !important;
    padding: 0 !important;
  }

  .highlighted-tr {
    td {
      font-family: @font-family-bold !important;
      background: @highlighted-area !important;
    }
  }

  .fa-sort-up {
    visibility: hidden;
  }

  .quarter {
    background: @highlighted-area !important;
    font-family: @font-family-bold !important;
  }

  .highlighted {
    background: @highlighted-area !important;
    font-family: @font-family-bold !important;
  }
}

.request-list-container {
  .request-list {
    margin-bottom: 0 !important;
    border-radius: 8px;
    padding: 10px !important;

    .nav > li > a {
      padding: 0;
      line-height: 1;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      margin-top: 0;
      white-space: normal;
    }
  }

  h5 {
    font-size: @font-size !important;
  }

  .horizontal-divider {
    background-color: @tables-background;
    box-shadow: 0 1px 4px rgb(8 40 100 / 15%);
  }

  .smaller-label {
    white-space: normal;
    font-family: @font-family-regular;
    font-size: calc(@font-size / 1.3) !important;
  }
  .progress-bar {
    .smaller-label {
      white-space: nowrap;
    }
  }

  a {
    cursor: pointer;
    color: @headers-color !important;
  }

  .label {
    margin-bottom: 2px !important;
    margin-right: 2px !important;
    margin-top: 0 !important;
    padding: 2px !important;
  }

  .badge {
    font-size: calc(@font-size / 1.2);
  }

  .additional_label {
    font-size: @font-size !important;
  }

  .executed_request_left {
    border-left: 6px solid @label-default-color !important;
  }

  .approved_request_left {
    border-left: 6px solid @label-warning-color !important;
  }

  .new_request_left {
    border-left: 6px solid @label-success-color !important;
  }

  .executed_request {
    border-top: 6px solid @label-default-color !important;
  }

  .approved_request {
    border-top: 6px solid @label-warning-color !important;
  }

  .new_request {
    border-top: 6px solid @contract-new-color !important;
  }

  .cancelled_request {
    opacity: 0.5;
    border-top: 6px solid @label-default-color !important;

    .label,
    .badge {
      .label_default;
    }

    .label_calculate {
      .label_calculate !important;
    }

    .label_danger {
      .label_danger !important;
    }

    .label-opacity {
      background: transparent !important;
      background-color: transparent !important;
      color: @contract-executed-color !important;
    }

    .update_time {
      background: transparent !important;
      width: auto !important;
    }

    .value {
      color: @contract-executed-color !important;
    }
  }

  .cancelled_request:hover {
    text-decoration: none !important;
    opacity: 1 !important;
  }
}

.mailings-attachments-table {
  tr td {
    white-space: normal;
    word-break: break-all;
  }
}

.mailings-table {
  tr td {
    font-size: calc(@font-size / 1.1) !important;

    .label {
      font-size: calc(@font-size / 1.1) !important;
    }
  }

  .ui-select-bootstrap > .ui-select-match > .btn {
    height: 24px;
    line-height: 1.5 !important;
  }
}

.mailing-actions {
  .btn-xs {
    padding: 6px 10px;
    line-height: 1.5;
  }
}

.request-page {
  .request-list-container {
    width: 100% !important;

    .request-list {
      width: 100%;
    }
  }
}

.passports-list-container {
  font-size: calc(@font-size / 1.3);

  h2 {
    font-size: @font-size;
    white-space: normal;
  }

  h3 {
    font-size: @font-size;
    margin: 0;
    margin-bottom: 5px;
    white-space: normal;
  }

  .header-grey {
    color: @smoke;
  }

  .counterparty_detail_table {
    tr td:first-child {
      border-right: 0 !important;
    }

    tr td {
      border-top: 0 !important;

      .label {
        font-size: @font-size !important;
      }
    }
  }

  .request-list-container;

  .executed_request {
    border-top: 0 !important;
    border-left: 6px solid @label-default-color !important;
  }

  .approved_request {
    border-top: 0 !important;
    border-left: 6px solid @label-warning-color !important;
  }

  .new_request {
    border-top: 0 !important;
    border-left: 6px solid @label-success-color !important;
  }

  .cancelled_request {
    opacity: 0.5;
    border-top: 0 !important;
    border-left: 6px solid @label-default-color !important;
  }
}

.tickets-page,
.indicators-page {
  .request-list-container {
    width: 100% !important;

    .request-list {
      width: 100%;
    }
  }
}

.passport-modal {
  .request-list-container {
    .nav > li > a {
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  .request-list-container .request-list {
    width: 100% !important;
  }

  .main-nav-tabs li.active a {
    background: @main-background !important;
    border-bottom: 0 !important;
  }
}

.elevator-modal {
  .form-group .btn-default {
    background: transparent !important;
  }

  .form-group .btn-primary {
    background: @highlighted-area * 1.1 !important;
  }

  .elevator-logistics-form {
    .form-group {
      min-height: 50px;
    }
  }
}

.client-modal {
  .form-group {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .form-block {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.role-modal {
  .form-group {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .form-block {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.logistic-modal {
  .form-group {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .form-block {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.finance-modal {
  .form-group {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .form-block {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .accordion {
    .card {
      background: @tables-background;
      &:hover,
      &:focus {
        transform: none;
      }
    }

    .card-header {
      &:hover,
      &:focus {
        transform: none;
      }
    }
  }
}

.request-modal {
  .form-group {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .form-block {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.request-logistic-table {
  .table-strip;

  .label {
    display: inline-block !important;
    width: auto !important;
    float: none !important;
    margin-top: 0;
    margin-bottom: 0;
    padding: 2px !important;
    vertical-align: middle;
    font-size: @font-size !important;
  }

  tr > td {
    padding: 2px !important;
    font-size: @font-size !important;
  }

  tr td:last-child {
    border-right: 0 !important;
  }

  tr td:first-child {
    vertical-align: middle;
    font-size: @font-size !important;
  }

  tr > th {
    &::first-letter {
      text-transform: uppercase !important;
    }
    white-space: nowrap !important;
  }
}

.cash-flow-table {
  .main-table-template;
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-right: 5px !important;

  .label {
    white-space: nowrap !important;
    margin-bottom: 0px !important;
    text-align: center !important;
  }

  .inside-table-ul {
    li {
      font-size: calc(@font-size / 1.3) !important;
      text-transform: initial !important;
      font-family: @font-family-regular !important;
      padding-top: 3px;
      padding-bottom: 3px;
      height: auto;
      white-space: normal !important;
      word-wrap: break-word;
      border-bottom: 1px solid darken(@borders-color, 9%) !important;

      span {
        font-size: calc(@font-size / 1.3) !important;
        font-family: @font-family-regular !important;
      }
    }

    li:last-child {
      border-bottom: 0 !important;
    }
  }

  tr th {
    border-right: 1px solid @borders-color !important;
    border-top: 1px solid @borders-color !important;
    text-align: left !important;

    div {
      text-align: left !important;
    }
  }

  tr td {
    text-align: right !important;
    max-width: 100px;
    min-width: 100px;
    white-space: nowrap !important;
    cursor: default;
    overflow: hidden;
    line-height: 1 !important;
    padding: 2px !important;
    font-size: calc(@font-size / 1.2) !important;
    border-right: 1px solid @borders-color !important;
    vertical-align: top !important;

    .btn-link {
      font-size: calc(@font-size / 1.2) !important;
    }

    span {
      font-size: calc(@font-size / 1.2) !important;

      .label {
        padding: 2px !important;
      }
    }

    .btn,
    .btn-xs {
      padding: 0 !important;
      margin: 0 !important;
      line-height: 1 !important;
    }
  }

  tbody {
    border: 0 !important;
  }

  tbody tr td:nth-child(1),
  thead tr th:nth-child(1) {
    max-width: 160px !important;
    min-width: 160px !important;
    width: 160px !important;
    background: @highlighted-area !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
    font-family: @font-family-semibold;
    text-align: left !important;
  }

  tbody tr td:nth-child(2),
  thead tr th:nth-child(2) {
    max-width: 100px !important;
    min-width: 100px !important;
    width: 100px !important;
    background: @highlighted-area !important;
    font-family: @font-family-semibold;
    &::first-letter {
      text-transform: uppercase !important;
    }
    text-align: left !important;
  }

  tbody tr td:nth-child(3),
  thead tr th:nth-child(3) {
    max-width: 120px !important;
    min-width: 120px !important;
    width: 120px !important;
    background: @highlighted-area !important;
    text-align: left !important;
    white-space: normal;
    word-wrap: break-word;

    span {
      font-size: calc(@font-size / 1.4) !important;
      white-space: normal;
      word-wrap: break-word;
    }
  }

  thead tr th:nth-child(1),
  thead tr th:nth-child(2),
  thead tr th:nth-child(3) {
    text-align: center !important;
  }

  tbody tr td:nth-child(1),
  tbody tr td:nth-child(2),
  tbody tr td:nth-child(3) {
    padding-left: 5px !important;
  }

  .badge {
    font-size: calc(@font-size / 1.3) !important;
  }

  td.danger {
    background: @status-danger-background !important;
    color: @label-danger-color !important;
  }

  td.success {
    background: @status-success-background !important;
    color: @contract-new-color !important;
  }

  .label_danger,
  .label_success {
    font-size: calc(@font-size / 1.3) !important;
    padding: 2px !important;
  }

  .alert-success,
  .alert-danger {
    display: block;
  }
}

.cash-flow-table:nth-of-type(3) {
  tr td {
    background: @status-danger-background !important;
    color: @label-danger-color !important;

    .fa {
      color: @label-danger-color !important;
    }
  }
}

.cash-flow-table:nth-of-type(2) {
  tr td {
    background: @status-success-background !important;
    color: @contract-new-color !important;

    .fa {
      color: @contract-new-color !important;
    }

    .btn-link {
      .fa {
        color: @btn-link-color !important;
      }
    }
  }
}

#pl-cash-flow-table {
  .main-table-template;
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-left: 5px !important;
  margin-right: 5px !important;

  .label {
    white-space: nowrap !important;
    margin-bottom: 0px !important;
    text-align: center !important;
  }

  tr td {
    text-align: right !important;
    width: auto;
    white-space: nowrap !important;
    cursor: default;
    line-height: 1;
    padding: 2px !important;
    font-size: @font-size !important;
    border-right: 1px solid @borders-color !important;

    span {
      font-size: @font-size !important;

      .label {
        padding: 2px !important;
      }
    }
  }

  tr td:first-child {
    text-align: left !important;
    color: @contract-executed-color !important;

    .label {
      display: inline-block !important;
    }
  }

  td:nth-child(1),
  th:nth-child(1) {
    position: absolute;
    background: @highlighted-area !important;
    border-radius: 0 !important;
    z-index: 999 !important;
    width: 200px;
    margin-left: -5px !important;
    padding-left: 10px !important;
    height: auto !important;
    min-height: 20px !important;

    .inside-table-ul {
      li {
        font-size: @font-size !important;
        text-transform: initial !important;
        font-family: @font-family-regular !important;
        padding-top: 3px;
        padding-bottom: 3px;

        span {
          font-size: @font-size !important;
          font-family: @font-family-regular !important;
        }
      }

      li:nth-child(odd) {
        background-color: @color-black-opacity-dark !important;
      }
    }
  }

  .second-td {
    min-width: 195px !important;
    background: @highlighted-area !important;
    border-color: @highlighted-area !important;
  }

  .inside-table-ul {
    li {
      font-size: calc(@font-size / 1.3) !important;
      text-transform: initial !important;
      font-family: @font-family-regular !important;
      border-bottom: 0 !important;
      padding-top: 3px;
      padding-bottom: 3px;
      height: 16px;

      span {
        font-size: calc(@font-size / 1.3) !important;
        font-family: @font-family-regular !important;
      }
    }

    li:nth-child(odd) {
      background-color: @color-black-opacity-dark !important;
    }
  }

  td:nth-child(2),
  th:nth-child(2) {
    display: table-cell;
  }

  .today {
    background: @highlighted-area !important;
  }

  tr:nth-child(2) {
    td {
      background: @status-success-background;
      color: @contract-new-color;
    }
  }

  tr:nth-child(6) {
    td {
      background: @status-danger-background;
      color: @label-danger-color;
    }
  }

  .badge {
    font-size: calc(@font-size / 1.4) !important;
  }

  td.danger {
    background: @status-danger-background !important;
    color: @label-danger-color !important;
  }

  td.success {
    background: @status-success-background !important;
    color: @contract-new-color !important;
  }
}

td.danger {
  background: @status-danger-background !important;
  color: @label-danger-color !important;
}

td.success {
  background: @status-success-background !important;
  color: @contract-new-color !important;
}

#pl-daily-table {
  overflow-x: auto;

  td {
    padding: 2px !important;
    white-space: nowrap;
    text-align: right !important;
    border-right: 1px solid @borders-color !important;

    .label,
    .badge {
      font-family: @font-family-regular !important;
    }
  }

  td:first-child {
    font-family: @font-family-bold !important;
    text-align: left !important;
  }

  tr td:last-child,
  tr th:last-child {
    border-right: 0 !important;
  }

  td.td-right-align {
    text-align: right !important;
  }

  td.td-center-align {
    text-align: center !important;
  }
}

.pl-table {
  .budget-table;

  tr td {
    padding: 6px !important;
  }

  tr td:first-child.highlighted {
    background: @highlighted-area !important;
    font-family: @font-family-bold !important;
    text-transform: uppercase;
  }

  tr td:last-child.highlighted {
    background: @highlighted-area !important;
    font-family: @font-family-bold !important;
  }
}

#pl-balance-table {
  .col-xs-4 {
    padding: 0;
    overflow: hidden;
  }

  td {
    padding: 2px !important;
    white-space: nowrap;
    border-right: 1px solid @borders-color !important;
    text-align: right !important;

    .label {
      display: inline-block;
      margin-bottom: 0;
      margin-top: 0;
      font-size: calc(@font-size / 1.3);
    }
  }

  h5,
  h3 {
    padding: 0;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  tr td:last-child,
  tr th:last-child {
    border-right: 0 !important;
  }

  td:first-child {
    font-family: @font-family-semibold !important;
    text-align: left !important;
  }

  td.highlighted-td {
    font-family: @font-family-bold !important;
  }

  td.td-left-align {
    text-align: left !important;
  }

  td.td-right-align {
    text-align: right !important;
  }

  td.td-center-align {
    text-align: center !important;
  }

  .progress .progress-bar {
    text-align: center;
  }
}

#pl-process-table {
  label {
    font-size: calc(@font-size / 1.3) !important;
  }
}

.attached-plan {
  background: rgba(0, 0, 0, 0.12) !important;
}

#pl-costs-table {
  .main-table-template;
  overflow-x: auto !important;

  td:nth-child(1) {
    text-align: left;

    .label {
      text-align: left !important;
      margin-left: 0 !important;
    }
  }

  td {
    text-align: center;
    width: 100% !important;
    word-break: normal !important;
    font-size: calc(@font-size / 1.3) !important;
    white-space: nowrap !important;

    .label {
      white-space: nowrap !important;
    }
  }

  th {
    width: 100% !important;
    white-space: nowrap;
  }

  th:last-child {
    color: @lower-zero-color !important;
  }

  tr th:last-child,
  tr td:last-child {
    background: @highlighted-area !important;
  }

  .passport_executed > td {
    color: @contract-executed-color !important;
    background: @tables-background !important;
    font-weight: normal;

    .btn-link {
      color: @contract-executed-color !important;
      border-bottom-color: @contract-executed-color !important;
    }

    .label {
      .label_default;
    }
  }

  .passport_request > td {
    color: @contract-new-color !important;

    .btn-link,
    a {
      color: @contract-new-color !important;
      border-bottom-color: @contract-new-color !important;
    }

    .label {
      .label_success;
    }
  }

  .passport_processing > td {
    color: @contract-processing-color !important;

    .btn-link,
    a {
      color: @contract-processing-color !important;
      border-bottom-color: @contract-processing-color !important;
    }

    .label {
      .label_warning;
    }
  }

  .passport_approved > td {
    color: @contract-calculate-color !important;

    .btn-link,
    a {
      color: @contract-calculate-color !important;
      border-bottom-color: @contract-calculate-color !important;
    }

    .label {
      .label_calculate;
    }
  }

  .passport_cancelled > td {
    color: @contract-cancelled-color !important;
    background: @tables-background !important;
    text-decoration: line-through;
    font-weight: normal;

    .btn-link,
    a {
      color: @contract-cancelled-color !important;
      border-bottom-color: @contract-cancelled-color !important;
    }

    .label {
      .label_default;
    }
  }
}

.related-table {
  tr td,
  tr th {
    border-color: @borders-color !important;
  }
}

.consolidated-table {
  th {
    text-align: center !important;
    text-transform: uppercase !important;
    cursor: pointer;
  }

  th:hover {
    background: @color-black-opacity;
  }

  tr td {
    color: @contract-executed-color !important;

    .label {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  tr td:nth-child(2) {
    text-transform: uppercase !important;
    background: @color-black-opacity;
    text-align: center !important;
    vertical-align: middle;

    span {
      font-size: 0.7em;
    }

    .btn-link {
      color: @btn-link-color !important;
      font-size: calc(@font-size / 1.3) !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#supplier-consolidated-table,
#buyer-consolidated-table {
  tr:hover {
    cursor: pointer;
  }

  tr:hover td {
    color: @table-headers-hover !important;
    text-decoration: none;
  }

  td:last-child {
    .label {
      font-size: calc(@font-size / 1.3);
    }

    background: @highlighted-area;
  }

  tr th {
    font-size: calc(@font-size / 1.3) !important;
    padding: 2px;
  }
}

#pl-statistics-table {
  select[multiple],
  select[size] {
    min-height: 300px;
  }
}

.table-with-inline-add {
  tr td,
  div {
    vertical-align: top !important;

    .input-group .input-group-addon {
      padding: 3px;
    }

    .inside-table-ul {
      li {
        text-align: left !important;
      }
    }

    .ui-select-bootstrap > .ui-select-match > .btn {
      border-radius: 0 !important;
      min-height: 20px !important;
      padding: 6px !important;
      margin-left: 0 !important;
    }

    .alert {
      padding: 4px !important;
      margin: 0;
      margin-top: 5px;
    }

    .form-indicator {
      color: @green !important;
      font-size: calc(@font-size / 1.8) !important;
    }

    .label-input {
      display: block;
      margin-top: 10px;
    }

    .form-block {
      display: block;
    }

    .form-control {
      height: auto !important;
      margin-top: 0;
      margin-bottom: 0;
      font-family: @font-family-regular, 'FontAwesome' !important;
      line-height: 1 !important;
      overflow: visible;
      white-space: normal;
      font-size: calc(@font-size / 1.2) !important;
      min-height: 20px !important;
      padding: 4px;
      border-radius: 0 !important;
      border: 0 !important;
      border-bottom: 1px solid darken(@borders-color, 23%) !important;

      &::-webkit-input-placeholder {
        font-size: calc(@font-size / 1.2) !important;
      }

      &::-moz-placeholder {
        font-size: calc(@font-size / 1.2) !important;
      }

      &:-moz-placeholder {
        font-size: calc(@font-size / 1.2) !important;
      }

      &:-ms-input-placeholder {
        font-size: calc(@font-size / 1.2) !important;
      }

      span {
        font-size: calc(@font-size / 1.2);
      }
    }

    .form-control .ui-select-match-text {
      color: @placeholder-color !important;
      font-family: @font-family-regular, 'FontAwesome' !important;
      word-break: break-word;
      font-size: calc(@font-size / 1.2) !important;
    }

    .form-control.transparent-form {
      background: @tables-background !important;
    }

    .form-control.narrow-textarea {
      text-align: left;
      border-radius: 6px !important;
      height: 30px !important;
      width: 50% !important;
      float: right;
    }
  }

  input,
  .form-control,
  .ui-select-match,
  .ui-select-search {
    font-size: calc(@font-size / 1.2) !important;
    text-align: left;
    vertical-align: middle !important;
    border-color: darken(@borders-color, 23%) !important;

    &::-webkit-input-placeholder {
      color: @placeholder-color !important;
      font-size: calc(@font-size / 1.2) !important;
      font-family: @font-family-regular;
    }

    &::-moz-placeholder {
      color: @placeholder-color !important;
      font-size: calc(@font-size / 1.2) !important;
      font-family: @font-family-regular;
    }

    &:-moz-placeholder {
      color: @placeholder-color !important;
      font-size: calc(@font-size / 1.2) !important;
      font-family: @font-family-regular;
    }

    &:-ms-input-placeholder {
      color: @placeholder-color !important;
      font-size: calc(@font-size / 1.2) !important;
      font-family: @font-family-regular;
    }

    .text-muted {
      color: @placeholder-color !important;
      font-size: calc(@font-size / 1.2) !important;
      font-family: @font-family-regular;
    }

    .caret {
      display: none;
    }
  }
  .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    font-size: calc(@font-size / 1.2) !important;
  }
  .ui-select-match-text {
    margin: 0 !important;
  }
  .input-group {
    margin-bottom: 2px;
  }

  .form-control .ui-select-match-text {
    font-size: calc(@font-size / 1.2) !important;
  }

  .ui-select-match {
    .form-control {
      line-height: 1;
      font-size: calc(@font-size / 1.2) !important;
    }
  }

  tr td {
    .ui-select-match-text {
      min-height: 1px !important;
      margin-top: 7px;
    }
  }
}

table.invoice-position-table {
  tr th div.td-right-align,
  tr th div.td-left-align {
    text-align: center !important;
  }
  .table-with-inline-add;
  tr td {
    vertical-align: top !important;
    white-space: normal;
    border-right: 0 !important;
    min-width: 50px !important;
    font-size: calc(@font-size / 1.5) !important;
    color: @smoke !important;
    font-family: @font-family-regular !important;
    border-top-width: 4px !important;

    .inside-table-ul {
      li {
        text-align: left !important;
      }
    }
  }
}

.invoice-position-list-table {
  tr td {
    white-space: nowrap !important;
    font-family: @font-family-regular !important;

    .form-control {
      height: auto !important;
      font-size: calc(@font-size / 1.2);
      font-family: @font-family-regular, 'FontAwesome' !important;
      line-height: 1;
      overflow: visible;
      white-space: normal;

      span {
        font-size: calc(@font-size / 1.2);
      }
    }
  }
}

#statistics-table,
#statistics-finance-table,
#statistics-execution-table,
.dashboard-table {
  padding-left: 5px;
  padding-right: 5px;

  th {
    font-size: 0.9em;
    text-transform: uppercase;
    margin-right: 2px;
    padding: 2px !important;
  }

  td {
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
    text-align: right;
    text-transform: uppercase;
    color: @statistics-third-row;

    .label {
      margin-right: 2px;
      margin-bottom: 2px;
    }
  }

  .lower-zero-number {
    color: @lower-zero-color !important;
  }

  .higher-zero-number {
    color: @higher-zero-color !important;
  }

  td:first-child {
    color: @contract-executed-color;
    padding-left: 15px;
    vertical-align: middle;
    background: transparent !important;
    text-align: left !important;
    font-family: @font-family-bold !important;

    .label {
      padding: 2px !important;
    }
  }
}

.dashboard-table {
  width: 100% !important;

  td:nth-child(3) {
    color: @contract-executed-color !important;
  }

  td:first-child {
    text-align: left !important;
    font-family: @font-family-bold !important;

    .btn-link {
      font-family: @font-family-light !important;
      color: @btn-link-color !important;
      font-size: calc(@font-size / 1.3) !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  tr td {
    vertical-align: middle !important;
    padding: 2px !important;

    .label,
    .badge {
      font-size: calc(@font-size / 1.3);
      padding: 2px !important;
    }
  }

  tr th {
    padding: 6px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    white-space: normal;
    word-wrap: break-word;
  }
}

.team-table {
  tr th {
    padding: 6px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .item-settings-dropdown {
    float: left !important;
  }

  .item-settings-dropdown .dropdown-menu {
    left: 0 !important;
  }

  tr td {
    white-space: normal !important;
  }

  tr td.nowrap {
    white-space: nowrap !important;
  }
}

.chart-block-wrapper {
  .form-control {
    padding: 2px !important;
    height: 22px;
  }

  .chart-graph-block,
  .chart-selects-block {
    width: 100% !important;
  }

  .page-header {
    .center-block;

    h3 {
      display: none;
    }
  }

  .contract-description-label {
    font-size: @font-size !important;
  }
}

.small-chart-block {
  .chart-graph-block {
    width: 100% !important;
  }
}

#statistics-execution-table {
  td {
    color: @statistics-third-row;
  }
}

.costs-table,
.quality-table {
  .table-strip;

  tr td {
    border-color: @borders-color !important;
    color: @contract-executed-color !important;
  }

  tr td:first-child {
    border-color: @borders-color !important;
    color: @contract-executed-color !important;
    background: @color-black-opacity !important;
  }
}

.prices_img {
  width: 100% !important;
  text-align: center;
  height: auto;
}

//buttons
#status_btn {
  margin: @left-sidebar-item-margin;
  margin-right: 0;
}

.add-indicator-start-btn {
  margin-right: 130px;
}

.add-indicator-calculator-start-btn {
  margin-right: 195px;
}

.add-ticket-start-btn {
  margin-right: 65px;
}

.btn-light,
.btn.btn-light,
a.btn-light {
  font-family: @font-family-light !important;
}

.inside-table-btn {
  font-size: calc(@font-size / 1.3);
  padding: 0 !important;
  box-shadow: none !important;
  text-transform: inherit !important;
}

.clear-btn {
  background: transparent !important;
  border: 0 !important;
}

.popup-wrapper {
  a {
    color: @white !important;
  }
}

#attach_btn,
#grain_quality_btn,
#notes_btn,
#add-new-payment {
  margin-top: 5px;
  width: auto;
}

.btn-filter-table {
  font-size: calc(@font-size * 1.2);
}

.add-btn {
  .btn-success;
  border: 1px solid darken(@btn-success-color, 17%) !important;
}

.add-btn:hover {
  background: @btn-success-color;
  color: @white !important;
  box-shadow: none !important;
}

.btn {
  .border-radius-4;
  outline: none !important;
  font-family: @font-family-regular;
  &::first-letter {
    text-transform: uppercase !important;
  }
  letter-spacing: @letter-spacing-very-huge;
  .no-text-stroke;
}

.btn:hover,
.btn:focus {
  transform: scale(1.05);
  transition: transform 0.2s;
  color: initial !important;
}

.btn-primary {
  .box-shadow-dark;
}

.btn-add-fixed {
  position: absolute;
  right: 10px;
  margin-top: -90px;
  .box-shadow-light !important;
  border-radius: 100% !important;
}

.btn-scrollup {
  background: transparent;
  z-index: 1000 !important;
  position: fixed;
  font-size: 20px;
  height: 30px;
  width: 10px;
  padding: 0;
  right: 1%;
  bottom: 0%;
}

.btn-disable {
  opacity: 0.5;
}

.filter-template .btn-primary {
  .box-shadow-dark;
  white-space: normal;
  word-wrap: break-word;
  color: @contract-executed-color !important;
  border-radius: 0 !important;
  border-bottom: 2px solid @label-info-color-border !important;
}

.btn-default {
  color: @btn-default-color;
  border: 0 !important;
  background: transparent;
}

.btn-default:hover,
.btn-default:focus {
  background: darken(@highlighted-area, 4%);
  box-shadow: none;
}

.btn-warning {
  color: @white;
  background: @btn-warning-color !important;
  border-width: 1px;
  border-color: @btn-warning-color !important;
  .box-shadow-light;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  border-color: @btn-warning-color !important;
  background: @btn-warning-color !important;
}

.btn-grey {
  color: @btn-color;
  background: @gradient;
  border-width: 1px;
  border-color: @btn-default-color;
}

.btn-grey:hover,
.btn-grey:focus,
.btn-grey:active,
.btn-grey.active,
.open .dropdown-toggle.btn-grey {
  border-color: @btn-default-color !important;
  background: @btn-default-color;
  color: @highlighted-area !important;
}

.btn-link {
  color: @btn-link-color !important;

  &:hover {
    text-decoration: underline;
  }

  padding-bottom: 0;
  border-radius: 0 !important;
  font-family: @font-family-semibold !important;
}

.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  color: @btn-link-color;
  border-bottom: 1px dashed @btn-link-color;
  text-decoration: none !important;
}

.btn-success {
  color: @white;
  border-color: @btn-success-color !important;
  background: @btn-success-color !important;
  border-width: 1px;
  .box-shadow-light;
}

button.btn-success {
  border-color: @btn-success-color !important;
  background-color: @btn-success-color !important;
  .border-radius-4;
}

button.btn-success:hover {
  opacity: 0.9;
}

.btn-add-dark {
  border-color: darken(@highlighted-area, 23%);
  color: @headers-color;
  background: transparent !important;
  border-width: 1px;

  &:hover {
    background: @navbar-background !important;
    color: @tables-background !important;
  }
}

.btn-info {
  color: @white;
  border-color: @btn-info-color !important;
  background: @btn-info-color !important;
  border-width: 1px;
  .box-shadow-light;
}

.btn-blue-border {
  border-color: @btn-info-color !important;
  border-width: 1px;
}

.btn-blue-border:hover {
  background-color: @btn-info-color !important;
  color: @white !important;
}

.btn-green-border {
  border-color: @btn-success-color !important;
  border-width: 1px;
}

.btn-grey-border:hover {
  background-color: @btn-default-color !important;
  color: @white !important;
}

.btn-grey-border {
  border-color: @btn-default-color !important;
  border-width: 1px;
}

.btn-dark-border:hover {
  background-color: darken(@navbar-background, 17%) !important;
  color: @white !important;
}

.btn-dark-border {
  border-color: darken(@navbar-background, 17%) !important;
  border-width: 1px;
}

.btn-green-border:hover {
  background-color: @btn-success-color !important;
  color: @white !important;
}

.btn-orange-border {
  border-color: @btn-warning-color !important;
  border-width: 1px;
}

.btn-orange-border:hover {
  background-color: @btn-warning-color !important;
  color: @white !important;
}

.other-actions-btn {
  padding: 2px !important;
  .border-radius-4;
  background: @tables-background;
  text-transform: uppercase !important;
  .box-shadow-light;
}

.btn-login {
  width: 100%;
  color: @btn-login-font-color;
  background: @gradient;
  background-color: @btn-login-color !important;
  border-color: @btn-login-color !important;
  outline: 0 !important;
  padding: 10px !important;
}

.btn-login:focus {
  outline: 0 !important;
}

.btn-danger {
  background: @tables-background;
  border-width: 1px;
}

.btn-no-data {
  padding: 6px 6px;
  font-size: 0.9em;
  margin: 0 auto;
  display: block;
  position: relative;
  float: none;
}

.btn-update,
.btn-update:hover,
.btn-update:focus {
  color: @btn-default-color !important;
  border: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
  overflow: hidden !important;
}

.btn-active-theme {
  background-color: @dropdown-menu-background-active;
}

.no-data-btn {
  text-align: center;
  display: block;
  width: 30%;
  margin: 0 auto;
  float: none;
  color: @contract-executed-color !important;
}

.no-data-btn:hover {
  color: @white !important;
}

#edit_btn {
  padding: 2px;
}

.edit-btns-group-main {
  .btn-fixed {
    background: @gradient-non-transparent;
    color: @contract-executed-color !important;
    border-radius: 100% !important;
    width: 40px;
    height: 40px;
    line-height: 1.6;
    font-size: 16px;
    position: relative;
  }

  .btn-default:hover {
    background: darken(@highlighted-area, 9%) !important;
  }

  .btn-danger:hover {
    background: @label-danger-color !important;
  }

  .btn-danger:hover,
  .btn-success:hover,
  .btn-info:hover,
  .btn-warning:hover {
    color: @white !important;
  }

  .btn-fixed:nth-child(1) {
    bottom: 2%;
  }

  .btn-fixed:nth-child(2) {
    bottom: 14%;
  }

  .btn-fixed:nth-child(3) {
    bottom: 22%;
  }
}

.btn-fixed {
  height: 70px;
  width: 70px;
  border-radius: 100% !important;
  line-height: 2.8;
  .box-shadow-heavy;
  font-family: @font-family-semibold;
  z-index: 1000 !important;
  right: 15px;
  bottom: 10px;
  position: fixed;
  font-size: calc(@font-size * 1.4) !important;
  .no-transition;
  color: @white !important;
}

.btn-fixed.btn-danger {
  background: @label-danger-color;
}

.btn-fixed:hover {
  color: @white !important;
  box-shadow: none !important;
}

.modal-edit-btns-group-main {
  .btn {
    color: @white !important;
    margin-left: 5px;
    border-width: 2px !important;
    font-family: @font-family-bold;
  }

  .btn-fixed {
    position: relative;
    color: @tables-background !important;
    border-radius: 100% !important;
    width: 30px;
    height: 30px;
    line-height: 1;
    font-size: 12px !important;
    bottom: 0 !important;
    margin-right: 5px;
    z-index: 9998;
    float: right;
    padding-left: 9px;
    margin-top: 2px;

    .fa-times {
      color: @main-font-color !important;
    }

    .fa-cog {
      color: @main-font-color !important;
    }
  }

  .btn-default:hover {
    background: darken(@highlighted-area, 9%) !important;
  }

  .btn-danger:hover,
  .btn-success:hover,
  .btn-info:hover,
  .btn-warning:hover {
    color: @white !important;
  }

  .btn-danger:hover {
    background: @label-danger-color !important;
  }

  .btn-warning {
    background: @btn-warning-color;
    color: @white;
  }

  .btn-info {
    background: @btn-info-color;
    color: @white;
  }

  .btn-danger {
    background: @label-danger-color;
    color: @white !important;
  }
}

.edit-btns-group-sub {
  margin-right: 0;
  display: block;
  text-align: right;
  margin-top: 15px;
  position: relative;
  z-index: 9998;

  .btn-fixed {
    width: 40px !important;
    height: 40px !important;
    position: relative !important;
    line-height: 2em;
    font-size: 16px;
    background: @gradient-non-transparent;
    margin-right: 10px;
    right: 0 !important;
    left: 0;
  }

  .btn-default:hover {
    background: darken(@highlighted-area, 9%) !important;
  }
}

#show-more-btn {
  .border-radius-4;
  border: 1px dashed @borders-color-light !important;
}

.tabs-container {
  .border-radius-4;
  background: @well-background;
}

.half-size-btn-group {
  width: 40% !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.help-btn-group {
  position: absolute;
  right: 35px;
  top: 50px;
  z-index: 999;

  a {
    float: right;
    margin-right: 5px !important;
    margin-left: 5px !important;
    width: auto;
    text-align: left;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: @font-size;
    font-family: @font-family-bold;
    .box-shadow-light;
    .transition;
    letter-spacing: @letter-spacing-huge;
    background: @tables-background;
  }

  a.gt-head-filter {
    border-radius: 4px;
    padding: 6px 12px;
  }

  a:last-child {
    margin-right: 0 !important;
  }

  a:hover {
    cursor: pointer;
    background: @main-background-color;
    box-shadow: 0px 1px 1px @fixed-btn-shadow;
  }

  .tooltip:hover::before {
    top: 40px;
    right: 5px;
  }

  .item-settings-dropdown {
    float: right;

    a:nth-of-type(1) {
      padding: 6px 12px !important;
    }

    a.gt-head-filter {
      display: none;
    }

    .dropdown-menu .btn,
    .dropdown-menu a {
      text-align: left !important;
      float: left;
      width: 100%;
    }
  }
}

.page-header-main:hover {
  .help-btn-group {
    a {
      .transition;
      border-radius: 4px;
      padding: 6px 12px;

      span {
        display: inline-block;
      }
    }
  }

  .payment-details-container {
    display: block;
  }
}

.container-btn-group {
  bottom: -4px;

  .btn {
    padding: 6px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border-top-left-radius: 0.25em !important;
    border-top-right-radius: 0.25em !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: none !important;
    font-size: @font-size;
    &::first-letter {
      text-transform: uppercase !important;
    }
  }

  .btn-primary {
    box-shadow: none !important;
  }
}

.button-group-container {
  .btn {
    margin-bottom: 2px;
    width: 100%;
    display: block;
  }
}

.well {
  background: @well-background;
  color: @well-color !important;
  border-radius: 8px;
  border: transparent;
  word-break: break-word;
  box-shadow: none;

  .container-btn-group {
    .btn-primary {
      background: darken(@highlighted-area, 17%) !important;
    }
  }
}

.if-navbar-left {
  .help-btn-group {
    top: 10px;
  }

  .alert-applied-filters {
    left: 45px;
  }

  .passports-table {
    tfoot > tr > td:first-child,
    tbody > tr > td:first-child,
    thead > tr > th:first-child {
      left: 62px;
    }
  }

  .create-contract-page {
    .gt-sidebar.col-xs-1 {
      border-right: 1px solid @tables-background;
      background: @tables-background;

      .toggle-button {
        .fa-bars {
          color: @contract-executed-color;
        }
      }
    }

    .gt-sidebar.col-xs-2 {
      background: @tables-background;

      .scrollable-sidebar {
        background: @tables-background;
      }

      .start-page-header {
        color: @contract-executed-color;
      }

      .opened-sidebar {
        background: @tables-background;
      }
    }
  }

  .toggle-button {
    .fa-bars {
      color: @contract-executed-color;
    }

    .fa-filter {
      color: @contract-executed-color;
    }
  }

  .toggle-button.btn-default {
    background: darken(@borders-color, 9%);
    color: @contract-executed-color;
  }

  .sidebar-wrapper {
    .search-wrapper {
      width: 90% !important;

      .search-container {
        border: 1px solid darken(@borders-color, 9%) !important;

        input {
          color: darken(@borders-color, 9%) !important;

          &::-webkit-input-placeholder {
            color: darken(@borders-color, 9%) !important;
          }
        }
      }
    }

    .pagination > li > a,
    .pagination > li > span {
      color: @contract-executed-color !important;
    }

    .pagination > li.active > a {
      background: darken(@borders-color, 17%) !important;
      color: @tables-background !important;
    }
  }

  .gt-table-sidebar {
    tr td {
      border-bottom: 1px solid @borders-color !important;
    }
  }

  .gt-table-sidebar-contracts {
    tr.contract_executed > td:first-child {
      border-left: 5px solid @label-default-background;
      color: @contract-executed-color !important;
    }

    tr.contract_calculate > td:first-child {
      border-left: 5px solid @label-calculate-color;
      color: @contract-calculate-color !important;
    }

    tr.contract_new > td:first-child {
      border-left: 5px solid @contract-new-color;
      color: @contract-new-color !important;
    }

    tr.contract_processing > td:first-child {
      border-left: 5px solid @contract-processing-color;
      color: @contract-processing-color !important;
    }

    tr.contract_cancelled > td:first-child {
      border-left: 5px solid @label-default-background;
      color: @contract-cancelled-color !important;
      text-decoration: line-through;
    }
  }
}

.btn-group {
  .btn {
    overflow-x: hidden;
  }

  table-layout: auto;
}

.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: inherit;
}

.column-config-table {
  width: 100% !important;

  tr td {
    vertical-align: middle !important;
    font-size: calc(@font-size / 1.2) !important;
    border-bottom: 1px solid @borders-color !important;
    border-top: 0 !important;
    line-height: 1 !important;
    padding: 2px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;

    input[type='checkbox'],
    input[type='radio'] {
      width: 20px !important;
      height: 20px !important;
    }

    .form-control {
      line-height: 1;
      font-size: calc(@font-size / 1.2) !important;
      border: 0 !important;
      margin: 0;
      min-height: auto !important;
      min-width: 0 !important;
      background: @highlighted-area;
      font-family: @font-family-regular !important;
    }

    .form-control:focus,
    textarea.form-control:focus {
      border: 0 !important;
    }

    .btn {
      line-height: 1;
      padding: 2px;
      font-size: calc(@font-size / 1.3) !important;
      margin: 0;
    }

    .ui-select-match-item.btn {
      font-family: @font-family-regular !important;
    }

    .fa-arrows {
      visibility: hidden;
    }
  }

  tr > td:first-child {
    font-family: @font-family-regular !important;

    span {
      font-family: @font-family-regular !important;
    }
  }

  tr:hover {
    td:first-child {
      .fa-arrows {
        visibility: visible;
      }
    }
  }

  .ui-select-multiple .btn {
    display: flex !important;
    padding: 2px !important;
    font-size: calc(@font-size / 1.3) !important;
    width: 51%;
  }

  .ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
    margin-right: 10px;
    font-size: @font-size !important;
  }

  .ui-select-match,
  .ui-select-container {
    max-width: 300px;
  }

  .ui-select-multiple .close {
    float: none !important;
  }

  .form-control {
    height: auto;
  }

  .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
    float: left;
  }
}

//tooltip
.tooltip-link {
  cursor: pointer;
}

.tooltip-modal {
  position: relative;
  width: 0px;
  left: -5px;
  top: 8px;

  .fa-question-circle {
    font-size: calc(@font-size / 1.3) !important;
    text-align: right;
    float: right;
    color: @light-blue !important;
  }
}

.tooltip-modal::after {
  border-radius: 8px 8px 8px 0px;
  box-shadow: none;
  color: #fff;
  content: '';
  background: transparent;
  opacity: 0;
  padding: 12px;
  position: absolute;
  visibility: hidden;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  .transition;
  font-size: calc(@font-size / 1.2) !important;
  font-family: @font-family-regular !important;
  text-transform: inherit !important;
  line-height: 1.4;
  max-width: 500px;
  min-width: 300px !important;
}

.tooltip-modal:hover::after {
  background: @navbar-background;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  content: attr(data-tip);
  margin-top: -45px;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  .transition;
}

.tooltip-centered {
  position: relative;
  margin-left: 10px;
}

.tooltip-centered::after {
  border-radius: 8px 8px 8px 0px;
  box-shadow: none;
  color: #fff;
  content: '';
  background: transparent;
  opacity: 0;
  padding: 12px;
  position: absolute;
  visibility: hidden;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  .transition;
  font-size: calc(@font-size / 1.2) !important;
  font-family: @font-family-regular !important;
  text-transform: inherit !important;
  line-height: 1.4;
  max-width: 500px;
  min-width: 300px !important;
}

.tooltip-centered:hover::after {
  background: @navbar-background;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  content: attr(data-tip);
  margin-top: -45px;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  .transition;
}

.tooltip {
  position: relative;
}

.tooltip::before {
  content: attr(data-tip);
  width: 1px;
  height: 1px;
  position: absolute;
  display: none;
  z-index: 9999 !important;
  opacity: 0;
  .transition;
}

.tooltip:hover::before {
  .transition;
  z-index: 9999 !important;
  width: auto !important;
  word-wrap: break-word !important;
  max-width: 400px;
  white-space: normal !important;
  word-break: normal;
  display: inline;
  position: absolute;
  left: 0;
  top: 35px;
  text-transform: inherit !important;
  font-size: calc(@font-size / 1.2) !important;
  font-family: @font-family-regular !important;
  font-weight: 400 !important;
  opacity: 1;
  min-width: max-content;
  .border-radius-4;
  padding: 8px 8px;
  height: auto;
  line-height: 1;
  background: @navbar-background;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  color: @tables-background;
}

//labels
.label[href]:focus,
.label[href]:hover {
  color: @main-table-td;
}

.badge {
  font-size: 0.8em;
  color: @label-color;
  .no-text-stroke;
  padding: 4px !important;
  font-family: @font-family-regular !important;
  letter-spacing: @letter-spacing-huge;
  &::first-letter {
    text-transform: uppercase !important;
  }
}

.label {
  color: @label-color;
  padding: 4px !important;
  .no-text-stroke;
  font-family: @font-family-regular !important;
  overflow: hidden;
  &::first-letter {
    text-transform: uppercase !important;
  }
  .border-radius-4;
  letter-spacing: @letter-spacing-huge;
}

.label-bold {
  font-family: @font-family-bold !important;
  letter-spacing: @letter-spacing-huge;
}

.secondary-text {
  color: @smoke !important;
  letter-spacing: @letter-spacing-huge;
  white-space: normal;
  word-break: break-all;
}

.contract-small-label {
  padding: 2px !important;
  font-size: calc(@font-size / 1.3) !important;
  letter-spacing: @letter-spacing-huge;
}

.bigger-label {
  font-family: @font-family-regular !important;
}

.label-strong {
  font-family: @font-family-semibold !important;
  letter-spacing: @letter-spacing-huge;
  .no-text-stroke;
}

.smaller-label {
  font-size: calc(@font-size / 1.3) !important;
  text-transform: initial !important;
  letter-spacing: @letter-spacing-huge;
}

.header-badge {
  margin-left: 2px;
  line-height: 1;
  padding: 2px !important;
  vertical-align: text-bottom;
  font-size: calc(@font-size / 1.3) !important;
  font-family: @font-family-semibold !important;
  letter-spacing: @letter-spacing-huge;
}

.label-danger-stamp {
  color: @label-danger-color !important;
  border: 2px dashed @label-danger-color !important;
  padding: 6px 8px !important;
  text-transform: uppercase;
  display: inline-block;
}

.label-calculate-stamp {
  .label-danger-stamp;
  color: @label-calculate-color !important;
  border: 2px dashed @label-calculate-color !important;
}

.label-default-stamp {
  .label-danger-stamp;
  color: @label-danger-color !important;
  border: 2px dashed @label-danger-color !important;
}

.link-to-passport {
  font-family: @font-family-semibold !important;
  letter-spacing: @letter-spacing-huge;
  cursor: pointer;

  .label {
    float: none !important;
  }
}

.label-date {
  border: 1px solid @borders-color;
  .border-radius-4;
  padding: 0;
  display: inline-block;

  .label.month {
    font-size: calc(@font-size / 1.3) !important;
    text-transform: uppercase;
    width: 100%;
    background: @color-black-opacity;
    padding: 2px !important;
    text-align: center !important;
    display: block;
    border-radius: 0 !important;
    margin-left: 0;
  }

  .day {
    font-size: calc(@font-size / 1.3);
    text-align: center !important;
    display: block;
    font-family: @font-family-semibold !important;
    padding: 0 !important;
    background: transparent !important;
  }

  .day.label_danger {
    background: @label-danger-color !important;
  }
}

.opacity-el {
  opacity: 0.5;
}

.additional_label {
  background: darken(@highlighted-area, 9%) !important;
  background-color: darken(@highlighted-area, 9%) !important;
  color: @contract-executed-color !important;
  font-size: @font-size !important;
  padding: 2px;
  .border-radius-4;
}

.updated_time {
  font-family: @font-family-regular !important;
  text-transform: initial;
  letter-spacing: @letter-spacing-huge;
  .noselect;
}

.contract_details {
  font-family: @font-family-regular;
}

.passport-label {
  margin-left: 2px !important;
  display: inline-flex !important;

  .btn-link {
    font-family: @font-family-semibold !important;
  }

  margin-top: 0;

  .fa {
    margin-right: 2px;
  }
}

.currency_exchange_label {
  padding: 2px !important;
  font-size: calc(@font-size / 1.4) !important;

  a,
  span {
    font-size: calc(@font-size / 1.4) !important;
  }
}

.label-none {
  display: none;
}

.label_start {
  font-family: @font-family-regular;
  font-size: @font-size !important;
  font-weight: 600 !important;
  padding-top: 1px;
  padding-bottom: 1px;
  letter-spacing: @letter-spacing-huge;
  .noselect;
  .border-radius-4;
}

.documents-date-label {
  color: @documents-date-label;
  font-weight: 500 !important;
}

.label-opacity {
  opacity: 0.6;
  box-shadow: none !important;
  border-color: transparent !important;
  font-size: calc(@font-size / 1.3) !important;
  background: transparent !important;
  letter-spacing: @letter-spacing-huge;
}

.label-smoke {
  color: @smoke !important;
}

.label-smoke.smaller-label {
  font-family: @font-family-regular;
  .no-text-stroke;
}

.label-invisible {
  color: transparent !important;
  background: transparent !important;
  letter-spacing: @letter-spacing-huge;
}

.label-dashed {
  opacity: 0.3;
  color: @label-color;
  box-shadow: none !important;
  font-weight: normal !important;
  border-color: transparent !important;
  letter-spacing: @letter-spacing-huge;
}

.label_cancelled {
  background: @label-cancelled-background;
  color: @label-cancelled-color !important;
}

.label-inactive {
  background: @label-cancelled-background !important;
  opacity: 0.4;
  color: @label-cancelled-color !important;
}

.label_brown {
  background-color: @label-warning-background !important;
  color: @label-warning-color !important;

  a {
    color: @label-warning-color !important;
  }
}

.label_grey {
  background-color: @label-grey-background !important;
  color: @label-grey-color-border !important;

  a {
    color: @label-grey-color !important;
  }
}

.label_success {
  background-color: @label-success-background !important;
  color: @label-success-color !important;

  a {
    color: @label-success-color !important;
  }
}

.label_calculate {
  background-color: @label-calculate-background !important;

  color: @label-calculate-color !important;

  a {
    color: @label-calculate-color !important;
  }
}

.label_warning {
  background-color: @label-warning-background !important;

  color: @label-warning-color !important;

  a {
    color: @label-warning-color !important;
  }
}

.label_info {
  background-color: @label-info-background !important;

  color: @label-info-color !important;

  a {
    color: @label-info-color !important;
  }
}

.label_default {
  background-color: @label-default-background !important;

  color: @label-default-color !important;

  a {
    color: @label-default-color !important;
  }

  a.btn-primary {
    color: @label-default-color !important;
  }
}

.label_danger {
  background-color: @label-danger-background !important;
  color: @label-danger-font-color !important;

  a {
    color: @label-danger-font-color !important;
  }

  span {
    color: @label-danger-font-color !important;
  }
}

.negative-number {
  color: @label-danger-color !important;
  .no-text-stroke;
}

.positive-number {
  color: @label-success-color !important;
  .no-text-stroke;
}

.alert {
  .border-radius-6;
  border: 0 !important;
}

.alert-danger-dashed,
.ul-inline-row.alert-danger-dashed,
.table-container-wrapper.alert-danger-dashed {
  border-bottom: 2px dashed darken(@borders-color, 9%) !important;
  .border-left {
    border-left: 6px solid @label-danger-background !important;
    margin-left: -15px;
    padding-left: 15px;
    padding-top: 5px !important;
    text-align: left;
  }
  .border-left-default {
    .border-left;
    border-left: 6px solid @label-default-background-alt !important;
  }

  .border-left-thin {
    border-left: 1px dashed darken(@borders-color, 9%) !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    text-align: left;
  }
}

.alert-applied-filters {
  position: fixed;
  bottom: 0;
  margin-bottom: 0;
  left: 0;
  right: 0;
  max-height: 60px;
  overflow: auto;
  z-index: 999;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0 !important;

  .btn {
    margin-left: 5px;
    margin-right: 5px;
    font-size: calc(@font-size / 1.3);
    line-height: 2;
    letter-spacing: @letter-spacing-huge;
  }

  .label {
    display: inline;
    margin-right: 4px;
    font-size: calc(@font-size / 1.2);
    line-height: 1;
    padding: 6px !important;
    letter-spacing: @letter-spacing-huge;
  }

  h5 {
    padding: 0;
    margin: 0;
    line-height: 2;
    font-size: @font-size;
  }
}

//dropdowns
.dropdown-menu {
  .border-radius-4;
  border: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: @dropdown-menu-background !important;
  color: @dropdown-menu-color;
  .box-shadow-very-heavy;

  .btn {
    border-radius: 0px !important;
  }
}

.dropdown-menu a:hover,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: @dropdown-menu-color-active;
  background-color: @dropdown-menu-background-active !important;
}

.dropdown-menu > li > a {
  color: @dropdown-menu-color;
  text-align: left !important;
}

.daterangepicker.dropdown-menu {
  color: @contract-executed-color;
  z-index: 9999;
}

.dropdown-menu > li > a:hover {
  color: @dropdown-menu-color-active;
  background-color: @dropdown-menu-background-active;
  border-radius: 0;
}

.bootstrap-datetimepicker-widget {
  th {
    color: @dropdown-menu-background-active;
  }

  td {
    color: @dropdown-menu-color-active !important;

    span {
      background: transparent !important;
    }
  }

  .btn-primary {
    border: 0 !important;
    background: transparent !important;
    box-shadow: none !important;
  }
}

.bootstrap-datetimepicker-widget td.active,
.bootstrap-datetimepicker-widget td.active:hover {
  background: @dropdown-menu-background-active !important;
}

.bootstrap-datetimepicker-widget td.day:hover,
.bootstrap-datetimepicker-widget td.hour:hover,
.bootstrap-datetimepicker-widget td.minute:hover,
.bootstrap-datetimepicker-widget td.second:hover {
  color: #666 !important;
}

//forms
input::-webkit-calendar-picker-indicator {
  display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.control-label {
  color: @contract-executed-color * 1.5;
  font-weight: normal;
  text-align: left !important;
  .noselect;
  padding-top: 10px;
}

.ui-select-match-item.btn {
  font-family: @font-family-semibold !important;
}

.input-group.input-group-select {
  .input-group-addon-search {
    font-size: calc(@font-size / 1.3);
    color: @contract-executed-color;
    padding: 10px;
    width: 2%;
    float: left;
  }

  .input-group-search-outside {
    padding-left: 2px;
    padding-right: 2px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .form-control:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0;
    display: block;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
}

.select-with-btn {
  width: 85%;
  float: left;

  .input-group.input-group-select {
    .form-control:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: 0 !important;
    }
  }
}

.text-muted {
  text-transform: none !important;
  color: @form-control-color !important;
  font-size: @font-size !important;
  line-height: 1 !important;
  vertical-align: bottom;
}

.form-control,
.input-group {
  .clear-btn {
    display: none;
  }

  &:hover {
    .clear-btn {
      display: inline-block;
    }
  }
}

.form-control {
  font-family: @font-family-regular !important;
  box-shadow: none;
  background: transparent;
  border-radius: 4px !important;
  border: 1px solid darken(@borders-color, 17%) !important;
  color: @form-control-color !important;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100% !important;
  margin-top: 0;
  padding: 0;
  padding-left: 4px;
  padding-right: 4px;

  option {
    font-family: @font-family-regular;
  }

  .ui-select-match-text {
    font-family: @font-family-regular !important;
    word-break: keep-all;
    padding-right: 0;

    .clear-btn {
      display: none;
    }

    &:hover {
      .clear-btn {
        display: inline;
        margin: 0;
        padding: 0;
      }
    }

    &::-webkit-input-placeholder {
      font-family: @font-family-regular;
      color: @placeholder-color !important;
      font-size: @font-size;
      text-overflow: ellipsis;
      letter-spacing: @letter-spacing-normal;
    }

    &::-moz-placeholder {
      font-family: @font-family-regular;
      color: @placeholder-color !important;
      font-size: @font-size;
      text-overflow: ellipsis;
      letter-spacing: @letter-spacing-normal;
    }

    &:-moz-placeholder {
      font-family: @font-family-regular;
      color: @placeholder-color !important;
      font-size: @font-size;
      text-overflow: ellipsis;
      letter-spacing: @letter-spacing-normal;
    }

    &:-ms-input-placeholder {
      font-family: @font-family-regular;
      color: @placeholder-color !important;
      font-size: @font-size;
      text-overflow: ellipsis;
      letter-spacing: @letter-spacing-normal;
    }
  }

  &::-webkit-input-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color !important;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }

  &::-moz-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color !important;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }

  &:-moz-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color !important;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }

  &:-ms-input-placeholder {
    font-family: @font-family-regular;
    color: @placeholder-color !important;
    font-size: @font-size;
    text-overflow: ellipsis;
    letter-spacing: @letter-spacing-normal;
  }
}

.form-control:focus {
  &::-webkit-input-placeholder {
    color: transparent;
  }

  &::-moz-placeholder {
    color: transparent;
  }

  &:-moz-placeholder {
    color: transparent;
  }

  &:-ms-input-placeholder {
    color: transparent;
  }
}

.has-error .form-control {
  box-shadow: none !important;
}

.has-error .form-control,
.ng-invalid {
  border-color: @label-danger-color !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group .has-error.form-control:first-child,
.input-group .ng-invalid-required.form-control:first-child {
  border-color: @label-danger-color !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-group-container .form-group.not-empty {
  .col-md-7 {
    min-height: 20px;
    height: auto;
    background: transparent !important;
    border: 1px solid transparent !important;
    border-radius: 0 !important;

    border-bottom: 1px solid @forms-border !important;
  }
}

.not-empty {
  .form-group {
    background: @tables-background !important;
  }

  .object-title {
    text-align: left;
    font-family: @font-family-bold;
    color: @form-control-color * 1.3;
  }

  .input-group-addon {
    color: @form-control-color * 1.3 !important;
  }

  .form-control {
    color: @form-control-color * 1.3 !important;
  }

  .fa-remove {
    color: @label-danger-color !important;
  }

  .add-btn {
    color: @form-control-color * 1.3 !important;
  }

  .form-add-btn {
    display: none;
  }

  .select-with-btn {
    width: 100% !important;
  }

  .btn-default {
    color: @form-control-color * 1.3 !important;
  }

  .gt-date-select {
    .input-group-addon {
      display: none;
    }
  }
}

.has-error {
  .form-group {
    background: @status-danger-background !important;
  }
}

.ui-select-bootstrap .ui-select-toggle > a.btn {
  position: absolute;
  height: 10px;
  right: -5px;
  margin-top: 0;
  text-decoration: none !important;
  border: 0 !important;
}

.ui-select-bootstrap > .ui-select-match > .btn {
  margin-top: 0;
  font-family: @font-family-regular;
  text-transform: initial;
  padding: 2px;
  border-radius: 4px !important;
  overflow: visible;
  white-space: normal;
  word-break: keep-all;
  height: auto;
  letter-spacing: @letter-spacing-huge;
}

.ui-select-bootstrap > .ui-select-choices {
  width: auto !important;
  background: @main-background;
}

.ui-select-choices-row {
  text-align: left;
  font-size: calc(@font-size / 1.2);
  overflow-y: hidden !important;
}

.ui-select-bootstrap .ui-select-choices-row.active > span {
  background: darken(@highlighted-area, 5%);
  color: @form-control-color-focus;
  font-family: @font-family-semibold;
}

.form-control:focus,
textarea.form-control:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  color: @form-control-color-focus;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid @form-control-color-focus;
}

.form-group {
  .center-block;
  margin-bottom: 0px !important;
  padding-right: 15px;
  padding-left: 15px;
}

.form-group .btn-default {
  border-bottom: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;
  background: @alt-background !important;
}

.formly-full-width {
  width: 100%;
  clear: both;
  display: block;
}

.formly-half-width {
  display: inline;
  width: 49.5%;
  float: left;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] {
  background-color: @color-black-opacity !important;
  border-color: transparent !important;

  &::-webkit-input-placeholder {
    color: @placeholder-color * 1.3;
  }

  &::-moz-placeholder {
    color: @placeholder-color * 1.3;
  }

  &:-moz-placeholder {
    color: @placeholder-color * 1.3;
  }

  &:-ms-input-placeholder {
    color: @placeholder-color * 1.3;
  }

  border-radius: 0 !important;
}

.similar-fields {
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
  text-align: left;

  ul {
    width: 100%;
    padding: 6px;
  }

  li {
    font-size: calc(@font-size / 1.3);
    color: @contract-executed-color;
    text-align: left;
    display: inline-block;
    margin-right: 10px;
  }

  .label {
    display: inline-flex !important;
  }
}

.ui-select-match[disabled='disabled'] {
  cursor: not-allowed !important;

  .btn-default {
    background-color: @highlighted-area !important;
    color: @contract-executed-color !important;

    .text-muted {
      color: @form-control-color !important;
    }
  }
}

// contract form
.label-contract-form {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}

.table-container-wrapper {
  background: @tables-background;
  border-radius: 8px;
  padding: 20px !important;
  .box-shadow-light;
  border: 0 !important;

  table {
    tr th {
      border: 0 !important;
      border-right: 1px solid darken(@borders-color, 5%) !important;
      background: @contract-errors-background !important;
    }

    tr th.alert-danger {
      background: @status-danger-background !important;
    }

    tr th.alert-success {
      background: @status-success-background !important;
    }

    tr th.alert-info {
      background: @light-blue-background !important;
    }

    tr th.alert-warning {
      color: @alert-warning-color !important;
      background: @alert-warning-background !important;
    }
  }

  .table-container-wrapper {
    padding-top: 0 !important;
    box-shadow: none !important;
  }
}

.overflow-x::-webkit-scrollbar,
.ul-inline-row::-webkit-scrollbar,
.table-container-wrapper::-webkit-scrollbar {
  height: 10px;
}

.ul-inline-row {
  background: @tables-background;
  border-radius: 8px;
  padding: 20px !important;
  .box-shadow-light;
  margin-bottom: 2px !important;
  border: 0 !important;

  .table-container-wrapper,
  .ul-inline-row,
  .well {
    box-shadow: none !important;
  }
}

.filters-well,
.analytics-container.filters-well {
  background: transparent !important;
  .box-shadow-none;
  border: 3px dashed @color-black-opacity-very-dark;

  .form-control {
    border: 0 !important;
    border-radius: 0 !important;
    border-bottom: 1px solid darken(@borders-color, 20%) !important;
  }
  .label {
    white-space: normal;
  }
}

.overflow-x {
  overflow-x: auto;
  max-height: 100%;
  max-width: 100%;
  overflow-y: hidden;
}

.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 700px;
  max-width: 100%;
}

.overflow-y-small {
  .overflow-y;
  max-height: 350px;
}

.overflow-x-with-margin {
  .overflow-x;
  padding-bottom: 250px;
  margin-bottom: -250px;
}

.no-overflow-container {
  .overflow-x {
    overflow: visible !important;
  }
}

.default-overflow {
  overflow-x: visible;
  overflow-y: visible;
}

.li-inline-row {
  display: block;
  border: 1px solid darken(@highlighted-area, 9%);
  height: 50px;
  .border-radius-4;
  margin-bottom: 4px;
  padding: 2px;

  .contract_details {
    font-size: @font-size;
    margin-bottom: 2px;
  }

  div {
    padding-left: 2px !important;
    padding-right: 5px !important;
  }
}

select {
  font-size: 0.9em;
  color: @select-color;
  margin-top: 2px;
  border: 0;
  outline: 0;
  cursor: pointer;
  border-bottom: 0 !important;
  box-shadow: none !important;
  .border-radius-4;

  option {
    color: @select-color !important;
    border: 0 !important;
    outline: 0;
    box-shadow: none;
    &:hover {
      background: @select-background !important;
    }
  }
}

select[multiple],
select[size] {
  .border-radius-4;

  option {
    border-bottom: 1px solid darken(@highlighted-area, 13%) !important;
  }
}

select::before {
  font-family: 'FontAwesome' !important;
  content: ' \f142 ' !important;
}

//inputs
input[type='checkbox'],
input[type='radio'] {
  margin: 0;
  width: 20px;
  height: 20px;
  text-align: left !important;
  float: left;
  margin-right: 5px;
}

.buyers-multiselect {
  .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    background: transparent !important;
  }

  .ng-not-empty.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    border: 1px solid darken(@borders-color, 17%) !important;
  }
}

.checkbox-logistics {
  width: 15px !important;
  height: 15px !important;
  margin: 0 !important;
  float: none !important;
}

input[type='date'] {
  .border-radius-4;
  border: 1px solid darken(@highlighted-area, 9%) !important;
}

input[type='date']::before {
  font-family: 'FontAwesome';
  content: ' \f073 ';
  margin-right: 0.3em;
}

input[type='email']::before {
  font-family: 'FontAwesome';
  content: ' \f0e0 ';
  margin-right: 0.3em;
}

input[type='tel']::before {
  font-family: 'FontAwesome';
  content: ' \f098 ';
  margin-right: 0.3em;
}

input[type='url']::before {
  font-family: 'FontAwesome';
  content: ' \f0c1 ';
  margin-right: 0.3em;
}

.selectpicker.btn-default {
  background: darken(@highlighted-area, 9%) !important;
}

.modal-content .selectpicker.btn-default {
  background: darken(@highlighted-area, 9%) !important;
}

.input-group-addon {
  padding: 6px;
  border: 0 !important;
  background: transparent;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  font-size: calc(@font-size / 1.3);
}

/*PAGINATION*/

.pagination {
  margin: 5px;
  margin-top: 50px;
}

.pagination nav {
  ul li:hover {
    cursor: pointer !important;
  }
}

.pagination > li > a,
.pagination > li > span {
  padding: 2px 8px;
  border: 0;
  background: transparent !important;
  color: @btn-default-color;
  font-size: calc(@font-size / 1.2) !important;

  :hover {
    cursor: pointer;
  }
}

.pagination-select {
  display: inline-block;
  width: 200px;
  margin: 0 auto;
}

.input-block-level form-control,
.bootstrap-select-searchbox input {
  border-bottom: 1px solid darken(@borders-color-light, 41%) !important;
  color: @contract-executed-color !important;
}

input::-webkit-input-placeholder {
  font-size: @font-size;
  padding-top: 2px;
}

input:-moz-placeholder {
  font-size: @font-size;
  padding-top: 2px;
}

.btn-default .caret {
  border-top-color: @borders-color !important;
  display: none;
}

.note {
  font-size: @font-size;
  text-align: right;
  color: @contract-executed-color !important;
}

#id_current_progress,
#id_response,
#id_commentary,
#id_additional_info,
#id_confirmation_info,
#id_logistics_info,
#id_history {
  height: 70px;
}

#id_title {
  background: darken(@highlighted-area, 9%);
  border: 0;
  outline: 0;
  .border-radius-4;
  color: @contract-executed-color;
}

#id_title:focus {
  color: @contract-executed-color;
}

.login-header {
  position: absolute;
  right: 0;
  left: 0;
  height: 60%;
  background: @navbar-background;
  box-shadow: inset 0 2px 10px #000;
  top: 0;
  z-index: 0;
}

.form-login {
  width: 100%;
  margin: 0 auto;

  .input-group {
    margin-bottom: 3px;
  }

  .form-control {
    border: 0 !important;
    border-bottom: 2px solid #ededed !important;
    font-family: @font-family-semibold !important;
  }

  .form-control:focus {
    border: 0 !important;
    border-bottom: 3px solid #ededed !important;
  }

  .input-group-addon {
    padding: 2px 15px;
    color: #fcc944;
    background: @navbar-background;
  }
}

#Terms {
  margin-left: 0px;
}

.stage-form {
  .ul-inline-row {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .form-group {
    margin-bottom: 5px !important;
  }

  .form-control[disabled] {
    .border-radius-4;
  }

  input[type='checkbox'],
  input[type='radio'] {
    text-align: left;
    float: left;
    width: 20px !important;
  }
}

.small-workflow {
  padding: 0 !important;
  margin: 0 !important;

  li {
    width: 2px;
    height: 4px;

    .fa {
      font-size: calc(@font-size / 1.3);
    }
  }
}

.page-info-container {
  border-radius: 0.65em;
  border: 1px dashed darken(@borders-color, 9%) !important;
  padding: 30px;

  h4,
  h5 {
    margin-bottom: 15px;
  }

  small {
    font-family: @font-family-regular;
  }

  .btn_default {
    background: @color-black-opacity-very-dark !important;
    border: 1px solid @color-black-opacity-very-dark !important;
    .border-radius-4;
  }

  .btn_link {
    border-bottom: 1px dashed darken(@borders-color, 9%) !important;
  }

  .btn-round {
    border-radius: 100% !important;
    height: 30px !important;
    width: 30px !important;
    line-height: 2;
  }

  .search-container {
    border: 1px solid @color-black-opacity-very-dark !important;
  }

  .well {
    margin: 0;
    overflow-x: auto;

    .inside-well-ul {
      margin: 0;

      li {
        background: @color-black-opacity-very-dark;
        text-align: left;
        padding: 2px;
        font-size: calc(@font-size / 1.3);
        font-family: @font-family-semibold;
        letter-spacing: @letter-spacing-normal;
        .border-radius-4;
        margin-bottom: 3px;
      }

      li:last-of-type {
        border-bottom: none !important;
      }
    }

    .inside-well-table {
      margin: 0;
      border-radius: 0.65em;

      tr td {
        padding: 2px;
        font-size: calc(@font-size / 1.3) !important;
        font-family: @font-family-semibold;
        border: 0 !important;
        border-bottom: 1px solid @color-black-opacity-very-dark !important;
        white-space: normal;
        word-break: keep-all;

        .nav-tabs-cut li > a {
          font-family: @font-family-regular !important;
          font-size: calc(@font-size / 1.3);
        }
      }

      tr:last-of-type td {
        border-bottom: none !important;
      }

      tr td:first-child {
        white-space: nowrap;
      }

      td.smaller-font-td {
        font-size: calc(@font-size / 1.3) !important;
      }
    }
  }
}

//start-page
.start-page-header {
  margin-top: 10px !important;
  padding-bottom: 10px !important;
  font-family: @font-family-bold !important;
  &::first-letter {
    text-transform: uppercase !important;
  }
  .no-text-stroke;

  small {
    font-size: 65% !important;
    font-family: @font-family-light;
    letter-spacing: @letter-spacing-huge;
  }
}

.dashboard-object-container {
  display: inline-block;

  .ul-inline-row {
    padding: 20px !important;
  }

  .dashboard-value {
    text-align: center;

    h1 {
      font-family: @font-family-bold;
      text-align: center;
      padding: 0 !important;
      margin: 0;
    }

    h3 {
      font-family: @font-family-bold;
      text-align: center;
      padding: 0 !important;
      margin: 0;
      font-size: calc(@font-size * 1.2);
    }
  }

  .col-md-4 {
    padding-left: 0;
    padding-right: 5px;
  }

  .dashboard-label {
    text-align: center;

    span {
      font-size: @font-size;
      font-family: @font-family-regular;
      text-align: center;
      color: @start-title-color-hover;
      text-transform: lowercase;
    }
  }
}

.dashboard-start-container {
  h5 {
    text-align: center;
    float: left;
    display: block;
    padding: 6px;
    clear: both;
  }

  .pagination {
    margin-top: 0;
    margin-bottom: 0;

    li a {
      padding: 2px 12px;
    }
  }

  .ui-grid-canvas {
    height: 170px;
  }

  .ui-grid-render-container {
    height: 170px;
    border: 0 !important;
  }

  .ui-grid-cell-contents,
  .ui-grid-cell .tooltip,
  .ui-grid-cell,
  .ui-grid-cell .label {
    font-size: calc(@font-size / 1.2) !important;
    vertical-align: top !important;
    line-height: 1.1 !important;
    color: @contract-executed-color;
  }

  .ui-grid-cell,
  .ui-grid-row {
    height: 15px !important;
    background: transparent !important;
  }

  .ui-grid-cell img,
  .ui-grid-cell .no-user-avatar {
    margin-top: 2px;
    font-size: calc(@font-size / 1.3) !important;
    height: 12px;
  }

  .ui-grid-row:nth-child(odd) .ui-grid-cell,
  .ui-grid-row:nth-child(even) .ui-grid-cell,
  .ui-grid-header-cell {
    background: transparent !important;
  }
}

.dashboard-start-container-inner {
  padding-right: 0px;
  padding-left: 10px;

  .dashboard-start-container-block {
    margin-bottom: 10px;
    border-radius: 8px;
    padding-left: 10px;
  }

  #update-properties-table tr td {
    font-size: calc(@font-size / 1.4) !important;
    padding: 2px !important;

    .updated_time {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  #update-properties-table tr td.message {
    font-size: calc(@font-size / 1.3) !important;
  }
}

.start {
  .label_start {
    float: right;
  }

  .start-block-container {
    padding: 5px;
  }

  .start-block-row {
    border-radius: 8px;
    font-family: @font-family-bold !important;

    a {
      display: inline-block;
      margin-right: 10px;
    }

    h5.start-page-header {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  .start-block-container:last-child {
    border-right: 0;
  }
}

.start h4 {
  color: @white;
  text-align: center;
}
.start-block {
  h5 {
    display: block;
  }
}

.start-container {
  height: auto;
  background: @start-container-background;
  .border-radius-4;
  font-family: @font-family-regular !important;
  margin-right: 22px;
  margin-bottom: 5px;
  max-width: 40px !important;
  min-width: 40px;
  white-space: nowrap;
  .transition;
}

a:hover,
a:focus {
  transform: scale(1.05);
  transition: transform 0.2s;
}

.start-block:hover {
  .start-title {
    transform: scale(1.05);
    transition: transform 0.2s;
  }
}

.start-img {
  width: 100%;
  height: 20px;
  background: @start-img-background;
  font-size: 15px;
  color: @start-title-color-hover;
  text-align: right;
  padding-right: 5px;
}

.start-img:hover {
  color: @start-title-color-hover !important;
}

.start-img i:hover {
  color: @start-title-color-hover !important;
}

.start-title {
  background: @start-block-background;
  font-size: calc(@font-size / 1.4);
  color: @navbar-background !important;
  text-align: left !important;
  width: 100%;
  border-radius: 8px !important;
  padding: 10px;
  overflow: hidden;
  text-transform: uppercase;
  &::first-letter {
    text-transform: uppercase !important;
  }
  .box-shadow-medium;
  .transition;

  letter-spacing: @letter-spacing-huge;

  .fa {
    font-size: calc(@font-size * 2);
    display: block;
    margin-bottom: 5px;
  }

  .label-start {
    color: @smoke * 1.4 !important;
    margin-left: 10px;
    float: right;
    display: none;
  }
}

.start-description {
  font-size: 0.7em;
  color: @smoke * 1.2 !important;
  text-align: center !important;
  overflow: hidden;
  background: @start-block-background;
}

.start-contacts {
  p {
    font-size: 0.7em;
    color: @smoke !important;
    text-align: center !important;
  }
}

/*colorization of start containers*/

.start .clients_tab {
  .start-title {
    border-bottom: 2px solid @clients-tab !important;
  }

  .label_start {
    background: transparent !important;
    color: @clients-tab !important;
  }

  .fa {
    color: @clients-tab !important;
  }
}

.start .prices_tab {
  .start-title {
    border-bottom: 2px solid @prices-tab !important;
  }

  .label_start {
    background: transparent !important;
    color: @prices-tab !important;
  }

  .fa {
    color: @prices-tab !important;
  }
}

.start .logistics_tab {
  .start-title {
    border-bottom: 2px solid @logistics-tab !important;
  }

  .label_start {
    background: transparent !important;
    color: @logistics-tab !important;
  }

  .fa {
    color: @logistics-tab !important;
  }
}

.start .payments_tab {
  .start-title {
    border-bottom: 2px solid @payments-tab !important;
  }

  .label_start {
    background: transparent !important;
    color: @payments-tab !important;
  }

  .fa {
    color: @payments-tab !important;
  }
}

.start .pl_tab {
  .start-title {
    border-bottom: 2px solid @pl-tab !important;
  }

  .label_start {
    background: transparent !important;
    color: @pl-tab !important;
  }

  .fa {
    color: @pl-tab !important;
  }
}

.start .crm_tab {
  .start-title {
    border-bottom: 2px solid @crm-tab !important;
  }

  .label_start {
    background: transparent !important;
    color: @crm-tab !important;
  }

  .fa {
    color: @crm-tab !important;
  }
}

.status {
  .start-description {
    padding-top: 10px;
  }

  .start-title:nth-last-child(1) {
    padding-bottom: 10px;
  }
}

//contracts,
.contract-page {
  background: @highlighted-area !important;
}

.filter-template {
  border-radius: 0 !important;
  word-wrap: break-word;
  background: transparent;

  .btn {
    font-size: calc(@font-size / 1.3) !important;
    border-radius: 0 !important;
    color: @smoke !important;
  }

  .ui-select-match .form-control,
  .ui-select-container .form-control {
    font-size: @font-size !important;
    color: @contract-executed-color !important;
    display: block;
    margin-top: 0;
  }
}

.bigger-filter-template {
  .btn {
    font-size: @font-size !important;
  }
}

.all-filters-block {
  height: auto;
  display: block;
  width: 17%;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  right: 0;
  bottom: 0;
  top: 0;
  box-shadow: none;
  background: @navbar-background;
  z-index: 1000;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 40px;
  padding-bottom: 40px;

  .filter-template {
    font-family: @font-family-semibold;
    color: @tables-background !important;
    box-shadow: none !important;
    .border-radius-4;
    border-bottom: 0 !important;
    word-wrap: break-word;
    margin-bottom: 5px;

    .btn-primary {
      font-family: @font-family-bold !important;
      box-shadow: none !important;
      font-size: calc(@font-size / 1.3) !important;
      color: @white !important;
    }

    .form-control {
      border: 0 !important;
      border-bottom: 1px solid @borders-color !important;
      border-radius: 0 !important;
    }

    .btn {
      color: @tables-background !important;
      padding: 2px;
      padding-left: 4px;
      padding-right: 4px;
      font-size: calc(@font-size / 1.3);
      letter-spacing: @letter-spacing-mega;
    }
  }

  .contract-description-label {
    padding: 6px;
    margin: 0 auto;
    margin-bottom: 0 !important;
    margin-top: 20px !important;
    display: block;
    text-align: center;
    color: @tables-background;
  }

  #daterange5 {
    width: 95%;
    margin: 0 auto;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
  }

  .fixed-button {
    bottom: 0px;
    right: 0;
    position: fixed;
    color: @tables-background !important;
    width: 17%;
    padding: 6px;
    z-index: 100;
    border-radius: 0;
    background: darken(@navbar-background, 23%);
  }

  .input-group {
    margin: 0 auto;
    width: 95%;

    .form-control {
      border-bottom: 1px solid @borders-color !important;
      color: @tables-background !important;
    }
  }

  .ui-select-multiple.ui-select-bootstrap {
    color: @tables-background !important;
    padding: 2px;
  }

  .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    height: 16px;
    color: @tables-background !important;
    font-size: calc(@font-size / 1.1) !important;
    text-transform: capitalize !important;
    margin-bottom: 0;
    letter-spacing: @letter-spacing-huge;

    &::-webkit-input-placeholder {
      color: @tables-background !important;
      font-size: calc(@font-size / 1.1) !important;
    }
  }

  .crop-filters {
    margin-bottom: 5px;
    text-align: left;
  }

  .filter-template.crop-filters {
    .btn {
      font-size: calc(@font-size / 1.2) !important;
      display: inline-block;
      width: auto;
      text-align: left;
      padding: 2px;
      margin: 0;
      color: @tables-background !important;
      line-height: 1;
    }
  }

  .gt_test_filter_date {
    margin-bottom: 5px !important;
  }

  .search-container {
    .btn {
      padding: 2px !important;
      color: @tables-background !important;
    }

    input {
      color: @tables-background !important;

      &::-webkit-input-placeholder {
        color: @tables-background !important;
      }
    }
  }

  h4 {
    color: @tables-background !important;
  }

  label {
    color: @tables-background !important;
  }

  .form-control {
    font-size: calc(@font-size / 1.3) !important;
    color: @tables-background !important;
    line-height: 1;

    &::-webkit-input-placeholder {
      color: @tables-background !important;
      &::first-letter {
        text-transform: uppercase !important;
      }
    }
  }

  .btn-default {
    color: @tables-background !important;
  }

  .btn-default:hover {
    background: darken(@navbar-background, 17%) !important;
  }

  .alert {
    padding: 5px;
    margin: 0;

    .input-group {
      .form-control {
        height: 20px;
        background: transparent;
      }
    }

    .form-control {
      height: 20px;
      background: transparent;
    }
  }

  .alert-warning {
    background: @label-warning-background-alt !important;

    .btn {
      background: @label-warning-background !important;
      border: 0 !important;
      color: @tables-background !important;
    }
  }

  .btn-danger {
    background: transparent !important;
    color: @tables-background !important;
  }

  .alert-danger {
    .btn-danger {
      background: @label-danger-color !important;
    }

    color: @td-main-color !important;
  }

  .text-muted {
    color: @tables-background !important;
  }

  .filter-template .ui-select-match .form-control,
  .filter-template .ui-select-container .form-control {
    font-size: calc(@font-size / 1.3) !important;
    height: 20px;
    padding-left: 5px;
    line-height: 1 !important;
  }

  .btn-group.crop-filters {
    margin-bottom: 5px;

    .btn-default {
      color: @tables-background !important;
    }
  }

  .gt-date-select {
    .dropdown-menu {
      left: -60px !important;
    }
  }

  .input-group-addon {
    background: darken(@navbar-background, 17%);
    color: @white;
  }

  .form-control .ui-select-match-text {
    color: @white;
    button {
      color: @white;
      i {
        color: @white;
      }
    }
  }

  .ng-not-empty.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    background: transparent !important;
  }
}

.header-filters {
  .btn-link {
    font-size: 0.7em;
  }
}

.filter-template.crop-filters {
  border-radius: 4px !important;

  .btn {
    display: block;
    width: 100%;
    padding-left: 10px;
    text-align: left;
    margin: 0 auto;
    border-radius: 0 !important;
    border-right: 0 !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
  }

  .btn-primary {
    border-radius: 4px !important;
  }
}

.btn-group.crop-filters {
  margin-bottom: 10px;

  .btn-default {
    font-family: @font-family-regular;
    margin-right: 2px;
    font-size: calc(@font-size / 1.3) !important;
  }

  .btn-primary {
    font-family: @font-family-regular;
    border: 1px solid darken(@borders-color, 9%) !important;
    font-size: calc(@font-size / 1.3) !important;
    background: @color-black-opacity-very-very-dark !important;
    padding: 4px !important;
  }
}

.filters-well {
  border-radius: 8px;
  padding: 12px;
  white-space: normal;
  .btn-group.crop-filters {
    .btn-primary {
      background: @tables-background !important;
    }
  }
}

.filter-template.filter-elevator-logistic {
  width: 100%;

  .btn {
    width: auto;
    margin: 0 auto;
    border-radius: 4px !important;
    padding: 10px;
  }
}

.btn-group-with-hidden-naming {
  .btn {
    span {
      display: none;
    }
  }

  .btn-primary {
    span {
      display: inline-block;
    }
  }
}

.filter-template-with-hidden-naming {
  .btn-default {
    span {
      font-size: calc(@font-size / 1.3);
    }

    i {
      font-size: @font-size;
    }
  }

  .btn-primary {
    span {
      font-size: @font-size;
      .transition;
    }

    i {
      font-size: @font-size;
    }
  }
}

.vertical-filter-template {
  .border-radius-4;

  .btn {
    .border-radius-4;
    display: block;
    width: 100%;
    text-align: left;
    padding: 2px;
    overflow: hidden;
    padding-left: 10px;
    white-space: nowrap;
    border-bottom: 0 !important;

    .label_start {
      position: absolute;
      top: 9px;
      right: 5px;
    }
  }

  .add-btn {
    .btn-success;
    border: 1px solid darken(@btn-success-color, 17%) !important;

    span {
      position: relative;
      top: 0;
      right: 0;
    }
  }

  .add-btn:hover {
    background: @btn-success-color !important;
  }
}

.prices-section-filters {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;

  .btn {
    font-size: calc(@font-size / 1.3) !important;
    padding: 2px;
  }
}

.analytics-page {
  .analytics-container-white {
    background: @tables-background !important;

    .table-container-wrapper,
    .ul-inline-row {
      box-shadow: none !important;
      background: transparent;
      border: 0 !important;
      padding: 0 !important;
      .border-radius-4;
    }
    .item-settings-dropdown {
      display: none;
    }
  }

  h4 {
    padding-left: 15px;
  }

  .main-table {
    width: 100% !important;

    tr td {
      padding: 2px;
      vertical-align: middle !important;
      border: 0 !important;
    }

    tr td:nth-child(2),
    tr td:nth-child(3) {
      text-align: right !important;
      white-space: nowrap !important;
    }
  }

  .chart-graph-block {
    width: 100% !important;
  }

  .overflow-x-with-margin {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .analytics-container .table {
    .table-strip;
  }
}

.no-borders-table {
  tbody tr td {
    border: 0 !important;
  }
}

.analytics-container {
  background: @tables-background;
  padding: 20px !important;
  .box-shadow-light;
  padding-top: 0;
  border-radius: 8px;

  .start-page-header {
    font-size: 18px;

    small {
      font-size: 12px;
      font-family: @font-family-light;
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    width: 20px;
    height: 20px;
  }

  .table-container-wrapper,
  .ul-inline-row {
    box-shadow: none !important;
    background: transparent;
    padding: 0 !important;
    .border-radius-4;
  }

  .upcoming-updates-list {
    .box-shadow-light;
    padding: 0 !important;

    h4 {
      font-size: @font-size !important;
    }

    .wrap-text {
      font-size: calc(@font-size / 1.3) !important;
      text-align: left;
    }

    .col-md-2,
    .col-md-3,
    .col-md-4 {
      text-align: left;
      padding-left: 2px;
      padding-right: 2px;
      line-height: 1;
    }
  }

  canvas {
    border: 0 !important;
  }

  table {
    tr th {
      border: 0 !important;
      border-right: 1px solid @borders-color !important;
      background: @th-main-background !important;
    }

    tr td {
      .fa {
        font-size: calc(@font-size / 1.4);
      }

      .fa-trash,
      .fa-arrows {
        font-size: calc(@font-size / 1.3);
      }
    }
  }

  table.logistics-table {
    tr th {
      background: @tables-background !important;
    }
  }

  .progress {
    background: @color-black-opacity-dark;
  }

  .unscrollable-grid,
  .grid {
    .ui-grid-cell {
      background: transparent !important;
      border-bottom: 0 !important;
      font-size: calc(@font-size / 1.3);
      text-align: right;

      .fa {
        font-size: calc(@font-size / 1.4);
      }
    }

    .ui-grid-cell:nth-child(1) {
      text-align: left;
    }

    .ui-grid-cell-contents {
      font-size: calc(@font-size / 1.3) !important;
      background: transparent !important;
      padding: 0 !important;
    }

    .ui-grid-header-cell {
      text-align: right;
      background: transparent !important;
    }

    .ui-grid-header-cell:nth-child(1) {
      text-align: right;
    }
  }

  .chart-graph-block {
    width: 100%;
    padding: 0;
  }

  .inset-container {
    background: transparent !important;
  }

  .inset-container.header-margin-top {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .form-control {
    border-radius: 4px !important;
    border: 1px solid darken(@alt-background, 9%) !important;
  }

  .input-group .form-control:first-child {
    border-radius: 0 !important;
    margin-bottom: 0 !important;
  }

  .alert-success {
    .form-control {
      border-color: @btn-success-color !important;
      background: @status-success-background !important;
      margin-bottom: 4px;
    }

    .ui-select-container .form-control {
      border-color: @btn-success-color !important;
      background: @status-success-background !important;
    }
  }
}

.analytics-container.filters-well {
  .input-group .form-control:first-child {
    border-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-bottom: 0 !important;
  }
}

.details-analytics-container {
  .table-container-wrapper {
    border: 0 !important;
  }
}

.crop-passport-analytics-container {
  .table-container-wrapper,
  .ul-inline-row {
    border: 1px solid @borders-color !important;
    background: @tables-background;
    padding: 20px !important;
    border-radius: 8px !important;
  }
}

.purchase-plan-table {
  .ui-grid-cell {
    line-height: 2;
    height: 30px;
  }
}

.users-plan-table {
  .table-hover;

  .ui-grid-cell {
    line-height: 1;
    height: 22px;
    vertical-align: middle;
  }

  .main-table {
    border-color: @borders-color !important;

    tr td:first-child {
      a {
        font-family: @font-family-bold !important;
        text-transform: uppercase;
        color: @contract-executed-color !important;
        font-size: calc(@font-size / 1.3) !important;
        vertical-align: middle !important;
      }
    }

    tr td {
      border-top: 0 / 1.1 !important;
      border-right: 0 * 1.05 !important;
      padding: 2px !important;
      vertical-align: middle !important;
    }
  }
}

.navbar-analytics-container {
  padding: 0 !important;
  border: 0 !important;
}

.settings-filters {
  padding: 0 !important;

  .btn {
    width: 100% !important;
    margin-left: 0 !important;
    text-transform: uppercase;
    padding: 12px;
    padding-left: 5px;
    padding-right: 5px;

    .label_start {
      top: 11px;
      font-size: calc(@font-size * 1.1) !important;
      font-weight: 600 !important;
    }
  }

  .btn + .btn {
    margin-left: 0 !important;
  }

  .btn:hover {
    background: darken(@tables-background, 9%);
    margin-left: 0 !important;
  }
}

.list-filters {
  .btn {
    padding: 2px;
    padding-left: 12px;
    padding-right: 12px;
    background: darken(@borders-color, 5%) !important;
    border-radius: 12px !important;
    margin-bottom: 2px;

    .label_start {
      top: 3px;
      font-size: @font-size !important;
    }
  }

  .btn-primary {
    background: @tables-background !important;
    border: 1px solid darken(@borders-color, 9%) !important;
  }
}

.settings-iframe-container {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  border-radius: 8px;

  iframe {
    margin-top: -100px;
    width: 100%;
    height: 2200px;
  }
}

.documentation-page {
  .settings-iframe-container {
    height: 100vh !important;
  }
}

#clients-vertical-filter {
  background: transparent !important;

  .btn {
    background: darken(@highlighted-area, 5%);
  }

  .btn:hover {
    background: darken(@highlighted-area, 9%);
  }

  .btn:nth-child(1),
  .btn:nth-child(6),
  .btn:nth-last-child(2) {
    margin-bottom: 10px;
    border-bottom: 1px solid @borders-color !important;
  }
}

.trader-page {
  .overlay {
    .fa {
      left: 48% !important;
    }
  }
}

.user-perm-list {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;

  li {
    text-align: left;
    border-bottom: 1px solid @borders-color;
  }
}

.consolidated-page {
  .overlay {
    .fa {
      left: 50% !important;
    }
  }

  .table-with-total {
    .label {
      padding: 2px !important;
      display: inline-block;
      margin-top: 2px;
      font-size: calc(@font-size / 1.3);
    }

    tr td:first-child {
      text-align: left !important;
    }
  }
}

.contracts-page,
.vessels-page,
.logistics-page,
.request-page,
.create-contract-page,
.farm-page,
.elevator-page,
.payments-page,
.documents-page,
.pl-page {
  background: transparent !important;

  .filter-template {
    border: 0 !important;

    a {
      font-size: @font-size;
    }
  }

  .select-container {
    width: 50% !important;
  }

  .half-size-btn-group {
    width: 40% !important;
  }

  #outer-USD-table td,
  .export-table td,
  .vessels-table td,
  .inner-UAH-table td {
    width: auto !important;
  }

  .cut-table {
    tr > td {
      border-color: @borders-color !important;
    }

    tr > th {
      border-color: @borders-color !important;
    }

    .label {
      color: @label-color;
    }
  }
}

.create-contract-page,
.create-request-page,
.create-finance-page,
.create-passport-page {
  .page-header-main {
    .item-details-block-transparent {
      .item-details-el {
        .value {
          color: @white !important;

          a {
            color: @white !important;
          }

          .label {
            color: @white !important;
          }
        }

        .property {
          color: @white !important;

          a {
            color: @white !important;
          }
        }
      }
    }
  }

  .nav-tabs-cut {
    li > a {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    .thead-filter-btn {
      display: block !important;
    }

    li.active > a {
      span {
        display: inline-block !important;
      }
    }
  }
}

//other styles
.cg-notify-message {
  background: @navbar-user-background !important;
  top: 0 !important;
  left: 0 !important;
  position: fixed !important;
  border: 1px solid darken(@navbar-user-background, 9%);
  border-radius: 4px;
  text-align: center !important;
  padding: 6px 12px;
  font-family: @font-family-bold !important;
}

.ui-notification {
  background: darken(@label-success-color, 9%) !important;
  opacity: 0.9;
  border-radius: 4px;
  width: auto !important;

  h3 {
    padding: 0;
    font-family: @font-family-bold !important;
  }

  .message {
    font-size: @font-size !important;
    padding: 6px;
    margin: 5px;
    font-family: @font-family-bold !important;
  }

  .message::before {
    font-family: 'FontAwesome';
    content: '\f058  ';
  }
}

.notifications-table {
  tbody > tr > td {
    padding: 6px !important;
    vertical-align: middle;
    line-height: 1.1 !important;
    border-bottom: 1px solid @borders-color !important;

    .label {
      padding: 2px !important;
      text-align: center;
      margin: 0;
      vertical-align: middle;
    }
  }
}

.ui-notification.error {
  background: darken(@label-danger-color, 9%) !important;

  .message {
    .fa {
      display: none;
    }
  }
}

.ui-notification.error .message::before {
  font-family: 'FontAwesome';
  margin-right: 5px;
  content: ' \f06a ';
}

.ui-notification.warning {
  background: darken(@label-danger-color, 9%) !important;

  .message {
    .fa {
      display: none;
    }
  }
}

.ui-notification.warning .message::before {
  font-family: 'FontAwesome';
  margin-right: 5px;
  content: ' \f071 ';
}

.ui-notification.info {
  background: darken(@payments-tab, 9%) !important;
}

.ui-notification.info .message::before {
  font-family: 'FontAwesome';
  margin-right: 5px;
  content: ' \f05a ';
}

.notify-dropdown-btn {
  background: transparent !important;
  position: relative;
  display: block;
  float: none;
  text-align: center;
  margin: -2px;

  span {
    vertical-align: top;
    margin-top: 14px;
    .border-radius-4;
  }
}

.permissions-table {
  tr td {
    padding: 2px !important;
    margin: 0 !important;
    vertical-align: middle;
    line-height: 1 !important;
    white-space: normal;

    input,
    .checkbox,
    span,
    label {
      margin: 0 !important;
      line-height: 1 !important;
    }
  }

  .highlighted-tr {
    background: @highlighted-area !important;
    font-family: @font-family-bold !important;

    td,
    span {
      font-family: @font-family-bold !important;
    }

    td {
      background: @highlighted-area !important;
    }
  }

  td.label-strong {
    font-family: @font-family-bold !important;
  }
}

.notify-dropdown-menu {
  table {
    width: 100% !important;

    tr th {
      text-align: center;
    }

    tr td {
      white-space: nowrap;
      padding: 6px !important;
      text-align: left;
      font-size: @font-size !important;
      color: @contract-executed-color;
      font-family: @font-family-regular !important;
      border: 0 !important;
      border-bottom: 1px solid @borders-color !important;

      .fa {
        color: @contract-executed-color !important;
      }
    }

    tr td.message {
      white-space: normal !important;
      word-wrap: break-word !important;
    }
  }

  .read {
    .fa-check-circle {
      display: none;
    }
  }

  .read:hover {
    cursor: pointer;

    .fa-circle-o {
      display: none;
    }

    .fa-check-circle {
      display: inline-block;
    }
  }

  .detail:hover {
    cursor: pointer;
  }
}

.preloader {
  height: auto;
  width: 75px;
}

legend {
  color: @legend-color;
}

.navbar {
  border-radius: 0;
  margin-bottom: 0 !important;
}

.error-msg {
  font-size: 0.8em;
  color: @lower-zero-color;
}

.help-inline {
  padding: 1px;
  width: 100%;
  display: block;
  font-size: calc(@font-size / 1.3);
  background: @label-danger-color;
  color: @label-danger-font-color;
  font-family: @font-family-semibold;
}

.help-inline-hint {
  display: block;
  font-size: calc(@font-size / 1.3);
  font-family: @font-family-light;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  width: 58.3333%;
  float: right;
  color: @light-blue;
  text-align: left;
  border-top: 0 !important;
  white-space: normal;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.additional-field {
  color: @additional-field-color !important;
  font-size: 0.7em;
}

.vertical-divider {
  width: 1px;
  background-color: @vertical-divider-color;
  height: 20px;
  margin-top: 15px;
}

.horizontal-divider {
  background-color: darken(@borders-color, 5%);
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  page-break-after: always;
}

.last_login {
  font-size: 0.8em;
  color: @last-login-color !important;
  text-align: center;
  border-top: 1px solid @last-login-border;
  padding-top: 5px;
  margin-top: 5px;
  overflow-x: hidden;

  a {
    color: #666;
  }
}

.header-margin-top {
  margin-top: 5px;
  margin-bottom: 2px;
  padding-left: 0px;
  padding-right: 0px;

  small {
    font-size: 75%;
    color: @small-color;
    margin-top: 30px;
    margin-left: 5px;
    font-family: @font-family-light;
  }

  .row {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}

.small-screen-show {
  display: none !important;
}

.col-sm-10 {
  .header-margin-top {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.contract_detail_table {
  table-layout: fixed !important;
  width: 100%;

  tr td {
    word-break: break-all;
  }

  .label-opacity {
    box-shadow: none !important;
    background: transparent !important;
  }

  .last_update_cell {
    width: 100% !important;

    .label {
      width: 99.5%;
    }
  }

  .contract_details {
    width: 98%;
    height: 100%;
    margin: 0 auto;
    .border-radius-4;
  }

  tbody {
    box-shadow: none !important;
  }

  ul {
    margin-bottom: 0;
  }
}

.contract-stages-table {
  tr td {
    white-space: normal;
    width: 55px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    vertical-align: top !important;

    .contract-status-block {
      padding: 2px !important;
      margin-bottom: 0 !important;
      word-break: break-all;
    }

    .smaller-label {
      white-space: normal;
      word-wrap: break-word;
    }
  }

  tbody tr:nth-last-child(1) td {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

.logistics-payment-table {
  tr td {
    font-size: @font-size !important;
  }
}

.multipassport-table {
  border-bottom: 4px solid @borders-color !important;

  .super-highlighted-tr {
    background: darken(@highlighted-area, 5%) !important;

    td {
      background: darken(@highlighted-area, 5%) !important;
      border-right: 1px solid darken(@highlighted-area, 9%) !important;
      font-family: @font-family-regular !important;
    }

    span {
      font-family: @font-family-regular !important;
    }
  }

  .mega-highlighted-tr {
    background: darken(@highlighted-area, 9%) !important;

    td {
      background: darken(@highlighted-area, 9%) !important;
      border-right: 1px solid darken(@highlighted-area, 13%) !important;
      font-family: @font-family-semibold !important;
    }

    span {
      font-family: @font-family-bold !important;
    }
  }

  tr td {
    text-align: left !important;
    vertical-align: top !important;
    font-size: calc(@font-size / 1.2) !important;

    h5,
    h6 {
      margin-top: 0;
      margin-bottom: 0;
      color: @td-main-color;
      font-family: @font-family-regular !important;
    }

    h5 {
      font-size: calc(@font-size / 1.1);
    }

    h6 {
      font-size: calc(@font-size / 1.1);
    }
  }

  thead {
    display: none;
  }

  tbody {
    border-bottom: 3px solid @borders-color !important;
  }

  tr td:nth-child(1) a,
  tr th:nth-child(1) a {
    font-size: calc(@font-size / 1.1) !important;
  }

  tr td:nth-child(1) a.smaller-label,
  tr th:nth-child(1) a.smaller-label {
    font-size: calc(@font-size / 1.3) !important;
  }

  tr td:nth-child(1),
  tr th:nth-child(1) {
    width: 250px !important;
  }

  tr td.product,
  tr th.product {
    width: 110px !important;
  }

  tr td.volume,
  tr th.volume {
    width: 80px !important;
  }

  tr td.price,
  tr th.price {
    width: 60px !important;
  }

  tr td.basis,
  tr th.basis {
    width: 35px !important;
  }

  tr td.value,
  tr th.value {
    width: 150px !important;
  }

  tr td.charges,
  tr th.charges {
    width: 100px !important;
  }

  tr td.debit,
  tr th.debit {
    width: 100px !important;
  }

  tr td.invoice,
  tr th.invoice {
    width: 150px !important;
  }

  .label {
    display: inline-flex !important;
    padding: 2px !important;
    margin: 0 !important;
  }

  .alert {
    padding: 2px !important;
    .border-radius-4;
    margin: 0 !important;
    margin-bottom: 2px !important;
    margin-top: 2px !important;

    h5 {
      font-size: calc(@font-size / 1.1) !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  tbody > tr td:nth-child(1),
  thead > tr th:nth-child(1) {
    padding: 2px !important;

    .label {
      display: inline-flex !important;
      padding: 2px !important;
    }
  }

  .inside-table-ul {
    margin-bottom: 0;

    li {
      text-align: left !important;
      font-size: calc(@font-size / 1.3) !important;
      padding: 2px !important;
      white-space: normal;

      .label {
        font-size: calc(@font-size / 1.3) !important;
      }
    }

    li:first-child {
      border-bottom: 0 !important;
    }
  }
}

.multipassport-table:nth-of-type(1) {
  thead {
    display: table-header-group;
  }
}

.multipassport-table:last-child {
  border-bottom: 0 !important;
}

.credit-report-table {
  border-collapse: collapse !important;

  tr td {
    padding: 4px 6px !important;
    vertical-align: middle !important;

    .label {
      font-size: calc(@font-size / 1.2) !important;
    }
  }
}

.credit-report-table tbody > tr td:nth-child(1),
.credit-report-table thead > tr th:nth-child(1) {
  padding: 4px 6px !important;
}

.credit-report-table tbody > tr > td,
.credit-report-table thead > tr > th {
  padding: 4px 6px !important;
}

.derivatives-position-container .table-container-wrapper,
.derivatives-position-container .ul-inline-row {
  background: @tables-background !important;
}

.statistics-costs-table {
  table-layout: fixed;
  border-bottom: 0 !important;

  tbody {
    border-bottom: 1px solid @borders-color !important;
  }

  h6 {
    padding: 0 !important;
    margin: 0 !important;
  }

  tr td,
  tr th {
    width: 130px !important;
    min-width: 130px !important;
    max-width: 130px !important;
    vertical-align: middle !important;
  }

  tr td:first-child,
  tr th:first-child {
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
    white-space: normal !important;

    h6 {
      font-size: @font-size !important;
      color: @contract-executed-color !important;
    }

    .fa {
      color: @smoke !important;
    }
  }

  tr td {
    font-size: calc(@font-size / 1.1) !important;
    line-height: 1;
    padding: 2px !important;

    .positive-number,
    .negative-number {
      font-family: @font-family-regular !important;
    }
  }
}

.costs-report-table {
  table-layout: fixed;
  border-bottom: 0 !important;
  overflow: auto;
  width: 100% !important;
  white-space: normal !important;
  vertical-align: middle !important;
  font-size: calc(@font-size / 1.1);
  background: transparent;

  tbody {
    border-bottom: 1px solid @borders-color !important;
  }
  tr.highlighted-tr-level-0 {
    background: darken(@highlighted-area, 9%) !important;
    font-family: @font-family-bold !important;
  }
  tr {
    background: transparent;
    font-family: @font-family-regular;
  }
  thead tr {
    height: 51px;
    line-height: 3;
  }
  thead tr th {
    position: sticky !important;
    margin: 5px;
    top: 0;
    z-index: 20;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    display: table-cell;
    white-space: nowrap;
    text-transform: capitalize !important;
    padding: 4px !important;
  }
  tbody tr td {
    border-color: @borders-color !important;
    color: @main-table-td;
    padding: 2px !important;
    text-align: left;
    overflow: hidden;
    display: table-cell;
    white-space: nowrap;
  }
  thead tr th.column-number,
  thead tr th.column-group {
    z-index: 40;
    top: 0 !important;
  }
  tbody tr td.column-number,
  tbody tr td.column-group {
    z-index: 30;
  }
  thead tr th.column-number,
  tbody tr td.column-number {
    position: sticky !important;
    left: 0 !important;
    width: 35px !important;
    min-width: 35px !important;
    max-width: 35px !important;
    font-size: calc(@font-size * 1) !important;
    background: @tables-background;
  }

  thead tr th.column-group,
  tbody tr td.column-group {
    position: sticky !important;
    left: 35px !important;
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
    background: @tables-background;
  }

  tbody tr td.column-details,
  thead tr th.column-details,
  tbody tr td.column-agg,
  thead tr th.column-agg {
    width: 150px;
    min-width: 150px;
    max-width: 200px;
  }
  tbody tr td.column-agg {
    text-align: right;
  }
  tbody tr td.column-details {
    white-space: normal;
  }
}
.report-cross-table {
  table-layout: fixed;
  border-bottom: 0 !important;
  overflow: auto;
  width: 100% !important;
  white-space: normal !important;
  vertical-align: middle !important;
  font-size: calc(@font-size / 1.2);
  background: transparent;
  @column-height: 51px;
  @column-number-width: 35px;
  @column-group-width: 250px;
  @column-totals-width: 250px;
  @column-agg-width: 300px;
  @column-resource-width: 400px;
  @column-resource-agg-width: 80px;

  tbody {
    border-bottom: 1px solid @borders-color !important;
  }
  tr {
    background: transparent;
    font-family: @font-family-regular;
  }
  thead tr {
    height: @column-height;
    line-height: 3;
  }
  thead tr th {
    position: sticky !important;
    margin: 5px;
    top: 0;
    z-index: 20;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    display: table-cell;
    white-space: nowrap;
    text-transform: capitalize !important;
    min-height: @column-height;
    padding: 4px !important;
  }
  thead tr:nth-child(1) th {
    top: 0;
  }
  thead tr:nth-child(2) th {
    top: @column-height;
  }
  thead tr:nth-child(3) th {
    top: 2 * @column-height;
  }
  thead tr:nth-child(4) th {
    top: 3 * @column-height;
  }
  thead tr:nth-child(5) th {
    top: 4 * @column-height;
  }
  tbody tr td {
    border-color: @borders-color !important;
    color: @main-table-td;
    text-align: left;
    overflow: hidden;
    display: table-cell;
    white-space: nowrap;
  }
  thead tr th.column-totals,
  thead tr th.column-number,
  thead tr th.column-group {
    z-index: 40;
    top: 0 !important;
  }
  tbody tr td.column-totals,
  tbody tr td.column-number,
  tbody tr td.column-group {
    z-index: 30;
  }
  thead tr th.column-totals,
  tbody tr td.column-totals {
    position: sticky !important;
    left: (@column-number-width + @column-group-width) !important;
    width: @column-totals-width;
    min-width: @column-totals-width;
    max-width: @column-totals-width;
    font-size: calc(@font-size / 1.2) !important;
    background: @tables-background;
  }
  thead tr th.column-number,
  tbody tr td.column-number {
    position: sticky !important;
    left: 0 !important;
    width: @column-number-width;
    min-width: @column-number-width;
    max-width: @column-number-width;
    font-size: calc(@font-size / 1.2) !important;
    background: @tables-background;
  }

  thead tr th.column-group,
  tbody tr td.column-group {
    position: sticky !important;
    left: @column-number-width !important;
    width: @column-group-width;
    min-width: @column-group-width;
    max-width: @column-group-width;
    background: @tables-background;
  }
  thead tr th.columns-total,
  thead tr td.columns-total,
  thead tr th.columns-number,
  thead tr td.columns-number,
  thead tr th.columns-group,
  tbody tr td.columns-group {
    background: @tables-background;
  }
  tbody tr td.details-info {
    white-space: normal;
  }

  tbody tr td.column-details,
  thead tr th.column-details,
  tbody tr td.column-agg,
  thead tr th.column-agg {
    width: @column-agg-width;
    min-width: @column-agg-width;
    max-width: @column-agg-width;
  }
  tbody tr td.column-resource,
  thead tr th.column-resource {
    width: @column-resource-width;
    min-width: @column-resource-width;
    max-width: @column-resource-width;
  }
  tbody tr td.column-resource-agg,
  thead tr th.column-resource-agg {
    width: @column-resource-agg-width;
    min-width: @column-resource-agg-width;
    max-width: @column-resource-agg-width;
  }
  tbody tr td.column-vertical-group,
  thead tr th.column-vertical-group {
    width: @column-agg-width;
    min-width: @column-agg-width;
    max-width: 100 * @column-agg-width;
  }
  thead tr th.column-agg:nth-child(0) {
    top: @column-height;
  }
  thead tr th.column-agg:nth-child(1) {
    top: @column-height;
  }
  thead tr th.column-agg:nth-child(2) {
    top: 2 * @column-height;
  }
  thead tr th.column-agg:nth-child(3) {
    top: 3 * @column-height;
  }
  tbody tr td.column-agg {
    text-align: right;
  }
  tbody tr td.column-details {
    white-space: normal;
  }
  tr td:nth-child(2) {
    padding-left: 55px;
  }
}
.highlighted-tr-level-0 {
  background: darken(@highlighted-area, 12%) !important;
  font-family: @font-family-bold !important;
  td:nth-child(1) {
    padding-left: 3px !important;
  }
  td:nth-child(2) {
    padding-left: 5px !important;
  }
}
.highlighted-tr-level-1 {
  background: darken(@highlighted-area, 10%) !important;
  font-family: @font-family-bold !important;
  td:nth-child(1) {
    padding-left: 6px !important;
  }
  td:nth-child(2) {
    padding-left: 15px !important;
  }
}
.highlighted-tr-level-2 {
  background: darken(@highlighted-area, 9%) !important;
  font-family: @font-family-semibold !important;
  td:nth-child(1) {
    padding-left: 9px !important;
  }
  td:nth-child(2) {
    padding-left: 25px !important;
  }
}
.highlighted-tr-level-3 {
  background: darken(@highlighted-area, 6%) !important;
  font-family: @font-family-semibold !important;
  td:nth-child(1) {
    padding-left: 12px !important;
  }
  td:nth-child(2) {
    padding-left: 35px !important;
  }
}
.highlighted-tr-level-4 {
  background: darken(@highlighted-area, 3%) !important;
  font-family: @font-family-semibold !important;
  td:nth-child(1) {
    padding-left: 15px !important;
  }
  td:nth-child(2) {
    padding-left: 45px !important;
  }
}
.highlighted-tr-level-5 {
  background: darken(@highlighted-area, 1%) !important;
  font-family: @font-family-semibold !important;
  td:nth-child(1) {
    padding-left: 18px !important;
  }
  td:nth-child(2) {
    padding-left: 55px !important;
  }
}
.highlighted-tr-level-details {
  background: @highlighted-area !important;
  font-family: @font-family-light !important;
  td:nth-child(2) {
    padding-left: 5px !important;
  }
}

.super-highlighted-tr {
  background: darken(@highlighted-area, 5%) !important;

  td {
    background: darken(@highlighted-area, 5%) !important;
    border-right: 1px solid darken(@highlighted-area, 9%) !important;
    font-family: @font-family-semibold !important;
  }

  span {
    font-family: @font-family-semibold !important;
  }
}

.mega-highlighted-tr {
  background: darken(@highlighted-area, 9%) !important;

  td {
    background: darken(@highlighted-area, 9%) !important;
    border-right: 1px solid darken(@highlighted-area, 13%) !important;
    font-family: @font-family-bold !important;
  }

  span {
    font-family: @font-family-bold !important;
  }
}
td.column-number.highlighted-tr-level-2 {
  padding-left: 10px !important;
}
td.column-number.highlighted-tr-level-3 {
  padding-left: 20px !important;
}
td.column-number.highlighted-tr-level-4 {
  padding-left: 25px !important;
}
td.column-number.highlighted-tr-level-5 {
  padding-left: 30px !important;
}
td.column-number.highlighted-tr-level-6 {
  padding-left: 35px !important;
}

.table-nowrap {
  tr td {
    white-space: nowrap !important;
    word-break: break-word !important;
  }
}

.passports-table {
  td.td-left-align {
    text-align: left !important;
  }

  td.td-right-align {
    text-align: right !important;
  }

  td.td-center-align {
    text-align: center !important;
  }

  h6 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  div.highlighted {
    border-left: 6px solid @label-info-color;
    margin-bottom: 10px;
    padding-left: 5px;
  }

  .passports-wrapper-light-mode {
    padding: 5px;
  }

  .passports-wrapper-light-mode .request-list {
    margin-right: 5px;
    float: left;
    border-radius: 12px !important;
    max-width: 220px !important;
    overflow: hidden;
    min-width: 220px;
  }
  .contract-details-container-cut h2 {
    font-size: @font-size !important;
    margin-bottom: 2px !important;
    margin-top: 0 !important;
    white-space: nowrap !important;
    font-family: @font-family-regular !important;
  }

  .progress {
    display: inline-flex;

    .progress-bar {
      padding: 2px !important;
    }
  }

  .well {
    margin-top: 5px;
  }

  .table-inside-table {
    background: transparent !important;
    margin: 0 !important;

    tr th {
      padding: 4px !important;
      border: 0 !important;
      font-size: calc(@font-size / 1.4) !important;
      background: transparent !important;
      text-align: left !important;
    }

    tr td {
      font-size: calc(@font-size / 1.3) !important;
      border: 0 !important;
      padding: 1px !important;
      white-space: nowrap !important;
      vertical-align: middle;
      word-break: keep-all !important;

      .label {
        font-size: calc(@font-size / 1.3) !important;
      }
    }

    .highlighted-tr > td {
      background: @color-black-opacity-very-very-dark !important;
    }
  }

  .counterparty_detail_table tr td {
    border-bottom: 0 !important;
    word-break: break-word !important;
    white-space: normal !important;
    font-size: calc(@font-size / 1.3) !important;
    .label {
      font-size: calc(@font-size / 1.3) !important;
    }
    .progress {
      border-radius: 4px !important;
    }
    vertical-align: middle !important;
  }
  .counterparty_detail_table tr.highlighted-tr td {
    text-align: center !important;
    padding: 6px !important;
    background: @highlighted-area !important;
  }
  .overflow-y-small {
    .analytics-container {
      padding: 5px !important;
      background: transparent !important;
    }

    .upcoming-updates-list,
    .add-new-inline {
      background: @tables-background !important;
    }

    .status-blocks {
      .contract-status-block {
        overflow: hidden;
        font-size: calc(@font-size / 1.3) !important;
        &::first-letter {
          text-transform: uppercase !important;
        }
      }
    }
  }

  .inside-table-ul {
    margin-bottom: 0;
    padding: 0;

    li {
      display: block;
      width: 100%;
      clear: both;
      height: 15px;
      padding: 2px;
      text-align: left;
      font-size: calc(@font-size / 1.3) !important;

      .label {
        padding: 2px !important;
        font-size: calc(@font-size / 1.3) !important;
      }
    }

    li.li-auto-height {
      height: auto;
    }

    li:last-child {
      border-bottom: 0 !important;
    }
  }

  .ul-inside-table {
    margin-bottom: 0;

    li {
      display: block;
      width: 100%;
      height: 15px;
      padding: 2px;
      clear: both;
      font-size: calc(@font-size / 1.3) !important;

      .label {
        padding: 2px !important;
        font-size: calc(@font-size / 1.3) !important;
      }
    }

    li:last-child {
      border-bottom: 0 !important;
    }

    li.li-auto-height {
      height: auto;
    }
  }

  tr th {
    padding: 2px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    height: 50px !important;
    text-align: center !important;
  }

  tr td {
    padding: 2px !important;
    vertical-align: top !important;
    text-align: left;
    font-size: @font-size !important;
    border-bottom: 3px solid darken(@borders-color, 10%) !important;

    .alert {
      padding: 4px;
      margin-bottom: 5px;

      .progress {
        float: none;
      }
    }
  }

  tr td.alert-info {
    border-color: @blue-background !important;
  }

  tr td.alert-warning {
    border-color: @label-warning-background !important;
  }

  td.small-font-cell {
    font-size: calc(@font-size / 1.3) !important;

    a,
    span,
    .label,
    .btn-link {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  tr td.nowrap {
    white-space: nowrap !important;
  }

  #passport-properties-costs {
    .center-block;
    min-height: 35px;

    table {
      width: 100%;
      display: inline-table;
    }

    th {
      font-size: calc(@font-size / 1.3) !important;
      border-bottom: 2px solid @borders-color;
      color: @contract-executed-color;
      text-align: right;
    }

    td {
      padding: 2px !important;
      font-size: @font-size !important;
      text-align: right;
    }

    td:first-child {
      color: @contract-processing-color !important;
      font-family: @font-family-bold !important;
      text-transform: uppercase;
    }

    th:first-child,
    td:first-child {
      text-align: left;
    }

    tr:last-child td {
      border-bottom: 0 !important;
    }
  }

  #passport-properties-result {
    .center-block;

    table {
      width: 100% !important;
    }

    th {
      font-size: calc(@font-size / 1.3) !important;
      border-bottom: 2px solid @borders-color;
      color: @contract-executed-color;
      text-align: right;
    }

    td {
      padding: 2px;
      text-align: right;
    }

    td:first-child {
      color: @contract-processing-color !important;
      font-family: @font-family-bold !important;
      text-transform: uppercase;
    }

    th:first-child,
    td:first-child {
      text-align: left;
    }

    tr:last-child td {
      border-bottom: 0 !important;
    }

    .highlighted-td {
      font-family: @font-family-bold !important;
    }
  }

  .passport-header-badges {
    .label {
      margin-left: 2px;
      padding: 2px !important;
    }
  }

  .passport_executed > td:first-of-type {
    border-left: 5px solid @label-default-background !important;
    background: @tables-background !important;
    color: @contract-executed-color !important;

    a {
      color: @contract-executed-color !important;
    }

    .label,
    .badge {
      .label_default;
    }

    .label_danger {
      .label_danger !important;
    }
  }

  .passport_new > td:first-of-type {
    border-left: 5px solid @contract-new-color !important;
    background: @status-success-background !important;
    color: @contract-new-color !important;

    a {
      color: @contract-new-color !important;
    }

    .label,
    .badge {
      .label_success;
    }

    .label_danger {
      .label_danger !important;
    }

    .dropdown-menu a {
      color: @contract-executed-color !important;
    }
  }

  .passport_processing > td:first-of-type {
    border-left: 5px solid @label-warning-color !important;
    background: @tables-background !important;
    color: @contract-processing-color !important;

    a {
      color: @contract-processing-color !important;
    }

    .label,
    .badge {
      .label_warning;
    }

    .label_danger {
      .label_danger !important;
    }

    .dropdown-menu a {
      color: @contract-executed-color !important;
    }
  }
}

.executed_payment,
.contract_executed {
  border-top-color: @label-default-color !important;

  .value {
    color: @contract-executed-color !important;
  }
}

.new_payment,
.contract_new {
  border-top-color: @label-success-color !important;

  .value {
    color: @contract-executed-color !important;
  }
}

.process_payment,
.contract_processing {
  border-top-color: @label-warning-color !important;

  .value {
    color: @contract-executed-color !important;
  }
}

.contract_cancelled,
.passport_cancelled {
  border-top-color: @label-default-color !important;

  a {
    color: @contract-executed-color !important;
  }

  .value {
    color: @contract-executed-color !important;
  }
}

.passport_approved:first-of-type > td:first-of-type {
  border-left: 5px solid @label-calculate-color !important;
  background: @status-exclamation-background !important;

  a {
    color: darken(@label-calculate-color, 17%) !important;
  }

  .label,
  .badge {
    .label_calculate;
  }

  .label_danger {
    .label_danger !important;
  }
}

.passport-crop-filter {
  .nav-tabs {
    width: 100%;
    border-bottom: 0;

    li {
      display: table-cell;
      width: 1%;
      float: none;
    }
  }

  .col-sm-6.center-block {
    width: 100% !important;
  }
}

.contract-details-container {
  padding: 0;
  border-radius: 0;
  margin-bottom: 0;

  small {
    margin-top: 0 !important;
    font-family: @font-family-light;

    .contract_details {
      margin-bottom: 2px;
      margin-right: 2px;
      float: left;
      white-space: nowrap;
      padding: 2px !important;
    }

    .contract-description-label {
      margin-bottom: 0px;
      font-size: calc(@font-size / 1.3) !important;
      padding: 6px;
    }
  }

  h2 {
    font-size: 22px !important;
    margin-bottom: 2px !important;
    margin-top: 0 !important;
  }

  .updated_time {
    background: transparent !important;
    color: @contract-executed-color !important;
  }

  .label-details {
    font-size: calc(@font-size / 1.3) !important;
    box-shadow: 0px 0px 1px #000;
    background: transparent !important;
    color: @contract-executed-color;
    margin-left: 1px;
  }
}

.contract-details-table {
  tr td {
    padding: 2px !important;
    font-size: @font-size !important;
    white-space: normal;
    word-break: break-word;
    border: 0 !important;
    line-height: 1;
    text-align: right;

    .label {
      float: right !important;
      padding: 2px !important;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      width: auto !important;
    }

    .btn-link {
      font-size: @font-size !important;
    }

    .btn-group-vertical > .btn,
    .btn-group > .btn {
      padding: 2px 2px;
      font-size: calc(@font-size / 1.3) !important;
    }

    a {
      cursor: pointer;
    }
  }

  td:first-child {
    font-size: @font-size !important;
    text-align: left;
    white-space: nowrap;
    text-transform: uppercase !important;
    vertical-align: middle;
    font-family: @font-family-semibold !important;
  }

  tr:first-child td {
    border-top: 0 !important;

    .label {
      float: none !important;
    }
  }
}

table.basis-port-prices-table {
  tr td,
  tr th {
    padding: 2px !important;
    font-size: calc(@font-size / 1.4) !important;
    height: 10px !important;
    border-right: 0 !important;
    text-align: left !important;
    white-space: nowrap;
    word-wrap: break-word;
    .no-text-stroke;
    .form-control .ui-select-match-text {
      min-height: auto !important;
      font-size: calc(@font-size / 1.2) !important;
    }
  }

  tbody {
    box-shadow: none !important;
  }

  tr td {
    background: @color-black-opacity !important;
  }

  thead > tr > th:first-child,
  tbody > tr > td:first-child {
    display: table-cell !important;
    position: relative !important;
    height: 10px !important;
  }
}

contract-prices-info table.basis-port-prices-table {
  tr td,
  tr th {
    white-space: normal;
  }
}

.item-details-block {
  width: 100%;
  display: -webkit-box;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  display: -moz-box;
  -moz-box-pack: justify;
  -moz-box-align: center;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  display: box; //deprecated but working
  box-pack: justify;
  box-align: center;
  background: @color-black-opacity;
  margin-top: 5px;
  line-height: 1 !important;

  .table-no-borders {
    tr td {
      font-size: calc(@font-size / 1.3) !important;

      h4 {
        font-size: @font-size !important;
      }
    }
  }

  .item-details-el {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    .border-radius-4;

    .value {
      font-size: @font-size;
      color: @contract-executed-color;
      font-family: @font-family-regular !important;
      padding: 0 !important;

      .label {
        font-family: @font-family-regular !important;
      }
    }

    .property {
      color: @contract-executed-color;
      font-size: calc(@font-size / 1.3);
      text-transform: lowercase;
      line-height: 1 !important;
      font-family: @font-family-light !important;
    }

    .label,
    .badge {
      font-size: @font-size;
      line-height: 1 !important;
      padding: 2px !important;
    }

    span {
      line-height: 1 !important;
    }
  }
}

.item-details-block-transparent {
  .item-details-block;
  background: transparent !important;
}

.item-details-block-justified {
  .item-details-block;
  display: block !important;

  .item-details-el {
    width: 100%;
    display: block;
    padding: 5px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    .border-radius-4;

    .label {
      float: none !important;
    }
  }

  .item-details-el-inline {
    display: inline-block;
    width: 25%;
  }
}

.related-page {
  .contract-details-container-cut {
    .btn-link {
      font-size: 0.7em !important;
    }
  }
}

.passports-report-table {
  tr th {
    text-align: center !important;
  }

  tr th:nth-child(2) {
    background: transparent;
  }

  tr th:nth-child(1) {
    background: transparent !important;
  }

  tr td:nth-child(1) {
    background: transparent !important;
  }

  tr td:nth-child(1).highlighted-td {
    background: @highlighted-area !important;
  }

  tr td {
    border-right: 1px solid @borders-color !important;
  }

  td.highlighted-td {
    background: @highlighted-area !important;
  }

  tr td:nth-child(2) {
    background: transparent;

    .inside-table-ul {
      li {
        text-align: left !important;

        span:first-child {
          float: none !important;
        }

        span:last-child {
          float: none !important;
        }
      }
    }
  }
}

.contract-plans-ul {
  li {
    white-space: nowrap !important;

    span {
      white-space: nowrap !important;
    }
  }
}

.contracts-report-table,
.tickets-report-table,
.indicators-report-table {
  .passports-report-table;

  .inside-table-ul {
    li {
      text-align: center !important;

      span:first-child {
        float: none !important;
      }

      span:last-child {
        float: none !important;
      }
    }
  }
}

.prices-position-inline-container {
  .row {
    div {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

.position-report-table {
  .contracts-report-table;

  li {
    white-space: nowrap;
  }

  .label {
    display: initial !important;
  }
}

.position-consolidated-table {
  tr td {
    white-space: nowrap !important;

    .label,
    .label-strong {
      display: inline-flex !important;
    }
  }

  tr td:first-child {
    width: auto !important;
  }
}

.page-break-el {
  page-break-before: always;
  page-break-after: always;
}

.position-passport-table {
  tbody + tbody {
    border-top: 3px solid @alt-background !important;
    box-shadow: none !important;
  }

  tr th {
    padding: 0 !important;
  }

  tr td {
    text-align: right !important;
    vertical-align: top !important;
    font-size: calc(@font-size / 1.1) !important;
    border-right: 1px solid @borders-color !important;
    padding: 2px !important;
    white-space: nowrap !important;

    .label {
      font-size: calc(@font-size / 1.1) !important;
      display: inline-flex;
    }

    .badge {
      font-size: calc(@font-size / 1.4) !important;
    }

    .bigger-label {
      font-size: @font-size !important;
    }

    .btn-link {
      font-size: calc(@font-size / 1.3) !important;
    }

    .fa {
      font-size: calc(@font-size / 1.4) !important;
    }

    .inside-table-ul {
      margin-bottom: 0 !important;
    }

    .alert {
      display: block;
      margin: 0;
      margin-top: 2px;
      padding: 2px;
    }
  }

  tr td.td-left-align {
    text-align: left !important;

    .label {
      text-align: left !important;
    }
  }

  td.smaller-font-td {
    font-size: calc(@font-size / 1.3) !important;
  }

  tbody:nth-last-child(1) .highlighted-td {
    background: @main-background-color !important;
  }

  td.no-wrap {
    white-space: nowrap;
    min-width: 80px;
  }

  tr td:nth-child(1) {
    color: @contract-executed-color !important;
    text-align: left !important;

    .btn-link {
      text-transform: initial !important;
      color: @btn-link-color !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .progress {
    .progress-bar {
      font-size: @font-size !important;
    }
  }

  tr td:nth-child(1).td-right-align {
    text-align: right !important;
  }

  tr td:nth-last-child(1),
  tr td:nth-last-child(2),
  tr td:nth-last-child(3),
  tr td:nth-last-child(5),
  tr td:nth-last-child(6),
  tr td:nth-last-child(7),
  tr td:nth-last-child(8) {
    white-space: nowrap !important;
  }

  .highlighted-tr {
    background: @highlighted-area !important;
    font-family: @font-family-bold !important;

    td,
    span {
      font-family: @font-family-bold !important;
    }
  }
}

table.inside-table-table {
  tbody > tr > td,
  thead > tr > th {
    padding: 2px !important;
    border: 0 !important;
    vertical-align: middle !important;
  }
}

.operations-table {
  tr > td {
    white-space: normal !important;
  }
}

.purchase-position-table {
  tr td {
    .label {
      padding: 2px !important;
      font-size: calc(@font-size / 1.3) !important;
    }

    .bigger-label {
      font-size: @font-size !important;
    }
  }

  tr td.smaller-font-cell {
    font-size: calc(@font-size / 1.3) !important;
  }

  td.highlighted-td {
    background: @color-black-opacity-dark !important;
    border: 0 !important;
  }

  tbody + tbody {
    border-top: 0 !important;
  }

  .purchase-ctr-row {
    border-top: 3px solid @borders-color !important;
  }
}

.position-crop-table {
  .position-passport-table;

  tbody + tbody {
    border-top: 0 !important;
  }

  tr td {
    padding: 6px !important;

    .bigger-label {
      font-size: calc(@font-size * 1.2) !important;
      font-family: @font-family-semibold !important;
    }
  }

  tr th {
    padding: 6px !important;
  }

  tr td:nth-child(1) {
    border-bottom: none !important;
    text-align: left !important;
    font-family: @font-family-semibold !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
  }

  .inside-table-ul {
    margin-bottom: 0 !important;

    li {
      border: 0 !important;

      .badge {
        font-size: calc(@font-size / 1.3);
      }
    }
  }

  .alert-success {
    .label {
      color: @label-success-color !important;
    }
  }

  .alert-danger {
    .label {
      color: @label-danger-color !important;
    }
  }
}

.lot-batches-table {
  .table-hover;

  table tr:first-child td {
    .alert-success;
  }

  table tr td {
    border-right: 1px solid @borders-color !important;
  }
}

.futures-position-table {
  .position-passport-table;

  tr td {
    padding: 2px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    vertical-align: middle !important;
    font-size: calc(@font-size / 1.1) !important;

    .bigger-label {
      font-size: calc(@font-size * 1.2) !important;
      font-family: @font-family-semibold !important;
    }

    .form-control {
      min-width: auto;
    }

    .form-control-small {
      min-width: auto;
      font-size: @font-size !important;
      font-family: @font-family-regular !important;
      height: 12px !important;
    }
  }

  tr th {
    padding: 2px !important;
  }

  tr.highlighted-tr {
    td {
      background: @highlighted-area !important;
    }
  }

  tr td:nth-child(1) {
    border-bottom: none !important;
    text-align: left !important;
    font-family: @font-family-regular !important;
    text-transform: uppercase;
  }

  .inside-table-ul {
    margin-bottom: 0 !important;
    background: @highlighted-area;

    li {
      .badge {
        font-size: calc(@font-size / 1.3);
      }
    }
  }

  .alert-success {
    padding: 2px !important;

    .label {
      color: @label-success-color !important;
    }
  }

  .alert-danger {
    padding: 2px !important;
    margin: 0 !important;

    .label {
      color: @label-danger-color !important;
    }
  }
}

.passport-position-crop-table {
  .position-passport-table;
  tbody + tbody {
    border-top: 0 !important;
  }

  .thick-bottom-border {
    border-bottom: 3px solid @borders-color !important;
  }

  tr td {
    .bigger-label {
      font-size: calc(@font-size * 1.2) !important;
      font-family: @font-family-semibold !important;
    }

    .not-bold {
      font-family: @font-family-regular !important;
    }

    .form-control {
      min-width: auto;
    }

    .form-control-small {
      min-width: auto;
      font-size: @font-size !important;
      font-family: @font-family-regular !important;
      height: 12px !important;
    }

    h6 {
      margin-bottom: 5px !important;
      border-bottom: 1px solid darken(@borders-color, 23%);
    }
  }

  tr td:nth-child(1) {
    border-bottom: none !important;
    text-align: left !important;
    font-family: @font-family-semibold !important;
  }

  .inside-table-ul {
    margin-bottom: 0 !important;

    li {
      .badge {
        font-size: calc(@font-size / 1.3);
      }
    }
  }

  .alert-success {
    .label {
      color: @label-success-color !important;
    }
  }

  .alert-danger {
    .label {
      color: @label-danger-color !important;
    }
  }
}

.passport-position-prices-table {
  tr td {
    .form-control {
      font-size: calc(@font-size / 1.3) !important;
      padding: 2px !important;
      text-overflow: clip;
      min-width: auto !important;
      display: block !important;
      height: 12px !important;
      max-height: 12px !important;
      min-height: 12px !important;
      line-height: 1 !important;
    }
  }
}

.consolidated-position-crop-table {
  h6 {
    margin-bottom: 5px !important;
    margin-top: 10px !important;
    text-align: left;
  }

  tr td:first-child {
    font-family: @font-family-regular;
    font-size: calc(@font-size / 1.1) !important;
    word-break: break-all;
  }

  tr th {
    padding: 2px !important;
  }

  tr td {
    font-size: calc(@font-size / 1.1) !important;
    padding: 2px !important;

    .alert-success,
    .alert-danger {
      white-space: nowrap;
      overflow: hidden;

      .fa {
        font-size: calc(@font-size / 1.1) !important;
      }
    }

    span,
    a,
    i,
    .label {
      font-size: calc(@font-size / 1.1) !important;
    }

    .label {
      padding: 2px !important;
      margin: 0;
    }

    .smaller-label {
      font-size: calc(@font-size / 1.3) !important;
      padding: 2px !important;
      letter-spacing: @letter-spacing-huge;
    }

    .inside-headers-big {
      font-size: calc(@font-size / 1.2) !important;
      background: @tables-background;
      padding: 4px !important;
      min-width: 150px;
      white-space: nowrap !important;
      text-align: left;
      margin-bottom: 5px;
      margin-top: 5px;
      border-top: 2px dashed darken(@highlighted-area, 23%) !important;

      .label {
        white-space: nowrap !important;
      }
    }

    .inside-headers-small {
      font-size: calc(@font-size / 1.3) !important;
      font-family: @font-family-semibold;
      margin-bottom: 5px;
      padding-left: 5px;
      white-space: nowrap !important;
      text-align: left;
      .span-right-align {
        font-size: calc(@font-size / 1.3) !important;
        margin-top: 3px;
      }
    }

    .span-right-align {
      text-align: right !important;
      float: right;
      margin-left: 15px;
    }
  }

  tr th.thick-border,
  tr td.thick-border {
    border-right: 3px solid @borders-color !important;
  }

  th.thick-highlighted,
  td.thick-highlighted {
    background: darken(@highlighted-area, 23%) !important;
  }

  .inside-table-ul {
    li {
      text-align: left;
      font-size: calc(@font-size / 1.3);
      white-space: nowrap !important;
      padding-left: 10px;
      border-bottom: 1px dashed darken(@highlighted-area, 27%) !important;
    }

    li:first-child {
      border-top: 0;
    }
    li:last-child {
      border-bottom: 0 !important;
    }
  }
}

.pnl-consolidated-table {
  table-layout: fixed;
  tr td {
    vertical-align: top !important;
    ul {
      margin-bottom: 0;
    }

    .bigger-label {
      font-size: @font-size !important;
      font-family: @font-family-bold !important;
    }

    .horizontal-divider {
      padding: 1px;
      margin: 2px;
      display: block;
      clear: both;
    }
  }

  .inside-table-ul > li {
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 1px solid darken(@borders-color, 9%) !important;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 0;
  }

  .inside-table-ul > li:last-child {
    border-bottom: 0 !important;
  }

  tr th {
    padding: 2px !important;
    font-size: @font-size !important;
  }

  tr td:first-child {
    padding-left: 6px !important;
    font-size: @font-size !important;
    white-space: normal !important;
    vertical-align: top !important;
  }

  .super-highlighted-tr {
    background: darken(@highlighted-area, 5%) !important;

    td {
      background: darken(@highlighted-area, 5%) !important;
      border-right: 1px solid darken(@highlighted-area, 17%) !important;
      font-family: @font-family-semibold !important;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }

    span {
      font-family: @font-family-semibold !important;
    }
  }

  .mega-highlighted-tr {
    background: darken(@highlighted-area, 9%) !important;

    td {
      background: darken(@highlighted-area, 9%) !important;
      border-right: 1px solid darken(@highlighted-area, 23%) !important;
      font-family: @font-family-bold !important;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }

    span {
      font-family: @font-family-bold !important;
    }
  }
}

.position-analytics-container {
  .item-details-block-transparent {
    .alert-success {
      .label {
        color: @label-success-color !important;
        font-size: calc(@font-size * 1.5) !important;
      }

      .property {
        color: @label-success-color !important;
      }
    }

    .alert-danger {
      .label {
        color: @label-danger-color !important;
        font-size: calc(@font-size * 1.5) !important;
      }

      .property {
        color: @label-danger-color !important;
      }
    }
  }

  .passport-position-crop-table {
    tr td {
      font-size: @font-size !important;
      border-right: 1px solid darken(@borders-color, 9%) !important;
      border-top: 1px solid darken(@borders-color, 9%) !important;

      .label {
        display: inline-flex;
      }
    }

    tr th:last-child,
    tr td:last-child {
      border-right: 0 !important;
    }
  }

  .futures-position-table {
    tr td {
      font-size: @font-size !important;
      border-right: none !important;
    }

    tr th:last-child,
    tr td:last-child {
      border-right: 0 !important;
    }
  }
}

.futures-table {
  .table-with-inline-add;
  tr td {
    font-size: calc(@font-size / 1.2) !important;
    .label {
      font-size: calc(@font-size / 1.2) !important;
    }
  }
  tr td:first-child {
    font-family: @font-family-regular !important;
  }
}

.position-analytics-container.ul-inline-row {
  .box-shadow-none;
  background: @color-black-opacity-dark;
  margin-right: 15px !important;
}

.page-total-analytics-container {
  padding: 0 !important;
  .border-radius-4;

  .item-details-block-transparent {
    display: block !important;
    margin-top: 0 !important;
    padding: 4px;
    font-size: calc(@font-size / 1.1) !important;

    .alert,
    .alert-success,
    .alert-danger {
      padding: 10px;
      font-size: calc(@font-size * 1.1) !important;
      .box-shadow-dark;

      .label {
        font-size: calc(@font-size * 1.1) !important;
        line-height: 1 !important;
        padding: 0 !important;
      }

      .property {
        font-size: calc(@font-size / 1.3) !important;
        line-height: 1 !important;
        letter-spacing: @letter-spacing-huge;

        span {
          line-height: 1 !important;
        }
      }
    }

    .alert-default {
      background: @color-black-opacity-dark !important;
      .box-shadow-none;
    }

    .horizontal-divider {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .item-details-el {
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
    margin-right: 10px;
  }
}

.page-total-analytics-container-fixed-alert {
  .item-details-block-transparent {
    .alert {
      width: 30%;
      overflow: hidden;
    }
  }
}

.lineup-light-table {
  .table-hover;

  tr th {
    text-align: left;
  }

  tr td {
    text-align: left;
    vertical-align: middle !important;
    border-right: 1px solid @borders-color !important;

    .label {
      font-size: @font-size;
    }

    .label-strong {
      white-space: normal;
    }

    .fa {
      font-size: calc(@font-size / 1.2) !important;
    }

    a {
      .fa {
        font-size: @font-size !important;
      }
    }

    div .form-contol {
      height: 24px !important;
      font-size: calc(@font-size / 1.3) !important;
    }

    select {
      text-align: center;
      margin: 0 auto;
      width: 80% !important;
    }
  }

  tr td.nowrap {
    white-space: nowrap;
    text-align: center;
  }

  td.action_addition {
    background: @action-addition;
    color: @label-success-color-border;
    border-radius: 0 !important;
    border-bottom: 1px solid @action-addition !important;
    font-family: @font-family-bold !important;
  }

  td.action_process {
    background: @action-process;
    color: @label-warning-color-border;
    border-radius: 0 !important;
    border-bottom: 1px solid @action-process !important;
    font-family: @font-family-bold !important;
  }

  td.action_executed {
    background: @action-executed;
    color: @label-default-color-border;
    border-radius: 0 !important;
    border-bottom: 1px solid @action-executed !important;
  }
}

.lineup-container {
  padding-left: 15px;
  padding-right: 15px;

  thead {
    th {
      font-size: calc(@font-size / 1.4) !important;
    }
  }

  .lineup-table thead {
    display: none;
    float: left;
  }

  .lineup-table tbody {
    float: left;
  }
}

.lineup-container:nth-of-type(1) {
  .lineup-table thead {
    display: table-header-group;

    th {
      font-size: calc(@font-size / 1.3) !important;
    }
  }
}

.lineup-table {
  margin-left: 189px !important;

  thead tr th {
    text-align: center !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    line-height: 1 !important;
    overflow: hidden;
    white-space: nowrap;
    border-right: 1px solid @borders-color !important;
    border-color: @borders-color !important;
    width: 110px !important;
    min-width: 110px !important;
    max-width: 110px !important;
  }

  tbody tr td {
    vertical-align: middle !important;
    font-size: @font-size !important;
    text-align: left;
    white-space: normal;
    word-break: break-all;
    padding: 2px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    line-height: 1 !important;
    width: 110px !important;
    min-width: 110px !important;
    max-width: 110px !important;
    border-right: 1px solid darken(@borders-color, 5%) !important;
    border-top: 1px solid darken(@borders-color, 5%) !important;
    background: @tables-background;

    .label {
      display: inline-flex;
      padding: 2px !important;
      font-size: calc(@font-size / 1.3);
    }

    .clear-btn {
      top: 10px;
      right: -20px;
      position: relative;
    }

    .ui-select-bootstrap > .ui-select-match > .btn {
      height: 25px !important;
      font-size: @font-size !important;
      padding: 2px !important;
      margin-top: 0 !important;
    }

    ul {
      padding: 0 !important;
      margin: 0 !important;
    }

    li span {
      white-space: normal;
    }

    .btn {
      padding: 2px;
      margin: 0;
      float: left;
      line-height: 1;
      font-size: calc(@font-size / 1.3) !important;
    }

    a {
      line-height: 1;
    }
  }

  thead tr th:first-child,
  tbody tr td:first-child {
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;

    span {
      margin-top: 0 !important;
    }
  }

  tr th:last-child,
  tr td:last-child {
    border-right: 0 !important;
  }

  .label-strong,
  .smaller-label {
    white-space: normal;
  }

  tr.highlight-new td {
    background: @action-addition;
    color: @label-success-color-border;
    font-family: @font-family-bold;

    .highlight-new-label {
      background: @action-addition-alt;
      white-space: normal;
    }
  }

  tr.highlight-process td {
    background: @action-process;
    color: @label-warning-color-border;
    font-family: @font-family-bold;

    .highlight-process-label {
      background: @action-process-alt;
      white-space: normal;
    }
  }

  tr.highlight-executed td {
    background: @action-executed;

    .highlight-executed-label {
      background: @action-executed-alt;
      white-space: normal;
    }
  }

  tr.highlight-approved td {
    background: @action-approved;
    color: @label-calculate-color-border;
    font-family: @font-family-bold;

    .highlight-approved-label {
      background: @action-approved-alt;
      white-space: normal;
    }
  }

  tr.highlight-cancelled td {
    background: @action-delete;
    color: @label-danger-color;
    font-family: @font-family-bold;

    .highlight-cancelled-label {
      background: @action-delete-alt;
      white-space: normal;
    }
  }

  tr td .highlight-purchase-label,
  tr td .highlight-sale-label {
    white-space: normal;
  }
}

.highlight-new {
  background: @action-addition !important;
  color: @label-success-color-border;
  font-family: @font-family-bold;

  .highlight-new-label {
    background: @action-addition-alt;
    white-space: normal;
  }
}

.highlight-process {
  background: @action-process !important;
  color: @label-warning-color-border;
  font-family: @font-family-bold;

  .highlight-process-label {
    background: @action-process-alt;
    white-space: normal;
  }
}

.highlight-executed {
  background: @action-executed !important;

  .highlight-executed-label {
    background: @action-executed-alt;
    white-space: normal;
  }
}

.highlight-approved {
  background: @action-approved !important;
  color: @label-calculate-color-border;
  font-family: @font-family-bold;

  .highlight-approved-label {
    background: @action-approved-alt;
    white-space: normal;
  }
}

.highlight-cancelled {
  background: @action-delete !important;
  color: @label-danger-color;
  font-family: @font-family-bold;

  .highlight-cancelled-label {
    background: @action-delete-alt;
    white-space: normal;
  }
}

.physical-position-table {
  tr th:first-child {
    text-align: center !important;
  }

  tr th {
    border-right: 1px solid @borders-color !important;
  }

  tr th:last-child {
    border-right: 0 !important;
  }
}

.big-physical-position-table {
  tr th {
    font-size: calc(@font-size / 1.2) !important;
    padding: 2px !important;
  }

  tr td {
    padding: 2px !important;
    line-height: 1;
    vertical-align: middle !important;

    .form-control {
      font-size: calc(@font-size / 1.3) !important;
      height: 20px !important;
      line-height: 1;
      min-width: 80px;
      max-width: 80px;
      padding: 2px !important;
    }

    .ui-select-bootstrap > .ui-select-match > .btn {
      line-height: 1 !important;
    }

    .input-group {
      display: block;
      width: auto;
      min-width: max-content;

      .form-control {
        margin-left: 5px;
      }
    }

    input[type='checkbox'] {
      min-width: 20px;
      max-width: 20px;
    }

    .ui-select-match,
    .ui-select-container {
      display: inline;
    }
  }

  tr td:first-child {
    .btn-danger,
    .btn-success {
      font-size: calc(@font-size / 1.3);
      line-height: 1;
      margin-top: 2px;
    }
  }
}

.main-table-condensed {
  tr td {
    padding: 2px 6px !important;
    line-height: 1 !important;

    a,
    .btn,
    .btn_link {
      margin: 0 !important;
      padding: 0 !important;
    }

    .label,
    .alert,
    .alert-success {
      padding: 2px !important;
      margin: 0 !important;
    }
  }
}

.trader-position-crop-table {
  tr th:nth-child(1) {
    text-align: center;
  }

  tr th {
    white-space: normal !important;
    word-break: break-all;

    .row div {
      white-space: normal !important;
    }

    .row span {
      text-align: right;
      white-space: normal !important;
    }
  }

  tr td {
    text-align: right;
    white-space: nowrap !important;
    padding: 2px 6px !important;
  }

  .col-xs-12 {
    width: max-content;
    min-width: 100%;
  }

  .row-no-margin {
    margin-right: 0;
    margin-left: 0;
  }

  .row {
    div {
      text-align: right;
      white-space: nowrap !important;

      span {
        text-align: right;
        float: right;
      }
    }

    span {
      text-align: right;
      white-space: nowrap !important;
    }
  }
}

.trader-position-crop-table.wide-columns {
  tr td {
    padding: 2px !important;
  }
}

.inside-table-ul {
  li {
    text-align: right;

    .label {
      margin: 0 !important;
      padding: 2px;
    }
  }
}

.inside-table-items {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: 4px;
}

.more-details-row {
  .btn-link {
    font-size: 0.7em;
  }
}

.inline-date {
  li {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 7px;

    .label:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .label-highlighted {
    background: @white !important;
    color: @label-default-color !important;
  }
}

.left-sidebar .contract-details-container-cut .btn-link {
  font-size: calc(@font-size / 1.2);
}

.highlighted-contract {
  background: rgba(0, 0, 0, 0) !important;
}

.unhighlighted-contract {
  background: @tables-background !important;
}

.progress {
  margin: 0 !important;
  background-color: @highlighted-area / 1.1;
  box-shadow: none !important;
  height: auto !important;
  border-radius: 2em !important;
  text-transform: uppercase;
  white-space: nowrap;
  font-family: @font-family-regular !important;

  .progress-bar {
    font-size: calc(@font-size / 1.3);
    padding: 0;
    padding-left: 5px;
    text-align: left;
    box-shadow: none !important;
  }

  .progress_bar_default {
    background-color: @progressbar-default;
    color: @label-default-color-border;
  }

  .progress_bar_success {
    background-color: @progressbar-success;
    color: @label-success-color-border;
  }

  .progress_bar_warning {
    background-color: @progressbar-warning;
    color: @label-warning-color-border;
  }

  .progress_bar_calculate {
    background-color: @progressbar-calculate;
    color: @label-calculate-color-border;
  }

  .progress_bar_danger {
    background-color: @progressbar-danger;
    color: @white;
  }
}

// try to code as a Human - (NO).

.gt-detail-page {
  .hide {
    display: none !important;
  }

  .central-container {
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-top: 0 !important;
  }

  .trader-page {
    margin-top: -22px !important;

    .page-header-main {
      padding-top: 15px !important;
      margin-right: -15px !important;
      margin-left: -15px !important;
    }
  }

  .page-header-main {
    margin-top: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: -15px !important;
    margin-left: -15px !important;

    .payment-details-container {
      max-width: 97.5%;
      margin-left: 15px !important;
      overflow-x: auto;
      margin-bottom: 25px !important;
    }

    .main-nav-tabs li > a {
      font-size: calc(@font-size / 1.2) !important;
      text-align: center;

      .fa {
        text-align: center;
      }
    }
  }
}

.gt-main-container.col-xs-11 {
  width: 97%;
}

.create-contract-page {
  .help-btn-group a:last-child {
    margin-right: 15px !important;
  }

  .gt-sidebar.col-xs-1 {
    background: @navbar-background;
    width: 3%;
    padding: 0;
    border-right: 1px solid @navbar-background;
    .box-shadow-light;
    padding-bottom: 1200px;

    .scrollable-sidebar {
      position: fixed;
      display: inline-table;
    }

    .toggle-button {
      margin: 0 auto;
      display: block;
      position: relative;
      margin-top: 20px;
      padding: 6px;
    }
  }

  .gt-sidebar.col-xs-2 {
    padding-right: 0;
    padding-left: 0px;
    padding-top: 10px;
    .box-shadow-light;
    background: @navbar-background;
    padding-bottom: 1000px;

    .scrollable-sidebar {
      position: fixed;
      display: inline-table;
      width: 16%;
      max-width: 16%;
      background: @navbar-background;
    }

    .start-page-header {
      color: @tables-background;
    }

    .opened-sidebar {
      background: @navbar-background;
    }
  }

  .gt-main-container {
    .help-btn-group {
      top: 20px !important;
      right: 35px;
    }

    .all-filters-block {
      padding-top: 40px !important;
    }

    .contract-details-container-cut {
      margin-top: 20px;
    }

    .page-header-main h1 {
      margin-top: 25px !important;
      padding-bottom: 40px !important;
    }
  }

  .toggle-button {
    .fa-bars {
      font-size: calc(@font-size * 2);
      color: @tables-background;
    }

    .fa-filter {
      font-size: calc(@font-size * 2);
      color: @tables-background;
    }
  }

  .toggle-button.btn-default {
    background: darken(@navbar-background, 29%);
    color: @white;
    .box-shadow-light;
  }

  .sidebar-wrapper {
    height: 100vh;
    display: block;
    padding: 0;
    margin: 0;

    .search-wrapper {
      width: 90% !important;

      .search-container {
        border: 1px solid @tables-background !important;

        input {
          color: @tables-background !important;

          &::-webkit-input-placeholder {
            color: @tables-background !important;
          }
        }
      }
    }

    .pagination > li > a,
    .pagination > li > span {
      font-size: calc(@font-size / 1.3) !important;
      padding: 6px;
      color: @tables-background !important;

      :hover {
        cursor: pointer;
      }
    }

    .pagination > li.active > a {
      background: @main-font-color !important;
      color: @tables-background !important;
    }
  }

  .gt-table-sidebar {
    width: 100%;
    background: transparent;
    display: block;
    overflow-y: auto;
    max-height: 25em;
    margin-bottom: 0;

    tbody {
      display: block;
    }

    tbody tr {
      display: grid;
    }

    tr td {
      padding: 6px;
      text-align: left;
      vertical-align: middle;
      border-bottom: 1px solid darken(@navbar-background, 23%);
      border-top: 0;
      line-height: 1;
      border-right: 0;
      font-size: calc(@font-size / 1.2) !important;
      word-break: break-all;
    }

    tr td.active-td {
      padding-left: 10px;
    }

    tr td:nth-child(1) {
      font-family: @font-family-bold;

      span {
        padding-top: 4px;
      }
    }

    tr.contract_new > td:first-child,
    tr.contract_processing > td:first-child,
    tr.contract_executed > td:first-child,
    tr.contract_cancelled > td:first-child,
    tr.contract_calculate > td:first-child {
      background: transparent !important;
    }

    tr:hover {
      cursor: pointer;

      td {
        background: @hover-color;
      }
    }

    tr.selected {
      td {
        background: @highlighted-area !important;
      }
    }
  }

  .gt-table-sidebar-contracts {
    tr.contract_executed > td:first-child {
      border-left: 5px solid @label-default-background;
      color: @contract-executed-color-alt !important;
    }

    tr.contract_calculate > td:first-child {
      border-left: 5px solid @label-calculate-color-alt;
      color: @contract-calculate-color-alt !important;
    }

    tr.contract_new > td:first-child {
      border-left: 5px solid @contract-new-color-alt;
      color: @contract-new-color-alt !important;
    }

    tr.contract_processing > td:first-child {
      border-left: 5px solid @contract-processing-color-alt;
      color: @contract-processing-color-alt !important;
    }

    tr.contract_cancelled > td:first-child {
      border-left: 5px solid @label-default-background-alt;
      color: @contract-cancelled-color-alt !important;
      text-decoration: line-through;
    }

    tr > td.active-td:first-child {
      border-left-width: 18px !important;
    }
  }
}

.table-action-buttons {
  display: block;

  .search-container {
    width: 100% !important;
  }

  .input-group-xs {
    .ui-select-multiple.ui-select-bootstrap {
      padding: 0;
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    width: 20px !important;
    height: 20px !important;
    margin-right: 5px;
  }

  .btn {
    font-family: @font-family-bold !important;
    text-transform: capitalize !important;
    &::first-letter {
      text-transform: capitalize !important;
    }
  }

  .btn-default {
    font-family: @font-family-bold !important;
    margin-left: 5px !important;
    line-height: 1.6 !important;
    background: transparent;
    font-size: calc(@font-size / 1) !important;
    letter-spacing: @letter-spacing-very-huge;
    .border-radius-4;
    border: 1px solid darken(@highlighted-area, 23%) !important;
    color: @headers-color !important;
    padding: 3px 10px;

    &:hover {
      color: @tables-background !important;
      background: darken(@highlighted-area, 23%);
    }

    &::first-letter {
      text-transform: uppercase !important;
    }
  }

  .btn-add-dark {
    margin-left: 5px !important;
    border: 1px solid darken(@highlighted-area, 23%) !important;
    padding: 3px 10px;
    line-height: 1.5;
    border-width: 1px;
    .border-radius-4;
    font-size: calc(@font-size / 1) !important;
  }

  .btn-orange-border,
  .btn-green-border,
  .btn-blue-border {
    background: transparent;
    box-shadow: none !important;
    margin-left: 5px !important;
    padding: 3px 10px;
    line-height: 1.5;
    border-width: 1px;
    font-size: calc(@font-size / 1) !important;
  }

  .btn-success {
    margin-left: 5px !important;
    padding: 3px 10px;
    border-width: 1px;
    line-height: 1.5;
    font-size: calc(@font-size / 1) !important;
  }

  .filter-template {
    border-bottom: 0 !important;

    .btn-default {
      padding: 2px !important;
      .border-radius-4;
    }
  }

  .btn-danger {
    background: @tables-background;
  }

  .gt-export-button {
    line-height: 1.6;
    padding: 3px 6px;
    font-size: @font-size;
    &::first-letter {
      text-transform: uppercase !important;
    }
  }

  .btn-primary {
    border: 1px solid darken(@highlighted-area, 23%) !important;
    background: darken(@highlighted-area, 23%) !important;
    color: @tables-background !important;
    margin-left: 5px !important;
  }

  .item-settings-dropdown.navbar-nav {
    .filter-template {
      .btn {
        line-height: 1 !important;
        padding: 2px 10px !important;
        &:hover {
          color: @main-font-color !important;
        }
      }
    }
  }

  .item-settings-dropdown a:nth-of-type(1) {
    line-height: 1 !important;
    padding: 2px 10px !important;
    .no-text-stroke;
    font-family: @font-family-regular !important;
    letter-spacing: @letter-spacing-very-huge;
    text-transform: capitalize !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
  }

  .item-settings-dropdown .dropdown-menu {
    left: calc(100% - 49px) !important;
    top: calc(100% + 5px) !important;
    background: @tables-background !important;
    .no-text-stroke;
  }

  .item-settings-dropdown {
    color: @headers-color !important;
    .no-text-stroke;
    background: transparent !important;
    border: 1px solid darken(@highlighted-area, 23%) !important;
    .border-radius-4;
    font-size: @font-size !important;
    line-height: 1;
    padding: 4px !important;
    margin-left: 5px !important;

    .user-dropdown-btn {
      background: transparent !important;
      color: @headers-color !important;

      .fa.show-on-hover {
        display: none;
      }
    }

    &:hover {
      .user-dropdown-btn {
        color: @tables-background !important;

        .fa.hide-on-hover {
          display: none;
        }

        .fa.show-on-hover {
          display: inline-block;
        }
      }

      color: @tables-background !important;
      background: @headers-color !important;
    }

    a {
      background: @tables-background !important;
      .no-text-stroke;

      &::first-letter {
        text-transform: uppercase !important;
      }
    }

    .gt-export-button {
      width: 100%;
      display: block;
      margin: 0 !important;
      border: 0 !important;
      text-align: left !important;
    }
  }

  .start-page-header {
    margin-top: 2px !important;
    padding-bottom: 5px !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
  }
}

.settings-page {
  .btn-group {
    width: 49%;

    button {
      width: 33%;
      font-size: calc(@font-size / 1.3);
      float: right;
    }
  }

  label {
    width: 50%;
  }

  .well {
    padding: 5px !important;
    margin: 5px;
  }

  .btn-primary {
    font-family: @font-family-bold !important;
    border: 1px solid darken(@borders-color, 9%) !important;
  }
}

.details-wrapper {
  .well {
    background: @tables-background !important;
    border-radius: 8px;
    .box-shadow-light;
    border: 0 !important;
    padding: 20px;
    display: block;
    position: relative;

    table,
    .table {
      margin-bottom: 0 !important;
    }

    .modal-form-block .ul-inline-row {
      padding: 0 !important;
    }

    .well,
    .analytics-container,
    .ul-inline-row,
    .table-container-wrapper {
      border: 0 !important;
      box-shadow: none !important;
      padding: 15px !important;
    }

    .request-list-container {
      padding-top: 10px !important;
    }

    .form-group-container .form-group.col-xs-12:hover {
      border-left: 0 !important;
    }

    .nav-tabs {
      border-bottom: 1px solid @borders-color !important;
    }

    .nav-tabs li.active a {
      box-shadow: none !important;
      border: 1px solid @borders-color !important;
      border-bottom: 0 !important;
    }

    .table-nav-tabs {
      li.active a {
        bottom: -1px !important;
      }
    }

    .ul-inline-row,
    .table-container-wrapper {
      box-shadow: none;
      padding: 2px !important;
    }

    .request-list {
      .box-shadow-dark;
    }

    .upcoming-updates-list,
    .add-new-inline {
      .box-shadow-dark;
      border: 1px solid darken(@borders-color, 17%) !important;
      padding: 6px !important;

      h4,
      h5 {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    .chart-graph-block {
      .table-container-wrapper {
        border: 0 !important;
        padding: 0 !important;
      }
    }

    .main-table {
      tr td {
        padding: 2px;
      }
    }

    .contract-quality-table {
      tr td {
        border-bottom: 1px solid @borders-color !important;
      }
    }

    .persons-table {
      tr td {
        border-top: 1px solid @borders-color !important;
      }
    }

    .facts-edit-table {
      tr td {
        border-top: 1px solid @borders-color !important;
      }
    }
  }

  .real-well {
    background: @well-background !important;
  }

  .table-action-buttons {
    h5.start-page-header {
      display: none;
    }

    .col-sm-3,
    .col-sm-2 {
      display: none;
    }
  }

  .container-btn-group,
  .search-container {
    display: none;
  }

  .visible-searchbar {
    .search-container {
      display: -webkit-box;
    }
  }

  .main-table.multipassport-table {
    tr td {
      border-top: 1px solid darken(@borders-color, 9%) !important;
      border-right: 1px solid darken(@borders-color, 9%) !important;
    }

    tr:hover {
      td {
        background: inherit !important;
      }
    }
  }

  .well-collapsed {
    max-height: 320px;
    overflow: hidden;
  }

  .well-expanded {
    max-height: inherit;
    overflow: inherit;
  }

  h2.start-page-header {
    font-size: 18px !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-bottom: 5px !important;
    letter-spacing: @letter-spacing-normal;
  }

  .start-page-header {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    &::first-letter {
      text-transform: uppercase !important;
    }

    small {
      text-transform: initial;
      font-family: @font-family-light;
    }
  }

  .btn-show-more {
    font-size: @font-size !important;
    font-family: @font-family-bold !important;
  }

  .btn-show-more-floating {
    position: absolute;
    left: 0%;
    bottom: 0%;
    background: @tables-background;
    .box-shadow-light;
  }
}

.main-list {
  border: 1px solid @borders-color !important;
  background-color: @tables-background;
  .border-radius-4;
  margin-top: -1px;

  .box-shadow-light;
  transition: background-color linear 0.3s;

  .transition;

  .hover-element {
    transition:
      transform 0.4s ease-in-out,
      opacity 0.4s ease-in;
    opacity: 0.001;
    transform: translate3d(0, 8px, 0);
    width: auto;
    visibility: visible;
  }

  &:hover {
    .box-shadow-light;
    cursor: pointer;

    .hover-element {
      .transition;
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0);
      width: auto;
    }

    .transform-element {
      transform: translate3d(0, 0, 0);
      font-size: 75%;
    }

    .fa-sort-desc.hover-element {
      font-size: calc(@font-size * 1.2) !important;
    }
  }

  .nav-tabs li.active a {
    background: @highlighted-area !important;
  }

  .main-list-el {
    background-color: @tables-background;
    line-height: 1.5;
    font-family: @font-family-regular;
    .border-radius-4;
    text-align: left;
    position: relative;
    padding: 6px;
    width: 100%;
    transition: background-color linear 0.3s;
    font-size: calc(@font-size / 1.1);
    color: @contract-executed-color !important;

    .label {
      font-size: calc(@font-size / 1.3);
      margin-top: 0;
      margin-bottom: 0;
      display: inline-flex;
      padding: 2px !important;
    }

    input[type='checkbox'],
    input[type='radio'] {
      width: 15px !important;
      height: 15px;
    }
  }

  .lineup-el {
    .label {
      font-size: calc(@font-size / 1.3);
    }

    ul {
      margin-bottom: 0 !important;
    }
  }

  .main-list-details-expanded {
    opacity: 1;
    padding: 6px;
    margin: 0;
    .border-radius-4;
    margin-top: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: left;
    height: auto;
    background: @color-black-opacity-dark;
    transition: background-color linear 0.3s;
    font-size: calc(@font-size / 1.3);

    h2 {
      font-size: calc(@font-size * 1.1) !important;
    }
  }

  .counterparty_detail_table {
    tr td:first-child {
      font-size: calc(@font-size / 1.3) !important;
      font-family: @font-family-regular !important;
    }

    tr td {
      font-size: @font-size !important;
      white-space: normal !important;
      word-wrap: break-word !important;
      font-family: @font-family-regular !important;
      border: 0 !important;
      vertical-align: middle !important;

      .label {
        padding: 2px !important;
        font-size: calc(@font-size / 1.3) !important;
      }

      .label-strong {
        font-family: @font-family-semibold !important;
      }

      .btn {
        padding: 2px !important;
        font-size: calc(@font-size / 1.3) !important;
      }

      .progress .progress-bar {
        padding: 2px !important;
        font-size: calc(@font-size / 1.3) !important;
        height: 12px !important;
      }

      .form-control {
        height: 22px !important;
        min-height: 22px !important;
      }
    }
  }

  .form-control .ui-select-match-text {
    font-size: calc(@font-size / 1.3);
    line-height: 1;
  }

  .ui-select-bootstrap > .ui-select-match > .btn {
    padding: 2px;
    height: 15px;
    border: 1px solid darken(@borders-color, 23%) !important;
  }

  .text-muted {
    font-size: calc(@font-size / 1.3);
    vertical-align: middle;
    line-height: 1 !important;
  }
}

.main-list-expanded {
  margin-top: 10px;
  margin-bottom: 10px;
  background: @color-black-opacity-dark;

  .main-list-el {
    padding: 20px;
  }

  .hover-element {
    .transition;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    width: auto;
  }

  .transform-element {
    transform: translate3d(0, 0, 0);
    font-size: 75%;
  }
}

.contract-details-container-cut {
  .counterparty-details-table {
    tr td {
      font-size: calc(@font-size / 1.3) !important;
    }
  }
  .contract-details-container;

  .horizontal-divider {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .progress {
    margin: 0 !important;
    background-color: @color-black-opacity-very-dark;
    box-shadow: none !important;
    height: auto !important;
    border-radius: 2em !important;

    .progress-bar {
      padding: 0px;
      padding-left: 5px;
      padding-top: 2px;
      text-align: left;
    }

    .progress_bar_default {
      background-color: @progressbar-default;
      color: @label-default-color-border;
    }

    .progress_bar_success {
      background-color: @progressbar-success;
      color: @label-success-color-border;
    }

    .progress_bar_warning {
      background-color: @progressbar-warning;
      color: @label-warning-color-border;
    }

    .progress_bar_calculate {
      background-color: @progressbar-calculate;
      color: @label-calculate-color-border;
    }

    .avg-price {
      display: inline;
      position: absolute;
      right: 20px;
    }
  }

  .progress-consisted {
    .progress-bar {
      overflow-x: hidden !important;
    }
  }

  .progress.col-xs-6,
  .progress.col-xs-4 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .contract_details {
    margin-bottom: 2px;
    margin-right: 2px;
    float: left;
    white-space: nowrap;
    padding: 2px !important;
  }

  .contract-description-label {
    font-family: @font-family-bold !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .btn-details-row {
    .btn {
      margin-right: 5px;
    }

    padding-top: 2px;
  }

  .nav-tabs {
    margin-top: 5px;
  }

  .label-details {
    font-size: calc(@font-size / 1.3) !important;
    box-shadow: 0px 0px 1px #000;
    background: transparent !important;
    color: @contract-executed-color;
    margin-left: 1px;
  }

  .label_danger {
    padding: 2px;
    .border-radius-4;
  }

  white-space: nowrap;
}

.inset-container {
  padding: 10px;
  .border-radius-4;
  margin-bottom: 10px;
  width: 100%;
  white-space: nowrap;

  ::-webkit-scrollbar-thumb {
    background: @very-dark-blue !important;
  }
}

.payment-plans-list {
  box-shadow: none !important;
  padding: 2px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  border-bottom: 1px solid @borders-color !important;
  margin-bottom: 0 !important;

  h4 {
    margin: 0;
    font-family: @font-family-regular !important;
  }
}

.payment-plan-modal-table {
  table-layout: fixed;
}

.payment-plan-finances-table {
  tr td {
    vertical-align: middle !important;
    padding: 4px !important;
    font-size: calc(@font-size / 1.2) !important;
    border-bottom: 1px solid @borders-color !important;
    border-right: 1px solid @borders-color !important;

    .form-control {
      height: 16px;
    }
  }

  tr th {
    border-bottom: 1px solid @borders-color !important;
    border-right: 1px solid @borders-color !important;
    background: @highlighted-area !important;
  }
}

.read-only {
  .btn-warning,
  .btn-info,
  .btn-danger,
  .btn-success,
  .search-wrapper,
  .search-container,
  .btn-orange-border,
  .btn-green-border,
  .btn-blue-border,
  tr.edit-mode,
  .edit-mode,
  .position-analytics-container {
    display: none;
  }
  .read-only-element {
    display: none;
  }
  .close.ui-select-match-close,
  .ui-select-search {
    display: none;
  }
  input,
  select,
  .ui-select-bootstrap > .ui-select-match > .btn {
    pointer-events: none;
    background: @color-black-opacity;
  }
  .copy-clipboard-btn {
    pointer-events: all !important;
    position: absolute;
    right: 0;

    z-index: 9999 !important;
  }

  .ui-select-match {
    .copy-clipboard-btn {
      display: none !important;
    }

    &:hover {
      .copy-clipboard-btn {
        display: inline-block !important;
      }
    }
  }

  .ui-select-match-text {
    margin-top: 0 !important;
  }

  .main-table .highlighted-td {
    background: @tables-background !important;
  }
}

.copy-clipboard-btn {
  cursor: pointer;
  font-size: calc(@font-size / 1.3);
  color: @light-blue;
  background: transparent !important;
  border: 0 !important;
  border-radius: 100% !important;
}

table,
.dropdown,
.ul-inline-row,
.table-container-wrapper,
.contract-details-container-cut {
  ::-webkit-scrollbar-thumb {
    background: darken(@highlighted-area, 33%) !important;
  }
}

.passport-inline-items-table,
.passport-properties-details {
  .ul-inline-row,
  .table-container-wrapper {
    background: @tables-background !important;
  }

  .add-new-inline {
    border: 1px solid @label-success-color !important;
    border-left: 6px solid @label-success-color !important;
    .border-radius-4;
  }

  .contract-costs-table,
  .contract-quality-table {
    tr td {
      border-right: 1px solid @borders-color !important;
    }

    tr td:first-child {
      border-left: 0 !important;
    }

    tr td:last-child {
      border-right: 0 !important;
    }

    tr th.thick-border,
    tr td.thick-border {
      border-right: 3px solid @borders-color !important;
    }
  }
}

.table-passport-result {
  tr td,
  tr th {
    padding: 2px !important;
    white-space: nowrap !important;
    .label {
      padding: 2px !important;
    }
  }
}

.container-wo-totals {
  .page-total-analytics-container,
  .min-medium-screens,
  .highlighted-tr {
    display: none !important;
  }

  .table-nav-tabs {
    li {
      display: none !important;
    }

    li:first-child {
      display: block !important;
    }
  }

  .table-container-wrapper {
    background: @tables-background !important;
  }
}

.passports-container {
  padding: 0;
  .border-radius-4;
  margin-bottom: 30px;
  white-space: nowrap;

  small {
    margin-top: 0 !important;

    .contract_details {
      margin-bottom: 2px;
      margin-right: 2px;
      float: left;
      white-space: nowrap;
      padding: 2px !important;
    }

    .contract-description-label {
      margin-bottom: 0px;
      font-size: calc(@font-size / 1.3) !important;
      padding: 6px;
    }
  }

  .contract-status-block {
    table {
      tr td {
        padding: 2px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        white-space: nowrap;
        background: transparent !important;

        .label {
          padding: 2px !important;
        }
      }
    }
  }

  .progress {
    margin: 0 !important;
    box-shadow: none !important;
    height: auto !important;
    border-radius: 4px !important;
    margin-bottom: 5px !important;

    .progress-bar {
      font-size: calc(@font-size / 1.3);
      padding: 6px;
      font-family: @font-family-bold !important;
      text-transform: uppercase;
      text-align: left;
    }

    .progress_bar_default {
      background-color: @progressbar-default;
      color: @label-default-color-border;
    }

    .progress_bar_success {
      background-color: @progressbar-success;
      color: @label-success-color-border;
    }

    .progress_bar_warning {
      background-color: @progressbar-warning;
      color: @label-warning-color-border;
    }

    .progress_bar_calculate {
      background-color: @progressbar-calculate;
      color: @label-calculate-color-border;
    }

    .progress_bar_danger {
      background-color: @progressbar-danger;
      color: @white;
    }

    .avg-price {
      display: inline;
      position: absolute;
      right: 20px;
      font-size: calc(@font-size / 1.3);
      line-height: 1.9;
    }
  }

  h2 {
    font-size: 18px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .label-details {
    font-size: calc(@font-size / 1.3) !important;
    box-shadow: 0px 0px 1px #000;
    background: transparent !important;
    color: @contract-executed-color;
    margin-left: 1px;
  }

  .request-list-container {
    width: 100% !important;

    .request-list,
    .contract-list {
      width: 100%;
      display: block;
      margin-bottom: 10px;
    }
  }

  .voyage-wrapper-light-mode {
    display: block;
    position: relative;

    padding-left: 0;
    padding-right: 0;
    width: 100%;
    -webkit-column-count: 1 !important;
    -webkit-column-gap: 0 !important;
    -moz-column-count: 1 !important;
    -moz-column-gap: 0 !important;
    column-count: 1 !important;
    column-gap: 0 !important;
    .contract-details-container-cut {
      overflow: auto !important;
    }
  }

  .passport-items-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .passport-label {
    margin-left: 2px !important;
    display: inline-flex !important;
    margin-top: 0;

    .fa {
      margin-right: 2px;
    }
  }

  h2 {
    .contract_details {
      width: auto !important;
    }
  }

  .inset-container {
    background: darken(@highlighted-area, 9%) !important;
    .border-radius-4;
    margin-bottom: 0 !important;
  }

  .passport-nav-tabs {
    li a {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
  }

  .passport-properties-details {
    padding-top: 5px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-top: 0 !important;

    .alert {
      margin-top: 3px;
      margin-bottom: 3px;
    }

    .label {
      font-size: calc(@font-size / 1.3);
      padding: 2px;
    }
  }

  .filter-template {
    .fa-th-list,
    .fa-th-large {
      font-size: calc(@font-size * 1.2);
    }
  }
}

.contracts-wrapper-light-mode {
  display: flow-root;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 5;
  -webkit-column-gap: 10px;
  -moz-column-count: 5;
  -moz-column-gap: 10px;
  column-count: 5;
  column-gap: 10px;

  .item-settings-dropdown {
    padding: 5px !important;
  }

  .passports-container {
    min-width: 250px;
    margin-right: 10px;
    float: left;
    overflow: hidden;
  }

  .request-list,
  .contract-details-container-cut {
    margin-bottom: 10px !important;
    overflow: visible !important;
    border-radius: 8px !important;
    display: inline-block;
    width: 100%;

    h2 {
      font-size: calc(@font-size * 1.2) !important;
    }

    small {
      margin-left: 5px;
    }

    .item-settings-dropdown a:nth-of-type(1) {
      font-size: calc(@font-size / 1.2) !important;
    }

    .label {
      padding: 2px !important;
      font-size: calc(@font-size / 1.3) !important;
    }

    .counterparty_detail_table {
      tr td:first-child {
        font-size: calc(@font-size / 1.3) !important;
        font-family: @font-family-regular !important;
      }

      tr td {
        font-size: calc(@font-size / 1.2) !important;
        white-space: normal !important;
        word-wrap: break-word !important;
        font-family: @font-family-regular !important;

        .label {
          padding: 2px !important;
          font-size: calc(@font-size / 1.3) !important;
        }

        .label-strong {
          font-family: @font-family-bold !important;
        }

        .btn {
          padding: 2px !important;
          font-size: calc(@font-size / 1.3) !important;
        }

        .progress .progress-bar {
          padding: 2px !important;
          font-size: calc(@font-size / 1.3) !important;
          height: 12px !important;
        }
      }
    }

    .form-control {
      height: auto !important;
      font-size: @font-size;
      margin-bottom: 2px;
      font-family: @font-family-bold, 'FontAwesome' !important;
      line-height: 1.5;
      overflow: visible;
      white-space: normal;

      span {
        font-size: @font-size;
      }
    }
    .ui-select-match-text {
      word-break: break-all;
      div {
        word-break: break-all;
      }
    }
  }
}

.clients-wrapper-light-mode {
  .contracts-wrapper-light-mode;
}

.persons-wrapper-light-mode {
  .contracts-wrapper-light-mode;
}

.bank-account-wrapper-light-mode {
  .contracts-wrapper-light-mode;

  .request-list,
  .contract-details-container-cut {
    .hover-element {
      transition:
        transform 0.4s ease-in-out,
        opacity 0.4s ease-in;
      opacity: 0.001;
      visibility: hidden;
    }

    &:hover {
      .hover-element {
        .transition;
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        width: auto;
      }

      .transform-element {
        transform: translate3d(0, 0, 0);
        font-size: 75%;
      }
    }
  }
}

.bank-account-wrapper-flex {
  .contracts-wrapper-light-mode;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  .request-list,
  .contract-details-container-cut {
    min-height: 250px;

    .hover-element {
      transition:
        transform 0.4s ease-in-out,
        opacity 0.4s ease-in;
      opacity: 0.001;
      visibility: hidden;
    }

    &:hover {
      .hover-element {
        .transition;
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        width: auto;
      }

      .transform-element {
        transform: translate3d(0, 0, 0);
        font-size: 75%;
      }
    }
  }
  .bank-account-item {
    flex: 0 0 calc(100% / 8 - 14px);
    box-sizing: border-box;
  }
}

.bank-group-name {
  margin-left: 25px;
  margin-bottom: 10px;
  font-family: 'Regular', sans-serif !important;
  letter-spacing: var(--letter-spacing-very-huge, 0.07em);
  -webkit-text-stroke: 0;
}

.bank-wrapper-light-mode {
  .contracts-wrapper-light-mode;
}

.voyage-wrapper-light-mode {
  .contracts-wrapper-light-mode;
}

.passport-wrapper-light-mode {
  .contracts-wrapper-light-mode;
}

.agreement-wrapper-light-mode {
  .contracts-wrapper-light-mode;

  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;

  .request-list,
  .contract-details-container-cut {
    width: 48%;
    height: fit-content !important;

    .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
      overflow: visible;
    }

    .form-control {
      height: auto !important;
    }
  }

  .contract-details-container-cut-edit {
    height: fit-content !important;
    width: 100%;
    overflow: visible !important;
  }
}

.logistics-wrapper-light-mode {
  .contracts-wrapper-light-mode;

  .request-list,
  .contract-details-container-cut {
    height: 70px;
    width: 9%;
    padding: 2px !important;
    line-height: 1;
    overflow: hidden;

    h2 {
      font-size: @font-size !important;
      margin: 0 !important;
    }

    input[type='checkbox'],
    input[type='radio'] {
      margin: 0;
      width: 13px;
      height: 13px;
    }
  }

  .item-settings-dropdown {
    padding: 2px !important;
  }
}

.passports-wrapper-light-mode {
  overflow-x: auto;
  display: flex;
  padding: 15px;

  .passports-container {
    min-width: 250px;
    margin-right: 10px;
    float: left;
    overflow: hidden;
  }

  .request-list {
    min-width: 270px;
    margin-right: 10px;
    float: left;
    overflow: hidden;
    .border-radius-4;

    .progress {
      width: 100%;
      margin: 0 !important;
    }

    .avg-price {
      display: inline;
      position: relative;
      float: right;
      font-family: @font-family-semibold !important;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .upcoming-updates-list {
    min-width: 200px;
    max-width: 200px;
    position: relative;

    h4 {
      margin: 0;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      margin-top: 0;
    }

    .wrap-text {
      white-space: normal !important;
      word-wrap: break-word;
      text-align: left;
    }

    .col-md-2,
    .col-md-3,
    .col-md-4 {
      text-align: left;
    }

    .well-footer {
      position: absolute;
      bottom: 5px;
      width: 87%;
    }

    .label-big {
      font-size: calc(@font-size * 1.1) !important;
    }
  }
}

.accordion .card div.ul-inline-row.upcoming-updates-list,
div.ul-inline-row.upcoming-updates-list {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  line-height: 1 !important;
  word-wrap: break-word;
  white-space: normal;
  h5 {
    margin-bottom: 5px;
  }
  .box-shadow-medium;
  border-left: 0 !important;
  .label {
    display: inline-flex;
  }
  div .form-control.transparent-form {
    font-size: calc(@font-size / 1.3) !important;
    min-height: 10px !important;
    border: 1px solid @borders-color !important;
    border-radius: 6px !important;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.executed_request.ul-inline-row.upcoming-updates-list {
  border-top: 0 !important;
  border-radius: 8px !important;
}

.new_request.ul-inline-row.upcoming-updates-list {
  border-top: 0 !important;
  border-radius: 8px !important;
  line-height: 1 !important;
  .box-shadow-medium;
  border-left: 4px solid @label-success-color-border !important;
  .label {
    display: inline-flex;
  }
}

.warning_request.ul-inline-row.upcoming-updates-list {
  border-top: 0 !important;
  border-radius: 8px !important;
  line-height: 1 !important;
  .box-shadow-medium;
  border-left: 4px solid @label-default-color-border !important;
  .label {
    display: inline-flex;
  }
}

.danger_request.ul-inline-row.upcoming-updates-list {
  border-top: 0 !important;
  border-radius: 8px !important;
  line-height: 1 !important;
  .box-shadow-medium;
  border-left: 4px solid @label-danger-color !important;
  .label {
    display: inline-flex;
  }
}

.updates-list-container {
  .executed_request.ul-inline-row.upcoming-updates-list {
    margin-bottom: 15px !important;
    white-space: normal;
  }
  .btn-group .btn {
    overflow: visible !important;
  }

  .label-warning {
    .gt-date-select {
      .dropdown-menu {
        position: static;
      }
    }
  }
}

.payment-info-wrapper-light-mode {
  .passports-wrapper-light-mode;

  .request-list {
    min-width: 150px;

    h4 {
      width: 100%;
    }
  }
}

.bank-accounts-container {
  .request-list {
    overflow: visible;
    padding: 10px !important;
    padding: 6px;
    .box-shadow-light;
    .counterparty_detail_table {
      .label {
        white-space: nowrap !important;
      }
    }
  }
}

.passport-alert {
  margin-top: -3px;
  margin-left: -5px;
  width: 100%;
}

.upcoming-updates-list {
  h4,
  h5 {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.deals-costs-table {
  .form-control {
    height: 12px !important;
    font-size: calc(@font-size / 1.3);
  }

  .ui-select-bootstrap > .ui-select-match > .btn {
    line-height: 1 !important;
    padding: 2px;
  }
}

.passport-costs-details {
  border-collapse: separate;

  th {
    font-size: calc(@font-size / 1.3);

    label {
      font-size: @font-size;
    }
  }

  td {
    padding: 2px !important;
    border-bottom: 1px solid @borders-color !important;
  }

  .highlighted-td {
    background: @tables-background !important;
    border-right: 1px solid @borders-color !important;
    border-left: 1px solid @borders-color !important; //border-bottom: 1px solid transparent !important;
    border-radius: 0 !important;
  }

  .label {
    float: none;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-size: 0.8em;
    padding: 2px !important;
  }

  input[type='checkbox'],
  input[type='radio'] {
    margin: 0 !important;
    padding: 0 !important;
    width: 20px;
    height: 20px;
  }
}

.loyalty-program-table {
  tr td {
    text-align: right;

    .label {
      font-size: @font-size;
    }
  }

  tr td.bold {
    font-family: @font-family-semibold !important;
  }
}

.status-report-table {
  tr td {
    text-align: left;
    font-size: calc(@font-size / 1.3);
    white-space: nowrap;
  }

  .label {
    font-size: calc(@font-size / 1.3) !important;
  }

  .allow-wrap {
    white-space: normal;
  }

  tr th.thick-border,
  tr td.thick-border {
    border-right: 3px solid @borders-color !important;
  }

  .highlighted-td {
    background: @highlighted-area !important;
    border-right: 1px solid @borders-color !important;
    border-left: 0 !important;
  }

  .alert-danger {
    color: @label-danger-color !important;
    font-family: @font-family-bold !important;
  }
}

.mtm-table-passport {
  tr td {
    font-size: @font-size !important;
  }

  tr th {
    text-align: right !important;
  }
  tr th:nth-child(1) {
    text-align: left !important;
  }
  tr th:nth-child(2) {
    text-align: left !important;
  }
}

.passport-process-details {
  tr td:nth-child(2),
  tr td:nth-child(3) {
    width: 10% !important;
  }

  tr td {
    vertical-align: top !important;
  }

  .form-control {
    margin-bottom: 2px;
    font-size: calc(@font-size / 1.3) !important;
    padding: 2px;
  }

  .form-control[disabled],
  fieldset[disabled] {
    .border-radius-4;
  }
}

.passport-info {
  font-size: calc(@font-size / 1.3) !important;

  table {
    margin: 0 auto;

    td {
      padding: 2px;
      font-size: @font-size !important;
    }

    .badge {
      margin-left: 2px;
      margin-right: 2px;
      font-size: @font-size !important;
    }
  }
}

.contracts-attached-container-cut {
  display: inline-block;
  margin-right: 5px;
}

.contract-status-container {
  .centered;
  width: 100% !important;
  font-size: @font-size;
  display: inline-flex;

  .fa {
    font-size: @font-size !important;
  }

  .fa-question-circle {
    font-size: calc(@font-size / 1.3) !important;
    float: left;
    margin-right: 2px;
  }

  .fa-check-circle,
  .fa-circle-o-notch,
  .fa-circle-o,
  .fa-times-circle-o,
  .fa-info-circle {
    float: right;
  }

  .tooltip:hover::before {
    top: 45px;
    z-index: 9999 !important;
  }

  .inner {
    width: 100%;
    text-align: left;

    .label {
      text-align: right;
      float: right;

      .fa {
        margin-left: 5px;
      }
    }
  }
}

.contract-status-sub-container {
  display: inline;
  vertical-align: top;
}

.contract-status-block {
  text-align: center;
  padding: 2px;
  margin-bottom: 0;
  border-radius: 4px;
  width: 100%;
  font-family: @font-family-regular !important;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  .contract_details {
    width: 100%;
    margin-bottom: 2px !important;
    display: block;
  }

  .fa-long-arrow-down {
    font-size: calc(@font-size / 1.3);
    color: @contract-executed-color;
  }

  .sub-status {
    .fa {
      font-size: calc(@font-size / 1.3) !important;
      padding-left: 10px;
    }
  }
}

.contract-status-block-sub {
  width: 100%;
  min-width: 200px;
  text-align: left;
  .border-radius-4;
  display: block;
  margin-bottom: 2px;
  padding: 2px;
  font-size: calc(@font-size / 1.3);
  font-family: @font-family-regular !important;
  text-transform: uppercase;
}

.contract-status-block-td {
  padding: 2px;
  margin-bottom: 0;
  font-size: 0.8em;
  white-space: nowrap;
  width: 100% !important;
  text-transform: uppercase;

  .contract-status-block {
    width: 98% !important;
    overflow: hidden;
    font-size: 0.7em;
    border-radius: 0.85em;
    .box-shadow-light;
  }

  .fa {
    font-size: calc(@font-size * 1.1);
    display: block;
  }
}

.status_success {
  background: @status-success-background !important;
  color: @contract-new-color !important;
  opacity: 1 !important;

  .fa {
    color: @contract-new-color !important;
  }
}

.status_default {
  color: @label-default-color;
  opacity: 0.7;

  .fa {
    color: @contract-executed-color;
  }
}

.status_exclamation {
  background: @status-exclamation-background !important;
  color: @contract-processing-color !important;
  opacity: 1 !important;

  .fa {
    color: @contract-processing-color !important;
  }
}

.status_danger {
  background: @status-danger-background !important;
  color: @label-danger-color !important;
  opacity: 1 !important;

  .fa {
    color: @label-danger-color !important;
  }
}

.sorting-filters-row {
  margin-bottom: 5px;
  display: block;
  height: 30px;

  .btn-link {
    margin-right: 5px;
    padding: 6px 12px;
    font-size: 0.7em !important;
  }
}

.filter-th:first-child {
  border-left: 5px solid @label-info-color-border !important;
}

.filter-th {
  border-top: 0 !important;
  border-bottom: 2px solid @borders-color !important;
  background: @tables-background !important;
}

.filter-th:hover {
  background: @tables-background !important;
}

.contract-details-small {
  margin-top: 5px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  position: relative;
  display: block;
  overflow-x: auto;
}

.page-header {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.page-header-main {
  margin-right: -35px;
  margin-left: -35px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  margin-bottom: 10px;

  h1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    font-family: @font-family-bold !important;
    font-size: calc(@font-size * 1.5);
    color: @tables-background;
    letter-spacing: @letter-spacing-huge;
    padding: 0px;

    .fa-caret-down {
      margin-left: 10px;
      transition:
        transform 0.4s ease-in-out,
        opacity 0.4s ease-in;
      opacity: 0.001;
      transform: translate3d(0, 8px, 0);
      height: 1px;
      display: inline-block;
    }

    .btn-orange-border {
      color: @tables-background !important;
      margin-left: 10px !important;
      padding: 2px 6px !important;
    }

    .user-dropdown-btn {
      padding-left: 0;
      margin-left: 15px;
      font-size: calc(@font-size * 1.5);
      transition: unset !important;
      transform: unset !important;
      font-family: @font-family-bold !important;
    }

    .label_start {
      background: transparent !important;
      color: @tables-background !important;
      font-size: @font-size !important;
      font-family: @font-family-bold !important;
    }

    .help-btn-group {
      margin-top: 15px !important;
    }

    .item-settings-dropdown .dropdown-menu {
      width: 100%;
    }
  }

  &:hover {
    .fa-caret-down {
      .transition;
      opacity: 1;
      transform: translate3d(0, 0, 0);
      height: auto;
    }
  }

  .payment-details-container {
    .border-radius-4;
    margin-bottom: 10px;
    margin-top: 10px;

    .item-details-block-transparent .item-details-el {
      padding: 10px !important;
      margin: 2px !important;
      .border-radius-4;
      background: rgba(0, 0, 0, 0.1) !important;

      .label {
        display: block;
        text-align: center;
        padding: 2px !important;
        float: none;
        margin: 0 auto !important;
      }
    }

    .item-details-block-transparent {
      -webkit-box-pack: initial;
      -webkit-box-align: center;
    }

    .contract-details-table {
      tr td,
      tr th {
        color: @tables-background !important;
        border: 0 !important;
      }

      tr th {
        display: none !important;
      }
    }
  }

  .updated_time {
    color: @tables-background !important;
    margin-left: 5px;
    vertical-align: middle;
    line-height: 1;
  }

  .btn-success {
    background: transparent !important;
    color: @tables-background !important;
    margin-top: 0;
    border: 0 !important;
    margin-left: 0;
    box-shadow: none !important;
  }

  .btn-success:hover {
    background: darken(@label-success-color, 9%) !important;
    color: @tables-background !important;
  }

  .user-dropdown-btn {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .nav {
    .btn {
      .label_start {
        background: transparent !important;
      }
    }
  }

  .nav .open > a {
    background: @color-black-opacity;
  }

  .nav > li > a:focus,
  .nav > li > a:hover {
    background: @color-black-opacity;
  }

  .main-nav-tabs {
    .label_start {
      background: transparent !important;
      color: @main-nav-tabs-color !important;
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }

    li a {
      background: rgba(0, 0, 0, 0.3) !important;
      color: @white;
      padding: 8px !important;
      padding-bottom: 4px !important;
      padding-top: 4px !important;
      font-family: @font-family-regular !important;
      border-bottom: 1px solid transparent !important;
      letter-spacing: @letter-spacing-huge;
      &::first-letter {
        text-transform: uppercase !important;
      }
      font-size: calc(@font-size / 1.2) !important;
    }

    li.active > a {
      color: @smoke !important;
      background: @main-background !important;
      border-color: transparent !important;
      font-family: @font-family-semibold;
      padding-top: 6px !important;

      .label_start {
        color: @smoke !important;
        font-family: @font-family-semibold !important;
      }
    }

    li.active:hover > a {
      color: @contract-executed-color !important;
      background: @main-background !important;
      border-color: transparent !important;
    }

    li:hover > a {
      background: rgba(0, 0, 0, 0.15) !important;
      border-color: transparent !important;
    }
  }

  .form-control {
    background: @main-background-color !important;
  }

  small {
    margin-top: 23px;
    margin-left: 5px;
  }

  .help-btn-group {
    a {
      background: @tables-background !important;
    }

    a.btn-info {
      background: @label-info-color !important;
      color: @tables-background !important;
    }

    a:hover {
      background: @main-background-color !important;
    }

    a.btn-info:hover {
      background: @label-info-color !important;
    }

    .navbar-nav {
      .user-dropdown-btn {
        color: @main-background-color !important;
      }
    }
  }

  .user-dropdown-btn {
    color: @tables-background !important;
  }

  .user-dropdown-btn:hover {
    color: @tables-background !important;
  }
}

h1 {
  .user-dropdown-btn {
    padding-top: 10px;
    padding-bottom: 10px;
    letter-spacing: @letter-spacing-huge;
  }
}

.page-header-main-info-tab {
  background: darken(@navbar-background, 9%) !important;

  .help-btn-group {
    a {
      color: @navbar-background !important;
    }
  }
}

.page-header-main-pl-tab {
  background: @pl-tab !important;

  .help-btn-group {
    a {
      color: @pl-tab !important;
    }
  }
}

.page-header-main-crm-tab {
  background: @crm-tab !important;

  .help-btn-group {
    a {
      color: @crm-tab !important;
    }
  }
}

.page-header-main-prices-tab {
  background: @prices-tab !important;

  .help-btn-group {
    a {
      color: @prices-tab !important;
    }
  }
}

.page-header-main-logistics-tab {
  background: @logistics-tab !important;

  .help-btn-group {
    a {
      color: @logistics-tab !important;
    }
  }
}

.page-header-main-payments-tab {
  background: @payments-tab !important;

  .help-btn-group {
    a {
      color: @payments-tab !important;
    }
  }
}

.page-header-main-clients-tab {
  background: @clients-tab !important;

  .help-btn-group {
    a {
      color: @clients-tab !important;
    }
  }
}

.left-sidebar {
  padding-top: 60px;
  margin-bottom: -99999px;
  padding-bottom: 99999px;

  .contract_details {
    a {
      cursor: pointer;
    }
  }

  .badge.contract_details {
    padding: 2px !important;
    margin-bottom: 0px !important;
  }

  .cut-table {
    margin-bottom: 30px !important;
  }
}

.central-container {
  .contract_details {
    font-size: calc(@font-size / 1.3);
  }

  .label-opacity.contract_details {
    font-size: calc(@font-size / 1.3) !important;
    background: transparent !important;
  }

  .badge.contract_details {
    font-size: calc(@font-size / 1.3);
    padding: 2px !important;
    margin-bottom: 5px;
  }
}

table tr td.no-data-row {
  padding: 0;
}

.ui-select-bootstrap > .ui-select-choices,
.ui-select-bootstrap > .ui-select-no-choice {
  .border-radius-4;
  padding: 10px !important;
  min-width: 100%;
  max-height: 350px;
  background: @tables-background;
}

.ui-select-bootstrap > .ui-select-choices::before {
  border-radius: 4px;
  color: @label-info-color;
  background: @label-info-background;
  content: 'Start typing to search in the list';
  padding: 4px;
  position: relative;
  opacity: 1;
  visibility: visible;
  white-space: normal;
  word-wrap: break-word;
  font-size: calc(@font-size / 1.3) !important;
  font-family: @font-family-semibold !important;
  text-transform: inherit !important;
  line-height: 1;
  margin-bottom: 5px;
  display: block;
  text-align: center;
  width: 100%;
}

.no_content_text {
  margin: 0 !important;
  text-transform: none !important;
  padding: 10px;
  font-size: calc(@font-size / 1.3) !important;
  color: @no-content-text-color !important;
  white-space: normal;

  font-family: @font-family-regular !important;
  .no-text-stroke;
  border: 0 !important;
  text-align: center;
  .centered;
  width: 100% !important;
}

.show-more-block {
  background: @color-black-opacity-dark;

  td {
    background: @color-black-opacity-dark;
  }
}

.no_content_text td {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  color: @no-content-text-color !important;
  text-align: center !important;
  font-size: calc(@font-size / 1.3) !important;
  .no-text-stroke;
  .centered;
}

.no_content_text td:hover {
  cursor: default;
}

#contract_errors {
  color: @lower-zero-color !important;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
  background: @contract-errors-background;
  font-size: 1.5em;
}

.export_img {
  opacity: 0.2;
  background-size: cover;
  margin-right: 15px;
  height: 20px;
  width: 20px;
}

.export_img:hover {
  opacity: 0.7;
}

.print-item {
  display: none;
}

.search-container {
  border: 1px solid darken(@borders-color, 9%) !important;
  border-radius: 4px !important;

  input {
    border-radius: 0 !important;
  }

  &:focus,
  &:active,
  &:hover {
    border: 1px solid darken(@borders-color, 17%) !important;
    .border-radius-4;
  }

  .btn {
    padding: 2px;
    background: transparent !important;
  }
}

.search {
  .centered;
  background: transparent;
  font-family: @font-family-regular, Helvetica, Arial, sans-serif;
  color: @contract-executed-color;
  border: 0 !important;
  outline: none;
  box-shadow: none !important;
  border-bottom: 1px solid @borders-color-light;
  border-radius: 0;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  height: 26px;
}

.filter {
  .search;
}

//placeholders
.filter::-moz-placeholder {
  color: @placeholder-color !important;
  font-size: calc(@font-size / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

.filter::-webkit-input-placeholder {
  color: @placeholder-color !important;
  font-size: calc(@font-size / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

.filter:-ms-input-placeholder {
  color: @placeholder-color !important;
  font-size: calc(@font-size / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

.search::-webkit-input-placeholder {
  color: @placeholder-color !important;
  font-size: calc(@font-size / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

.search::-moz-placeholder {
  color: @placeholder-color !important;
  font-size: calc(@font-size / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

.search:-ms-input-placeholder {
  color: @placeholder-color !important;
  font-size: calc(@font-size / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

/* ie */

.password-form div input::-webkit-input-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f023  ';
}

.password-form div input::-moz-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f023  ';
}

/* firefox 19+ */

.password-form div input:-ms-input-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f023  ';
}

/* ie */

.password-form div input:-moz-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f023  ';
}

.password-form div input::-webkit-input-placeholder {
  color: @placeholder-color;
  font-family: 'Light', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

.password-form div input::-moz-placeholder {
  color: @placeholder-color;
  font-family: 'Light', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

/* firefox 19+ */

.password-form div input:-ms-input-placeholder {
  color: @placeholder-color;
  font-family: 'Light', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

/* ie */

.password-form div input:-moz-placeholder {
  color: @placeholder-color;
  font-family: 'Light', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

.login-form div input::-webkit-input-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f007  ';
}

.login-form div input::-moz-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f007  ';
}

/* firefox 19+ */

.login-form div input:-ms-input-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f007  ';
}

/* ie */

.login-form div input:-moz-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f007  ';
}

.login-form div input::-webkit-input-placeholder {
  color: @placeholder-color;
  font-family: 'Extrabld', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

.login-form div input::-moz-placeholder {
  color: @placeholder-color;
  font-family: 'Extrabld', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

/* firefox 19+ */

.login-form div input:-ms-[input]-placeholder {
  color: @placeholder-color;
  font-family: 'Extrabld', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

/* ie */

.login-form div input:-moz-placeholder {
  color: @placeholder-color;
  font-family: 'Extrabld', 'FontAwesome';
  letter-spacing: @letter-spacing-normal;
}

.form-login.form-horizontal {
  .checkbox {
    padding-left: 0 !important;
    margin-left: 0 !important;
    text-align: right !important;
    margin-bottom: 7px;
  }

  #Terms {
    margin-left: 25px !important;
  }

  .checkbox-inline {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    font-family: @font-family-light !important;
  }

  .form-control {
    height: 44px;
    border: 0 !important;
    background-color: darken(@highlighted-area, 3%) !important;
    border-left: 0 !important;
  }

  .form-control:focus {
    border: 0 solid @borders-color !important;
    background-color: darken(@highlighted-area, 9%) !important;
    border-left: 0 !important;
  }
}

.comment-form {
  border: 1px solid @legend-color !important;
  .border-radius-4;
  width: 93%;
  text-align: left !important;
}

#left-sidebar {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.scrollable {
  height: 800px;
  overflow: hidden;
}

.scrollable:hover {
  overflow: auto;
}

/*hack for disabling scrollbar in FF*/

@-moz-document url-prefix() {
  .scrollable {
    height: auto;
    overflow: visible;
  }
}

.left-sidebar-buttons {
  .centered;
  width: 84% !important;
  margin-bottom: 10px !important;
  font-size: 0.9em;
  padding: 2px 10px;
  display: block;
}

//central container

.contract-properties {
  display: none;
}

.contract-description-label {
  color: @contract-description-label-color;
}

.contract-description-item {
  min-height: 50px;
  font-size: 0.9em;
}

.contract-description-item-value {
  .centered-text;
  font-size: @big-font;
  color: @contract-description-item-value-color;

  span {
    color: @contract-description-item-value-span-color;
    font-size: 0.7em !important;
  }
}

.contract-description-item-title {
  .centered-text;
  font-size: @small-font / 1.2;
  font-weight: @bold;
}

//documents
.documents-list-table {
  .table-hover;
  margin-bottom: 0 !important;

  tr td {
    font-size: calc(@font-size / 1.3);
    border: 0 !important;

    .ui-select-match-text {
      white-space: nowrap;
    }
  }

  .no-data-img {
    width: 50px;
    height: 50px;
  }
}

.docs-check li,
.docs-list li {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: calc(@font-size / 1.1);
  margin-bottom: 5px !important;
  color: @main-table-td !important;
}

.docs-list {
  li:hover {
    background: @documents-background;
  }

  p {
    margin-right: 10px;
    margin-top: 0;
    line-height: 1.5;
    text-align: right;
    word-break: break-all;
    overflow: hidden;
    color: @main-table-td !important;
  }

  .docs-ico {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background-size: contain !important;
  }

  .docs-word {
    background: url(img/word-ico.png);
  }

  .docs-xls {
    background: url(img/excel-ico.png);
  }

  .docs-pdf {
    background: url(img/pdf-ico.png);
  }
}

.table-template .docs-list-small,
.docs-list-small {
  .docs-list;

  td,
  td:nth-child(1),
  td:nth-child(2) {
    padding: 2px !important;
    border-top: 0 !important;
    border-bottom: 1px solid darken(@borders-color, 9%) !important;
    vertical-align: middle !important;
    color: @main-table-td !important;
    border: 0 !important;
    font-size: calc(@font-size / 1.1);

    .label {
      font-size: calc(@font-size / 1.1);
    }
  }
}

.docs-uploaded-img-small {
  background-size: contain !important;
  width: 20px !important;
  height: 20px !important;
  border: 0 !important;
  margin-left: 0 !important;
}

.docs-uploaded-span-small {
  font-size: calc(@font-size / 1.3);
  color: @contract-executed-color !important;
}

.formly-field {
  .form-group.col-xs-12 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.modal-form-container {
  .modal-form-block {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    margin-bottom: 10px !important;

    .ul-inline-row {
      background: @tables-background !important;
      padding: 30px !important;
      border: 0 !important;
      border-radius: 8px;

      .btn-add-dark,
      .btn-primary {
        .border-radius-4;
      }

      .btn-add-dark {
        .border-radius-4;
        border: 2px solid @navbar-background !important;
        color: @navbar-background !important;

        &:hover {
          color: @tables-background !important;
        }
      }

      .btn-primary {
        border: 2px solid @navbar-background !important;
        background: @navbar-background !important;
        color: @tables-background !important;
        margin-left: 5px !important;
      }
    }
  }

  .contract-description-label {
    font-size: @font-size;
    color: @blue-color !important;
    font-family: @font-family-bold;
  }

  .form-group {
    .btn-default {
      background: transparent !important;
    }

    .input-group-addon {
      border-radius: 0 !important;
      padding: 2px !important;
    }
  }

  .input-group-addon-search {
    font-size: calc(@font-size / 1.3);
    color: @contract-executed-color;
    padding: 0;
    padding-top: 5px;
    width: 2%;
    float: left;
    display: none;

    .fa-search {
      display: none;
    }
  }

  .btn:hover,
  .btn:focus {
    transform: none;
    transition: none;
  }

  .copy-clipboard-btn {
    display: none;
  }

  .not-empty:hover .copy-clipboard-btn {
    display: block !important;
    position: absolute;
    right: 15px;
    top: 0;
    background: transparent;
    border: 0;
    border-radius: 100%;
    cursor: pointer;
    font-size: calc(@font-size / 1.3);
    color: @light-blue;
    z-index: 9999 !important;
    .fa {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  .select-with-btn {
    width: 85%;
    float: left;

    .btn {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border: 0 !important;
      padding: 0 !important;
    }
  }

  .gt-date-select {
    .dropdown-menu {
      left: -70px !important;
    }
  }

  .add-btn {
    color: @btn-default-color !important;
  }

  .form-add-btn {
    padding: 0;
    width: 12%;
    float: right;

    a {
      height: 10px;
      margin: 0;
      line-height: 1;
      padding: 0;
      width: 100%;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      font-size: calc(@font-size / 1.3);
      box-shadow: none !important;
      background: transparent !important;
      border-color: transparent !important;
      color: @btn-default-color !important;
    }

    a:hover {
      color: @label-success-color-border !important;
      background: transparent !important;
    }
  }

  .form-control .fa-remove {
    font-size: calc(@font-size / 1.3) !important;
  }

  .form-add-btn-fullwidth {
    height: 20px;
    margin: 0;
    padding: 0;
    width: 100%;

    a {
      height: 20px;
      margin: 0;
      padding: 0;
      width: 100%;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      margin-top: -2px;
      font-size: @font-size;
      box-shadow: none !important;
      background: transparent !important;
      border-color: transparent !important;
    }

    a:hover {
      background: @label-success-color !important;
    }
  }

  .form-dismiss-select {
    position: absolute;
    right: 65px;
    top: 8px;
    font-size: 15px !important;
  }

  input[type='date'] {
    width: 100% !important;
    float: left;
  }

  .input-group {
    width: 100%;
  }

  select {
    background: transparent !important;
  }

  select option {
    color: @btn-default-color !important;
  }

  select option[selected] {
    color: @contract-executed-color !important;
    background: @tables-background !important;
    font-family: @font-family-semibold !important;
  }

  .caret {
    display: none;
  }

  input[type='checkbox'],
  input[type='radio'] {
    margin: 0;
    width: 15px;
    height: 15px;
  }

  .ui-select-multiple.ui-select-bootstrap {
    padding: 0;
  }

  input[type='date'] {
    background: transparent !important;
    border-radius: 0 !important;
  }

  .counterparty-block {
    .modal-form-block {
      margin-bottom: 0;
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .form-group {
      width: 55%;
    }
  }
}

.ui-select-container {
  .form-search-select {
    display: none;
  }

  .form-control {
    font-family: @font-family-regular, 'FontAwesome' !important;
    background: transparent !important;
  }
}

.ui-select-container.open {
  .form-search-select {
    display: block;
    color: @contract-executed-color;
    position: absolute;
    left: 5px;
    top: 12px;
    font-size: 9px !important;
  }
}

.ui-select-multiple {
  overflow: visible !important;

  .close {
    float: right !important;
  }

  .btn {
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: block;
    width: auto;
  }

  input {
    width: 100% !important;
  }
}

.analytics-container {
  .ui-select-multiple.ui-select-bootstrap {
    margin-bottom: 5px;
  }

  label {
    padding-left: 5px;
  }

  .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    font-size: calc(@font-size / 1.3) !important;
    &::-webkit-input-placeholder {
      font-size: calc(@font-size / 1.3) !important;
      &::first-letter {
        text-transform: uppercase !important;
      }
    }
  }
}

.ui-select-multiple.ui-select-bootstrap {
  padding: 5px;
}

.form-group-container .form-group .ui-select-multiple {
  padding-left: 0 !important;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  background: transparent !important;
  font-family: @font-family-regular, sans-serif;
  .border-radius-4;
  margin-bottom: 0;
  font-size: @font-size !important;

  &::-webkit-input-placeholder {
    color: @placeholder-color !important;
    text-overflow: ellipsis;
  }
}

.ng-not-empty.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  font-family: @font-family-semibold sans-serif;
  background: @forms-background !important;
  border: 1px solid @forms-border !important;
}

.form-group-container {
  padding-left: 10px !important;
  padding-right: 10px !important;

  .form-group {
    padding-left: 0px !important;
    padding-right: 0px !important;

    .control-label {
      font-size: calc(@font-size / 1.2) !important;
      padding-top: 0 !important;
      padding-left: 0px !important;
      margin: 0 !important;
      color: @form-control-color-focus;
      font-family: @font-family-regular;
      text-align: left !important;
      text-overflow: ellipsis;
      overflow: hidden;
      min-height: 24px;
      line-height: 1 !important;
      display: flex;
      align-items: center;
      word-break: break-word;
      text-transform: capitalize !important;

      .check-label {
        float: left;
        text-align: left !important;
        padding-right: 4px;
        font-size: calc(@font-size / 1.5) !important;

        .fa-circle {
          color: @forms-background;
        }
      }
    }

    .not-empty {
      .control-label {
        .fa-circle {
          color: @green !important;
        }
      }
      &hover {
        .control-label {
          .fa-circle {
            color: @green;
          }
        }
      }
    }

    .ui-select-container {
      padding-left: 0 !important;
    }

    input {
      padding-left: 0 !important;
    }

    input.form-control {
      padding-left: 0 !important;
    }

    .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
      font-size: @font-size !important;
      padding-left: 0;
      margin: 0;

      &::-webkit-input-placeholder {
        display: none !important;
        color: @placeholder-color !important;
        font-size: @font-size !important;
        text-overflow: ellipsis;
      }
    }

    .ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
      line-height: 1;
    }

    .form-control {
      border: 0 !important;
      padding-left: 0 !important;
      height: auto;
      font-size: calc(@font-size / 1.2) !important;
      overflow: visible;
      white-space: normal;
      line-height: 1;

      &::-webkit-input-placeholder {
        display: none !important;
        color: transparent !important;
        font-size: calc(@font-size / 1.2) !important;
        text-overflow: ellipsis;
      }

      &::-moz-placeholder {
        display: none !important;
        color: transparent !important;
        font-size: calc(@font-size / 1.2) !important;
        text-overflow: ellipsis;
      }

      &:-moz-placeholder {
        display: none !important;
        color: transparent !important;
        font-size: calc(@font-size / 1.2) !important;
        text-overflow: ellipsis;
      }

      &:-ms-input-placeholder {
        display: none !important;
        color: transparent !important;
        font-size: calc(@font-size / 1.2) !important;
        text-overflow: ellipsis;
      }

      .text-muted {
        font-size: calc(@font-size / 1.2) !important;
        color: transparent !important;
      }
    }

    .not-empty {
      .form-control,
      .form-control .ui-select-match-text {
        font-family: @font-family-regular;
      }

      .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
        font-family: @font-family-regular;
        font-size: calc(@font-size / 1.2) !important;
        white-space: normal;
        word-break: break-word;
        line-height: 1;
        .updating {
          display: none;
        }
      }

      .control-label {
        font-family: @font-family-regular;
      }
    }
  }

  .form-group.col-xs-12 {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 2px !important;
    border-radius: 0;
    padding: 0px !important;
  }

  .form-group.col-xs-12:hover {
    .control-label .fa-circle {
      color: darken(@forms-background, 29%) !important;
    }
  }

  .has-error {
    .control-label,
    .form-group .col-md-7 {
      height: 30px !important;
    }
    .control-label {
      .fa-circle {
        color: @label-danger-color !important;
      }
    }
  }

  .form-group .col-md-7 {
    height: 20px;
    line-height: 1;
    border-bottom: 1px solid @forms-border !important;

    .input-group-addon {
      color: @form-control-color-focus;

      .fa {
        color: @form-control-color-focus;
      }
    }
  }
  .not-empty,
  .has-error {
    .form-group .col-md-7 {
      height: auto !important;
    }
  }

  .gt_test_wrapper_contract_prices,
  .gt_test_wrapper_derivatives,
  .gt_test_wrapper_few_commodity,
  .gt_test_wrapper_role_names,
  .gt_test_wrapper_extra_actions,
  .gt_test_wrapper_contract_consignees {
    height: auto !important;
  }

  .form-group.col-xs-12.focused {
    background: @color-black-opacity !important;
  }

  .form-group.not-empty {
    font-family: @font-family-regular !important;
  }

  .has-error {
    .form-group {
      background: @status-danger-background !important;
      border-color: @label-danger-color !important;
    }
  }

  select[multiple],
  select[size] {
    overflow: auto !important;
  }

  textarea.form-control {
    resize: auto !important;
  }
}

.form-group-comparison-container {
  .modal-form-block {
    margin-top: -45px !important;
  }

  .ui-select-bootstrap > .ui-select-match > .btn {
    line-height: 1 !important;
  }
}

.form-group-container .form-group .contract-price-widget .form-control {
  line-height: 1 !important;
  border-bottom: 1px solid darken(@borders-color, 9%) !important;
  text-align: left !important;
  height: 22px !important;
  width: 100% !important;
}

.contract-price-widget {
  background: @alt-background;
  padding: 12px;
  overflow: visible;

  label {
    font-size: calc(@font-size / 1.3) !important;
    text-align: right !important;
  }

  .form-group-container .form-group .form-control {
    line-height: 1 !important;
    text-align: left !important;
    width: 100% !important;
    display: block;
    height: auto !important;
    overflow: auto;
  }

  .btn-default {
    line-height: 1 !important;
  }

  .btn-default.form-control {
    line-height: 1 !important;
  }

  .input-group {
    .col-sm-5 {
      text-align: right !important;
    }

    .btn-default {
      display: inline-block;
    }
  }

  .input-group {
    .col-sm-7 {
      padding-left: 0;
      padding-right: 0;
    }
    .col-sm-5 {
      padding-left: 0;
    }
  }
}

.gt_test_wrapper_contract_prices,
.gt_test_wrapper_derivatives,
.gt_test_wrapper_few_commodity,
.gt_test_wrapper_contract_consignees {
  width: 100%;
}

.contract-price-widget-table {
  tr td {
    vertical-align: middle !important;
    width: auto;
    font-size: calc(@font-size / 1.3) !important;
    padding: 2px !important;

    .form-control {
      width: 100% !important;
      display: block;
      height: 22px !important;
    }
  }

  tr td:nth-child(1) {
    width: 10%;
  }
}

.form-group-container .form-group .contract-price-widget-table {
  .form-control {
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 100% !important;
    display: block;
    height: 22px !important;
    font-size: calc(@font-size / 1.3) !important;
  }
}

.role-form-group-container {
  .modal-form-block {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.finance-modal-form-container {
  .form-group-container.main-block-wide {
    .modal-form-block {
      width: 100%;
    }
  }
}

.client-modal-form-container {
  .form-group-container.main-block-wide {
    .modal-form-block {
      width: 100%;
    }
  }
}

.form-group-container.main-block-wide {
  display: inline-block;
  margin-bottom: 5px !important;
  margin-left: 5px !important;
  margin-right: 10px !important;
  background: @tables-background !important;
  .box-shadow-light;
  border-radius: 8px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 99%;

  .modal-form-block {
    width: 33%;
    float: left;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-bottom: 0 !important;
  }

  .modal-form-block .ul-inline-row {
    text-align: left;
    background: transparent !important;
    border: 0 !important;
    box-shadow: none;
  }
}

.formly-field-gt-ui-multiselect {
  .form-control {
    height: auto !important;
  }
}

.input-group .form-control {
  .border-radius-4;
}

.input-group .form-control:first-child {
  border-top-left-radius: 0.25em !important;
  border-bottom-left-radius: 0.25em !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group .form-control:last-child {
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.input-group-inline {
  .form-control {
    width: auto !important;
    display: inline-block;
    height: 30px;
  }

  .input-group-btn {
    display: inline-block;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    box-shadow: none !important;
  }

  .input-group-btn:last-child > .btn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.request-modal-form-container {
  .modal-form-container;

  input[type='date'] {
    width: 96% !important;
    float: left;
  }
}

.client-modal-form-container {
  .modal-form-container;
}

.role-modal-form-container {
  .modal-form-container;
}

.logistic-modal-form-container {
  .modal-form-container;
}

.finance-modal-form-container {
  .modal-form-container;
}

.passport-modal-form-container {
  .modal-form-container;
}

.person-form-container {
  .form-group {
    margin-bottom: 5px !important;
  }

  textarea.form-control {
    min-height: 200px !important;
  }
}

.vessel-modal-form-container {
  .modal-form-container;
}

.contract-modal-form-container,
.request-modal-form-container {
  .modal-form-container;

  textarea.additional-info.form-control {
    height: auto !important;
    min-height: 200px;
  }
}

.costs-form-container {
  .form-group {
    padding-bottom: 3px !important;
    padding-top: 3px !important;
    border-bottom: 1px dotted @borders-color;
  }

  .control-label {
    text-align: left !important;
    float: left !important;
    padding-top: 3px !important;
  }

  .control-label.label_default {
    margin-top: 7px !important;
    padding-top: 0 !important;
  }

  input[type='checkbox'] {
    width: 40% !important;
  }
}

.quality-form-container {
  .form-group {
    padding-bottom: 3px !important;
    padding-top: 3px !important;
  }
}

.contract-modal-form-container {
  .modal-form-container;
  display: block;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 0;

  input[type='date'] {
    max-width: 95%;
  }

  .form-dismiss-select {
    right: 55px;
  }
}

.task-modal-form-container {
  .modal-form-container;
}

/*CLIENTS*/

.clients-page {
  .overlay {
    .fa {
      left: 48% !important;
    }
  }

  .filter-template.crm-relations-filter {
    .border-radius-4;
  }
}

.status_container {
  height: 100px;
  display: inline-block;
  width: auto;
}

.crm-relations-filter {
  .border-radius-4;

  .btn {
    border-left: 3px solid !important;
    .border-radius-4;
  }

  .btn:hover {
    border-left: 6px solid !important;
  }

  .btn:nth-child(1) {
    border-left-color: @highlighted-area !important;
  }

  .btn:nth-child(2) {
    border-left-color: @label-danger-color !important;
  }

  .btn:nth-child(3) {
    border-left-color: @label-warning-color !important;
  }

  .btn:nth-child(4) {
    border-left-color: @label-default-color !important;
  }

  .btn:nth-child(5) {
    border-left-color: @label-calculate-color !important;
  }

  .btn:nth-child(6) {
    border-left-color: @label-success-color !important;
  }

  .btn:nth-child(1).btn-primary {
    border-left: 1px solid @highlighted-area !important;
  }

  .btn:nth-child(2).btn-primary {
    background-color: @label-danger-color !important;
    color: @white !important;
  }

  .btn:nth-child(3).btn-primary {
    background-color: @label-warning-color !important;
    color: @white !important;
  }

  .btn:nth-child(4).btn-primary {
    background-color: @label-default-color !important;
    color: @white !important;
  }

  .btn:nth-child(5).btn-primary {
    background-color: @label-calculate-color !important;
    color: @white !important;
  }

  .btn:nth-child(6).btn-primary {
    background-color: @label-success-color !important;
    color: @white !important;
  }
}

.status-filter {
  .crm-relations-filter;

  .btn:nth-child(2) {
    border-left-color: @label-default-color !important;
  }

  .btn:nth-child(2).btn-primary {
    background-color: @label-default-color !important;
    color: @white !important;
  }

  .btn:nth-child(3) {
    border-left-color: @label-success-color !important;
  }

  .btn:nth-child(3).btn-primary {
    background-color: @label-success-color !important;
    color: @white !important;
  }

  .btn:nth-child(4) {
    border-left-color: @label-success-color !important;
  }

  .btn:nth-child(4).btn-primary {
    background-color: @label-success-color !important;
    color: @white !important;
  }

  .btn:nth-child(5) {
    border-left-color: @label-warning-color !important;
  }

  .btn:nth-child(5).btn-primary {
    background-color: @label-warning-color !important;
    color: @white !important;
  }

  .btn:nth-child(6) {
    border-left-color: @label-danger-color !important;
  }

  .btn:nth-child(6).btn-primary {
    background-color: @label-danger-color !important;
    color: @white !important;
  }
}

.priority-filter {
  .crm-relations-filter;
}

.type-filter {
  .crm-relations-filter;
}

.clients-table {
  .main-table-template;
  .table-hover;
  width: 100% !important;

  tr td {
    width: auto;
    font-size: calc(@font-size / 1.1) !important;
    white-space: nowrap;
    padding: 2px !important;
    line-height: 1 !important;

    .actions-btn {
      display: inline-flex;
      padding: 1px !important;

      .fa-ellipsis {
        font-size: calc(@font-size * 1.1) !important;
      }
    }

    .label {
      white-space: nowrap;
      width: auto !important;
      font-size: calc(@font-size / 1.1) !important;
      text-align: left;
      padding: 4px !important;
    }

    .label_default.label-opacity {
      background: transparent !important;
    }
  }

  td.highlighted {
    background: @highlighted-area !important;
    border-right: 1px solid darken(@highlighted-area, 4%) !important;
    border-left: 1px solid darken(@highlighted-area, 4%) !important;
    border-bottom: 1px solid darken(@highlighted-area, 4%) !important;
    border-radius: 0 !important;
  }

  td.td-left-align {
    text-align: left !important;
  }

  td.td-right-align {
    text-align: right !important;
  }

  td.td-center-align {
    text-align: center !important;
  }

  td.nowrap {
    white-space: nowrap !important;
  }

  td.update_time {
    white-space: nowrap !important;
    color: @contract-executed-color !important;
    font-family: @font-family-regular !important;
    text-transform: initial;
    min-width: 140px;

    .label {
      margin-left: 0;
      text-align: left !important;
      display: inline !important;
    }
  }

  td.updates {
    min-width: 110px;
  }

  td.company {
    &::first-letter {
      text-transform: uppercase !important;
    }
    vertical-align: middle;
    white-space: nowrap !important;
    text-align: left !important;
    font-family: @font-family-regular !important;

    .btn-link {
      font-family: @font-family-regular !important;
      color: @btn-link-color !important;

      &:hover {
        text-decoration: underline;
      }
    }

    .fa-arrow-right {
      font-size: calc(@font-size / 1.4) !important;
    }

    .label {
      text-align: left;
      margin-left: 0 !important;
    }
  }

  td.small-font-cell {
    font-size: calc(@font-size / 1.3) !important;

    a,
    span,
    .btn-link {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  td.info {
    white-space: nowrap;
  }

  .contacts,
  .deals,
  .dates {
    white-space: nowrap;
    background: @color-black-opacity !important;
  }

  a.modal-btn {
    font-family: @font-family-regular !important;
    color: @btn-link-color !important;
    .no-text-stroke;
  }

  a.modal-btn:hover {
    border-bottom: 1px dashed @contract-executed-color !important;
    transform: scale(1.05);
    transition: transform 0.2s;
  }

  .item-settings-dropdown a:nth-of-type(1) .fa-cog {
    font-size: @font-size !important;
  }
}

tr td:nth-child(1) {
  span.smaller-label {
    font-size: calc(@font-size / 1.3) !important;
    padding: 0;
    margin: 0;
  }
}

.persons-table {
  .clients-table;

  tr td {
    text-align: left;

    h5 {
      padding: 0;
      margin: 0;
      color: @contract-executed-color;
    }
  }

  tr td:first-child {
    span {
      font-family: @font-family-regular !important;
    }

    .btn-link {
      font-family: @font-family-semibold !important;
    }
  }
}

.confirmations-table {
  .table-hover;
  .clients-table;
  margin-left: -2px !important;

  tr th {
    padding: 6px !important;
    padding-right: 2px !important;
    padding-left: 2px !important;
    border-right: 1px solid @borders-color !important;
  }

  tr td {
    border-right: 1px solid @borders-color !important;

    .label {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  tr th:nth-child(3) {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  tr th:nth-child(1),
  tr th:nth-child(2) {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    border: 0 !important;

    span {
      text-transform: uppercase !important;
      font-family: @font-family-regular !important;
    }
  }

  tr td:nth-child(2) {
    span {
      text-transform: uppercase !important;
    }
  }

  tr td:nth-child(1),
  tr td:nth-child(2) {
    .btn-link,
    a {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  tr:nth-last-child(1) {
    td:nth-child(1),
    td:nth-child(2) {
      line-height: 2 !important;
    }
  }
}

.tasks-table,
.persons-table {
  width: 100% !important;

  th {
    text-align: center !important;
    text-transform: uppercase !important;
    cursor: pointer;
    min-width: 20px;
  }

  th:hover {
    background: @color-black-opacity;
  }

  tr td {
    font-size: @font-size;
    text-align: left !important;

    .companies_list {
      display: block;
    }
  }
}

.tasks-extended-table {
  tr td {
    font-size: calc(@font-size / 1.3);
    padding: 2px !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid darken(@borders-color, 5%) !important;

    .label,
    .badge,
    a {
      margin: 0 auto !important;
    }
  }

  .status_new {
    background: @action-addition-alt !important;
    color: @label-success-color-border !important;

    .badge {
      background: @action-addition-alt !important;
    }
  }

  .status_process {
    background: @action-process-alt !important;
    color: @label-warning-color-border !important;

    .badge {
      background: @action-process-alt !important;
    }
  }

  .status_review {
    background: @action-approved !important;
    color: @label-calculate-color-border !important;

    .badge {
      background: @action-approved-alt !important;
    }
  }

  .status_failed {
    background: @action-delete !important;
    color: @white !important;

    .badge {
      background: @action-delete !important;
    }
  }
}

/*clients relations labels*/

.label_hot {
  background-color: @label-danger-color !important;
  color: @white !important;
  .box-shadow-light;
}

.label_warm {
  background-color: @label-warning-color !important;
  color: @white !important;
  .box-shadow-light;
}

.label_neutral {
  background-color: @label-default-color !important;
  color: @white !important;
  .box-shadow-light;
}

.label_cold {
  background-color: @label-calculate-color !important;
  color: @white !important;
  .box-shadow-light;
}

.label_angry {
  background-color: @label-calculate-color !important;
  color: @white !important;
  .box-shadow-light;
}

// calendar
.gt-calendar {
  .fc-state-default {
    color: @contract-executed-color;
  }

  .fc-view-container {
    background: @tables-background;
    .border-radius-4;
    font-family: @font-family-regular !important;
  }

  .calendar {
    .fc-state-active {
      .border-radius-4;
      border: 1px solid @borders-color !important;
    }
  }

  .fc th {
    border: 0;
  }

  .fc-left {
    h2 {
      font-family: @font-family-bold !important;
    }
  }

  .fc-state-default {
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  .fc-unthemed th,
  .fc-unthemed td,
  .fc-unthemed thead,
  .fc-unthemed tbody,
  .fc-unthemed .fc-divider,
  .fc-unthemed .fc-row,
  .fc-unthemed .fc-content,
  .fc-unthemed .fc-popover,
  .fc-unthemed .fc-list-view,
  .fc-unthemed .fc-list-heading td {
    border-left: 0 !important;
  }

  .fc-unthemed td:last-child {
    border-right: 0 !important;
  }

  .fc-unthemed th {
    text-align: right;
  }

  .calendar-btn-group {
    .btn-primary {
      border: 1px solid transparent !important;
    }
  }

  .fc-event-container {
    padding: 2px !important;

    .fc-title {
      font-family: @font-family-bold !important;
      white-space: normal;
    }

    .fc-time {
      color: @white;
      font-family: @font-family-bold !important;
    }

    .fc-day-grid-event {
      padding: 6px;
      box-shadow: 0 0 6px @tables-box-shadow;
    }
  }

  .fc-day-number {
    font-family: @font-family-bold !important;
    padding: 0 !important;
    padding-right: 2px !important;
  }

  .fc-basic-view .fc-body .fc-row {
    height: auto !important;
  }

  .fc-scroller.fc-day-grid-container {
    height: auto !important;
  }
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  overflow: hidden;
  text-align: left;
  &::first-letter {
    text-transform: uppercase !important;
  }
  border-radius: 2px !important;
}

/*datarangepicker*/

#daterange5 {
  width: auto;
}

#reportrange {
  padding: 6px;
  height: auto;
  color: @contract-executed-color;
  outline: 0;
  cursor: pointer;
  border-bottom: 0 !important;
  box-shadow: none !important;
  border-radius: 0px;
  border: 0 !important;
  margin: 0 !important;
  background: @gradient-thin;
  overflow-x: visible;

  &::-webkit-input-placeholder {
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
  }

  &::-moz-placeholder {
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
  }

  &:-moz-placeholder {
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
  }

  &:-ms-input-placeholder {
    color: @placeholder-color;
    font-size: @font-size;
    text-overflow: ellipsis;
  }
}

.daterangepicker.opensleft {
  position: fixed !important;
  margin-top: 0;
  z-index: 9999 !important;
}

.daterangepicker_start_input {
  float: left;
  display: block;
  width: 100%;
}

.daterangepicker_end_input {
  float: left;
  padding-left: 0px !important;
  width: 100%;
}

.daterangepicker .daterangepicker_input i.glyphicon-calendar {
  display: none;
}

.daterangepicker .ranges .input-mini {
  width: 100%;
}

.daterangepicker .btn {
  .border-radius-4;
}

.daterangepicker .calendar-date {
  border: 0 !important;
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background: @highlighted-area;
  border-color: @highlighted-area;
  color: @links-color;
}

.daterangepicker .ranges li {
  font-size: calc(@font-size / 1.3);
  padding: 2px 6px;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  min-width: 18px;
  width: 30px;
  height: 12px;
  line-height: 12px;
  font-size: calc(@font-size / 1.3);
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: calc(@font-size / 1.3);
}

.table-hover {
  .datetimepicker {
    tbody tr td {
      background: @tables-background !important;
      opacity: inherit !important;
    }

    thead tr th:nth-child(1),
    thead tr th:nth-child(2),
    thead tr th:nth-child(3),
    tbody tr td:nth-child(1),
    tbody tr td:nth-child(2),
    tbody tr td:nth-child(3) {
      background: @tables-background !important;
      opacity: inherit !important;
    }

    .table thead tr th:nth-child(6),
    .table thead tr th:nth-child(7),
    .table tbody tr td:nth-child(6),
    .table tbody tr td:nth-child(7) {
      background: darken(@highlighted-area, 9%) !important;
    }

    .table tbody tr td.current {
      background: @label-info-color !important;
      .border-radius-4;
    }

    .table tbody tr td.active {
      background: @label-success-color !important;
      .border-radius-4;
    }
  }
}

.datetimepicker {
  margin-top: 0;
  width: auto;
  padding: 12px;
  background: @tables-background !important;

  .table tbody tr td {
    background: @tables-background !important;
    display: table-cell !important;
    text-align: center !important;
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
    font-size: calc(@font-size / 1.3) !important;
    vertical-align: middle !important;
    color: @contract-executed-color !important;
    font-family: @font-family-semibold !important;
    margin: 0 !important;
    position: relative !important;
    border: 0 !important;
    line-height: 1 !important;
    border-radius: 0 !important;
    white-space: nowrap !important;
  }

  .table thead tr th:nth-child(1),
  .table thead tr th:nth-child(2),
  .table thead tr th:nth-child(3),
  .table tbody tr td:nth-child(1),
  .table tbody tr td:nth-child(2),
  .table tbody tr td:nth-child(3) {
    margin-left: 0 !important;
    margin-top: 0 !important;
    text-align: center !important;
    background: @tables-background !important;
    position: relative !important;
    width: 20px !important;
    min-width: 20px !important;
    display: table-cell !important;
  }

  .hour,
  .minute {
    white-space: nowrap !important;
  }

  .table tbody tr:hover td {
    background: @tables-background !important;
  }

  .table tbody tr:last-child td {
    background: @tables-background !important;
    line-height: 1 !important;
  }

  .table tbody tr:hover td {
    background: inherit !important;
  }

  .table thead tr th:nth-child(6),
  .table thead tr th:nth-child(7),
  .table tbody tr td:nth-child(6),
  .table tbody tr td:nth-child(7) {
    background: darken(@highlighted-area, 9%) !important;
    border: 0 !important;
    font-family: @font-family-regular !important;
    line-height: 1 !important;
    margin-left: 0 !important;
    position: relative !important;
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
  }

  .table tbody tr:nth-last-child(1) td {
    font-family: @font-family-regular !important;
    line-height: 1 !important;
    padding: 2px !important;
  }

  .table thead tr th,
  .table thead tr th.right,
  .table thead tr th.left,
  .table thead tr th.switch {
    border: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    display: table-cell !important;
    color: @main-font-color !important;
    position: relative !important;
    font-size: @font-size !important;
    font-family: @font-family-semibold !important;
  }

  .table thead tr th.right,
  .table thead tr th.left,
  .table thead tr th.switch {
    line-height: 1 !important;
    padding: 2px !important;
  }

  .hour-view,
  .minute-view {
    width: 250px;
  }

  .table tbody tr td .month,
  .table tbody tr td .year,
  .table tbody tr td .hour,
  .table tbody tr td .minute {
    font-family: @font-family-semibold !important;
    padding: 2px;
  }

  .datetimepicker .active:hover,
  .datetimepicker .active:hover:hover,
  .datetimepicker .active.disabled:hover,
  .datetimepicker .active.disabled:hover:hover,
  .datetimepicker .active:active,
  .datetimepicker .active:hover:active,
  .datetimepicker .active.disabled:active,
  .datetimepicker .active.disabled:hover:active,
  .datetimepicker .active.active,
  .datetimepicker .active:hover.active,
  .datetimepicker .active.disabled.active,
  .datetimepicker .active.disabled:hover.active,
  .datetimepicker .active.disabled,
  .datetimepicker .active:hover.disabled,
  .datetimepicker .active.disabled.disabled,
  .datetimepicker .active.disabled:hover.disabled,
  .datetimepicker td.active[disabled],
  .datetimepicker td.active:hover[disabled],
  .datetimepicker td.active.disabled[disabled],
  .datetimepicker td.active.disabled:hover[disabled],
  .datetimepicker span.active[disabled],
  .datetimepicker span.active:hover[disabled],
  .datetimepicker span.active.disabled[disabled],
  .datetimepicker span.active.disabled:hover[disabled] {
    background: @highlighted-area;
    border-color: @highlighted-area;
    color: @links-color;
  }

  .table tbody tr td.current {
    background: @label-info-color !important;
    color: @white !important;
    .border-radius-4;
  }

  .table tbody tr td.active {
    background: @label-success-color !important;
    color: @white !important;
    .border-radius-4;
  }

  .table tbody tr td.future {
    opacity: 0.2 !important;
  }

  .table tbody tr td.past {
    opacity: 0.2 !important;
  }
  .table tbody tr td.disabled {
    opacity: 0.2 !important;
  }

  .table tbody tr td.active,
  .table tbody tr td.active:hover {
    background-color: #32ae84 !important;
    border-color: #32ae84 !important;
  }

  span {
    height: auto !important;
  }

  .table tbody tr td[colspan] {
    width: 100% !important;
  }

  .table thead tr th {
    white-space: nowrap !important;
  }
}

.label-opacity.tooltip-modal::after {
  border-radius: 8px 8px 8px 0px;
  box-shadow: none;
  color: #fff;
  content: '';
  background: transparent;
  padding: 2px 7px;
  position: absolute;
  visibility: hidden;
  white-space: normal;
  word-wrap: break-word;
  opacity: 1 !important;
  .transition;
  font-size: calc(@font-size / 1.3) !important;
  font-family: @font-family-bold !important;
  text-transform: inherit !important;
  line-height: 1.4;
  max-width: 300px;
}

.label-opacity.tooltip-modal:hover::after {
  background: rgba(136, 139, 148, 1);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  content: attr(data-tip);
  margin-top: -24px;
  opacity: 1;
  visibility: visible;
  .transition;
}

.overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999 !important;
  background: rgba(0, 0, 0, 0.2);

  .fa {
    top: 50%;
    left: 50%;
    font-size: 30px;
    position: fixed;
    color: @overlay-color;
    z-index: 9999 !important;
  }
}

.opacity {
  opacity: 0.5;
}

.permanent-hover {
  div div {
    .gt-table__hovering;
  }
}

.table-with-addon {
  outline: none !important;

  tbody:nth-of-type(1) tr:first-child td:nth-child(1) {
    border-left: 5px solid @navbar-background !important;
  }

  tr:first-child,
  td.edit {
    td {
      border-bottom: 0 !important;

      .filter-template {
        background: @contract-errors-background !important;
        border-right: 0 !important;

        .btn {
          border-right: 0 !important;
        }

        .btn-primary {
          background: @btn-success-color !important;
          color: @tables-background !important;
        }
      }

      .ui-select-multiple.ui-select-bootstrap {
        padding: 0 !important;
        padding-left: 2px !important;
      }

      .form-control {
        border: 0 !important;
        background: @contract-errors-background;
        border-radius: 4px !important;
      }

      .input-group-addon {
        background: @contract-errors-background;
        color: @tables-background !important;
      }

      select[multiple],
      select[size] {
        width: 100%;
        border: 0 !important;
        background: @contract-errors-background !important;
      }
    }
  }
}

.table-with-addon-container {
  table {
    .table-with-addon;
  }

  outline: none !important;
}

.persons-table tr:first-child td .form-control {
  height: auto !important;
}

.no-outline {
  outline: none !important;
}

.hidden-element {
  height: 1px;
}

.visible-element {
  height: auto;
}

.gt-date-select {
  .input-group {
    text-align: left !important;
  }

  input.form-control {
    float: left !important;
  }

  .clear-btn {
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
  }
}

.details-wrapper {
  .process-container-well {
    background: @very-dark-blue !important;
    box-shadow: none !important;
    margin-bottom: 15px !important;
    padding: 40px !important;

    .start-page-header {
      color: @tables-background;
    }

    .item-process-container {
      border-left: 1px solid @borders-color;

      .process-item {
        .box-shadow-light;
        background: @tables-background;
        padding: 20px;
        border-radius: 8px;
        margin-left: 10px;
        overflow-x: auto;
        .col-xs-5,
        .btn-add-dark {
          overflow: hidden;
        }
        .alert {
          overflow-x: auto;
        }
      }

      .label {
        margin-right: 5px;
      }

      h5 {
        background: @very-dark-blue;
        font-family: @font-family-regular;
        color: @tables-background;
        margin-left: -18px;
        border-radius: 6px;
        padding-left: 10px;
        display: flex;

        .smaller-label {
          vertical-align: bottom;
        }

        .label_default {
          background: darken(@borders-color, 23%) !important;
        }

        .label {
          display: inline-block;
        }

        &:hover,
        &:focus {
          transform: scale(1.05);
          transition: transform 0.2s;
          cursor: pointer;
        }
      }

      h5.positive-number {
        color: @btn-success-color !important;
        .fa {
          color: @btn-success-color !important;
        }
      }
    }
  }
}

.updates-process-container {
  .process-container-well {
    .item-process-container {
      border-left: 0;
    }
    .item-process-container h5 {
      background: @tables-background;
      width: 100% !important;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      margin-top: 5px;
      .label {
        font-size: @font-size !important;
      }
      .col-xs-3,
      .col-xs-2,
      .col-xs-6,
      .col-xs-4 {
        padding-left: 2px !important;
        padding-right: 2px !important;
        text-align: left;
      }
    }
    .item-process-container .process-item {
      padding: 10px;
      border-left: 1px solid darken(@borders-color, 9%);
      margin-left: 90px;
      border-radius: 0;
    }
    .item-process-container:last-child .process-item {
      border-left: 0;
    }
  }
}

.simplified-updates {
  .item-process-container {
    .fa-ellipsis {
      display: none;
    }
    .label_info.smaller-label {
      display: none !important;
    }
    .btn-link {
      font-size: calc(@font-size / 1.4) !important;
    }
  }
  .item-process-container .process-item {
    display: none;
  }
}

.padding-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.padding-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.modal-success-alert {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  border: 2px dashed @color-black-opacity-very-dark !important;
}

table.contract-pnl-detalization-table {
  thead > tr > th {
    border-right: 1px solid darken(@borders-color, 17%) !important;
    border-bottom: 1px solid darken(@borders-color, 17%) !important;
  }

  tbody:first-child {
    border-bottom: 3px solid darken(@borders-color, 17%) !important;
  }

  tr th:last-child {
    border-right: 0 !important;
  }

  tr td {
    text-align: right;

    .smaller-label {
      font-size: calc(@font-size / 1.3) !important;
    }
  }

  tr td:first-child {
    text-align: left;
  }
}

.no-data-img {
  background: url(img/no-data-img.jpeg);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  float: none;
  display: block;
  margin-top: 10px;
  background-position: center;
  background-size: cover;
}

.no-data-img-small {
  width: 50px;
  height: 50px;

  .no-data-img {
    width: 50px;
    height: 50px;
  }
}

.read-only-resource {
  .ui-select-match > .btn {
    padding: 0;
    min-height: 0 !important;
  }
}

.user-dropdown-btn,
.table-nav-tabs li {
  .fa.show-on-hover,
  .fa.bounce-on-hover,
  .fa.spin-on-hover {
    display: none;
  }

  &:hover {
    .fa.hide-on-hover,
    .fa.hide-on-bounce,
    .fa.hide-on-spin {
      display: none;
    }

    .fa.show-on-hover,
    .fa.bounce-on-hover,
    .fa.spin-on-hover {
      display: inline-block;
    }
  }
}

.quick-modal {
  .form-group-container {
    display: block;
    width: 100% !important;
  }

  .quick-modal-hide {
    display: none;
  }
}

.invoice-inside-table {
  table-layout: fixed;
  margin-bottom: 0 !important;
  tr td {
    border: 0 !important;
    font-size: calc(@font-size / 1.3);
    border-bottom: 1px dashed @borders-color !important;
    white-space: normal;
    text-align: left;
  }
  tr:last-child td {
    border-bottom: 0 !important;
  }
}

.approvals-by-config-container {
  .col-xs-12 {
    .btn {
      margin: 5px;
    }
  }
}

.passport-block-mode {
  background: @color-black-opacity-very-dark;
  border-radius: 8px;
  padding-left: 15px !important;
  padding-top: 15px !important;
  .alert-danger-dashed {
    .rounded-with-background {
      background: @color-black-opacity-dark;
      border-radius: 100%;
      padding: 10px;
      width: 100px;
      margin: 0 auto;
      .fa-ship {
        color: darken(@borders-color, 41%);
      }
    }
    .rounded-with-dark-background {
      .rounded-with-background;
      background: @color-black-opacity-very-dark;
      .fa-ship {
        color: darken(@borders-color, 9%);
      }
    }

    .item-settings-dropdown:hover .dropdown-menu {
      left: -260px !important;
    }
    padding: 30px !important;
    overflow: hidden !important;
    line-height: 1.5 !important;
    height: 380px;
    margin-bottom: 10px !important;
    border-bottom: 2px solid darken(@borders-color, 9%) !important;
    overflow: visible;
    h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: center;
      white-space: normal;
    }
  }
  .invoice-inside-table tr td {
    padding: 2px !important;
    border: 1px dashed @borders-color !important;
    text-align: center !important;
    vertical-align: middle !important;
  }
  .invoice-inside-table-no-border {
    tr td {
      border: 0 !important;
    }
  }
}
.contracts-wrapper-light-mode.passport-block-mode .request-list,
.contracts-wrapper-light-mode .contract-details-container-cut {
  margin-bottom: 15px !important;
  display: inline-block;
}

.request-table,
.table-template {
  .item-settings-dropdown:hover .dropdown-menu {
    top: -8px;
    left: 15px;
  }
}
.table-fix-head {
  overflow-y: auto;
  height: 500px;
  thead th {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
}

.bigger-invoice-list {
  .alert-danger-dashed {
    h4 {
      font-size: calc(@font-size * 1.2) !important;
    }
    h6 {
      font-size: @font-size !important;
      font-family: @font-family-regular !important;
    }
    .invoice-inside-table tr td {
      font-size: @font-size !important;
      .smaller-label {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
  }
}

.ui-select-no-choice:empty {
  padding: 0 !important;
}

.space-between {
  display: flex;
  justify-content: space-between;
  .actions-btn,
  .indent-left {
    margin-left: 10px !important;
  }

  .item-settings-dropdown {
    .dropdown-menu {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -40px;
        height: 32px;
        width: 40px;
        cursor: pointer;
        pointer-events: none;
      }
    }
    .open {
      .dropdown-menu {
        top: -8px;
        left: 15px !important;

        &:after {
          pointer-events: initial;
        }
      }
    }
    .fa {
      min-width: 12px;
    }
  }
  .label {
    align-self: flex-start;
  }
}
.fa-file-text {
  min-width: 8px;
}
.fa-sort-desc {
  min-width: 12px;
}
.passport-recap-table {
  tr > td {
    text-align: left !important;
    white-space: normal !important;
    word-wrap: break-word;
  }
}

.table-highlighted {
  tr td {
    background: @highlighted-area !important;
    border-right: 1px solid darken(@hover-color, 9%) !important;
    border-left: 1px solid darken(@hover-color, 9%) !important;
    border-bottom: 1px solid darken(@hover-color, 9%) !important;
    border-radius: 0 !important;
    font-family: @font-family-semibold !important;
  }
}

.help-btn-group {
  .item-settings-dropdown .dropdown-menu {
    left: calc(100% - 20px) !important;
  }
}

.new-cashflow-table {
  tr td {
    font-size: calc(@font-size / 1.2) !important;
    white-space: nowrap;
    .fa {
      margin-left: 5px;
      font-size: calc(@font-size / 1.5);
    }
    .negative-number {
      .fa {
        color: @label-danger-color !important;
      }
    }
    .label {
      display: inline-block !important;
      font-size: calc(@font-size / 1.2) !important;
    }
    .detalization-unit {
      padding: 4px;
      margin-bottom: 5px;
      border-radius: 6px;
      a {
        font-family: @font-family-regular !important;
      }
    }
    input[type='checkbox'],
    input[type='radio'] {
      width: 15px;
      height: 15px;
      margin-right: 0;
      float: none;
    }
  }
}

.simplified-view {
  background: @tables-background !important;
  .page-header-main {
    background: @modal-header-background !important;
    margin-bottom: 0;
    .main-nav-tabs {
      li.active > a {
        background: @tables-background !important;
      }
      li > a {
        font-size: calc(@font-size * 1.1) !important;
      }
    }
  }

  .nav-tabs-with-hidden-naming,
  .main-nav-tabs {
    li {
      .to-hide {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
  }
  .help-btn-group a {
    color: @navbar-background !important;
  }

  .main-nav-tabs li.active > a .help-btn-group a {
    span {
      display: none;
    }
  }
  .help-btn-group a {
    span {
      display: none;
    }
  }
  .help-btn-group a.gt-head-filter {
    span {
      display: inline-block;
    }
  }

  .container-btn-group,
  .small-screen-hide,
  .pagination-select {
    display: none;
  }

  .create-contract-page {
    .page-header-main {
      h1 {
        display: block;
      }
    }
  }

  .object-details-container .form-group-container .form-group.col-xs-12 {
    border-bottom: 1px solid transparent !important;
  }
  .page-info-container {
    display: none;
  }
  .control-label {
    .tooltip-modal {
      display: none;
    }
  }
  .help-inline-hint {
    display: none;
  }
  .details-wrapper .well {
    margin-bottom: 0;
  }
  .page-header-main h1 .label_start {
    display: none;
  }
  .page-header-main .main-nav-tabs .label_start {
    display: none;
  }
  .page-header-main h1 .user-dropdown-btn {
    font-size: calc(@font-size * 1.1) !important;
  }
  .table-action-buttons .item-settings-dropdown {
    display: none;
  }

  .filters-well,
  .analytics-container.filters-well {
    margin-top: 10px;
  }
}

.accordion {
  .card {
    .transition;
    padding: 0;
    border-bottom: 1px solid darken(@borders-color, 1%) !important;
    background: @tables-background;
    .border-radius-6;

    .well,
    .ul-inline-row,
    .table-container-wrapper,
    .analytics-container {
      padding: 20px !important;
    }

    .request-list-container .ul-inline-row,
    .invoice-container .ul-inline-row,
    .invoice-container .ul-inline-row .table-container-wrapper,
    .finance-modal-container .ul-inline-row .table-container-wrapper {
      padding: 0 !important;
    }

    .invoice-container .ul-inline-row .table-action-buttons,
    .finance-modal-container .ul-inline-row .start-page-header,
    .table-action-buttons thead {
      display: none;
    }

    intermodal-transports-list-container .table-action-buttons thead {
      display: table-header-group;
    }
  }

  .card:last-child {
    border-bottom: 0 !important;
  }

  .card-expanded {
    padding: 20px;
    margin-bottom: 25px;
    border-bottom: 0 !important;

    &:hover,
    &:focus {
      transform: none;
      cursor: pointer;
    }
  }

  .card-header {
    .start-page-header {
      margin-bottom: 0 !important;
    }

    .transition;
    .btn-add-dark {
      border-width: 0;
    }

    .btn-primary {
      background: @navbar-background !important;
      color: @tables-background !important;
      border: 1px solid @navbar-background !important;
    }

    &:hover,
    &:focus {
      transform: scale(1.01);
      cursor: pointer;
      .btn-add-dark {
        background: @navbar-background !important;
        color: @tables-background !important;
      }
    }
  }

  .collapse.in {
    margin-top: 0;
    .favorite-filter-container {
      display: none;
    }
  }

  .collapse {
    .well,
    .analytics-container,
    .ul-inline-row,
    .table-container-wrapper {
      .start-page-header {
        font-size: @font-size !important;
        color: @smoke;
      }
    }
  }
}

.accordion .card .analytics-container .table-container-wrapper.gt-table__hovering {
  padding-bottom: 100px !important;
}

.accordion .card documents-container .table-container-wrapper.gt-table__hovering {
  padding-bottom: 350px !important;
}

.modal-content {
  .accordion {
    .card {
      background: transparent;
    }
  }
}

.additional-agreements-modal {
  .copy-clipboard-btn {
    display: none;
  }
}

.divflex {
  display: inline-flex !important;
}

.item-settings-dropdown.dropdown-left {
  .dropdown-menu {
    left: calc(100% - 300px) !important;
    top: calc(100% - 20px) !important;
  }
}

.overflow-y-small,
.alert,
.well {
  .contracts-wrapper-light-mode,
  .clients-wrapper-light-mode,
  .persons-wrapper-light-mode {
    padding: 5px;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

.table-quality {
  tr td {
    white-space: wrap !important;
    word-wrap: break-word !important;
  }
}

.details-wrapper .passport-details-well.well {
  padding: 0 !important;
  .item-settings-dropdown.actions-btn {
    display: none;
  }
  .passports-wrapper-light-mode {
    padding: 0 !important;
    margin-top: 15px !important;
  }
  .bank-accounts-container .request-list {
    padding: 0 !important;
  }
  .create-contract-page .gt-main-container .contract-details-container-cut {
    margin-top: 0 !important;
  }
  .table-inside-table {
    .main-table;
    background: transparent !important;
    tr td,
    tr th {
      font-size: calc(@font-size / 1.3) !important;
      padding: 2px !important;
      word-break: normal;
    }
  }
}

.config-presets-name-input {
  margin-top: 8px !important;
}

.disabled-button {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
  .btn {
    opacity: 0.5;
    cursor: not-allowed !important;
    pointer-events: none;
  }
}

ul.form-stepper {
  counter-reset: section;
  margin-bottom: 3rem;
}

ul.form-stepper .form-stepper-circle {
  position: relative;
}

ul.form-stepper .form-stepper-circle span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.form-stepper-horizontal {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

ul.form-stepper > li:not(:last-of-type) {
  margin-bottom: 0.625rem;
  -webkit-transition: margin-bottom 0.4s;
  -o-transition: margin-bottom 0.4s;
  transition: margin-bottom 0.4s;
}

.form-stepper-horizontal > li:not(:last-of-type) {
  margin-bottom: 0 !important;
}

.form-stepper-horizontal li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.form-stepper-horizontal li:not(:last-child):after {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 1px;
  content: '';
  top: 32%;
}

.form-stepper-horizontal li:after {
  background-color: @tables-background;
}

.form-stepper-horizontal li.form-stepper-completed:after {
  background-color: @label-warning-color-border;
}

.form-stepper-horizontal li:last-child {
  flex: unset;
}

ul.form-stepper li a .form-stepper-circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 0;
  line-height: 1.7rem;
  text-align: center;
  background: @color-black-opacity;
  border-radius: 50%;
}

.form-stepper .form-stepper-active .form-stepper-circle {
  background-color: @very-dark-blue !important;
  color: @tables-background;
}

.form-stepper .form-stepper-active .stepper-label {
  color: @tables-background !important;
}

.form-stepper .form-stepper-active .form-stepper-circle:hover {
  background-color: @very-dark-blue !important;
  color: @tables-background !important;
}

.form-stepper .form-stepper-unfinished .form-stepper-circle {
  background-color: @tables-background;
}

.form-stepper .form-stepper-completed .form-stepper-circle {
  background-color: @very-dark-blue !important;
  color: @tables-background;
}

.form-stepper .form-stepper-completed .stepper-label {
  color: @very-dark-blue !important;
}

.form-stepper .form-stepper-completed .form-stepper-circle:hover {
  background-color: @very-dark-blue !important;
  color: @tables-background !important;
}

.form-stepper .form-stepper-active span.text-muted {
  color: @tables-background !important;
}

.form-stepper .form-stepper-completed span.text-muted {
  color: @tables-background !important;
}

.form-stepper .stepper-label {
  font-size: 1rem;
  margin-top: 0.5rem;
}
.form-stepper a {
  cursor: default;
}
.currency-symbol {
  text-transform: uppercase !important;
}
.btn-payment-plan-reset {
  margin-right: 4px !important;
}

.search-inside-ui-select {
  position: absolute;
  left: 10px;
  font-size: calc(@font-size / 1.3);
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
  z-index: 999;
  position: sticky;
  opacity: 1;
  background: @dark-grey !important;
  font-size: @font-size;
  line-height: 1 !important;
  .negative-number;
  .fa-times {
    font-size: @font-size !important;
  }
}

.analytical-plan-fact-pnl-report-table {
  tr td {
    .fa {
      margin-left: 5px;
      color: @smoke !important;
      font-size: calc(@font-size / 1.5);
    }
    .negative-number {
      .fa {
        color: @label-danger-color !important;
      }
    }
  }
}

.payment-plan-warning-icon-wrapper {
  position: absolute;
  left: -25px;
  top: calc(50% - 10px);
}

.payment-plan-warning-icon {
  color: orange;
  margin-right: 4px;

  &:before {
    font-size: 20px;
  }
}

.quality-rules-table {
  tbody .highlighted-tr td {
    border-top: 2px solid @borders-color / 1.1 !important;
    border-radius: 0 !important;
    vertical-align: middle !important;
  }
  tbody .highlighted-tr td:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  tbody .highlighted-tr td:last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.update-required-field .help-inline {
  text-align: center;
  border-radius: 0 0 4px 4px !important;
}

.analytics-container .update-required-field textarea {
  &.ng-invalid.ng-touched,
  &.ng-pristine {
    background: @status-danger-background !important;
    border: 1px solid @label-danger-color !important;
    margin-bottom: 0 !important;
    border-radius: 4px 4px 0 0 !important;
    border-bottom: 0;

    + .help-inline {
      display: block;
    }
  }

  + .help-inline {
    display: none;
  }
}

.td-width-payment-cell,
.td-width-vehicle-number-cell {
  min-width: 170px;
}

.td-width-buyer-cell,
.td-width-purchase-price-cell,
.td-width-sale-price-cell,
.td-width-import-contract-cell,
.td-width-volume-recived-cell,
.td-width-volume-departed-real-cell,
.td-width-buyer-total-invoiced-plan-cell,
.td-width-buyer-total-invoiced-cell,
.td-width-volume-departed-cell {
  min-width: 115px;
}

.td-width-sale-date-cell,
.td-width-supplier-invoiced-balance-cell,
.td-width-supplaier-paid-balance-cell {
  min-width: 75px;
}

.td-width-export-contract-cell,
.td-width-volume-borded-cell,
.td-width-supplier-to-be-invoiced-balance-cell,
.td-width-supplier-paid-cell,
.td-width-supplier-total-paid-cell,
.td-width-byuyer-to-be-invoiced-balance-cell,
.td-width-buyer-invoiced-balance-cell,
.td-width-buyer-paid-cell,
.td-width-buyer-paid-balance-cell,
.td-width-buyer-total-paid-cell {
  min-width: 90px;
}

.td-width-volume-lost-cell,
.td-width-volume-departed-cell,
.td-width-buyer-to-be-invoiced-cell {
  min-width: 145px;
}

.td-width-supplier-to-be-invoiced-cell,
.td-width-supplier-total-invoiced-plan-cell {
  min-width: 170px;
}

.td-width-supplier-invoiced-cell,
.td-width-buyer-invoiced-cell {
  min-width: 140px;
}

.td-width-supplier-total-invoiced-cell {
  min-width: 150px;
}

.td-width-penalties-cell {
  min-width: 100px;
}

.td-width-approval-status-cell {
  min-width: 130px;
}

.td-width-status-cell {
  min-width: 120px;
}

gt-resource-select .ui-select-choices.submenu {
  max-height: 120px;
  top: calc(20px - 100%) !important;
}

contract-list-table-view .gt-table__hovering {
  padding-bottom: 380px !important;
}

a.disabled-link {
  pointer-events: none;
  cursor: default;
}

prices-position-inline-create .form-control {
  height: auto;
  min-height: 34px;
}

.table-form {
  margin-bottom: 15px;

  .table-form-field {
    .form-group.col-xs-12 {
      .row {
        display: flex;
        flex-direction: column;

        .control-label-wrapper {
          display: block;
          width: 100%;
          min-height: 15px;
        }
        .control-label-wrapper .position-row-detail-container {
          width: 40%;
          display: table-cell;
        }

        .input-wrapper {
          width: 100%;
          margin-bottom: 5px;
        }
        .input-wrapper .position-row-detail-container {
          width: 60%;
        }
      }
    }
  }
}

.tooltip-left {
  &.tooltip:hover::before {
    top: 0px;
    left: 0px;
  }
}
.custom-values-container {
  .table-nav-tabs {
    display: none;
  }
}
.custom-values-table {
  thead {
    display: none;
  }
  tr td {
    white-space: normal;
  }
  tr td:first-child {
    border-top: 0;
    text-align: left !important;
    text-overflow: ellipsis;
    color: @smoke;
    width: 40%;
  }
}
.object-details-container {
  .custom-values-table {
    tr td {
      border: 0 !important;
      border-bottom: 1px dashed @borders-color !important;
      .edit-button {
        display: none !important;
      }
    }
    tr td:first-child {
      text-align: right !important;
      width: 40%;
    }
    .not-empty {
      display: none;
    }
  }
  .no-data-img,
  .no-data-row {
    display: none;
  }
}
.multiposition-form {
  .help-inline-hint {
    width: 100%;
  }
}
.text-highlighted {
  background-color: #f3ffa2 !important;
}
table.inline-edit-table {
  padding-bottom: 300px;
  tr td {
    padding: 0 !important;
    .form-group {
      padding: 0 !important;
    }
    .help-inline-hint,
    .input-group-addon-search,
    .control-label {
      display: none !important;
    }
    .input-wrapper {
      width: 100% !important;
      .copy-clipboard-btn {
        visibility: hidden;
      }
      &:hover {
        .copy-clipboard-btn {
          visibility: visible;
        }
      }
    }
    .form-control,
    textarea,
    .ui-select-bootstrap > .ui-select-match > .btn {
      margin: 0 !important;
      padding: 4px !important;
      width: 100% !important;
      line-height: 1.2 !important;
      min-height: 20px !important;
      max-height: 20px !important;
      min-width: 80px !important;
      white-space: nowrap;
      font-size: calc(@font-size / 1.3) !important;
      border: 1px solid @borders-color !important;

      &::-webkit-input-placeholder {
        font-family: @font-family-regular;
        color: @placeholder-color;
        font-size: calc(@font-size / 1.3) !important;
        text-overflow: ellipsis;
      }

      &::-moz-placeholder {
        font-family: @font-family-regular;
        color: @placeholder-color;
        font-size: calc(@font-size / 1.3) !important;
        text-overflow: ellipsis;
      }

      &:-moz-placeholder {
        font-family: @font-family-regular;
        color: @placeholder-color;
        font-size: calc(@font-size / 1.3) !important;
        text-overflow: ellipsis;
      }

      &:-ms-input-placeholder {
        font-family: @font-family-regular;
        color: @placeholder-color;
        font-size: calc(@font-size / 1.3) !important;
        text-overflow: ellipsis;
      }
    }
    .form-control .ui-select-match-text {
      margin: 0 !important;
      min-height: 20px !important;
      max-height: 20px !important;
      min-width: 80px !important;
      padding: 4px !important;
      white-space: nowrap;
    }
    textarea.form-control {
      min-height: 20px !important;
      max-height: 20px !important;
      min-width: 120px !important;
      width: 120px !important;
    }
    select.form-control {
      padding: 0 !important;
    }
    .ui-select-bootstrap .ui-select-choices-row > span {
      padding: 2px 4px !important;
      font-size: calc(@font-size / 1.3) !important;
    }
    .text-muted {
      color: @placeholder-color;
      display: block;
      padding: 4px !important;
      line-height: 1.2 !important;
      font-size: calc(@font-size / 1.3) !important;
    }
    .ui-select-bootstrap > .ui-select-match > .btn {
      padding: 0 !important;
    }
    .ui-select-multiple.ui-select-bootstrap {
      margin: 0 !important;
    }
    .copy-clipboard-btn {
      position: absolute;
      top: 0;
      right: 5px;
    }
    .clear-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
    .add-btn {
      padding: 0 2px !important;
      position: absolute;
      top: 0;
      right: 0;
      width: auto !important;
      background: @color-black-opacity-dark !important;
      border: 0 !important;
      color: @contract-executed-color !important;
      .fa {
        display: none;
      }
    }
    .select-with-btn {
      float: none;
      width: 100%;
    }
  }
}

table.inline-edit-table tr:first-child {
  background-color: @status-success-background;
}

.embedded-superset iframe {
  width: 100%;
  height: 1750px;
}

.passports-table {
  .progress {
    .negative-number {
      color: #b94a48 !important;
      background-color: #f2dede !important;
    }
  }
}

.multicontract-fixed-cols-table {
  tfoot > tr > td:nth-child(1),
  tbody > tr > td:nth-child(1),
  thead > tr > th:nth-child(1) {
    position: sticky;
    left: 0 !important;
    width: 290px !important;
    min-width: 290px !important;
    z-index: 30 !important;
    height: 30px;
    min-height: 30px;
    vertical-align: middle !important;
    background: @tables-background;
    .item-settings-dropdown .dropdown-menu {
      left: calc(100% + 7px) !important;
    }
  }

  tfoot > tr > td:nth-child(2),
  tbody > tr > td:nth-child(2),
  thead > tr > th:nth-child(2) {
    position: sticky;
    left: 290px;
    width: 260px !important;
    vertical-align: middle !important;
    background: @tables-background;
    z-index: 12;
    .item-settings-dropdown .dropdown-menu {
      left: calc(100% + 1px) !important;
    }
  }
}
.basis-doc-table {
  tr td .ui-select-bootstrap > .ui-select-match > .btn,
  tr td .form-control .ui-select-match-text,
  tr td .form-control,
  tr th .ui-select-bootstrap > .ui-select-match > .btn {
    min-height: 20px;
    max-height: 20px;
    white-space: nowrap;
    margin: 0 !important;
  }
}
.payment-overdue-alert {
  position: fixed;
  top: 0;
  width: 100%;
  height: 20px;
  padding: 2px;
  text-align: center;
  margin: 0;
  z-index: 9999;
}
.payment-overdue-padding {
  padding-top: 20px !important;
}
.payment-overdue-top {
  top: 20px !important;
}
.fixed-date-select input {
  height: 20px;
}
.green-text {
  color: #1bcc1b;
}
.all-info-container {
  h2 {
    font-size: 18px !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-bottom: 5px !important;
    letter-spacing: @letter-spacing-normal;
  }
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search[disabled] {
  cursor: not-allowed !important;
}
