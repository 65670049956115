/*--- MEDIA QUERIES --*/
/* min3200 */
// @media (min-width: 3200px) {

//   .analytics-container .start-page-header {
//     font-size: @font-size * 2.5;
//   }
//   .table-container-wrapper .start-page-header, .ul-inline-row .start-page-header, .analytics-container .start-page-header, .well .start-page-header {
//     font-size: @font-size * 2.5;
//   }
//   .start-page-header {
//     .btn-add-dark {
//       font-size: @font-size * 2;
//     }
//   }

//   .page-header-main h1 .user-dropdown-btn {
//     font-size: @font-size * 2.5 !important;
//   }

//   .page-header-main h1 .label_start {
//     font-size: @font-size * 1.5 !important;
//   }

//   .start-title {
//     font-size: @font-size * 1.5;
//   }
//   .dashboard-object-container .dashboard-value {
//     h3 {
//       font-size: @font-size * 2.5;
//     }
//   }
//   .dashboard-object-container .dashboard-label {
//     font-size: @font-size * 2;
//   }
//   .start .start-block-row h5.start-page-header {
//     font-size: @font-size * 2 !important;
//   }
//   .dashboard-start-container .ui-grid-cell-contents, .dashboard-start-container .ui-grid-cell .tooltip, .dashboard-start-container .ui-grid-cell, .dashboard-start-container .ui-grid-cell .label {
//     font-size: @font-size * 1.5 !important;
//   }
//   .dashboard-start-container .ui-grid-cell, .dashboard-start-container .ui-grid-row {
//     height: 30px !important;
//   }
//   .navbar-user li > a {
//     height: 80px !important;
//     font-size: @font-size * 1.5 !important;
//   }
//   .navbar-user {
//     height: 80px !important;
//   }
//   .navbar-user li {
//     height: 80px !important;
//     min-width:  80px !important;
//     font-size: @font-size * 1.5 !important;
//   }
//   .navbar-user li > a .fa {
//     font-size: @font-size * 1.5 !important;
//   }
//   .navbar-user .user-dropdown-li .fa {
//     font-size: @font-size * 1.5 !important;
//   }
//   .navbar-user .navbar-top-grouped li > a span {
//     font-size: @font-size * 1.5 !important;
//   }
//   .navbar-user .dropdown-menu li > a, .navbar-user .dropdown-menu li {
//     height: 50px !important;
//   }
//   .if-navbar-top .central-container {
//     padding-top: 75px !important;
//   }
//   .help-btn-group {
//     top: 100px !important;
//   }
//   .navbar-user .logo {
//     height: 70px;
//     width: 120px !important;
//   }

//   .client-name-header {
//     margin-top: 10px;
//     margin-left: 135px;
//     font-size: @font-size * 1.5 !important;
//   }
//   .client-name-header-dark .label {
//     font-size: @font-size * 1.5 !important;
//   }
//   .clients-table tr td, .clients-table tr th,
//   .table-template tr td, .table-template tr th,
//   .request-table tr td, .request-table tr th, .btn-link,
//    .request-table td.small-font-cell a,
//    .request-table td.small-font-cell span,
//    .analytics-container table tr th,
//    .analytics-container table tr td,
//    .request-table td.small-font-cell .btn-link {
//     font-size: @font-size * 1.5 !important;
//     padding:10px !important;
//     .label {
//       font-size: @font-size * 1.5 !important;
//     }
//   }
//   .sticky-table-first-td thead > tr > th:first-child, table.sticky-table-first-td thead > tr > th:first-child, .sticky-table-first-td-ultra-condensed thead > tr > th:first-child {
//     min-height: 120px !important;
//   }
//   .request-table .smaller-label, .request-table .smaller-label.label, .smaller-label.label, .smaller-label {
//     font-size: @font-size * 1.5 !important;
//   }
//   .request-table td.small-font-cell a, .request-table td.small-font-cell span, .request-table td.small-font-cell .btn-link {
//     font-size: @font-size * 1.5 !important;
//   }
//   .request-table tr td .fa {
//     font-size: @font-size * 1.5 !important;
//   }
//   .page-header-main h1 {
//     font-size: @font-size * 2.5;
//   }
//   .page-header-main .main-nav-tabs li a {
//     font-size: @font-size * 1.5 !important;
//   }
//   .container-btn-group .btn {
//     font-size: @font-size * 1.5 !important;
//   }
//   .btn-fixed {
//     height: 120px;
//     width: 120px;
//     font-size: @font-size * 3 !important;
//   }
//   .table-nav-tabs a, .table-nav-tabs li.active a {
//     font-size: @font-size * 1.5 !important;
//   }
//   .item-settings-dropdown .dropdown-menu .btn, .item-settings-dropdown .dropdown-menu a {
//     font-size: @font-size * 1.5 !important;
//     padding: 8px !important;
//   }
//   .dashboard-object-container .dashboard-label span {
//     font-size: @font-size * 1.5;
//   }
//   .help-btn-group a {
//     font-size: @font-size * 2;
//   }
//   .form-group-container .form-group .control-label {
//     font-size: @font-size * 2 !important;
//   }
//   .tooltip-modal .fa-question-circle {
//     font-size: @font-size !important;
//   }
//   .form-group-container .form-group .form-control, .form-group-container .form-group .form-control .ui-select-match-text {
//     font-size: @font-size * 2 !important;
//   }
//   .modal-form-container, .client-modal-form-container, .contract-modal-form-container  {
//     .contract-description-label {
//       font-size: @font-size * 1.5 !important;
//     }
//     font-size: @font-size * 1.5 !important;
//      .form-add-btn a {
//       font-size: @font-size * 1.5 !important;
//      }
//      input[type='checkbox'], input[type='radio'] {
//       width: 55px;
//       height: 55px;
//      }
//   }

//   .object-details-container .form-group-container .form-group .control-label,
//   .object-details-container .form-group-container .form-group.not-empty .input-wrapper, .object-details-container .form-group-container .form-group .input-wrapper {
//     font-size: @font-size * 1.5 !important;
//   }
//   .object-details-container .form-group-container .form-group .input-wrapper .object-title, .object-details-container .form-group-container .form-group .input-wrapper textarea.form-control, .object-details-container .form-group-container .form-group .input-wrapper select, .object-details-container .form-group-container .form-group .input-wrapper input, .object-details-container .form-group-container .form-group .input-wrapper input[type='checkbox'], .object-details-container .form-group-container .form-group .input-wrapper .ui-select-multiple .ui-select-match-item, .object-details-container .form-group-container .form-group .input-wrapper .form-control {
//     font-size: @font-size * 1.5 !important;
//   }

//   .modal-template .modal-content .alert-info {
//     h1,h2,h3,h4,h5,h6 {
//       font-size: @font-size * 2 !important;
//     }
//     font-size: @font-size * 1.5 !important;
//     .label {
//       font-size: @font-size * 1.5 !important;
//     }
//     .btn {
//       font-size: @font-size * 2 !important;
//     }
//   }
//   .input-group-addon {
//     font-size: @font-size * 1.5;
//   }
//   .help-inline {
//     font-size: @font-size * 1.5;
//   }

//   .label-opacity, .clients-table .label-opacity {
//     font-size: @font-size * 1.5 !important;
//   }
//   .page-total-analytics-container .item-details-block-transparent .alert .label, .page-total-analytics-container .item-details-block-transparent .alert-success .label, .page-total-analytics-container .item-details-block-transparent .alert-danger .label {
//     font-size: @font-size * 2 !important;
//   }
//   .page-total-analytics-container .item-details-block-transparent .alert .property, .page-total-analytics-container .item-details-block-transparent .alert-success .property, .page-total-analytics-container .item-details-block-transparent .alert-danger .property {
//     font-size: @font-size * 1.5 !important;
//   }

//   .search {
//     height: 50px;
//     &::-webkit-input-placeholder {
//       font-size: @font-size * 1.5 !important;
//     }

//   }
//   .table-action-buttons .item-settings-dropdown .user-dropdown-btn {
//     font-size: @font-size * 1.5 !important;
//     padding: 8px;
//   }
//   .table-action-buttons .btn-add-dark {
//     font-size: @font-size * 1.5 !important;
//     padding: 8px;
//   }

//   .details-wrapper h2.start-page-header {
//     font-size: @font-size * 2 !important;
//   }

//   .modal-content .modal-title {
//       font-size: @font-size * 2 !important;
//       label {
//         font-size: @font-size * 1.5 !important;
//       }
//   }
//   .modal-edit-btns-group-main .btn {
//     font-size: @font-size * 1.5 !important;
//   }
//   .ui-select-bootstrap .ui-select-choices-row > span {
//     font-size: @font-size * 1.5 !important;
//     padding: 8px !important;
//   }
//   .item-details-block-transparent .item-details-el .value {
//     font-size: @font-size * 1.5 !important;
//   }
//   .item-details-block-transparent .item-details-el .property {
//     font-size: @font-size * 1.1 !important;
//   }
//   .gt-detail-page .page-header-main .main-nav-tabs li > a {
//     font-size: @font-size * 1.5 !important;
//   }

//   .details-wrapper .process-container-well .item-process-container h5 {
//     font-size: @font-size * 1.5 !important;
//   }
//   .item-process-container {
//     .btn-add-dark {
//       font-size: @font-size * 1.5 !important;
//     }
//   }
//   .contract-details-container-cut .contract-description-label {
//     font-size: @font-size * 1.5 !important;
//   }
//   .contract-status-block-sub {
//     font-size: @font-size * 1.5 !important;
//   }
//   label {
//     font-size: @font-size * 1.5 !important;
//   }
//   .progress .progress-bar {
//     font-size: @font-size * 1.5 !important;
//   }
//   .create-contract-page .gt-table-sidebar tr td {
//     font-size: @font-size * 1.5 !important;
//   }
//   .create-contract-page .gt-sidebar.col-xs-2 .start-page-header {
//     font-size: @font-size * 1.5 !important;
//   }
//   .create-contract-page .sidebar-wrapper .pagination > li > a, .create-contract-page .sidebar-wrapper .pagination > li > span {
//     font-size: @font-size * 1.5 !important;
//   }
//   .details-wrapper .process-container-well .item-process-container .process-item {
//     font-size: @font-size * 1.5 !important;
//   }
//   .filter-template .btn {
//     font-size: @font-size * 1.5 !important;
//   }
//   .no_content_text {
//     font-size: @font-size * 1.5 !important;
//   }
// }

/* max991 */

@media (max-width: 991px) {
  .object-details-container {
    select[multiple] option,
    select[size] option {
      padding: 2px !important;
    }
  }
  .position-row-detail-container {
    select[multiple] option,
    select[size] option {
      padding: 2px !important;
    }
  }
  .clients-logo-start img {
    max-height: 70px;
  }
  .page-info-container {
    display: none;
  }
  .analytics-container,
  .ul-inline-row,
  .table-container-wrapper {
    .start-page-header {
      margin: 0 !important;
    }
  }
  .modal-template-third-width,
  .modal-template-three-thirds-width,
  .modal-template-half-width {
    .modal-dialog {
      width: 100% !important;
      float: none;
    }
  }
  .details-wrapper .process-container-well .item-process-container .process-item {
    font-size: @font-size / 1.1 !important;
  }
  .passports-container .passport-properties-details .alert {
    font-size: @font-size / 1.1 !important;
  }
  .start .start-title .fa {
    font-size: @font-size * 2 !important;
  }
  .modal-content {
    .alert-info {
      position: relative;
    }
  }

  #login {
    .logo-login {
      width: 400px !important;
      height: 250px !important;
      display: block;
      margin-bottom: 100px;
      margin-top: 300px;
    }
    .btn-link {
      font-size: @font-size * 2.5 !important;
    }
    .alert {
      font-size: @font-size * 2 !important;
    }
  }

  .navbar-nav {
    margin: 0;
  }
  .navbar-user .navbar-top-grouped {
    margin: 0;
    margin-top: 10px !important;
    li {
      display: inline-block;
      // span {
      //   display: none !important;
      // }
    }
    li.inside-navbar {
      display: block;
      // span {
      //   display: inline-block !important;
      // }
    }
  }

  .navbar-collapse.in {
    overflow-y: inherit;
  }

  .if-navbar-top .central-container {
    padding-top: 68px !important;
  }

  .navbar-default .navbar-collapse {
    background: @navbar-background;
    border-bottom-right-radius: 12px;
    overflow: hidden;
    padding: 12px !important;
    margin-top: -1px;
  }

  .navbar-brand {
    display: none !important;
  }
  .notify-dropdown-li {
    position: absolute !important;
    right: 100px !important;
    top: 13px !important;
  }
  .user-dropdown-li {
    position: relative !important;
    right: inherit !important;
    top: inherit !important;
    float: left;
  }
  .navbar-user-dark {
    li {
      display: inline-block !important;
    }
  }
  .notify-dropdown-btn span {
    margin-top: 0 !important;
  }
  .page-header-main h1 .user-dropdown-btn {
    span {
      display: inline-block !important;
    }
  }

  .nav-tabs.table-nav-tabs {
    li {
      display: inline-block !important;
      a {
        font-size: @font-size * 1.2 !important;
        padding: 10px !important;
      }
    }
    li.active > a {
      font-size: @font-size * 1.2 !important;
      padding: 10px !important;
    }
  }

  .navbar-nav .open .dropdown-menu {
    position: absolute;
    background-color: @tables-background;
    overflow: hidden;
  }

  .item-settings-dropdown {
    li {
      display: block;
      span {
        display: inline-block !important;
      }
    }
  }

  .modal-form-block .control-label {
    border-right: 0 !important;
  }
}

/* max991 */

@media (max-width: 991px) and (orientation: portrait) {
  .start .start-block-row {
    display: block;
  }
  .help-btn-group .item-settings-dropdown a:nth-of-type(1) {
    margin: 0 !important;
  }
  .navbar-nav .open .dropdown-menu {
    position: absolute !important;
    background-color: @tables-background !important;
    right: 0;
    overflow: hidden;
  }
  .table-action-buttons {
    .col-sm-3 {
      padding: 0;
    }
  }
  .wizard {
    display: none;
  }
  .card-header h2.start-page-header {
    margin-bottom: 15px !important;
  }
  .card-body {
    .space-between {
      .item-settings-dropdown {
        font-size: @font-size;
      }
    }
  }
  .create-contract-page .gt-main-container .page-header-main h1 {
    padding-bottom: 5px !important;
  }

  .alert {
    line-height: 1;
    font-size: @font-size;
  }

  .navbar-header {
    height: 80px;
    background: @navbar-background;
  }

  .navbar-collapse.in {
    overflow-y: auto;
  }

  .if-navbar-top .central-container {
    padding-top: 75px !important;
  }

  .page-header-main {
    padding-right: 15px;
    padding-left: 15px;
  }
  br {
    font-size: 12px !important;
  }
  .page-header-main .main-nav-tabs li.active > a {
    padding-top: 22px !important;
  }
  .gt-main-container.col-xs-11,
  .gt-main-container-with-sidebar.col-xs-10 {
    width: 100%;
  }

  .help-btn-group a {
    display: block;
    width: 100% !important;
    border-radius: 0.25em !important;
    margin-bottom: 2px;
    height: 30px !important;
    span {
      display: inline-block !important;
    }
  }

  .item-settings-dropdown a:nth-of-type(1) {
    white-space: nowrap;
  }

  .item-settings-dropdown .dropdown-toggle.long-title {
    white-space: normal;
  }

  .item-settings-dropdown {
    padding: 0;
    width: 100%;
    display: block;
    margin: 0;
    margin-bottom: 5px;
  }

  table tr td {
    .item-settings-dropdown {
      width: auto;
    }
  }
  .create-contract-page .gt-main-container .help-btn-group {
    margin: 20px;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    display: inline-block;
    position: relative;
    width: 100%;
    a:last-child {
      margin-right: 5px !important;
    }
  }

  .create-contract-page .gt-main-container .help-btn-group .item-settings-dropdown {
    float: left;
    margin: 1px !important;
    margin-left: -5px !important;
    margin-bottom: 3px !important;
  }
  .table-action-buttons {
    margin-bottom: 5px;

    div.pull-right {
      float: none !important;
      margin: 0;
    }
    .item-settings-dropdown {
      margin-left: 0 !important;
      margin: 0 !important;
      margin-bottom: 5px !important;
    }
    .btn-group {
      margin-right: 0 !important;
      margin-left: 0;
      width: 100%;
      display: block;
      margin-bottom: 5px !important;
    }

    .btn {
      margin-left: 0 !important;
      margin-bottom: 5px !important;
    }
    .col-md-2,
    .col-xs-2,
    .col-sm-2,
    .pull-left {
      margin: 0;
      width: 100%;
      display: block;
      padding: 0;
    }

    .alert-applied-filters .btn {
      padding: 4px !important;
    }

    .item-settings-dropdown a:nth-of-type(1) {
      padding: 12px;
      text-align: center;
    }
  }

  .col-sm-12.col-md-5 {
    padding-left: 0;
    padding-right: 0;
  }

  .table-fixed-layout,
  .contract_detail_table {
    table-layout: auto !important;
  }

  .main-list {
    margin-top: 10px;
  }

  .lineup-header {
    display: none;
  }

  .col-md-6.pull-left,
  .col-md-6.pull-right,
  .col-sm-6.pull-left,
  .col-sm-6.pull-right,
  .col-sm-4 {
    padding-right: 0;
    padding-left: 0;
  }
  .well {
    .btn.btn-info,
    .btn.btn-success,
    .btn.btn-warning,
    .btn.btn-danger {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .gt-detail-page .central-container {
    padding-top: 0 !important;
  }

  .create-contract-page .gt-sidebar.col-xs-2 {
    display: none !important;
  }
  .col-md-7.pull-right {
    float: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .passports-wrapper-light-mode .request-list .avg-price {
    font-size: @font-size;
  }

  .client-name-header {
    display: none;
  }
  // .navbar-user .navbar-top-grouped {
  //   margin-left: 130px !important;
  // }

  .navbar-nav {
    margin: 0;
  }

  .item-settings-dropdown .dropdown-menu .btn,
  .item-settings-dropdown .dropdown-menu a {
    padding: 8px !important;
  }

  .page-total-analytics-container .item-details-block-transparent {
    .alert {
      display: grid;
      margin-bottom: 10px;
      padding: 10px !important;
      width: 100%;
    }
    .alert .label {
      font-size: @font-size * 1.3 !important;
    }
    .alert .property {
      font-size: @font-size !important;
    }
  }

  .contracts-wrapper-light-mode,
  .clients-wrapper-light-mode,
  .persons-wrapper-light-mode {
    padding: 5px;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

  .notify-dropdown-li {
    display: inline-block !important;
  }
  .notify-dropdown-li {
    position: absolute !important;
    right: 100px !important;
    top: 28px !important;
  }
  .user-dropdown-li {
    position: relative !important;
    right: inherit !important;
    top: inherit !important;
  }
  .navbar-user-dark {
    li {
      display: inline-block !important;
    }
  }
  .navbar-nav.user-dropdown.navbar-user-dark {
    display: block;
    margin: 2px;
  }

  .notify-dropdown-btn,
  .user-dropdown-li {
    font-size: @font-size !important;
  }

  .nav .navbar-nav .user-dropdown .navbar-user-dark {
    display: none !important;
  }

  .navbar-user .navbar-top-grouped {
    width: 100%;
    li {
      display: block;
      text-align: left !important;
      // span {
      //   display: none !important;
      // }
    }
    li.inside-navbar {
      display: block;
      // span {
      //   display: none !important;
      // }
      a {
        display: block;
        text-align: left !important;
      }
    }
  }

  .navbar-user .navbar-top-grouped li > a {
    font-size: @font-size * 1.2 !important;
    height: auto !important;
    text-align: left !important;
    span {
      font-size: @font-size !important;
    }
  }

  .navbar-user .navbar-top-grouped {
    min-height: 100px !important;
  }
  .navbar-user .navbar-top-grouped li {
    border-right: 0 !important;
    .fa {
      font-size: @font-size !important;
    }
    font-size: @font-size * 1.2 !important;
    height: auto !important;
    text-align: left !important;
  }
  .navbar-user .user-dropdown-li .fa {
    font-size: @font-size * 1.2 !important;
  }
  // .navbar-user .business-units-li {
  //   display: none !important;
  // }

  // .navbar-user .navbar-top-grouped li > a  {
  //   .fa, span {
  //      font-size: @font-size !important;
  //   }
  // }

  .col-sm-2 {
    width: 100% !important;
  }
  .col-sm-3 {
    width: 100% !important;
  }
  .col-sm-4 {
    width: 100% !important;
  }

  .small-screen-show {
    display: inline-block !important;
  }
  .small-screen-hide {
    display: none !important;
  }

  .table-action-buttons .btn,
  .table-action-buttons span .btn {
    padding: 12px !important;
    width: 100%;
    display: block;
    clear: both;
  }

  .main-table,
  .table-template,
  table {
    tr td {
      white-space: nowrap !important;
    }
    tr th {
      .fa-info-circle {
        display: none;
      }
    }
  }

  .clients-table tr td:nth-child(1) {
    .smaller-label span {
      font-size: @font-size !important;
    }
    .smaller-label {
      font-size: @font-size !important;
    }
  }

  .item-settings-dropdown .dropdown-menu .btn,
  .item-settings-dropdown .dropdown-menu a {
    font-size: @font-size !important;
  }

  // .navbar-header {
  //     background: @navbar-user-background !important;
  // }
  .contract-details-container-cut {
    overflow-x: auto;
  }
  // .navbar-user li>a .fa {
  //   display: none;
  // }

  .modal-content .modal-title {
    padding: 12px !important;
  }
  .modal-form-container .gt-date-select .dropdown-menu {
    left: -150px !important;
  }
  // .modal-edit-btns-group-main {
  //   padding-left: 5px !important;
  //   padding-right: 5px !important;
  // }

  .main-nav-tabs {
    li {
      display: inline-flex !important;
    }
    li > a {
      padding-bottom: 15px !important;
      padding-top: 15px !important;
      overflow-x: hidden;
      max-width: 100px;
      .label_start {
        padding: 2px !important;
        display: none;
      }
    }
    li.active > a {
      padding-bottom: 15px !important;
      padding-top: 15px !important;
    }
  }
  .table-nav-tabs {
    li a {
      padding: 10px !important;
    }
  }

  .trader-nav-tabs {
    overflow-x: auto;
  }

  .trader-nav-tabs li a {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .passports-container {
    .nav-tabs span {
      display: none !important;
    }
    small {
      display: none;
    }
  }
  .if-navbar-left .central-container {
    padding-left: 10px;
  }
  .global-container {
    overflow: visible;
  }
  .left-sidebar,
  .central-container,
  .right-sidebar {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .create-contract-page .gt-sidebar.col-xs-1 {
    display: none;
  }
  .if-navbar-left .left-sidebar {
    padding-left: 0 !important;
  }

  .right-sidebar {
    box-shadow: 0 !important;
  }
  .all-filters-block {
    width: 100%;
  }
  .navbar-collapse {
    max-height: 2000px;
  }

  .client-name-header {
    margin-left: 110px;
    margin-top: 55px !important;
  }
  .page-header-main h1 .label_start {
    font-size: @font-size !important;
  }
  .navbar-user {
    margin: 0 !important;

    .btn {
      padding: 0;
      margin: 0;
      border: 0 !important;
    }
    li {
      display: inline-block;
    }
    .user-dropdown-li {
      .btn {
        padding: 2px !important;
      }
    }

    // .notify-dropdown-btn {
    //   span {
    //     margin-top: 20px;
    //     padding: 12px !important;
    //   }

    // }
    // .open {
    //   display: inline !important;

    //   a {
    //     display: inline-block;
    //   }
    // }
  }

  body {
    font-size: @font-size * 1.2;
  }

  .details-wrapper .well {
    padding: 20px;
  }

  .details-wrapper h2.start-page-header {
    font-size: @font-size * 1.1;
  }

  #attach_btn,
  #grain_quality_btn,
  #notes_btn,
  .add-new-payment,
  .costs-btn {
    .centered;
    width: 100% !important;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  input[type='date'] {
    border-radius: 4px !important;
  }
  .btn {
    border-radius: 6px !important;
    padding: 8px !important;
    font-size: @font-size / 1.1 !important;
  }
  .btn-fixed {
    border-radius: 100% !important;
    right: 1%;
    bottom: 1%;
    width: 80px !important;
    height: 80px !important;
    font-size: @font-size * 2 !important;
    padding: 0 !important;
  }
  .edit-btns-group-main {
    .btn-fixed {
      background: @gradient-non-transparent;
      border-radius: 100% !important;
      width: 80px;
      height: 80px;
      line-height: 1;
      font-size: @font-size;
    }
    .btn-fixed:nth-child(1) {
      bottom: 2%;
    }
    .btn-fixed:nth-child(2) {
      bottom: 24%;
    }
    .btn-fixed:nth-child(3) {
      bottom: 52%;
    }
  }
  .modal-edit-btns-group-main {
    // display: flex;
    width: 100% !important;
    .btn-fixed {
      padding: 0 !important;
      font-size: @font-size * 1.2 !important; // line-height: 1 !important;
      width: 35px !important;
      height: 35px !important;
      line-height: 2;
    }
    .btn-success {
      display: block;
      width: 100%;
      margin-bottom: 5px;
    }
  }
  .edit-btns-group-sub {
    .btn-fixed {
      line-height: 2 !important;
      font-size: @font-size !important;
      padding: 0 !important;
    }
  }
  input[type='checkbox'],
  input[type='radio'] {
    width: 40px;
    height: 40px;
  }
  .contract-costs-table .form-control,
  .contract-quality-table .form-control {
    height: auto !important;
  }

  .btn-no-data {
    border-radius: 4px !important;
  }
  .badge,
  .label {
    padding: 4px !important;
    border-radius: 2px !important;
  }
  .logo {
    height: 125px;
    margin-left: 15px;
  }

  .navbar-user .logo {
    width: 80px;
    height: 70px;
  }
  .navbar-user .user-dropdown .navbar-user-dark {
    display: none !important;
  }

  .month-selector {
    h4 {
      font-size: @font-size !important;
    }
  }

  .logistics-page {
    .my-plans-checkbox {
      input {
        width: auto !important;
      }
      label {
        margin-left: 0 !important;
      }
    }
  }

  .planning-table .label {
    width: 100% !important;
  }

  .page-header-main h1 {
    margin-top: 20px !important;
    font-size: @font-size * 1.5 !important;
  } //     .header-margin-top {
  //     .nav-tabs {
  //         li a {
  //             span {
  //                 display: none;
  //                 }
  //         }
  //     }
  // }
  .main-table td {
    font-size: @font-size !important;
    vertical-align: middle !important;
  }
  .main-table td:first-child .btn-link,
  .main-table td .btn-link {
    font-size: @font-size !important;
    border-radius: 0 !important; // padding: 6px !important;
    margin: 0 !important;
    margin-bottom: 3px !important;
  }
  .clients-table {
    tr td {
      font-size: @font-size !important;
      vertical-align: middle !important;
      .label-opacity {
        font-size: @font-size !important;
      }
    }
  }
  .no_content_text {
    font-size: @font-size !important;
  }
  .counterparties-dropdown .btn {
    font-size: @font-size * 1.2 !important;
  }

  .ui-notification {
    .message {
      font-size: @font-size !important;
    }
  }
  .thead-filters {
    .filter-template {
      overflow: visible !important;
    }
  }

  .offers-table tr td .form-control {
    height: 50px;
  }
  .contract-costs-table,
  .contract-quality-table,
  .contract-charges-table {
    .form-control {
      width: auto !important;
    }
    tr td {
      font-size: @font-size !important;
      width: auto !important;
      .label {
        display: inline-block !important;
      }
    }
    tr th {
      font-size: @font-size !important;
      width: auto !important;
    }
    .input-group-addon {
      font-size: @font-size !important;
    }
  }
  .costs-form-container input[type='checkbox'] {
    width: 100% !important;
  }
  .passport-costs-details input[type='checkbox'],
  .passport-costs-details input[type='radio'] {
    height: 80px !important;
    width: 80px !important;
  }

  .ui-select-bootstrap .ui-select-choices-row > span {
    padding: 6px !important;
    font-size: @font-size !important;
  }
  #purchase-properties-table .label {
    font-size: @font-size !important;
  }
  .cut-table td:first-child {
    .btn-link {
      font-size: @font-size !important;
      border-radius: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      margin-bottom: 3px !important;
    }
  }

  .logistics-table tr td:nth-child(1) {
    font-size: @font-size !important;
  }
  .table-template,
  .table-details,
  .table-responsive,
  .table-with-total,
  .cut-contract-table,
  .cut-table,
  #report-farms-table,
  .consolidated-contracts-table,
  .competitors-table {
    max-width: none;
    font-size: @font-size !important;
    overflow-x: auto !important;

    .label,
    .badge {
      max-width: 300px !important;
      padding: 6px !important;
      display: block !important;
    }

    td {
      white-space: nowrap;
      word-break: normal; // width: 100%;
      font-size: @font-size !important;
      padding: 4px !important;

      .label {
        margin-right: 2px;
        margin-left: 2px;
        padding: 4px !important;
        display: block !important;
      }
    }
    th {
      white-space: nowrap;
      font-size: @font-size !important;
    }
  }
  .analytics-container .table-responsive td {
    font-size: @font-size !important;
  }

  .request-table tr td .label,
  .request-table tr td .badge {
    margin-right: 2px;
    margin-left: 2px;
    padding: 6px !important;
    display: block !important;
  }
  .indicators-table tr td .fa {
    font-size: @font-size !important;
  }
  .persons-table tr td {
    font-size: @font-size !important;
    .label {
      font-size: @font-size !important;
    }
  }
  .competitors-table tr td {
    font-size: @font-size !important;
    .form-control {
      height: 44px !important;
    }
    .label {
      font-size: @font-size !important;
    }
  }

  .analytics-container .unscrollable-grid {
    width: 100% !important;
  }
  .analytics-container .unscrollable-grid .ui-grid-cell,
  .analytics-container .grid .ui-grid-cell {
    .btn-link {
      font-size: @font-size !important;
    }
    font-size: @font-size !important;
  }

  .contract-price-widget-table {
    tr td {
      font-size: @font-size !important;
    }
    .form-control,
    .ui-select-match {
      font-size: @font-size !important;
    }
    .clear-btn {
      display: none !important;
    }
  }
  .form-group-container .form-group .contract-price-widget-table .form-control {
    font-size: @font-size !important;
  }
  .form-group-container .form-group .contract-price-widget-table .ui-select-toggle {
    font-size: @font-size !important;
    line-height: 1 !important;
    padding-top: 0 !important;
  }
  .contract-stages-table tr td {
    width: 170px !important;
    .smaller-label {
      white-space: nowrap;
    }
  }
  .contract-status-block-td .contract-status-block {
    font-size: @font-size !important;
    white-space: nowrap;
  }

  .contract-stages-table tr td:nth-child(1) span.smaller-label {
    font-size: @font-size !important;
  }

  .item-details-block-transparent .item-details-el .value {
    font-size: @font-size !important;
  }

  .item-details-block-transparent .item-details-el .property {
    font-size: @font-size / 1.1 !important;
  }

  .position-crop-table tr td .label {
    font-size: @font-size !important;
  }
  .position-crop-table tr td .badge {
    font-size: @font-size !important;
  }
  .position-crop-table tr td {
    font-size: @font-size !important;
  }
  .passport-position-crop-table tr td {
    font-size: @font-size !important;
    .btn-link,
    .label,
    span {
      font-size: @font-size !important;
    }
  }
  .position-crop-table {
    tr th {
      font-size: @font-size !important;
    }
    .details-btn {
      margin-right: 10px;
      .fa {
        font-size: @font-size !important;
      }
    }
  }
  .column-config-table {
    tr td {
      font-size: @font-size !important;
      .form-control {
        font-size: @font-size !important;
      }
      .btn {
        font-size: @font-size !important;
      }
    }
    tr th {
      font-size: @font-size !important;
      white-space: normal !important;
      word-wrap: break-word !important;
      div {
        font-size: @font-size !important;
        white-space: normal !important;
        word-wrap: break-word !important;
      }
    }
  }

  .position-passport-table tr td {
    font-size: @font-size !important;
    .btn-link,
    .label,
    span {
      font-size: @font-size !important;
    }
  }

  .position-passport-table tr td .bigger-label {
    font-size: @font-size !important;
  }
  .position-passport-table tr td .smaller-label {
    font-size: @font-size !important;
  }
  #pl-cash-flow-table tr td {
    font-size: @font-size !important;
    .btn-link,
    .label,
    span {
      font-size: @font-size !important;
    }
  }
  #pl-cash-flow-table tr td:nth-child(1) {
    overflow-x: hidden !important;
  }

  .payments_table .bigger-label.negative-number,
  .payments_table .bigger-label.positive-number {
    font-size: @font-size !important;
  }
  .modal-template .modal-dialog {
    width: 100% !important;
    .ui-select-bootstrap > .ui-select-choices,
    .ui-select-bootstrap > .ui-select-no-choice {
      max-height: fit-content;
    }
  }
  .modal-body {
    padding: 0;
  }
  .datetimepicker .table thead tr th {
    font-size: @font-size !important;
  }

  .datetimepicker .table thead tr th.right,
  .datetimepicker .table thead tr th.left,
  .datetimepicker .table thead tr th.switch {
    font-size: @font-size !important;
  }
  .datetimepicker .table tbody tr td {
    font-size: @font-size !important;
  }

  .contract-details-container-cut .label-details {
    font-size: @font-size !important;
  }
  .contract-details-container-cut .contract-description-label {
    font-size: @font-size !important;
  }
  .contracts-page .contract-description-label,
  .vessels-page .contract-description-label,
  .logistics-page .contract-description-label,
  .request-page .contract-description-label,
  .create-contract-page .contract-description-label,
  .farm-page .contract-description-label,
  .elevator-page .contract-description-label,
  .payments-page .contract-description-label,
  .documents-page .contract-description-label,
  .pl-page .contract-description-label {
    font-size: @font-size !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .payment-details-table {
    tr td {
      white-space: nowrap !important;
    }
    td:last-child {
      white-space: nowrap !important;
    }
  }
  .left-sidebar {
    .cut-contract-table {
      .label,
      .badge {
        max-width: 800px !important;
        padding: 10px;
      }
    }
  }
  .crops-table-container {
    overflow-x: auto;
    margin-right: 15px;
    margin-left: 15px;
  }

  .client-name-header-dark .label {
    font-size: @font-size !important;
  }

  .help-btn-group {
    top: 0;
    right: 0;
    display: block;
    position: relative;
    a {
      padding: 5px !important;
      height: 40px;
      width: 40px;
      font-size: @font-size !important;
    }
    .fa {
      font-size: @font-size !important;
    }
  }
  .header-filters {
    .btn {
      font-size: 1.1em !important;
    }
  }
  .select-date {
    span {
      font-size: @font-size;
    }
  }
  .daterangepicker select {
    font-size: @font-size !important;
  }
  .daterangepicker .ranges {
    font-size: @font-size !important;
  }
  .daterangepicker .ranges li {
    font-size: @font-size !important;
  }
  .daterangepicker .btn {
    font-size: @font-size !important;
  }
  .daterangepicker .calendar {
    max-width: 800px !important;
  }

  .daterangepicker .ranges ul {
    width: 500px;
  }

  .datarangepicker .form-control {
    font-size: @font-size !important;
  }

  table tr th .thead-filters .form-control {
    font-size: @font-size !important;
    height: 60px !important;
  }
  .main-table th .input-group-addon {
    font-size: @font-size !important;
  }
  .main-table th .filter-template .ui-select-match .form-control,
  .main-table th .filter-template .ui-select-container .form-control,
  .main-table th .form-control {
    font-size: @font-size !important;
  }

  .alert-filter {
    top: 65px;
    max-width: 400px;
  }
  .modal-dialog {
    width: 100% !important;
  }
  .right-sidebar .table-template tr,
  .right-sidebar .table-template td {
    width: 100%;
  }
  .filter-template {
    width: 100%;
    display: block;
    border-radius: 4px !important;
    overflow-x: auto;
  }
  .all-filters-block {
    .filter-template {
      display: table;
    }
  }
  .modal-header {
    .col-xs-8.center-block {
      width: 100% !important;
      margin-top: 20px;
    }
    .label {
      display: none;
    }
  }
  .modal-title {
    .label {
      display: none;
    }
  }
  .modal-content {
    .filter-template {
      overflow-x: auto;
    }
    .updated_time {
      display: none;
    }
  }
  .modal-form-container {
    padding-left: 15px;
    padding-right: 15px;

    input[type='checkbox'],
    input[type='radio'] {
      width: 50px !important;
      height: 50px !important;
    }

    .ui-select-choices-row {
      font-size: @font-size;
    }

    .input-group-addon-search {
      display: none !important;
    }
    .input-group {
      width: 100% !important;
    } // .select-with-btn .btn-default {
    //     padding-top: 20px !important;
    //     padding-bottom: 21px !important;
    // }
    .form-add-btn {
      margin-top: 0 !important;
      a {
        line-height: 1;
        font-size: @font-size !important;
        height: 100% !important;
        margin-top: 0 !important;
        padding: 0 !important;
        padding-top: 10px !important;
      }
    }
    .form-add-btn-fullwidth {
      a {
        line-height: 1;
        font-size: calc(@font-size / 1.3) !important;
      }
    }

    .form-group .input-group-addon {
      display: none !important;
    }

    .ul-inline-row {
      overflow: visible !important;
    }
  }
  .client-modal-form-container {
    .modal-form-container;
  }
  .role-modal-form-container {
    .modal-form-container;
  }
  .finance-modal-form-container {
    .modal-form-container;
  }
  .contract-modal-form-container {
    .modal-form-container;
  }
  .client-modal-form-container {
    input[type='checkbox'],
    input[type='radio'] {
      width: 50px;
      height: 50px;
    }
  }

  .passport-modal-form-container {
    .modal-form-container;
  }

  .request-modal-form-container {
    .modal-form-container;
  }
  .contract-modal-form-container {
    .modal-form-container;
    input[type='date'] {
      max-width: 100%;
      width: 100% !important;
    }
  }
  .ui-select-container {
    .btn {
      font-size: @font-size !important;
    }
  }

  .request-modal-form-container .form-add-btn a {
    font-size: @font-size !important; // line-height: 2.4;
    height: auto;
  }
  .request-modal-form-container .form-dismiss-select {
    right: 125px;
    top: 18px;
  }
  .request-modal-form-container .form-add-btn-fullwidth a {
    font-size: calc(@font-size / 1.2) !important;
    line-height: 1;
    height: auto;
  }
  .request-modal-form-container .ul-inline-row {
    padding: 15px !important;
  }

  .form-group-container.main-block-wide .modal-form-block {
    width: 100% !important;
  }

  .filter-template .btn {
    padding: 3px 6px !important;
    font-size: @font-size !important;
    .label_start {
      display: none;
    }
  }

  .sticky-table-first-td,
  .sticky-table-first-td-condensed,
  .sticky-table-first-td-ultra-condensed,
  .sticky-table-first-td-expanded,
  .sticky-table-first-td-expanded-position,
  table.sticky-table-first-td,
  table.sticky-table-first-td-condensed,
  table.sticky-table-first-td-ultra-condensed,
  table.sticky-table-first-td-expanded,
  table.sticky-table-first-td-expanded-position {
    margin-left: 0 !important;
    tfoot > tr > td:first-child,
    tbody > tr > td:first-child,
    thead > tr > th:first-child {
      position: relative !important;
      left: 0 !important;
      width: auto !important;
      vertical-align: middle !important;
      height: auto !important;
      max-height: auto !important;
      min-height: auto !important;
    }
  }

  .payment-partial-table .form-control {
    height: auto;
    font-size: @font-size !important;
  }
  .payment-partial-table .form-control span {
    height: auto;
    font-size: @font-size !important;
  }
  .payment-partial-table tr td .fa,
  .payment-partial-table tr td .btn {
    font-size: @font-size !important;
  }

  .commentaries-table tr td.edit,
  .commentaries-table tr td {
    font-size: @font-size !important;
  }

  .form-group-container select[multiple],
  .form-group-container select[size] {
    height: 300px !important;
  }

  .task-modal-form-container {
    .ul-inline-row {
      overflow-x: visible !important;
    }
  }

  .modal-form-container {
    .ul-inline-row {
      overflow-x: visible !important;
    }
  }

  .ui-select-bootstrap > .ui-select-choices,
  .ui-select-bootstrap > .ui-select-no-choice {
    max-height: 700px;
  }

  .vertical-filter-template .btn {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
  }
  .prices-section-filters .btn {
    font-size: @font-size !important;
  }
  .contract-details-container {
    small {
      .contract_details {
        padding: 8px;
      }
    }
  }
  .updated_time {
    font-size: @font-size !important;
  }
  .no_content_text {
    .fa.fa-exclamation-circle {
      margin-right: 10px;
    }
  }
  .left-sidebar .contract-details-container-cut .btn-link {
    font-size: @font-size !important;
  }
  .left-sidebar .cut-contract-table .label,
  .left-sidebar .cut-contract-table .badge {
    font-size: @font-size !important;
  }
  .contract-details-container-cut small .contract_details {
    font-size: @font-size !important;
  }
  .contract-details-container-cut .btn-link {
    font-size: @font-size !important;
  }
  .contract_details {
    font-size: calc(@font-size / 1.3) !important;
  }
  #cash-flow-table tr td {
    font-size: @font-size !important;
  }
  .contract-page .table-template tr td,
  .contract-page .cut-table tr td,
  .contract-page .table-with-total tr td {
    font-size: @font-size !important;
  }
  .form-horizontal .form-group {
    margin-left: 1%;
    margin-right: 1%;
  }
  .form-group {
    font-size: @font-size * 1.2 !important;
    margin-bottom: 5px !important;
  }
  .form-control {
    font-size: @font-size !important;
    padding: 12px;
    border-radius: 0px !important;
    height: auto;
    margin: 0 !important;

    option {
      font-size: @font-size !important;
      color: @option-color !important;
    }

    &::-webkit-input-placeholder {
      font-size: @font-size !important;
    }
    &::-moz-placeholder {
      font-size: @font-size !important;
    }
    &:-moz-placeholder {
      font-size: @font-size !important;
    }
    &:-ms-input-placeholder {
      font-size: @font-size !important;
    }
  }

  .input-group-addon {
    font-size: @font-size;
  }

  .contract-description-label {
    font-size: @font-size;
  }
  .header-badge {
    font-size: @font-size !important;
  }
  .my-plans-checkbox input {
    width: 70px !important;
    height: 70px !important;
  }
  .link-to-passport {
    font-size: @font-size;
  }
  .form-group-container .form-group.col-xs-12 {
    margin-bottom: 20px !important;
  }

  .form-group-container .form-group .form-control {
    height: 40px;
    font-size: @font-size * 1.2 !important;
  }

  .form-group-container .text-muted {
    font-size: @font-size * 1.2 !important;
  }

  .modal-form-container {
    .clear-btn {
      font-size: @font-size * 1.2 !important;
    }
  }

  .form-group-container .form-group .control-label {
    font-size: @font-size !important;
    margin-bottom: 10px !important;
  }
  .btn-group-justified {
    overflow-x: auto;
    width: 100%;
    display: block;
  }
  .nav-tabs-cut .thead-filter-btn {
    display: none !important;
  }
  .ui-select-bootstrap .ui-select-match-text {
    margin-top: 10px !important;
  }
  .modal-form-block .control-label {
    border-right: 0 !important;
  }
  h4 {
    small {
      display: none;
    }
  }
  .form-group-container.main-block-wide {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;

    .modal-form-block .formly-field {
      display: block;
      width: 100% !important;
    }
    .modal-form-block .control-label {
      font-size: @font-size !important;
    }

    .select-with-btn {
      width: 83% !important;
    }
    .form-add-btn {
      width: 15% !important;
      .add-btn,
      a {
        padding-top: 20px;
        font-size: @font-size !important;
      }
    }
    .form-group.col-xs-12 {
      margin-bottom: 0 !important;
    }
    .form-group .form-control {
      font-size: @font-size * 1.2 !important;
      height: 50px;
      padding: 4px !important;
      .add-btn {
        font-size: @font-size !important;
      }

      &::-webkit-input-placeholder {
        font-size: @font-size * 1.2 !important;
      }
      &::-moz-placeholder {
        font-size: @font-size * 1.2 !important;
      }
      &:-moz-placeholder {
        font-size: @font-size * 1.2 !important;
      }
      &:-ms-input-placeholder {
        font-size: @font-size * 1.2 !important;
      }
    }
    .text-muted {
      font-size: @font-size * 1.2 !important;
    }
    .help-inline {
      top: 35px;
    }
    .multiselect .help-inline {
      top: 285px;
    }
  }
  .modal-content .modal-header .filter-template .btn .fa {
    font-size: @font-size * 1.2 !important;
  }
  .form-group-container.main-block-wide .modal-form-block .ul-inline-row {
    padding: 0 !important;
  }
  .search-container {
    max-height: 100px;
    margin-bottom: 5px;

    .search {
      padding: 10px;
      height: 100%;
      font-size: @font-size !important;
      display: block;
    }
    .btn {
      font-size: @font-size !important;
      padding: 20px !important;
    }
  }
  .form-control.search {
    border-radius: 0px !important;
    width: 100% !important;
  }
  .ul-inline-row {
    width: 100% !important;
    overflow-x: auto !important;
  }
  .crops-edit-table {
    table-layout: auto !important;
    .form-control {
      width: auto !important;
      padding: 17px;
      height: 70px;
    }
    h5 {
      font-size: @font-size;
    }
  }

  .datetimepicker .table thead tr th:nth-child(1),
  .datetimepicker .table thead tr th:nth-child(2),
  .datetimepicker .table thead tr th:nth-child(3),
  .datetimepicker .table tbody tr td:nth-child(1),
  .datetimepicker .table tbody tr td:nth-child(2),
  .datetimepicker .table tbody tr td:nth-child(3) {
    width: 40px !important;
    min-width: 40px !important;
    height: 40px !important;
  }
  .datetimepicker .table tbody tr td {
    width: 40px !important;
    min-width: 40px !important;
    height: 40px !important;
  }

  // .modal-template .modal-body {
  //   background: transparent !important;
  // }

  .modal-form-block {
    margin-bottom: 0 !important;
  }

  .form-group-container .form-group.col-xs-12 {
    margin-bottom: 20px !important;
  }
  .modal-form-container .modal-form-block .ul-inline-row {
    padding: 10px !important;
  }
  .container-btn-group {
    .btn {
      padding: 16px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
  .form-group-container {
    padding: 0 !important;
  }
  .formly-field.formly-field-group {
    line-height: 1 !important;
  }
  .modal-content .modal-header .filter-template .btn {
    font-size: @font-size !important;
  }
  .central-home-purchase {
    .ui-select-match,
    .ui-select-container {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .payment-plans-list {
    font-size: @font-size !important;
    text-align: left;
    border: 1px dashed darken(@borders-color, 23%) !important;
    padding: 25px !important;
    margin-bottom: 15px !important;
    border-radius: 6px;
    .pull-left {
      display: block;
      text-align: left;
      float: none !important;
    }
    .pull-right {
      display: block;
      text-align: left;
      float: none !important;
    }
  }
  .tariffs-table {
    table-layout: auto !important;
    tr td {
      padding: 6px !important;
    }
    .form-add-btn a {
      height: 48px !important;
      line-height: 1;
      padding: 0 !important;
      padding-top: 15px !important;
      padding-left: 2px;
      padding-right: 2px;
    }
    .select-with-btn {
      margin-bottom: 5px;
    }
    .form-control {
      padding-left: 2px !important;
      padding-right: 2px !important;
    }
  }
  input {
    border-radius: 0 !important;
  }
  .control-label {
    font-size: calc(@font-size / 1.2) !important;
    margin: 0 !important;
  }
  .help-inline {
    font-size: @font-size !important;
  }
  select[multiple],
  select[size] {
    border-radius: 4px !important;

    option {
      font-size: @font-size !important;
      padding: 12px;
    }
  }
  .bootstrap-select,
  select {
    font-size: @font-size !important;

    option {
      font-size: @font-size !important;
    }
  }
  .page-header .dropdown-toggle {
    span {
      font-size: @font-size * 1.5 !important;
    }
    .label-info {
      display: none !important;
    }
  }
  .page-header-main h1 .user-dropdown-btn {
    white-space: normal;
    font-size: @font-size * 1.5 !important;
    span.initial-transform {
      font-size: @font-size * 2 !important;
      margin-bottom: 15px !important;
    }
  }

  .border-left-thin {
    border-left: 0 !important;
  }
  .alert-danger-dashed .border-left,
  .ul-inline-row.alert-danger-dashed .border-left,
  .table-container-wrapper.alert-danger-dashed .border-left {
    border-top: 6px solid @label-danger-background !important;
    border-left: 0 !important;
    overflow: hidden;
  }
  .passport-block-mode .alert-danger-dashed {
    height: 500px !important;
  }

  .process-container-well {
    display: none !important;
  }
  .updates-process-container {
    .process-container-well {
      display: block !important;
      .item-process-container h5 {
        text-align: left;
      }
    }
  }

  .details-wrapper .process-container-well .item-process-container h5 {
    text-align: left;
    margin-bottom: 10px;
    border-bottom: 1px solid darken(@borders-color, 9%);
    padding-bottom: 5px;
  }

  .help-inline-hint,
  .tooltip-modal {
    display: none !important;
  }
  td .dropdown-toggle {
    font-size: @font-size !important;
  }
  .bootstrap-select.btn-group .dropdown-menu li {
    font-size: @font-size !important;
  }

  .settings-page {
    label {
      font-size: @font-size;
    }
    .btn {
      font-size: @font-size;
    }
  }
  .consolidated-page {
    .filter-template {
      .btn {
        border-radius: 2px !important;
        display: inline-block;
        width: auto;
        text-align: center;
        padding: 8px;
        padding-left: 10px;
        border-top: 1px solid @borders-color !important;
      }
    }
  }
  .contracts-page input,
  .vessels-page input,
  .logistics-page input,
  .request-page input,
  .farm-page input,
  .elevator-page input {
    width: 100% !important;
  }
  .search {
    font-size: @font-size !important;
  }
  small {
    font-size: @font-size !important;
    line-height: 1.2;
    display: block;
    span,
    div,
    p,
    .label {
      line-height: 1;
    }
  }
  .btn {
    padding: 20px;
  }
  td {
    .btn {
      font-size: @font-size !important;
      padding: 4px !important;
    }
  }

  .page-header-main .main-nav-tabs li > a {
    padding: 20px !important;
  }

  .nav-tabs a {
    max-width: 100px;
    overflow-x: hidden;
    span {
      display: none;
    }
  }

  .nav-tabs-cut li.active > a span {
    display: none;
  }

  // .nav-tabs li.active > a {
  //   font-size: @font-size !important;
  //   padding: 30px !important;
  // }

  .request-table tr td .fa,
  .clients-table tr td .fa {
    font-size: @font-size !important;
  }
  .request-table tr td .fa-pencil-square,
  .clients-table tr td .fa-pencil-square,
  .request-table tr td .fa-trash,
  .clients-table tr td .fa-trash {
    font-size: @font-size !important;
  }
  .request-table td.small-font-cell {
    font-size: @font-size !important;
  }
  .request-table td.small-font-cell a,
  .request-table td.small-font-cell span,
  .request-table td.small-font-cell .btn-link {
    font-size: @font-size !important;
  }
  .contract-details-table,
  .contract-costs-table,
  .contract-quality-table {
    tr td,
    td th {
      font-size: @font-size !important;
    }
  }
  .no-user-avatar,
  .user-avatar {
    font-size: @font-size !important;
  }

  .tags-list li .label {
    padding: 6px;
    font-size: @font-size !important;
    margin: 6px !important;
  }

  .payments_table .bigger-label {
    font-size: @font-size !important;
  }

  .notify-dropdown-menu {
    min-width: 100% !important;
    width: 800px !important;
  }
  .all-filters-block .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    font-size: @font-size !important;
  }
  .all-filters-block .input-group .form-control {
    height: 60px;
  }
  .all-filters-block .contract-description-label {
    font-size: @font-size !important;
  }

  .table-container-wrapper::-webkit-scrollbar {
    height: 40px;
  }
  .overflow-x::-webkit-scrollbar {
    height: 40px;
  }

  .nav-tabs-cut {
    li a span {
      display: none;
    }
    li a {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .sticky-table-first-td-ultra-condensed thead > tr > th:first-child div {
    padding: 0 !important;
  }
  .sticky-table-first-td thead > tr > th:first-child div,
  table.sticky-table-first-td thead > tr > th:first-child div {
    padding: 0 !important;
  }

  .table-nav-tabs {
    display: none; // overflow-x: auto;
  }

  .ui-select-choices-row {
    font-size: @font-size !important;
  }

  .btn-sm {
    padding: 16px;
  }
  .btn-xs {
    padding: 10px;
  }
  .checkbox-inline.no_indent {
    font-size: 22px;
    .btn-link {
      font-size: 22px !important;
    }
  }
  #Terms {
    padding: 5px;
    height: 50px;
    width: 50px;
    margin: 0;
  }
  #show-more-btn {
    clear: both !important;
    padding: 14px 40px;
    margin: 40px;
  }
  .docs-list .fa.fa-download {
    font-size: @font-size !important;
  }
  .docs-list p {
    font-size: @font-size;
  }
  .right-sidebar {
    height: auto !important;
    clear: both;

    select {
      height: auto;
    }
  }
  .central-container {
    border: none;

    label {
      font-size: @font-size;
    }
  }
  .header-margin-top.container {
    max-width: 100% !important;
  }

  .page-header-main .btn-success {
    display: none;
  }

  .page-header-main small {
    display: none;
  }

  .bigger-label {
    font-size: @font-size !important;
  }
  .smaller-label {
    font-size: @font-size !important;
  }
  .label-opacity {
    font-size: @font-size !important;
  }
  .request-list-container .label {
    font-size: @font-size !important;
  }

  .passports-wrapper-light-mode .request-list {
    min-width: 450px;
  }

  table tr th {
    font-size: @font-size !important;
  }

  table tr th div {
    white-space: nowrap !important;
    font-size: calc(@font-size / 1.3) !important;
  }
  .clients-table tr td {
    font-size: calc(@font-size / 1.2) !important;
  }

  .clients-table tr td .label {
    font-size: calc(@font-size / 1.2) !important;
  }
  .clients-table a.modal-btn {
    font-size: calc(@font-size / 1.2) !important;
  }

  .tariffs-table {
    tr td {
      font-size: calc(@font-size / 1.2) !important;
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
    .inside-table-ul li span {
      font-size: calc(@font-size / 1.2) !important;
    }
    .form-control {
      min-width: 200px !important;
    }

    .form-add-btn a {
      height: 72px !important;
      line-height: 2.5;
      display: none;
    }

    .btn-success {
      font-size: calc(@font-size / 1.2) !important;
    }

    .select-with-btn {
      overflow: visible;
      width: auto !important;
    }
  }

  .pagination > li > a,
  .pagination > li > span {
    padding: 14px;
    font-size: @font-size !important;
  }
  #log-properties-table {
    tr td {
      font-size: calc(@font-size / 1.2) !important;
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
        display: block !important;
      }
    }
    .inside-table-ul li span {
      font-size: calc(@font-size / 1.2) !important;
    }
  }

  .request-table {
    tr td {
      font-size: calc(@font-size / 1.2) !important;
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
    .inside-table-ul li span {
      font-size: calc(@font-size / 1.2) !important;
    }
  }

  .email-template-table {
    tr td {
      word-wrap: break-word !important;
      white-space: normal !important;
    }

    td input[type='checkbox'],
    td input[type='radio'] {
      width: 40px;
      height: 40px;
    }
  }

  .request-table .item-settings-dropdown .fa-cog,
  .request-table .item-settings-dropdown .fa-align-left {
    font-size: calc(@font-size / 1.2) !important;
  }

  .places-table .inner-span {
    font-size: calc(@font-size / 1.2) !important;
  }

  .manager-table {
    tr td {
      font-size: calc(@font-size / 1.2) !important;
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
    .inside-table-ul li span {
      font-size: calc(@font-size / 1.2) !important;
    }
  }
  .task-block,
  .task-item-container {
    tr td {
      font-size: calc(@font-size / 1.2) !important;

      .btn-default,
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
    .inside-table-ul li span {
      font-size: calc(@font-size / 1.2) !important;
    }
    .task-update-time {
      font-size: calc(@font-size / 1.2) !important;
    }

    .smaller-label {
      font-size: calc(@font-size / 1.2) !important;
    }
  }
  .task-table {
    margin-bottom: 0 !important;
  }

  .crops-edit-table h5 .input-group .form-control {
    height: 70px !important;
  }
  .counterparty_detail_table {
    tr td:first-child {
      font-size: calc(@font-size / 1.2) !important;
      width: auto;
      label,
      .label,
      .btn-link,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
    tr td {
      font-size: calc(@font-size / 1.2) !important;
      padding: 4px;
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
    .inside-table-ul li span {
      font-size: calc(@font-size / 1.2) !important;
    }
  }

  .contract-details-table td:first-child {
    font-size: calc(@font-size / 1.2) !important;
  }

  .contract-details-table tr td .btn-link {
    font-size: calc(@font-size / 1.2) !important;
  }

  .request-logistic-table tr td:first-child {
    font-size: calc(@font-size / 1.2) !important;
  }

  .payment-partial-table tr td {
    font-size: calc(@font-size / 1.2) !important;
  }
  .indicators-table {
    tr td {
      font-size: calc(@font-size / 1.2) !important;
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
    .inside-table-ul li span {
      font-size: calc(@font-size / 1.2) !important;
    }
  }
  .contract-details-container-cut .progress .progress-bar {
    height: auto !important;
  }

  .contract-details-container-cut h2 {
    font-size: calc(@font-size / 1.2) !important;
  }

  .passports-table {
    overflow-x: auto;
    tr td {
      font-size: calc(@font-size / 1.2) !important;
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
    .inside-table-ul li span {
      font-size: calc(@font-size / 1.2) !important;
    }
  }
  .logistics-table {
    tr td {
      font-size: calc(@font-size / 1.2) !important;
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
  }
  .table-with-total .total-row {
    td {
      font-size: calc(@font-size / 1.2) !important;
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
  }

  .progress .progress-bar {
    font-size: calc(@font-size / 1.2) !important;
  }

  .dashboard-table {
    tr td {
      font-size: calc(@font-size / 1.2) !important;
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
  }
  .planning-table {
    tr th {
      font-size: calc(@font-size / 1.2) !important;
    }
    tr td {
      font-size: calc(@font-size / 1.2) !important;
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
      .form-control {
        font-size: calc(@font-size / 1.2) !important;
        width: 70px !important;
      }
    }
  }

  .daterangepicker {
    td,
    th {
      width: 50px;
      height: 50px;
      font-size: calc(@font-size / 1.2) !important;
    }
  }
  .datetimepicker {
    width: auto !important;
  }

  .datetimepicker tr th {
    font-size: calc(@font-size / 1.2) !important;
  }
  .datetimepicker tr td {
    font-size: calc(@font-size / 1.2) !important;
  }

  .dashboard-start-container .ui-grid-cell-contents,
  .dashboard-start-container .ui-grid-cell .tooltip,
  .dashboard-start-container .ui-grid-cell,
  .dashboard-start-container .ui-grid-cell .label {
    font-size: calc(@font-size / 1.2) !important;
  }
  .dashboard-start-container .ui-grid-cell,
  .dashboard-start-container .ui-grid-row {
    height: 40px !important;
  }

  .navbar-user .dropdown-menu li,
  .navbar-user .dropdown-menu li > a {
    height: 40px !important;
  }
  .navbar-user .dropdown-menu li > a {
    padding: 10px !important;
  }

  .navbar-user .dropdown-menu .pagination li {
    display: inline !important;
  }

  .navbar-user .dropdown-menu .pagination li > a {
    width: auto !important;
  }

  .notify-dropdown-menu {
    max-height: 1000px;
  }

  .notify-dropdown-menu table tr td {
    padding: 6px;
    font-size: calc(@font-size / 1.2) !important;
  }

  .pagination-select {
    display: none;
  }

  .bank-accounts-container .request-list {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }

  .list-filters .btn .label_start {
    display: none;
  }

  .item-settings-dropdown a:nth-of-type(1) .fa {
    font-size: calc(@font-size / 1.2) !important;
  }
  .item-settings-dropdown {
    .fa-sort-desc {
      display: none;
    }
    .dropdown-menu {
      min-width: 250px;
    }
  }
  .all-filters-block {
    margin-top: 250px;
    .dropdown-menu {
      position: relative;
    }
    .fa-times-circle {
      font-size: calc(@font-size / 1.2) !important;
    }
  }

  .add-indicator-start-btn {
    margin-right: 205px;
  }
  .add-ticket-start-btn {
    margin-right: 105px;
  }

  .add-indicator-calculator-start-btn {
    margin-right: 305px;
  }

  .filter-template .ui-select-match .form-control,
  .filter-template .ui-select-container .form-control {
    font-size: calc(@font-size / 1.2) !important;
  }

  .ui-notification {
    h3 {
      padding: 12px;
      font-family: 'SourceSansProBold' !important;
    }
    .message {
      font-size: calc(@font-size / 1.2);
      padding: 12px;
      font-family: 'SourceSansProBold' !important;
    }
  }

  .ui-grid-cell .tooltip {
    font-size: calc(@font-size / 1.2) !important;
    line-height: 1;
  }
  .ui-grid-header-cell-label {
    font-size: calc(@font-size / 1.2) !important;
  }

  .ui-grid-cell-contents {
    font-size: calc(@font-size / 1.2) !important;
    line-height: 1;
  }

  .ui-grid-cell .label {
    font-size: calc(@font-size / 1.2) !important;
  }

  .btn-link.start-btn-link {
    font-size: calc(@font-size / 1.2) !important;
  }

  .navbar-default .small-screen-show {
    .user-avatar,
    .no-user-avatar {
      font-size: calc(@font-size / 1.2) !important;
      margin-top: 15px;
    }
  }

  .dashboard-start-container .pagination li a {
    padding: 14px !important;
  }

  .inside-table-btn {
    font-size: calc(@font-size / 1.2) !important;
  }
  .author-me-checkbox,
  .assigned-to-me-checkbox {
    font-size: calc(@font-size / 1.2) !important;
    label {
      font-size: calc(@font-size / 1.2) !important;
    }
  }

  .task-block tr td.comments,
  .task-item-container tr td.comments {
    white-space: normal !important;
  }

  .task-modal-form-container .input-group-addon-search {
    display: none !important;
  }

  .task-block tr td,
  .task-item-container tr td {
    font-size: calc(@font-size / 1.2) !important;
    .btn {
      font-size: calc(@font-size / 1.2) !important;
    }
  }

  .fc-event {
    font-size: calc(@font-size / 1.2) !important;
  }

  #update-properties-table {
    tr td {
      font-size: calc(@font-size / 1.2) !important;
      white-space: normal !important;
      word-wrap: break-word !important;
      .fa {
        font-size: calc(@font-size / 1.2) !important;
      }
      .fa-pencil-square {
        font-size: calc(@font-size / 1.2) !important;
      }
      textarea.form-control {
        font-size: calc(@font-size / 1.2) !important;
        width: 300px !important;
      }
      label,
      .label,
      .badge {
        font-size: calc(@font-size / 1.2) !important;
      }
    }
    .btn-link {
      font-size: calc(@font-size / 1.2) !important;
    }
    tr td.message {
      font-size: calc(@font-size / 1.2) !important;
      width: 70% !important;
    }
  }
  .chart-block-wrapper .form-control {
    height: auto !important;
  }

  .label-date .label.month {
    font-size: calc(@font-size / 1.2) !important;
  }
  .label-date .day {
    font-size: calc(@font-size / 1.2) !important;
  }

  .select-date {
    font-size: calc(@font-size / 1.2);
  }
  .table-responsive {
    border: none !important;
  }

  .start-title {
    font-size: @font-size / 1.5 !important;
    padding: 12px;
    border-radius: 6px !important;
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    height: 70px;
    max-height: 70px;
    min-height: 70px;
    overflow: hidden;
  }
  .start-container {
    margin-right: 15px;
  }
  .dashboard-object-container {
    display: block;
    padding: 0 !important;
    margin: 15px;
    .ul-inline-row {
      margin: 0 !important;
      padding: 10px !important;
    }
  }
  .analytics-container,
  .ul-inline-row,
  .table-container-wrapper {
    padding: 15px !important;
    margin: 0 !important;
    margin-bottom: 15px !important;
    .analytics-container,
    .ul-inline-row,
    .table-container-wrapper {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  .dashboard-object-container .dashboard-label {
    line-height: 1;
  }
  .dashboard-start-container-inner {
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    .col-sm-12.col-md-6 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    br {
      display: none;
    }
  }
  .position-page-container {
    .col-md-6.col-sm-12,
    .col-md-4.col-sm-12,
    .col-md-3.col-sm-12,
    .col-md-6.col-xs-12,
    .col-md-4.col-xs-12,
    .col-md-3.col-xs-12,
    .col-xs-12.col-md-9,
    .col-xs-12.col-sm-4,
    .col-xs-12.col-sm-6,
    .col-xs-12.col-sm-3 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
  .analytics-page {
    .col-md-6.col-sm-12,
    .col-md-4.col-sm-12,
    .col-md-3.col-sm-12,
    .col-md-6.col-xs-12,
    .col-md-4.col-xs-12,
    .col-md-3.col-xs-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .start-page-header {
    .col-xs-3,
    .col-xs-2,
    .col-sm-3,
    .col-sm-2 {
      width: 100% !important;
    }
  }

  .gt-table__hovering {
    overflow-x: auto !important;
  }

  .analytics-container {
    margin-bottom: 10px;
  }
  .start-img {
    padding-top: 10px;
    padding-bottom: 40px;
  }
  .start-img .fa {
    font-size: @font-size !important;
    text-align: right;
  }
  .item-details-block-transparent {
    .table-no-borders tr td {
      font-size: @font-size !important;
    }
  }

  .contracts-wrapper-light-mode .request-list,
  .contracts-wrapper-light-mode .contract-details-container-cut {
    border-bottom: 1px solid darken(@borders-color, 9%) !important;
    border-radius: 8px !important;
    padding: 16px !important;
    margin-bottom: 15px !important;
    .item-settings-dropdown a:nth-of-type(1) {
      font-size: @font-size * 1.1 !important;
    }
    .invoice-inside-table tr td {
      white-space: normal !important;
    }

    .item-settings-dropdown {
      width: auto !important;
      display: inline-flex;
    }
  }

  .persons-wrapper-light-mode .request-list,
  .persons-wrapper-light-mode .contract-details-container-cut {
    border-bottom: 1px solid darken(@borders-color, 9%) !important;
    border-radius: 0 !important;
    .item-settings-dropdown a:nth-of-type(1) {
      font-size: @font-size * 1.1 !important;
    }
  }

  .clients-wrapper-light-mode .request-list,
  .clients-wrapper-light-mode .contract-details-container-cut {
    border-bottom: 1px solid darken(@borders-color, 9%) !important;
    border-radius: 0 !important;
    .item-settings-dropdown a:nth-of-type(1) {
      font-size: @font-size * 1.1 !important;
    }
  }

  .table-action-buttons .item-settings-dropdown .dropdown-menu {
    left: 0 !important;
  }

  .container.start .start-block {
    .centered;
    margin-top: 10px;
  }
  .start-description {
    font-size: calc(@font-size / 1.2) !important;
  }

  .start-logo-login {
    height: 150px;
    background-size: 100% !important;
    margin-right: auto !important;
    margin-left: auto !important;
    float: none;
    display: block;
  }

  .form-login {
    width: 100%;
    .input-group {
      height: 130px;
    }
    .form-control {
      height: 130px !important;
      font-size: @font-size * 3.5 !important;
      line-height: 1 !important;

      &::-webkit-input-placeholder {
        font-size: @font-size * 3.5 !important;
        text-overflow: ellipsis;
      }
      &::-moz-placeholder {
        font-size: @font-size * 3.5 !important;
        text-overflow: ellipsis;
      }
      &:-moz-placeholder {
        font-size: @font-size * 3.5 !important;
        text-overflow: ellipsis;
      }
      &:-ms-input-placeholder {
        font-size: @font-size * 3.5 !important;
        text-overflow: ellipsis;
      }
    }
    .input-group-addon {
      font-size: @font-size * 3.5 !important;
    }
    .checkbox-inline.no_indent {
      font-size: @font-size * 2.5 !important;
    }
  }

  .start-contacts p {
    font-size: @font-size * 2.5 !important;
  }
  .form-login input {
    font-size: @font-size * 3.5 !important;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btn-login {
    font-size: @font-size * 3 !important;
    height: 130px;
  }
  .btn-fixed {
    right: 1%;
    bottom: 1% !important;
    height: 80px;
    width: 80px;
    line-height: 2.5;
  }

  body {
    font-size: @font-size * 1.2;
  }
  form[name='SaleContractForm'],
  form[name='PurchaseContractForm'] {
    -webkit-column-count: 1;
    -webkit-column-gap: 10px;
    -webkit-column-fill: auto;
    -moz-column-count: 0;
    -moz-column-gap: 10px;
    column-count: 1;
    column-gap: 15px;
    column-fill: auto;
    .ul-inline-row:nth-of-type(2) {
      margin-bottom: 0;
    }
  }

  .item-settings-dropdown {
    margin: 0 !important;
  }
  .counterparties-dropdown {
    .btn {
      padding: 2px !important;
      margin: 0 !important;
    }
  }
  .table-responsive {
    border: none !important;
    overflow: auto;
  }
  .cut-table {
    overflow-x: auto;

    td {
      white-space: normal !important;
      font-size: @font-size !important;
    }
  }
  .all-filters-block {
    width: 100%;
  }
  .left-sidebar {
    height: auto !important;

    table td {
      font-size: @font-size !important;
    }
  }
  .search {
    width: 100% !important;
    border-radius: 0 !important;
  }
  .print-item {
    display: table-cell;
  }

  /*   .chart-template {width:350px!important;height:250px!important;}*/
  .header-margin-top {
    margin-top: 5px;
    margin-bottom: 5px;

    small {
      display: block;
      // span {
      //   display: none;
      // }
      margin-top: 5px !important;
      margin-bottom: 15px !important;
      line-height: 2;
    }
    span {
      margin-right: 2px;
    }
  }

  .ui-select-multiple.ui-select-bootstrap {
    input.ui-select-search {
      height: 2.5em !important;
      font-size: @font-size !important;
    }
  }
}

/* max480*/

@media (max-width: 480px) {
  body {
    font-size: @font-size*2.8;
  }
}

/* print*/

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }

  input[type='date'] {
    min-height: 20px !important;
  }
  img {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }
  a[href]:after {
    content: none !important;
  }

  body {
    font-size: @font-size !important;
    background-image: none;
    background: transparent !important;
    color: #000 !important;
    font-family: @font-family-regular, sans-serif !important;
    overflow: visible !important;
  }

  .overflow-x {
    overflow-x: visible !important;
  }

  .sticky-table-first-td,
  table.sticky-table-first-td {
    margin-left: 0 !important;

    tr > td {
      height: auto;
      // max-height: 35px;
      min-height: auto;
    }

    thead > tr > th div {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    tfoot > tr > td:first-child,
    tbody > tr > td:first-child,
    thead > tr > th:first-child {
      position: relative;
      left: 0;
      width: auto !important;
      .item-settings-dropdown {
        padding-top: 0 !important;
      }
      .label,
      span {
        margin-top: 0 !important;
      }
    }
  }

  .sticky-table-first-second-third-td {
    margin-left: 0 !important;

    tfoot > tr > td:nth-child(1),
    tbody > tr > td:nth-child(1),
    thead > tr > th:nth-child(1),
    tfoot > tr > td:nth-child(2),
    tbody > tr > td:nth-child(2),
    thead > tr > th:nth-child(2),
    tfoot > tr > td:nth-child(3),
    tbody > tr > td:nth-child(3),
    thead > tr > th:nth-child(3) {
      position: relative;
      left: 0;
      width: auto !important;

      .item-settings-dropdown {
        padding-top: 0 !important;
      }

      .label,
      span {
        margin-top: 0 !important;
      }
    }
  }

  .main-table,
  .table-template,
  table {
    tr td {
      color: #000 !important;
      padding: 2px !important;
      white-space: normal !important;
      word-break: break-word !important;
      label,
      .label {
        border: 0 !important;
        color: #000 !important;
      }
      .negative-number,
      .positive-number {
        color: #000 !important;
      }
      .label_success,
      .label_danger {
        color: #000 !important;
        background: transparent !important;
      }
      .label-success {
        border: 1px solid @label-success-color-border !important;
      }
      .label-danger {
        border: 1px solid @label-danger-color !important;
      }
      .label_default,
      .label-default {
        background: transparent !important;
      }
    }
  }

  .position-passport-table {
    tr td {
      .label-opacity {
        font-size: @font-size / 1.1 !important;
        color: #000 !important;
      }
    }

    tr td:nth-last-child(1),
    tr td:nth-last-child(2),
    tr td:nth-last-child(3),
    tr td:nth-last-child(5),
    tr td:nth-last-child(6),
    tr td:nth-last-child(7),
    tr td:nth-last-child(8) {
      white-space: nowrap !important;
      min-width: 80px !important;
    }
  }

  .header-margin-top,
  .page-header {
    font-size: @font-size !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .search {
    font-size: @font-size !important;
    border-bottom: 1px solid #000 !important;
  }
  .select-date {
    font-size: @font-size !important;
  }

  h1 {
    font-size: 26px !important;
  }

  .print-item {
    display: table-cell;
  }

  .print-hide {
    display: none;
  }
  a.btn.btn-xs {
    display: none;
  }
  // .item-settings-dropdown {
  //   display: none;
  // }
  .fa.fa-pencil-square {
    display: none;
  }
  .btn.btn-fixed {
    display: none;
  }
  .help-btn-group {
    margin: 20px;
    margin-bottom: 20px !important;
    margin-top: 20px;
    display: inline-block;
    position: relative;
    a:last-child {
      margin-right: 0 !important;
    }
  }
  .page-header-main small {
    display: none;
  }
  .input-group.search-container {
    display: none;
  }
  .filter-template {
    display: none;
  }
  tr th .fa.fa-sort {
    display: none;
  }
  .nav.nav-tabs.main-nav-tabs,
  .nav.nav-tabs.table-nav-tabs {
    display: none;
  }

  .page-header-main h1 .label_start {
    display: none;
  }
}
