//colors
@green: #1bb394;
@green-light: #1bb394;
@brown: #a28355;
@white: #ffffff;
@black: #000000;
@smoke: #666b72;
@red: #cf6163;
@asphalt: #34495e;
@dark-blue: #413e63;
@blue-background: rgba(62, 162, 213, 0.19);
@blue-color: #1f506b;
@very-dark-blue: #263746;
@btn-link-color: #1763ad;
@modal-header-background: #435c75;
@light-blue: #0075ff;
@light-blue-background: rgba(0, 117, 255, 0.19);
@dark-grey: #e5e5e4;

//buttons colors
@btn-login-color: #47a447;
@btn-login-font-color: #ffffff;
@btn-color: #666;
@btn-default-color: #666b72;
@btn-fixed-color: #666b72;
@btn-warning-color: #ed9c28;
@btn-success-color: #1bb394;
@btn-info-color: #1d82c7;
@fixed-btn-shadow: #666b72;
@gradient: linear-gradient(
  45deg,
  rgba(0, 0, 0, 0.07) 25%,
  transparent 15%,
  transparent 50%,
  rgba(0, 0, 0, 0.08) 50%,
  rgba(0, 0, 0, 0.08) 75%,
  transparent 75%,
  transparent
);
@gradient-non-transparent: linear-gradient(
  45deg,
  #e9e9e9 25%,
  #f5f5f5 15%,
  #f5f5f5 50%,
  #e9e9e9 50%,
  #e9e9e9 75%,
  #f5f5f5 75%,
  #f5f5f5
);
@gradient-thin: rgba(0, 0, 0, 0.08);

//labels colors
@label-color: #000000;
@label-dashed-color: #fff;
@label-dashed-background: rgba(132, 187, 219, 0.4);
@label-info-color: #1d82c7;
@label-info-color-border: #1d82c7;
@label-info-background: rgba(212, 232, 240, 0.7);
@label-success-color-border: #07422d;
@label-success-color: #07422d;
@label-success-background: rgba(28, 179, 148, 0.4);
@label-calculate-color: #6d6a6f;
@label-calculate-color-alt: rgba(164, 149, 176, 1);
@label-calculate-background: rgba(135, 86, 191, 0.2);
@label-calculate-color-border: #6d6a6f;
@label-warning-color: #685438;
@label-warning-color-alt: #7d6643;
@label-warning-background: rgba(181, 146, 96, 0.4);
@label-warning-background-alt: rgba(181, 146, 96, 0.1);
@label-warning-color-border: #7d6643;
@label-cancelled-color: rgba(119, 119, 119, 1);
@label-cancelled-background: rgba(119, 119, 119, 0.2);
@label-cancelled-color-border: #777;
@label-grey-color: #595b63;
@label-grey-background: rgba(119, 119, 119, 0.2);
@label-grey-color-border: #595b63;
@label-default-color-border: #595b63;
@label-default-color: #595b63;
@label-default-background: rgba(120, 123, 132, 0.2);
@label-default-background-alt: rgba(119, 119, 119, 0.1);
@label-danger-color: #cf6163;
@label-danger-background: rgba(207, 97, 99, 0.7);
@label-danger-font-color: #fff;
@label-cut-table-color: #eee;
@documents-date-label: #bdc3c7;

//some elements colors
@main-background-color: #eeedeb;
@tables-background: #fff;
@tables-box-shadow: rgba(8, 40, 100, 0.15);
@tables-box-shadow-light: rgba(60, 64, 67, 0.16);
@borders-color: #e8e6e6;
@borders-color-light: #666b72;
@highlighted-area: #f9f9f7; //f0f9fb
@font-color: #ecf0f1;
@filter-border-color: #666b72;
@vertical-divider-color: #95a5a6;
@placeholder-color: #000000; // #8a8e97
@well-background: rgba(0, 0, 0, 0.05);
@well-color: #666b72;
@headers-color: #666;
@links-color: #000000;
@legend-color: #bdc3c7;
@contract-errors-background: #f7f7f7;
@color-black-opacity: rgba(0, 0, 0, 0.01);
@color-black-opacity-dark: rgba(0, 0, 0, 0.03);
@color-black-opacity-very-dark: rgba(0, 0, 0, 0.08);
@color-black-opacity-very-very-dark: rgba(0, 0, 0, 0.15);
@small-color: #8a8e97;
@no-content-text-color: #666b72;
@details-text-color: #e8e6e6;
@updated-time-color: #666b72;
@lower-zero-color: #cf6163;
@higher-zero-color: #1bb394;
@navbar-color: #a28355;
@navbar-background: #34495e;
@navbar-user-background: #efefef;
@navbar-hover-background: #f5f5f5;
@navbar-hover-color: #a28355;
@navbar-default-color: #c0c0c0;
@navbar-font-color: #393a40;
@navbar-font-color-active: #393a40;
@main-nav-tabs-color: #fff;
@nav-border-color: #d2d1d1;
@contract-description-label-color: #bdc3c7;
@contract-description-item-value-color: #666;
@contract-description-item-value-span-color: #7f8c8d;
@last-login-color: #666;
@last-login-border: #666;
@last-change-date-color: #e8e6e6;
@department-title-color: #e8e6e6;
@statistics-second-row: #a28355;
@statistics-third-row: #595b63;
@left-sidebar-background: #e8e6e6;
@right-sidebar-background: #efefef;
@forms-background: #efefef;
@forms-border: #e1dede;

//documents
@documents-background: #e8e6e6;
@documents-color: #393a40;
@documents-color-hover: #666;

//start page colors
@start-container-background: #fff;
@start-img-background: #fff;
@start-block-background: #fff;
@start-block-background-hover: darken(#fdfdfd, 9%);
@start-title-color: #666;
@start-title-color-hover: #9e9e9e;
@start-description-color: #9e9e9e;
@search-color: #666;

@hover-color: #f4f7fa; //fcf8e3
//contract styles colors
@contract-details-color: #ffffe1;
@contract-new-color: darken(#1bb394, 23%);
@contract-new-color-alt: #1bc98e;
@contract-processing-color: #685438;
@contract-processing-color-alt: #e5b878;
@contract-executed-color: #000000;
@contract-executed-color-alt: #f5f5f5;
@contract-cancelled-color: #7f8c8d;
@contract-cancelled-color-alt: #7f8c8d;
@contract-calculate-color: #a495b0;
@contract-calculate-color-alt: #a495b0;

//status styles colors
@status-success-background: #ebf1ef;
@status-success-background-alt: #43555f;
@status-exclamation-background: #f0ede9;
@status-exclamation-background-alt: #54565e;
@status-default-background: rgb(119, 122, 131);
@status-danger-background: rgba(207, 97, 99, 0.13);

@alert-warning-color: #a5a293;
@alert-warning-background: #fcf8e3;

@action-addition: rgba(41, 141, 107, 0.11);
@action-addition-alt: rgba(41, 141, 107, 0.21);
@action-process: rgba(229, 218, 203, 0.42);
@action-process-alt: rgba(229, 218, 203, 0.62);
@action-executed: rgba(120, 123, 132, 0.13);
@action-executed-alt: rgba(120, 123, 132, 0.63);
@action-delete: rgba(207, 97, 99, 0.11);
@action-delete-alt: rgba(207, 97, 99, 0.63);
@action-delete-alt-transparent: rgba(207, 97, 99, 0.05);
@action-approved: rgba(164, 149, 176, 0.22);
@action-approved-alt: #a495b0;

// progressbar backgrounds
@progressbar-success: rgba(41, 141, 107, 0.7);
@progressbar-warning: rgba(229, 218, 203, 0.7);
@progressbar-default: rgba(120, 123, 132, 0.4);
@progressbar-danger: rgba(207, 97, 99, 0.7);
@progressbar-calculate: rgba(164, 149, 176, 0.7);

//dropdowm colors
@dropdown-menu-background: #fdfdfd;
@dropdown-menu-background-active: #c6c7cb;
@dropdown-menu-color: #91795c;
@dropdown-menu-color-active: #666;

@pagination-background: #f8f8f8;
@overlay-color: #2f3138;

//blocks colors
@info-tab: #81a8af;
@pl-tab: #cdaf9b;
@crm-tab: #9597b1;
@prices-tab: #d4b668;
@logistics-tab: #d49091;
@payments-tab: #9bbacd;
@clients-tab: rgba(129, 175, 159, 1);

//forms colors
@help-inline-color: #1bb394;
@additional-field-color: #95a5a6;
@form-group-color: #dcdcdc;
@form-control-color: #000000;
@form-control-color-focus: #666;
@control-label-color: #a28355;
@option-color: #666;
@option-background: #7f8c8d;
@option-background-hover: #dcdcdc;
@select-background: #dcdcdc;
@select-color: #666b72;
@select-background-hover: #91795c;
@select-color-hover: #666;
@not-empty: rgba(11, 103, 71, 0.09) !important;
@not-empty-alt: #f2fffa;

//modal colors
@modal-background: @main-background-color;
@modal-content-color: #000000;
@modal-header: rgba(0, 0, 0, 0.07);

//table colors
@table-border-color: #666b72;
@table-border-color-light: #5a5e6f;
@table-headers-hover: #666;
@th-main-color: #666b72;
@th-main-background: #fafafa;
@td-first-color: #a28355;
@td-main-color: darken(#1bb394, 23%);
@main-table-td: #000000;

//general styles
@main-background: @main-background-color url(img/top-bg-white.png);
@main-background-alt: @main-background-color url(img/top-bg.png);
//@main-background: @main-background-color;
// @main-background: #484d63;
@alt-background: #ebebeb;
@main-font-color: #909597;
@alt-font-color: #f8f8f8;

@main-navbar-background: rgba(52, 50, 64, 0.65);
@border: 1px dashed @borders-color;
@left-sidebar-item-width: 80%;
@sidebar-min-height: 1300px;

//color effects
@text-shadow: none;
@text-shadow-font: #000 0px 0px 0px;
@bold: 600;
@stroke-color: #000;
//margins
@left-sidebar-item-margin: 10px 0 10px 50px;

//fonts
// @font-family: 'Roboto Condensed', Helvetica, Trebuchet MS, Arial, sans-serif, serif;
@font-size: 14px;
@big-font: 1.2em;
@middle-font: 1em;
@small-font: 0.9em;

.modal-template .modal-content {
  background: @highlighted-area !important;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  background: @navbar-background;
}

.navbar-default {
  background-color: @main-navbar-background;
  border-right: 0;
}
.navbar-fixed-left {
  background-color: @navbar-background;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background: @navbar-hover-background !important;
  color: #666;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  /*background: @navbar-hover-background !important;*/
  /*color:#eee;*/
}
.navbar-right > li:last-child > a:hover,
.navbar-right > li:last-child > a:focus {
  background: transparent !important;
  color: #666;
}
.navbar-right > li:nth-last-child(2) > a:hover,
.navbar-right > li:nth-last-child(2) > a:focus {
  background: transparent !important;
  color: #666;
}

.logo {
  background-image: url(img/logo_light.png);
}

.logo_inverted {
  background-image: url(img/logo.png);
}

.if-navbar-left {
  .logo {
    background-image: url(img/logo.png);
  }
}
.label {
  font-weight: 400 !important;
}
.form-group .btn-default {
  border-bottom: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;
  background: darken(@highlighted-area, 9%) !important;
}
.logo-login {
  background: url(img/logo_light.png);
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center;
}

.btn-danger {
  color: #666 !important;
}

.btn-primary {
  background: @tables-background !important;
  color: @btn-color!important;
  border: 1px solid @borders-color !important;
  box-shadow: none !important;
}
.table-action-buttons .btn-primary {
  border: 1px solid @borders-color !important;
}

.btn-primary:hover,
.btn-primary:focus {
  background: @tables-background !important;
}

select[multiple] option[selected] {
  background-color: #666 !important;
  color: #fff !important;
}

// .if-navbar-left {
//   .page-header-main .main-nav-tabs li a {
//     background: @color-black-opacity-dark;
//   }
//   .page-header-main-info-tab,
//   .page-header-main-pl-tab,
//   .page-header-main-crm-tab,
//   .page-header-main-prices-tab,
//   .page-header-main-logistics-tab,
//   .page-header-main-payments-tab,
//   .page-header-main-clients-tab {
//     background: @navbar-background!important;

//     .help-btn-group {
//       a {
//         color: @navbar-background !important;
//       }
//     }
//   }
// }

.navbar-user > ul.navbar-top-grouped li.inside-navbar > a {
  color: @tables-background !important;
}

.ui-select-choices-row:hover {
  background: #eeeee8;
}
