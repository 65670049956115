.modal-template .modal-content {
  background: #f9f9f7 !important;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  background: #34495e;
}
.navbar-default {
  background-color: rgba(52, 50, 64, 0.65);
  border-right: 0;
}
.navbar-fixed-left {
  background-color: #34495e;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background: #f5f5f5 !important;
  color: #666;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  /*background: @navbar-hover-background !important;*/
  /*color:#eee;*/
}
.navbar-right > li:last-child > a:hover,
.navbar-right > li:last-child > a:focus {
  background: transparent !important;
  color: #666;
}
.navbar-right > li:nth-last-child(2) > a:hover,
.navbar-right > li:nth-last-child(2) > a:focus {
  background: transparent !important;
  color: #666;
}
.logo {
  background-image: url(img/logo_light.png);
}
.logo_inverted {
  background-image: url(img/logo.png);
}
.if-navbar-left .logo {
  background-image: url(img/logo.png);
}
.label {
  font-weight: 400 !important;
}
.form-group .btn-default {
  border-bottom: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;
  background: #e5e5dd !important;
}
.logo-login {
  background: url(img/logo_light.png);
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center;
}
.btn-danger {
  color: #666 !important;
}
.btn-primary {
  background: #fff !important;
  color: #666 !important;
  border: 1px solid #e8e6e6 !important;
  box-shadow: none !important;
}
.table-action-buttons .btn-primary {
  border: 1px solid #e8e6e6 !important;
}
.btn-primary:hover,
.btn-primary:focus {
  background: #fff !important;
}
select[multiple] option[selected] {
  background-color: #666 !important;
  color: #fff !important;
}
.navbar-user > ul.navbar-top-grouped li.inside-navbar > a {
  color: #fff !important;
}
.ui-select-choices-row:hover {
  background: #eeeee8;
}
.centered {
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
}
.center-block .start-page-header,
.center-block .pull-left {
  text-align: left !important;
}
.transition {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.transition-long {
  -webkit-transition: ease-out 1s;
  -moz-transition: ease-out 1s;
  -o-transition: ease-out 1s;
  transition: ease-out 1s;
}
.no-transition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
.box-shadow-light {
  box-shadow: none !important;
}
.box-shadow-dark {
  box-shadow: none !important;
}
.box-shadow-heavy {
  box-shadow: 0px 0px 12px #666b72 !important;
}
.box-shadow-medium {
  box-shadow: 0 1px 4px rgba(8, 40, 100, 0.15) !important;
}
.box-shadow-very-heavy {
  box-shadow: 0px 0px 40px #666b72 !important;
}
.box-shadow-none {
  box-shadow: none !important;
}
.box-shadow-none .ul-inline-row,
.box-shadow-none .analytics-container,
.box-shadow-none .table-container-wrapper,
.box-shadow-none .well {
  box-shadow: none !important;
}
.border-radius-25 {
  border-radius: 0.25em;
}
.border-radius-55 {
  border-radius: 0.55em;
}
.rotate {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rotate-15 {
  transform: rotate(15deg);
  -webkit-transform: rotate(15deg);
  -moz-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -o-transform: rotate(15deg);
}
.centered-text {
  text-align: center !important;
}
.nowrap {
  white-space: nowrap !important;
}
.yeswrap {
  white-space: normal !important;
  word-wrap: break-word !important;
}
.line-throught {
  text-decoration: line-through;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hidden-element {
  display: none !important;
}
.visible {
  display: block !important;
}
.visible-inline {
  display: inline-block !important;
}
.visible-table {
  display: table !important;
}
.visible-flex {
  display: inline-flex !important;
}
.hidden-el {
  visibility: hidden !important;
}
.shown-el {
  visibility: visible !important;
}
.chart-template {
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 75% !important;
  display: none;
}
.filter-template {
  border-radius: 2px;
  border: 0;
  font-size: 14px;
}
.filter-template .btn {
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
}
.filter-template .btn-primary {
  background: transparent !important;
  border-bottom: 2px solid #1d82c7 !important;
}
.modal {
  overflow-y: hidden;
}
.modal-template .modal-dialog {
  width: 100% !important;
}
.modal-template .modal-header {
  border-bottom: 0 !important;
}
.modal-template .modal-header .btn-fixed {
  box-shadow: none !important;
}
.modal-template .modal-body {
  background: #eeedeb !important;
}
.modal-template .modal-body .start-page-header {
  font-size: calc(14px * 1.6);
}
.modal-template .modal-body .details-wrapper {
  margin-top: 25px;
}
.modal-template .modal-content {
  background: #435c75 !important;
  color: #000000;
  border-radius: 0.25em;
  border: 0 !important;
}
.modal-template .modal-content .btn-fixed {
  border-width: 1px !important;
}
.modal-template .modal-content .alert-info {
  box-shadow: 0 1px 4px rgba(8, 40, 100, 0.15) !important;
  padding: 10px 30px !important;
  border-radius: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.modal-template .filter-template {
  background: #34495e !important;
  margin-top: 15px;
}
.modal-template .filter-template .btn-primary,
.modal-template .filter-template .btn-primary:hover,
.modal-template .filter-template .btn-primary:focus {
  background: #eeedeb !important;
  border-color: transparent !important;
  border-top-color: transparent !important;
  border-radius: 0 !important;
}
.modal-template-half-width .modal-dialog {
  width: 50% !important;
  float: right;
}
.modal-template-third-width .modal-dialog {
  width: 35% !important;
  float: right;
}
.modal-template-three-thirds-width .modal-dialog {
  width: 75% !important;
  float: right;
}
.modal-footer {
  border-top: 0;
  margin-top: 0;
}
.modal-dialog {
  width: 100%;
  padding: 0;
  margin: 0;
}
.contract_details {
  font-size: calc(14px / 1.5) !important;
}
.updated_time {
  font-size: 14px !important;
}
.contract_executed {
  color: #000000;
}
.contract_cancelled {
  color: #7f8c8d !important;
  text-decoration: line-through !important;
}
.contract_executed > td:first-child,
.vessel_loaded > td:first-child {
  text-align: left;
  color: #000000 !important;
}
.contract_cancelled > td:first-child,
.vessel_washout > td:first-child {
  color: #7f8c8d !important;
  text-align: left;
}
.lower-zero-number {
  color: #cf6163 !important;
}
.higher-zero-number {
  color: #1bb394 !important;
}
.text-stroke {
  -webkit-text-stroke: 0.2px #000;
}
.no-text-stroke {
  -webkit-text-stroke: 0;
}
.main-table-template {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
}
.main-table-template th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.main-table-template th::first-letter {
  text-transform: uppercase !important;
}
.main-table-template th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.main-table-template tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.main-table-template tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.main-table-template tr td:nth-child(1) {
  vertical-align: middle;
}
.main-table-template tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.main-table-template tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.main-table-template .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.main-table-template .label::first-letter {
  text-transform: uppercase !important;
}
.main-table-template .label .fa {
  margin-right: 3px;
}
.main-table-template .label-opacity {
  font-size: 14px / 1.8 !important;
}
.main-table-template .badge {
  padding: 4px !important;
}
.main-table-template .badge::first-letter {
  text-transform: uppercase !important;
}
.main-table-template a {
  cursor: pointer;
}
.main-table-template .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.main-table-template .item-settings-dropdown li > a {
  line-height: 5px;
}
.wrap-text {
  white-space: normal !important;
  word-wrap: break-word;
}
table.table-right-align tbody tr > td {
  text-align: right !important;
}
table.table-right-align tbody tr > td:first-child {
  text-align: left !important;
}
.border-radius-4 {
  border-radius: 4px !important;
}
.border-radius-6 {
  border-radius: 6px !important;
}
@font-face {
  font-family: 'FontAwesome';
  src: url('fonts/fontawesome-webfont.eot');
  src: url('fonts/fontawesome-webfont.eot') format('embedded-opentype'), url('fonts/fontawesome-webfont.woff') format('woff'), url('fonts/fontawesome-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
html,
body {
  overflow-x: hidden !important;
  height: 100%;
}
body {
  background: #eeedeb url(img/top-bg-white.png);
  font-size: 14px;
  font-family: 'Regular', sans-serif !important;
  line-height: 1;
  margin: 0 !important;
  width: 100% !important;
  overflow-x: hidden;
  padding-bottom: 200px;
}
body.modal-open {
  overflow: hidden;
}
h1,
h2,
h5,
h3,
h4,
h6 {
  color: #666;
  -webkit-text-stroke: 0;
  font-family: 'Bold', sans-serif !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
h1 small,
h2 small,
h5 small,
h3 small,
h4 small,
h6 small {
  font-family: 'Regular', sans-serif !important;
}
h1,
h2 {
  font-family: 'Bold', sans-serif !important;
}
textarea {
  resize: none;
}
label {
  font-weight: 400 !important;
  font-size: calc(0.9em / 1.1);
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  -webkit-text-stroke: 0;
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
a {
  color: #000000;
  cursor: pointer !important;
  text-decoration: none !important;
}
a :hover {
  text-decoration: none !important;
}
img,
canvas,
.fa,
br {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-text-stroke: 0;
}
.user-avatar {
  display: inline;
  font-size: 14px;
  border-radius: 3px;
}
.user-avatar img {
  height: 15px;
  width: 15px;
  border-radius: 4px !important;
}
.gt-table__visiblehovering {
  overflow-x: inherit !important;
  overflow-y: inherit !important;
}
.gt-table__visiblehovering .sticky-table-first-td tfoot > tr > td:first-child,
.gt-table__visiblehovering .sticky-table-first-td-condensed tfoot > tr > td:first-child,
.gt-table__visiblehovering .sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child,
.gt-table__visiblehovering .sticky-table-first-td-expanded tfoot > tr > td:first-child,
.gt-table__visiblehovering .sticky-table-first-td-expanded-position tfoot > tr > td:first-child,
.gt-table__visiblehovering .sticky-table-first-td tbody > tr > td:first-child,
.gt-table__visiblehovering .sticky-table-first-td-condensed tbody > tr > td:first-child,
.gt-table__visiblehovering .sticky-table-first-td-ultra-condensed tbody > tr > td:first-child,
.gt-table__visiblehovering .sticky-table-first-td-expanded tbody > tr > td:first-child,
.gt-table__visiblehovering .sticky-table-first-td-expanded-position tbody > tr > td:first-child,
.gt-table__visiblehovering .sticky-table-first-td thead > tr > th:first-child,
.gt-table__visiblehovering .sticky-table-first-td-condensed thead > tr > th:first-child,
.gt-table__visiblehovering .sticky-table-first-td-ultra-condensed thead > tr > th:first-child,
.gt-table__visiblehovering .sticky-table-first-td-expanded thead > tr > th:first-child,
.gt-table__visiblehovering .sticky-table-first-td-expanded-position thead > tr > th:first-child {
  z-index: initial !important;
}
.gt-table__hovering {
  padding-bottom: 300px !important;
}
.gt-table__hovering .tooltip {
  position: inherit !important;
}
.gt-table__hovering .sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-condensed tfoot > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-expanded tfoot > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-expanded-position tfoot > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-condensed tbody > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-expanded tbody > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-expanded-position tbody > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-condensed thead > tr > th:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-ultra-condensed thead > tr > th:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-expanded thead > tr > th:first-child .item-settings-dropdown .dropdown-menu,
.gt-table__hovering .sticky-table-first-td-expanded-position thead > tr > th:first-child .item-settings-dropdown .dropdown-menu {
  left: calc(100% + 1px) !important;
}
.gt-table__hovering .counterparty_detail_table tbody > tr > td:first-child {
  background: inherit !important;
}
.gt-table__nohovering .gt-table__hovering {
  padding-bottom: 0 !important;
  overflow-x: auto !important;
  overflow-y: visible !important;
}
.no-sticky-table .sticky-table-first-td,
.no-sticky-table .sticky-table-first-td-condensed,
.no-sticky-table .sticky-table-first-td-ultra-condensed,
.no-sticky-table .sticky-table-first-td-expanded,
.no-sticky-table .sticky-table-first-td-expanded-position {
  margin-left: 0 !important;
}
.no-sticky-table .sticky-table-first-td tr > td,
.no-sticky-table .sticky-table-first-td-condensed tr > td,
.no-sticky-table .sticky-table-first-td-ultra-condensed tr > td,
.no-sticky-table .sticky-table-first-td-expanded tr > td,
.no-sticky-table .sticky-table-first-td-expanded-position tr > td {
  height: auto !important;
}
.no-sticky-table .sticky-table-first-td tfoot > tr > td:first-child,
.no-sticky-table .sticky-table-first-td-condensed tfoot > tr > td:first-child,
.no-sticky-table .sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child,
.no-sticky-table .sticky-table-first-td-expanded tfoot > tr > td:first-child,
.no-sticky-table .sticky-table-first-td-expanded-position tfoot > tr > td:first-child,
.no-sticky-table .sticky-table-first-td tbody > tr > td:first-child,
.no-sticky-table .sticky-table-first-td-condensed tbody > tr > td:first-child,
.no-sticky-table .sticky-table-first-td-ultra-condensed tbody > tr > td:first-child,
.no-sticky-table .sticky-table-first-td-expanded tbody > tr > td:first-child,
.no-sticky-table .sticky-table-first-td-expanded-position tbody > tr > td:first-child,
.no-sticky-table .sticky-table-first-td thead > tr > th:first-child,
.no-sticky-table .sticky-table-first-td-condensed thead > tr > th:first-child,
.no-sticky-table .sticky-table-first-td-ultra-condensed thead > tr > th:first-child,
.no-sticky-table .sticky-table-first-td-expanded thead > tr > th:first-child,
.no-sticky-table .sticky-table-first-td-expanded-position thead > tr > th:first-child {
  z-index: inherit !important;
  width: auto !important;
  left: 0;
  position: relative !important;
}
.no-user-avatar {
  display: inline-block;
  height: 15px;
  width: 15px;
  line-height: 1.1;
  border-radius: 3px;
  background: #413e63;
  color: #ffffff;
  font-size: 14px;
  font-family: 'Bold', sans-serif !important;
  text-transform: uppercase !important;
  text-align: center !important;
}
.big-user-avatar {
  font-size: 14px;
  border-radius: 3px;
  display: inline;
}
.big-user-avatar img {
  height: 15px;
  width: 15px;
  border-radius: 4px !important;
}
.big-user-avatar img {
  height: 100px;
  width: 100px;
}
.big-no-user-avatar {
  height: 15px;
  width: 15px;
  line-height: 1.1;
  border-radius: 3px;
  background: #413e63;
  color: #ffffff;
  font-size: 14px;
  font-family: 'Bold', sans-serif !important;
  text-transform: uppercase !important;
  text-align: center !important;
  display: inline-block;
  height: 100px;
  width: 100px;
  font-size: calc(14px * 5);
  line-height: 1.3 !important;
}
::selection {
  background: #c7c7b4;
}
::-moz-selection {
  background: #c7c7b4;
}
.initial-transform {
  text-transform: initial !important;
}
.center-block {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
}
.logo {
  width: 70px;
  height: 35px;
  position: absolute;
  left: 2px;
  top: 2px;
  margin-left: 2px;
  border: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.logo-login {
  margin: auto;
  width: 200px;
  height: 150px;
  display: block;
}
.start-logo-login {
  height: 100px;
  background-size: 65% !important;
}
.cabinets-login .cabinets-header {
  color: #ffffff;
  font-size: 2em;
  font-family: 'Light', sans-serif !important;
}
.cabinets-login .logo-login {
  width: 150px;
  height: 100px;
  display: inline-block;
  vertical-align: bottom;
}
/* WebKit Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.custom-scroll-table .overflow-x::-webkit-scrollbar,
.custom-scroll-table .ul-inline-row::-webkit-scrollbar,
.custom-scroll-table .table-container-wrapper::-webkit-scrollbar {
  height: 13px;
}
/* Track */
::-webkit-scrollbar-thumb {
  background: #34495e !important;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}
.small-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0 !important;
}
.main_alert {
  position: relative;
  display: block;
  top: 40px;
  z-index: 9998;
  padding: 2px;
  padding-left: 12px;
  padding-right: 12px;
  right: 0;
  left: 0;
  margin-bottom: 0 !important;
}
.main_alert p {
  margin-left: 50px;
}
.main_alert .btn {
  padding: 6px 12px !important;
}
.alert-success {
  background: #ebf1ef !important;
  color: #07422d !important;
}
.alert-success .filter-template {
  background: #ebf1ef !important;
  border-right: 0 !important;
}
.alert-success .filter-template .btn {
  border-right: 0 !important;
}
.alert-success .filter-template .btn-primary {
  background: #1bb394 !important;
  color: #fff !important;
}
.alert-success .form-control {
  border-color: #1bb394 !important;
  background: #ebf1ef !important;
  margin-bottom: 4px;
}
.alert-success .input-group-addon {
  background: #1bb394 !important;
  color: #fff !important;
}
.alert-success td.edit,
.alert-success .edit {
  background: #ebf1ef !important;
  color: #07422d !important;
}
.alert-success .label-strong {
  color: #07422d;
}
.alert-success .ui-select-container .form-control {
  border-color: #1bb394 !important;
  background: #ebf1ef !important;
}
.alert-success h1,
.alert-success h2,
.alert-success h5,
.alert-success h3,
.alert-success h4,
.alert-success h6,
.alert-success small {
  margin-top: 0;
  margin-bottom: 0;
  color: #07422d !important;
}
.alert-success.label-strong {
  color: #07422d !important;
}
.alert-contrast {
  background: #666 !important;
  color: #fff !important;
}
.alert-contrast h1,
.alert-contrast h2,
.alert-contrast h5,
.alert-contrast h3,
.alert-contrast h4,
.alert-contrast h6,
.alert-contrast span,
.alert-contrast a,
.alert-contrast li,
.alert-contrast small {
  margin-top: 0;
  margin-bottom: 0;
  color: #fff !important;
}
.alert-contrast li {
  font-family: 'Bold', sans-serif !important;
}
.alert-default {
  background: #f9f9f7 !important;
}
.alert-default .label-strong {
  color: #000000;
}
.alert-default h1,
.alert-default h2,
.alert-default h5,
.alert-default h3,
.alert-default h4,
.alert-default h6,
.alert-default small {
  margin-top: 0;
  margin-bottom: 0;
}
.alert-warning {
  color: #a5a293 !important;
  background: #fcf8e3 !important;
}
.alert-warning h1,
.alert-warning h2,
.alert-warning h5,
.alert-warning h3,
.alert-warning h4,
.alert-warning h6,
.alert-warning small {
  color: #a5a293 !important;
  margin-top: 0;
  margin-bottom: 0;
}
.alert-filter {
  top: 15px;
  left: 0px;
  z-index: 9999 !important;
  overflow-x: auto;
  padding: 6px;
  max-width: 450px;
}
.alert-filter label {
  margin-left: 0;
}
.alert-filter .main-table tr td:last-child {
  text-align: left;
}
.alert-filter .main-table tr td {
  padding: 2px;
  border: 0 !important;
}
.navbar-brand {
  padding: 0;
  margin-top: 3px;
  margin-left: 10px;
}
.clients-logo img {
  max-height: 33px;
  display: inline;
}
.clients-logo-start {
  display: block;
}
.clients-logo-start img {
  max-height: 150px;
  margin: 20px;
}
.client-name-header {
  margin: 0;
  margin-left: 2px;
  display: inline;
  padding: 0;
  font-size: 14px !important;
}
.client-name-header .label {
  color: #000000 !important;
  font-family: 'Bold', sans-serif !important;
}
.client-name-header-dark .label {
  color: #fff !important;
  font-size: calc(14px / 1.3) !important;
}
/*LEFT NAVBAR*/
.navbar-fixed-left {
  width: auto;
  position: fixed;
  border-radius: 0;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}
.navbar-fixed-left:hover {
  overflow-y: auto;
}
.navbar-fixed-left .logo {
  width: 40px;
}
.navbar-fixed-left .navbar-collapse {
  padding-right: 0 !important;
}
.navbar-fixed-left .navbar-nav {
  width: 45px;
  transition: all 0.5s ease-in-out;
  position: relative;
  margin-left: 0 !important;
}
.navbar-fixed-left .navbar-brand {
  padding: 0 !important;
}
.navbar-fixed-left .navbar-nav:hover {
  width: 200px;
  position: relative;
  transition: all 0.5s ease-in-out;
}
.navbar-fixed-left .navbar-nav:hover li {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left: 10px solid !important;
}
.navbar-fixed-left .navbar-nav:not(:hover) {
  transition: all 0.5s ease-in-out;
  position: relative;
}
.navbar-fixed-left .navbar-nav > li {
  float: none;
  width: 100% !important;
  clear: both;
  display: block;
  height: 40px !important;
  overflow-x: hidden;
  overflow-y: hidden;
  border-left: 6px solid;
  font-family: 'Bold', sans-serif !important;
}
.navbar-fixed-left:hover .navbar-nav > li {
  border-bottom: 1px solid #243240 !important;
  border-top: 1px solid #243240 !important;
}
.navbar-fixed-left .navbar-nav > li:hover {
  border-left: 20px solid !important;
}
.navbar-fixed-left .navbar-nav > li:hover a {
  background: #395067 !important;
}
/* colorization of navbar */
.navbar-fixed-left .navbar-nav > li.info_tab {
  border-left-color: #81a8af !important;
}
.navbar-fixed-left .navbar-nav > li.prices_tab {
  border-left-color: #d4b668 !important;
}
.navbar-fixed-left .navbar-nav > li.logistics_tab {
  border-left-color: #d49091 !important;
}
.navbar-fixed-left .navbar-nav > li.clients_tab {
  border-left-color: #81af9f !important;
}
.navbar-fixed-left .navbar-nav > li.payments_tab {
  border-left-color: #9bbacd !important;
}
.navbar-fixed-left .navbar-nav > li.pl_tab {
  border-left-color: #cdaf9b !important;
}
.navbar-fixed-left .navbar-nav > li.crm_tab {
  border-left-color: #9597b1 !important;
}
.navbar-fixed-left .navbar-nav > li.info_tab.active a {
  background: #81a8af !important;
}
.navbar-fixed-left .navbar-nav > li.prices_tab.active a {
  background: #d4b668 !important;
}
.navbar-fixed-left .navbar-nav > li.logistics_tab.active a {
  background: #d49091 !important;
}
.navbar-fixed-left .navbar-nav > li.clients_tab.active a {
  background: #81af9f !important;
}
.navbar-fixed-left .navbar-nav > li.payments_tab.active a {
  background: #9bbacd !important;
}
.navbar-fixed-left .navbar-nav > li.pl_tab.active a {
  background: #cdaf9b !important;
}
.navbar-fixed-left .navbar-nav > li.crm_tab.active a {
  background: #9597b1 !important;
}
.navbar-fixed-left .navbar-nav > li > a {
  height: 100% !important;
  width: 100% !important;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 13px !important;
}
.navbar-fixed-left .navbar-nav > li.active > a {
  border-color: #f5f5f5 !important;
}
.navbar-fixed-left .navbar-nav > li.active > a > span {
  display: none !important;
}
.navbar-fixed-left .navbar-nav:hover > li.active > a > span {
  display: inline-block !important;
}
.navbar-fixed-left .navbar-nav:hover > li > a {
  text-align: left;
}
.navbar-fixed-left .navbar-nav > li > a > .fa {
  vertical-align: top !important;
}
.navbar-fixed-left .navbar-nav > li > a > span {
  visibility: visible !important;
  display: inline;
  margin-left: 25px;
  font-size: calc(14px / 1.3) !important;
  bottom: 0px !important;
}
.navbar-fixed-left .navbar-nav:hover > li > a > span {
  right: 0;
  margin-left: 10px;
  bottom: 3px !important;
}
.navbar-fixed-left .navbar-nav > li:hover > a > span {
  margin-left: 10px;
  font-size: calc(14px / 1.3) !important;
}
.navbar-fixed-left + .container {
  padding-left: 160px;
}
.if-navbar-left .central-container {
  padding-top: 0 !important;
  padding-right: 15px;
  padding-left: 60px;
}
.if-navbar-top .central-container {
  padding-top: 35px !important;
  padding-right: 35px;
  padding-left: 35px;
}
.if-navbar-top .central-container.gt-detail-page {
  padding-top: 35px !important;
  padding-right: 15px;
  padding-left: 15px;
}
.if-navbar-left .left-sidebar {
  padding-left: 50px;
  padding-top: 45px;
}
.if-navbar-top .left-sidebar {
  padding-left: 10px;
  padding-top: 45px;
}
.if-navbar-left .contract-page {
  padding-left: 10px !important;
}
.if-navbar-top .contract-page {
  padding-left: 10px !important;
}
/* On using dropdown menu (To right shift popuped) */
.navbar-fixed-left .navbar-nav > li > .dropdown-menu {
  margin-top: -50px;
  margin-left: 140px;
}
.navbar-fixed-left .navbar-header {
  float: none;
  clear: both;
}
.navbar-header {
  width: 100%;
}
.navbar-top-grouped .open > a,
.navbar-top-grouped .open > a:focus,
.navbar-top-grouped .open > a:hover,
.navbar-top-grouped li > a:focus,
.navbar-top-grouped li > a:hover,
.navbar-top-grouped li > a:active,
.navbar-top-grouped li > a:visited {
  background-color: transparent !important;
}
.navbar-user-dark .label,
.navbar-user-dark div,
.navbar-user-dark .fa {
  color: #fff !important;
}
.if-navbar-left .header-margin-top {
  margin-top: 0 !important;
}
.navbar.navbar-default.header.navbar-fixed-left {
  border: 0 !important;
}
.navbar.navbar-default.header.navbar-fixed-left .no-user-avatar,
.navbar.navbar-default.header.navbar-fixed-left .user-avatar {
  height: 35px;
  width: 35px;
  line-height: 2.5;
  display: block;
  float: none;
  margin: 0 auto;
}
.navbar.navbar-default.header.navbar-fixed-left .logo {
  width: 35px;
}
.navbar-user-dark > .open > a,
.navbar-user-dark > .open > a:focus,
.navbar-user-dark > .open > a:hover,
.navbar-user-dark > li > a:focus,
.navbar-user-dark > li > a:hover {
  background-color: transparent !important;
}
.if-navbar-left.navbar-user {
  background: #eeedeb url(img/top-bg-white.png) !important;
  display: none;
}
.if-navbar-left.navbar-user li > a {
  color: #666b72 !important;
}
.navbar-default .navbar-nav > li > a {
  color: #c0c0c0;
  width: 75px;
  overflow: hidden;
  height: 55px;
  font-size: 14px;
  text-align: center;
}
.navbar-default .navbar-nav > li > a span {
  clear: both;
  visibility: hidden;
  position: absolute;
  bottom: -299px;
}
.navbar-default .navbar-nav > li:hover,
.navbar-default .navbar-nav > li.active a,
.navbar-default .navbar-nav > li > a:focus {
  width: auto;
  height: 55px;
  font-size: 14px;
}
.navbar-default .navbar-nav > li:hover span,
.navbar-default .navbar-nav > li.active a span,
.navbar-default .navbar-nav > li > a:focus span {
  visibility: visible;
  position: relative;
  bottom: 0px;
}
.navbar-default .navbar-nav > li.active a,
.navbar-default .navbar-nav > li > a:focus {
  color: #eeedeb !important;
}
.navbar-default .navbar-nav:hover li span {
  visibility: visible;
  position: relative;
  bottom: 0px;
}
.dropdown-menu li a:hover {
  background: #f9f9f7 !important;
}
.ui-select-match,
.ui-select-container {
  outline: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
}
.ui-select-match::-webkit-input-placeholder,
.ui-select-container::-webkit-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.ui-select-match::-moz-placeholder,
.ui-select-container::-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.ui-select-match:-moz-placeholder,
.ui-select-container:-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.ui-select-match:-ms-input-placeholder,
.ui-select-container:-ms-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.ui-select-match .form-control,
.ui-select-container .form-control {
  color: #666b72;
  margin-top: 0px;
  border: 0;
  outline: 0;
  cursor: pointer;
  box-shadow: none !important;
  border-radius: 4px !important;
  background: #beb9b9 !important;
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  height: auto;
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search::-webkit-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search::-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search:-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search:-ms-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.ui-select-bootstrap .ui-select-choices-row > span {
  line-height: 1;
  white-space: normal;
  padding: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 1px solid #beb9b9 !important;
  font-family: 'Regular', sans-serif !important;
  -webkit-text-stroke: 0;
}
.ui-select-bootstrap .ui-select-choices-row > a {
  text-align: left !important;
  font-size: 14px !important;
}
.ui-select-bootstrap .ui-select-choices-row.active > a {
  background: #eeeee8 !important;
  color: #ffffff !important;
}
input.form-control.ui-select-search::before {
  font-family: 'FontAwesome';
  content: '&#128269;';
  font-size: calc(14px / 1.3);
}
.navbar-right li > a {
  font-size: calc(14px * 1.1) !important;
  width: auto !important;
  padding-bottom: 5px;
}
/*GROUPED NAVBAR*/
.grouped-navbar .collapse-button {
  border-left: 0 !important;
}
.grouped-navbar .collapse-button a {
  border: 0 !important;
}
.grouped-navbar:hover .navbar-nav > li {
  border-top: 0 !important;
}
.grouped-navbar li.info_tab.half-active a {
  border-left: 8px solid #81a8af !important;
  color: #81a8af !important;
}
.grouped-navbar li.info_tab.half-active.active a {
  color: #ffffff !important;
}
.grouped-navbar li.info_tab.inside-navbar {
  border-left: 3px solid #81a8af !important;
}
.grouped-navbar li.prices_tab.half-active a {
  border-left: 8px solid #d4b668 !important;
  color: #d4b668 !important;
}
.grouped-navbar li.prices_tab.inside-navbar {
  border-left: 3px solid #d4b668 !important;
}
.grouped-navbar li.logistics_tab.half-active a {
  border-left: 8px solid #d49091 !important;
  color: #d49091 !important;
}
.grouped-navbar li.logistics_tab.inside-navbar {
  border-left: 3px solid #d49091 !important;
}
.grouped-navbar li.clients_tab.half-active a {
  border-left: 8px solid #81af9f !important;
  color: #81af9f !important;
}
.grouped-navbar li.clients_tab.inside-navbar {
  border-left: 3px solid #81af9f !important;
}
.grouped-navbar li.payments_tab.half-active a {
  border-left: 8px solid #9bbacd !important;
  color: #9bbacd !important;
}
.grouped-navbar li.payments_tab.inside-navbar {
  border-left: 3px solid #9bbacd !important;
}
.grouped-navbar li.pl_tab.half-active a {
  border-left: 8px solid #cdaf9b !important;
  color: #cdaf9b !important;
}
.grouped-navbar li.pl_tab.inside-navbar {
  border-left: 3px solid #cdaf9b !important;
}
.grouped-navbar li.crm_tab.half-active a {
  border-left: 8px solid #9597b1 !important;
  color: #9597b1 !important;
}
.grouped-navbar li.crm_tab.inside-navbar {
  border-left: 3px solid #9597b1 !important;
}
.navbar-user .navbar-top-grouped {
  float: left;
  margin-left: 10px !important;
  right: initial !important;
}
.navbar-user .navbar-top-grouped li.inside-navbar {
  border-right: 0 !important;
}
.navbar-user .navbar-top-grouped li > a {
  color: #fff;
}
.navbar-user .navbar-top-grouped li:last-child {
  border-right: 0 !important;
}
.navbar-user .navbar-top-grouped li:nth-last-child(2) {
  border-right: 0 !important;
}
.navbar-user .navbar-top-grouped li.inside-navbar > a {
  color: #393a40;
}
.navbar-user .navbar-top-grouped li > a span {
  position: relative !important;
  display: inline-block;
  visibility: visible !important;
  bottom: 0 !important;
  font-family: 'Regular', sans-serif !important;
  font-size: 14px !important;
}
.navbar-user .navbar-top-grouped li.info_tab.half-active a {
  border-bottom: 4px solid #81a8af !important;
}
.navbar-user .navbar-top-grouped li.prices_tab.half-active a {
  border-bottom: 4px solid #d4b668 !important;
}
.navbar-user .navbar-top-grouped li.logistics_tab.half-active a {
  border-bottom: 4px solid #d49091 !important;
}
.navbar-user .navbar-top-grouped li.pl_tab.half-active a {
  border-bottom: 4px solid #cdaf9b !important;
}
.navbar-user .navbar-top-grouped li.crm_tab.half-active a {
  border-bottom: 4px solid #9597b1 !important;
}
.navbar-user .navbar-top-grouped li.clients_tab.half-active a {
  border-bottom: 4px solid #81af9f !important;
}
.navbar-user .navbar-top-grouped li.payments_tab.half-active a {
  border-bottom: 4px solid #9bbacd !important;
}
.navbar-user .navbar-top-grouped li.info_tab.half-active.active > a {
  background: #81a8af !important;
}
.navbar-user .navbar-top-grouped li.prices_tab.half-active.active > a {
  background: #d4b668 !important;
}
.navbar-user .navbar-top-grouped li.logistics_tab.half-active.active > a {
  background: #d49091 !important;
}
.navbar-user .navbar-top-grouped li.pl_tab.half-active.active > a {
  background: #cdaf9b !important;
}
.navbar-user .navbar-top-grouped li.crm_tab.half-active.active > a {
  background: #9597b1 !important;
}
.navbar-user .navbar-top-grouped li.clients_tab.half-active.active > a {
  background: #81af9f !important;
}
.navbar-user .navbar-top-grouped li.payments_tab.half-active.active > a {
  background: #9bbacd !important;
}
.navbar-user .navbar-top-grouped li.info_tab.inside-navbar a {
  border-left: 6px solid #81a8af !important;
}
.navbar-user .navbar-top-grouped li.prices_tab.inside-navbar a {
  border-left: 6px solid #d4b668 !important;
}
.navbar-user .navbar-top-grouped li.logistics_tab.inside-navbar a {
  border-left: 6px solid #d49091 !important;
}
.navbar-user .navbar-top-grouped li.pl_tab.inside-navbar a {
  border-left: 6px solid #cdaf9b !important;
}
.navbar-user .navbar-top-grouped li.crm_tab.inside-navbar a {
  border-left: 6px solid #9597b1 !important;
}
.navbar-user .navbar-top-grouped li.clients_tab.inside-navbar a {
  border-left: 6px solid #81af9f !important;
}
.navbar-user .navbar-top-grouped li.payments_tab.inside-navbar a {
  border-left: 6px solid #9bbacd !important;
}
.navbar-user .navbar-top-grouped li.inside-navbar:hover a {
  border-left-width: 10px !important;
  background: transparent !important;
}
.navbar-user .navbar-top-grouped li.half-active.active li.inside-navbar a {
  color: #393a40 !important;
}
.navbar-user .navbar-top-grouped li.info_tab.inside-navbar.active > a {
  color: #ffffff !important;
  background: #81a8af !important;
}
.navbar-user .navbar-top-grouped li.prices_tab.inside-navbar.active > a {
  color: #ffffff !important;
  background: #d4b668 !important;
}
.navbar-user .navbar-top-grouped li.logistics_tab.inside-navbar.active > a {
  color: #ffffff !important;
  background: #d49091 !important;
}
.navbar-user .navbar-top-grouped li.pl_tab.inside-navbar.active > a {
  color: #ffffff !important;
  background: #cdaf9b !important;
}
.navbar-user .navbar-top-grouped li.crm_tab.inside-navbar.active > a {
  color: #ffffff !important;
  background: #9597b1 !important;
}
.navbar-user .navbar-top-grouped li.clients_tab.inside-navbar.active > a {
  color: #ffffff !important;
  background: #81af9f !important;
}
.navbar-user .navbar-top-grouped li.payments_tab.inside-navbar.active > a {
  color: #ffffff !important;
  background: #9bbacd !important;
}
.navbar-user .navbar-top-grouped li.half-active:hover a {
  border-bottom-width: 6px !important;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.navbar-user .navbar-top-grouped li.half-active li.inside-navbar a {
  border-bottom: 0 !important;
  border-right: 0 !important;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  color: #fff;
}
/*TOP USER NAVBAR*/
.navbar-user {
  top: 0 !important;
  margin-top: 0 !important;
  min-height: 40px;
  border-bottom: 0;
}
.navbar-user .logo {
  width: 40px;
  top: 0 !important;
}
.navbar-user .user-dropdown {
  position: relative;
  float: right;
  font-family: 'Semibold', sans-serif !important;
}
.navbar-user .user-dropdown .dropdown-menu {
  right: 0;
  left: inherit;
}
.navbar-user .no-user-avatar,
.navbar-user .user-avatar,
.navbar-user .user-avatar img {
  height: 20px;
  width: 20px;
  line-height: 1.8;
}
.navbar-user li {
  border: 0 !important;
  height: 40px !important;
  min-width: 40px !important;
  display: block;
}
.navbar-user li > a {
  font-size: 14px !important;
  width: auto !important;
  padding: 0;
  border: 0 !important;
  height: 40px !important;
  min-width: 40px !important;
  border-radius: 0 !important;
  line-height: 3;
  padding-left: 10px !important;
  padding-right: 10px !important;
  color: #666b72;
}
.navbar-user li > a .fa {
  font-size: calc(14px / 1.3) !important;
  line-height: 2;
}
.navbar-user .user-dropdown-li .fa {
  font-size: 14px !important;
  line-height: 2.5;
}
.navbar-user .user-dropdown-li input[type='checkbox'],
.navbar-user .user-dropdown-li input[type='radio'] {
  height: 20px !important;
  width: 20px !important;
  margin-right: 5px;
}
.navbar-user .dropdown-menu {
  min-width: 260px;
}
.navbar-user .dropdown-menu li {
  height: 25px !important;
}
.navbar-user .dropdown-menu li > a {
  font-size: 14px !important;
  height: 25px !important;
  line-height: 1.7;
}
.navbar-user .dropdown-menu li > a .fa {
  line-height: 1;
}
.navbar-user .pagination li {
  display: inline !important;
}
.navbar-user .table-template td:first-child {
  color: #666b72 !important;
}
.navbar-user .dropdown-menu .divider {
  height: 2px !important;
  margin: 0;
  display: block;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  color: #beb9b9;
  border: 1px solid #beb9b9 !important;
  background: #fff !important;
  border-color: transparent !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}
.table-container-wrapper,
.ul-inline-row,
.analytics-container,
.well {
  border-radius: 8px;
}
.table-container-wrapper .pagination > .active > a,
.ul-inline-row .pagination > .active > a,
.analytics-container .pagination > .active > a,
.well .pagination > .active > a,
.table-container-wrapper .pagination > .active > a:focus,
.ul-inline-row .pagination > .active > a:focus,
.analytics-container .pagination > .active > a:focus,
.well .pagination > .active > a:focus,
.table-container-wrapper .pagination > .active > a:hover,
.ul-inline-row .pagination > .active > a:hover,
.analytics-container .pagination > .active > a:hover,
.well .pagination > .active > a:hover,
.table-container-wrapper .pagination > .active > span,
.ul-inline-row .pagination > .active > span,
.analytics-container .pagination > .active > span,
.well .pagination > .active > span,
.table-container-wrapper .pagination > .active > span:focus,
.ul-inline-row .pagination > .active > span:focus,
.analytics-container .pagination > .active > span:focus,
.well .pagination > .active > span:focus,
.table-container-wrapper .pagination > .active > span:hover,
.ul-inline-row .pagination > .active > span:hover,
.analytics-container .pagination > .active > span:hover,
.well .pagination > .active > span:hover {
  background: #34495e !important;
  color: #fff !important;
  border-color: transparent !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}
.table-container-wrapper .page-total-analytics-container,
.ul-inline-row .page-total-analytics-container,
.analytics-container .page-total-analytics-container,
.well .page-total-analytics-container {
  margin-bottom: 10px;
  text-align: left;
}
.table-container-wrapper .start-page-header,
.ul-inline-row .start-page-header,
.analytics-container .start-page-header,
.well .start-page-header {
  font-size: 18px;
}
.table-container-wrapper .start-page-header small,
.ul-inline-row .start-page-header small,
.analytics-container .start-page-header small,
.well .start-page-header small {
  font-size: 12px;
}
.nav-tabs {
  border-bottom: 1px solid transparent !important;
  width: 100%;
}
.nav-tabs li {
  font-size: 14px !important;
  cursor: pointer;
  color: #000000 !important;
}
.nav-tabs a {
  font-size: 14px !important;
  cursor: pointer;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background: transparent !important;
  margin-right: 5px !important;
  font-family: 'Regular', sans-serif !important;
}
.nav-tabs a:hover {
  background: #ffffff !important;
}
.nav-tabs li.active a {
  background: #fff !important;
  color: #000000 !important;
  font-size: 14px !important;
  bottom: -2px !important;
}
.nav-tabs .month-selector a {
  background: transparent !important;
}
.nav-tabs .month-selector a:hover {
  background: transparent !important;
}
.main-nav-tabs {
  border-bottom: 0 !important;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.main-nav-tabs .label_start {
  margin-left: 5px;
}
.main-nav-tabs li {
  margin-bottom: 0;
  float: none;
  display: inline-block;
}
.main-nav-tabs .payment-details-container {
  display: none;
}
.main-nav-tabs li > a {
  font-size: 14px !important;
  cursor: pointer;
  padding-bottom: 4px !important;
  padding-top: 4px !important;
  padding-right: 4px;
  padding-left: 4px;
  font-family: 'Light', sans-serif !important;
}
.main-nav-tabs li > a::first-letter {
  text-transform: uppercase !important;
}
.main-nav-tabs li.active a {
  background: #eeedeb url(img/top-bg-white.png) !important;
  border-bottom-color: #eeedeb !important;
  box-shadow: none !important;
  font-size: 14px !important;
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}
.nav-tabs-cut .thead-filter-btn {
  display: block !important;
}
.nav-tabs-cut li > a {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.nav-tabs-cut li.active > a span {
  display: inline-block;
}
.nav-tabs::-webkit-scrollbar {
  height: 0;
}
.nav-tabs > li > a {
  border-radius: 0.45em 0.45em 0 0;
}
.table-nav-tabs {
  border-bottom: 1px solid #e8e6e6 !important;
}
.table-nav-tabs a {
  padding: 4px !important;
  color: #000000 !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  font-family: 'Light', sans-serif !important;
}
.table-nav-tabs li.active a {
  border: 0 !important;
  border-bottom: 2px solid #1763ad !important;
  bottom: 0 !important;
  font-size: 14px !important;
  border-top-left-radius: 0.25em !important;
  border-top-right-radius: 0.25em !important;
  font-family: 'Regular', sans-serif !important;
}
.table-nav-tabs li {
  margin-right: 10px;
}
.no-nav-tabs .table-nav-tabs {
  display: none;
}
.nav-tabs.subtabs-container li > a,
.nav-tabs.subtabs-container li a,
.nav-tabs.subtabs-container a {
  line-height: 1 !important;
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.nav-tabs.subtabs-container li.active a {
  font-size: calc(14px / 1.3) !important;
  bottom: -3px !important;
}
@-moz-document url-prefix() {
  .reports-dropdown li > a {
    display: inline-block !important;
  }
}
.prices-nav {
  width: 95% !important;
  margin-top: 140px;
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
}
.prices-nav li a {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.modal-nav-tabs {
  border-bottom: 0 !important;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.modal-nav-tabs .label_start {
  margin-left: 5px;
}
.modal-nav-tabs li {
  margin-bottom: 0;
  float: none;
  display: inline-block;
}
.modal-nav-tabs .payment-details-container {
  display: none;
}
.modal-nav-tabs li > a {
  font-size: 14px !important;
  cursor: pointer;
  padding-bottom: 4px !important;
  padding-top: 4px !important;
  padding-right: 4px;
  padding-left: 4px;
  font-family: 'Light', sans-serif !important;
}
.modal-nav-tabs li > a::first-letter {
  text-transform: uppercase !important;
}
.modal-nav-tabs li.active a {
  background: #eeedeb url(img/top-bg-white.png) !important;
  border-bottom-color: #eeedeb !important;
  box-shadow: none !important;
  font-size: 14px !important;
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}
.modal-nav-tabs li.active a {
  background: #eeedeb !important;
  border-bottom-color: #eeedeb !important;
  bottom: -2px !important;
}
.modal-nav-tabs li > a {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.main-nav-tabs-colored li.active a {
  background: #fff !important;
  border-bottom-color: #fff !important;
}
.nav-tabs-condensed li > a {
  padding-left: 4px;
  padding-right: 4px;
}
.nav-tabs-condensed li {
  margin-bottom: 0 !important;
}
.nav-tabs-condensed li.active {
  margin-bottom: -1px !important;
}
.global-search-container .ui-select-container {
  padding: 2px;
}
.global-search-container .label {
  padding: 2px;
  color: #000000 !important;
  margin-right: 5px;
}
.global-search-container .ui-select-bootstrap > .ui-select-match > .btn,
.global-search-container .form-control {
  height: 30px !important;
  padding: 6px !important;
  color: #fff !important;
  border: 0 !important;
  border-radius: 0 !important;
  width: 50px !important;
  border-bottom: 1px solid #e8e6e6 !important;
  overflow: hidden;
}
.global-search-container .form-control:focus {
  min-width: 150px !important;
  border-bottom: 1px solid #e8e6e6 !important;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.global-search-container .ui-select-container .form-control {
  min-width: 150px !important;
  color: #ffffff !important;
  border-radius: 0 !important;
}
.global-search-container .text-muted {
  color: #ffffff !important;
}
.global-search-container .dropdown-menu li {
  height: auto !important;
  max-height: 400px !important;
}
.trader-nav-tabs {
  display: inline-flex;
}
.trader-nav-tabs li {
  display: inline-block;
}
.trader-nav-tabs li a {
  padding-left: 4px;
  padding-right: 4px;
}
.trader-nav-tabs li a .label_start {
  background: transparent !important;
}
.details-nav-tabs {
  display: block;
  margin-top: 0 !important;
}
.details-nav-tabs li,
.details-nav-tabs span,
.details-nav-tabs a {
  text-align: left;
  border: 0 !important;
}
.details-nav-tabs li,
.details-nav-tabs a {
  display: block;
  width: 100%;
  border: 0 !important;
  border-radius: 4px !important;
  font-size: calc(14px / 1.3) !important;
}
.details-nav-tabs a {
  padding: 6px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-family: 'Bold', sans-serif !important;
  text-transform: uppercase;
  border-bottom: 1px solid #e8e6e6 !important;
}
.details-nav-tabs a .label_start {
  background: transparent !important;
}
.details-nav-tabs li:last-child a {
  border-bottom: 0 !important;
}
.details-nav-tabs li.active a {
  border: 0 !important;
  border-bottom: 1px solid #e8e6e6 !important;
  box-shadow: rgba(8, 40, 100, 0.15);
}
.user-dropdown:hover li span {
  visibility: visible;
  position: relative;
  bottom: 0px !important;
}
.item-settings-dropdown {
  padding: 1px;
  float: none;
  text-align: left;
  display: inline-flex;
}
.item-settings-dropdown .show-menu.dropdown-menu {
  display: block !important;
  left: calc(100% + 1px) !important;
  overflow: visible;
}
.item-settings-dropdown a:nth-of-type(1) {
  padding: 0;
  line-height: 1 !important;
  vertical-align: middle;
  -webkit-text-stroke: 0.2px #000;
  font-family: 'Regular', sans-serif !important;
}
.item-settings-dropdown a:nth-of-type(1)::first-letter {
  text-transform: uppercase !important;
}
.item-settings-dropdown li:hover > a {
  opacity: 0.8;
}
.item-settings-dropdown .dropdown-menu {
  padding: 20px !important;
  border-radius: 8px !important;
  border: 0 !important;
  min-width: 300px;
  overflow: hidden;
}
.item-settings-dropdown .dropdown-menu .btn,
.item-settings-dropdown .dropdown-menu a {
  padding: 6px !important;
  font-size: calc(14px / 1.2) !important;
  -webkit-text-stroke: 0;
  line-height: 1.1;
  border-bottom: 1px solid #e8e6e6 !important;
  color: #000000 !important;
  font-family: 'Regular', sans-serif !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  white-space: normal;
}
.item-settings-dropdown .dropdown-menu .btn:hover,
.item-settings-dropdown .dropdown-menu a:hover {
  background: rgba(0, 0, 0, 0.08) !important;
}
.item-settings-dropdown .dropdown-menu .btn .fa,
.item-settings-dropdown .dropdown-menu a .fa {
  font-size: calc(14px / 1.3);
  margin-right: 15px;
}
.item-settings-dropdown .dropdown-menu li:last-child .btn,
.item-settings-dropdown .dropdown-menu li:last-child a {
  border-bottom: 0 !important;
}
.item-settings-dropdown .dropdown-menu .horizontal-divider {
  margin-top: 0;
  margin-bottom: 0;
}
.item-settings-dropdown .dropdown-menu.wrapped-title {
  top: -15px !important;
}
.item-settings-dropdown .tooltip:hover::before {
  display: none !important;
  content: none;
}
.item-settings-dropdown .long-title {
  width: 250px;
  white-space: wrap;
}
.hover-nav:hover .dropdown-menu,
.item-settings-dropdown:hover .dropdown-menu {
  display: block;
  height: auto;
  overflow: visible;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.hover-nav:hover .ui-select-choices-content,
.item-settings-dropdown:hover .ui-select-choices-content {
  overflow: auto;
}
.counterparties-dropdown {
  width: 100%;
}
.counterparties-dropdown .btn {
  font-size: 14px;
  color: #909597;
  padding: 2px;
}
.counterparties-dropdown .btn .fa {
  font-size: 14px;
}
.counterparties-dropdown .btn .label_start {
  float: right;
  padding: 0 !important;
}
.counterparties-dropdown li:nth-child(1),
.counterparties-dropdown li:nth-child(6),
.counterparties-dropdown li:nth-last-child(3) {
  margin-bottom: 10px;
  border-bottom: 1px solid #e8e6e6 !important;
}
@-moz-document url-prefix() {
  .counterparties-dropdown li > a {
    display: inline-block !important;
  }
}
.reports-dropdown {
  width: 100%;
}
.reports-dropdown .btn {
  font-size: 14px;
  color: #909597;
  padding: 2px;
}
.reports-dropdown .btn .fa {
  font-size: 14px;
}
.reports-dropdown .btn .label_start {
  float: right;
  padding: 0 !important;
}
.reports-dropdown li:nth-child(1),
.reports-dropdown li:nth-child(6),
.reports-dropdown li:nth-last-child(3) {
  margin-bottom: 10px;
  border-bottom: 1px solid #e8e6e6 !important;
}
.reports-dropdown li:nth-child(1),
.reports-dropdown li:nth-child(6),
.reports-dropdown li:nth-last-child(2) {
  margin-bottom: 0;
  border-bottom: 0 !important;
}
.crop-filter-dropdown {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
  margin-top: 0;
}
.crop-filter-dropdown .main-li {
  padding: 0 !important;
  float: none;
}
.crop-filter-dropdown .main-li a {
  padding-bottom: 5px !important;
  padding-top: 0px !important;
}
.crop-filter-dropdown .start-page-header,
.crop-filter-dropdown .pull-left {
  text-align: left !important;
}
.no-header thead,
.no-header .nav-tabs {
  display: none !important;
}
.table-fixed-layout {
  table-layout: fixed;
}
.table-fixed-layout tr td {
  white-space: normal !important;
}
.table-min-column-width tr td {
  min-width: 70px !important;
}
.table-no-borders tr td {
  padding: 2px;
  padding-left: 6px;
  padding-right: 6px;
  vertical-align: middle;
  text-align: left;
  line-height: 1;
  border-bottom: 1px solid #e8e6e6;
}
.table-no-borders tr td .alert,
.table-no-borders tr td .alert-success,
.table-no-borders tr td .alert-danger {
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: none !important;
}
.table-no-borders tr td .alert .label,
.table-no-borders tr td .alert-success .label,
.table-no-borders tr td .alert-danger .label {
  font-size: 14px !important;
  line-height: 1 !important;
  padding: 0 !important;
}
.table-no-borders tr td .property {
  font-size: calc(14px / 1.3) !important;
  line-height: 1 !important;
  font-family: 'Light', sans-serif !important;
}
.table-no-borders tr td .property span {
  line-height: 1 !important;
}
.table-no-borders tr:last-child td,
.table-no-borders tr td.no-border {
  border-bottom: 0;
}
.table-no-borders td.td-center-align {
  text-align: center !important;
}
.details-wrapper .table-with-border,
.table-with-border {
  border-collapse: collapse;
}
.details-wrapper .table-with-border tr th,
.table-with-border tr th,
.details-wrapper .table-with-border tr td,
.table-with-border tr td {
  border: 1px solid #e8e6e6 !important;
}
.details-wrapper .table-with-border tr th:last-child,
.table-with-border tr th:last-child,
.details-wrapper .table-with-border tr td:last-child,
.table-with-border tr td:last-child {
  border-right: 1px solid #e8e6e6 !important;
}
.details-wrapper .table-with-border tr th.thick-border,
.table-with-border tr th.thick-border,
.details-wrapper .table-with-border tr td.thick-border,
.table-with-border tr td.thick-border {
  border-right: 3px solid #e8e6e6 !important;
}
.table-fixed tbody {
  display: block;
  height: 200px;
  overflow: auto;
}
.table-fixed thead,
.table-fixed tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.table-fixed tr td {
  text-overflow: ellipsis;
}
.table-fixed thead {
  width: calc(100% - 1em);
}
.ui-grid-header {
  border: 0 !important;
}
.ui-grid-cell-contents {
  padding: 2px !important;
  font-family: 'Regular', sans-serif !important;
  font-size: 14px !important;
  line-height: 2;
  white-space: normal;
}
.ui-grid-cell-contents .btn {
  background: #fff !important;
  border-right: 0 !important;
}
.ui-grid-column-menu-button {
  background: #fff !important;
}
.ui-grid-render-container {
  border-radius: 4px !important;
  padding: 0px;
}
.ui-grid-header-cell {
  border-right: 0 !important;
  color: #666b72 !important;
  background: #fff !important;
  white-space: normal;
}
.dashboard-start-container-block .ui-grid {
  height: auto !important;
}
.dashboard-start-container-block .ui-grid-viewport,
.dashboard-start-container-block .ui-grid-header {
  overflow: visible !important;
  width: auto !important;
}
.dashboard-start-container-block .ui-grid-viewport ::-webkit-scrollbar,
.dashboard-start-container-block .ui-grid-header ::-webkit-scrollbar {
  display: none;
}
.dashboard-start-container-block .ui-grid-viewport {
  height: 300px;
}
.dashboard-start-container-block .no_content_text {
  padding: 0 !important;
  padding-left: 10px !important;
}
.ui-grid {
  border: 0 !important;
}
.ui-grid-header-cell-label {
  font-size: calc(14px / 1.2) !important;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Semibold', sans-serif !important;
  color: #666b72 !important;
}
.ui-grid-row:nth-child(odd) .ui-grid-cell {
  background: #fff !important;
}
.ui-grid-row:nth-child(even) .ui-grid-cell {
  background: #fff !important;
}
.ui-grid-cell {
  border-right: 0 !important;
  border-bottom: 0 !important;
  vertical-align: middle !important;
}
.ui-grid-cell img,
.ui-grid-cell .no-user-avatar {
  height: 15px;
  width: 15px;
  text-align: center;
  border-radius: 4px !important;
  display: block;
  margin: 0 auto;
  margin-top: 7px;
}
.ui-grid-cell .label {
  text-align: center;
  padding: 2px !important;
  vertical-align: bottom;
  font-size: calc(14px / 1.2) !important;
  border-radius: 0 !important;
}
.ui-grid-cell .tooltip {
  font-size: calc(14px / 1.2) !important;
  padding: 2px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  position: relative;
  display: block;
  text-overflow: ellipsis;
  line-height: 2;
  font-family: 'Regular', sans-serif !important;
}
.ui-grid-cell .negative-number {
  color: #cf6163 !important;
}
.ui-grid-cell .positive-number {
  color: #07422d !important;
}
.ui-grid-top-panel {
  background: transparent !important;
}
.btn-link.start-btn-link {
  font-size: calc(14px / 1.3) !important;
  margin-bottom: 1px;
  text-transform: lowercase !important;
  color: #1763ad !important;
}
.btn-link.start-btn-link:hover {
  text-decoration: underline;
}
.transfer-table tr td {
  border-right: 1px solid #e8e6e6 !important;
}
.transfer-container tr td input[type='checkbox'],
.transfer-container tr td input[type='radio'] {
  margin: 0 !important;
  width: 12px;
  height: 12px;
}
.cabinet-container div.ui-grid-cell input[type='checkbox'],
.passport-costs-edit div.ui-grid-cell input[type='checkbox'],
.position-report-page div.ui-grid-cell input[type='checkbox'],
.kpitrader-report-container div.ui-grid-cell input[type='checkbox'],
.transfer-container div.ui-grid-cell input[type='checkbox'],
.signings-report-page div.ui-grid-cell input[type='checkbox'],
.confirmations-report-page div.ui-grid-cell input[type='checkbox'],
.contract-group-container div.ui-grid-cell input[type='checkbox'] {
  margin: 0;
}
.cabinet-container .ui-grid-render-container,
.passport-costs-edit .ui-grid-render-container,
.position-report-page .ui-grid-render-container,
.kpitrader-report-container .ui-grid-render-container,
.transfer-container .ui-grid-render-container,
.signings-report-page .ui-grid-render-container,
.confirmations-report-page .ui-grid-render-container,
.contract-group-container .ui-grid-render-container {
  background: #fff !important;
  border-radius: 4px !important;
  padding: 20px !important;
  max-height: 100%;
}
.cabinet-container .ui-grid-viewport,
.passport-costs-edit .ui-grid-viewport,
.position-report-page .ui-grid-viewport,
.kpitrader-report-container .ui-grid-viewport,
.transfer-container .ui-grid-viewport,
.signings-report-page .ui-grid-viewport,
.confirmations-report-page .ui-grid-viewport,
.contract-group-container .ui-grid-viewport {
  overflow-y: visible !important;
}
.cabinet-container .ui-grid-header-cell,
.passport-costs-edit .ui-grid-header-cell,
.position-report-page .ui-grid-header-cell,
.kpitrader-report-container .ui-grid-header-cell,
.transfer-container .ui-grid-header-cell,
.signings-report-page .ui-grid-header-cell,
.confirmations-report-page .ui-grid-header-cell,
.contract-group-container .ui-grid-header-cell {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 0 !important;
}
.cabinet-container .ui-grid-cell,
.passport-costs-edit .ui-grid-cell,
.position-report-page .ui-grid-cell,
.kpitrader-report-container .ui-grid-cell,
.transfer-container .ui-grid-cell,
.signings-report-page .ui-grid-cell,
.confirmations-report-page .ui-grid-cell,
.contract-group-container .ui-grid-cell {
  padding: 2px;
  font-size: calc(14px / 1.3);
  overflow-y: visible !important;
  text-align: left;
  height: auto !important;
  position: relative;
  border: 0 !important;
}
.cabinet-container .ui-grid-cell .label,
.passport-costs-edit .ui-grid-cell .label,
.position-report-page .ui-grid-cell .label,
.kpitrader-report-container .ui-grid-cell .label,
.transfer-container .ui-grid-cell .label,
.signings-report-page .ui-grid-cell .label,
.confirmations-report-page .ui-grid-cell .label,
.contract-group-container .ui-grid-cell .label {
  font-size: calc(14px / 1.3);
  padding: 2px !important;
  margin: 0 !important;
  display: inline-block;
  vertical-align: middle;
}
.cabinet-container .ui-grid-cell .tooltip,
.passport-costs-edit .ui-grid-cell .tooltip,
.position-report-page .ui-grid-cell .tooltip,
.kpitrader-report-container .ui-grid-cell .tooltip,
.transfer-container .ui-grid-cell .tooltip,
.signings-report-page .ui-grid-cell .tooltip,
.confirmations-report-page .ui-grid-cell .tooltip,
.contract-group-container .ui-grid-cell .tooltip {
  padding: 0;
  line-height: 1;
}
.cabinet-container .ui-grid-cell img,
.passport-costs-edit .ui-grid-cell img,
.position-report-page .ui-grid-cell img,
.kpitrader-report-container .ui-grid-cell img,
.transfer-container .ui-grid-cell img,
.signings-report-page .ui-grid-cell img,
.confirmations-report-page .ui-grid-cell img,
.contract-group-container .ui-grid-cell img {
  margin: 0 !important;
}
.cabinet-container .ui-grid-cell:nth-child(1),
.passport-costs-edit .ui-grid-cell:nth-child(1),
.position-report-page .ui-grid-cell:nth-child(1),
.kpitrader-report-container .ui-grid-cell:nth-child(1),
.transfer-container .ui-grid-cell:nth-child(1),
.signings-report-page .ui-grid-cell:nth-child(1),
.confirmations-report-page .ui-grid-cell:nth-child(1),
.contract-group-container .ui-grid-cell:nth-child(1) {
  text-align: left;
}
.cabinet-container .ui-grid-menu .ui-grid-menu-inner ul li button,
.passport-costs-edit .ui-grid-menu .ui-grid-menu-inner ul li button,
.position-report-page .ui-grid-menu .ui-grid-menu-inner ul li button,
.kpitrader-report-container .ui-grid-menu .ui-grid-menu-inner ul li button,
.transfer-container .ui-grid-menu .ui-grid-menu-inner ul li button,
.signings-report-page .ui-grid-menu .ui-grid-menu-inner ul li button,
.confirmations-report-page .ui-grid-menu .ui-grid-menu-inner ul li button,
.contract-group-container .ui-grid-menu .ui-grid-menu-inner ul li button {
  padding: 2px;
  font-size: 14px;
  border-bottom: 1px solid #e8e6e6;
  background: #fff;
  outline: none !important;
}
.cabinet-container .ui-grid-cell-contents,
.passport-costs-edit .ui-grid-cell-contents,
.position-report-page .ui-grid-cell-contents,
.kpitrader-report-container .ui-grid-cell-contents,
.transfer-container .ui-grid-cell-contents,
.signings-report-page .ui-grid-cell-contents,
.confirmations-report-page .ui-grid-cell-contents,
.contract-group-container .ui-grid-cell-contents {
  line-height: 1;
  padding: 0;
}
.cabinet-container .ui-grid-row,
.passport-costs-edit .ui-grid-row,
.position-report-page .ui-grid-row,
.kpitrader-report-container .ui-grid-row,
.transfer-container .ui-grid-row,
.signings-report-page .ui-grid-row,
.confirmations-report-page .ui-grid-row,
.contract-group-container .ui-grid-row {
  border-top: 1px solid #e8e6e6 !important;
  height: auto !important;
  position: relative;
}
.cabinet-container .main-table tr td {
  border-right: 1px solid #f4f2f2 !important;
  padding: 2px !important;
  white-space: nowrap;
  word-wrap: break-word;
}
.cabinet-container .main-table tr td .btn,
.cabinet-container .main-table tr td .btn-xs,
.cabinet-container .main-table tr td a {
  padding: 0 !important;
  line-height: 1.1 !important;
}
.cabinet-container .main-table tr td .label {
  font-size: 100% !important;
  display: inline-block;
  margin: 0 !important;
}
.cabinet-container .main-table tr th {
  text-align: center !important;
  height: 51px !important;
  min-height: 51px !important;
}
.cabinet-container .main-table tr td:last-child {
  border-right: none !important;
}
.cabinet-container .main-table tr td.stages {
  min-width: 500px;
}
.cabinet-container .main-table tr td.td-white-space-normal {
  white-space: normal !important;
}
.cabinet-container .contract-status-block-td {
  display: inline !important;
}
.cabinet-container .contract-status-block-td .contract-status-block {
  width: auto !important;
}
.cabinet-container .ui-grid-cell .tooltip {
  font-family: 'Regular', sans-serif !important;
}
.cabinet-container .contract-status-block {
  padding: 2px !important;
  margin: 0 !important;
  max-width: 60px;
  word-break: break-word;
  white-space: normal;
  vertical-align: top;
}
.cabinet-container .ui-grid {
  height: auto !important;
}
.position-report-page .no-wrap,
.kpi-traders-container .no-wrap {
  white-space: nowrap !important;
}
.position-report-page .ui-grid-footer-aggregates-row,
.kpi-traders-container .ui-grid-footer-aggregates-row {
  height: auto !important;
  background: #f9f9f7 !important;
}
.position-report-page .ui-grid-footer-aggregates-row .ui-grid-cell-contents,
.kpi-traders-container .ui-grid-footer-aggregates-row .ui-grid-cell-contents {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Semibold', sans-serif !important;
}
.contract-group-container .ui-grid-cell {
  text-align: right;
  border-right: 1px solid #e8e6e6 !important;
}
.contract-group-container .ui-grid-cell:first-child {
  text-align: left;
}
.contract-group-container .ui-grid-header-cell {
  border-right: 1px solid #e8e6e6 !important;
}
.contract-group-container .ui-grid-header-cell:nth-last-child {
  border-right: 0 !important;
}
.contract-group-container .ui-grid {
  height: 700px !important;
}
.contract-group-container .ui-grid-viewport {
  height: 700px !important;
}
.unscrollable-grid .ui-grid {
  border: 0 !important;
  height: auto !important;
  position: relative;
  overflow-y: visible !important;
}
.unscrollable-grid .ui-grid-contents-wrapper {
  height: auto !important;
}
.unscrollable-grid .ui-grid-viewport,
.unscrollable-grid .ui-grid-header {
  width: auto !important;
}
.unscrollable-grid ::-webkit-scrollbar {
  display: none;
}
.scrollable-grid .ui-grid {
  height: 600px !important;
}
.scrollable-grid .ui-grid-viewport,
.scrollable-grid .ui-grid-header {
  width: auto !important;
}
.scrollable-grid .ui-grid-viewport {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.ui-grid-footer-aggregates-row {
  height: auto !important;
}
.ui-grid-footer-cell {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
  white-space: normal;
  padding: 2px;
  font-size: 14px !important;
}
.ui-grid-footer-panel {
  border: 0 !important;
  border-top: 1px solid #e8e6e6 !important;
  background: #fff !important;
}
.kpitrader-report-container .ui-grid-cell {
  text-align: right;
  vertical-align: middle;
  border-right: 1px solid #e8e6e6 !important;
}
.kpitrader-report-container .ui-grid-cell .label {
  padding: 2px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  vertical-align: middle;
}
.kpitrader-report-container .ui-grid-cell .badge {
  font-size: calc(14px / 1.3) !important;
}
.kpitrader-report-container .ui-grid-cell:first-child {
  text-align: left;
}
.kpitrader-report-container .ui-grid-header-cell {
  padding-right: 0;
  padding-left: 0;
}
.kpitrader-report-container .ui-grid-cell:last-child {
  border-right: 0 !important;
}
.kpitrader-report-container .ui-grid-category {
  text-align: center;
  border-right: 1px solid #e8e6e6 !important;
}
.kpitrader-report-container .ui-grid-category:last-child {
  border-right: 0 !important;
}
.kpitrader-report-container .ui-grid-footer-cell {
  text-align: right !important;
}
.table thead > tr > th,
.table tbody > tr > td,
.table tfoot > tr > td {
  border-color: #666b72;
}
table caption {
  display: none;
}
table tr th {
  word-wrap: break-word;
  white-space: normal;
}
table tr th div {
  padding: 2px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  text-align: center;
  word-wrap: break-word;
  white-space: normal;
}
table tr th .thead-filters {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
table tr th .thead-filters div {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  text-align: left;
}
table tr th .thead-filters .form-control {
  padding: 2px !important;
  min-width: 100px;
  background: #e8e6e6 !important;
  border: 0 !important;
}
table tr th .thead-filters .filter-template {
  background: transparent !important;
  border: 0 !important;
}
table tr th .thead-filters .filter-template .btn:last-child {
  background: #e8e6e6 !important;
}
table tr th .thead-filters .filter-template .btn.btn-clear:last-child {
  border: 0 !important;
}
table tr th .thead-filters .filter-template .btn {
  padding: 0 !important;
  border: 0 !important;
  margin-right: 5px;
}
table tr th .thead-filters .form-group {
  margin-bottom: 2px !important;
}
table tr th .thead-filters .input-group {
  margin-bottom: 2px !important;
}
.table tbody > tr > td,
.table tbody > tr > th {
  line-height: 1;
}
.table-template {
  border-collapse: separate;
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 0.9em;
  text-align: center;
}
.table-template tr > td {
  border-top: 1px solid #e8e6e6 !important;
  border-right: 1px solid #f8f6f6 !important;
  line-height: 1 !important;
  vertical-align: middle !important;
  -webkit-text-stroke: 0.2px #000;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.table-template tr > td .hover-element {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  transform: translate3d(0, 8px, 0);
  visibility: hidden;
}
.table-template tr > td .fa-arrow-right.hover-element {
  font-size: calc(14px / 1.4) !important;
}
.table-template tr > td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.table-template tr > td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.table-template tr > td .fa.show-on-hover {
  display: none;
}
.table-template tr > td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.table-template tr > td span {
  line-height: 1 !important;
}
.table-template tr > td .text-muted {
  line-height: 1 !important;
}
.table-template tr > td .input-group-table-edit-btn {
  display: flex;
}
.table-template tr > td .table-edit-btn {
  padding: 0 !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  margin: 0;
  line-height: 1 !important;
  margin-left: 5px;
  font-size: 14px !important;
}
.table-template tr > td .table-edit-btn .fa {
  font-size: 14px !important;
}
.table-template tr > td .table-edit-btn:first-of-type {
  margin-left: 0;
}
.table-template tr > td .info-table-btn:hover {
  border: 1px solid #1d82c7 !important;
}
.table-template tr > td .danger-table-btn:hover {
  border: 1px solid rgba(207, 97, 99, 0.7) !important;
}
.table-template tr > td .warning-table-btn:hover {
  border: 1px solid #7d6643 !important;
}
.table-template tr > td .small {
  color: #666b72 !important;
  margin-top: 5px;
  text-align: left;
}
.table-template tr > td .small .fa,
.table-template tr > td .small .updated_time,
.table-template tr > td .small a,
.table-template tr > td .small span {
  color: #666b72 !important;
  font-size: calc(14px / 1.3) !important;
}
.table-template tr > td .small .btn-link {
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.table-template tr > td .small .btn-link:hover {
  text-decoration: underline;
}
.table-template tr:hover .fa.hide-on-hover {
  display: none;
}
.table-template tr:hover .fa.show-on-hover {
  display: inline-block;
}
.table-template tr:hover td .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
  width: auto;
}
.table-template tr:hover td .fa-sort-desc.hover-element {
  font-size: calc(14px * 1.2) !important;
}
.table-template tr > th {
  border-bottom: 1px solid #e8e6e6 !important;
  border-right: 0;
  letter-spacing: var(--letter-spacing-mega, 0.05em);
}
.table-template tr > th::first-letter {
  text-transform: uppercase !important;
}
.table-template tr th.filter-th {
  background: #f9f9f7 !important;
  border-bottom: 0 !important;
}
.table-template tr:hover th.filter-th {
  background: #f9f9f7 !important;
}
.table-template tr > td:last-child,
.table-template tr > th:last-child {
  border-right: 0 !important;
}
.table-template th {
  color: #666b72;
  font-size: calc(14px / 1.3);
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
  font-family: 'Regular', sans-serif !important;
  cursor: pointer !important;
  vertical-align: middle !important;
}
.table-template th .filter-template .ui-select-match .form-control,
.table-template th .filter-template .ui-select-container .form-control,
.table-template th .form-control {
  font-size: calc(14px / 1.3) !important;
  padding: 0 !important;
  /*  height:20px !important;*/
  width: 100%;
  padding-left: 5px !important;
  max-width: 100%;
  min-width: 100%;
  border-radius: 4px !important;
}
.table-template th .fa.fa-sort {
  display: none;
}
.table-template th .filter-template .ui-select-match .form-control {
  line-height: 2.4;
  width: 100% !important;
}
.table-template th .input-group-addon {
  font-size: calc(14px / 1.3) !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-transform: none !important;
}
.table-template th .form-group {
  padding: 2px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 5px !important;
  width: 100% !important;
}
.table-template th .input-group {
  margin-bottom: 5px;
}
.table-template th select {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.table-template th .caret {
  display: none;
}
.table-template th .text-muted {
  text-transform: none !important;
  color: #000000 !important;
  line-height: 1;
}
.table-template th .big-font {
  font-size: calc(14px * 1.2) !important;
}
.table-template th .btn-link {
  -webkit-text-stroke: 0;
  padding: 0 !important;
}
.table-template th:hover {
  color: #666;
}
.table-template .filter-th:hover {
  background: #fff !important;
}
.table-template td:first-child {
  text-align: left;
  font-family: 'Regular', sans-serif !important;
}
.table-template td:first-child .btn-link {
  color: #1763ad !important;
  border-bottom: 0 !important;
  -webkit-text-stroke: 0;
}
.table-template td:first-child .btn-link:hover {
  text-decoration: underline;
}
.table-template td:first-child .btn-link .fa {
  font-size: calc(14px / 1.3) !important;
}
.table-template td {
  color: #0c4d40;
  text-align: center;
  vertical-align: top;
  border-radius: 0;
  font-family: 'Regular', sans-serif !important;
  line-height: 1;
}
.table-strip tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-strip tr td {
  border: 0;
}
.cut-contract-table {
  padding-left: 5px;
  padding-right: 5px;
}
#cut-contract-table {
  width: 80%;
}
.cut-table {
  border-collapse: separate;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 0.9em;
  text-align: center;
  font-size: 0.9em / 1.1;
  overflow: auto;
  width: 100% !important;
  border-color: #5a5e6f !important;
}
.cut-table tr > td {
  border-top: 1px solid #e8e6e6 !important;
  border-right: 1px solid #f8f6f6 !important;
  line-height: 1 !important;
  vertical-align: middle !important;
  -webkit-text-stroke: 0.2px #000;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.cut-table tr > td .hover-element {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  transform: translate3d(0, 8px, 0);
  visibility: hidden;
}
.cut-table tr > td .fa-arrow-right.hover-element {
  font-size: calc(14px / 1.4) !important;
}
.cut-table tr > td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.cut-table tr > td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.cut-table tr > td .fa.show-on-hover {
  display: none;
}
.cut-table tr > td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.cut-table tr > td span {
  line-height: 1 !important;
}
.cut-table tr > td .text-muted {
  line-height: 1 !important;
}
.cut-table tr > td .input-group-table-edit-btn {
  display: flex;
}
.cut-table tr > td .table-edit-btn {
  padding: 0 !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  margin: 0;
  line-height: 1 !important;
  margin-left: 5px;
  font-size: 14px !important;
}
.cut-table tr > td .table-edit-btn .fa {
  font-size: 14px !important;
}
.cut-table tr > td .table-edit-btn:first-of-type {
  margin-left: 0;
}
.cut-table tr > td .info-table-btn:hover {
  border: 1px solid #1d82c7 !important;
}
.cut-table tr > td .danger-table-btn:hover {
  border: 1px solid rgba(207, 97, 99, 0.7) !important;
}
.cut-table tr > td .warning-table-btn:hover {
  border: 1px solid #7d6643 !important;
}
.cut-table tr > td .small {
  color: #666b72 !important;
  margin-top: 5px;
  text-align: left;
}
.cut-table tr > td .small .fa,
.cut-table tr > td .small .updated_time,
.cut-table tr > td .small a,
.cut-table tr > td .small span {
  color: #666b72 !important;
  font-size: calc(14px / 1.3) !important;
}
.cut-table tr > td .small .btn-link {
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.cut-table tr > td .small .btn-link:hover {
  text-decoration: underline;
}
.cut-table tr:hover .fa.hide-on-hover {
  display: none;
}
.cut-table tr:hover .fa.show-on-hover {
  display: inline-block;
}
.cut-table tr:hover td .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
  width: auto;
}
.cut-table tr:hover td .fa-sort-desc.hover-element {
  font-size: calc(14px * 1.2) !important;
}
.cut-table tr > th {
  border-bottom: 1px solid #e8e6e6 !important;
  border-right: 0;
  letter-spacing: var(--letter-spacing-mega, 0.05em);
}
.cut-table tr > th::first-letter {
  text-transform: uppercase !important;
}
.cut-table tr th.filter-th {
  background: #f9f9f7 !important;
  border-bottom: 0 !important;
}
.cut-table tr:hover th.filter-th {
  background: #f9f9f7 !important;
}
.cut-table tr > td:last-child,
.cut-table tr > th:last-child {
  border-right: 0 !important;
}
.cut-table th {
  color: #666b72;
  font-size: calc(14px / 1.3);
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
  font-family: 'Regular', sans-serif !important;
  cursor: pointer !important;
  vertical-align: middle !important;
}
.cut-table th .filter-template .ui-select-match .form-control,
.cut-table th .filter-template .ui-select-container .form-control,
.cut-table th .form-control {
  font-size: calc(14px / 1.3) !important;
  padding: 0 !important;
  /*  height:20px !important;*/
  width: 100%;
  padding-left: 5px !important;
  max-width: 100%;
  min-width: 100%;
  border-radius: 4px !important;
}
.cut-table th .fa.fa-sort {
  display: none;
}
.cut-table th .filter-template .ui-select-match .form-control {
  line-height: 2.4;
  width: 100% !important;
}
.cut-table th .input-group-addon {
  font-size: calc(14px / 1.3) !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-transform: none !important;
}
.cut-table th .form-group {
  padding: 2px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 5px !important;
  width: 100% !important;
}
.cut-table th .input-group {
  margin-bottom: 5px;
}
.cut-table th select {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.cut-table th .caret {
  display: none;
}
.cut-table th .text-muted {
  text-transform: none !important;
  color: #000000 !important;
  line-height: 1;
}
.cut-table th .big-font {
  font-size: calc(14px * 1.2) !important;
}
.cut-table th .btn-link {
  -webkit-text-stroke: 0;
  padding: 0 !important;
}
.cut-table th:hover {
  color: #666;
}
.cut-table .filter-th:hover {
  background: #fff !important;
}
.cut-table td:first-child {
  text-align: left;
  font-family: 'Regular', sans-serif !important;
}
.cut-table td:first-child .btn-link {
  color: #1763ad !important;
  border-bottom: 0 !important;
  -webkit-text-stroke: 0;
}
.cut-table td:first-child .btn-link:hover {
  text-decoration: underline;
}
.cut-table td:first-child .btn-link .fa {
  font-size: calc(14px / 1.3) !important;
}
.cut-table td {
  color: #0c4d40;
  text-align: center;
  vertical-align: top;
  border-radius: 0;
  font-family: 'Regular', sans-serif !important;
  line-height: 1;
}
.cut-table .item-settings-dropdown:hover .dropdown-menu {
  top: -8px;
  left: 15px;
}
.cut-table td {
  color: #0c4d40;
  padding-left: 2px !important;
  padding-right: 2px !important;
  text-align: center;
  border-color: #e8e6e6 !important;
  width: auto !important;
  line-height: 1;
}
.cut-table tr > td {
  border-top: 1px solid #e8e6e6 !important;
}
.cut-table tr > th {
  border-bottom: 2px solid #e8e6e6 !important;
}
.cut-table th:first-child {
  text-align: center;
}
.cut-table th {
  text-align: center;
  border-color: #e8e6e6 !important;
}
.cut-table tr.selected {
  text-decoration: none !important;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.01) !important;
}
.cut-table tr.selected td {
  opacity: 1 !important;
  text-decoration: none !important;
  background: rgba(0, 0, 0, 0.01) !important;
}
.cut-table td:nth-child(1) {
  color: #0c4d40 !important;
  text-align: center;
  width: 15%;
}
.cut-table td:last-child {
  padding-right: 5px !important;
}
.cut-table span {
  margin-top: 2px !important;
}
.cut-table .lower-zero-number {
  color: #cf6163 !important;
}
.cut-table .higher-zero-number {
  color: #1bb394 !important;
}
.cut-table .contract_executed > td,
.cut-table .contract_executed > td.status-cell {
  color: #000000 !important;
  font-weight: normal;
}
.cut-table .contract_executed > td .btn-link,
.cut-table .contract_executed > td.status-cell .btn-link {
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.cut-table .contract_executed > td .btn-link:hover,
.cut-table .contract_executed > td.status-cell .btn-link:hover {
  text-decoration: underline;
}
.cut-table .contract_executed > td .label,
.cut-table .contract_executed > td.status-cell .label,
.cut-table .contract_executed > td .badge,
.cut-table .contract_executed > td.status-cell .badge {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
.cut-table .contract_executed > td .label a,
.cut-table .contract_executed > td.status-cell .label a,
.cut-table .contract_executed > td .badge a,
.cut-table .contract_executed > td.status-cell .badge a {
  color: #595b63 !important;
}
.cut-table .contract_executed > td .label a.btn-primary,
.cut-table .contract_executed > td.status-cell .label a.btn-primary,
.cut-table .contract_executed > td .badge a.btn-primary,
.cut-table .contract_executed > td.status-cell .badge a.btn-primary {
  color: #595b63 !important;
}
.cut-table .contract_executed > td .label a,
.cut-table .contract_executed > td.status-cell .label a,
.cut-table .contract_executed > td .badge a,
.cut-table .contract_executed > td.status-cell .badge a {
  color: #fff !important;
}
.cut-table .contract_executed > td .label_danger,
.cut-table .contract_executed > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.cut-table .contract_executed > td .label_danger a,
.cut-table .contract_executed > td.status-cell .label_danger a {
  color: #fff !important;
}
.cut-table .contract_executed > td .label_danger span,
.cut-table .contract_executed > td.status-cell .label_danger span {
  color: #fff !important;
}
.cut-table .contract_executed > td .label-opacity,
.cut-table .contract_executed > td.status-cell .label-opacity {
  background: transparent !important;
}
.cut-table .contract_calculate > td:first-child,
.cut-table .contract_calculate > td.status-cell {
  color: #a495b0 !important;
}
.cut-table .contract_calculate > td:first-child .btn-link,
.cut-table .contract_calculate > td.status-cell .btn-link,
.cut-table .contract_calculate > td:first-child a,
.cut-table .contract_calculate > td.status-cell a {
  color: #a495b0 !important;
  border-bottom-color: #a495b0 !important;
}
.cut-table .contract_calculate > td:first-child .label,
.cut-table .contract_calculate > td.status-cell .label,
.cut-table .contract_calculate > td:first-child .badge,
.cut-table .contract_calculate > td.status-cell .badge {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
.cut-table .contract_calculate > td:first-child .label a,
.cut-table .contract_calculate > td.status-cell .label a,
.cut-table .contract_calculate > td:first-child .badge a,
.cut-table .contract_calculate > td.status-cell .badge a {
  color: #6d6a6f !important;
}
.cut-table .contract_calculate > td:first-child .label a,
.cut-table .contract_calculate > td.status-cell .label a,
.cut-table .contract_calculate > td:first-child .badge a,
.cut-table .contract_calculate > td.status-cell .badge a {
  color: #fff !important;
}
.cut-table .contract_calculate > td:first-child .label_danger,
.cut-table .contract_calculate > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.cut-table .contract_calculate > td:first-child .label_danger a,
.cut-table .contract_calculate > td.status-cell .label_danger a {
  color: #fff !important;
}
.cut-table .contract_calculate > td:first-child .label_danger span,
.cut-table .contract_calculate > td.status-cell .label_danger span {
  color: #fff !important;
}
.cut-table .contract_calculate > td:first-child .label-opacity,
.cut-table .contract_calculate > td.status-cell .label-opacity {
  background: transparent !important;
}
.cut-table .contract_new > td:first-child,
.cut-table .contract_new > td.status-cell {
  color: #0c4d40 !important;
}
.cut-table .contract_new > td:first-child .btn-link,
.cut-table .contract_new > td.status-cell .btn-link,
.cut-table .contract_new > td:first-child a,
.cut-table .contract_new > td.status-cell a {
  color: #0c4d40 !important;
  border-bottom-color: #0c4d40 !important;
}
.cut-table .contract_new > td:first-child .label,
.cut-table .contract_new > td.status-cell .label,
.cut-table .contract_new > td:first-child .badge,
.cut-table .contract_new > td.status-cell .badge {
  background-color: rgba(28, 179, 148, 0.4) !important;
  color: #07422d !important;
}
.cut-table .contract_new > td:first-child .label a,
.cut-table .contract_new > td.status-cell .label a,
.cut-table .contract_new > td:first-child .badge a,
.cut-table .contract_new > td.status-cell .badge a {
  color: #07422d !important;
}
.cut-table .contract_new > td:first-child .label a,
.cut-table .contract_new > td.status-cell .label a,
.cut-table .contract_new > td:first-child .badge a,
.cut-table .contract_new > td.status-cell .badge a {
  color: #fff !important;
}
.cut-table .contract_new > td:first-child .label_danger,
.cut-table .contract_new > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.cut-table .contract_new > td:first-child .label_danger a,
.cut-table .contract_new > td.status-cell .label_danger a {
  color: #fff !important;
}
.cut-table .contract_new > td:first-child .label_danger span,
.cut-table .contract_new > td.status-cell .label_danger span {
  color: #fff !important;
}
.cut-table .contract_new > td:first-child .label-opacity,
.cut-table .contract_new > td.status-cell .label-opacity {
  background: transparent !important;
}
.cut-table .contract_processing > td:first-child,
.cut-table .contract_processing > td.status-cell {
  color: #685438 !important;
}
.cut-table .contract_processing > td:first-child .btn-link,
.cut-table .contract_processing > td.status-cell .btn-link,
.cut-table .contract_processing > td:first-child a,
.cut-table .contract_processing > td.status-cell a {
  color: #685438 !important;
  border-bottom-color: #685438 !important;
}
.cut-table .contract_processing > td:first-child .label,
.cut-table .contract_processing > td.status-cell .label,
.cut-table .contract_processing > td:first-child .badge,
.cut-table .contract_processing > td.status-cell .badge {
  background-color: rgba(181, 146, 96, 0.4) !important;
  color: #685438 !important;
}
.cut-table .contract_processing > td:first-child .label a,
.cut-table .contract_processing > td.status-cell .label a,
.cut-table .contract_processing > td:first-child .badge a,
.cut-table .contract_processing > td.status-cell .badge a {
  color: #685438 !important;
}
.cut-table .contract_processing > td:first-child .label a,
.cut-table .contract_processing > td.status-cell .label a,
.cut-table .contract_processing > td:first-child .badge a,
.cut-table .contract_processing > td.status-cell .badge a {
  color: #fff !important;
}
.cut-table .contract_processing > td:first-child .label_danger,
.cut-table .contract_processing > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.cut-table .contract_processing > td:first-child .label_danger a,
.cut-table .contract_processing > td.status-cell .label_danger a {
  color: #fff !important;
}
.cut-table .contract_processing > td:first-child .label_danger span,
.cut-table .contract_processing > td.status-cell .label_danger span {
  color: #fff !important;
}
.cut-table .contract_processing > td:first-child .label-opacity,
.cut-table .contract_processing > td.status-cell .label-opacity {
  background: transparent !important;
}
.cut-table .contract_cancelled > td,
.cut-table .contract_cancelled > td.status-cell {
  color: #7f8c8d !important;
  opacity: 0.5;
  text-decoration: line-through;
  font-weight: normal;
}
.cut-table .contract_cancelled > td .btn-link,
.cut-table .contract_cancelled > td.status-cell .btn-link,
.cut-table .contract_cancelled > td a,
.cut-table .contract_cancelled > td.status-cell a {
  color: #7f8c8d !important;
  border-bottom-color: #7f8c8d !important;
}
.cut-table .contract_cancelled > td .label,
.cut-table .contract_cancelled > td.status-cell .label,
.cut-table .contract_cancelled > td .badge,
.cut-table .contract_cancelled > td.status-cell .badge {
  background: rgba(119, 119, 119, 0.2);
  color: #777777 !important;
}
.cut-table .contract_cancelled > td .label a,
.cut-table .contract_cancelled > td.status-cell .label a,
.cut-table .contract_cancelled > td .badge a,
.cut-table .contract_cancelled > td.status-cell .badge a {
  color: #fff !important;
}
.cut-table .contract_cancelled > td .label_danger,
.cut-table .contract_cancelled > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.cut-table .contract_cancelled > td .label_danger a,
.cut-table .contract_cancelled > td.status-cell .label_danger a {
  color: #fff !important;
}
.cut-table .contract_cancelled > td .label_danger span,
.cut-table .contract_cancelled > td.status-cell .label_danger span {
  color: #fff !important;
}
.cut-table .contract_cancelled > td .label-opacity,
.cut-table .contract_cancelled > td.status-cell .label-opacity {
  background: transparent !important;
}
.cut-table .label {
  display: inline-block !important;
  margin-top: 1px;
  text-align: center;
  white-space: normal;
  font-size: 1em !important;
  color: #eee;
  font-family: 'Light', sans-serif !important;
  font-weight: 400 !important;
}
.cut-table .label-dashed {
  color: #fff;
}
.cut-table .attached_label {
  display: block !important;
}
.cut-table td:nth-child(3) {
  word-break: break-word;
}
.table-details {
  border-collapse: separate;
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 0.9em;
  text-align: center;
}
.table-details tr > td {
  border-top: 1px solid #e8e6e6 !important;
  border-right: 1px solid #f8f6f6 !important;
  line-height: 1 !important;
  vertical-align: middle !important;
  -webkit-text-stroke: 0.2px #000;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.table-details tr > td .hover-element {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  transform: translate3d(0, 8px, 0);
  visibility: hidden;
}
.table-details tr > td .fa-arrow-right.hover-element {
  font-size: calc(14px / 1.4) !important;
}
.table-details tr > td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.table-details tr > td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.table-details tr > td .fa.show-on-hover {
  display: none;
}
.table-details tr > td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.table-details tr > td span {
  line-height: 1 !important;
}
.table-details tr > td .text-muted {
  line-height: 1 !important;
}
.table-details tr > td .input-group-table-edit-btn {
  display: flex;
}
.table-details tr > td .table-edit-btn {
  padding: 0 !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  margin: 0;
  line-height: 1 !important;
  margin-left: 5px;
  font-size: 14px !important;
}
.table-details tr > td .table-edit-btn .fa {
  font-size: 14px !important;
}
.table-details tr > td .table-edit-btn:first-of-type {
  margin-left: 0;
}
.table-details tr > td .info-table-btn:hover {
  border: 1px solid #1d82c7 !important;
}
.table-details tr > td .danger-table-btn:hover {
  border: 1px solid rgba(207, 97, 99, 0.7) !important;
}
.table-details tr > td .warning-table-btn:hover {
  border: 1px solid #7d6643 !important;
}
.table-details tr > td .small {
  color: #666b72 !important;
  margin-top: 5px;
  text-align: left;
}
.table-details tr > td .small .fa,
.table-details tr > td .small .updated_time,
.table-details tr > td .small a,
.table-details tr > td .small span {
  color: #666b72 !important;
  font-size: calc(14px / 1.3) !important;
}
.table-details tr > td .small .btn-link {
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.table-details tr > td .small .btn-link:hover {
  text-decoration: underline;
}
.table-details tr:hover .fa.hide-on-hover {
  display: none;
}
.table-details tr:hover .fa.show-on-hover {
  display: inline-block;
}
.table-details tr:hover td .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
  width: auto;
}
.table-details tr:hover td .fa-sort-desc.hover-element {
  font-size: calc(14px * 1.2) !important;
}
.table-details tr > th {
  border-bottom: 1px solid #e8e6e6 !important;
  border-right: 0;
  letter-spacing: var(--letter-spacing-mega, 0.05em);
}
.table-details tr > th::first-letter {
  text-transform: uppercase !important;
}
.table-details tr th.filter-th {
  background: #f9f9f7 !important;
  border-bottom: 0 !important;
}
.table-details tr:hover th.filter-th {
  background: #f9f9f7 !important;
}
.table-details tr > td:last-child,
.table-details tr > th:last-child {
  border-right: 0 !important;
}
.table-details th {
  color: #666b72;
  font-size: calc(14px / 1.3);
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
  font-family: 'Regular', sans-serif !important;
  cursor: pointer !important;
  vertical-align: middle !important;
}
.table-details th .filter-template .ui-select-match .form-control,
.table-details th .filter-template .ui-select-container .form-control,
.table-details th .form-control {
  font-size: calc(14px / 1.3) !important;
  padding: 0 !important;
  /*  height:20px !important;*/
  width: 100%;
  padding-left: 5px !important;
  max-width: 100%;
  min-width: 100%;
  border-radius: 4px !important;
}
.table-details th .fa.fa-sort {
  display: none;
}
.table-details th .filter-template .ui-select-match .form-control {
  line-height: 2.4;
  width: 100% !important;
}
.table-details th .input-group-addon {
  font-size: calc(14px / 1.3) !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-transform: none !important;
}
.table-details th .form-group {
  padding: 2px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 5px !important;
  width: 100% !important;
}
.table-details th .input-group {
  margin-bottom: 5px;
}
.table-details th select {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.table-details th .caret {
  display: none;
}
.table-details th .text-muted {
  text-transform: none !important;
  color: #000000 !important;
  line-height: 1;
}
.table-details th .big-font {
  font-size: calc(14px * 1.2) !important;
}
.table-details th .btn-link {
  -webkit-text-stroke: 0;
  padding: 0 !important;
}
.table-details th:hover {
  color: #666;
}
.table-details .filter-th:hover {
  background: #fff !important;
}
.table-details td:first-child {
  text-align: left;
  font-family: 'Regular', sans-serif !important;
}
.table-details td:first-child .btn-link {
  color: #1763ad !important;
  border-bottom: 0 !important;
  -webkit-text-stroke: 0;
}
.table-details td:first-child .btn-link:hover {
  text-decoration: underline;
}
.table-details td:first-child .btn-link .fa {
  font-size: calc(14px / 1.3) !important;
}
.table-details td {
  color: #0c4d40;
  text-align: center;
  vertical-align: top;
  border-radius: 0;
  font-family: 'Regular', sans-serif !important;
  line-height: 1;
}
.table-details .item-settings-dropdown:hover .dropdown-menu {
  top: -8px;
  left: 15px;
}
.table-details td,
.table-details td:first-child {
  text-align: center;
  border-color: #666b72 !important;
}
.table-details tr td {
  border-color: #666b72 !important;
}
.table-details tr:first-child td {
  border: none;
}
.big-table {
  border-collapse: separate;
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 0.9em;
  text-align: center;
  font-size: 0.9em / 1.3;
}
.big-table tr > td {
  border-top: 1px solid #e8e6e6 !important;
  border-right: 1px solid #f8f6f6 !important;
  line-height: 1 !important;
  vertical-align: middle !important;
  -webkit-text-stroke: 0.2px #000;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.big-table tr > td .hover-element {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  transform: translate3d(0, 8px, 0);
  visibility: hidden;
}
.big-table tr > td .fa-arrow-right.hover-element {
  font-size: calc(14px / 1.4) !important;
}
.big-table tr > td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.big-table tr > td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.big-table tr > td .fa.show-on-hover {
  display: none;
}
.big-table tr > td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.big-table tr > td span {
  line-height: 1 !important;
}
.big-table tr > td .text-muted {
  line-height: 1 !important;
}
.big-table tr > td .input-group-table-edit-btn {
  display: flex;
}
.big-table tr > td .table-edit-btn {
  padding: 0 !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  margin: 0;
  line-height: 1 !important;
  margin-left: 5px;
  font-size: 14px !important;
}
.big-table tr > td .table-edit-btn .fa {
  font-size: 14px !important;
}
.big-table tr > td .table-edit-btn:first-of-type {
  margin-left: 0;
}
.big-table tr > td .info-table-btn:hover {
  border: 1px solid #1d82c7 !important;
}
.big-table tr > td .danger-table-btn:hover {
  border: 1px solid rgba(207, 97, 99, 0.7) !important;
}
.big-table tr > td .warning-table-btn:hover {
  border: 1px solid #7d6643 !important;
}
.big-table tr > td .small {
  color: #666b72 !important;
  margin-top: 5px;
  text-align: left;
}
.big-table tr > td .small .fa,
.big-table tr > td .small .updated_time,
.big-table tr > td .small a,
.big-table tr > td .small span {
  color: #666b72 !important;
  font-size: calc(14px / 1.3) !important;
}
.big-table tr > td .small .btn-link {
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.big-table tr > td .small .btn-link:hover {
  text-decoration: underline;
}
.big-table tr:hover .fa.hide-on-hover {
  display: none;
}
.big-table tr:hover .fa.show-on-hover {
  display: inline-block;
}
.big-table tr:hover td .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
  width: auto;
}
.big-table tr:hover td .fa-sort-desc.hover-element {
  font-size: calc(14px * 1.2) !important;
}
.big-table tr > th {
  border-bottom: 1px solid #e8e6e6 !important;
  border-right: 0;
  letter-spacing: var(--letter-spacing-mega, 0.05em);
}
.big-table tr > th::first-letter {
  text-transform: uppercase !important;
}
.big-table tr th.filter-th {
  background: #f9f9f7 !important;
  border-bottom: 0 !important;
}
.big-table tr:hover th.filter-th {
  background: #f9f9f7 !important;
}
.big-table tr > td:last-child,
.big-table tr > th:last-child {
  border-right: 0 !important;
}
.big-table th {
  color: #666b72;
  font-size: calc(14px / 1.3);
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
  font-family: 'Regular', sans-serif !important;
  cursor: pointer !important;
  vertical-align: middle !important;
}
.big-table th .filter-template .ui-select-match .form-control,
.big-table th .filter-template .ui-select-container .form-control,
.big-table th .form-control {
  font-size: calc(14px / 1.3) !important;
  padding: 0 !important;
  /*  height:20px !important;*/
  width: 100%;
  padding-left: 5px !important;
  max-width: 100%;
  min-width: 100%;
  border-radius: 4px !important;
}
.big-table th .fa.fa-sort {
  display: none;
}
.big-table th .filter-template .ui-select-match .form-control {
  line-height: 2.4;
  width: 100% !important;
}
.big-table th .input-group-addon {
  font-size: calc(14px / 1.3) !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-transform: none !important;
}
.big-table th .form-group {
  padding: 2px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 5px !important;
  width: 100% !important;
}
.big-table th .input-group {
  margin-bottom: 5px;
}
.big-table th select {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.big-table th .caret {
  display: none;
}
.big-table th .text-muted {
  text-transform: none !important;
  color: #000000 !important;
  line-height: 1;
}
.big-table th .big-font {
  font-size: calc(14px * 1.2) !important;
}
.big-table th .btn-link {
  -webkit-text-stroke: 0;
  padding: 0 !important;
}
.big-table th:hover {
  color: #666;
}
.big-table .filter-th:hover {
  background: #fff !important;
}
.big-table td:first-child {
  text-align: left;
  font-family: 'Regular', sans-serif !important;
}
.big-table td:first-child .btn-link {
  color: #1763ad !important;
  border-bottom: 0 !important;
  -webkit-text-stroke: 0;
}
.big-table td:first-child .btn-link:hover {
  text-decoration: underline;
}
.big-table td:first-child .btn-link .fa {
  font-size: calc(14px / 1.3) !important;
}
.big-table td {
  color: #0c4d40;
  text-align: center;
  vertical-align: top;
  border-radius: 0;
  font-family: 'Regular', sans-serif !important;
  line-height: 1;
}
.big-table .item-settings-dropdown:hover .dropdown-menu {
  top: -8px;
  left: 15px;
}
.main-table {
  margin-bottom: 0 !important;
  border-collapse: separate;
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 0.9em;
  text-align: center;
  font-size: 0.9em / 1.1;
  text-overflow: ellipsis;
}
.main-table tr > td {
  border-top: 1px solid #e8e6e6 !important;
  border-right: 1px solid #f8f6f6 !important;
  line-height: 1 !important;
  vertical-align: middle !important;
  -webkit-text-stroke: 0.2px #000;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.main-table tr > td .hover-element {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  transform: translate3d(0, 8px, 0);
  visibility: hidden;
}
.main-table tr > td .fa-arrow-right.hover-element {
  font-size: calc(14px / 1.4) !important;
}
.main-table tr > td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.main-table tr > td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.main-table tr > td .fa.show-on-hover {
  display: none;
}
.main-table tr > td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.main-table tr > td span {
  line-height: 1 !important;
}
.main-table tr > td .text-muted {
  line-height: 1 !important;
}
.main-table tr > td .input-group-table-edit-btn {
  display: flex;
}
.main-table tr > td .table-edit-btn {
  padding: 0 !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  margin: 0;
  line-height: 1 !important;
  margin-left: 5px;
  font-size: 14px !important;
}
.main-table tr > td .table-edit-btn .fa {
  font-size: 14px !important;
}
.main-table tr > td .table-edit-btn:first-of-type {
  margin-left: 0;
}
.main-table tr > td .info-table-btn:hover {
  border: 1px solid #1d82c7 !important;
}
.main-table tr > td .danger-table-btn:hover {
  border: 1px solid rgba(207, 97, 99, 0.7) !important;
}
.main-table tr > td .warning-table-btn:hover {
  border: 1px solid #7d6643 !important;
}
.main-table tr > td .small {
  color: #666b72 !important;
  margin-top: 5px;
  text-align: left;
}
.main-table tr > td .small .fa,
.main-table tr > td .small .updated_time,
.main-table tr > td .small a,
.main-table tr > td .small span {
  color: #666b72 !important;
  font-size: calc(14px / 1.3) !important;
}
.main-table tr > td .small .btn-link {
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.main-table tr > td .small .btn-link:hover {
  text-decoration: underline;
}
.main-table tr:hover .fa.hide-on-hover {
  display: none;
}
.main-table tr:hover .fa.show-on-hover {
  display: inline-block;
}
.main-table tr:hover td .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
  width: auto;
}
.main-table tr:hover td .fa-sort-desc.hover-element {
  font-size: calc(14px * 1.2) !important;
}
.main-table tr > th {
  border-bottom: 1px solid #e8e6e6 !important;
  border-right: 0;
  letter-spacing: var(--letter-spacing-mega, 0.05em);
}
.main-table tr > th::first-letter {
  text-transform: uppercase !important;
}
.main-table tr th.filter-th {
  background: #f9f9f7 !important;
  border-bottom: 0 !important;
}
.main-table tr:hover th.filter-th {
  background: #f9f9f7 !important;
}
.main-table tr > td:last-child,
.main-table tr > th:last-child {
  border-right: 0 !important;
}
.main-table th {
  color: #666b72;
  font-size: calc(14px / 1.3);
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
  font-family: 'Regular', sans-serif !important;
  cursor: pointer !important;
  vertical-align: middle !important;
}
.main-table th .filter-template .ui-select-match .form-control,
.main-table th .filter-template .ui-select-container .form-control,
.main-table th .form-control {
  font-size: calc(14px / 1.3) !important;
  padding: 0 !important;
  /*  height:20px !important;*/
  width: 100%;
  padding-left: 5px !important;
  max-width: 100%;
  min-width: 100%;
  border-radius: 4px !important;
}
.main-table th .fa.fa-sort {
  display: none;
}
.main-table th .filter-template .ui-select-match .form-control {
  line-height: 2.4;
  width: 100% !important;
}
.main-table th .input-group-addon {
  font-size: calc(14px / 1.3) !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-transform: none !important;
}
.main-table th .form-group {
  padding: 2px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 5px !important;
  width: 100% !important;
}
.main-table th .input-group {
  margin-bottom: 5px;
}
.main-table th select {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.main-table th .caret {
  display: none;
}
.main-table th .text-muted {
  text-transform: none !important;
  color: #000000 !important;
  line-height: 1;
}
.main-table th .big-font {
  font-size: calc(14px * 1.2) !important;
}
.main-table th .btn-link {
  -webkit-text-stroke: 0;
  padding: 0 !important;
}
.main-table th:hover {
  color: #666;
}
.main-table .filter-th:hover {
  background: #fff !important;
}
.main-table td:first-child {
  text-align: left;
  font-family: 'Regular', sans-serif !important;
}
.main-table td:first-child .btn-link {
  color: #1763ad !important;
  border-bottom: 0 !important;
  -webkit-text-stroke: 0;
}
.main-table td:first-child .btn-link:hover {
  text-decoration: underline;
}
.main-table td:first-child .btn-link .fa {
  font-size: calc(14px / 1.3) !important;
}
.main-table td {
  color: #0c4d40;
  text-align: center;
  vertical-align: top;
  border-radius: 0;
  font-family: 'Regular', sans-serif !important;
  line-height: 1;
}
.main-table .item-settings-dropdown:hover .dropdown-menu {
  top: -8px;
  left: 15px;
}
.main-table td:first-child {
  border-left: 5px solid rgba(255, 255, 255, 0) !important;
}
.main-table tbody > tr > th,
.main-table tfoot > tr > th,
.main-table thead > tr > th {
  border-top: 0;
}
.main-table td {
  border-color: #e8e6e6 !important;
  color: #000000;
  vertical-align: middle !important;
}
.main-table tr td .form-control,
.main-table tr th .form-control,
.main-table tr td .form-control .ui-select-match-text,
.main-table tr th .form-control .ui-select-match-text {
  min-height: 34px;
  min-width: 50px !important;
  overflow: hidden;
  margin-bottom: 2px;
  font-size: calc(14px / 1.2) !important;
}
.main-table tr td input.valid-date,
.main-table tr th input.valid-date,
.main-table tr td .gt-date-select input.form-control,
.main-table tr th .gt-date-select input.form-control {
  margin-bottom: 0 !important;
}
.main-table tr td .ui-select-bootstrap > .ui-select-match > .btn,
.main-table tr th .ui-select-bootstrap > .ui-select-match > .btn {
  min-width: 50px !important;
  min-height: 34px;
  height: auto;
  overflow: hidden;
  line-height: 1 !important;
  margin-bottom: 2px;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  font-size: calc(14px / 1.2) !important;
}
.main-table tr th .form-control,
.main-table tr th .form-control .ui-select-match-text,
.main-table tr th .text-muted {
  font-family: 'Regular', sans-serif !important;
}
.main-table tr th .ui-select-bootstrap > .ui-select-match > .btn,
.main-table tr th .form-control {
  min-height: auto;
  height: auto;
  padding: 4px !important;
}
.main-table .td-with-border {
  border-right: 1px solid #e8e6e6 !important;
}
.main-table td:last-child {
  border-right: 0 !important;
}
.main-table .highlighted-tr td {
  background: #f9f9f7 !important;
  border-color: #f0f0eb !important;
  color: #000000 !important;
  border-radius: 0 !important;
}
.main-table .highlighted-tr td:first-child {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-left: 0 !important;
}
.main-table .highlighted-tr td:last-child {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-right: 0 !important;
}
.main-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.main-table td.th-left-align {
  text-align: left !important;
}
.main-table td.th-left-align div {
  text-align: left !important;
}
.main-table td.th-left-align .no-user-avatar {
  text-align: center !important;
}
.main-table td.th-center-align {
  text-align: center !important;
}
.main-table td.th-center-align div {
  text-align: center !important;
}
.main-table td.td-left-align {
  text-align: left !important;
}
.main-table td.td-right-align {
  text-align: right !important;
}
.main-table .highlighted-tr-first-row td {
  font-family: 'Bold', sans-serif !important;
  border-right-color: transparent !important;
}
.main-table .highlighted-tr-first-row td:first-child {
  background: #ebebeb !important;
  border-bottom: 1px solid #e5e5dd !important;
  color: #000000 !important;
  border-right-color: #e8e6e6 !important;
}
.main-table .highlighted-td {
  background: #f9f9f7 !important;
  border-radius: 0 !important;
}
.main-table .highlighted-th {
  background: rgba(0, 0, 0, 0.01) !important;
  vertical-align: top !important;
}
.main-table .highlighted-td.danger-highlighted {
  background: rgba(207, 97, 99, 0.13) !important;
}
.main-table .highlighted-td.warning-highlighted {
  background: #f0ede9 !important;
}
.main-table .highlighted-td.success-highlighted {
  background: #ebf1ef !important;
}
.main-table tr td .ui-select-bootstrap .ui-select-toggle > .caret {
  display: none !important;
}
.extended-table {
  margin-bottom: 0 !important;
  border-collapse: separate;
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 0.9em;
  text-align: center;
  font-size: 0.9em / 1.1;
  text-overflow: ellipsis;
  font-size: 0.9em / 1.3;
}
.extended-table tr > td {
  border-top: 1px solid #e8e6e6 !important;
  border-right: 1px solid #f8f6f6 !important;
  line-height: 1 !important;
  vertical-align: middle !important;
  -webkit-text-stroke: 0.2px #000;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.extended-table tr > td .hover-element {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  transform: translate3d(0, 8px, 0);
  visibility: hidden;
}
.extended-table tr > td .fa-arrow-right.hover-element {
  font-size: calc(14px / 1.4) !important;
}
.extended-table tr > td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.extended-table tr > td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.extended-table tr > td .fa.show-on-hover {
  display: none;
}
.extended-table tr > td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.extended-table tr > td span {
  line-height: 1 !important;
}
.extended-table tr > td .text-muted {
  line-height: 1 !important;
}
.extended-table tr > td .input-group-table-edit-btn {
  display: flex;
}
.extended-table tr > td .table-edit-btn {
  padding: 0 !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  margin: 0;
  line-height: 1 !important;
  margin-left: 5px;
  font-size: 14px !important;
}
.extended-table tr > td .table-edit-btn .fa {
  font-size: 14px !important;
}
.extended-table tr > td .table-edit-btn:first-of-type {
  margin-left: 0;
}
.extended-table tr > td .info-table-btn:hover {
  border: 1px solid #1d82c7 !important;
}
.extended-table tr > td .danger-table-btn:hover {
  border: 1px solid rgba(207, 97, 99, 0.7) !important;
}
.extended-table tr > td .warning-table-btn:hover {
  border: 1px solid #7d6643 !important;
}
.extended-table tr > td .small {
  color: #666b72 !important;
  margin-top: 5px;
  text-align: left;
}
.extended-table tr > td .small .fa,
.extended-table tr > td .small .updated_time,
.extended-table tr > td .small a,
.extended-table tr > td .small span {
  color: #666b72 !important;
  font-size: calc(14px / 1.3) !important;
}
.extended-table tr > td .small .btn-link {
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.extended-table tr > td .small .btn-link:hover {
  text-decoration: underline;
}
.extended-table tr:hover .fa.hide-on-hover {
  display: none;
}
.extended-table tr:hover .fa.show-on-hover {
  display: inline-block;
}
.extended-table tr:hover td .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
  width: auto;
}
.extended-table tr:hover td .fa-sort-desc.hover-element {
  font-size: calc(14px * 1.2) !important;
}
.extended-table tr > th {
  border-bottom: 1px solid #e8e6e6 !important;
  border-right: 0;
  letter-spacing: var(--letter-spacing-mega, 0.05em);
}
.extended-table tr > th::first-letter {
  text-transform: uppercase !important;
}
.extended-table tr th.filter-th {
  background: #f9f9f7 !important;
  border-bottom: 0 !important;
}
.extended-table tr:hover th.filter-th {
  background: #f9f9f7 !important;
}
.extended-table tr > td:last-child,
.extended-table tr > th:last-child {
  border-right: 0 !important;
}
.extended-table th {
  color: #666b72;
  font-size: calc(14px / 1.3);
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
  font-family: 'Regular', sans-serif !important;
  cursor: pointer !important;
  vertical-align: middle !important;
}
.extended-table th .filter-template .ui-select-match .form-control,
.extended-table th .filter-template .ui-select-container .form-control,
.extended-table th .form-control {
  font-size: calc(14px / 1.3) !important;
  padding: 0 !important;
  /*  height:20px !important;*/
  width: 100%;
  padding-left: 5px !important;
  max-width: 100%;
  min-width: 100%;
  border-radius: 4px !important;
}
.extended-table th .fa.fa-sort {
  display: none;
}
.extended-table th .filter-template .ui-select-match .form-control {
  line-height: 2.4;
  width: 100% !important;
}
.extended-table th .input-group-addon {
  font-size: calc(14px / 1.3) !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-transform: none !important;
}
.extended-table th .form-group {
  padding: 2px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 5px !important;
  width: 100% !important;
}
.extended-table th .input-group {
  margin-bottom: 5px;
}
.extended-table th select {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.extended-table th .caret {
  display: none;
}
.extended-table th .text-muted {
  text-transform: none !important;
  color: #000000 !important;
  line-height: 1;
}
.extended-table th .big-font {
  font-size: calc(14px * 1.2) !important;
}
.extended-table th .btn-link {
  -webkit-text-stroke: 0;
  padding: 0 !important;
}
.extended-table th:hover {
  color: #666;
}
.extended-table .filter-th:hover {
  background: #fff !important;
}
.extended-table td:first-child {
  text-align: left;
  font-family: 'Regular', sans-serif !important;
}
.extended-table td:first-child .btn-link {
  color: #1763ad !important;
  border-bottom: 0 !important;
  -webkit-text-stroke: 0;
}
.extended-table td:first-child .btn-link:hover {
  text-decoration: underline;
}
.extended-table td:first-child .btn-link .fa {
  font-size: calc(14px / 1.3) !important;
}
.extended-table td {
  color: #0c4d40;
  text-align: center;
  vertical-align: top;
  border-radius: 0;
  font-family: 'Regular', sans-serif !important;
  line-height: 1;
}
.extended-table .item-settings-dropdown:hover .dropdown-menu {
  top: -8px;
  left: 15px;
}
.extended-table td:first-child {
  border-left: 5px solid rgba(255, 255, 255, 0) !important;
}
.extended-table tbody > tr > th,
.extended-table tfoot > tr > th,
.extended-table thead > tr > th {
  border-top: 0;
}
.extended-table td {
  border-color: #e8e6e6 !important;
  color: #000000;
  vertical-align: middle !important;
}
.extended-table tr td .form-control,
.extended-table tr th .form-control,
.extended-table tr td .form-control .ui-select-match-text,
.extended-table tr th .form-control .ui-select-match-text {
  min-height: 34px;
  min-width: 50px !important;
  overflow: hidden;
  margin-bottom: 2px;
  font-size: calc(14px / 1.2) !important;
}
.extended-table tr td input.valid-date,
.extended-table tr th input.valid-date,
.extended-table tr td .gt-date-select input.form-control,
.extended-table tr th .gt-date-select input.form-control {
  margin-bottom: 0 !important;
}
.extended-table tr td .ui-select-bootstrap > .ui-select-match > .btn,
.extended-table tr th .ui-select-bootstrap > .ui-select-match > .btn {
  min-width: 50px !important;
  min-height: 34px;
  height: auto;
  overflow: hidden;
  line-height: 1 !important;
  margin-bottom: 2px;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  font-size: calc(14px / 1.2) !important;
}
.extended-table tr th .form-control,
.extended-table tr th .form-control .ui-select-match-text,
.extended-table tr th .text-muted {
  font-family: 'Regular', sans-serif !important;
}
.extended-table tr th .ui-select-bootstrap > .ui-select-match > .btn,
.extended-table tr th .form-control {
  min-height: auto;
  height: auto;
  padding: 4px !important;
}
.extended-table .td-with-border {
  border-right: 1px solid #e8e6e6 !important;
}
.extended-table td:last-child {
  border-right: 0 !important;
}
.extended-table .highlighted-tr td {
  background: #f9f9f7 !important;
  border-color: #f0f0eb !important;
  color: #000000 !important;
  border-radius: 0 !important;
}
.extended-table .highlighted-tr td:first-child {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-left: 0 !important;
}
.extended-table .highlighted-tr td:last-child {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-right: 0 !important;
}
.extended-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.extended-table td.th-left-align {
  text-align: left !important;
}
.extended-table td.th-left-align div {
  text-align: left !important;
}
.extended-table td.th-left-align .no-user-avatar {
  text-align: center !important;
}
.extended-table td.th-center-align {
  text-align: center !important;
}
.extended-table td.th-center-align div {
  text-align: center !important;
}
.extended-table td.td-left-align {
  text-align: left !important;
}
.extended-table td.td-right-align {
  text-align: right !important;
}
.extended-table .highlighted-tr-first-row td {
  font-family: 'Bold', sans-serif !important;
  border-right-color: transparent !important;
}
.extended-table .highlighted-tr-first-row td:first-child {
  background: #ebebeb !important;
  border-bottom: 1px solid #e5e5dd !important;
  color: #000000 !important;
  border-right-color: #e8e6e6 !important;
}
.extended-table .highlighted-td {
  background: #f9f9f7 !important;
  border-radius: 0 !important;
}
.extended-table .highlighted-th {
  background: rgba(0, 0, 0, 0.01) !important;
  vertical-align: top !important;
}
.extended-table .highlighted-td.danger-highlighted {
  background: rgba(207, 97, 99, 0.13) !important;
}
.extended-table .highlighted-td.warning-highlighted {
  background: #f0ede9 !important;
}
.extended-table .highlighted-td.success-highlighted {
  background: #ebf1ef !important;
}
.extended-table tr td .ui-select-bootstrap .ui-select-toggle > .caret {
  display: none !important;
}
.extended-table td:first-child {
  vertical-align: middle;
  text-align: right;
  color: #0c4d40;
  border-color: #666b72 !important;
}
.table tfoot tr:nth-last-child(2) td {
  border-bottom: 0 !important;
}
.table tfoot tr:nth-last-child(1) td {
  font-family: 'Semibold', sans-serif !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.table-with-total {
  border-color: #666b72 !important;
}
.table-with-total tbody tr:nth-last-child(2) td {
  border-bottom: 0 !important;
}
.table-with-total tbody tr:nth-last-child(1) td,
.table-with-total tfoot tr:nth-last-child(1) td,
.table-with-total tbody tr:nth-last-child(1) td.highlighted-tr,
.table-with-total tfoot tr:nth-last-child(1) td.highlighted-tr,
.table-with-total tbody tr:nth-last-child(1) td.highlighted-td,
.table-with-total tfoot tr:nth-last-child(1) td.highlighted-td {
  background: #fff !important;
  font-family: 'Bold', sans-serif !important;
  border-radius: 0 !important;
}
.table-with-total tbody tr:nth-child(1) {
  background: transparent !important;
}
.table-with-total .lower-zero-number {
  color: #cf6163 !important;
}
.table-with-total .higher-zero-number {
  color: #1bb394 !important;
}
.table-with-total .total-row .label {
  font-size: 14px !important;
  font-family: 'Bold', sans-serif !important;
}
.table-no-total tbody tr:nth-last-child(1) td {
  background: transparent !important;
  color: #595b63;
  padding: 2px !important;
}
.table-no-total tr td {
  color: #000000 !important;
  border: 0 !important;
  padding: 2px !important;
}
.table-inside-table {
  background: rgba(0, 0, 0, 0.03);
}
.table-hover tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.big-progressbars-container label,
.big-progressbars-container label.contract-description-label {
  font-size: 14px !important;
  margin: 0;
  line-height: 2.3;
}
.big-progressbars-container .progress .progress-bar {
  font-size: 14px !important;
  padding: 0 !important;
}
.big-progressbars-container .col-xs-6 {
  padding-left: 10px;
  padding-right: 0;
}
.cabinets-counters.big-progressbars-container label,
.cabinets-counters.big-progressbars-container label.contract-description-label {
  font-size: calc(14px * 1.2) !important;
  margin: 0;
  line-height: 1.1;
}
.cabinets-counters.big-progressbars-container .progress .progress-bar {
  font-size: calc(14px * 1.2) !important;
  padding: 0 !important;
}
.object-details-container .modal-header {
  display: none;
}
.object-details-container .form-group-container .form-group .col-xs-12 {
  display: none;
}
.object-details-container .form-group-container input[type='checkbox'].ng-empty,
.object-details-container .form-group-container input[type='radio'].ng-empty {
  display: none;
}
.object-details-container .form-group-container.col-md-6,
.object-details-container .form-group-container.col-sm-3,
.object-details-container .form-group-container.col-sm-4 {
  width: 100% !important;
}
.object-details-container label,
.position-row-detail-container label {
  pointer-events: none;
}
.object-details-container .modal-body,
.position-row-detail-container .modal-body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.object-details-container .modal-form-block .ul-inline-row,
.position-row-detail-container .modal-form-block .ul-inline-row {
  box-shadow: none !important;
  padding: 0 !important;
}
.object-details-container .modal-form-block .ul-inline-row .btn-add-dark,
.position-row-detail-container .modal-form-block .ul-inline-row .btn-add-dark {
  display: none;
}
.object-details-container .help-inline-hint,
.position-row-detail-container .help-inline-hint {
  display: none;
}
.object-details-container .form-group-container.main-block-wide,
.position-row-detail-container .form-group-container.main-block-wide {
  box-shadow: none !important;
  width: 98%;
  margin: 0 !important;
}
.object-details-container .form-group-container.main-block-wide .modal-form-block,
.position-row-detail-container .form-group-container.main-block-wide .modal-form-block {
  width: 98% !important;
}
.object-details-container .form-group-container.main-block-wide .formly-field .form-group .control-label,
.position-row-detail-container .form-group-container.main-block-wide .formly-field .form-group .control-label {
  font-size: calc(14px / 1.2) !important;
  color: #000000 !important;
}
.object-details-container .form-group-container.main-block-wide .formly-field .form-group .form-control,
.position-row-detail-container .form-group-container.main-block-wide .formly-field .form-group .form-control {
  font-size: calc(14px / 1.2) !important;
  background: transparent !important;
  color: #000000 !important;
}
.object-details-container .form-group-container,
.position-row-detail-container .form-group-container {
  padding-right: 0 !important;
  padding-left: 10px;
}
.object-details-container .contract-description-label,
.position-row-detail-container .contract-description-label {
  background: rgba(62, 162, 213, 0.19) !important;
  color: #fff !important;
  letter-spacing: 2px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding: 2px;
  font-size: calc(14px / 1.3) !important;
  display: none !important;
}
.object-details-container .form-group-container .form-group .col-xs-12.not-empty,
.position-row-detail-container .form-group-container .form-group .col-xs-12.not-empty {
  display: block;
  border-bottom: 1px dashed #e8e6e6 !important;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}
.object-details-container .form-group-container .form-group.not-empty,
.position-row-detail-container .form-group-container .form-group.not-empty {
  background: transparent !important;
  border: 0 !important;
  border-bottom: 0 !important;
}
.object-details-container .form-group-container .form-group .ui-select-container,
.position-row-detail-container .form-group-container .form-group .ui-select-container {
  padding-left: 0 !important;
}
.object-details-container .form-group-container .form-group .control-label,
.position-row-detail-container .form-group-container .form-group .control-label {
  padding: 2px !important;
  word-wrap: break-word;
  margin: 0 !important;
  float: left;
  color: #666b72 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
  text-align: right !important;
  white-space: nowrap;
  overflow: hidden;
  height: 18px !important;
  min-height: 18px !important;
  display: block !important;
  width: 40%;
}
.object-details-container .form-group-container .form-group .control-label::first-letter,
.position-row-detail-container .form-group-container .form-group .control-label::first-letter {
  text-transform: uppercase !important;
}
.object-details-container .form-group-container .form-group .control-label .check-label,
.position-row-detail-container .form-group-container .form-group .control-label .check-label {
  display: none;
}
.object-details-container .form-group-container .form-group .control-label .tooltip-modal,
.position-row-detail-container .form-group-container .form-group .control-label .tooltip-modal {
  display: none;
}
.object-details-container .form-group-container .form-group .control-label:hover,
.position-row-detail-container .form-group-container .form-group .control-label:hover {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  white-space: normal;
}
.object-details-container .form-group-container .form-group .input-wrapper,
.position-row-detail-container .form-group-container .form-group .input-wrapper {
  float: right;
  width: 60%;
}
.object-details-container .form-group-container .form-group .input-wrapper .object-title,
.position-row-detail-container .form-group-container .form-group .input-wrapper .object-title,
.object-details-container .form-group-container .form-group .input-wrapper textarea.form-control,
.position-row-detail-container .form-group-container .form-group .input-wrapper textarea.form-control,
.object-details-container .form-group-container .form-group .input-wrapper select,
.position-row-detail-container .form-group-container .form-group .input-wrapper select,
.object-details-container .form-group-container .form-group .input-wrapper input,
.position-row-detail-container .form-group-container .form-group .input-wrapper input,
.object-details-container .form-group-container .form-group .input-wrapper input[type='checkbox'],
.position-row-detail-container .form-group-container .form-group .input-wrapper input[type='checkbox'],
.object-details-container .form-group-container .form-group .input-wrapper .ui-select-multiple .ui-select-match-item,
.position-row-detail-container .form-group-container .form-group .input-wrapper .ui-select-multiple .ui-select-match-item,
.object-details-container .form-group-container .form-group .input-wrapper .form-control,
.position-row-detail-container .form-group-container .form-group .input-wrapper .form-control {
  font-size: calc(14px / 1.2) !important;
  cursor: pointer !important;
  text-align: left;
  line-height: 1 !important;
  font-family: 'Regular', sans-serif !important;
  color: #000000 !important;
}
.object-details-container .object-title,
.position-row-detail-container .object-title {
  text-align: left;
  font-family: 'Regular', sans-serif !important;
  font-size: calc(14px / 1.2) !important;
}
.object-details-container .form-group-container .form-group.col-xs-12,
.position-row-detail-container .form-group-container .form-group.col-xs-12 {
  margin-bottom: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
.object-details-container .form-group-container .form-group .input-wrapper .input-group,
.position-row-detail-container .form-group-container .form-group .input-wrapper .input-group {
  padding-left: 0;
  line-height: 1;
}
.object-details-container .form-group-container .form-group.not-empty .input-wrapper,
.position-row-detail-container .form-group-container .form-group.not-empty .input-wrapper,
.object-details-container .form-group-container .form-group .input-wrapper,
.position-row-detail-container .form-group-container .form-group .input-wrapper {
  padding: 0 !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  white-space: normal;
  word-wrap: break-word;
  margin: 0 !important;
  border: 0 !important;
  background: #fff !important;
  border-radius: 0 !important;
  text-align: left;
  overflow: visible;
  height: auto !important;
  min-height: auto !important;
}
.object-details-container .form-group-container .form-group,
.position-row-detail-container .form-group-container .form-group {
  padding: 0 !important;
}
.object-details-container .form-group-container .form-group .form-control,
.position-row-detail-container .form-group-container .form-group .form-control,
.object-details-container .form-control,
.position-row-detail-container .form-control {
  border: 0 !important;
  background: transparent !important;
  padding: 0 !important;
  overflow: hidden;
  white-space: normal;
  pointer-events: none;
}
.object-details-container .form-group-container .form-group .form-control .ui-select-placeholder,
.position-row-detail-container .form-group-container .form-group .form-control .ui-select-placeholder,
.object-details-container .form-control .ui-select-placeholder,
.position-row-detail-container .form-control .ui-select-placeholder,
.object-details-container .form-group-container .form-group .form-control .text-muted,
.position-row-detail-container .form-group-container .form-group .form-control .text-muted,
.object-details-container .form-control .text-muted,
.position-row-detail-container .form-control .text-muted {
  display: none !important;
  color: transparent !important;
  font-size: calc(14px / 1.2) !important;
}
.object-details-container .form-group-container .form-group .form-control::-webkit-input-placeholder,
.position-row-detail-container .form-group-container .form-group .form-control::-webkit-input-placeholder,
.object-details-container .form-control::-webkit-input-placeholder,
.position-row-detail-container .form-control::-webkit-input-placeholder {
  display: none !important;
  color: transparent !important;
  font-size: calc(14px / 1.2) !important;
}
.object-details-container .form-group-container .form-group .form-control::-moz-placeholder,
.position-row-detail-container .form-group-container .form-group .form-control::-moz-placeholder,
.object-details-container .form-control::-moz-placeholder,
.position-row-detail-container .form-control::-moz-placeholder {
  display: none !important;
  color: transparent !important;
  font-size: calc(14px / 1.2) !important;
}
.object-details-container .form-group-container .form-group .form-control:-moz-placeholder,
.position-row-detail-container .form-group-container .form-group .form-control:-moz-placeholder,
.object-details-container .form-control:-moz-placeholder,
.position-row-detail-container .form-control:-moz-placeholder {
  display: none !important;
  color: transparent !important;
  font-size: calc(14px / 1.2) !important;
}
.object-details-container .form-group-container .form-group .form-control:-ms-input-placeholder,
.position-row-detail-container .form-group-container .form-group .form-control:-ms-input-placeholder,
.object-details-container .form-control:-ms-input-placeholder,
.position-row-detail-container .form-control:-ms-input-placeholder {
  display: none !important;
  color: transparent !important;
  font-size: calc(14px / 1.2) !important;
}
.object-details-container .form-group-container .form-group .form-control .alert,
.position-row-detail-container .form-group-container .form-group .form-control .alert,
.object-details-container .form-control .alert,
.position-row-detail-container .form-control .alert {
  width: 80%;
  border-radius: 10px !important;
  border-bottom-right-radius: 0 !important;
  margin-bottom: 5px;
  padding: 5px;
}
.object-details-container .copy-clipboard-btn,
.position-row-detail-container .copy-clipboard-btn {
  pointer-events: all !important;
}
.object-details-container .form-group-container .form-group select,
.position-row-detail-container .form-group-container .form-group select {
  margin-left: -4px !important;
}
.object-details-container input[type='checkbox'],
.position-row-detail-container input[type='checkbox'],
.object-details-container input[type='radio'],
.position-row-detail-container input[type='radio'] {
  margin: 0;
  margin-left: -4px !important;
}
.object-details-container .input-group-addon-search,
.position-row-detail-container .input-group-addon-search {
  display: none;
}
.object-details-container .clear-btn,
.position-row-detail-container .clear-btn,
.object-details-container .clear-title-btn,
.position-row-detail-container .clear-title-btn {
  display: none;
}
.object-details-container .form-group .input-group-addon,
.position-row-detail-container .form-group .input-group-addon {
  display: none;
}
.object-details-container .add-btn,
.position-row-detail-container .add-btn {
  display: none;
}
.object-details-container .ui-select-match-close,
.position-row-detail-container .ui-select-match-close {
  display: none;
}
.object-details-container .help-inline,
.position-row-detail-container .help-inline {
  display: none;
}
.object-details-container .select-with-btn,
.position-row-detail-container .select-with-btn {
  width: 100% !important;
}
.object-details-container .contract-price-widget,
.position-row-detail-container .contract-price-widget {
  background: #fff !important;
}
.object-details-container .contract-price-widget .btn-primary,
.position-row-detail-container .contract-price-widget .btn-primary {
  display: none;
}
.object-details-container .not-empty .form-control,
.position-row-detail-container .not-empty .form-control,
.object-details-container .not-empty .control-label,
.position-row-detail-container .not-empty .control-label {
  color: #000000 !important;
  background: #fff !important;
}
.object-details-container .not-empty .btn-default,
.position-row-detail-container .not-empty .btn-default {
  color: #000000 !important;
  white-space: normal;
  overflow: visible;
  line-height: 1;
}
.object-details-container .not-empty .btn-default::first-letter,
.position-row-detail-container .not-empty .btn-default::first-letter {
  text-transform: uppercase !important;
}
.object-details-container .not-empty .form-add-btn,
.position-row-detail-container .not-empty .form-add-btn {
  display: none;
}
.object-details-container .not-empty .select-with-btn,
.position-row-detail-container .not-empty .select-with-btn {
  width: 100%;
}
.object-details-container .ui-select-multiple.ui-select-bootstrap input.ui-select-search,
.position-row-detail-container .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  display: none;
}
.object-details-container a.btn-success,
.position-row-detail-container a.btn-success,
.object-details-container a.btn_success,
.position-row-detail-container a.btn_success,
.object-details-container .fa-files-o,
.position-row-detail-container .fa-files-o,
.object-details-container .fa-trash,
.position-row-detail-container .fa-trash,
.object-details-container .fa-pencil,
.position-row-detail-container .fa-pencil,
.object-details-container .fa-file-word-o,
.position-row-detail-container .fa-file-word-o,
.object-details-container .fa-remove,
.position-row-detail-container .fa-remove {
  display: none;
}
.object-details-container .edit-mode,
.position-row-detail-container .edit-mode {
  display: none;
}
.object-details-container textarea.form-control,
.position-row-detail-container textarea.form-control,
.object-details-container select,
.position-row-detail-container select,
.object-details-container input,
.position-row-detail-container input,
.object-details-container input[type='checkbox'],
.position-row-detail-container input[type='checkbox'],
.object-details-container .ui-select-multiple .ui-select-match-item,
.position-row-detail-container .ui-select-multiple .ui-select-match-item,
.object-details-container .form-control,
.position-row-detail-container .form-control {
  border: 0 !important;
  font-size: calc(14px / 1.3) !important;
  cursor: pointer !important;
}
.object-details-container .form-control .ui-select-match-text:hover .clear-btn,
.position-row-detail-container .form-control .ui-select-match-text:hover .clear-btn {
  display: none;
}
.object-details-container .form-control .ui-select-match-text:hover .copy-btn,
.position-row-detail-container .form-control .ui-select-match-text:hover .copy-btn {
  display: inline-block;
}
.object-details-container select,
.position-row-detail-container select,
.object-details-container input[type='checkbox'],
.position-row-detail-container input[type='checkbox'] {
  pointer-events: none !important;
  user-select: auto;
}
.object-details-container .positions-modal .form-group-container,
.position-row-detail-container .positions-modal .form-group-container {
  width: 100% !important;
}
.object-details-container .form-group-container.col-sm-3,
.object-details-container .form-group-container.col-sm-4 {
  width: 100% !important;
}
.counterparty_detail_table td {
  padding: 4px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  white-space: normal;
  word-wrap: break-word;
  text-align: left !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
  vertical-align: middle !important;
}
.counterparty_detail_table td .form-control {
  height: 22px !important;
  min-height: 22px !important;
}
.counterparty_detail_table td h3,
.counterparty_detail_table td h4,
.counterparty_detail_table td h5 {
  margin: 0;
}
.counterparty_detail_table tr:first-child {
  border-top: 0 !important;
}
.counterparty_detail_table tr:first-child td {
  border-top: 0 !important;
}
.counterparty_detail_table tr td:first-child {
  color: #666b72 !important;
  font-size: calc(14px / 1.2) !important;
  vertical-align: middle;
  font-family: 'Regular', sans-serif !important;
  width: 42%;
  word-break: normal;
}
.counterparty_detail_table tr td:first-child::first-letter {
  text-transform: uppercase !important;
}
.counterparty_detail_table .label {
  display: inline-block !important;
  margin-right: 2px;
  vertical-align: middle;
  font-size: calc(14px / 1.2) !important;
}
.counterparty_detail_table .btn-link {
  font-size: calc(14px / 1.2) !important;
}
.counterparty-details-table tr td:first-child {
  background: rgba(0, 0, 0, 0.01) !important;
  text-transform: uppercase !important;
  vertical-align: middle;
  font-family: 'Light', sans-serif !important;
}
.counterparty-details-table tr td {
  font-size: calc(14px / 1.2);
  color: #000000;
  padding: 2px !important;
  word-break: break-word;
  white-space: normal;
}
.counterparty-details-table tr td .label {
  display: inline-flex;
}
.tariffs-table {
  width: 100% !important;
}
.tariffs-table tr td {
  text-align: left !important;
  border-right: 1px solid #e8e6e6 !important;
  vertical-align: middle;
  padding: 2px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  font-size: calc(14px / 1.2);
}
.tariffs-table tr td .bigger-label {
  font-family: 'Semibold', sans-serif !important;
  font-size: 14px !important;
}
.tariffs-table tr:nth-child(1) td:nth-child(1) {
  border-left: 5px solid #0c4d40 !important;
}
.tariffs-table tr td.edit .form-control {
  margin-bottom: 2px;
}
.tariffs-table td.td-right-align {
  text-align: right !important;
}
.tariffs-table td.td-center-align {
  text-align: center !important;
}
.tariffs-table tr th {
  text-align: center !important;
}
.tariffs-table td.additional_info {
  word-wrap: break-word;
  white-space: normal;
}
.tariffs-table td:last-child {
  text-align: center !important;
}
.tariffs-table td:first-child {
  font-family: 'Regular', sans-serif !important;
  text-transform: initial !important;
}
.tariffs-table td:first-child .label_success {
  padding: 10px !important;
  display: block;
  text-align: left;
}
.tariffs-table .label {
  white-space: nowrap;
}
.tariffs-table .btn {
  border-radius: 4px !important;
}
.tariffs-table .btn-success {
  border-width: 1px;
  color: #ffffff !important;
  padding: 6px !important;
}
.tariffs-table .btn-success:hover {
  color: #ffffff !important;
}
.tariffs-table .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 10px;
  width: 2%;
  float: left;
}
.tariffs-table .form-control {
  margin: 0;
  padding: 2px;
  height: 30px;
  font-size: 14px;
}
.tariffs-table .ui-select-bootstrap > .ui-select-match > .btn,
.tariffs-table .form-control .ui-select-match-text {
  line-height: 2 !important;
}
.input-group {
  padding-bottom: 0;
}
.page-filter-modal .title-column,
.page-filter-modal .new-title-column,
.page-filter-modal .select-column {
  color: #000000 !important;
  text-transform: capitalize !important;
  line-height: 1 !important;
  font-family: 'Regular', sans-serif !important;
  font-size: calc(14px / 1.2) !important;
}
.page-filter-modal .row,
.page-filter-modal .input-wrapper,
.page-filter-modal .input-group {
  display: flex;
  align-items: center;
  width: 100%;
}
.page-filter-modal .input-wrapper {
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
}
.page-filter-modal .input-group {
  min-width: 175px;
}
.page-filter-modal .input-group input {
  flex-grow: 1;
  margin-right: 10px;
}
.page-filter-modal .input-group .copy-clipboard-btn {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.page-filter-modal .select-all-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-filter-modal .select-all-wrapper span {
  color: #000000 !important;
  text-transform: capitalize !important;
  line-height: 1 !important;
  font-family: 'Regular', sans-serif !important;
  font-size: calc(14px / 1.2) !important;
}
.page-filter-modal .select-all-wrapper input {
  margin-right: 10px;
}
.page-filter-modal .column-headers {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.page-filter-modal .column-headers div {
  text-align: center;
  font-weight: bold;
}
.page-filter-modal .control-label-wrapper {
  left: 60px;
  padding-right: 15px;
}
.page-filter-modal .form-group-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.page-filter-modal .col-xs-4 {
  flex: 1;
  padding: 0;
}
.page-filter-modal .select-column {
  justify-content: center;
  padding-right: 10px;
}
.page-filter-modal .row {
  margin: 0 !important;
}
.bill-of-lading-table tr td {
  padding: 2px !important;
  font-size: calc(14px / 1.2) !important;
}
.bill-of-lading-table tr td .label {
  display: inline-block !important;
}
.bill-of-lading-table tr td .item-settings-dropdown .dropdown-menu {
  top: -20px !important;
}
.bill-of-lading-table tr td .fa {
  font-size: calc(14px / 1.3) !important;
}
.bill-of-lading-table tr td:first-child {
  font-family: 'Regular', sans-serif !important;
  font-size: 14px !important;
}
.bill-of-lading-table td.small-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.bill-of-lading-table td.small-font-cell a,
.bill-of-lading-table td.small-font-cell span,
.bill-of-lading-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.2) !important;
}
.commentaries-table tr td.edit {
  background: #f9f9f7;
}
.commentaries-table tr td.edit .form-control {
  margin-bottom: 2px;
}
.commentaries-table tr td {
  padding: 2px !important;
  white-space: nowrap;
}
.commentaries-table tr td .btn {
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin: 0;
  line-height: 1;
}
.commentaries-table tr td.comments {
  white-space: normal !important;
  word-wrap: break-word !important;
  max-width: 400px;
  text-align: left !important;
}
.crops-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  margin: 0 auto;
  overflow-x: auto;
  padding: 2px;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.crops-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.crops-table th::first-letter {
  text-transform: uppercase !important;
}
.crops-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.crops-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.crops-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.crops-table tr td:nth-child(1) {
  vertical-align: middle;
}
.crops-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.crops-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.crops-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.crops-table .label::first-letter {
  text-transform: uppercase !important;
}
.crops-table .label .fa {
  margin-right: 3px;
}
.crops-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.crops-table .badge {
  padding: 4px !important;
}
.crops-table .badge::first-letter {
  text-transform: uppercase !important;
}
.crops-table a {
  cursor: pointer;
}
.crops-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.crops-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.crops-table .label {
  text-align: left !important;
  display: inline-block !important;
}
.crops-table tr th {
  border-top: 1px solid #beb9b9 !important;
  border-bottom: 2px solid #beb9b9 !important;
  text-align: center !important;
}
.crops-table td {
  width: auto;
  text-align: center;
}
.crops-table td label {
  text-align: left;
}
.crops-table td:nth-child(1) {
  width: 10% !important;
}
.crops-table td:nth-child(2) {
  width: 15% !important;
}
.crops-table td:nth-child(3) {
  width: 10% !important;
}
.crops-table td:nth-child(4) {
  width: 10% !important;
}
.crops-table td:nth-child(5) {
  width: 10% !important;
}
.crops-table td:nth-child(6) {
  width: 5% !important;
}
.crops-table td:nth-child(7) {
  width: 10% !important;
}
.crops-table td:nth-child(8) {
  width: 10% !important;
}
.crops-table td:nth-child(9) {
  width: 10% !important;
}
.crops-table td:nth-child(10) {
  width: 5% !important;
}
.crops-table td:nth-child(11) {
  width: 5% !important;
}
.crops-table .form-control {
  font-size: calc(14px / 1.3) !important;
}
.crops-table .update_time {
  white-space: nowrap !important;
  text-align: left !important;
  color: #000000 !important;
}
.crops-table .fa-times-circle {
  color: #cf6163;
  font-size: calc(14px * 1.2);
  cursor: pointer;
}
.crops-table .fa-pencil-square,
.crops-table .fa-floppy-o {
  font-size: calc(14px * 1.2);
  cursor: pointer;
}
.add-new-inline,
.ul-inline-row.add-new-inline {
  border-left: 6px solid #07422d !important;
}
.crops-edit-table {
  table-layout: fixed !important;
  width: 100% !important;
}
.crops-edit-table .ui-select-bootstrap > .ui-select-match > .btn {
  display: block !important;
}
.crops-edit-table .form-control {
  font-size: 14px !important;
  text-align: left;
  vertical-align: middle !important;
  border-color: #d2cece !important;
  border-radius: 4px !important;
  width: 100% !important;
}
.crops-edit-table .form-control::-webkit-input-placeholder {
  color: #000000 !important;
  font-size: 14px !important;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.crops-edit-table .form-control::-moz-placeholder {
  color: #000000 !important;
  font-size: 14px !important;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.crops-edit-table .form-control:-moz-placeholder {
  color: #000000 !important;
  font-size: 14px !important;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.crops-edit-table .form-control:-ms-input-placeholder {
  color: #000000 !important;
  font-size: 14px !important;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.crops-edit-table .form-control .text-muted {
  color: #000000 !important;
  font-size: 14px !important;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.crops-edit-table .form-control .caret {
  display: none;
}
.crops-edit-table .ui-select-match .form-control {
  line-height: 1;
}
.crops-edit-table .ui-select-match .clear-btn {
  background: #fff !important;
}
.crops-edit-table tr td .input-group {
  width: 100% !important;
}
.crops-edit-table h5 .input-group {
  float: left;
  margin-left: 5px;
  margin-top: -4px !important;
}
.crops-edit-table .input-group .has-error.form-control:first-child,
.crops-edit-table .input-group .ng-invalid-required.form-control:first-child {
  border-bottom-left-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}
.crops-edit-table .form-control:focus {
  box-shadow: 0 0 10px #07422d !important;
}
.crops-edit-table input:focus {
  box-shadow: 0 0 10px #07422d !important;
}
.crops-edit-table tbody > tr > td {
  border-color: transparent !important;
}
.crops-edit-table .ui-select-bootstrap > .ui-select-choices {
  text-align: left !important;
}
.crops-edit-table td {
  padding: 2px;
  width: auto;
}
.crops-edit-table tr th {
  text-align: center;
}
.passport-inline-items-table {
  margin-bottom: 0 !important;
  border-collapse: separate;
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 0.9em;
  text-align: center;
  font-size: 0.9em / 1.1;
  text-overflow: ellipsis;
  width: 98% !important;
}
.passport-inline-items-table tr > td {
  border-top: 1px solid #e8e6e6 !important;
  border-right: 1px solid #f8f6f6 !important;
  line-height: 1 !important;
  vertical-align: middle !important;
  -webkit-text-stroke: 0.2px #000;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.passport-inline-items-table tr > td .hover-element {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  transform: translate3d(0, 8px, 0);
  visibility: hidden;
}
.passport-inline-items-table tr > td .fa-arrow-right.hover-element {
  font-size: calc(14px / 1.4) !important;
}
.passport-inline-items-table tr > td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.passport-inline-items-table tr > td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.passport-inline-items-table tr > td .fa.show-on-hover {
  display: none;
}
.passport-inline-items-table tr > td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.passport-inline-items-table tr > td span {
  line-height: 1 !important;
}
.passport-inline-items-table tr > td .text-muted {
  line-height: 1 !important;
}
.passport-inline-items-table tr > td .input-group-table-edit-btn {
  display: flex;
}
.passport-inline-items-table tr > td .table-edit-btn {
  padding: 0 !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  margin: 0;
  line-height: 1 !important;
  margin-left: 5px;
  font-size: 14px !important;
}
.passport-inline-items-table tr > td .table-edit-btn .fa {
  font-size: 14px !important;
}
.passport-inline-items-table tr > td .table-edit-btn:first-of-type {
  margin-left: 0;
}
.passport-inline-items-table tr > td .info-table-btn:hover {
  border: 1px solid #1d82c7 !important;
}
.passport-inline-items-table tr > td .danger-table-btn:hover {
  border: 1px solid rgba(207, 97, 99, 0.7) !important;
}
.passport-inline-items-table tr > td .warning-table-btn:hover {
  border: 1px solid #7d6643 !important;
}
.passport-inline-items-table tr > td .small {
  color: #666b72 !important;
  margin-top: 5px;
  text-align: left;
}
.passport-inline-items-table tr > td .small .fa,
.passport-inline-items-table tr > td .small .updated_time,
.passport-inline-items-table tr > td .small a,
.passport-inline-items-table tr > td .small span {
  color: #666b72 !important;
  font-size: calc(14px / 1.3) !important;
}
.passport-inline-items-table tr > td .small .btn-link {
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.passport-inline-items-table tr > td .small .btn-link:hover {
  text-decoration: underline;
}
.passport-inline-items-table tr:hover .fa.hide-on-hover {
  display: none;
}
.passport-inline-items-table tr:hover .fa.show-on-hover {
  display: inline-block;
}
.passport-inline-items-table tr:hover td .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
  width: auto;
}
.passport-inline-items-table tr:hover td .fa-sort-desc.hover-element {
  font-size: calc(14px * 1.2) !important;
}
.passport-inline-items-table tr > th {
  border-bottom: 1px solid #e8e6e6 !important;
  border-right: 0;
  letter-spacing: var(--letter-spacing-mega, 0.05em);
}
.passport-inline-items-table tr > th::first-letter {
  text-transform: uppercase !important;
}
.passport-inline-items-table tr th.filter-th {
  background: #f9f9f7 !important;
  border-bottom: 0 !important;
}
.passport-inline-items-table tr:hover th.filter-th {
  background: #f9f9f7 !important;
}
.passport-inline-items-table tr > td:last-child,
.passport-inline-items-table tr > th:last-child {
  border-right: 0 !important;
}
.passport-inline-items-table th {
  color: #666b72;
  font-size: calc(14px / 1.3);
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
  font-family: 'Regular', sans-serif !important;
  cursor: pointer !important;
  vertical-align: middle !important;
}
.passport-inline-items-table th .filter-template .ui-select-match .form-control,
.passport-inline-items-table th .filter-template .ui-select-container .form-control,
.passport-inline-items-table th .form-control {
  font-size: calc(14px / 1.3) !important;
  padding: 0 !important;
  /*  height:20px !important;*/
  width: 100%;
  padding-left: 5px !important;
  max-width: 100%;
  min-width: 100%;
  border-radius: 4px !important;
}
.passport-inline-items-table th .fa.fa-sort {
  display: none;
}
.passport-inline-items-table th .filter-template .ui-select-match .form-control {
  line-height: 2.4;
  width: 100% !important;
}
.passport-inline-items-table th .input-group-addon {
  font-size: calc(14px / 1.3) !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-transform: none !important;
}
.passport-inline-items-table th .form-group {
  padding: 2px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 5px !important;
  width: 100% !important;
}
.passport-inline-items-table th .input-group {
  margin-bottom: 5px;
}
.passport-inline-items-table th select {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.passport-inline-items-table th .caret {
  display: none;
}
.passport-inline-items-table th .text-muted {
  text-transform: none !important;
  color: #000000 !important;
  line-height: 1;
}
.passport-inline-items-table th .big-font {
  font-size: calc(14px * 1.2) !important;
}
.passport-inline-items-table th .btn-link {
  -webkit-text-stroke: 0;
  padding: 0 !important;
}
.passport-inline-items-table th:hover {
  color: #666;
}
.passport-inline-items-table .filter-th:hover {
  background: #fff !important;
}
.passport-inline-items-table td:first-child {
  text-align: left;
  font-family: 'Regular', sans-serif !important;
}
.passport-inline-items-table td:first-child .btn-link {
  color: #1763ad !important;
  border-bottom: 0 !important;
  -webkit-text-stroke: 0;
}
.passport-inline-items-table td:first-child .btn-link:hover {
  text-decoration: underline;
}
.passport-inline-items-table td:first-child .btn-link .fa {
  font-size: calc(14px / 1.3) !important;
}
.passport-inline-items-table td {
  color: #0c4d40;
  text-align: center;
  vertical-align: top;
  border-radius: 0;
  font-family: 'Regular', sans-serif !important;
  line-height: 1;
}
.passport-inline-items-table .item-settings-dropdown:hover .dropdown-menu {
  top: -8px;
  left: 15px;
}
.passport-inline-items-table td:first-child {
  border-left: 5px solid rgba(255, 255, 255, 0) !important;
}
.passport-inline-items-table tbody > tr > th,
.passport-inline-items-table tfoot > tr > th,
.passport-inline-items-table thead > tr > th {
  border-top: 0;
}
.passport-inline-items-table td {
  border-color: #e8e6e6 !important;
  color: #000000;
  vertical-align: middle !important;
}
.passport-inline-items-table tr td .form-control,
.passport-inline-items-table tr th .form-control,
.passport-inline-items-table tr td .form-control .ui-select-match-text,
.passport-inline-items-table tr th .form-control .ui-select-match-text {
  min-height: 34px;
  min-width: 50px !important;
  overflow: hidden;
  margin-bottom: 2px;
  font-size: calc(14px / 1.2) !important;
}
.passport-inline-items-table tr td input.valid-date,
.passport-inline-items-table tr th input.valid-date,
.passport-inline-items-table tr td .gt-date-select input.form-control,
.passport-inline-items-table tr th .gt-date-select input.form-control {
  margin-bottom: 0 !important;
}
.passport-inline-items-table tr td .ui-select-bootstrap > .ui-select-match > .btn,
.passport-inline-items-table tr th .ui-select-bootstrap > .ui-select-match > .btn {
  min-width: 50px !important;
  min-height: 34px;
  height: auto;
  overflow: hidden;
  line-height: 1 !important;
  margin-bottom: 2px;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  font-size: calc(14px / 1.2) !important;
}
.passport-inline-items-table tr th .form-control,
.passport-inline-items-table tr th .form-control .ui-select-match-text,
.passport-inline-items-table tr th .text-muted {
  font-family: 'Regular', sans-serif !important;
}
.passport-inline-items-table tr th .ui-select-bootstrap > .ui-select-match > .btn,
.passport-inline-items-table tr th .form-control {
  min-height: auto;
  height: auto;
  padding: 4px !important;
}
.passport-inline-items-table .td-with-border {
  border-right: 1px solid #e8e6e6 !important;
}
.passport-inline-items-table td:last-child {
  border-right: 0 !important;
}
.passport-inline-items-table .highlighted-tr td {
  background: #f9f9f7 !important;
  border-color: #f0f0eb !important;
  color: #000000 !important;
  border-radius: 0 !important;
}
.passport-inline-items-table .highlighted-tr td:first-child {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-left: 0 !important;
}
.passport-inline-items-table .highlighted-tr td:last-child {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-right: 0 !important;
}
.passport-inline-items-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.passport-inline-items-table td.th-left-align {
  text-align: left !important;
}
.passport-inline-items-table td.th-left-align div {
  text-align: left !important;
}
.passport-inline-items-table td.th-left-align .no-user-avatar {
  text-align: center !important;
}
.passport-inline-items-table td.th-center-align {
  text-align: center !important;
}
.passport-inline-items-table td.th-center-align div {
  text-align: center !important;
}
.passport-inline-items-table td.td-left-align {
  text-align: left !important;
}
.passport-inline-items-table td.td-right-align {
  text-align: right !important;
}
.passport-inline-items-table .highlighted-tr-first-row td {
  font-family: 'Bold', sans-serif !important;
  border-right-color: transparent !important;
}
.passport-inline-items-table .highlighted-tr-first-row td:first-child {
  background: #ebebeb !important;
  border-bottom: 1px solid #e5e5dd !important;
  color: #000000 !important;
  border-right-color: #e8e6e6 !important;
}
.passport-inline-items-table .highlighted-td {
  background: #f9f9f7 !important;
  border-radius: 0 !important;
}
.passport-inline-items-table .highlighted-th {
  background: rgba(0, 0, 0, 0.01) !important;
  vertical-align: top !important;
}
.passport-inline-items-table .highlighted-td.danger-highlighted {
  background: rgba(207, 97, 99, 0.13) !important;
}
.passport-inline-items-table .highlighted-td.warning-highlighted {
  background: #f0ede9 !important;
}
.passport-inline-items-table .highlighted-td.success-highlighted {
  background: #ebf1ef !important;
}
.passport-inline-items-table tr td .ui-select-bootstrap .ui-select-toggle > .caret {
  display: none !important;
}
.passport-inline-items-table .crops-edit-table .input-group-addon {
  display: none;
}
.passport-inline-items-table .crops-edit-table .ui-select-match,
.passport-inline-items-table .crops-edit-table .ui-select-container {
  max-width: 100px;
}
.passport-inline-items-table .table {
  margin-bottom: 0 !important;
}
.passport-inline-items-table tr th {
  font-size: calc(14px / 1.2);
  padding: 2px !important;
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  text-align: right;
}
.passport-inline-items-table tr td {
  padding: 2px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  text-align: right;
  color: #000000;
}
.passport-inline-items-table tr td .label {
  display: inline-flex;
  padding: 2px;
  margin-bottom: 2px;
}
.passport-inline-items-table tr td .label .fa {
  margin-right: 3px;
}
.transshipment-table tr th {
  text-align: center !important;
}
.transshipment-table tr th div {
  text-align: center !important;
}
.transshipment-table tr td {
  border-right: 1px solid #e8e6e6 !important;
}
.transshipment-table tr td .btn {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.payments-edit-table .form-control {
  font-size: 14px !important;
  text-align: left;
  vertical-align: middle !important;
}
.payments-edit-table tbody > tr > td {
  border-color: transparent !important;
}
.payments-edit-table .ui-select-bootstrap > .ui-select-choices {
  text-align: left !important;
}
.payments-edit-table th {
  text-align: center;
}
.payments-edit-table td {
  width: auto !important;
}
.payments-edit-table td:nth-child(1) {
  width: 15% !important;
}
.payment-partial-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  width: 100% !important;
}
.payment-partial-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.payment-partial-table th::first-letter {
  text-transform: uppercase !important;
}
.payment-partial-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.payment-partial-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.payment-partial-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.payment-partial-table tr td:nth-child(1) {
  vertical-align: middle;
}
.payment-partial-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.payment-partial-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.payment-partial-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.payment-partial-table .label::first-letter {
  text-transform: uppercase !important;
}
.payment-partial-table .label .fa {
  margin-right: 3px;
}
.payment-partial-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.payment-partial-table .badge {
  padding: 4px !important;
}
.payment-partial-table .badge::first-letter {
  text-transform: uppercase !important;
}
.payment-partial-table a {
  cursor: pointer;
}
.payment-partial-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.payment-partial-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.payment-partial-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.payment-partial-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.payment-partial-table .datetimepicker thead tr th:nth-child(1),
.payment-partial-table .datetimepicker thead tr th:nth-child(2),
.payment-partial-table .datetimepicker thead tr th:nth-child(3),
.payment-partial-table .datetimepicker tbody tr td:nth-child(1),
.payment-partial-table .datetimepicker tbody tr td:nth-child(2),
.payment-partial-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.payment-partial-table .datetimepicker .table thead tr th:nth-child(6),
.payment-partial-table .datetimepicker .table thead tr th:nth-child(7),
.payment-partial-table .datetimepicker .table tbody tr td:nth-child(6),
.payment-partial-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.payment-partial-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.payment-partial-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.payment-partial-table tr td {
  width: auto;
  font-size: calc(14px / 1.1) !important;
  white-space: nowrap;
  padding: 2px !important;
  line-height: 1 !important;
}
.payment-partial-table tr td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.payment-partial-table tr td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.payment-partial-table tr td .label {
  white-space: nowrap;
  width: auto !important;
  font-size: calc(14px / 1.1) !important;
  text-align: left;
  padding: 4px !important;
}
.payment-partial-table tr td .label_default.label-opacity {
  background: transparent !important;
}
.payment-partial-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.payment-partial-table td.td-left-align {
  text-align: left !important;
}
.payment-partial-table td.td-right-align {
  text-align: right !important;
}
.payment-partial-table td.td-center-align {
  text-align: center !important;
}
.payment-partial-table td.nowrap {
  white-space: nowrap !important;
}
.payment-partial-table td.update_time {
  white-space: nowrap !important;
  color: #000000 !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: initial;
  min-width: 140px;
}
.payment-partial-table td.update_time .label {
  margin-left: 0;
  text-align: left !important;
  display: inline !important;
}
.payment-partial-table td.updates {
  min-width: 110px;
}
.payment-partial-table td.company {
  vertical-align: middle;
  white-space: nowrap !important;
  text-align: left !important;
  font-family: 'Regular', sans-serif !important;
}
.payment-partial-table td.company::first-letter {
  text-transform: uppercase !important;
}
.payment-partial-table td.company .btn-link {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
}
.payment-partial-table td.company .btn-link:hover {
  text-decoration: underline;
}
.payment-partial-table td.company .fa-arrow-right {
  font-size: calc(14px / 1.4) !important;
}
.payment-partial-table td.company .label {
  text-align: left;
  margin-left: 0 !important;
}
.payment-partial-table td.small-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.payment-partial-table td.small-font-cell a,
.payment-partial-table td.small-font-cell span,
.payment-partial-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.payment-partial-table td.info {
  white-space: nowrap;
}
.payment-partial-table .contacts,
.payment-partial-table .deals,
.payment-partial-table .dates {
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.01) !important;
}
.payment-partial-table a.modal-btn {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
  -webkit-text-stroke: 0;
}
.payment-partial-table a.modal-btn:hover {
  border-bottom: 1px dashed #000000 !important;
  transform: scale(1.05);
  transition: transform 0.2s;
}
.payment-partial-table .item-settings-dropdown a:nth-of-type(1) .fa-cog {
  font-size: 14px !important;
}
.payment-partial-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.payment-partial-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.payment-partial-table .datetimepicker thead tr th:nth-child(1),
.payment-partial-table .datetimepicker thead tr th:nth-child(2),
.payment-partial-table .datetimepicker thead tr th:nth-child(3),
.payment-partial-table .datetimepicker tbody tr td:nth-child(1),
.payment-partial-table .datetimepicker tbody tr td:nth-child(2),
.payment-partial-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.payment-partial-table .datetimepicker .table thead tr th:nth-child(6),
.payment-partial-table .datetimepicker .table thead tr th:nth-child(7),
.payment-partial-table .datetimepicker .table tbody tr td:nth-child(6),
.payment-partial-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.payment-partial-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.payment-partial-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.payment-partial-table tr th {
  padding-right: 6px !important;
  padding-left: 6px !important;
  text-align: center !important;
  border-right: 1px solid #f8f6f6 !important;
}
.payment-partial-table tr td.company {
  white-space: normal !important;
}
.payment-partial-table tr td.company span {
  font-size: calc(14px / 1.2) !important;
}
.payment-partial-table tr > td .text-muted {
  line-height: 1.1 !important;
}
.payment-partial-table tr td {
  border: 0 !important;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 2px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  white-space: normal;
  word-wrap: break-word;
  border-right: 1px solid #f8f6f6 !important;
  border-bottom: 1px solid #f8f6f6 !important;
}
.payment-partial-table tr td .fa {
  font-size: calc(14px / 1.2) !important;
  white-space: nowrap;
}
.payment-partial-table tr td.nowrap {
  white-space: nowrap !important;
}
.payment-partial-table td.alert-success {
  background: #ebf1ef !important;
}
.payment-partial-table td.alert-danger {
  background: rgba(207, 97, 99, 0.13) !important;
}
.payment-partial-table tr {
  border-bottom: 1px solid #e8e6e6 !important;
}
.payment-partial-table tbody {
  border: 0 !important;
}
.payment-partial-table tfoot tr.highlighted-tr > td {
  background: #f9f9f7 !important;
}
.payment-partial-table .bigger-label.positive-number {
  font-size: 14px !important;
  font-family: 'Regular', sans-serif !important;
}
.payment-partial-table .bigger-label.negative-number {
  font-size: 14px !important;
  font-family: 'Regular', sans-serif !important;
}
.payment-partial-table .span-left {
  float: left !important;
}
.payment-partial-table .span-right {
  float: right !important;
}
.payment-partial-table .span-center {
  float: none !important;
}
.payment-partial-table .form-control {
  height: auto !important;
  font-size: 14px;
  font-family: 'Semibold', sans-serif, 'FontAwesome' !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
.payment-partial-table .form-control span {
  font-size: 14px;
}
.payment-partial-table tr td:first-child {
  text-align: left !important;
}
.payment-partial-table tr td:first-child .form-control {
  line-height: 1.5;
  font-family: 'Semibold', sans-serif 'FontAwesome' !important;
}
.payment-partial-table tr td:first-child .form-control span {
  font-size: 14px;
  font-family: 'Semibold', sans-serif 'FontAwesome' !important;
}
.payment-partial-table .ui-select-bootstrap > .ui-select-match > .btn {
  line-height: 1 !important;
}
.facts-edit-table td.nowrap {
  white-space: nowrap;
}
.facts-edit-table tr td {
  padding: 2px !important;
  border-right: 1px solid #e8e6e6 !important;
}
.facts-edit-table tr td input[type='checkbox'],
.facts-edit-table tr td input[type='radio'] {
  text-align: center !important;
  float: none !important;
  margin: 0 auto;
}
.fixed-width-column {
  width: 105px !important;
}
.crops-table-container {
  overflow-x: auto;
}
.manager-table-details .label,
.manager-table-details .badge {
  font-size: 14px;
}
.manager-table-details tr td {
  font-size: 14px;
}
.manager-table-details .contract-description-label {
  margin-bottom: 0 !important;
}
.manager-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  width: 100% !important;
}
.manager-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.manager-table th::first-letter {
  text-transform: uppercase !important;
}
.manager-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.manager-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.manager-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.manager-table tr td:nth-child(1) {
  vertical-align: middle;
}
.manager-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.manager-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.manager-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.manager-table .label::first-letter {
  text-transform: uppercase !important;
}
.manager-table .label .fa {
  margin-right: 3px;
}
.manager-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.manager-table .badge {
  padding: 4px !important;
}
.manager-table .badge::first-letter {
  text-transform: uppercase !important;
}
.manager-table a {
  cursor: pointer;
}
.manager-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.manager-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.manager-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.manager-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.manager-table .datetimepicker thead tr th:nth-child(1),
.manager-table .datetimepicker thead tr th:nth-child(2),
.manager-table .datetimepicker thead tr th:nth-child(3),
.manager-table .datetimepicker tbody tr td:nth-child(1),
.manager-table .datetimepicker tbody tr td:nth-child(2),
.manager-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.manager-table .datetimepicker .table thead tr th:nth-child(6),
.manager-table .datetimepicker .table thead tr th:nth-child(7),
.manager-table .datetimepicker .table tbody tr td:nth-child(6),
.manager-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.manager-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.manager-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.manager-table tr td {
  width: auto;
  font-size: calc(14px / 1.1) !important;
  white-space: nowrap;
  padding: 2px !important;
  line-height: 1 !important;
}
.manager-table tr td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.manager-table tr td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.manager-table tr td .label {
  white-space: nowrap;
  width: auto !important;
  font-size: calc(14px / 1.1) !important;
  text-align: left;
  padding: 4px !important;
}
.manager-table tr td .label_default.label-opacity {
  background: transparent !important;
}
.manager-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.manager-table td.td-left-align {
  text-align: left !important;
}
.manager-table td.td-right-align {
  text-align: right !important;
}
.manager-table td.td-center-align {
  text-align: center !important;
}
.manager-table td.nowrap {
  white-space: nowrap !important;
}
.manager-table td.update_time {
  white-space: nowrap !important;
  color: #000000 !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: initial;
  min-width: 140px;
}
.manager-table td.update_time .label {
  margin-left: 0;
  text-align: left !important;
  display: inline !important;
}
.manager-table td.updates {
  min-width: 110px;
}
.manager-table td.company {
  vertical-align: middle;
  white-space: nowrap !important;
  text-align: left !important;
  font-family: 'Regular', sans-serif !important;
}
.manager-table td.company::first-letter {
  text-transform: uppercase !important;
}
.manager-table td.company .btn-link {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
}
.manager-table td.company .btn-link:hover {
  text-decoration: underline;
}
.manager-table td.company .fa-arrow-right {
  font-size: calc(14px / 1.4) !important;
}
.manager-table td.company .label {
  text-align: left;
  margin-left: 0 !important;
}
.manager-table td.small-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.manager-table td.small-font-cell a,
.manager-table td.small-font-cell span,
.manager-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.manager-table td.info {
  white-space: nowrap;
}
.manager-table .contacts,
.manager-table .deals,
.manager-table .dates {
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.01) !important;
}
.manager-table a.modal-btn {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
  -webkit-text-stroke: 0;
}
.manager-table a.modal-btn:hover {
  border-bottom: 1px dashed #000000 !important;
  transform: scale(1.05);
  transition: transform 0.2s;
}
.manager-table .item-settings-dropdown a:nth-of-type(1) .fa-cog {
  font-size: 14px !important;
}
.manager-table tr td {
  text-align: left !important;
}
.manager-table .margin {
  text-align: center !important;
}
.contract-charges-table tr th {
  min-width: 30px;
  word-wrap: break-word !important;
}
.contract-charges-table tr td {
  padding: 2px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  vertical-align: middle !important;
  white-space: nowrap;
  font-size: calc(14px / 1.2) !important;
}
.contract-charges-table tr td .secondary-text {
  white-space: nowrap !important;
}
.contract-charges-table tr td .secondary-text.wrap-text {
  white-space: normal !important;
}
.contract-charges-table tr td .badge {
  margin-bottom: 2px;
  margin-top: 2px;
}
.contract-charges-table tr td .open > .dropdown-menu {
  position: relative;
}
.contract-charges-table tr td .label {
  display: inline-block;
  font-size: calc(14px / 1.2);
  padding: 4px !important;
  line-height: 1;
  margin: 0;
}
.contract-charges-table tr td .fa-plus,
.contract-charges-table tr td .fa-minus {
  margin-right: 2px;
}
.contract-charges-table tr td .fa {
  font-size: calc(14px / 1.4) !important;
}
.contract-charges-table tr td ul {
  margin-bottom: 0;
}
.contract-charges-table tr td .form-control {
  height: auto !important;
  font-family: 'Regular', sans-serif, 'FontAwesome' !important;
  overflow: visible;
  white-space: normal;
  width: 100% !important;
  max-width: 100% !important;
  border-color: #b0a9a9 !important;
}
.contract-charges-table tr td .form-control span {
  font-size: calc(14px / 1.2);
}
.contract-charges-table tr td .alert-success .ui-select-container .form-control {
  border-color: #b0a9a9 !important;
}
.contract-charges-table tr td .ui-select-bootstrap .ui-select-choices-row > span {
  white-space: normal;
  min-width: 200px;
}
.contract-charges-table tr td input[type='checkbox'] {
  margin: 0;
}
.contract-charges-table tr td .badge-block {
  width: 100%;
  display: block;
  padding: 2px !important;
}
.contract-charges-table td.minWidth {
  min-width: 120px !important;
}
.contract-charges-table .inside-table-ul span.smaller-label {
  font-size: calc(14px / 1.3) !important;
}
.contract-charges-table .inside-table-ul li {
  text-align: left !important;
}
.contract-charges-table .inside-table-ul li:last-child {
  border-bottom: 0 !important;
}
.contract-charges-table tr td.edit {
  background: #f9f9f7;
}
.contract-charges-table tr td.edit .form-control .ui-select-match-text {
  font-family: 'Regular', sans-serif !important;
}
.contract-charges-table tbody:nth-of-type(1) tr td.edit:nth-child(1) {
  border-left: 5px solid #0c4d40 !important;
}
.contract-charges-table tr td.nowrap {
  white-space: nowrap !important;
}
ul.inside-table {
  margin-bottom: 2px !important;
  border-radius: 8px;
  background: #c7c7b4;
  padding: 2px;
}
.contract-costs-table tr td,
.contract-quality-table tr td {
  font-size: calc(14px / 1.2);
  white-space: normal;
  vertical-align: middle !important;
  text-align: right;
}
.contract-costs-table tr td .label,
.contract-quality-table tr td .label,
.contract-costs-table tr td label,
.contract-quality-table tr td label,
.contract-costs-table tr td .badge,
.contract-quality-table tr td .badge {
  font-size: calc(14px / 1.2);
}
.contract-costs-table tr td .btn,
.contract-quality-table tr td .btn {
  line-height: 1;
  font-size: calc(14px / 1.2);
}
.contract-costs-table tr td:first-child,
.contract-quality-table tr td:first-child {
  font-size: calc(14px / 1.2);
  font-family: 'Regular', sans-serif !important;
  color: #000000;
}
.contract-costs-table tr td:first-child::first-letter,
.contract-quality-table tr td:first-child::first-letter {
  text-transform: uppercase !important;
}
.contract-costs-table tr td:first-child .fa-arrows,
.contract-quality-table tr td:first-child .fa-arrows {
  visibility: hidden;
}
.contract-costs-table tr:hover td:first-child .fa-arrows,
.contract-quality-table tr:hover td:first-child .fa-arrows {
  visibility: visible;
}
.contract-costs-table .label,
.contract-quality-table .label {
  width: auto !important;
}
.contract-costs-table .ui-select-bootstrap > .ui-select-match > .btn,
.contract-quality-table .ui-select-bootstrap > .ui-select-match > .btn {
  line-height: 2 !important;
}
.contract-costs-table .form-control,
.contract-quality-table .form-control {
  height: 22px;
}
.contract-costs-table .modal-content .input-group-addon,
.contract-quality-table .modal-content .input-group-addon {
  background: #f9f9f7 !important;
}
.contract-costs-table tr th.thick-border,
.contract-quality-table tr th.thick-border,
.contract-costs-table tr td.thick-border,
.contract-quality-table tr td.thick-border {
  border-right: 3px solid #e8e6e6 !important;
}
.contract-costs-table td.small-font-cell,
.contract-quality-table td.small-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.contract-costs-table td.small-font-cell a,
.contract-quality-table td.small-font-cell a,
.contract-costs-table td.small-font-cell span,
.contract-quality-table td.small-font-cell span,
.contract-costs-table td.small-font-cell .btn-link,
.contract-quality-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.contract-quality-table .form-control {
  height: 20px;
}
.contract-quality-table .input-group-addon {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.contract-quality-table .highlighted-tr td {
  background: #9bbacd !important;
}
.contract-quality-table tr td {
  white-space: normal !important;
}
.prices-calc-table tr td {
  text-align: left !important;
}
.prices-calc-table tr td .badge,
.prices-calc-table tr td .label {
  font-size: 14px;
}
.booking-confirmations-table tr th {
  white-space: normal;
}
.booking-confirmations-table tr td {
  white-space: normal;
  word-break: break-all;
  text-align: left !important;
}
.booking-confirmations-table tr td .btn {
  padding: 2px !important;
  padding-left: 3px !important;
}
.booking-confirmations-table tr td .bigger-label {
  font-size: 14px !important;
  font-family: 'Semibold', sans-serif !important;
}
.booking-confirmations-table tr td:nth-child(1) {
  font-size: 14px !important;
  font-family: 'Semibold', sans-serif !important;
  color: #000000 !important;
}
.booking-confirmations-table tr td:nth-child(1) .btn-link {
  font-family: 'Semibold', sans-serif !important;
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.booking-confirmations-table tr td:nth-child(1) .btn-link:hover {
  text-decoration: underline;
}
.booking-confirmations-table tr.highlighted-tr td {
  background: rgba(0, 0, 0, 0.03) !important;
}
.booking-confirmations-table tr.add-new-inline td {
  background: #ebf1ef !important;
}
.booking-confirmations-table .ui-select-bootstrap > .ui-select-match > .btn,
.booking-confirmations-table .form-control {
  height: 20px !important;
  line-height: 1 !important;
}
.booking-confirmations-table .child-padding-1 {
  padding-left: 25px !important;
}
.booking-confirmations-table .child-padding-2 {
  padding-left: 50px !important;
}
.booking-confirmations-table .child-padding-3 {
  padding-left: 75px !important;
}
.booking-confirmations-table .child-padding-n {
  padding-left: 100px !important;
}
.booking-confirmations-table .crops-edit-table .form-control .ui-select-match-text {
  padding-top: 0 !important;
}
.booking-confirmations-table .crops-edit-table .ui-select-match .clear-btn {
  padding-top: 2px !important;
}
.table-with-fixed-columns {
  table-layout: initial !important;
}
.table-with-fixed-columns thead > tr th,
.table-with-fixed-columns tbody > tr td {
  width: 150px !important;
  min-width: 150px !important;
}
.table-with-fixed-columns thead > tr th:nth-child(1),
.table-with-fixed-columns tbody > tr td:nth-child(1) {
  width: 300px !important;
  min-width: 300px !important;
}
.debit-credit-table {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 10px;
}
.debit-credit-table tr th {
  white-space: nowrap;
  padding: 2px !important;
  text-align: center !important;
  border-right: 1px solid #e8e6e6 !important;
}
.debit-credit-table tr th .th-right-align {
  text-align: center !important;
}
.debit-credit-table tr th.thick-border,
.debit-credit-table tr td.thick-border {
  border-right: 1px solid #e8e6e6 !important;
}
.debit-credit-table thead > tr > th,
.debit-credit-table tbody > tr > td {
  width: 80px !important;
  min-width: 80px !important;
  overflow: hidden;
}
.debit-credit-table tr td {
  white-space: nowrap;
  padding: 2px !important;
  text-align: right !important;
  border-right: 1px solid #e8e6e6 !important;
  font-size: calc(14px / 1.2);
  line-height: 1 !important;
}
.debit-credit-table tr td .alert {
  padding: 2px !important;
  margin: 0 !important;
}
.debit-credit-table tr td .bigger-label,
.debit-credit-table tr td .label-strong,
.debit-credit-table tr td .label {
  font-family: 'Semibold', sans-serif !important;
  font-size: calc(14px / 1.2) !important;
}
.debit-credit-table tr td .btn {
  padding: 2px !important;
  padding-left: 4px !important;
  margin: 0 !important;
  line-height: 1 !important;
  font-size: calc(14px / 1.2) !important;
}
.debit-credit-table tr td h5 {
  padding: 0 !important;
  margin: 0 !important;
  display: inline;
  font-family: 'Regular', sans-serif !important;
  color: #000000 !important;
  font-size: calc(14px / 1.1) !important;
}
.debit-credit-table tr td .label-no-padding-right {
  padding-right: 0 !important;
}
.debit-credit-table tr td .label {
  padding: 0 !important;
  margin: 0 !important;
}
.debit-credit-table tr th:nth-child(2),
.debit-credit-table tr td:nth-child(2) {
  width: 150px !important;
  min-width: 150px !important;
}
.debit-credit-table tr th:nth-child(1),
.debit-credit-table tr td:nth-child(1) {
  text-align: left !important;
  width: 150px !important;
  white-space: normal !important;
}
.debit-credit-table tr th:nth-child(1) .btn,
.debit-credit-table tr td:nth-child(1) .btn {
  font-size: calc(14px / 1.2) !important;
  font-family: 'Semibold', sans-serif !important;
  text-align: left !important;
  white-space: normal !important;
}
.debit-credit-table tr th:last-child {
  border-right: 0 !important;
}
.sticky-table-first-td tr > td,
table.sticky-table-first-td tr > td {
  height: 30px;
  min-height: 30px;
  padding: 2px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.sticky-table-first-td tr > td .label,
table.sticky-table-first-td tr > td .label {
  margin-top: 0;
  margin-bottom: 0;
}
.sticky-table-first-td thead > tr > th div,
table.sticky-table-first-td thead > tr > th div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.sticky-table-first-td .contract-column,
table.sticky-table-first-td .contract-column {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sticky-table-first-td .number-column,
table.sticky-table-first-td .number-column {
  max-width: 195px;
  white-space: nowrap;
}
.sticky-table-first-td .number-column:hover::after,
table.sticky-table-first-td .number-column:hover::after {
  content: attr(data-fulltext);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.sticky-table-first-td tfoot > tr > td:first-child,
table.sticky-table-first-td tfoot > tr > td:first-child,
.sticky-table-first-td tbody > tr > td:first-child,
table.sticky-table-first-td tbody > tr > td:first-child,
.sticky-table-first-td thead > tr > th:first-child,
table.sticky-table-first-td thead > tr > th:first-child {
  position: sticky;
  left: 0;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
  min-width: 195px;
}
.sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown,
table.sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown,
table.sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown,
table.sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown,
.sticky-table-first-td tfoot > tr > td:first-child .initial-transform,
table.sticky-table-first-td tfoot > tr > td:first-child .initial-transform,
.sticky-table-first-td tbody > tr > td:first-child .initial-transform,
table.sticky-table-first-td tbody > tr > td:first-child .initial-transform,
.sticky-table-first-td thead > tr > th:first-child .initial-transform,
table.sticky-table-first-td thead > tr > th:first-child .initial-transform {
  white-space: nowrap;
  font-family: 'Regular', sans-serif !important;
}
.sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown .fa,
table.sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown .fa,
table.sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown .fa,
table.sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown .fa,
.sticky-table-first-td tfoot > tr > td:first-child .initial-transform .fa,
table.sticky-table-first-td tfoot > tr > td:first-child .initial-transform .fa,
.sticky-table-first-td tbody > tr > td:first-child .initial-transform .fa,
table.sticky-table-first-td tbody > tr > td:first-child .initial-transform .fa,
.sticky-table-first-td thead > tr > th:first-child .initial-transform .fa,
table.sticky-table-first-td thead > tr > th:first-child .initial-transform .fa {
  font-size: calc(14px / 1.1);
}
.sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
table.sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
table.sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown > .dropdown-menu,
table.sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown > .dropdown-menu {
  z-index: 9999 !important;
}
.sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown,
table.sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown,
table.sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown,
table.sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown {
  width: 93%;
}
.sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown.actions-btn,
table.sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown.actions-btn,
table.sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown.actions-btn,
table.sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown.actions-btn {
  width: auto;
}
.sticky-table-first-td tfoot > tr > td:first-child .label,
table.sticky-table-first-td tfoot > tr > td:first-child .label,
.sticky-table-first-td tbody > tr > td:first-child .label,
table.sticky-table-first-td tbody > tr > td:first-child .label,
.sticky-table-first-td thead > tr > th:first-child .label,
table.sticky-table-first-td thead > tr > th:first-child .label {
  margin-top: 10px !important;
}
.sticky-table-first-td .contract_detail_table tbody > tr > td:first-child,
table.sticky-table-first-td .contract_detail_table tbody > tr > td:first-child,
.sticky-table-first-td .inside-table tbody > tr > td:first-child,
table.sticky-table-first-td .inside-table tbody > tr > td:first-child,
.sticky-table-first-td .counterparty_detail_table tbody > tr > td:first-child,
table.sticky-table-first-td .counterparty_detail_table tbody > tr > td:first-child {
  position: relative;
  left: 0;
  width: 70px !important;
  display: table-cell;
  z-index: 0 !important;
}
.sticky-table-first-td tbody > tr > td:first-child.no-data-row,
table.sticky-table-first-td tbody > tr > td:first-child.no-data-row {
  position: relative;
  left: 0;
  width: auto !important;
}
.sticky-table-first-td thead > tr > th:first-child,
table.sticky-table-first-td thead > tr > th:first-child {
  background: #f7f7f7 !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 51px;
  min-height: 51px;
}
.sticky-table-first-td tfoot > tr > td:first-child,
table.sticky-table-first-td tfoot > tr > td:first-child {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-td tr.highlighted-tr > td,
table.sticky-table-first-td tr.highlighted-tr > td {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-td tr.sub-total-row.highlighted-tr > td,
table.sticky-table-first-td tr.sub-total-row.highlighted-tr > td {
  height: 30px !important;
  min-height: 30px !important;
}
.sticky-table-first-td tfoot tr td,
table.sticky-table-first-td tfoot tr td {
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}
.sticky-table-first-td .navbar-nav > li > .dropdown-menu,
table.sticky-table-first-td .navbar-nav > li > .dropdown-menu {
  z-index: 999;
  width: 100%;
}
.sticky-table-first-td table.basis-port-prices-table thead > tr > th:first-child,
table.sticky-table-first-td table.basis-port-prices-table thead > tr > th:first-child,
.sticky-table-first-td table.basis-port-prices-table tbody > tr > td:first-child,
table.sticky-table-first-td table.basis-port-prices-table tbody > tr > td:first-child {
  z-index: 0 !important;
}
.sticky-table-first-td-condensed tr > td {
  height: 30px;
  min-height: 30px;
  padding: 2px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.sticky-table-first-td-condensed tr > td .label {
  margin-top: 0;
  margin-bottom: 0;
}
.sticky-table-first-td-condensed thead > tr > th div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.sticky-table-first-td-condensed .contract-column {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sticky-table-first-td-condensed .number-column {
  max-width: 195px;
  white-space: nowrap;
}
.sticky-table-first-td-condensed .number-column:hover::after {
  content: attr(data-fulltext);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.sticky-table-first-td-condensed tfoot > tr > td:first-child,
.sticky-table-first-td-condensed tbody > tr > td:first-child,
.sticky-table-first-td-condensed thead > tr > th:first-child {
  position: sticky;
  left: 0;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
  min-width: 195px;
}
.sticky-table-first-td-condensed tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-condensed tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-condensed thead > tr > th:first-child .item-settings-dropdown,
.sticky-table-first-td-condensed tfoot > tr > td:first-child .initial-transform,
.sticky-table-first-td-condensed tbody > tr > td:first-child .initial-transform,
.sticky-table-first-td-condensed thead > tr > th:first-child .initial-transform {
  white-space: nowrap;
  font-family: 'Regular', sans-serif !important;
}
.sticky-table-first-td-condensed tfoot > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-condensed tbody > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-condensed thead > tr > th:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-condensed tfoot > tr > td:first-child .initial-transform .fa,
.sticky-table-first-td-condensed tbody > tr > td:first-child .initial-transform .fa,
.sticky-table-first-td-condensed thead > tr > th:first-child .initial-transform .fa {
  font-size: calc(14px / 1.1);
}
.sticky-table-first-td-condensed tfoot > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-td-condensed tbody > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-td-condensed thead > tr > th:first-child .item-settings-dropdown > .dropdown-menu {
  z-index: 9999 !important;
}
.sticky-table-first-td-condensed tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-condensed tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-condensed thead > tr > th:first-child .item-settings-dropdown {
  width: 93%;
}
.sticky-table-first-td-condensed tfoot > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-td-condensed tbody > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-td-condensed thead > tr > th:first-child .item-settings-dropdown.actions-btn {
  width: auto;
}
.sticky-table-first-td-condensed tfoot > tr > td:first-child .label,
.sticky-table-first-td-condensed tbody > tr > td:first-child .label,
.sticky-table-first-td-condensed thead > tr > th:first-child .label {
  margin-top: 10px !important;
}
.sticky-table-first-td-condensed .contract_detail_table tbody > tr > td:first-child,
.sticky-table-first-td-condensed .inside-table tbody > tr > td:first-child,
.sticky-table-first-td-condensed .counterparty_detail_table tbody > tr > td:first-child {
  position: relative;
  left: 0;
  width: 70px !important;
  display: table-cell;
  z-index: 0 !important;
}
.sticky-table-first-td-condensed tbody > tr > td:first-child.no-data-row {
  position: relative;
  left: 0;
  width: auto !important;
}
.sticky-table-first-td-condensed thead > tr > th:first-child {
  background: #f7f7f7 !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 51px;
  min-height: 51px;
}
.sticky-table-first-td-condensed tfoot > tr > td:first-child {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-td-condensed tr.highlighted-tr > td {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-td-condensed tr.sub-total-row.highlighted-tr > td {
  height: 30px !important;
  min-height: 30px !important;
}
.sticky-table-first-td-condensed tfoot tr td {
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}
.sticky-table-first-td-condensed .navbar-nav > li > .dropdown-menu {
  z-index: 999;
  width: 100%;
}
.sticky-table-first-td-condensed table.basis-port-prices-table thead > tr > th:first-child,
.sticky-table-first-td-condensed table.basis-port-prices-table tbody > tr > td:first-child {
  z-index: 0 !important;
}
.sticky-table-first-td-condensed tr > td {
  height: 25px !important;
}
.sticky-table-first-td-condensed tbody > tr > td:first-child .item-settings-dropdown {
  padding-top: 3px !important;
  margin-top: 0 !important;
}
.sticky-table-first-td-condensed tbody > tr > td:first-child .label,
.sticky-table-first-td-condensed tbody > tr > td:first-child span {
  margin-top: 3px !important;
}
.sticky-table-first-td-ultra-condensed tr > td {
  height: 30px;
  min-height: 30px;
  padding: 2px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.sticky-table-first-td-ultra-condensed tr > td .label {
  margin-top: 0;
  margin-bottom: 0;
}
.sticky-table-first-td-ultra-condensed thead > tr > th div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.sticky-table-first-td-ultra-condensed .contract-column {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sticky-table-first-td-ultra-condensed .number-column {
  max-width: 195px;
  white-space: nowrap;
}
.sticky-table-first-td-ultra-condensed .number-column:hover::after {
  content: attr(data-fulltext);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child,
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child,
.sticky-table-first-td-ultra-condensed thead > tr > th:first-child {
  position: sticky;
  left: 0;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
  min-width: 195px;
}
.sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-ultra-condensed thead > tr > th:first-child .item-settings-dropdown,
.sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child .initial-transform,
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .initial-transform,
.sticky-table-first-td-ultra-condensed thead > tr > th:first-child .initial-transform {
  white-space: nowrap;
  font-family: 'Regular', sans-serif !important;
}
.sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-ultra-condensed thead > tr > th:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child .initial-transform .fa,
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .initial-transform .fa,
.sticky-table-first-td-ultra-condensed thead > tr > th:first-child .initial-transform .fa {
  font-size: calc(14px / 1.1);
}
.sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-td-ultra-condensed thead > tr > th:first-child .item-settings-dropdown > .dropdown-menu {
  z-index: 9999 !important;
}
.sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-ultra-condensed thead > tr > th:first-child .item-settings-dropdown {
  width: 93%;
}
.sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-td-ultra-condensed thead > tr > th:first-child .item-settings-dropdown.actions-btn {
  width: auto;
}
.sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child .label,
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .label,
.sticky-table-first-td-ultra-condensed thead > tr > th:first-child .label {
  margin-top: 10px !important;
}
.sticky-table-first-td-ultra-condensed .contract_detail_table tbody > tr > td:first-child,
.sticky-table-first-td-ultra-condensed .inside-table tbody > tr > td:first-child,
.sticky-table-first-td-ultra-condensed .counterparty_detail_table tbody > tr > td:first-child {
  position: relative;
  left: 0;
  width: 70px !important;
  display: table-cell;
  z-index: 0 !important;
}
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child.no-data-row {
  position: relative;
  left: 0;
  width: auto !important;
}
.sticky-table-first-td-ultra-condensed thead > tr > th:first-child {
  background: #f7f7f7 !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 51px;
  min-height: 51px;
}
.sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-td-ultra-condensed tr.highlighted-tr > td {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-td-ultra-condensed tr.sub-total-row.highlighted-tr > td {
  height: 30px !important;
  min-height: 30px !important;
}
.sticky-table-first-td-ultra-condensed tfoot tr td {
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}
.sticky-table-first-td-ultra-condensed .navbar-nav > li > .dropdown-menu {
  z-index: 999;
  width: 100%;
}
.sticky-table-first-td-ultra-condensed table.basis-port-prices-table thead > tr > th:first-child,
.sticky-table-first-td-ultra-condensed table.basis-port-prices-table tbody > tr > td:first-child {
  z-index: 0 !important;
}
.sticky-table-first-td-ultra-condensed tr > td {
  height: 20px !important;
}
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .item-settings-dropdown {
  padding-top: 2px !important;
  margin-top: 0 !important;
}
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .label,
.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child span {
  margin-top: 2px !important;
}
.sticky-table-first-td-ultra-condensed-tiny-column {
  margin-left: 148px !important;
}
.sticky-table-first-td-ultra-condensed-tiny-column tfoot > tr > td:first-child,
.sticky-table-first-td-ultra-condensed-tiny-column tbody > tr > td:first-child,
.sticky-table-first-td-ultra-condensed-tiny-column thead > tr > th:first-child {
  position: absolute;
  left: 10px;
  width: 160px !important;
  white-space: normal;
}
.sticky-table-first-td-expanded tr > td {
  height: 30px;
  min-height: 30px;
  padding: 2px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.sticky-table-first-td-expanded tr > td .label {
  margin-top: 0;
  margin-bottom: 0;
}
.sticky-table-first-td-expanded thead > tr > th div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.sticky-table-first-td-expanded .contract-column {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sticky-table-first-td-expanded .number-column {
  max-width: 195px;
  white-space: nowrap;
}
.sticky-table-first-td-expanded .number-column:hover::after {
  content: attr(data-fulltext);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.sticky-table-first-td-expanded tfoot > tr > td:first-child,
.sticky-table-first-td-expanded tbody > tr > td:first-child,
.sticky-table-first-td-expanded thead > tr > th:first-child {
  position: sticky;
  left: 0;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
  min-width: 195px;
}
.sticky-table-first-td-expanded tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-expanded tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-expanded thead > tr > th:first-child .item-settings-dropdown,
.sticky-table-first-td-expanded tfoot > tr > td:first-child .initial-transform,
.sticky-table-first-td-expanded tbody > tr > td:first-child .initial-transform,
.sticky-table-first-td-expanded thead > tr > th:first-child .initial-transform {
  white-space: nowrap;
  font-family: 'Regular', sans-serif !important;
}
.sticky-table-first-td-expanded tfoot > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-expanded tbody > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-expanded thead > tr > th:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-expanded tfoot > tr > td:first-child .initial-transform .fa,
.sticky-table-first-td-expanded tbody > tr > td:first-child .initial-transform .fa,
.sticky-table-first-td-expanded thead > tr > th:first-child .initial-transform .fa {
  font-size: calc(14px / 1.1);
}
.sticky-table-first-td-expanded tfoot > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-td-expanded tbody > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-td-expanded thead > tr > th:first-child .item-settings-dropdown > .dropdown-menu {
  z-index: 9999 !important;
}
.sticky-table-first-td-expanded tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-expanded tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-expanded thead > tr > th:first-child .item-settings-dropdown {
  width: 93%;
}
.sticky-table-first-td-expanded tfoot > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-td-expanded tbody > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-td-expanded thead > tr > th:first-child .item-settings-dropdown.actions-btn {
  width: auto;
}
.sticky-table-first-td-expanded tfoot > tr > td:first-child .label,
.sticky-table-first-td-expanded tbody > tr > td:first-child .label,
.sticky-table-first-td-expanded thead > tr > th:first-child .label {
  margin-top: 10px !important;
}
.sticky-table-first-td-expanded .contract_detail_table tbody > tr > td:first-child,
.sticky-table-first-td-expanded .inside-table tbody > tr > td:first-child,
.sticky-table-first-td-expanded .counterparty_detail_table tbody > tr > td:first-child {
  position: relative;
  left: 0;
  width: 70px !important;
  display: table-cell;
  z-index: 0 !important;
}
.sticky-table-first-td-expanded tbody > tr > td:first-child.no-data-row {
  position: relative;
  left: 0;
  width: auto !important;
}
.sticky-table-first-td-expanded thead > tr > th:first-child {
  background: #f7f7f7 !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 51px;
  min-height: 51px;
}
.sticky-table-first-td-expanded tfoot > tr > td:first-child {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-td-expanded tr.highlighted-tr > td {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-td-expanded tr.sub-total-row.highlighted-tr > td {
  height: 30px !important;
  min-height: 30px !important;
}
.sticky-table-first-td-expanded tfoot tr td {
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}
.sticky-table-first-td-expanded .navbar-nav > li > .dropdown-menu {
  z-index: 999;
  width: 100%;
}
.sticky-table-first-td-expanded table.basis-port-prices-table thead > tr > th:first-child,
.sticky-table-first-td-expanded table.basis-port-prices-table tbody > tr > td:first-child {
  z-index: 0 !important;
}
.sticky-table-first-td-expanded tr > td {
  height: 45px !important;
}
.sticky-table-first-td-expanded tbody > tr > td:first-child .item-settings-dropdown {
  width: 70%;
  padding-top: 8px !important;
}
.sticky-table-first-td-expanded tbody > tr > td:first-child .label,
.sticky-table-first-td-expanded tbody > tr > td:first-child span {
  margin-top: 8px !important;
}
.sticky-table-first-td-expanded-position {
  margin-left: 140px !important;
}
.sticky-table-first-td-expanded-position tr > td {
  height: 30px;
  min-height: 30px;
  padding: 2px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.sticky-table-first-td-expanded-position tr > td .label {
  margin-top: 0;
  margin-bottom: 0;
}
.sticky-table-first-td-expanded-position thead > tr > th div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.sticky-table-first-td-expanded-position .contract-column {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sticky-table-first-td-expanded-position .number-column {
  max-width: 195px;
  white-space: nowrap;
}
.sticky-table-first-td-expanded-position .number-column:hover::after {
  content: attr(data-fulltext);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.sticky-table-first-td-expanded-position tfoot > tr > td:first-child,
.sticky-table-first-td-expanded-position tbody > tr > td:first-child,
.sticky-table-first-td-expanded-position thead > tr > th:first-child {
  position: sticky;
  left: 0;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
  min-width: 195px;
}
.sticky-table-first-td-expanded-position tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-expanded-position tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-expanded-position thead > tr > th:first-child .item-settings-dropdown,
.sticky-table-first-td-expanded-position tfoot > tr > td:first-child .initial-transform,
.sticky-table-first-td-expanded-position tbody > tr > td:first-child .initial-transform,
.sticky-table-first-td-expanded-position thead > tr > th:first-child .initial-transform {
  white-space: nowrap;
  font-family: 'Regular', sans-serif !important;
}
.sticky-table-first-td-expanded-position tfoot > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-expanded-position tbody > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-expanded-position thead > tr > th:first-child .item-settings-dropdown .fa,
.sticky-table-first-td-expanded-position tfoot > tr > td:first-child .initial-transform .fa,
.sticky-table-first-td-expanded-position tbody > tr > td:first-child .initial-transform .fa,
.sticky-table-first-td-expanded-position thead > tr > th:first-child .initial-transform .fa {
  font-size: calc(14px / 1.1);
}
.sticky-table-first-td-expanded-position tfoot > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-td-expanded-position tbody > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-td-expanded-position thead > tr > th:first-child .item-settings-dropdown > .dropdown-menu {
  z-index: 9999 !important;
}
.sticky-table-first-td-expanded-position tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-expanded-position tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-td-expanded-position thead > tr > th:first-child .item-settings-dropdown {
  width: 93%;
}
.sticky-table-first-td-expanded-position tfoot > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-td-expanded-position tbody > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-td-expanded-position thead > tr > th:first-child .item-settings-dropdown.actions-btn {
  width: auto;
}
.sticky-table-first-td-expanded-position tfoot > tr > td:first-child .label,
.sticky-table-first-td-expanded-position tbody > tr > td:first-child .label,
.sticky-table-first-td-expanded-position thead > tr > th:first-child .label {
  margin-top: 10px !important;
}
.sticky-table-first-td-expanded-position .contract_detail_table tbody > tr > td:first-child,
.sticky-table-first-td-expanded-position .inside-table tbody > tr > td:first-child,
.sticky-table-first-td-expanded-position .counterparty_detail_table tbody > tr > td:first-child {
  position: relative;
  left: 0;
  width: 70px !important;
  display: table-cell;
  z-index: 0 !important;
}
.sticky-table-first-td-expanded-position tbody > tr > td:first-child.no-data-row {
  position: relative;
  left: 0;
  width: auto !important;
}
.sticky-table-first-td-expanded-position thead > tr > th:first-child {
  background: #f7f7f7 !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 51px;
  min-height: 51px;
}
.sticky-table-first-td-expanded-position tfoot > tr > td:first-child {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-td-expanded-position tr.highlighted-tr > td {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-td-expanded-position tr.sub-total-row.highlighted-tr > td {
  height: 30px !important;
  min-height: 30px !important;
}
.sticky-table-first-td-expanded-position tfoot tr td {
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}
.sticky-table-first-td-expanded-position .navbar-nav > li > .dropdown-menu {
  z-index: 999;
  width: 100%;
}
.sticky-table-first-td-expanded-position table.basis-port-prices-table thead > tr > th:first-child,
.sticky-table-first-td-expanded-position table.basis-port-prices-table tbody > tr > td:first-child {
  z-index: 0 !important;
}
.sticky-table-first-td-expanded-position tr > td {
  height: 45px !important;
}
.sticky-table-first-td-expanded-position tbody > tr > td:first-child {
  left: 16px;
  width: 150px !important;
}
.sticky-table-first-td-expanded-position tbody > tr > td:first-child .item-settings-dropdown {
  padding-top: 8px !important;
}
.sticky-table-first-td-expanded-position tbody > tr > td:first-child .label,
.sticky-table-first-td-expanded-position tbody > tr > td:first-child span {
  margin-top: 8px !important;
}
.sticky-table-first-td-expanded-position thead > tr > th:first-child {
  left: 16px;
  width: 150px !important;
}
.sticky-table-first-second-third-td tr > td {
  height: 30px;
  min-height: 30px;
  padding: 2px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.sticky-table-first-second-third-td tr > td .label {
  margin-top: 0;
  margin-bottom: 0;
}
.sticky-table-first-second-third-td thead > tr > th div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.sticky-table-first-second-third-td .contract-column {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sticky-table-first-second-third-td .number-column {
  max-width: 195px;
  white-space: nowrap;
}
.sticky-table-first-second-third-td .number-column:hover::after {
  content: attr(data-fulltext);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.sticky-table-first-second-third-td tfoot > tr > td:first-child,
.sticky-table-first-second-third-td tbody > tr > td:first-child,
.sticky-table-first-second-third-td thead > tr > th:first-child {
  position: sticky;
  left: 0;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
  min-width: 195px;
}
.sticky-table-first-second-third-td tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-second-third-td tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-second-third-td thead > tr > th:first-child .item-settings-dropdown,
.sticky-table-first-second-third-td tfoot > tr > td:first-child .initial-transform,
.sticky-table-first-second-third-td tbody > tr > td:first-child .initial-transform,
.sticky-table-first-second-third-td thead > tr > th:first-child .initial-transform {
  white-space: nowrap;
  font-family: 'Regular', sans-serif !important;
}
.sticky-table-first-second-third-td tfoot > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-second-third-td tbody > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-second-third-td thead > tr > th:first-child .item-settings-dropdown .fa,
.sticky-table-first-second-third-td tfoot > tr > td:first-child .initial-transform .fa,
.sticky-table-first-second-third-td tbody > tr > td:first-child .initial-transform .fa,
.sticky-table-first-second-third-td thead > tr > th:first-child .initial-transform .fa {
  font-size: calc(14px / 1.1);
}
.sticky-table-first-second-third-td tfoot > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-second-third-td tbody > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-second-third-td thead > tr > th:first-child .item-settings-dropdown > .dropdown-menu {
  z-index: 9999 !important;
}
.sticky-table-first-second-third-td tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-second-third-td tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-second-third-td thead > tr > th:first-child .item-settings-dropdown {
  width: 93%;
}
.sticky-table-first-second-third-td tfoot > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-second-third-td tbody > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-second-third-td thead > tr > th:first-child .item-settings-dropdown.actions-btn {
  width: auto;
}
.sticky-table-first-second-third-td tfoot > tr > td:first-child .label,
.sticky-table-first-second-third-td tbody > tr > td:first-child .label,
.sticky-table-first-second-third-td thead > tr > th:first-child .label {
  margin-top: 10px !important;
}
.sticky-table-first-second-third-td .contract_detail_table tbody > tr > td:first-child,
.sticky-table-first-second-third-td .inside-table tbody > tr > td:first-child,
.sticky-table-first-second-third-td .counterparty_detail_table tbody > tr > td:first-child {
  position: relative;
  left: 0;
  width: 70px !important;
  display: table-cell;
  z-index: 0 !important;
}
.sticky-table-first-second-third-td tbody > tr > td:first-child.no-data-row {
  position: relative;
  left: 0;
  width: auto !important;
}
.sticky-table-first-second-third-td thead > tr > th:first-child {
  background: #f7f7f7 !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 51px;
  min-height: 51px;
}
.sticky-table-first-second-third-td tfoot > tr > td:first-child {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-second-third-td tr.highlighted-tr > td {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-second-third-td tr.sub-total-row.highlighted-tr > td {
  height: 30px !important;
  min-height: 30px !important;
}
.sticky-table-first-second-third-td tfoot tr td {
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}
.sticky-table-first-second-third-td .navbar-nav > li > .dropdown-menu {
  z-index: 999;
  width: 100%;
}
.sticky-table-first-second-third-td table.basis-port-prices-table thead > tr > th:first-child,
.sticky-table-first-second-third-td table.basis-port-prices-table tbody > tr > td:first-child {
  z-index: 0 !important;
}
.sticky-table-first-second-third-td tr > td {
  height: 20px !important;
}
.sticky-table-first-second-third-td tbody > tr > td:first-child .item-settings-dropdown {
  padding-top: 2px !important;
  margin-top: 0 !important;
}
.sticky-table-first-second-third-td tbody > tr > td:first-child .label,
.sticky-table-first-second-third-td tbody > tr > td:first-child span {
  margin-top: 2px !important;
}
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(1),
.sticky-table-first-second-third-td tbody > tr > td:nth-child(1),
.sticky-table-first-second-third-td thead > tr > th:nth-child(1) {
  position: absolute;
  left: 16px !important;
  width: 35px !important;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
}
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(1) .item-settings-dropdown,
.sticky-table-first-second-third-td tbody > tr > td:nth-child(1) .item-settings-dropdown,
.sticky-table-first-second-third-td thead > tr > th:nth-child(1) .item-settings-dropdown {
  padding-top: 10px !important;
}
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(1) .label,
.sticky-table-first-second-third-td tbody > tr > td:nth-child(1) .label,
.sticky-table-first-second-third-td thead > tr > th:nth-child(1) .label,
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(1) span,
.sticky-table-first-second-third-td tbody > tr > td:nth-child(1) span,
.sticky-table-first-second-third-td thead > tr > th:nth-child(1) span {
  margin-top: 10px !important;
}
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(2),
.sticky-table-first-second-third-td tbody > tr > td:nth-child(2),
.sticky-table-first-second-third-td thead > tr > th:nth-child(2) {
  position: absolute;
  left: 50px;
  width: 70px !important;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
}
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(2) .item-settings-dropdown,
.sticky-table-first-second-third-td tbody > tr > td:nth-child(2) .item-settings-dropdown,
.sticky-table-first-second-third-td thead > tr > th:nth-child(2) .item-settings-dropdown {
  padding-top: 10px !important;
}
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(2) .label,
.sticky-table-first-second-third-td tbody > tr > td:nth-child(2) .label,
.sticky-table-first-second-third-td thead > tr > th:nth-child(2) .label,
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(2) span,
.sticky-table-first-second-third-td tbody > tr > td:nth-child(2) span,
.sticky-table-first-second-third-td thead > tr > th:nth-child(2) span {
  margin-top: 10px !important;
}
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(3),
.sticky-table-first-second-third-td tbody > tr > td:nth-child(3),
.sticky-table-first-second-third-td thead > tr > th:nth-child(3) {
  position: absolute;
  left: 120px;
  width: 200px !important;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
}
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(3) .item-settings-dropdown,
.sticky-table-first-second-third-td tbody > tr > td:nth-child(3) .item-settings-dropdown,
.sticky-table-first-second-third-td thead > tr > th:nth-child(3) .item-settings-dropdown {
  padding-top: 10px !important;
}
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(3) .label,
.sticky-table-first-second-third-td tbody > tr > td:nth-child(3) .label,
.sticky-table-first-second-third-td thead > tr > th:nth-child(3) .label,
.sticky-table-first-second-third-td tfoot > tr > td:nth-child(3) span,
.sticky-table-first-second-third-td tbody > tr > td:nth-child(3) span,
.sticky-table-first-second-third-td thead > tr > th:nth-child(3) span {
  margin-top: 10px !important;
}
.sticky-table-first-second-third-td thead > tr > th:nth-child(1),
.sticky-table-first-second-third-td thead > tr > th:nth-child(2),
.sticky-table-first-second-third-td thead > tr > th:nth-child(3) {
  border-bottom: 0 !important;
}
.sticky-table-first-second-td tr > td {
  height: 30px;
  min-height: 30px;
  padding: 2px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.sticky-table-first-second-td tr > td .label {
  margin-top: 0;
  margin-bottom: 0;
}
.sticky-table-first-second-td thead > tr > th div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.sticky-table-first-second-td .contract-column {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sticky-table-first-second-td .number-column {
  max-width: 195px;
  white-space: nowrap;
}
.sticky-table-first-second-td .number-column:hover::after {
  content: attr(data-fulltext);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.sticky-table-first-second-td tfoot > tr > td:first-child,
.sticky-table-first-second-td tbody > tr > td:first-child,
.sticky-table-first-second-td thead > tr > th:first-child {
  position: sticky;
  left: 0;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
  min-width: 195px;
}
.sticky-table-first-second-td tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-second-td tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-second-td thead > tr > th:first-child .item-settings-dropdown,
.sticky-table-first-second-td tfoot > tr > td:first-child .initial-transform,
.sticky-table-first-second-td tbody > tr > td:first-child .initial-transform,
.sticky-table-first-second-td thead > tr > th:first-child .initial-transform {
  white-space: nowrap;
  font-family: 'Regular', sans-serif !important;
}
.sticky-table-first-second-td tfoot > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-second-td tbody > tr > td:first-child .item-settings-dropdown .fa,
.sticky-table-first-second-td thead > tr > th:first-child .item-settings-dropdown .fa,
.sticky-table-first-second-td tfoot > tr > td:first-child .initial-transform .fa,
.sticky-table-first-second-td tbody > tr > td:first-child .initial-transform .fa,
.sticky-table-first-second-td thead > tr > th:first-child .initial-transform .fa {
  font-size: calc(14px / 1.1);
}
.sticky-table-first-second-td tfoot > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-second-td tbody > tr > td:first-child .item-settings-dropdown > .dropdown-menu,
.sticky-table-first-second-td thead > tr > th:first-child .item-settings-dropdown > .dropdown-menu {
  z-index: 9999 !important;
}
.sticky-table-first-second-td tfoot > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-second-td tbody > tr > td:first-child .item-settings-dropdown,
.sticky-table-first-second-td thead > tr > th:first-child .item-settings-dropdown {
  width: 93%;
}
.sticky-table-first-second-td tfoot > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-second-td tbody > tr > td:first-child .item-settings-dropdown.actions-btn,
.sticky-table-first-second-td thead > tr > th:first-child .item-settings-dropdown.actions-btn {
  width: auto;
}
.sticky-table-first-second-td tfoot > tr > td:first-child .label,
.sticky-table-first-second-td tbody > tr > td:first-child .label,
.sticky-table-first-second-td thead > tr > th:first-child .label {
  margin-top: 10px !important;
}
.sticky-table-first-second-td .contract_detail_table tbody > tr > td:first-child,
.sticky-table-first-second-td .inside-table tbody > tr > td:first-child,
.sticky-table-first-second-td .counterparty_detail_table tbody > tr > td:first-child {
  position: relative;
  left: 0;
  width: 70px !important;
  display: table-cell;
  z-index: 0 !important;
}
.sticky-table-first-second-td tbody > tr > td:first-child.no-data-row {
  position: relative;
  left: 0;
  width: auto !important;
}
.sticky-table-first-second-td thead > tr > th:first-child {
  background: #f7f7f7 !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 51px;
  min-height: 51px;
}
.sticky-table-first-second-td tfoot > tr > td:first-child {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-second-td tr.highlighted-tr > td {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  height: 70px;
  min-height: 70px;
}
.sticky-table-first-second-td tr.sub-total-row.highlighted-tr > td {
  height: 30px !important;
  min-height: 30px !important;
}
.sticky-table-first-second-td tfoot tr td {
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}
.sticky-table-first-second-td .navbar-nav > li > .dropdown-menu {
  z-index: 999;
  width: 100%;
}
.sticky-table-first-second-td table.basis-port-prices-table thead > tr > th:first-child,
.sticky-table-first-second-td table.basis-port-prices-table tbody > tr > td:first-child {
  z-index: 0 !important;
}
.sticky-table-first-second-td tr > td {
  height: 20px !important;
}
.sticky-table-first-second-td tbody > tr > td:first-child .item-settings-dropdown {
  padding-top: 2px !important;
  margin-top: 0 !important;
}
.sticky-table-first-second-td tbody > tr > td:first-child .label,
.sticky-table-first-second-td tbody > tr > td:first-child span {
  margin-top: 2px !important;
}
.sticky-table-first-second-td tfoot > tr > td:nth-child(1),
.sticky-table-first-second-td tbody > tr > td:nth-child(1),
.sticky-table-first-second-td thead > tr > th:nth-child(1) {
  position: absolute;
  left: 16px !important;
  width: 35px !important;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
}
.sticky-table-first-second-td tfoot > tr > td:nth-child(1) .item-settings-dropdown,
.sticky-table-first-second-td tbody > tr > td:nth-child(1) .item-settings-dropdown,
.sticky-table-first-second-td thead > tr > th:nth-child(1) .item-settings-dropdown {
  padding-top: 10px !important;
}
.sticky-table-first-second-td tfoot > tr > td:nth-child(1) .label,
.sticky-table-first-second-td tbody > tr > td:nth-child(1) .label,
.sticky-table-first-second-td thead > tr > th:nth-child(1) .label,
.sticky-table-first-second-td tfoot > tr > td:nth-child(1) span,
.sticky-table-first-second-td tbody > tr > td:nth-child(1) span,
.sticky-table-first-second-td thead > tr > th:nth-child(1) span {
  margin-top: 10px !important;
}
.sticky-table-first-second-td tfoot > tr > td:nth-child(2),
.sticky-table-first-second-td tbody > tr > td:nth-child(2),
.sticky-table-first-second-td thead > tr > th:nth-child(2) {
  position: absolute;
  left: 50px;
  width: 70px !important;
  z-index: 30;
  vertical-align: middle !important;
  background: #fff;
}
.sticky-table-first-second-td tfoot > tr > td:nth-child(2) .item-settings-dropdown,
.sticky-table-first-second-td tbody > tr > td:nth-child(2) .item-settings-dropdown,
.sticky-table-first-second-td thead > tr > th:nth-child(2) .item-settings-dropdown {
  padding-top: 10px !important;
}
.sticky-table-first-second-td tfoot > tr > td:nth-child(2) .label,
.sticky-table-first-second-td tbody > tr > td:nth-child(2) .label,
.sticky-table-first-second-td thead > tr > th:nth-child(2) .label,
.sticky-table-first-second-td tfoot > tr > td:nth-child(2) span,
.sticky-table-first-second-td tbody > tr > td:nth-child(2) span,
.sticky-table-first-second-td thead > tr > th:nth-child(2) span {
  margin-top: 10px !important;
}
.sticky-table-first-second-td thead > tr > th:nth-child(1),
.sticky-table-first-second-td thead > tr > th:nth-child(2) {
  border-bottom: 0 !important;
}
.sticky-table-no-shadow tbody {
  box-shadow: none !important;
}
.sticky-block {
  position: fixed;
  z-index: 30;
  top: 40px;
  right: auto;
  left: auto;
  max-width: 1240px;
  margin: 0 auto;
}
.stations-table {
  table-layout: fixed;
  width: 100%;
}
.stations-table tr td {
  border-right: 1px solid #e8e6e6 !important;
}
.stations-table tr td.highlighted-td {
  background: #eeeee8 !important;
}
.reassignment-table tr td,
.reassignment-table tr th {
  text-align: left;
}
.supplier-statistic-table table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.supplier-statistic-table table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.supplier-statistic-table table .datetimepicker thead tr th:nth-child(1),
.supplier-statistic-table table .datetimepicker thead tr th:nth-child(2),
.supplier-statistic-table table .datetimepicker thead tr th:nth-child(3),
.supplier-statistic-table table .datetimepicker tbody tr td:nth-child(1),
.supplier-statistic-table table .datetimepicker tbody tr td:nth-child(2),
.supplier-statistic-table table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.supplier-statistic-table table .datetimepicker .table thead tr th:nth-child(6),
.supplier-statistic-table table .datetimepicker .table thead tr th:nth-child(7),
.supplier-statistic-table table .datetimepicker .table tbody tr td:nth-child(6),
.supplier-statistic-table table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.supplier-statistic-table table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.supplier-statistic-table table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.supplier-statistic-table table tr th {
  white-space: nowrap;
  padding: 6px !important;
  text-align: center !important;
  border-right: 1px solid #e8e6e6 !important;
}
.supplier-statistic-table table tr td {
  white-space: nowrap;
  padding: 2px !important;
  text-align: right !important;
  border-right: 1px solid #e8e6e6 !important;
  vertical-align: middle !important;
}
.supplier-statistic-table table tr td.supplier-name {
  text-align: left !important;
  font-family: 'Bold', sans-serif !important;
  text-transform: uppercase;
  padding-left: 10px;
}
.supplier-statistic-table table tr th:last-child {
  border-right: 0 !important;
}
.supplier-statistic-table table tr td:first-child input[type='checkbox'],
.supplier-statistic-table table tr td:first-child input[type='radio'] {
  margin: 0 !important;
  width: 15px;
  height: 15px;
}
.additional-agreement-table tr td {
  padding: 2px !important;
  white-space: nowrap;
  border-bottom: 0 !important;
}
.additional-agreement-table tr td .form-control,
.additional-agreement-table tr td input,
.additional-agreement-table tr td .btn,
.additional-agreement-table tr td .ui-select-bootstrap > .ui-select-match > .btn {
  font-size: calc(14px / 1.3);
  min-height: 22px !important;
}
.additional-agreement-table tr td input[type='checkbox'],
.additional-agreement-table tr td input[type='radio'] {
  height: 22px !important;
  width: 22px !important;
}
.additional-agreement-table .fa-pencil-square {
  display: block !important;
}
.additional-agreement-table tbody:nth-last-child(1) tr td {
  background: #f9f9f7;
}
.additional-agreement-table tbody:nth-last-child(1) tr td .fa.fa-pencil-square {
  display: none !important;
}
.additional-agreement-table tbody:nth-of-type(1) > tr td {
  background: transparent !important;
}
.additional-agreement-table .additional_info {
  word-wrap: break-word;
  white-space: normal;
}
.additional-agreement-table tbody {
  border-top: 0 !important;
}
.additional-agreement-table tr {
  border-bottom: 1px solid #e8e6e6 !important;
}
.table-comments td {
  color: #000000;
}
.table-comments td:first-child {
  width: 40%;
  color: #000000;
}
.table-comments .label {
  margin-bottom: 2px !important;
  display: inline-block;
  padding: 2px !important;
}
.row_inactive > td {
  color: #7f8c8d !important;
  opacity: 0.6;
}
.modal-content .contract-costs-table tr th {
  text-align: center;
}
.modal-content .btn-group > .btn:last-child:not(:first-child),
.modal-content .btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.modal-content .btn-group > .btn {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background: #263746;
}
.modal-content .btn-group > .btn::first-letter {
  text-transform: uppercase !important;
}
.modal-content .analytics-container .btn-group > .btn {
  background: transparent !important;
}
.modal-content .modal-header {
  padding-bottom: 0 !important;
  background: #435c75;
}
.modal-content .modal-header .filter-template {
  background: #34495e;
  width: 100% !important;
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}
.modal-content .modal-header .filter-template .btn {
  border-right: 0 !important;
  padding: 6px 10px !important;
  font-size: 14px !important;
  color: #ffffff !important;
  background: #263746;
  margin-right: 10px;
}
.modal-content .modal-header .filter-template .btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.modal-content .modal-header .filter-template .btn:hover {
  color: #ffffff !important;
  background: #2a3d4d;
}
.modal-content .modal-header .filter-template .btn-primary {
  border-top-left-radius: 0.25em !important;
  border-top-right-radius: 0.25em !important;
  color: #000000 !important;
}
.modal-content .modal-header button {
  color: #f5f5f5;
  background-color: #595b63;
}
.modal-content .modal-header .label_default {
  text-transform: lowercase;
  background: transparent !important;
  font-size: calc(14px / 1.3) !important;
  color: #fff !important;
}
.modal-content .modal-header .label_default .updated_time {
  text-transform: lowercase;
  background: transparent !important;
}
.modal-content .modal-body {
  padding-top: 5px !important;
  padding-bottom: 300px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  background: #eeedeb url(img/top-bg-white.png);
  clear: both;
  display: block;
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.modal-content .modal-body .action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal-content .modal-body .action-buttons .icon-padding {
  padding-top: 20px;
}
.modal-content .modal-body .action-buttons > div:first-child {
  padding-top: 0px;
}
.modal-content .modal-body .action-buttons button {
  position: relative;
  z-index: 1 !important;
}
.modal-content .modal-body .action-buttons .tooltip {
  z-index: 100 !important;
}
.modal-content .modal-body .action-buttons .tooltip:hover::before {
  z-index: 10000 !important;
}
.modal-content .modal-title {
  margin-bottom: 10px;
  color: #fff;
}
.modal-content .updated_time {
  color: #fff !important;
  margin-left: 15px;
}
.modal-content .updated_time .label {
  display: inline-flex;
  padding: 6px !important;
}
.modal-content .ul-inline-row {
  background: #fff !important;
  box-shadow: none !important;
}
.modal-content .ul-inline-row .ul-inline-row,
.modal-content .ul-inline-row .analytics-container,
.modal-content .ul-inline-row .table-container-wrapper,
.modal-content .ul-inline-row .well {
  box-shadow: none !important;
}
.modal-content .analytics-container,
.modal-content .table-container-wrapper {
  box-shadow: none !important;
}
.modal-content .analytics-container .ul-inline-row,
.modal-content .table-container-wrapper .ul-inline-row,
.modal-content .analytics-container .analytics-container,
.modal-content .table-container-wrapper .analytics-container,
.modal-content .analytics-container .table-container-wrapper,
.modal-content .table-container-wrapper .table-container-wrapper,
.modal-content .analytics-container .well,
.modal-content .table-container-wrapper .well {
  box-shadow: none !important;
}
.modal-content #log-properties-table .extended-changed-fields {
  display: table-cell;
}
.modal-content #log-properties-table .changed-fields {
  display: none;
}
.modal-content .form-group .btn-default {
  background: transparent !important;
}
.modal-content .input-group-addon {
  background: transparent !important;
}
.modal-content .inset-container .ul-inline-row,
.modal-content .inset-container .table-container-wrapper {
  background: #fff !important;
}
.modal-content .docs-uploaded-span-small {
  font-size: calc(14px / 1.3) !important;
  color: #000000 !important;
}
.client-filters-block .tooltip:hover::before {
  width: auto !important;
  min-width: 100px;
  max-width: 150px;
  position: fixed;
  margin-top: 15px !important;
  margin-left: -100px;
  top: inherit;
  right: inherit;
  font-size: 14px !important;
}
.modal-update-table tr th {
  text-align: left !important;
}
.modal-update-table .form-control {
  border-radius: 4px !important;
  border: 1px solid #beb9b9 !important;
  background: #f9f9f7 !important;
}
.modal-update-table .form-control:focus {
  border: 2px solid #d2cece !important;
}
#contract-properties-activity .modal-log-table {
  width: 100% !important;
}
#contract-properties-activity .btn-link {
  font-size: calc(14px / 1.3) !important;
}
#contract-properties-activity .container {
  width: 100% !important;
}
#contract-properties-activity .center-block {
  padding: 0;
}
#contract-properties-activity .filter-template .btn {
  padding: 2px 1px !important;
}
#report-confirm-table {
  overflow-x: auto;
}
#report-confirm-table td:last-child {
  background: transparent !important;
}
#report-confirm-table td:first-child,
#report-confirm-table th:first-child {
  text-align: left !important;
}
#report-confirm-table tr th {
  border-right: 1px solid #e8e6e6 !important;
  word-wrap: break-word;
  white-space: normal;
  vertical-align: top;
}
#report-confirm-table tr td {
  text-align: center;
  cursor: default;
  border-right: 1px solid #e8e6e6 !important;
  padding: 2px !important;
  padding-left: 4px !important;
}
#report-confirm-table tr td .label {
  padding: 2px !important;
}
#report-confirm-table tr td .btn-link {
  padding: 0 !important;
  margin-bottom: 0 !important;
}
#report-traders-table {
  width: 100% !important;
  margin-bottom: 10px !important;
}
#report-traders-table .main-table {
  width: 100% !important;
}
#report-traders-table td:nth-child(3) {
  text-align: left !important;
  background: transparent !important;
}
#report-traders-table tr td {
  font-size: 14px;
  text-align: left !important;
  padding: 2px !important;
  color: #000000 !important;
}
#report-traders-table tr td .label {
  padding: 2px !important;
  display: inline-block;
  font-size: calc(14px / 1.3);
}
#report-traders-table tr th {
  text-transform: uppercase;
  text-align: left;
  font-size: calc(14px / 1.3);
}
#report-traders-table tr th:nth-child(3) {
  background: rgba(0, 0, 0, 0.01) !important;
}
#report-traders-table tr td:nth-child(1) {
  font-family: 'Regular', sans-serif !important;
  text-transform: initial !important;
}
#report-traders-table tr td:nth-child(3) {
  text-transform: uppercase !important;
  background: rgba(0, 0, 0, 0.01) !important;
  vertical-align: middle;
}
#report-traders-table tr td:nth-child(3) span {
  font-size: 0.7em;
}
#report-traders-table tr td:nth-child(3) .btn-link {
  font-family: 'Bold', sans-serif !important;
}
#report-traders-table tr td:nth-child(3) .btn-link .fa {
  font-size: calc(14px / 1.3);
}
#cpt-prices-properties-table tr td {
  text-align: right !important;
  border-color: #e8e6e6 !important;
}
#cpt-prices-properties-table tr th {
  text-align: right !important;
}
#cpt-prices-properties-table tr td:first-child {
  text-align: left !important;
}
#cpt-prices-properties-table tr th:first-child {
  text-align: left !important;
}
#log-properties-table {
  width: 100% !important;
  margin-top: 5px;
}
#log-properties-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
#log-properties-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
#log-properties-table .datetimepicker thead tr th:nth-child(1),
#log-properties-table .datetimepicker thead tr th:nth-child(2),
#log-properties-table .datetimepicker thead tr th:nth-child(3),
#log-properties-table .datetimepicker tbody tr td:nth-child(1),
#log-properties-table .datetimepicker tbody tr td:nth-child(2),
#log-properties-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
#log-properties-table .datetimepicker .table thead tr th:nth-child(6),
#log-properties-table .datetimepicker .table thead tr th:nth-child(7),
#log-properties-table .datetimepicker .table tbody tr td:nth-child(6),
#log-properties-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
#log-properties-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
#log-properties-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
#log-properties-table tr th {
  cursor: pointer;
  padding-left: 15px !important;
  padding-right: 15px !important;
  text-align: left;
}
#log-properties-table tr th:hover {
  background: rgba(0, 0, 0, 0.01);
}
#log-properties-table .label {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  font-size: calc(14px / 1.2) !important;
  max-width: 200px !important;
  display: inline-block;
}
#log-properties-table td {
  color: #000000;
  text-align: left;
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle;
  line-height: 1.2 !important;
}
#log-properties-table td .btn-link {
  padding: 0;
}
#log-properties-table .event-td {
  text-align: left;
}
#log-properties-table .event-td .label_success {
  max-width: 400px;
  white-space: normal;
}
#log-properties-table .event-td .label_danger {
  max-width: 400px;
  white-space: normal;
  text-decoration: line-through;
}
#log-properties-table .event-td .label_danger span {
  color: #fff !important;
}
#log-properties-table .event-td ul {
  margin: 0 !important;
}
#log-properties-table .event-td:hover .label_danger {
  text-decoration: none;
}
#log-properties-table .extended-changed-fields {
  display: none;
}
#log-properties-table td:nth-child(5) {
  white-space: normal !important;
}
#log-properties-table th:nth-child(5) {
  text-align: center;
}
#log-properties-table tr:last-child td:last-child {
  text-align: right !important;
}
#log-properties-table .action_addition > td {
  background: rgba(41, 141, 107, 0.11);
  color: #0c4d40;
  border-radius: 0 !important;
}
#log-properties-table .action_delete > td {
  background: rgba(207, 97, 99, 0.11);
  color: #cf6163 !important;
  border-radius: 0 !important;
}
#log-properties-table .action_process > td {
  background: rgba(229, 218, 203, 0.42);
  color: #685438 !important;
  border-radius: 0 !important;
}
#update-properties-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  width: 100% !important;
}
#update-properties-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
#update-properties-table th::first-letter {
  text-transform: uppercase !important;
}
#update-properties-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
#update-properties-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
#update-properties-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
#update-properties-table tr td:nth-child(1) {
  vertical-align: middle;
}
#update-properties-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
#update-properties-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
#update-properties-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
#update-properties-table .label::first-letter {
  text-transform: uppercase !important;
}
#update-properties-table .label .fa {
  margin-right: 3px;
}
#update-properties-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
#update-properties-table .badge {
  padding: 4px !important;
}
#update-properties-table .badge::first-letter {
  text-transform: uppercase !important;
}
#update-properties-table a {
  cursor: pointer;
}
#update-properties-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
#update-properties-table .item-settings-dropdown li > a {
  line-height: 5px;
}
#update-properties-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
#update-properties-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
#update-properties-table .datetimepicker thead tr th:nth-child(1),
#update-properties-table .datetimepicker thead tr th:nth-child(2),
#update-properties-table .datetimepicker thead tr th:nth-child(3),
#update-properties-table .datetimepicker tbody tr td:nth-child(1),
#update-properties-table .datetimepicker tbody tr td:nth-child(2),
#update-properties-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
#update-properties-table .datetimepicker .table thead tr th:nth-child(6),
#update-properties-table .datetimepicker .table thead tr th:nth-child(7),
#update-properties-table .datetimepicker .table tbody tr td:nth-child(6),
#update-properties-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
#update-properties-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
#update-properties-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
#update-properties-table tr td {
  width: auto;
  font-size: calc(14px / 1.1) !important;
  white-space: nowrap;
  padding: 2px !important;
  line-height: 1 !important;
}
#update-properties-table tr td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
#update-properties-table tr td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
#update-properties-table tr td .label {
  white-space: nowrap;
  width: auto !important;
  font-size: calc(14px / 1.1) !important;
  text-align: left;
  padding: 4px !important;
}
#update-properties-table tr td .label_default.label-opacity {
  background: transparent !important;
}
#update-properties-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
#update-properties-table td.td-left-align {
  text-align: left !important;
}
#update-properties-table td.td-right-align {
  text-align: right !important;
}
#update-properties-table td.td-center-align {
  text-align: center !important;
}
#update-properties-table td.nowrap {
  white-space: nowrap !important;
}
#update-properties-table td.update_time {
  white-space: nowrap !important;
  color: #000000 !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: initial;
  min-width: 140px;
}
#update-properties-table td.update_time .label {
  margin-left: 0;
  text-align: left !important;
  display: inline !important;
}
#update-properties-table td.updates {
  min-width: 110px;
}
#update-properties-table td.company {
  vertical-align: middle;
  white-space: nowrap !important;
  text-align: left !important;
  font-family: 'Regular', sans-serif !important;
}
#update-properties-table td.company::first-letter {
  text-transform: uppercase !important;
}
#update-properties-table td.company .btn-link {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
}
#update-properties-table td.company .btn-link:hover {
  text-decoration: underline;
}
#update-properties-table td.company .fa-arrow-right {
  font-size: calc(14px / 1.4) !important;
}
#update-properties-table td.company .label {
  text-align: left;
  margin-left: 0 !important;
}
#update-properties-table td.small-font-cell {
  font-size: calc(14px / 1.3) !important;
}
#update-properties-table td.small-font-cell a,
#update-properties-table td.small-font-cell span,
#update-properties-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.3) !important;
}
#update-properties-table td.info {
  white-space: nowrap;
}
#update-properties-table .contacts,
#update-properties-table .deals,
#update-properties-table .dates {
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.01) !important;
}
#update-properties-table a.modal-btn {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
  -webkit-text-stroke: 0;
}
#update-properties-table a.modal-btn:hover {
  border-bottom: 1px dashed #000000 !important;
  transform: scale(1.05);
  transition: transform 0.2s;
}
#update-properties-table .item-settings-dropdown a:nth-of-type(1) .fa-cog {
  font-size: 14px !important;
}
#update-properties-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
#update-properties-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
#update-properties-table .datetimepicker thead tr th:nth-child(1),
#update-properties-table .datetimepicker thead tr th:nth-child(2),
#update-properties-table .datetimepicker thead tr th:nth-child(3),
#update-properties-table .datetimepicker tbody tr td:nth-child(1),
#update-properties-table .datetimepicker tbody tr td:nth-child(2),
#update-properties-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
#update-properties-table .datetimepicker .table thead tr th:nth-child(6),
#update-properties-table .datetimepicker .table thead tr th:nth-child(7),
#update-properties-table .datetimepicker .table tbody tr td:nth-child(6),
#update-properties-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
#update-properties-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
#update-properties-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
#update-properties-table tr th {
  text-align: center !important;
  vertical-align: middle;
  white-space: nowrap !important;
  padding: 6px;
  border-right: 0 !important;
  font-size: calc(14px / 1.2) !important;
  height: auto !important;
}
#update-properties-table tr td {
  text-align: center !important;
  vertical-align: middle;
  padding: 6px !important;
  border-right: 0 !important;
  overflow-x: visible !important;
  color: #000000 !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
#update-properties-table tr td .fa {
  margin-right: 0 !important;
}
#update-properties-table tr td .btn-link {
  padding: 0 !important;
}
#update-properties-table tr td .bigger-label .fa {
  font-size: calc(14px * 1.1) !important;
}
#update-properties-table tr td.message {
  white-space: normal !important;
  word-break: break-word !important;
  font-size: 14px !important;
}
#update-properties-table tr td.related {
  white-space: nowrap !important;
}
#update-properties-table .inside-table-btn {
  text-transform: uppercase !important;
  font-family: 'Bold', sans-serif !important;
}
#update-properties-table .form-control {
  font-size: calc(14px / 1.3);
}
#update-properties-table .form-group {
  margin: 0;
  padding: 0;
}
#update-properties-table .form-group .col-sm-12 {
  margin: 0;
  padding: 0;
}
#update-properties-table .update_time {
  white-space: nowrap !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: inherit !important;
}
#update-properties-table select {
  font-size: 14px;
  margin-top: 2px;
  color: #000000;
  width: 100%;
}
.updates-inside-table #update-properties-table .update_time {
  font-size: calc(14px / 1.3) !important;
  background: transparent !important;
  font-family: 'Regular', sans-serif !important;
}
.updates-inside-table #update-properties-table tr td:nth-last-child(1),
.updates-inside-table #update-properties-table tr td:nth-last-child(2),
.updates-inside-table #update-properties-table tr td:nth-last-child(3) {
  display: none;
}
.updates-inside-table #update-properties-table tr th:nth-last-child(1),
.updates-inside-table #update-properties-table tr th:nth-last-child(2) {
  display: none;
}
.updates-inside-table #update-properties-table tr td {
  font-size: 14px !important;
  text-transform: initial;
  background: transparent !important;
}
.updates-inside-table #update-properties-table tr td .fa {
  font-size: calc(14px / 1.3) !important;
}
#crop-properties-table tr td {
  vertical-align: middle !important;
  white-space: nowrap !important;
}
#crop-properties-table tr td:nth-child(2) .form-control {
  font-size: calc(14px * 1.4) !important;
}
#person-properties-table th {
  text-transform: uppercase;
  text-align: left;
  font-size: calc(14px / 1.2) !important;
}
#person-properties-table .label {
  max-width: 250px;
  display: inline-flex;
  width: auto !important;
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
#person-properties-table .label .fa {
  margin-right: 2px;
}
#person-properties-table td {
  text-align: left !important;
  padding: 2px !important;
}
.task-table {
  margin-bottom: 0px;
}
#task-properties-table th {
  text-transform: uppercase;
  text-align: center;
}
#task-properties-table .label {
  display: inline-block;
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
#task-properties-table .task_type_label {
  font-size: calc(14px * 1.5) !important;
  cursor: pointer;
}
#task-properties-table .task_type_label:hover {
  opacity: 0.8;
}
#task-properties-table .label-date {
  border: 0 !important;
}
#task-properties-table td {
  color: #000000;
  overflow-x: hidden;
  white-space: normal !important;
  word-break: normal !important;
  text-align: left !important;
  vertical-align: middle;
  width: 70%;
  padding: 2px !important;
}
#task-properties-table td:first-child {
  text-align: left;
  width: 30% !important;
}
#task-properties-table .td-commentary,
#task-properties-table .td-response {
  word-wrap: break-word;
  max-width: 100px;
}
#task-properties-table .add_text {
  font-size: calc(14px / 1.3);
  vertical-align: super;
}
#task-properties-table .status_new > .task-status {
  background: rgba(41, 141, 107, 0.11);
  color: #0c4d40;
  border-radius: 0 !important;
}
#task-properties-table .status_process > .task-status {
  background: rgba(229, 218, 203, 0.42);
  color: #685438 !important;
  border-radius: 0 !important;
}
#task-properties-table .status_review > .task-status {
  background: rgba(164, 149, 176, 0.22);
  color: #6d6a6f !important;
  border-radius: 0 !important;
}
#task-properties-table .status_failed > .task-status {
  background: rgba(207, 97, 99, 0.11);
  color: #cf6163 !important;
  border-radius: 0 !important;
}
#task-properties-table .status_executed > .task-status {
  background: rgba(120, 123, 132, 0.13);
  color: #000000 !important;
  border-radius: 0 !important;
}
#task-properties-table td:nth-child(2) {
  background: rgba(0, 0, 0, 0.01) !important;
}
#task-properties-table .status_new td:nth-child(2) {
  background: rgba(41, 141, 107, 0.21) !important;
}
#task-properties-table .status_process td:nth-child(2) {
  background: rgba(229, 218, 203, 0.62) !important;
}
#task-properties-table .status_review td:nth-child(2) {
  background: rgba(164, 149, 176, 0.22) !important;
}
#task-properties-table .status_failed td:nth-child(2) {
  background: rgba(207, 97, 99, 0.11) !important;
}
.task-block .ul-inline-row,
.task-item-container .ul-inline-row {
  border-radius: 4px !important;
  margin-bottom: 5px !important;
  padding: 6px !important;
  width: 100%;
  overflow-x: hidden;
  box-shadow: none !important;
}
.task-block tr td,
.task-item-container tr td {
  font-size: calc(14px / 1.3);
  vertical-align: middle !important;
  color: #000000 !important;
  border-top: 0 !important;
  white-space: nowrap;
}
.task-block tr td .btn-default,
.task-item-container tr td .btn-default {
  font-size: calc(14px / 1.3) !important;
}
.task-block tr td.comments,
.task-item-container tr td.comments {
  white-space: normal;
  word-wrap: break-word;
  font-family: 'Semibold', sans-serif !important;
  max-width: 200px;
  min-width: 100px;
}
.task-block tr td.task-type,
.task-item-container tr td.task-type {
  min-width: 10em;
  max-width: 10em;
  white-space: normal;
  word-wrap: break-word;
}
.task-block .label,
.task-item-container .label {
  display: inline-block;
  font-size: calc(14px / 1.3) !important;
}
.task-block .smaller-label,
.task-item-container .smaller-label {
  font-size: calc(14px / 1.3) !important;
  text-align: center !important;
  margin: 0 auto !important;
  margin-top: 5px !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.task-block .task-update-time,
.task-item-container .task-update-time {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Bold', sans-serif !important;
}
.task-block .bigger-label,
.task-item-container .bigger-label {
  font-family: 'Bold', sans-serif !important;
}
.task-block .status_new .task-status,
.task-item-container .status_new .task-status {
  background: rgba(41, 141, 107, 0.21) !important;
  color: #0c4d40 !important;
  text-align: center !important;
}
.task-block .status_new tr td,
.task-item-container .status_new tr td {
  background: rgba(41, 141, 107, 0.11) !important;
  color: #0c4d40 !important;
  border-color: rgba(41, 141, 107, 0.21) !important;
}
.task-block .status_process .task-status,
.task-item-container .status_process .task-status {
  background: rgba(229, 218, 203, 0.62) !important;
  color: #685438 !important;
  text-align: center !important;
}
.task-block .status_process tr td,
.task-item-container .status_process tr td {
  background: rgba(229, 218, 203, 0.42) !important;
  color: #685438 !important;
  border-color: rgba(229, 218, 203, 0.62) !important;
}
.task-block .status_review .task-status,
.task-item-container .status_review .task-status {
  background: rgba(164, 149, 176, 0.22) !important;
  color: #6d6a6f !important;
  text-align: center !important;
}
.task-block .status_review tr td,
.task-item-container .status_review tr td {
  background: rgba(164, 149, 176, 0.22) !important;
  color: #6d6a6f !important;
  border-color: #a495b0 !important;
}
.task-block .status_failed .task-status,
.task-item-container .status_failed .task-status {
  background: rgba(207, 97, 99, 0.11) !important;
  color: #cf6163 !important;
  text-align: center !important;
}
.task-block .status_failed tr td,
.task-item-container .status_failed tr td {
  background: rgba(207, 97, 99, 0.11) !important;
  color: #cf6163 !important;
  border-color: rgba(207, 97, 99, 0.11) !important;
}
.task-block .status_executed .task-status,
.task-item-container .status_executed .task-status {
  background: rgba(120, 123, 132, 0.13) !important;
  color: #000000 !important;
  text-align: center !important;
}
.task-block .status_executed tr td,
.task-item-container .status_executed tr td {
  background: #fff !important;
  color: #000000 !important;
  border-color: rgba(120, 123, 132, 0.13);
}
#logistics-properties-table {
  margin-top: 10px;
  margin-bottom: 10px;
}
#logistics-properties-table tr td {
  padding: 2px;
}
#purchase-properties-table {
  width: 100% !important;
  margin-top: 0px;
  margin-bottom: 0px;
}
#purchase-properties-table tr td:first-child {
  text-align: left;
  border-color: #e8e6e6 !important;
  text-transform: uppercase;
  font-family: 'Bold', sans-serif !important;
}
#purchase-properties-table tr td {
  text-align: right;
  border-color: #e8e6e6 !important;
  padding: 2px !important;
  font-size: 14px;
}
#purchase-properties-table tr:first-child td {
  border: none !important;
}
#purchase-properties-table .label {
  font-size: 14px;
}
.indicators-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  width: 100% !important;
}
.indicators-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.indicators-table th::first-letter {
  text-transform: uppercase !important;
}
.indicators-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.indicators-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.indicators-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.indicators-table tr td:nth-child(1) {
  vertical-align: middle;
}
.indicators-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.indicators-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.indicators-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.indicators-table .label::first-letter {
  text-transform: uppercase !important;
}
.indicators-table .label .fa {
  margin-right: 3px;
}
.indicators-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.indicators-table .badge {
  padding: 4px !important;
}
.indicators-table .badge::first-letter {
  text-transform: uppercase !important;
}
.indicators-table a {
  cursor: pointer;
}
.indicators-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.indicators-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.indicators-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.indicators-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.indicators-table .datetimepicker thead tr th:nth-child(1),
.indicators-table .datetimepicker thead tr th:nth-child(2),
.indicators-table .datetimepicker thead tr th:nth-child(3),
.indicators-table .datetimepicker tbody tr td:nth-child(1),
.indicators-table .datetimepicker tbody tr td:nth-child(2),
.indicators-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.indicators-table .datetimepicker .table thead tr th:nth-child(6),
.indicators-table .datetimepicker .table thead tr th:nth-child(7),
.indicators-table .datetimepicker .table tbody tr td:nth-child(6),
.indicators-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.indicators-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.indicators-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.indicators-table tr td {
  width: auto;
  font-size: calc(14px / 1.1) !important;
  white-space: nowrap;
  padding: 2px !important;
  line-height: 1 !important;
}
.indicators-table tr td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.indicators-table tr td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.indicators-table tr td .label {
  white-space: nowrap;
  width: auto !important;
  font-size: calc(14px / 1.1) !important;
  text-align: left;
  padding: 4px !important;
}
.indicators-table tr td .label_default.label-opacity {
  background: transparent !important;
}
.indicators-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.indicators-table td.td-left-align {
  text-align: left !important;
}
.indicators-table td.td-right-align {
  text-align: right !important;
}
.indicators-table td.td-center-align {
  text-align: center !important;
}
.indicators-table td.nowrap {
  white-space: nowrap !important;
}
.indicators-table td.update_time {
  white-space: nowrap !important;
  color: #000000 !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: initial;
  min-width: 140px;
}
.indicators-table td.update_time .label {
  margin-left: 0;
  text-align: left !important;
  display: inline !important;
}
.indicators-table td.updates {
  min-width: 110px;
}
.indicators-table td.company {
  vertical-align: middle;
  white-space: nowrap !important;
  text-align: left !important;
  font-family: 'Regular', sans-serif !important;
}
.indicators-table td.company::first-letter {
  text-transform: uppercase !important;
}
.indicators-table td.company .btn-link {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
}
.indicators-table td.company .btn-link:hover {
  text-decoration: underline;
}
.indicators-table td.company .fa-arrow-right {
  font-size: calc(14px / 1.4) !important;
}
.indicators-table td.company .label {
  text-align: left;
  margin-left: 0 !important;
}
.indicators-table td.small-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.indicators-table td.small-font-cell a,
.indicators-table td.small-font-cell span,
.indicators-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.indicators-table td.info {
  white-space: nowrap;
}
.indicators-table .contacts,
.indicators-table .deals,
.indicators-table .dates {
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.01) !important;
}
.indicators-table a.modal-btn {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
  -webkit-text-stroke: 0;
}
.indicators-table a.modal-btn:hover {
  border-bottom: 1px dashed #000000 !important;
  transform: scale(1.05);
  transition: transform 0.2s;
}
.indicators-table .item-settings-dropdown a:nth-of-type(1) .fa-cog {
  font-size: 14px !important;
}
.indicators-table tr td {
  text-align: left !important;
  padding: 2px !important;
  vertical-align: middle !important;
  font-size: 14px !important;
}
.indicators-table .user-dropdown-btn {
  font-size: 14px !important;
}
.indicators-table .item-settings-dropdown .dropdown-menu {
  left: calc(100% - 8px) !important;
}
.offers-table {
  width: 100% !important;
}
.offers-table .sortorder:after {
  content: '\25b2';
}
.offers-table .sortorder.reverse:after {
  content: '\25bc';
}
.offers-table input[type='number']::-webkit-inner-spin-button,
.offers-table input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.offers-table tr td {
  white-space: nowrap;
  width: auto;
}
.offers-table tr td .input-group {
  margin-bottom: 2px;
}
.offers-table tr td .form-control {
  padding: 2px !important;
  height: 25px;
  min-width: 70px;
}
.offers-table tr td .label {
  text-align: left;
  padding: 2px;
}
.offers-table .hover-td:hover {
  background: rgba(0, 0, 0, 0.01);
  cursor: pointer;
}
.offers-table .currency-group {
  display: block !important;
}
.offers-table .currency-group .form-control:last-child {
  width: 100%;
  display: block;
}
.offers-table td.highlighted {
  background: rgba(0, 0, 0, 0.01) !important;
  border-right: 1px solid #e8e6e6 !important;
  border-left: 1px solid #e8e6e6 !important;
  border-radius: 0 !important;
}
.offers-table td:first-child .btn-link {
  font-family: 'Bold', sans-serif !important;
}
.places-table tr td {
  white-space: nowrap;
}
.places-table .inner-span {
  font-size: 14px !important;
  font-family: 'Regular', sans-serif !important;
}
.competitors-table table {
  width: 100% !important;
}
.competitors-table tr td {
  white-space: nowrap;
  text-align: left;
  border-right: 1px solid #e8e6e6 !important;
  padding: 2px !important;
  min-width: 150px;
}
.competitors-table tr td.highlighted {
  background: #f9f9f7 !important;
  border-right: 0 !important;
  border-top: 2px solid #e8e6e6 !important;
  border-bottom: 2px solid #e8e6e6 !important;
  font-family: 'Bold', sans-serif !important;
}
.competitors-table tr td.highlighted .fa-bookmark {
  display: none;
}
.competitors-table tr td:nth-child(1),
.competitors-table tr td:nth-child(2) {
  padding: 2px !important;
  padding-right: 6px !important;
  padding-left: 6px !important;
}
.competitors-table tr td:nth-child(1) {
  border-right: 0 !important;
  min-width: 200px;
}
.competitors-table .alert-success {
  font-family: 'Bold', sans-serif !important;
}
.competitors-table .with-comment {
  border: dashed #a28355 1px;
  display: block;
}
.places-calc-table tr td {
  padding: 2px !important;
  vertical-align: top !important;
}
.places-calc-table tr td .label {
  display: block !important;
  max-width: 500px !important;
}
.places-calc-table tr td tr td:first-child {
  vertical-align: middle !important;
}
.warehouse-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  width: 100% !important;
}
.warehouse-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.warehouse-table th::first-letter {
  text-transform: uppercase !important;
}
.warehouse-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.warehouse-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.warehouse-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.warehouse-table tr td:nth-child(1) {
  vertical-align: middle;
}
.warehouse-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.warehouse-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.warehouse-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.warehouse-table .label::first-letter {
  text-transform: uppercase !important;
}
.warehouse-table .label .fa {
  margin-right: 3px;
}
.warehouse-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.warehouse-table .badge {
  padding: 4px !important;
}
.warehouse-table .badge::first-letter {
  text-transform: uppercase !important;
}
.warehouse-table a {
  cursor: pointer;
}
.warehouse-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.warehouse-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.warehouse-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.warehouse-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.warehouse-table .datetimepicker thead tr th:nth-child(1),
.warehouse-table .datetimepicker thead tr th:nth-child(2),
.warehouse-table .datetimepicker thead tr th:nth-child(3),
.warehouse-table .datetimepicker tbody tr td:nth-child(1),
.warehouse-table .datetimepicker tbody tr td:nth-child(2),
.warehouse-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.warehouse-table .datetimepicker .table thead tr th:nth-child(6),
.warehouse-table .datetimepicker .table thead tr th:nth-child(7),
.warehouse-table .datetimepicker .table tbody tr td:nth-child(6),
.warehouse-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.warehouse-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.warehouse-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.warehouse-table tr td {
  width: auto;
  font-size: calc(14px / 1.1) !important;
  white-space: nowrap;
  padding: 2px !important;
  line-height: 1 !important;
}
.warehouse-table tr td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.warehouse-table tr td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.warehouse-table tr td .label {
  white-space: nowrap;
  width: auto !important;
  font-size: calc(14px / 1.1) !important;
  text-align: left;
  padding: 4px !important;
}
.warehouse-table tr td .label_default.label-opacity {
  background: transparent !important;
}
.warehouse-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.warehouse-table td.td-left-align {
  text-align: left !important;
}
.warehouse-table td.td-right-align {
  text-align: right !important;
}
.warehouse-table td.td-center-align {
  text-align: center !important;
}
.warehouse-table td.nowrap {
  white-space: nowrap !important;
}
.warehouse-table td.update_time {
  white-space: nowrap !important;
  color: #000000 !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: initial;
  min-width: 140px;
}
.warehouse-table td.update_time .label {
  margin-left: 0;
  text-align: left !important;
  display: inline !important;
}
.warehouse-table td.updates {
  min-width: 110px;
}
.warehouse-table td.company {
  vertical-align: middle;
  white-space: nowrap !important;
  text-align: left !important;
  font-family: 'Regular', sans-serif !important;
}
.warehouse-table td.company::first-letter {
  text-transform: uppercase !important;
}
.warehouse-table td.company .btn-link {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
}
.warehouse-table td.company .btn-link:hover {
  text-decoration: underline;
}
.warehouse-table td.company .fa-arrow-right {
  font-size: calc(14px / 1.4) !important;
}
.warehouse-table td.company .label {
  text-align: left;
  margin-left: 0 !important;
}
.warehouse-table td.small-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.warehouse-table td.small-font-cell a,
.warehouse-table td.small-font-cell span,
.warehouse-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.warehouse-table td.info {
  white-space: nowrap;
}
.warehouse-table .contacts,
.warehouse-table .deals,
.warehouse-table .dates {
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.01) !important;
}
.warehouse-table a.modal-btn {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
  -webkit-text-stroke: 0;
}
.warehouse-table a.modal-btn:hover {
  border-bottom: 1px dashed #000000 !important;
  transform: scale(1.05);
  transition: transform 0.2s;
}
.warehouse-table .item-settings-dropdown a:nth-of-type(1) .fa-cog {
  font-size: 14px !important;
}
.warehouse-table .label,
.warehouse-table .badge {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  padding: 2px !important;
  font-size: 14px !important;
}
.warehouse-table .region-td {
  max-width: 200px !important;
  overflow-x: auto !important;
}
.warehouse-table .item-settings-dropdown {
  float: left !important;
}
.warehouse-table .item-settings-dropdown .dropdown-menu {
  left: 0 !important;
}
.warehouse-table tr td {
  border-right: 1px solid #e8e6e6 !important;
  vertical-align: middle !important;
  text-align: left !important;
  font-size: 14px !important;
}
.warehouse-table tr td .btn-link {
  text-align: left !important;
  float: left;
  font-size: 14px !important;
  padding: 0;
}
.warehouse-table tr td .btn-link .label {
  font-size: 14px !important;
}
.warehouse-table tfoot tr td {
  font-size: 14px !important;
}
.warehouse-table tfoot tr td .label {
  font-size: 14px !important;
}
.warehouse-table tr th {
  border-right: 1px solid #e8e6e6 !important;
  overflow-x: hidden !important;
  text-align: center !important;
}
.warehouse-table tr th:last-child {
  border-right: 0 !important;
}
.warehouse-table tr td:nth-child(1) {
  vertical-align: middle !important;
  text-align: left;
}
.warehouse-trade-table {
  table-layout: fixed;
}
.warehouse-trade-table tr td {
  border-right: 1px solid #e8e6e6 !important;
}
.warehouse-trade-table tr td .label-strong {
  font-family: 'Semibold', sans-serif !important;
}
.warehouse-trade-table tr td .bigger-label {
  font-size: 14px !important;
  font-family: 'Semibold', sans-serif !important;
}
.warehouse-trade-table tr td .bigger-label .btn {
  padding: 0;
}
.contract-status-table .highlighted-td {
  font-family: 'Semibold', sans-serif !important;
}
.contract-status-table thead tr > th {
  background: #fff !important;
  border-right: 0 !important;
}
.contract-status-table tbody {
  border-top: 0 !important;
  border-bottom: 0 !important;
}
.contract-status-table tfoot tr.highlighted-tr > td {
  background: #f9f9f7 !important;
}
.new-statistics-table {
  table-layout: fixed;
}
.new-statistics-table tr th div {
  padding: 0 !important;
  font-size: calc(14px / 1.3) !important;
}
.new-statistics-table tbody > tr > td {
  height: 15px !important;
}
.new-statistics-table tbody > tr.sub-total-row.highlighted-tr > td {
  height: 15px !important;
  min-height: 15px !important;
}
.new-statistics-table tbody > tr > td:nth-child(1) {
  padding: 0 !important;
}
.new-statistics-table tr > td,
.new-statistics-table tr > th {
  width: 150px !important;
  padding: 2px !important;
  vertical-align: middle !important;
  font-size: 14px !important;
  border-right: 1px solid #e8e6e6 !important;
}
.new-statistics-table tr > td .fa,
.new-statistics-table tr > th .fa {
  font-size: calc(14px / 1.3) !important;
}
.new-statistics-table tr > td .label-strong,
.new-statistics-table tr > th .label-strong {
  font-family: 'Semibold', sans-serif !important;
}
.new-statistics-table tr > td .bigger-label,
.new-statistics-table tr > th .bigger-label {
  font-size: 14px !important;
  font-family: 'Semibold', sans-serif !important;
}
.new-statistics-table tr th {
  font-size: calc(14px / 1.3) !important;
  border-right: 0 !important;
}
.new-statistics-table tr td:nth-child(1),
.new-statistics-table tr th:nth-child(1) {
  width: 350px !important;
  left: 15px !important;
  white-space: normal;
  vertical-align: middle !important;
}
.new-statistics-table tr td:nth-child(1) .btn,
.new-statistics-table tr th:nth-child(1) .btn {
  font-family: 'Semibold', sans-serif !important;
}
.new-statistics-table tr td:nth-child(1) .label,
.new-statistics-table tr th:nth-child(1) .label {
  padding: 2px !important;
}
.new-statistics-table tr td:nth-child(1) a,
.new-statistics-table tr th:nth-child(1) a {
  font-size: 14px !important;
  padding: 0 !important;
  padding-left: 5px !important;
}
.new-statistics-table tr td:nth-child(1) a.btn-xs pull-right,
.new-statistics-table tr th:nth-child(1) a.btn-xs pull-right {
  margin-top: 7px !important;
}
.new-statistics-table tr td:nth-child(1) input,
.new-statistics-table tr th:nth-child(1) input {
  margin: 2px;
  height: 15px;
  width: 15px;
}
.new-statistics-table tr td:nth-child(2),
.new-statistics-table tr th:nth-child(2) {
  width: 150px !important;
  white-space: normal;
}
.new-statistics-table tr td:nth-child(3),
.new-statistics-table tr th:nth-child(3) {
  width: 150px !important;
}
.new-statistics-table tbody + tbody {
  border-top: 1px solid #e8e6e6 !important;
}
.new-statistics-table .highlighted-tr td {
  background: #f9f9f7 !important;
  border-bottom: 0 !important;
}
.new-statistics-table .total-row td .bigger-label {
  font-size: 14px !important;
}
.new-statistics-table .sub-total-row td .bigger-label {
  font-size: calc(14px / 1.3) !important;
}
.statistics-nav-tabs .nav-tabs li a {
  font-size: calc(14px * 1.1) !important;
  padding: 6px !important;
}
.consolidated-contracts-table th {
  text-align: center !important;
}
.consolidated-contracts-table th:last-child,
.consolidated-contracts-table td:last-child {
  background: #f9f9f7 !important;
}
.consolidated-contracts-table td {
  font-size: 0.9em;
  text-align: center !important;
}
.consolidated-contracts-table tr:last-child {
  font-size: calc(14px / 1.3);
}
.consolidated-contracts-table td:nth-child(2) {
  word-break: break-all;
  width: 8%;
}
.consolidated-contracts-table td:nth-child(3) {
  word-break: break-word;
  width: 15%;
}
.consolidated-contracts-table tr:hover {
  text-decoration: none !important;
  cursor: pointer;
}
.consolidated-contracts-table tr:hover td {
  opacity: 1 !important;
  text-decoration: none !important;
}
.consolidated-contracts-table .label {
  font-size: 0.9em;
  text-align: center !important;
}
.email-template-table th {
  text-align: left !important;
}
.email-template-table td {
  color: #000000;
  text-align: left !important;
  padding: 10px !important;
  padding-left: 10px !important;
  white-space: normal;
  word-break: break-all;
}
.email-template-table td input[type='checkbox'],
.email-template-table td input[type='radio'] {
  margin: 0 !important;
  width: 25px;
  height: 25px;
}
.email-template-table td .fa {
  font-size: 14px !important;
}
.email-template-table td:last-child {
  text-align: right !important;
}
.email-modal .ul-inline-row {
  padding: 0 !important;
  background: transparent !important;
}
.email-modal .gt_test_wrapper_subject textarea.form-control {
  min-height: 60px !important;
}
.email-modal .gt_test_wrapper_body textarea.form-control {
  min-height: 400px !important;
}
.email-modal .input-group {
  width: 100%;
}
.email-modal .form-group-container textarea.form-control.body {
  min-height: 400px;
}
.email-modal .copy-clipboard-btn {
  display: none !important;
}
.tags-list li {
  display: inline-block !important;
  margin: 2px;
}
.tags-list li .label {
  padding: 2px !important;
}
.fixing-hedging-report .inside-table-ul {
  margin-top: 0 !important;
}
.fixing-hedging-report thead {
  display: none;
}
.fixing-hedging-report .table-inside-table {
  margin-bottom: 0;
  margin-top: 0;
  table-layout: fixed;
}
.fixing-hedging-report .table-inside-table thead {
  display: none;
}
.fixing-hedging-report .table-inside-table tr th {
  font-size: calc(14px / 1.2) !important;
  padding: 2px !important;
}
.fixing-hedging-report .table-inside-table tr td {
  text-align: left !important;
  font-size: calc(14px / 1.2) !important;
  word-wrap: break-word;
  white-space: normal;
  border-bottom: 1px solid #e8e6e6 !important;
  border-left: 0 !important;
  background: #fff;
  padding: 2px !important;
}
.fixing-hedging-report .table-inside-table tr td .inside-table-ul li {
  padding: 0;
  border-bottom: 0 !important;
  white-space: nowrap;
  font-size: calc(14px / 1.1) !important;
}
.fixing-hedging-report .table-inside-table tr.with-no-derivative td {
  background-color: #fffee0;
}
.fixing-hedging-report tbody + tbody {
  border-top: 0;
}
.fixing-hedging-report tr:nth-child(1) .table-inside-table thead {
  display: table-header-group;
}
.fixing-hedging-report tr td {
  padding: 6px !important;
  vertical-align: middle !important;
  white-space: nowrap;
  text-align: left !important;
  font-size: calc(14px / 1.2) !important;
  border-top: 0 !important;
}
.fixing-hedging-report tr td .label {
  display: inline-flex;
}
.fixing-hedging-report .inside-table-ul li {
  padding: 1px;
  border-top: 0 !important;
  white-space: nowrap;
  font-size: calc(14px / 1.2) !important;
}
.statistics-report-table {
  border-bottom: 1px solid #e8e6e6 !important;
}
.statistics-report-table tbody {
  border-bottom: 0 !important;
}
.statistics-report-table h5,
.statistics-report-table h6 {
  font-family: 'Regular', sans-serif !important;
  color: #000000 !important;
  font-size: calc(14px / 1.1) !important;
  margin: 0;
}
.statistics-report-table tbody + tbody {
  border-top: 1px solid #e8e6e6 !important;
}
.statistics-report-table ul {
  margin-bottom: 0;
}
.statistics-report-table .no-border tr td {
  border-top: 0 solid #e8e6e6 !important;
}
.statistics-report-table .super-highlighted-tr {
  background: #eeeee8 !important;
}
.statistics-report-table .super-highlighted-tr td {
  background: #eeeee8 !important;
  border-right: 1px solid #e5e5dd !important;
  font-family: 'Semibold', sans-serif !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.statistics-report-table .super-highlighted-tr td .bigger-label {
  font-size: calc(14px / 1.1) !important;
}
.statistics-report-table .super-highlighted-tr span {
  font-family: 'Semibold', sans-serif !important;
}
.statistics-report-table .mega-highlighted-tr {
  background: #e5e5dd !important;
}
.statistics-report-table .mega-highlighted-tr td {
  background: #e5e5dd !important;
  border-right: 1px solid #ddddd1 !important;
  font-family: 'Bold', sans-serif !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.statistics-report-table .mega-highlighted-tr span {
  font-family: 'Bold', sans-serif !important;
}
.statistics-report-table thead > tr > th {
  text-transform: capitalize !important;
}
.statistics-report-table tbody > tr > td,
.statistics-report-table thead > tr > th {
  vertical-align: middle !important;
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
  white-space: normal !important;
  padding: 2px;
}
.statistics-report-table tbody > tr > td:nth-child(1),
.statistics-report-table thead > tr > th:nth-child(1) {
  width: 180px !important;
  min-width: 180px !important;
  max-width: 180px !important;
  white-space: normal !important;
}
.statistics-report-table tbody > tr > td.passport,
.statistics-report-table thead > tr > th.passport {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important;
  white-space: normal !important;
}
.statistics-report-table tbody > tr > td.dates,
.statistics-report-table thead > tr > th.dates {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
  white-space: nowrap !important;
}
.statistics-report-table tbody > tr > td.product,
.statistics-report-table thead > tr > th.product {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important;
  white-space: normal !important;
}
.statistics-report-table tbody > tr > td.counterparty,
.statistics-report-table thead > tr > th.counterparty {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
  white-space: normal !important;
}
.statistics-report-table tbody > tr > td.price,
.statistics-report-table thead > tr > th.price {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important;
  white-space: normal !important;
}
.farm-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  width: 100% !important;
}
.farm-table a {
  text-align: left;
  white-space: nowrap;
}
.farm-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.farm-table th::first-letter {
  text-transform: uppercase !important;
}
.farm-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.farm-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.farm-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.farm-table tr td:nth-child(1) {
  vertical-align: middle;
}
.farm-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.farm-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.farm-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.farm-table .label::first-letter {
  text-transform: uppercase !important;
}
.farm-table .label .fa {
  margin-right: 3px;
}
.farm-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.farm-table .badge {
  padding: 4px !important;
}
.farm-table .badge::first-letter {
  text-transform: uppercase !important;
}
.farm-table a {
  cursor: pointer;
}
.farm-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.farm-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.farm-table tr th:nth-last-child(4) {
  background: rgba(0, 0, 0, 0.01);
}
.farm-table tr th:nth-last-child(2),
.farm-table tr th:nth-last-child(3) {
  background: rgba(0, 0, 0, 0.01);
  border-radius: 0 !important;
  text-align: right;
}
.farm-table tr td:nth-last-child(1) {
  width: 15%;
}
.farm-table tr td:nth-last-child(4) {
  background: rgba(0, 0, 0, 0.01);
}
.farm-table tr td:nth-last-child(4) .label {
  white-space: nowrap;
  max-width: 140px;
}
.farm-table tr td:nth-last-child(5) {
  width: 10%;
}
.farm-table tr td:nth-last-child(2),
.farm-table tr td:nth-last-child(3) {
  background: rgba(0, 0, 0, 0.01);
  border-radius: 0 !important;
  width: 8%;
  text-align: left;
}
.farm-table tr td:nth-last-child(2) .label,
.farm-table tr td:nth-last-child(3) .label {
  text-align: left !important;
  white-space: nowrap;
}
.farm-table tr td:first-child {
  text-align: left !important;
  word-wrap: normal;
  white-space: normal;
  word-break: break-word;
  color: #000000 !important;
  width: 10%;
}
.farm-table tr td:first-child .label {
  white-space: nowrap !important;
}
.farm-table tr td:nth-child(2) .label {
  width: 100%;
}
.farm-table tr td:nth-child(2) label {
  font-size: calc(14px / 1.3) !important;
}
.logistics-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
}
.logistics-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.logistics-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.logistics-table .datetimepicker thead tr th:nth-child(1),
.logistics-table .datetimepicker thead tr th:nth-child(2),
.logistics-table .datetimepicker thead tr th:nth-child(3),
.logistics-table .datetimepicker tbody tr td:nth-child(1),
.logistics-table .datetimepicker tbody tr td:nth-child(2),
.logistics-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.logistics-table .datetimepicker .table thead tr th:nth-child(6),
.logistics-table .datetimepicker .table thead tr th:nth-child(7),
.logistics-table .datetimepicker .table tbody tr td:nth-child(6),
.logistics-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.logistics-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.logistics-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.logistics-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.logistics-table th::first-letter {
  text-transform: uppercase !important;
}
.logistics-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.logistics-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.logistics-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.logistics-table tr td:nth-child(1) {
  vertical-align: middle;
}
.logistics-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.logistics-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.logistics-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.logistics-table .label::first-letter {
  text-transform: uppercase !important;
}
.logistics-table .label .fa {
  margin-right: 3px;
}
.logistics-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.logistics-table .badge {
  padding: 4px !important;
}
.logistics-table .badge::first-letter {
  text-transform: uppercase !important;
}
.logistics-table a {
  cursor: pointer;
}
.logistics-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.logistics-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.logistics-table tr > th {
  border-right: 1px solid #e8e6e6 !important;
  border-color: #e8e6e6 !important;
  background: #fff !important;
  min-width: 40px;
  border-bottom: 0 !important;
}
.logistics-table tr th:nth-child(1),
.logistics-table tr th:nth-child(2) {
  background: #fff !important;
}
.logistics-table tr th:last-child {
  border-right: 0 !important;
}
.logistics-table tr td {
  padding: 2px !important;
  border-top: 1px solid #e8e6e6 !important;
  border-right: 1px solid #e8e6e6 !important;
  text-align: left !important;
  font-size: 14px !important;
  white-space: nowrap !important;
}
.logistics-table tr td .open > .dropdown-menu {
  position: relative;
}
.logistics-table tr td .btn-blue-border,
.logistics-table tr td .btn-orange-border {
  padding: 1px;
  font-size: calc(14px / 1.4);
}
.logistics-table tr td .label {
  font-size: calc(14px / 1.1) !important;
}
.logistics-table tr td .form-control {
  padding: 2px !important;
  height: 24px !important;
  min-height: 24px !important;
  font-size: 14px !important;
  min-width: 85px !important;
  text-align: left;
}
.logistics-table tr td input[type='checkbox'] {
  height: 14px !important;
}
.logistics-table tr td .smaller-label.label-strong {
  margin-left: 10px;
}
.logistics-table tr td:nth-child(1) {
  font-size: calc(14px / 1.1) !important;
  color: #000000 !important;
  font-family: 'Semibold', sans-serif !important;
}
.logistics-table tr td:nth-child(1) span {
  font-family: 'Regular', sans-serif !important;
}
.logistics-table tr td:nth-child(1) .btn-xs {
  font-size: calc(14px / 1.4) !important;
  line-height: 1;
}
.logistics-table .exporter_set span {
  display: block;
  line-height: 1;
}
.logistics-table td.td-right-align {
  text-align: right !important;
}
.logistics-table td.td-center-align {
  text-align: center !important;
}
.logistics-table tr td:first-child {
  word-break: break-all;
  white-space: normal;
}
.logistics-table tr td:first-child .btn-link {
  text-transform: initial !important;
}
.logistics-table .label {
  white-space: nowrap !important;
  padding: 2px !important;
}
.logistics-table .label-opacity {
  background: transparent !important;
  z-index: 998;
}
.logistics-table .label-opacity .smaller-label {
  display: none;
}
.logistics-table input[type='number']::-webkit-inner-spin-button,
.logistics-table input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.logistics-table .counterparty_detail_table tr td {
  font-size: calc(14px / 1.4) !important;
  padding: 2px !important;
  font-family: 'Regular', sans-serif !important;
}
.logistics-table .counterparty_detail_table tr td:nth-child(1) {
  min-width: auto !important;
  font-family: 'Regular', sans-serif !important;
}
.logistics-table .counterparty_detail_table tr:nth-last-child(1) td {
  background: transparent !important;
  font-family: 'Regular', sans-serif !important;
}
.logistics-table tr th {
  font-size: calc(14px / 1.3) !important;
  z-index: 998;
}
.logistics-table tr th .form-control {
  padding: 2px !important;
  height: 22px !important;
  font-size: 14px !important;
  min-width: 75px !important;
}
.logistics-table tr th .text-muted {
  font-size: 14px !important;
}
.logistics-table tr th.filter-th {
  border: 0 !important;
  border-bottom: 0 !important;
  background: #fff !important;
}
.logistics-table tr th.filter-th .ui-select-choices-row div {
  text-align: left !important;
}
.logistics-table tr:hover th.filter-th {
  background: #fff !important;
}
.logistics-table th.filter-th:hover {
  background: #fff !important;
}
.logistics-table .item-settings-dropdown a:nth-of-type(1) .fa-cog {
  font-size: 14px !important;
}
.logistics-table input[type='checkbox'],
.logistics-table input[type='radio'] {
  margin: 0 !important;
  width: 14px;
  height: 14px;
  margin-right: 5px !important;
}
.logistics-table .highlighted-td {
  background: #f9f9f7 !important;
  border-right: 1px solid #e8e6e6 !important;
  border-left: 1px solid #e8e6e6 !important;
}
.logistics-table .highlighted-td.danger-highlighted {
  background: rgba(207, 97, 99, 0.13) !important;
}
.logistics-table .highlighted-td.warning-highlighted {
  background: #f0ede9 !important;
}
.logistics-table .highlighted-td.success-highlighted {
  background: #ebf1ef !important;
}
.logistics-table .ag-header-cell {
  font-size: calc(14px / 1.3) !important;
  padding: 2px !important;
  font-family: 'Bold', sans-serif !important;
  border-right: 1px solid #e8e6e6;
}
.logistics-table .ag-header-cell .ag-header-cell-text {
  white-space: normal;
}
.logistics-table .ag-cell {
  font-size: calc(14px / 1.3) !important;
  color: #000000 !important;
  word-wrap: break-word;
  white-space: normal;
  font-family: 'Regular', sans-serif !important;
  padding: 2px !important;
}
.logistics-table .ag-cell .fa {
  font-size: calc(14px / 1.4) !important;
}
.logistic-connect-document-input,
label:hover .logistic-connect-document-input,
label:focus .logistic-connect-document-input {
  width: 100% !important;
  background: #d4d4d4 !important;
  border-radius: 4px !important;
  padding: 5px !important;
  font-size: calc(14px / 1.3) !important;
  border: 1px dashed #909597 !important;
  margin-top: 1px;
  margin-bottom: 1px;
}
input.logistic-connect-document-input:hover,
input.logistic-connect-document-input:focus {
  background: #c0c0c0 !important;
  border: 2px dashed #909597 !important;
  margin-top: 0px;
  margin-bottom: 0px;
}
logistics-documents-container .logistic-connect-document-input::file-selector-button {
  white-space: normal;
  width: 100%;
}
logistics-documents-container .row.display-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
logistics-documents-container .row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}
logistics-documents-container .display-flex-card {
  height: calc(100% + 4px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 4px;
  white-space: normal;
  word-wrap: break-word;
}
logistics-documents-container .align-heading {
  text-align: center;
}
.connect-documents-checkbox {
  width: 20px !important;
  height: 20px !important;
  margin: 0 !important;
}
.table-with-fixed-column td:nth-child(1),
.table-with-fixed-column th:nth-child(1),
.table-with-fixed-column td:nth-child(2),
.table-with-fixed-column th:nth-child(2) {
  position: absolute;
  border-radius: 0 !important;
  background: #f9f9f7 !important;
  border-color: #e5e5dd !important;
  z-index: 999 !important;
  width: 190px;
  line-height: 1.4 !important;
}
.table-with-fixed-column td:nth-child(1) span,
.table-with-fixed-column th:nth-child(1) span,
.table-with-fixed-column td:nth-child(2) span,
.table-with-fixed-column th:nth-child(2) span {
  font-family: 'Regular', sans-serif !important;
  text-transform: initial !important;
}
.table-with-fixed-column thead {
  background: #fff !important;
}
.table-with-fixed-column th:nth-child(1),
.table-with-fixed-column th:nth-child(2) {
  height: 60px !important;
  padding-top: 20px !important;
  border-color: #e8e6e6 !important;
}
.table-with-fixed-column tr:nth-last-child(1) td:nth-child(1),
.table-with-fixed-column tr:nth-last-child(1) td:nth-child(2),
.table-with-fixed-column tr:nth-last-child(1) td:nth-child(3) {
  line-height: 3 !important;
}
.table-with-fixed-column td:nth-child(2),
.table-with-fixed-column th:nth-child(2) {
  margin-left: 190px !important;
}
.table-with-fixed-column tr:hover td:nth-child(1),
.table-with-fixed-column tr:hover td:nth-child(2) {
  background: #f9f9f7 !important;
}
.table-with-fixed-column td:nth-child(3),
.table-with-fixed-column th:nth-child(3) {
  margin-left: 380px;
  display: block;
}
.table-with-fixed-column th:nth-child(3) {
  margin-top: 6px;
}
.table-with-fixed-column tr > td .table-edit-btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.table-with-fixed-column tr > td .table-edit-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.planning-table {
  margin-bottom: 10px !important;
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
}
.planning-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.planning-table th::first-letter {
  text-transform: uppercase !important;
}
.planning-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.planning-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.planning-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.planning-table tr td:nth-child(1) {
  vertical-align: middle;
}
.planning-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.planning-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.planning-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.planning-table .label::first-letter {
  text-transform: uppercase !important;
}
.planning-table .label .fa {
  margin-right: 3px;
}
.planning-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.planning-table .badge {
  padding: 4px !important;
}
.planning-table .badge::first-letter {
  text-transform: uppercase !important;
}
.planning-table a {
  cursor: pointer;
}
.planning-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.planning-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.planning-table .label {
  font-size: calc(14px / 1.2) !important;
  white-space: nowrap;
}
.planning-table .item-details-block-transparent .item-details-el {
  float: none;
  margin: 0 auto;
}
.planning-table tr th {
  text-align: middle !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.planning-table tr th div {
  padding: 0 !important;
}
.planning-table tr td:nth-child(1) span {
  white-space: normal !important;
  word-wrap: break-word !important;
  text-align: left !important;
}
.planning-table .label-opacity {
  background: transparent !important;
  z-index: 998;
}
.planning-table .inside-table-ul {
  margin-bottom: 0;
  padding: 0;
}
.planning-table .inside-table-ul li {
  display: block;
  width: 100%;
  clear: both;
  height: 15px;
  padding: 2px;
  text-align: left;
  font-size: calc(14px / 1.3) !important;
}
.planning-table .inside-table-ul li .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.planning-table .inside-table-ul li:last-child {
  border-bottom: 0 !important;
}
.planning-table .ul-inside-table {
  margin-bottom: 0;
}
.planning-table .ul-inside-table li {
  display: block;
  width: 100%;
  height: 15px;
  padding: 2px;
  clear: both;
  font-size: calc(14px / 1.3) !important;
}
.planning-table .ul-inside-table li .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.planning-table .ul-inside-table li:last-child {
  border-bottom: 0 !important;
}
.planning-table tr > th {
  border-bottom: 2px solid #e8e6e6 !important;
  border-right: 1px solid #e8e6e6 !important;
}
.planning-table tr td:first-child {
  text-align: left !important;
  vertical-align: top !important;
  padding: 4px !important;
}
.planning-table tr td:first-child span {
  word-wrap: break-word;
  white-space: normal;
}
.planning-table .label.additional_info {
  width: 100%;
  padding: 2px;
  background: #cf6163;
  font-size: calc(14px / 1.4) !important;
  color: #ffffff !important;
}
.planning-table .item-settings-dropdown .dropdown-menu {
  left: 0;
}
.planning-table tr td {
  text-align: left !important;
  padding: 2px !important;
  white-space: nowrap;
  max-width: 300px;
  vertical-align: top !important;
}
.planning-table tr td .label-danger,
.planning-table tr td .label_danger {
  color: #ffffff !important;
}
.planning-table tr td .label-invisible {
  color: #e8e6e6 !important;
}
.planning-table tr td .form-control {
  min-width: 20px;
}
.planning-table td {
  font-size: calc(14px / 1.2) !important;
  white-space: nowrap !important;
  border-right: 1px solid #e8e6e6 !important;
}
.planning-table td .form-control {
  padding: 2px !important;
  height: auto !important;
  font-size: calc(14px / 1.2) !important;
  width: 22px !important;
  margin-bottom: 2px;
}
.planning-table td .form-control::-webkit-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.planning-table td .form-control::-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.planning-table td .form-control:-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.planning-table td .form-control:-ms-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.planning-table td .form-control.inactive {
  color: #e8e6e6 !important;
}
.planning-table td .label {
  padding: 4px !important;
  margin-bottom: 2px !important;
  text-align: left !important;
  border-radius: 4px !important;
}
.planning-table td input[type='number']::-webkit-inner-spin-button,
.planning-table td input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.planning-table td .plan-calendar-date {
  color: #595b63 !important;
  float: right;
  position: relative;
  font-size: calc(14px / 1.3) !important;
  z-index: 999 !important;
  background: transparent;
  border-radius: 100%;
}
.planning-table tr td.smaller-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.planning-table .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.planning-table .inline .label {
  padding: 2px !important;
  display: inline-block !important;
}
.planning-table .date-cell {
  white-space: nowrap !important;
}
.planning-table .item-settings-dropdown {
  float: left !important;
}
.planning-table .label.request,
.planning-table .request {
  background: rgba(120, 123, 132, 0.63) !important;
  text-align: center !important;
  color: #000000 !important;
}
.planning-table .label.approved,
.planning-table .approved {
  background: rgba(229, 218, 203, 0.62) !important;
  text-align: center !important;
  color: #000000 !important;
}
.planning-table .label.shipped,
.planning-table .shipped {
  background: rgba(41, 141, 107, 0.21) !important;
  text-align: center !important;
  color: #000000 !important;
}
.planning-table .highlighted-td {
  background: #f9f9f7;
}
.planning-table td.td-left-align {
  text-align: left !important;
}
.planning-table td.td-right-align {
  text-align: right !important;
}
.planning-table td.td-center-align {
  text-align: center !important;
}
.planning-table-aggregated {
  margin-bottom: 10px !important;
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
}
.planning-table-aggregated th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.planning-table-aggregated th::first-letter {
  text-transform: uppercase !important;
}
.planning-table-aggregated th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.planning-table-aggregated tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.planning-table-aggregated tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.planning-table-aggregated tr td:nth-child(1) {
  vertical-align: middle;
}
.planning-table-aggregated tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.planning-table-aggregated tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.planning-table-aggregated .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.planning-table-aggregated .label::first-letter {
  text-transform: uppercase !important;
}
.planning-table-aggregated .label .fa {
  margin-right: 3px;
}
.planning-table-aggregated .label-opacity {
  font-size: 14px / 1.8 !important;
}
.planning-table-aggregated .badge {
  padding: 4px !important;
}
.planning-table-aggregated .badge::first-letter {
  text-transform: uppercase !important;
}
.planning-table-aggregated a {
  cursor: pointer;
}
.planning-table-aggregated .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.planning-table-aggregated .item-settings-dropdown li > a {
  line-height: 5px;
}
.planning-table-aggregated .label {
  font-size: calc(14px / 1.2) !important;
  white-space: nowrap;
}
.planning-table-aggregated .item-details-block-transparent .item-details-el {
  float: none;
  margin: 0 auto;
}
.planning-table-aggregated tr th {
  text-align: middle !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.planning-table-aggregated tr th div {
  padding: 0 !important;
}
.planning-table-aggregated tr td:nth-child(1) span {
  white-space: normal !important;
  word-wrap: break-word !important;
  text-align: left !important;
}
.planning-table-aggregated .label-opacity {
  background: transparent !important;
  z-index: 998;
}
.planning-table-aggregated .inside-table-ul {
  margin-bottom: 0;
  padding: 0;
}
.planning-table-aggregated .inside-table-ul li {
  display: block;
  width: 100%;
  clear: both;
  height: 15px;
  padding: 2px;
  text-align: left;
  font-size: calc(14px / 1.3) !important;
}
.planning-table-aggregated .inside-table-ul li .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.planning-table-aggregated .inside-table-ul li:last-child {
  border-bottom: 0 !important;
}
.planning-table-aggregated .ul-inside-table {
  margin-bottom: 0;
}
.planning-table-aggregated .ul-inside-table li {
  display: block;
  width: 100%;
  height: 15px;
  padding: 2px;
  clear: both;
  font-size: calc(14px / 1.3) !important;
}
.planning-table-aggregated .ul-inside-table li .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.planning-table-aggregated .ul-inside-table li:last-child {
  border-bottom: 0 !important;
}
.planning-table-aggregated tr > th {
  border-bottom: 2px solid #e8e6e6 !important;
  border-right: 1px solid #e8e6e6 !important;
}
.planning-table-aggregated tr td:first-child {
  text-align: left !important;
  vertical-align: top !important;
  padding: 4px !important;
}
.planning-table-aggregated tr td:first-child span {
  word-wrap: break-word;
  white-space: normal;
}
.planning-table-aggregated .label.additional_info {
  width: 100%;
  padding: 2px;
  background: #cf6163;
  font-size: calc(14px / 1.4) !important;
  color: #ffffff !important;
}
.planning-table-aggregated .item-settings-dropdown .dropdown-menu {
  left: 0;
}
.planning-table-aggregated tr td {
  text-align: left !important;
  padding: 2px !important;
  white-space: nowrap;
  max-width: 300px;
  vertical-align: top !important;
}
.planning-table-aggregated tr td .label-danger,
.planning-table-aggregated tr td .label_danger {
  color: #ffffff !important;
}
.planning-table-aggregated tr td .label-invisible {
  color: #e8e6e6 !important;
}
.planning-table-aggregated tr td .form-control {
  min-width: 20px;
}
.planning-table-aggregated td {
  font-size: calc(14px / 1.2) !important;
  white-space: nowrap !important;
  border-right: 1px solid #e8e6e6 !important;
}
.planning-table-aggregated td .form-control {
  padding: 2px !important;
  height: auto !important;
  font-size: calc(14px / 1.2) !important;
  width: 22px !important;
  margin-bottom: 2px;
}
.planning-table-aggregated td .form-control::-webkit-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.planning-table-aggregated td .form-control::-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.planning-table-aggregated td .form-control:-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.planning-table-aggregated td .form-control:-ms-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: 14px;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.planning-table-aggregated td .form-control.inactive {
  color: #e8e6e6 !important;
}
.planning-table-aggregated td .label {
  padding: 4px !important;
  margin-bottom: 2px !important;
  text-align: left !important;
  border-radius: 4px !important;
}
.planning-table-aggregated td input[type='number']::-webkit-inner-spin-button,
.planning-table-aggregated td input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.planning-table-aggregated td .plan-calendar-date {
  color: #595b63 !important;
  float: right;
  position: relative;
  font-size: calc(14px / 1.3) !important;
  z-index: 999 !important;
  background: transparent;
  border-radius: 100%;
}
.planning-table-aggregated tr td.smaller-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.planning-table-aggregated .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.planning-table-aggregated .inline .label {
  padding: 2px !important;
  display: inline-block !important;
}
.planning-table-aggregated .date-cell {
  white-space: nowrap !important;
}
.planning-table-aggregated .item-settings-dropdown {
  float: left !important;
}
.planning-table-aggregated .label.request,
.planning-table-aggregated .request {
  background: rgba(120, 123, 132, 0.63) !important;
  text-align: center !important;
  color: #000000 !important;
}
.planning-table-aggregated .label.approved,
.planning-table-aggregated .approved {
  background: rgba(229, 218, 203, 0.62) !important;
  text-align: center !important;
  color: #000000 !important;
}
.planning-table-aggregated .label.shipped,
.planning-table-aggregated .shipped {
  background: rgba(41, 141, 107, 0.21) !important;
  text-align: center !important;
  color: #000000 !important;
}
.planning-table-aggregated .highlighted-td {
  background: #f9f9f7;
}
.planning-table-aggregated td.td-left-align {
  text-align: left !important;
}
.planning-table-aggregated td.td-right-align {
  text-align: right !important;
}
.planning-table-aggregated td.td-center-align {
  text-align: center !important;
}
.planning-table-aggregated tr th {
  border-bottom: 2px solid #e8e6e6 !important;
  border-right: 1px solid #e8e6e6 !important;
}
.planning-table-aggregated tr td {
  vertical-align: middle !important;
}
.planning-table-aggregated tr td .label {
  text-align: center !important;
}
.planning-table-aggregated tr td .span {
  vertical-align: middle !important;
}
.planning-table-aggregated tr td:nth-child(1) {
  vertical-align: middle !important;
}
.planning-table-aggregated .buyer-td {
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  word-wrap: break-word !important;
  white-space: normal !important;
}
.planning-table-aggregated .crop-td {
  width: 70px !important;
  max-width: 70px !important;
  min-width: 70px !important;
  word-wrap: break-word !important;
  white-space: normal !important;
}
.planning-table-aggregated .terminal-td {
  width: 70px !important;
  max-width: 70px !important;
  min-width: 70px !important;
  word-wrap: break-word !important;
  white-space: normal !important;
}
.planning-table-aggregated .days-cell {
  width: 20px !important;
  max-width: 20px !important;
}
.my-plans-checkbox {
  line-height: 1;
  margin-left: 10px;
}
.my-plans-checkbox input {
  margin: 0 !important;
  width: 20px !important;
  height: 20px !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
}
.my-plans-checkbox label {
  padding-left: 5px;
  padding-top: 5px;
  margin: 0 !important;
}
.logistics-table-cut tr td {
  background: transparent !important;
}
.logistics-table-cut tr th {
  background: transparent !important;
  border-bottom: 2px solid #e5e5dd !important;
}
.vessels-table tbody > tr > td {
  font-size: 14px !important;
  text-align: left !important;
  padding: 2px !important;
  white-space: normal !important;
  word-break: keep-all !important;
}
.vessels-table tbody > tr > td .label {
  white-space: nowrap !important;
  padding: 2px !important;
  display: inline-block;
  margin-bottom: 2px !important;
}
.vessels-table .counterparty_detail_table tr td:first-child,
.vessels-table .table-inside-table tr td:first-child {
  min-width: auto !important;
  background: inherit !important;
  z-index: 0 !important;
}
.vessels-table tr td.nowrap {
  white-space: nowrap !important;
}
.vessels-table tr td:first-child {
  text-align: left !important;
  background: #fff !important;
  font-family: 'Bold', sans-serif !important;
  z-index: 99 !important;
}
.vessels-table td.highlighted {
  background: rgba(0, 0, 0, 0.01) !important;
}
.vessels-table .item-settings-dropdown a:nth-of-type(1) .fa-cog {
  font-size: 14px !important;
}
.vessels-table .vessel_new > td:first-child {
  color: #0c4d40 !important;
  border-left: 5px solid #0c4d40 !important;
}
.vessels-table .vessel_new > td:first-child .btn-link,
.vessels-table .vessel_new > td:first-child a {
  color: #0c4d40 !important;
}
.vessels-table .vessel_new > td:first-child .label,
.vessels-table .vessel_new > td:first-child .badge {
  background-color: rgba(28, 179, 148, 0.4) !important;
  color: #07422d !important;
}
.vessels-table .vessel_new > td:first-child .label a,
.vessels-table .vessel_new > td:first-child .badge a {
  color: #07422d !important;
}
.vessels-table .vessel_new > td:first-child .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.vessels-table .vessel_new > td:first-child .label_danger a {
  color: #fff !important;
}
.vessels-table .vessel_new > td:first-child .label_danger span {
  color: #fff !important;
}
.vessels-table .vessel_new > td:first-child .label-opacity {
  background: transparent !important;
}
.vessels-table .vessel_new > td:first-child .dropdown-menu a {
  color: #000000 !important;
}
.vessels-table .vessel_calculate > td:first-child {
  color: #a495b0 !important;
  border-left: 5px solid #6d6a6f !important;
}
.vessels-table .vessel_calculate > td:first-child .btn-link,
.vessels-table .vessel_calculate > td:first-child a {
  color: #a495b0 !important;
}
.vessels-table .vessel_calculate > td:first-child .label,
.vessels-table .vessel_calculate > td:first-child .badge {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
.vessels-table .vessel_calculate > td:first-child .label a,
.vessels-table .vessel_calculate > td:first-child .badge a {
  color: #6d6a6f !important;
}
.vessels-table .vessel_calculate > td:first-child .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.vessels-table .vessel_calculate > td:first-child .label_danger a {
  color: #fff !important;
}
.vessels-table .vessel_calculate > td:first-child .label_danger span {
  color: #fff !important;
}
.vessels-table .vessel_calculate > td:first-child .dropdown-menu a {
  color: #000000 !important;
}
.vessels-table .vessel_loaded > td:first-child {
  color: #000000 !important;
  border-left: 5px solid rgba(120, 123, 132, 0.2) !important;
  font-weight: normal;
}
.vessels-table .vessel_loaded > td:first-child .btn-link {
  color: #000000 !important;
}
.vessels-table .vessel_loaded > td:first-child .label,
.vessels-table .vessel_loaded > td:first-child .badge {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
.vessels-table .vessel_loaded > td:first-child .label a,
.vessels-table .vessel_loaded > td:first-child .badge a {
  color: #595b63 !important;
}
.vessels-table .vessel_loaded > td:first-child .label a.btn-primary,
.vessels-table .vessel_loaded > td:first-child .badge a.btn-primary {
  color: #595b63 !important;
}
.vessels-table .vessel_loaded > td:first-child .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.vessels-table .vessel_loaded > td:first-child .label_danger a {
  color: #fff !important;
}
.vessels-table .vessel_loaded > td:first-child .label_danger span {
  color: #fff !important;
}
.vessels-table .vessel_loaded > td:first-child .label-opacity {
  background: transparent !important;
}
.vessels-table .vessel_loaded > td:first-child .dropdown-menu a {
  color: #000000 !important;
}
.vessels-table .vessel_shipment > td:first-child {
  color: #685438 !important;
  border-left: 5px solid #685438 !important;
}
.vessels-table .vessel_shipment > td:first-child .btn-link,
.vessels-table .vessel_shipment > td:first-child a {
  color: #685438 !important;
}
.vessels-table .vessel_shipment > td:first-child .label,
.vessels-table .vessel_shipment > td:first-child .badge {
  background-color: rgba(181, 146, 96, 0.4) !important;
  color: #685438 !important;
}
.vessels-table .vessel_shipment > td:first-child .label a,
.vessels-table .vessel_shipment > td:first-child .badge a {
  color: #685438 !important;
}
.vessels-table .vessel_shipment > td:first-child .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.vessels-table .vessel_shipment > td:first-child .label_danger a {
  color: #fff !important;
}
.vessels-table .vessel_shipment > td:first-child .label_danger span {
  color: #fff !important;
}
.vessels-table .vessel_shipment > td:first-child .label-opacity {
  background: transparent !important;
}
.vessels-table .vessel_shipment > td:first-child .dropdown-menu a {
  color: #000000 !important;
}
.vessels-table .vessel_washout > td {
  color: #7f8c8d !important;
  opacity: 0.5;
  text-decoration: line-through;
  font-weight: normal;
}
.vessels-table .vessel_washout > td .btn-link,
.vessels-table .vessel_washout > td a {
  color: #7f8c8d !important;
}
.vessels-table .vessel_washout > td .label,
.vessels-table .vessel_washout > td .badge {
  background-color: rgba(181, 146, 96, 0.4) !important;
  color: #685438 !important;
}
.vessels-table .vessel_washout > td .label a,
.vessels-table .vessel_washout > td .badge a {
  color: #685438 !important;
}
.vessels-table .vessel_washout > td .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.vessels-table .vessel_washout > td .label_danger a {
  color: #fff !important;
}
.vessels-table .vessel_washout > td .label_danger span {
  color: #fff !important;
}
.vessels-table .vessel_washout > td .label-opacity {
  background: transparent !important;
}
.vessels-table .vessel_washout > td .dropdown-menu a {
  color: #000000 !important;
}
.vessels-table .progress {
  border: 1px solid #e8e6e6 !important;
}
.documents-table {
  background: #fff;
  margin-bottom: 10px;
  border-radius: 4px !important;
  box-shadow: none !important;
}
.documents-table .label {
  padding: 2px !important;
}
.document_form .form-group {
  float: left !important;
  text-align: left !important;
}
.quality_rule_templates_table tr th div {
  text-align: left;
}
.payments_table tr th {
  padding: 2px !important;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  border-right: 1px solid #f8f6f6 !important;
}
.payments_table tr th div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.payments_table tr td {
  font-size: 14px !important;
  border-right: 1px solid #f8f6f6 !important;
}
.payments_table tfoot tr:nth-last-child(1) td {
  vertical-align: top !important;
}
.payments_table tfoot tr td {
  text-align: left !important;
}
.payments_table .to-pay-btn {
  display: block;
  box-shadow: none !important;
  padding: 0 !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  font-size: calc(14px / 1.3);
  font-family: 'Regular', sans-serif !important;
  border-radius: 1em !important;
}
.payments_table .bigger-label.positive-number {
  font-size: 14px !important;
  font-family: 'Semibold', sans-serif !important;
}
.payments_table .bigger-label.negative-number {
  font-size: 14px !important;
  font-family: 'Semibold', sans-serif !important;
}
.payments_table .span-left {
  float: left !important;
}
.payments_table .span-right {
  float: right !important;
}
.payments_table td.alert-success {
  background: #ebf1ef !important;
}
.payments_table td.alert-danger {
  background: rgba(207, 97, 99, 0.13) !important;
}
.payments_table .new_payment > td.gt-featured {
  border-left: 5px solid #0c4d40 !important;
  background: #ebf1ef !important;
  color: #0c4d40 !important;
}
.payments_table .new_payment > td.gt-featured span {
  color: #0c4d40 !important;
}
.payments_table .new_payment > td.gt-featured .btn-link {
  color: #0c4d40 !important;
  border-bottom-color: #0c4d40 !important;
}
.payments_table .new_payment > td.gt-featured .label,
.payments_table .new_payment > td.gt-featured .badge {
  background-color: rgba(28, 179, 148, 0.4) !important;
  color: #07422d !important;
}
.payments_table .new_payment > td.gt-featured .label a,
.payments_table .new_payment > td.gt-featured .badge a {
  color: #07422d !important;
}
.payments_table .new_payment > td.gt-featured .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.payments_table .new_payment > td.gt-featured .label_danger a {
  color: #fff !important;
}
.payments_table .new_payment > td.gt-featured .label_danger span {
  color: #fff !important;
}
.payments_table .process_payment > td.gt-featured {
  border-left: 5px solid #685438 !important;
  background: #f0ede9 !important;
  color: #685438 !important;
}
.payments_table .process_payment > td.gt-featured span {
  color: #685438 !important;
}
.payments_table .process_payment > td.gt-featured .btn-link {
  color: #685438 !important;
  border-bottom-color: #685438 !important;
}
.payments_table .process_payment > td.gt-featured .label,
.payments_table .process_payment > td.gt-featured .badge {
  background-color: rgba(181, 146, 96, 0.4) !important;
  color: #685438 !important;
}
.payments_table .process_payment > td.gt-featured .label a,
.payments_table .process_payment > td.gt-featured .badge a {
  color: #685438 !important;
}
.payments_table .process_payment > td.gt-featured .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.payments_table .process_payment > td.gt-featured .label_danger a {
  color: #fff !important;
}
.payments_table .process_payment > td.gt-featured .label_danger span {
  color: #fff !important;
}
.payments_table .executed_payment > td.gt-featured {
  border-left: 5px solid rgba(120, 123, 132, 0.2) !important;
  color: #000000 !important;
  background: rgba(0, 0, 0, 0.01) !important;
}
.payments_table .executed_payment > td.gt-featured .btn-link {
  color: #000000 !important;
  border-bottom-color: #000000 !important;
}
.payments_table .executed_payment > td.gt-featured .label,
.payments_table .executed_payment > td.gt-featured .badge {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
.payments_table .executed_payment > td.gt-featured .label a,
.payments_table .executed_payment > td.gt-featured .badge a {
  color: #595b63 !important;
}
.payments_table .executed_payment > td.gt-featured .label a.btn-primary,
.payments_table .executed_payment > td.gt-featured .badge a.btn-primary {
  color: #595b63 !important;
}
.payments_table .executed_payment > td.gt-featured .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.payments_table .executed_payment > td.gt-featured .label_danger a {
  color: #fff !important;
}
.payments_table .executed_payment > td.gt-featured .label_danger span {
  color: #fff !important;
}
.payment-details-container {
  padding-left: 0;
  padding-right: 0;
  text-align: left !important;
  overflow-x: auto;
}
.payment-details-container .col-xs-4 {
  padding-left: 5px;
  padding-right: 5px;
  overflow-x: auto;
}
.payment-details-table {
  width: 100% !important;
  margin-bottom: 1px !important;
}
.payment-details-table tr td {
  color: #000000 !important;
  font-size: 14px !important;
  padding: 2px !important;
}
.payment-details-table tr td:first-child {
  color: #685438 !important;
  font-size: calc(14px / 1.3) !important;
  font-family: 'Bold', sans-serif !important;
  text-transform: uppercase;
}
.payment-details-table tr:first-child td {
  border-top: 0 !important;
}
.table-payments {
  width: 100% !important;
}
.credit-note-table tr td {
  vertical-align: middle;
  font-size: 14px !important;
}
.credit-note-table tr td .label {
  font-size: 14px !important;
  font-family: 'Regular', sans-serif !important;
  margin: 0 !important;
  display: inline-flex;
}
.credit-note-table tr td .hide-btn {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  transform: translate3d(0, 8px, 0);
  height: 1px;
  display: block;
}
.credit-note-table tr td:hover .hide-btn {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  height: auto;
}
.credit-note-table tr td:hover .transform-element {
  transform: translate3d(0, 0, 0);
  font-size: 75%;
}
.finance-client-details-table tr td {
  font-size: 14px !important;
  vertical-align: middle;
}
.finance-client-details-table tr td .label {
  font-size: 14px !important;
  font-family: 'Semibold', sans-serif !important;
  margin: 0 !important;
}
.request-prices-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.request-prices-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.request-prices-table .datetimepicker thead tr th:nth-child(1),
.request-prices-table .datetimepicker thead tr th:nth-child(2),
.request-prices-table .datetimepicker thead tr th:nth-child(3),
.request-prices-table .datetimepicker tbody tr td:nth-child(1),
.request-prices-table .datetimepicker tbody tr td:nth-child(2),
.request-prices-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.request-prices-table .datetimepicker .table thead tr th:nth-child(6),
.request-prices-table .datetimepicker .table thead tr th:nth-child(7),
.request-prices-table .datetimepicker .table tbody tr td:nth-child(6),
.request-prices-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.request-prices-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.request-prices-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.request-prices-table tr th {
  text-align: center !important;
}
.request-prices-table tr td {
  border-right: 1px solid #e8e6e6 !important;
}
.request-prices-table tr td .label {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Semibold', sans-serif !important;
}
.elevator-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
}
.elevator-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.elevator-table th::first-letter {
  text-transform: uppercase !important;
}
.elevator-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.elevator-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.elevator-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.elevator-table tr td:nth-child(1) {
  vertical-align: middle;
}
.elevator-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.elevator-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.elevator-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.elevator-table .label::first-letter {
  text-transform: uppercase !important;
}
.elevator-table .label .fa {
  margin-right: 3px;
}
.elevator-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.elevator-table .badge {
  padding: 4px !important;
}
.elevator-table .badge::first-letter {
  text-transform: uppercase !important;
}
.elevator-table a {
  cursor: pointer;
}
.elevator-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.elevator-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.elevator-table .label {
  max-width: 150px;
  white-space: nowrap;
}
.elevator-table tr td:first-child {
  text-align: left !important;
  word-wrap: normal;
  white-space: normal;
  word-break: break-word;
  color: #000000 !important;
  width: 10%;
}
.elevator-table tr td:first-child .label {
  white-space: nowrap !important;
}
.elevator-table td.additional_info {
  width: 10%;
}
.elevator-table tr td:nth-last-child(2),
.elevator-table tr td:nth-last-child(3) {
  background: rgba(0, 0, 0, 0.01);
  border-radius: 0 !important;
  text-align: right;
  width: 16%;
}
.elevator-table tr td:nth-last-child(2) .label,
.elevator-table tr td:nth-last-child(3) .label {
  white-space: nowrap;
}
.elevator-table tr th:nth-last-child(2),
.elevator-table tr th:nth-last-child(3) {
  background: rgba(0, 0, 0, 0.01);
  border-radius: 0 !important;
  text-align: right;
}
.elevator-table tr th:nth-last-child(2) .label,
.elevator-table tr th:nth-last-child(3) .label {
  display: inline-block;
  text-align: left;
}
.elevator-table tr td:nth-child(2) label {
  font-size: calc(14px / 1.3) !important;
}
.request-table {
  width: 100% !important;
}
.request-table .column-min-width {
  min-width: 100px;
}
.request-table .passport-label {
  margin-left: 2px !important;
  display: inline-flex !important;
  margin-top: 0;
}
.request-table .passport-label .fa {
  margin-right: 2px;
}
.request-table td.alert-success {
  background: #ebf1ef !important;
}
.request-table td.alert-danger {
  background: rgba(207, 97, 99, 0.13) !important;
}
.request-table .big-font {
  font-size: calc(14px * 1.2) !important;
}
.request-table tr td {
  white-space: nowrap !important;
  font-size: calc(14px / 1.1) !important;
  vertical-align: middle !important;
  text-align: center;
  line-height: 1 !important;
  padding: 2px 6px !important;
}
.request-table tr td .btn:hover,
.request-table tr td .btn:focus {
  z-index: 9999;
}
.request-table tr td .fa.show-on-hover {
  display: none;
}
.request-table tr td .label,
.request-table tr td .badge {
  margin: 0 !important;
  display: inline-block;
}
.request-table tr td .label.indent-left,
.request-table tr td .badge.indent-left {
  margin-left: 10px !important;
}
.request-table tr td .fa {
  font-size: calc(14px / 1.4) !important;
}
.request-table tr td .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.request-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.request-table tr td .contract-small-label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.request-table td.td-left-align {
  text-align: left !important;
}
.request-table td.td-right-align {
  text-align: right !important;
}
.request-table td.td-center-align {
  text-align: center !important;
}
.request-table td.contents-display .label {
  display: contents;
  float: left;
}
.request-table td.update_time {
  white-space: nowrap !important;
  color: #000000 !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: initial;
  min-width: 140px;
}
.request-table td.update_time .label {
  margin-left: 0;
  text-align: left !important;
}
.request-table td.wrap-text {
  white-space: normal !important;
  word-wrap: break-word;
}
.request-table .item-settings-dropdown {
  width: auto;
}
.request-table .item-settings-dropdown li:nth-of-type(1) {
  width: auto;
}
.request-table .item-settings-dropdown:hover {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.request-table .item-settings-dropdown:hover .dropdown-menu {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.request-table .updated_time {
  padding: 2px;
  width: auto !important;
  background: transparent !important;
  text-align: left !important;
}
.request-table .exporter_set span {
  display: block;
  line-height: 1;
}
.request-table tr th {
  background: transparent !important;
  font-size: calc(14px / 1.3) !important;
  text-align: center !important;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
  height: 50px !important;
  min-width: 50px;
}
.request-table tr:hover .fa.hide-on-hover {
  display: none;
}
.request-table tr:hover .fa.show-on-hover {
  display: inline-block;
}
.request-table .fa-cog {
  font-size: calc(14px / 1.1) !important;
}
.request-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.request-table td.small-font-cell {
  font-size: calc(14px / 1.1) !important;
}
.request-table td.small-font-cell a,
.request-table td.small-font-cell span,
.request-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.1) !important;
}
.request-table .highlighted.danger-highlighted {
  background: rgba(207, 97, 99, 0.13) !important;
}
.request-table .highlighted.warning-highlighted {
  background: #f0ede9 !important;
}
.request-table .highlighted.success-highlighted {
  background: #ebf1ef !important;
}
.request-table tr td:first-child {
  text-align: left !important;
  white-space: nowrap;
  font-family: 'Regular', sans-serif !important;
}
.request-table tr td.word-wrap {
  word-wrap: break-word !important;
  white-space: normal !important;
}
.request-table .label,
.request-table .badge {
  font-size: calc(14px / 1.1) !important;
  vertical-align: middle;
}
.request-table .smaller-label,
.request-table .smaller-label.label {
  font-size: calc(14px / 1.3) !important;
  padding: 2px !important;
}
.request-table .label-opacity {
  font-size: calc(14px / 1.3) !important;
  background: transparent !important;
}
.request-table .executed_request > td:first-child,
.request-table .executed_request > td.status-cell {
  border-left: 5px solid rgba(120, 123, 132, 0.2) !important;
  background: #fff !important;
}
.request-table .executed_request > td:first-child .btn-link,
.request-table .executed_request > td.status-cell .btn-link,
.request-table .executed_request > td:first-child a,
.request-table .executed_request > td.status-cell a,
.request-table .executed_request > td:first-child span,
.request-table .executed_request > td.status-cell span {
  color: #000000;
}
.request-table .executed_request > td:first-child .label,
.request-table .executed_request > td.status-cell .label,
.request-table .executed_request > td:first-child .badge,
.request-table .executed_request > td.status-cell .badge {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
.request-table .executed_request > td:first-child .label a,
.request-table .executed_request > td.status-cell .label a,
.request-table .executed_request > td:first-child .badge a,
.request-table .executed_request > td.status-cell .badge a {
  color: #595b63 !important;
}
.request-table .executed_request > td:first-child .label a.btn-primary,
.request-table .executed_request > td.status-cell .label a.btn-primary,
.request-table .executed_request > td:first-child .badge a.btn-primary,
.request-table .executed_request > td.status-cell .badge a.btn-primary {
  color: #595b63 !important;
}
.request-table .executed_request > td:first-child .label_calculate,
.request-table .executed_request > td.status-cell .label_calculate {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
.request-table .executed_request > td:first-child .label_calculate a,
.request-table .executed_request > td.status-cell .label_calculate a {
  color: #6d6a6f !important;
}
.request-table .executed_request > td:first-child .label_danger,
.request-table .executed_request > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.request-table .executed_request > td:first-child .label_danger a,
.request-table .executed_request > td.status-cell .label_danger a {
  color: #fff !important;
}
.request-table .executed_request > td:first-child .label_danger span,
.request-table .executed_request > td.status-cell .label_danger span {
  color: #fff !important;
}
.request-table .executed_request > td:first-child .label-opacity,
.request-table .executed_request > td.status-cell .label-opacity {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}
.request-table .approved_request > td:first-child,
.request-table .approved_request > td.status-cell {
  border-left: 5px solid #685438 !important;
  background: #f0ede9 !important;
}
.request-table .approved_request > td:first-child .btn-link,
.request-table .approved_request > td.status-cell .btn-link,
.request-table .approved_request > td:first-child a,
.request-table .approved_request > td.status-cell a,
.request-table .approved_request > td:first-child span,
.request-table .approved_request > td.status-cell span {
  color: #685438;
}
.request-table .approved_request > td:first-child .label,
.request-table .approved_request > td.status-cell .label,
.request-table .approved_request > td:first-child .badge,
.request-table .approved_request > td.status-cell .badge {
  background-color: rgba(181, 146, 96, 0.4) !important;
  color: #685438 !important;
}
.request-table .approved_request > td:first-child .label a,
.request-table .approved_request > td.status-cell .label a,
.request-table .approved_request > td:first-child .badge a,
.request-table .approved_request > td.status-cell .badge a {
  color: #685438 !important;
}
.request-table .approved_request > td:first-child .label_calculate,
.request-table .approved_request > td.status-cell .label_calculate {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
.request-table .approved_request > td:first-child .label_calculate a,
.request-table .approved_request > td.status-cell .label_calculate a {
  color: #6d6a6f !important;
}
.request-table .approved_request > td:first-child .label_danger,
.request-table .approved_request > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.request-table .approved_request > td:first-child .label_danger a,
.request-table .approved_request > td.status-cell .label_danger a {
  color: #fff !important;
}
.request-table .approved_request > td:first-child .label_danger span,
.request-table .approved_request > td.status-cell .label_danger span {
  color: #fff !important;
}
.request-table .approved_request > td:first-child .label-opacity,
.request-table .approved_request > td.status-cell .label-opacity {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}
.request-table .new_request > td:first-child,
.request-table .new_request > td.status-cell {
  border-left: 5px solid #0c4d40 !important;
  background: #ebf1ef !important;
}
.request-table .new_request > td:first-child .btn-link,
.request-table .new_request > td.status-cell .btn-link,
.request-table .new_request > td:first-child a,
.request-table .new_request > td.status-cell a {
  color: #0c4d40;
}
.request-table .new_request > td:first-child .label,
.request-table .new_request > td.status-cell .label,
.request-table .new_request > td:first-child .badge,
.request-table .new_request > td.status-cell .badge {
  background-color: rgba(28, 179, 148, 0.4) !important;
  color: #07422d !important;
}
.request-table .new_request > td:first-child .label a,
.request-table .new_request > td.status-cell .label a,
.request-table .new_request > td:first-child .badge a,
.request-table .new_request > td.status-cell .badge a {
  color: #07422d !important;
}
.request-table .new_request > td:first-child .label_calculate,
.request-table .new_request > td.status-cell .label_calculate {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
.request-table .new_request > td:first-child .label_calculate a,
.request-table .new_request > td.status-cell .label_calculate a {
  color: #6d6a6f !important;
}
.request-table .new_request > td:first-child .label_danger,
.request-table .new_request > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.request-table .new_request > td:first-child .label_danger a,
.request-table .new_request > td.status-cell .label_danger a {
  color: #fff !important;
}
.request-table .new_request > td:first-child .label_danger span,
.request-table .new_request > td.status-cell .label_danger span {
  color: #fff !important;
}
.request-table .new_request > td:first-child .label-opacity,
.request-table .new_request > td.status-cell .label-opacity {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}
.request-table .cancelled_request > td:first-child,
.request-table .cancelled_request > td.status-cell {
  border-left: 5px solid rgba(120, 123, 132, 0.2);
  background: #fff !important;
}
.request-table .cancelled_request > td:first-child .btn-link,
.request-table .cancelled_request > td.status-cell .btn-link,
.request-table .cancelled_request > td:first-child a,
.request-table .cancelled_request > td.status-cell a {
  color: #000000;
  text-decoration: none !important;
}
.request-table .cancelled_request > td:first-child .label,
.request-table .cancelled_request > td.status-cell .label,
.request-table .cancelled_request > td:first-child .badge,
.request-table .cancelled_request > td.status-cell .badge {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
.request-table .cancelled_request > td:first-child .label a,
.request-table .cancelled_request > td.status-cell .label a,
.request-table .cancelled_request > td:first-child .badge a,
.request-table .cancelled_request > td.status-cell .badge a {
  color: #595b63 !important;
}
.request-table .cancelled_request > td:first-child .label a.btn-primary,
.request-table .cancelled_request > td.status-cell .label a.btn-primary,
.request-table .cancelled_request > td:first-child .badge a.btn-primary,
.request-table .cancelled_request > td.status-cell .badge a.btn-primary {
  color: #595b63 !important;
}
.request-table .cancelled_request > td:first-child .label_calculate,
.request-table .cancelled_request > td.status-cell .label_calculate {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
.request-table .cancelled_request > td:first-child .label_calculate a,
.request-table .cancelled_request > td.status-cell .label_calculate a {
  color: #6d6a6f !important;
}
.request-table .cancelled_request > td:first-child .label_danger,
.request-table .cancelled_request > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.request-table .cancelled_request > td:first-child .label_danger a,
.request-table .cancelled_request > td.status-cell .label_danger a {
  color: #fff !important;
}
.request-table .cancelled_request > td:first-child .label_danger span,
.request-table .cancelled_request > td.status-cell .label_danger span {
  color: #fff !important;
}
.request-table .cancelled_request > td:first-child .label-opacity,
.request-table .cancelled_request > td.status-cell .label-opacity {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}
.request-table .cancelled_request > td,
.request-table .cancelled_request > td.status-cell {
  color: #7f8c8d !important;
  text-decoration: line-through;
}
.request-table .cancelled_request:hover > td {
  text-decoration: none !important;
  opacity: 1 !important;
}
.request-table .cancelled_request:hover > td .btn-link {
  opacity: 1 !important;
}
.request-table td.contract_executed,
.request-table .contract_executed > td:first-child {
  border-left: 5px solid rgba(120, 123, 132, 0.2) !important;
  background: #fff !important;
}
.request-table td.contract_executed,
.request-table .contract_executed > td,
.request-table .contract_executed > td.status-cell {
  color: #000000 !important;
  font-weight: normal;
}
.request-table td.contract_executed .btn-link,
.request-table .contract_executed > td .btn-link,
.request-table .contract_executed > td.status-cell .btn-link {
  color: #000000 !important;
}
.request-table td.contract_executed .label,
.request-table .contract_executed > td .label,
.request-table .contract_executed > td.status-cell .label,
.request-table td.contract_executed .badge,
.request-table .contract_executed > td .badge,
.request-table .contract_executed > td.status-cell .badge {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
.request-table td.contract_executed .label a,
.request-table .contract_executed > td .label a,
.request-table .contract_executed > td.status-cell .label a,
.request-table td.contract_executed .badge a,
.request-table .contract_executed > td .badge a,
.request-table .contract_executed > td.status-cell .badge a {
  color: #595b63 !important;
}
.request-table td.contract_executed .label a.btn-primary,
.request-table .contract_executed > td .label a.btn-primary,
.request-table .contract_executed > td.status-cell .label a.btn-primary,
.request-table td.contract_executed .badge a.btn-primary,
.request-table .contract_executed > td .badge a.btn-primary,
.request-table .contract_executed > td.status-cell .badge a.btn-primary {
  color: #595b63 !important;
}
.request-table td.contract_executed .label a,
.request-table .contract_executed > td .label a,
.request-table .contract_executed > td.status-cell .label a,
.request-table td.contract_executed .badge a,
.request-table .contract_executed > td .badge a,
.request-table .contract_executed > td.status-cell .badge a {
  color: #fff !important;
}
.request-table td.contract_executed .label_danger,
.request-table .contract_executed > td .label_danger,
.request-table .contract_executed > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.request-table td.contract_executed .label_danger a,
.request-table .contract_executed > td .label_danger a,
.request-table .contract_executed > td.status-cell .label_danger a {
  color: #fff !important;
}
.request-table td.contract_executed .label_danger span,
.request-table .contract_executed > td .label_danger span,
.request-table .contract_executed > td.status-cell .label_danger span {
  color: #fff !important;
}
.request-table td.contract_executed .label-opacity,
.request-table .contract_executed > td .label-opacity,
.request-table .contract_executed > td.status-cell .label-opacity {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}
.request-table td.contract_calculate,
.request-table .contract_calculate > td:first-child {
  border-left: 5px solid #6d6a6f !important;
}
.request-table td.contract_calculate,
.request-table .contract_calculate > td,
.request-table .contract_calculate > td.status-cell {
  color: #a495b0 !important;
  background: #fff !important;
}
.request-table td.contract_calculate .btn-link,
.request-table .contract_calculate > td .btn-link,
.request-table .contract_calculate > td.status-cell .btn-link,
.request-table td.contract_calculate a,
.request-table .contract_calculate > td a,
.request-table .contract_calculate > td.status-cell a {
  color: #a495b0 !important;
}
.request-table td.contract_calculate .label,
.request-table .contract_calculate > td .label,
.request-table .contract_calculate > td.status-cell .label,
.request-table td.contract_calculate .badge,
.request-table .contract_calculate > td .badge,
.request-table .contract_calculate > td.status-cell .badge {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
.request-table td.contract_calculate .label a,
.request-table .contract_calculate > td .label a,
.request-table .contract_calculate > td.status-cell .label a,
.request-table td.contract_calculate .badge a,
.request-table .contract_calculate > td .badge a,
.request-table .contract_calculate > td.status-cell .badge a {
  color: #6d6a6f !important;
}
.request-table td.contract_calculate .label a,
.request-table .contract_calculate > td .label a,
.request-table .contract_calculate > td.status-cell .label a,
.request-table td.contract_calculate .badge a,
.request-table .contract_calculate > td .badge a,
.request-table .contract_calculate > td.status-cell .badge a {
  color: #fff !important;
}
.request-table td.contract_calculate .label_danger,
.request-table .contract_calculate > td .label_danger,
.request-table .contract_calculate > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.request-table td.contract_calculate .label_danger a,
.request-table .contract_calculate > td .label_danger a,
.request-table .contract_calculate > td.status-cell .label_danger a {
  color: #fff !important;
}
.request-table td.contract_calculate .label_danger span,
.request-table .contract_calculate > td .label_danger span,
.request-table .contract_calculate > td.status-cell .label_danger span {
  color: #fff !important;
}
.request-table td.contract_calculate .label-opacity,
.request-table .contract_calculate > td .label-opacity,
.request-table .contract_calculate > td.status-cell .label-opacity {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}
.request-table td.contract_new,
.request-table .contract_new > td:first-child,
.request-table .contract_new > td.status-cell {
  border-left: 5px solid #0c4d40 !important;
  background: #ebf1ef !important;
}
.request-table td.contract_new .btn-link,
.request-table .contract_new > td:first-child .btn-link,
.request-table .contract_new > td.status-cell .btn-link,
.request-table td.contract_new a,
.request-table .contract_new > td:first-child a,
.request-table .contract_new > td.status-cell a {
  color: #0c4d40 !important;
}
.request-table td.contract_new .label,
.request-table .contract_new > td:first-child .label,
.request-table .contract_new > td.status-cell .label,
.request-table td.contract_new .badge,
.request-table .contract_new > td:first-child .badge,
.request-table .contract_new > td.status-cell .badge {
  background-color: rgba(28, 179, 148, 0.4) !important;
  color: #07422d !important;
}
.request-table td.contract_new .label a,
.request-table .contract_new > td:first-child .label a,
.request-table .contract_new > td.status-cell .label a,
.request-table td.contract_new .badge a,
.request-table .contract_new > td:first-child .badge a,
.request-table .contract_new > td.status-cell .badge a {
  color: #07422d !important;
}
.request-table td.contract_new .label a,
.request-table .contract_new > td:first-child .label a,
.request-table .contract_new > td.status-cell .label a,
.request-table td.contract_new .badge a,
.request-table .contract_new > td:first-child .badge a,
.request-table .contract_new > td.status-cell .badge a {
  color: #fff !important;
}
.request-table td.contract_new .label_danger,
.request-table .contract_new > td:first-child .label_danger,
.request-table .contract_new > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.request-table td.contract_new .label_danger a,
.request-table .contract_new > td:first-child .label_danger a,
.request-table .contract_new > td.status-cell .label_danger a {
  color: #fff !important;
}
.request-table td.contract_new .label_danger span,
.request-table .contract_new > td:first-child .label_danger span,
.request-table .contract_new > td.status-cell .label_danger span {
  color: #fff !important;
}
.request-table td.contract_new .label-opacity,
.request-table .contract_new > td:first-child .label-opacity,
.request-table .contract_new > td.status-cell .label-opacity {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}
.request-table td.contract_processing,
.request-table .contract_processing > td:first-child,
.request-table .contract_processing > td.status-cell {
  border-left: 5px solid #685438 !important;
  background: #fff !important;
}
.request-table td.contract_processing .btn-link,
.request-table .contract_processing > td:first-child .btn-link,
.request-table .contract_processing > td.status-cell .btn-link,
.request-table td.contract_processing a,
.request-table .contract_processing > td:first-child a,
.request-table .contract_processing > td.status-cell a {
  color: #685438 !important;
}
.request-table td.contract_processing .label,
.request-table .contract_processing > td:first-child .label,
.request-table .contract_processing > td.status-cell .label,
.request-table td.contract_processing .badge,
.request-table .contract_processing > td:first-child .badge,
.request-table .contract_processing > td.status-cell .badge {
  background-color: rgba(181, 146, 96, 0.4) !important;
  color: #685438 !important;
}
.request-table td.contract_processing .label a,
.request-table .contract_processing > td:first-child .label a,
.request-table .contract_processing > td.status-cell .label a,
.request-table td.contract_processing .badge a,
.request-table .contract_processing > td:first-child .badge a,
.request-table .contract_processing > td.status-cell .badge a {
  color: #685438 !important;
}
.request-table td.contract_processing .label a,
.request-table .contract_processing > td:first-child .label a,
.request-table .contract_processing > td.status-cell .label a,
.request-table td.contract_processing .badge a,
.request-table .contract_processing > td:first-child .badge a,
.request-table .contract_processing > td.status-cell .badge a {
  color: #fff !important;
}
.request-table td.contract_processing .label_danger,
.request-table .contract_processing > td:first-child .label_danger,
.request-table .contract_processing > td.status-cell .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.request-table td.contract_processing .label_danger a,
.request-table .contract_processing > td:first-child .label_danger a,
.request-table .contract_processing > td.status-cell .label_danger a {
  color: #fff !important;
}
.request-table td.contract_processing .label_danger span,
.request-table .contract_processing > td:first-child .label_danger span,
.request-table .contract_processing > td.status-cell .label_danger span {
  color: #fff !important;
}
.request-table td.contract_processing .label-opacity,
.request-table .contract_processing > td:first-child .label-opacity,
.request-table .contract_processing > td.status-cell .label-opacity {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}
.request-table td.contract_cancelled,
.request-table .contract_cancelled > td:first-child,
.request-table .contract_cancelled > td.status-cell {
  border-left: 5px solid rgba(120, 123, 132, 0.2) !important;
  background: #fff !important;
}
.request-table td.contract_cancelled,
.request-table .contract_cancelled > td {
  color: #7f8c8d !important;
  text-decoration: line-through;
  font-weight: normal;
}
.request-table td.contract_cancelled .btn-link,
.request-table .contract_cancelled > td .btn-link,
.request-table td.contract_cancelled a,
.request-table .contract_cancelled > td a {
  color: #7f8c8d !important;
}
.request-table td.contract_cancelled .label,
.request-table .contract_cancelled > td .label,
.request-table td.contract_cancelled .badge,
.request-table .contract_cancelled > td .badge {
  background: rgba(119, 119, 119, 0.2);
  color: #777777 !important;
}
.request-table td.contract_cancelled .label a,
.request-table .contract_cancelled > td .label a,
.request-table td.contract_cancelled .badge a,
.request-table .contract_cancelled > td .badge a {
  color: #fff !important;
}
.request-table td.contract_cancelled .label_danger,
.request-table .contract_cancelled > td .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.request-table td.contract_cancelled .label_danger a,
.request-table .contract_cancelled > td .label_danger a {
  color: #fff !important;
}
.request-table td.contract_cancelled .label_danger span,
.request-table .contract_cancelled > td .label_danger span {
  color: #fff !important;
}
.request-table td.contract_cancelled .label-opacity,
.request-table .contract_cancelled > td .label-opacity {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}
.request-table .inside-table-ul li {
  font-size: calc(14px / 1.1);
  border-bottom: 0 !important;
  text-align: left;
}
.request-table .inside-table-ul li span {
  font-size: calc(14px / 1.1);
}
.request-table .progress {
  margin: 0 !important;
  background-color: rgba(0, 0, 0, 0.01);
  box-shadow: none !important;
  height: auto !important;
  border-radius: 2em !important;
  text-transform: uppercase;
  min-width: 80px;
}
.request-table .progress .progress-bar {
  font-size: 14px;
  padding: 0px;
  padding-left: 5px;
  text-align: left;
}
.request-table .progress .progress_bar_default {
  background-color: rgba(120, 123, 132, 0.4);
  color: #595b63;
}
.request-table .progress .progress_bar_success {
  background-color: rgba(41, 141, 107, 0.7);
  color: #07422d;
}
.request-table .progress .progress_bar_warning {
  background-color: rgba(229, 218, 203, 0.7);
  color: #7d6643;
}
.request-table .progress .progress_bar_calculate {
  background-color: rgba(164, 149, 176, 0.7);
  color: #6d6a6f;
}
.request-table .progress .progress_bar_danger {
  background-color: rgba(207, 97, 99, 0.7);
  color: #ffffff;
}
.request-table .progress .avg-price {
  display: inline;
  position: absolute;
  right: 20px;
}
.request-table .progress-consisted .progress-bar {
  overflow-x: hidden !important;
}
.request-table tr td .currency-totals .label {
  display: initial !important;
}
.request-table .passports-wrapper-light-mode {
  overflow-x: auto;
  display: flex;
  padding: 5px;
}
.request-table .passports-wrapper-light-mode .contract-details-container-cut h2 {
  font-size: calc(14px * 1.1) !important;
  font-family: 'Semibold', sans-serif !important;
}
.request-table .passports-wrapper-light-mode .passports-container {
  min-width: 50px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.request-table .passports-wrapper-light-mode .request-list {
  min-width: 70px;
  padding: 5px !important;
  margin: 0 !important;
  margin-right: 5px !important;
}
.request-table .passports-wrapper-light-mode .request-list .counterparty_detail_table tr td {
  font-size: calc(14px / 1.3) !important;
  background: inherit !important;
  font-family: 'Regular', sans-serif !important;
}
.limits-table tr td {
  padding: 6px !important;
}
.limits-table tr td .label {
  float: right;
}
.approvers-table tr td:first-child {
  border-left: 0 !important;
}
.approvers-table tr th {
  min-width: 40px;
  height: 20px !important;
}
.approvers-table tr td.wrap-text {
  white-space: normal !important;
  word-wrap: break-word;
}
.bank-accounts-list {
  border-top: 0 !important;
  text-align: left !important;
  font-size: 14px !important;
}
.bank-accounts-list h1 {
  padding: 0;
  margin: 0;
}
.bank-accounts-list h3 {
  padding: 0;
  margin: 0;
}
.bank-accounts-list div {
  white-space: normal;
  word-wrap: break-word !important;
  word-break: break-all;
}
.bank-accounts-list ul li a {
  text-align: left !important;
  cursor: pointer;
}
.bank-accounts-list .nav {
  font-family: 'Bold', sans-serif !important;
}
.bank-accounts-list .label-strong {
  font-family: 'Bold', sans-serif !important;
}
.budget-table tr td {
  border-right: 1px solid #e8e6e6 !important;
  text-align: right !important;
  font-size: 14px !important;
  vertical-align: middle !important;
  padding: 2px !important;
  white-space: nowrap;
  line-height: 1 !important;
}
.budget-table tr td .label,
.budget-table tr td .badge {
  display: inline-flex;
}
.budget-table tr td:first-child {
  text-align: left !important;
  font-size: 14px !important;
}
.budget-table tr td:last-child,
.budget-table tr th:last-child {
  border-right: 0 !important;
}
.budget-table .form-control {
  height: 16px;
  font-size: 14px !important;
}
.budget-table .income-row {
  background: #ebf1ef !important;
  color: #0c4d40;
  font-family: 'Bold', sans-serif !important;
  font-size: 14px !important;
}
.budget-table .expense-row {
  background: rgba(207, 97, 99, 0.13) !important;
  color: #cf6163;
  font-family: 'Bold', sans-serif !important;
  font-size: 14px !important;
}
.budget-table th[column-name='q1'],
.budget-table th[column-name='q2'],
.budget-table th[column-name='q3'],
.budget-table th[column-name='q4'] {
  background: #f9f9f7 !important;
}
.budget-table .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.budget-table .bigger-label {
  font-size: 14px !important;
  font-family: 'Bold', sans-serif !important;
  padding: 0 !important;
}
.budget-table .highlighted-tr td {
  font-family: 'Bold', sans-serif !important;
  background: #f9f9f7 !important;
}
.budget-table .fa-sort-up {
  visibility: hidden;
}
.budget-table .quarter {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
}
.budget-table .highlighted {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
}
.request-list-container .request-list {
  margin-bottom: 0 !important;
  border-radius: 8px;
  padding: 10px !important;
}
.request-list-container .request-list .nav > li > a {
  padding: 0;
  line-height: 1;
}
.request-list-container .request-list h2,
.request-list-container .request-list h3,
.request-list-container .request-list h4,
.request-list-container .request-list h5,
.request-list-container .request-list h6 {
  margin-bottom: 0;
  margin-top: 0;
  white-space: normal;
}
.request-list-container h5 {
  font-size: 14px !important;
}
.request-list-container .horizontal-divider {
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(8, 40, 100, 0.15);
}
.request-list-container .smaller-label {
  white-space: normal;
  font-family: 'Regular', sans-serif !important;
  font-size: calc(14px / 1.3) !important;
}
.request-list-container .progress-bar .smaller-label {
  white-space: nowrap;
}
.request-list-container a {
  cursor: pointer;
  color: #666 !important;
}
.request-list-container .label {
  margin-bottom: 2px !important;
  margin-right: 2px !important;
  margin-top: 0 !important;
  padding: 2px !important;
}
.request-list-container .badge {
  font-size: calc(14px / 1.2);
}
.request-list-container .additional_label {
  font-size: 14px !important;
}
.request-list-container .executed_request_left {
  border-left: 6px solid #595b63 !important;
}
.request-list-container .approved_request_left {
  border-left: 6px solid #685438 !important;
}
.request-list-container .new_request_left {
  border-left: 6px solid #07422d !important;
}
.request-list-container .executed_request {
  border-top: 6px solid #595b63 !important;
}
.request-list-container .approved_request {
  border-top: 6px solid #685438 !important;
}
.request-list-container .new_request {
  border-top: 6px solid #0c4d40 !important;
}
.request-list-container .cancelled_request {
  opacity: 0.5;
  border-top: 6px solid #595b63 !important;
}
.request-list-container .cancelled_request .label,
.request-list-container .cancelled_request .badge {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
.request-list-container .cancelled_request .label a,
.request-list-container .cancelled_request .badge a {
  color: #595b63 !important;
}
.request-list-container .cancelled_request .label a.btn-primary,
.request-list-container .cancelled_request .badge a.btn-primary {
  color: #595b63 !important;
}
.request-list-container .cancelled_request .label_calculate {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
.request-list-container .cancelled_request .label_calculate a {
  color: #6d6a6f !important;
}
.request-list-container .cancelled_request .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.request-list-container .cancelled_request .label_danger a {
  color: #fff !important;
}
.request-list-container .cancelled_request .label_danger span {
  color: #fff !important;
}
.request-list-container .cancelled_request .label-opacity {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}
.request-list-container .cancelled_request .update_time {
  background: transparent !important;
  width: auto !important;
}
.request-list-container .cancelled_request .value {
  color: #000000 !important;
}
.request-list-container .cancelled_request:hover {
  text-decoration: none !important;
  opacity: 1 !important;
}
.mailings-attachments-table tr td {
  white-space: normal;
  word-break: break-all;
}
.mailings-table tr td {
  font-size: calc(14px / 1.1) !important;
}
.mailings-table tr td .label {
  font-size: calc(14px / 1.1) !important;
}
.mailings-table .ui-select-bootstrap > .ui-select-match > .btn {
  height: 24px;
  line-height: 1.5 !important;
}
.mailing-actions .btn-xs {
  padding: 6px 10px;
  line-height: 1.5;
}
.request-page .request-list-container {
  width: 100% !important;
}
.request-page .request-list-container .request-list {
  width: 100%;
}
.passports-list-container {
  font-size: calc(14px / 1.3);
}
.passports-list-container h2 {
  font-size: 14px;
  white-space: normal;
}
.passports-list-container h3 {
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
  white-space: normal;
}
.passports-list-container .header-grey {
  color: #666b72;
}
.passports-list-container .counterparty_detail_table tr td:first-child {
  border-right: 0 !important;
}
.passports-list-container .counterparty_detail_table tr td {
  border-top: 0 !important;
}
.passports-list-container .counterparty_detail_table tr td .label {
  font-size: 14px !important;
}
.passports-list-container .request-list {
  margin-bottom: 0 !important;
  border-radius: 8px;
  padding: 10px !important;
}
.passports-list-container .request-list .nav > li > a {
  padding: 0;
  line-height: 1;
}
.passports-list-container .request-list h2,
.passports-list-container .request-list h3,
.passports-list-container .request-list h4,
.passports-list-container .request-list h5,
.passports-list-container .request-list h6 {
  margin-bottom: 0;
  margin-top: 0;
  white-space: normal;
}
.passports-list-container h5 {
  font-size: 14px !important;
}
.passports-list-container .horizontal-divider {
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(8, 40, 100, 0.15);
}
.passports-list-container .smaller-label {
  white-space: normal;
  font-family: 'Regular', sans-serif !important;
  font-size: calc(14px / 1.3) !important;
}
.passports-list-container .progress-bar .smaller-label {
  white-space: nowrap;
}
.passports-list-container a {
  cursor: pointer;
  color: #666 !important;
}
.passports-list-container .label {
  margin-bottom: 2px !important;
  margin-right: 2px !important;
  margin-top: 0 !important;
  padding: 2px !important;
}
.passports-list-container .badge {
  font-size: calc(14px / 1.2);
}
.passports-list-container .additional_label {
  font-size: 14px !important;
}
.passports-list-container .executed_request_left {
  border-left: 6px solid #595b63 !important;
}
.passports-list-container .approved_request_left {
  border-left: 6px solid #685438 !important;
}
.passports-list-container .new_request_left {
  border-left: 6px solid #07422d !important;
}
.passports-list-container .executed_request {
  border-top: 6px solid #595b63 !important;
}
.passports-list-container .approved_request {
  border-top: 6px solid #685438 !important;
}
.passports-list-container .new_request {
  border-top: 6px solid #0c4d40 !important;
}
.passports-list-container .cancelled_request {
  opacity: 0.5;
  border-top: 6px solid #595b63 !important;
}
.passports-list-container .cancelled_request .label,
.passports-list-container .cancelled_request .badge {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
.passports-list-container .cancelled_request .label a,
.passports-list-container .cancelled_request .badge a {
  color: #595b63 !important;
}
.passports-list-container .cancelled_request .label a.btn-primary,
.passports-list-container .cancelled_request .badge a.btn-primary {
  color: #595b63 !important;
}
.passports-list-container .cancelled_request .label_calculate {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
.passports-list-container .cancelled_request .label_calculate a {
  color: #6d6a6f !important;
}
.passports-list-container .cancelled_request .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.passports-list-container .cancelled_request .label_danger a {
  color: #fff !important;
}
.passports-list-container .cancelled_request .label_danger span {
  color: #fff !important;
}
.passports-list-container .cancelled_request .label-opacity {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}
.passports-list-container .cancelled_request .update_time {
  background: transparent !important;
  width: auto !important;
}
.passports-list-container .cancelled_request .value {
  color: #000000 !important;
}
.passports-list-container .cancelled_request:hover {
  text-decoration: none !important;
  opacity: 1 !important;
}
.passports-list-container .executed_request {
  border-top: 0 !important;
  border-left: 6px solid #595b63 !important;
}
.passports-list-container .approved_request {
  border-top: 0 !important;
  border-left: 6px solid #685438 !important;
}
.passports-list-container .new_request {
  border-top: 0 !important;
  border-left: 6px solid #07422d !important;
}
.passports-list-container .cancelled_request {
  opacity: 0.5;
  border-top: 0 !important;
  border-left: 6px solid #595b63 !important;
}
.tickets-page .request-list-container,
.indicators-page .request-list-container {
  width: 100% !important;
}
.tickets-page .request-list-container .request-list,
.indicators-page .request-list-container .request-list {
  width: 100%;
}
.passport-modal .request-list-container .nav > li > a {
  padding-left: 2px;
  padding-right: 2px;
}
.passport-modal .request-list-container .request-list {
  width: 100% !important;
}
.passport-modal .main-nav-tabs li.active a {
  background: #eeedeb url(img/top-bg-white.png) !important;
  border-bottom: 0 !important;
}
.elevator-modal .form-group .btn-default {
  background: transparent !important;
}
.elevator-modal .form-group .btn-primary {
  background: #ffffff !important;
}
.elevator-modal .elevator-logistics-form .form-group {
  min-height: 50px;
}
.client-modal .form-group {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.client-modal .form-block {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.role-modal .form-group {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.role-modal .form-block {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.logistic-modal .form-group {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.logistic-modal .form-block {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.finance-modal .form-group {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.finance-modal .form-block {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.finance-modal .accordion .card {
  background: #fff;
}
.finance-modal .accordion .card:hover,
.finance-modal .accordion .card:focus {
  transform: none;
}
.finance-modal .accordion .card-header:hover,
.finance-modal .accordion .card-header:focus {
  transform: none;
}
.request-modal .form-group {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.request-modal .form-block {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.request-logistic-table tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.request-logistic-table tr td {
  border: 0;
}
.request-logistic-table .label {
  display: inline-block !important;
  width: auto !important;
  float: none !important;
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px !important;
  vertical-align: middle;
  font-size: 14px !important;
}
.request-logistic-table tr > td {
  padding: 2px !important;
  font-size: 14px !important;
}
.request-logistic-table tr td:last-child {
  border-right: 0 !important;
}
.request-logistic-table tr td:first-child {
  vertical-align: middle;
  font-size: 14px !important;
}
.request-logistic-table tr > th {
  white-space: nowrap !important;
}
.request-logistic-table tr > th::first-letter {
  text-transform: uppercase !important;
}
.cash-flow-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-right: 5px !important;
}
.cash-flow-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.cash-flow-table th::first-letter {
  text-transform: uppercase !important;
}
.cash-flow-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.cash-flow-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.cash-flow-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.cash-flow-table tr td:nth-child(1) {
  vertical-align: middle;
}
.cash-flow-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.cash-flow-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.cash-flow-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.cash-flow-table .label::first-letter {
  text-transform: uppercase !important;
}
.cash-flow-table .label .fa {
  margin-right: 3px;
}
.cash-flow-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.cash-flow-table .badge {
  padding: 4px !important;
}
.cash-flow-table .badge::first-letter {
  text-transform: uppercase !important;
}
.cash-flow-table a {
  cursor: pointer;
}
.cash-flow-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.cash-flow-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.cash-flow-table .label {
  white-space: nowrap !important;
  margin-bottom: 0px !important;
  text-align: center !important;
}
.cash-flow-table .inside-table-ul li {
  font-size: calc(14px / 1.3) !important;
  text-transform: initial !important;
  font-family: 'Regular', sans-serif !important;
  padding-top: 3px;
  padding-bottom: 3px;
  height: auto;
  white-space: normal !important;
  word-wrap: break-word;
  border-bottom: 1px solid #d2cece !important;
}
.cash-flow-table .inside-table-ul li span {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.cash-flow-table .inside-table-ul li:last-child {
  border-bottom: 0 !important;
}
.cash-flow-table tr th {
  border-right: 1px solid #e8e6e6 !important;
  border-top: 1px solid #e8e6e6 !important;
  text-align: left !important;
}
.cash-flow-table tr th div {
  text-align: left !important;
}
.cash-flow-table tr td {
  text-align: right !important;
  max-width: 100px;
  min-width: 100px;
  white-space: nowrap !important;
  cursor: default;
  overflow: hidden;
  line-height: 1 !important;
  padding: 2px !important;
  font-size: calc(14px / 1.2) !important;
  border-right: 1px solid #e8e6e6 !important;
  vertical-align: top !important;
}
.cash-flow-table tr td .btn-link {
  font-size: calc(14px / 1.2) !important;
}
.cash-flow-table tr td span {
  font-size: calc(14px / 1.2) !important;
}
.cash-flow-table tr td span .label {
  padding: 2px !important;
}
.cash-flow-table tr td .btn,
.cash-flow-table tr td .btn-xs {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1 !important;
}
.cash-flow-table tbody {
  border: 0 !important;
}
.cash-flow-table tbody tr td:nth-child(1),
.cash-flow-table thead tr th:nth-child(1) {
  max-width: 160px !important;
  min-width: 160px !important;
  width: 160px !important;
  background: #f9f9f7 !important;
  font-family: 'Semibold', sans-serif !important;
  text-align: left !important;
}
.cash-flow-table tbody tr td:nth-child(1)::first-letter,
.cash-flow-table thead tr th:nth-child(1)::first-letter {
  text-transform: uppercase !important;
}
.cash-flow-table tbody tr td:nth-child(2),
.cash-flow-table thead tr th:nth-child(2) {
  max-width: 100px !important;
  min-width: 100px !important;
  width: 100px !important;
  background: #f9f9f7 !important;
  font-family: 'Semibold', sans-serif !important;
  text-align: left !important;
}
.cash-flow-table tbody tr td:nth-child(2)::first-letter,
.cash-flow-table thead tr th:nth-child(2)::first-letter {
  text-transform: uppercase !important;
}
.cash-flow-table tbody tr td:nth-child(3),
.cash-flow-table thead tr th:nth-child(3) {
  max-width: 120px !important;
  min-width: 120px !important;
  width: 120px !important;
  background: #f9f9f7 !important;
  text-align: left !important;
  white-space: normal;
  word-wrap: break-word;
}
.cash-flow-table tbody tr td:nth-child(3) span,
.cash-flow-table thead tr th:nth-child(3) span {
  font-size: calc(14px / 1.4) !important;
  white-space: normal;
  word-wrap: break-word;
}
.cash-flow-table thead tr th:nth-child(1),
.cash-flow-table thead tr th:nth-child(2),
.cash-flow-table thead tr th:nth-child(3) {
  text-align: center !important;
}
.cash-flow-table tbody tr td:nth-child(1),
.cash-flow-table tbody tr td:nth-child(2),
.cash-flow-table tbody tr td:nth-child(3) {
  padding-left: 5px !important;
}
.cash-flow-table .badge {
  font-size: calc(14px / 1.3) !important;
}
.cash-flow-table td.danger {
  background: rgba(207, 97, 99, 0.13) !important;
  color: #cf6163 !important;
}
.cash-flow-table td.success {
  background: #ebf1ef !important;
  color: #0c4d40 !important;
}
.cash-flow-table .label_danger,
.cash-flow-table .label_success {
  font-size: calc(14px / 1.3) !important;
  padding: 2px !important;
}
.cash-flow-table .alert-success,
.cash-flow-table .alert-danger {
  display: block;
}
.cash-flow-table:nth-of-type(3) tr td {
  background: rgba(207, 97, 99, 0.13) !important;
  color: #cf6163 !important;
}
.cash-flow-table:nth-of-type(3) tr td .fa {
  color: #cf6163 !important;
}
.cash-flow-table:nth-of-type(2) tr td {
  background: #ebf1ef !important;
  color: #0c4d40 !important;
}
.cash-flow-table:nth-of-type(2) tr td .fa {
  color: #0c4d40 !important;
}
.cash-flow-table:nth-of-type(2) tr td .btn-link .fa {
  color: #1763ad !important;
}
#pl-cash-flow-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
#pl-cash-flow-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
#pl-cash-flow-table th::first-letter {
  text-transform: uppercase !important;
}
#pl-cash-flow-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
#pl-cash-flow-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
#pl-cash-flow-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
#pl-cash-flow-table tr td:nth-child(1) {
  vertical-align: middle;
}
#pl-cash-flow-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
#pl-cash-flow-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
#pl-cash-flow-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
#pl-cash-flow-table .label::first-letter {
  text-transform: uppercase !important;
}
#pl-cash-flow-table .label .fa {
  margin-right: 3px;
}
#pl-cash-flow-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
#pl-cash-flow-table .badge {
  padding: 4px !important;
}
#pl-cash-flow-table .badge::first-letter {
  text-transform: uppercase !important;
}
#pl-cash-flow-table a {
  cursor: pointer;
}
#pl-cash-flow-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
#pl-cash-flow-table .item-settings-dropdown li > a {
  line-height: 5px;
}
#pl-cash-flow-table .label {
  white-space: nowrap !important;
  margin-bottom: 0px !important;
  text-align: center !important;
}
#pl-cash-flow-table tr td {
  text-align: right !important;
  width: auto;
  white-space: nowrap !important;
  cursor: default;
  line-height: 1;
  padding: 2px !important;
  font-size: 14px !important;
  border-right: 1px solid #e8e6e6 !important;
}
#pl-cash-flow-table tr td span {
  font-size: 14px !important;
}
#pl-cash-flow-table tr td span .label {
  padding: 2px !important;
}
#pl-cash-flow-table tr td:first-child {
  text-align: left !important;
  color: #000000 !important;
}
#pl-cash-flow-table tr td:first-child .label {
  display: inline-block !important;
}
#pl-cash-flow-table td:nth-child(1),
#pl-cash-flow-table th:nth-child(1) {
  position: absolute;
  background: #f9f9f7 !important;
  border-radius: 0 !important;
  z-index: 999 !important;
  width: 200px;
  margin-left: -5px !important;
  padding-left: 10px !important;
  height: auto !important;
  min-height: 20px !important;
}
#pl-cash-flow-table td:nth-child(1) .inside-table-ul li,
#pl-cash-flow-table th:nth-child(1) .inside-table-ul li {
  font-size: 14px !important;
  text-transform: initial !important;
  font-family: 'Regular', sans-serif !important;
  padding-top: 3px;
  padding-bottom: 3px;
}
#pl-cash-flow-table td:nth-child(1) .inside-table-ul li span,
#pl-cash-flow-table th:nth-child(1) .inside-table-ul li span {
  font-size: 14px !important;
  font-family: 'Regular', sans-serif !important;
}
#pl-cash-flow-table td:nth-child(1) .inside-table-ul li:nth-child(odd),
#pl-cash-flow-table th:nth-child(1) .inside-table-ul li:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
#pl-cash-flow-table .second-td {
  min-width: 195px !important;
  background: #f9f9f7 !important;
  border-color: #f9f9f7 !important;
}
#pl-cash-flow-table .inside-table-ul li {
  font-size: calc(14px / 1.3) !important;
  text-transform: initial !important;
  font-family: 'Regular', sans-serif !important;
  border-bottom: 0 !important;
  padding-top: 3px;
  padding-bottom: 3px;
  height: 16px;
}
#pl-cash-flow-table .inside-table-ul li span {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
#pl-cash-flow-table .inside-table-ul li:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
#pl-cash-flow-table td:nth-child(2),
#pl-cash-flow-table th:nth-child(2) {
  display: table-cell;
}
#pl-cash-flow-table .today {
  background: #f9f9f7 !important;
}
#pl-cash-flow-table tr:nth-child(2) td {
  background: #ebf1ef;
  color: #0c4d40;
}
#pl-cash-flow-table tr:nth-child(6) td {
  background: rgba(207, 97, 99, 0.13);
  color: #cf6163;
}
#pl-cash-flow-table .badge {
  font-size: calc(14px / 1.4) !important;
}
#pl-cash-flow-table td.danger {
  background: rgba(207, 97, 99, 0.13) !important;
  color: #cf6163 !important;
}
#pl-cash-flow-table td.success {
  background: #ebf1ef !important;
  color: #0c4d40 !important;
}
td.danger {
  background: rgba(207, 97, 99, 0.13) !important;
  color: #cf6163 !important;
}
td.success {
  background: #ebf1ef !important;
  color: #0c4d40 !important;
}
#pl-daily-table {
  overflow-x: auto;
}
#pl-daily-table td {
  padding: 2px !important;
  white-space: nowrap;
  text-align: right !important;
  border-right: 1px solid #e8e6e6 !important;
}
#pl-daily-table td .label,
#pl-daily-table td .badge {
  font-family: 'Regular', sans-serif !important;
}
#pl-daily-table td:first-child {
  font-family: 'Bold', sans-serif !important;
  text-align: left !important;
}
#pl-daily-table tr td:last-child,
#pl-daily-table tr th:last-child {
  border-right: 0 !important;
}
#pl-daily-table td.td-right-align {
  text-align: right !important;
}
#pl-daily-table td.td-center-align {
  text-align: center !important;
}
.pl-table tr td {
  border-right: 1px solid #e8e6e6 !important;
  text-align: right !important;
  font-size: 14px !important;
  vertical-align: middle !important;
  padding: 2px !important;
  white-space: nowrap;
  line-height: 1 !important;
}
.pl-table tr td .label,
.pl-table tr td .badge {
  display: inline-flex;
}
.pl-table tr td:first-child {
  text-align: left !important;
  font-size: 14px !important;
}
.pl-table tr td:last-child,
.pl-table tr th:last-child {
  border-right: 0 !important;
}
.pl-table .form-control {
  height: 16px;
  font-size: 14px !important;
}
.pl-table .income-row {
  background: #ebf1ef !important;
  color: #0c4d40;
  font-family: 'Bold', sans-serif !important;
  font-size: 14px !important;
}
.pl-table .expense-row {
  background: rgba(207, 97, 99, 0.13) !important;
  color: #cf6163;
  font-family: 'Bold', sans-serif !important;
  font-size: 14px !important;
}
.pl-table th[column-name='q1'],
.pl-table th[column-name='q2'],
.pl-table th[column-name='q3'],
.pl-table th[column-name='q4'] {
  background: #f9f9f7 !important;
}
.pl-table .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.pl-table .bigger-label {
  font-size: 14px !important;
  font-family: 'Bold', sans-serif !important;
  padding: 0 !important;
}
.pl-table .highlighted-tr td {
  font-family: 'Bold', sans-serif !important;
  background: #f9f9f7 !important;
}
.pl-table .fa-sort-up {
  visibility: hidden;
}
.pl-table .quarter {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
}
.pl-table .highlighted {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
}
.pl-table tr td {
  padding: 6px !important;
}
.pl-table tr td:first-child.highlighted {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
  text-transform: uppercase;
}
.pl-table tr td:last-child.highlighted {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
}
#pl-balance-table .col-xs-4 {
  padding: 0;
  overflow: hidden;
}
#pl-balance-table td {
  padding: 2px !important;
  white-space: nowrap;
  border-right: 1px solid #e8e6e6 !important;
  text-align: right !important;
}
#pl-balance-table td .label {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
  font-size: calc(14px / 1.3);
}
#pl-balance-table h5,
#pl-balance-table h3 {
  padding: 0;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
#pl-balance-table tr td:last-child,
#pl-balance-table tr th:last-child {
  border-right: 0 !important;
}
#pl-balance-table td:first-child {
  font-family: 'Semibold', sans-serif !important;
  text-align: left !important;
}
#pl-balance-table td.highlighted-td {
  font-family: 'Bold', sans-serif !important;
}
#pl-balance-table td.td-left-align {
  text-align: left !important;
}
#pl-balance-table td.td-right-align {
  text-align: right !important;
}
#pl-balance-table td.td-center-align {
  text-align: center !important;
}
#pl-balance-table .progress .progress-bar {
  text-align: center;
}
#pl-process-table label {
  font-size: calc(14px / 1.3) !important;
}
.attached-plan {
  background: rgba(0, 0, 0, 0.12) !important;
}
#pl-costs-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  overflow-x: auto !important;
}
#pl-costs-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
#pl-costs-table th::first-letter {
  text-transform: uppercase !important;
}
#pl-costs-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
#pl-costs-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
#pl-costs-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
#pl-costs-table tr td:nth-child(1) {
  vertical-align: middle;
}
#pl-costs-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
#pl-costs-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
#pl-costs-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
#pl-costs-table .label::first-letter {
  text-transform: uppercase !important;
}
#pl-costs-table .label .fa {
  margin-right: 3px;
}
#pl-costs-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
#pl-costs-table .badge {
  padding: 4px !important;
}
#pl-costs-table .badge::first-letter {
  text-transform: uppercase !important;
}
#pl-costs-table a {
  cursor: pointer;
}
#pl-costs-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
#pl-costs-table .item-settings-dropdown li > a {
  line-height: 5px;
}
#pl-costs-table td:nth-child(1) {
  text-align: left;
}
#pl-costs-table td:nth-child(1) .label {
  text-align: left !important;
  margin-left: 0 !important;
}
#pl-costs-table td {
  text-align: center;
  width: 100% !important;
  word-break: normal !important;
  font-size: calc(14px / 1.3) !important;
  white-space: nowrap !important;
}
#pl-costs-table td .label {
  white-space: nowrap !important;
}
#pl-costs-table th {
  width: 100% !important;
  white-space: nowrap;
}
#pl-costs-table th:last-child {
  color: #cf6163 !important;
}
#pl-costs-table tr th:last-child,
#pl-costs-table tr td:last-child {
  background: #f9f9f7 !important;
}
#pl-costs-table .passport_executed > td {
  color: #000000 !important;
  background: #fff !important;
  font-weight: normal;
}
#pl-costs-table .passport_executed > td .btn-link {
  color: #000000 !important;
  border-bottom-color: #000000 !important;
}
#pl-costs-table .passport_executed > td .label {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
#pl-costs-table .passport_executed > td .label a {
  color: #595b63 !important;
}
#pl-costs-table .passport_executed > td .label a.btn-primary {
  color: #595b63 !important;
}
#pl-costs-table .passport_request > td {
  color: #0c4d40 !important;
}
#pl-costs-table .passport_request > td .btn-link,
#pl-costs-table .passport_request > td a {
  color: #0c4d40 !important;
  border-bottom-color: #0c4d40 !important;
}
#pl-costs-table .passport_request > td .label {
  background-color: rgba(28, 179, 148, 0.4) !important;
  color: #07422d !important;
}
#pl-costs-table .passport_request > td .label a {
  color: #07422d !important;
}
#pl-costs-table .passport_processing > td {
  color: #685438 !important;
}
#pl-costs-table .passport_processing > td .btn-link,
#pl-costs-table .passport_processing > td a {
  color: #685438 !important;
  border-bottom-color: #685438 !important;
}
#pl-costs-table .passport_processing > td .label {
  background-color: rgba(181, 146, 96, 0.4) !important;
  color: #685438 !important;
}
#pl-costs-table .passport_processing > td .label a {
  color: #685438 !important;
}
#pl-costs-table .passport_approved > td {
  color: #a495b0 !important;
}
#pl-costs-table .passport_approved > td .btn-link,
#pl-costs-table .passport_approved > td a {
  color: #a495b0 !important;
  border-bottom-color: #a495b0 !important;
}
#pl-costs-table .passport_approved > td .label {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
#pl-costs-table .passport_approved > td .label a {
  color: #6d6a6f !important;
}
#pl-costs-table .passport_cancelled > td {
  color: #7f8c8d !important;
  background: #fff !important;
  text-decoration: line-through;
  font-weight: normal;
}
#pl-costs-table .passport_cancelled > td .btn-link,
#pl-costs-table .passport_cancelled > td a {
  color: #7f8c8d !important;
  border-bottom-color: #7f8c8d !important;
}
#pl-costs-table .passport_cancelled > td .label {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
#pl-costs-table .passport_cancelled > td .label a {
  color: #595b63 !important;
}
#pl-costs-table .passport_cancelled > td .label a.btn-primary {
  color: #595b63 !important;
}
.related-table tr td,
.related-table tr th {
  border-color: #e8e6e6 !important;
}
.consolidated-table th {
  text-align: center !important;
  text-transform: uppercase !important;
  cursor: pointer;
}
.consolidated-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.consolidated-table tr td {
  color: #000000 !important;
}
.consolidated-table tr td .label {
  font-size: calc(14px / 1.3) !important;
}
.consolidated-table tr td:nth-child(2) {
  text-transform: uppercase !important;
  background: rgba(0, 0, 0, 0.01);
  text-align: center !important;
  vertical-align: middle;
}
.consolidated-table tr td:nth-child(2) span {
  font-size: 0.7em;
}
.consolidated-table tr td:nth-child(2) .btn-link {
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.consolidated-table tr td:nth-child(2) .btn-link:hover {
  text-decoration: underline;
}
#supplier-consolidated-table tr:hover,
#buyer-consolidated-table tr:hover {
  cursor: pointer;
}
#supplier-consolidated-table tr:hover td,
#buyer-consolidated-table tr:hover td {
  color: #666 !important;
  text-decoration: none;
}
#supplier-consolidated-table td:last-child,
#buyer-consolidated-table td:last-child {
  background: #f9f9f7;
}
#supplier-consolidated-table td:last-child .label,
#buyer-consolidated-table td:last-child .label {
  font-size: calc(14px / 1.3);
}
#supplier-consolidated-table tr th,
#buyer-consolidated-table tr th {
  font-size: calc(14px / 1.3) !important;
  padding: 2px;
}
#pl-statistics-table select[multiple],
#pl-statistics-table select[size] {
  min-height: 300px;
}
.table-with-inline-add tr td,
.table-with-inline-add div {
  vertical-align: top !important;
}
.table-with-inline-add tr td .input-group .input-group-addon,
.table-with-inline-add div .input-group .input-group-addon {
  padding: 3px;
}
.table-with-inline-add tr td .inside-table-ul li,
.table-with-inline-add div .inside-table-ul li {
  text-align: left !important;
}
.table-with-inline-add tr td .ui-select-bootstrap > .ui-select-match > .btn,
.table-with-inline-add div .ui-select-bootstrap > .ui-select-match > .btn {
  border-radius: 0 !important;
  min-height: 20px !important;
  padding: 6px !important;
  margin-left: 0 !important;
}
.table-with-inline-add tr td .alert,
.table-with-inline-add div .alert {
  padding: 4px !important;
  margin: 0;
  margin-top: 5px;
}
.table-with-inline-add tr td .form-indicator,
.table-with-inline-add div .form-indicator {
  color: #1bb394 !important;
  font-size: calc(14px / 1.8) !important;
}
.table-with-inline-add tr td .label-input,
.table-with-inline-add div .label-input {
  display: block;
  margin-top: 10px;
}
.table-with-inline-add tr td .form-block,
.table-with-inline-add div .form-block {
  display: block;
}
.table-with-inline-add tr td .form-control,
.table-with-inline-add div .form-control {
  height: auto !important;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Regular', sans-serif, 'FontAwesome' !important;
  line-height: 1 !important;
  overflow: visible;
  white-space: normal;
  font-size: calc(14px / 1.2) !important;
  min-height: 20px !important;
  padding: 4px;
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid #b0a9a9 !important;
}
.table-with-inline-add tr td .form-control::-webkit-input-placeholder,
.table-with-inline-add div .form-control::-webkit-input-placeholder {
  font-size: calc(14px / 1.2) !important;
}
.table-with-inline-add tr td .form-control::-moz-placeholder,
.table-with-inline-add div .form-control::-moz-placeholder {
  font-size: calc(14px / 1.2) !important;
}
.table-with-inline-add tr td .form-control:-moz-placeholder,
.table-with-inline-add div .form-control:-moz-placeholder {
  font-size: calc(14px / 1.2) !important;
}
.table-with-inline-add tr td .form-control:-ms-input-placeholder,
.table-with-inline-add div .form-control:-ms-input-placeholder {
  font-size: calc(14px / 1.2) !important;
}
.table-with-inline-add tr td .form-control span,
.table-with-inline-add div .form-control span {
  font-size: calc(14px / 1.2);
}
.table-with-inline-add tr td .form-control .ui-select-match-text,
.table-with-inline-add div .form-control .ui-select-match-text {
  color: #000000 !important;
  font-family: 'Regular', sans-serif, 'FontAwesome' !important;
  word-break: break-word;
  font-size: calc(14px / 1.2) !important;
}
.table-with-inline-add tr td .form-control.transparent-form,
.table-with-inline-add div .form-control.transparent-form {
  background: #fff !important;
}
.table-with-inline-add tr td .form-control.narrow-textarea,
.table-with-inline-add div .form-control.narrow-textarea {
  text-align: left;
  border-radius: 6px !important;
  height: 30px !important;
  width: 50% !important;
  float: right;
}
.table-with-inline-add input,
.table-with-inline-add .form-control,
.table-with-inline-add .ui-select-match,
.table-with-inline-add .ui-select-search {
  font-size: calc(14px / 1.2) !important;
  text-align: left;
  vertical-align: middle !important;
  border-color: #b0a9a9 !important;
}
.table-with-inline-add input::-webkit-input-placeholder,
.table-with-inline-add .form-control::-webkit-input-placeholder,
.table-with-inline-add .ui-select-match::-webkit-input-placeholder,
.table-with-inline-add .ui-select-search::-webkit-input-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
.table-with-inline-add input::-moz-placeholder,
.table-with-inline-add .form-control::-moz-placeholder,
.table-with-inline-add .ui-select-match::-moz-placeholder,
.table-with-inline-add .ui-select-search::-moz-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
.table-with-inline-add input:-moz-placeholder,
.table-with-inline-add .form-control:-moz-placeholder,
.table-with-inline-add .ui-select-match:-moz-placeholder,
.table-with-inline-add .ui-select-search:-moz-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
.table-with-inline-add input:-ms-input-placeholder,
.table-with-inline-add .form-control:-ms-input-placeholder,
.table-with-inline-add .ui-select-match:-ms-input-placeholder,
.table-with-inline-add .ui-select-search:-ms-input-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
.table-with-inline-add input .text-muted,
.table-with-inline-add .form-control .text-muted,
.table-with-inline-add .ui-select-match .text-muted,
.table-with-inline-add .ui-select-search .text-muted {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
.table-with-inline-add input .caret,
.table-with-inline-add .form-control .caret,
.table-with-inline-add .ui-select-match .caret,
.table-with-inline-add .ui-select-search .caret {
  display: none;
}
.table-with-inline-add .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  font-size: calc(14px / 1.2) !important;
}
.table-with-inline-add .ui-select-match-text {
  margin: 0 !important;
}
.table-with-inline-add .input-group {
  margin-bottom: 2px;
}
.table-with-inline-add .form-control .ui-select-match-text {
  font-size: calc(14px / 1.2) !important;
}
.table-with-inline-add .ui-select-match .form-control {
  line-height: 1;
  font-size: calc(14px / 1.2) !important;
}
.table-with-inline-add tr td .ui-select-match-text {
  min-height: 1px !important;
  margin-top: 7px;
}
table.invoice-position-table tr th div.td-right-align,
table.invoice-position-table tr th div.td-left-align {
  text-align: center !important;
}
table.invoice-position-table tr td,
table.invoice-position-table div {
  vertical-align: top !important;
}
table.invoice-position-table tr td .input-group .input-group-addon,
table.invoice-position-table div .input-group .input-group-addon {
  padding: 3px;
}
table.invoice-position-table tr td .inside-table-ul li,
table.invoice-position-table div .inside-table-ul li {
  text-align: left !important;
}
table.invoice-position-table tr td .ui-select-bootstrap > .ui-select-match > .btn,
table.invoice-position-table div .ui-select-bootstrap > .ui-select-match > .btn {
  border-radius: 0 !important;
  min-height: 20px !important;
  padding: 6px !important;
  margin-left: 0 !important;
}
table.invoice-position-table tr td .alert,
table.invoice-position-table div .alert {
  padding: 4px !important;
  margin: 0;
  margin-top: 5px;
}
table.invoice-position-table tr td .form-indicator,
table.invoice-position-table div .form-indicator {
  color: #1bb394 !important;
  font-size: calc(14px / 1.8) !important;
}
table.invoice-position-table tr td .label-input,
table.invoice-position-table div .label-input {
  display: block;
  margin-top: 10px;
}
table.invoice-position-table tr td .form-block,
table.invoice-position-table div .form-block {
  display: block;
}
table.invoice-position-table tr td .form-control,
table.invoice-position-table div .form-control {
  height: auto !important;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Regular', sans-serif, 'FontAwesome' !important;
  line-height: 1 !important;
  overflow: visible;
  white-space: normal;
  font-size: calc(14px / 1.2) !important;
  min-height: 20px !important;
  padding: 4px;
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid #b0a9a9 !important;
}
table.invoice-position-table tr td .form-control::-webkit-input-placeholder,
table.invoice-position-table div .form-control::-webkit-input-placeholder {
  font-size: calc(14px / 1.2) !important;
}
table.invoice-position-table tr td .form-control::-moz-placeholder,
table.invoice-position-table div .form-control::-moz-placeholder {
  font-size: calc(14px / 1.2) !important;
}
table.invoice-position-table tr td .form-control:-moz-placeholder,
table.invoice-position-table div .form-control:-moz-placeholder {
  font-size: calc(14px / 1.2) !important;
}
table.invoice-position-table tr td .form-control:-ms-input-placeholder,
table.invoice-position-table div .form-control:-ms-input-placeholder {
  font-size: calc(14px / 1.2) !important;
}
table.invoice-position-table tr td .form-control span,
table.invoice-position-table div .form-control span {
  font-size: calc(14px / 1.2);
}
table.invoice-position-table tr td .form-control .ui-select-match-text,
table.invoice-position-table div .form-control .ui-select-match-text {
  color: #000000 !important;
  font-family: 'Regular', sans-serif, 'FontAwesome' !important;
  word-break: break-word;
  font-size: calc(14px / 1.2) !important;
}
table.invoice-position-table tr td .form-control.transparent-form,
table.invoice-position-table div .form-control.transparent-form {
  background: #fff !important;
}
table.invoice-position-table tr td .form-control.narrow-textarea,
table.invoice-position-table div .form-control.narrow-textarea {
  text-align: left;
  border-radius: 6px !important;
  height: 30px !important;
  width: 50% !important;
  float: right;
}
table.invoice-position-table input,
table.invoice-position-table .form-control,
table.invoice-position-table .ui-select-match,
table.invoice-position-table .ui-select-search {
  font-size: calc(14px / 1.2) !important;
  text-align: left;
  vertical-align: middle !important;
  border-color: #b0a9a9 !important;
}
table.invoice-position-table input::-webkit-input-placeholder,
table.invoice-position-table .form-control::-webkit-input-placeholder,
table.invoice-position-table .ui-select-match::-webkit-input-placeholder,
table.invoice-position-table .ui-select-search::-webkit-input-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
table.invoice-position-table input::-moz-placeholder,
table.invoice-position-table .form-control::-moz-placeholder,
table.invoice-position-table .ui-select-match::-moz-placeholder,
table.invoice-position-table .ui-select-search::-moz-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
table.invoice-position-table input:-moz-placeholder,
table.invoice-position-table .form-control:-moz-placeholder,
table.invoice-position-table .ui-select-match:-moz-placeholder,
table.invoice-position-table .ui-select-search:-moz-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
table.invoice-position-table input:-ms-input-placeholder,
table.invoice-position-table .form-control:-ms-input-placeholder,
table.invoice-position-table .ui-select-match:-ms-input-placeholder,
table.invoice-position-table .ui-select-search:-ms-input-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
table.invoice-position-table input .text-muted,
table.invoice-position-table .form-control .text-muted,
table.invoice-position-table .ui-select-match .text-muted,
table.invoice-position-table .ui-select-search .text-muted {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
table.invoice-position-table input .caret,
table.invoice-position-table .form-control .caret,
table.invoice-position-table .ui-select-match .caret,
table.invoice-position-table .ui-select-search .caret {
  display: none;
}
table.invoice-position-table .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  font-size: calc(14px / 1.2) !important;
}
table.invoice-position-table .ui-select-match-text {
  margin: 0 !important;
}
table.invoice-position-table .input-group {
  margin-bottom: 2px;
}
table.invoice-position-table .form-control .ui-select-match-text {
  font-size: calc(14px / 1.2) !important;
}
table.invoice-position-table .ui-select-match .form-control {
  line-height: 1;
  font-size: calc(14px / 1.2) !important;
}
table.invoice-position-table tr td .ui-select-match-text {
  min-height: 1px !important;
  margin-top: 7px;
}
table.invoice-position-table tr td {
  vertical-align: top !important;
  white-space: normal;
  border-right: 0 !important;
  min-width: 50px !important;
  font-size: calc(14px / 1.5) !important;
  color: #666b72 !important;
  font-family: 'Regular', sans-serif !important;
  border-top-width: 4px !important;
}
table.invoice-position-table tr td .inside-table-ul li {
  text-align: left !important;
}
.invoice-position-list-table tr td {
  white-space: nowrap !important;
  font-family: 'Regular', sans-serif !important;
}
.invoice-position-list-table tr td .form-control {
  height: auto !important;
  font-size: calc(14px / 1.2);
  font-family: 'Regular', sans-serif, 'FontAwesome' !important;
  line-height: 1;
  overflow: visible;
  white-space: normal;
}
.invoice-position-list-table tr td .form-control span {
  font-size: calc(14px / 1.2);
}
#statistics-table,
#statistics-finance-table,
#statistics-execution-table,
.dashboard-table {
  padding-left: 5px;
  padding-right: 5px;
}
#statistics-table th,
#statistics-finance-table th,
#statistics-execution-table th,
.dashboard-table th {
  font-size: 0.9em;
  text-transform: uppercase;
  margin-right: 2px;
  padding: 2px !important;
}
#statistics-table td,
#statistics-finance-table td,
#statistics-execution-table td,
.dashboard-table td {
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: right;
  text-transform: uppercase;
  color: #595b63;
}
#statistics-table td .label,
#statistics-finance-table td .label,
#statistics-execution-table td .label,
.dashboard-table td .label {
  margin-right: 2px;
  margin-bottom: 2px;
}
#statistics-table .lower-zero-number,
#statistics-finance-table .lower-zero-number,
#statistics-execution-table .lower-zero-number,
.dashboard-table .lower-zero-number {
  color: #cf6163 !important;
}
#statistics-table .higher-zero-number,
#statistics-finance-table .higher-zero-number,
#statistics-execution-table .higher-zero-number,
.dashboard-table .higher-zero-number {
  color: #1bb394 !important;
}
#statistics-table td:first-child,
#statistics-finance-table td:first-child,
#statistics-execution-table td:first-child,
.dashboard-table td:first-child {
  color: #000000;
  padding-left: 15px;
  vertical-align: middle;
  background: transparent !important;
  text-align: left !important;
  font-family: 'Bold', sans-serif !important;
}
#statistics-table td:first-child .label,
#statistics-finance-table td:first-child .label,
#statistics-execution-table td:first-child .label,
.dashboard-table td:first-child .label {
  padding: 2px !important;
}
.dashboard-table {
  width: 100% !important;
}
.dashboard-table td:nth-child(3) {
  color: #000000 !important;
}
.dashboard-table td:first-child {
  text-align: left !important;
  font-family: 'Bold', sans-serif !important;
}
.dashboard-table td:first-child .btn-link {
  font-family: 'Light', sans-serif !important;
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.dashboard-table td:first-child .btn-link:hover {
  text-decoration: underline;
}
.dashboard-table tr td {
  vertical-align: middle !important;
  padding: 2px !important;
}
.dashboard-table tr td .label,
.dashboard-table tr td .badge {
  font-size: calc(14px / 1.3);
  padding: 2px !important;
}
.dashboard-table tr th {
  padding: 6px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  white-space: normal;
  word-wrap: break-word;
}
.team-table tr th {
  padding: 6px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.team-table .item-settings-dropdown {
  float: left !important;
}
.team-table .item-settings-dropdown .dropdown-menu {
  left: 0 !important;
}
.team-table tr td {
  white-space: normal !important;
}
.team-table tr td.nowrap {
  white-space: nowrap !important;
}
.chart-block-wrapper .form-control {
  padding: 2px !important;
  height: 22px;
}
.chart-block-wrapper .chart-graph-block,
.chart-block-wrapper .chart-selects-block {
  width: 100% !important;
}
.chart-block-wrapper .page-header {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
}
.chart-block-wrapper .page-header .start-page-header,
.chart-block-wrapper .page-header .pull-left {
  text-align: left !important;
}
.chart-block-wrapper .page-header h3 {
  display: none;
}
.chart-block-wrapper .contract-description-label {
  font-size: 14px !important;
}
.small-chart-block .chart-graph-block {
  width: 100% !important;
}
#statistics-execution-table td {
  color: #595b63;
}
.costs-table tbody tr:nth-child(even),
.quality-table tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.costs-table tr td,
.quality-table tr td {
  border: 0;
}
.costs-table tr td,
.quality-table tr td {
  border-color: #e8e6e6 !important;
  color: #000000 !important;
}
.costs-table tr td:first-child,
.quality-table tr td:first-child {
  border-color: #e8e6e6 !important;
  color: #000000 !important;
  background: rgba(0, 0, 0, 0.01) !important;
}
.prices_img {
  width: 100% !important;
  text-align: center;
  height: auto;
}
#status_btn {
  margin: 10px 0 10px 50px;
  margin-right: 0;
}
.add-indicator-start-btn {
  margin-right: 130px;
}
.add-indicator-calculator-start-btn {
  margin-right: 195px;
}
.add-ticket-start-btn {
  margin-right: 65px;
}
.btn-light,
.btn.btn-light,
a.btn-light {
  font-family: 'Light', sans-serif !important;
}
.inside-table-btn {
  font-size: calc(14px / 1.3);
  padding: 0 !important;
  box-shadow: none !important;
  text-transform: inherit !important;
}
.clear-btn {
  background: transparent !important;
  border: 0 !important;
}
.popup-wrapper a {
  color: #ffffff !important;
}
#attach_btn,
#grain_quality_btn,
#notes_btn,
#add-new-payment {
  margin-top: 5px;
  width: auto;
}
.btn-filter-table {
  font-size: calc(14px * 1.2);
}
.add-btn {
  color: #ffffff;
  border-color: #1bb394 !important;
  background: #1bb394 !important;
  border-width: 1px;
  box-shadow: none !important;
  border: 1px solid #106856 !important;
}
.add-btn:hover {
  background: #1bb394;
  color: #ffffff !important;
  box-shadow: none !important;
}
.btn {
  border-radius: 4px !important;
  outline: none !important;
  font-family: 'Regular', sans-serif !important;
  letter-spacing: var(--letter-spacing-very-huge, 0.07em);
  -webkit-text-stroke: 0;
}
.btn::first-letter {
  text-transform: uppercase !important;
}
.btn:hover,
.btn:focus {
  transform: scale(1.05);
  transition: transform 0.2s;
  color: initial !important;
}
.btn-primary {
  box-shadow: none !important;
}
.btn-add-fixed {
  position: absolute;
  right: 10px;
  margin-top: -90px;
  box-shadow: none !important !important;
  border-radius: 100% !important;
}
.btn-scrollup {
  background: transparent;
  z-index: 1000 !important;
  position: fixed;
  font-size: 20px;
  height: 30px;
  width: 10px;
  padding: 0;
  right: 1%;
  bottom: 0%;
}
.btn-disable {
  opacity: 0.5;
}
.filter-template .btn-primary {
  box-shadow: none !important;
  white-space: normal;
  word-wrap: break-word;
  color: #000000 !important;
  border-radius: 0 !important;
  border-bottom: 2px solid #1d82c7 !important;
}
.btn-default {
  color: #666b72;
  border: 0 !important;
  background: transparent;
}
.btn-default:hover,
.btn-default:focus {
  background: #f0f0eb;
  box-shadow: none;
}
.btn-warning {
  color: #ffffff;
  background: #ed9c28 !important;
  border-width: 1px;
  border-color: #ed9c28 !important;
  box-shadow: none !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  border-color: #ed9c28 !important;
  background: #ed9c28 !important;
}
.btn-grey {
  color: #666;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.07) 25%, transparent 15%, transparent 50%, rgba(0, 0, 0, 0.08) 50%, rgba(0, 0, 0, 0.08) 75%, transparent 75%, transparent);
  border-width: 1px;
  border-color: #666b72;
}
.btn-grey:hover,
.btn-grey:focus,
.btn-grey:active,
.btn-grey.active,
.open .dropdown-toggle.btn-grey {
  border-color: #666b72 !important;
  background: #666b72;
  color: #f9f9f7 !important;
}
.btn-link {
  color: #1763ad !important;
  padding-bottom: 0;
  border-radius: 0 !important;
  font-family: 'Semibold', sans-serif !important;
}
.btn-link:hover {
  text-decoration: underline;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  color: #1763ad;
  border-bottom: 1px dashed #1763ad;
  text-decoration: none !important;
}
.btn-success {
  color: #ffffff;
  border-color: #1bb394 !important;
  background: #1bb394 !important;
  border-width: 1px;
  box-shadow: none !important;
}
button.btn-success {
  border-color: #1bb394 !important;
  background-color: #1bb394 !important;
  border-radius: 4px !important;
}
button.btn-success:hover {
  opacity: 0.9;
}
.btn-add-dark {
  border-color: #c7c7b4;
  color: #666;
  background: transparent !important;
  border-width: 1px;
}
.btn-add-dark:hover {
  background: #34495e !important;
  color: #fff !important;
}
.btn-info {
  color: #ffffff;
  border-color: #1d82c7 !important;
  background: #1d82c7 !important;
  border-width: 1px;
  box-shadow: none !important;
}
.btn-blue-border {
  border-color: #1d82c7 !important;
  border-width: 1px;
}
.btn-blue-border:hover {
  background-color: #1d82c7 !important;
  color: #ffffff !important;
}
.btn-green-border {
  border-color: #1bb394 !important;
  border-width: 1px;
}
.btn-grey-border:hover {
  background-color: #666b72 !important;
  color: #ffffff !important;
}
.btn-grey-border {
  border-color: #666b72 !important;
  border-width: 1px;
}
.btn-dark-border:hover {
  background-color: #151e26 !important;
  color: #ffffff !important;
}
.btn-dark-border {
  border-color: #151e26 !important;
  border-width: 1px;
}
.btn-green-border:hover {
  background-color: #1bb394 !important;
  color: #ffffff !important;
}
.btn-orange-border {
  border-color: #ed9c28 !important;
  border-width: 1px;
}
.btn-orange-border:hover {
  background-color: #ed9c28 !important;
  color: #ffffff !important;
}
.other-actions-btn {
  padding: 2px !important;
  border-radius: 4px !important;
  background: #fff;
  text-transform: uppercase !important;
  box-shadow: none !important;
}
.btn-login {
  width: 100%;
  color: #ffffff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.07) 25%, transparent 15%, transparent 50%, rgba(0, 0, 0, 0.08) 50%, rgba(0, 0, 0, 0.08) 75%, transparent 75%, transparent);
  background-color: #47a447 !important;
  border-color: #47a447 !important;
  outline: 0 !important;
  padding: 10px !important;
}
.btn-login:focus {
  outline: 0 !important;
}
.btn-danger {
  background: #fff;
  border-width: 1px;
}
.btn-no-data {
  padding: 6px 6px;
  font-size: 0.9em;
  margin: 0 auto;
  display: block;
  position: relative;
  float: none;
}
.btn-update,
.btn-update:hover,
.btn-update:focus {
  color: #666b72 !important;
  border: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
  overflow: hidden !important;
}
.btn-active-theme {
  background-color: #c6c7cb;
}
.no-data-btn {
  text-align: center;
  display: block;
  width: 30%;
  margin: 0 auto;
  float: none;
  color: #000000 !important;
}
.no-data-btn:hover {
  color: #ffffff !important;
}
#edit_btn {
  padding: 2px;
}
.edit-btns-group-main .btn-fixed {
  background: linear-gradient(45deg, #e9e9e9 25%, #f5f5f5 15%, #f5f5f5 50%, #e9e9e9 50%, #e9e9e9 75%, #f5f5f5 75%, #f5f5f5);
  color: #000000 !important;
  border-radius: 100% !important;
  width: 40px;
  height: 40px;
  line-height: 1.6;
  font-size: 16px;
  position: relative;
}
.edit-btns-group-main .btn-default:hover {
  background: #e5e5dd !important;
}
.edit-btns-group-main .btn-danger:hover {
  background: #cf6163 !important;
}
.edit-btns-group-main .btn-danger:hover,
.edit-btns-group-main .btn-success:hover,
.edit-btns-group-main .btn-info:hover,
.edit-btns-group-main .btn-warning:hover {
  color: #ffffff !important;
}
.edit-btns-group-main .btn-fixed:nth-child(1) {
  bottom: 2%;
}
.edit-btns-group-main .btn-fixed:nth-child(2) {
  bottom: 14%;
}
.edit-btns-group-main .btn-fixed:nth-child(3) {
  bottom: 22%;
}
.btn-fixed {
  height: 70px;
  width: 70px;
  border-radius: 100% !important;
  line-height: 2.8;
  box-shadow: 0px 0px 12px #666b72 !important;
  font-family: 'Semibold', sans-serif !important;
  z-index: 1000 !important;
  right: 15px;
  bottom: 10px;
  position: fixed;
  font-size: calc(14px * 1.4) !important;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  color: #ffffff !important;
}
.btn-fixed.btn-danger {
  background: #cf6163;
}
.btn-fixed:hover {
  color: #ffffff !important;
  box-shadow: none !important;
}
.modal-edit-btns-group-main .btn {
  color: #ffffff !important;
  margin-left: 5px;
  border-width: 2px !important;
  font-family: 'Bold', sans-serif !important;
}
.modal-edit-btns-group-main .btn-fixed {
  position: relative;
  color: #fff !important;
  border-radius: 100% !important;
  width: 30px;
  height: 30px;
  line-height: 1;
  font-size: 12px !important;
  bottom: 0 !important;
  margin-right: 5px;
  z-index: 9998;
  float: right;
  padding-left: 9px;
  margin-top: 2px;
}
.modal-edit-btns-group-main .btn-fixed .fa-times {
  color: #909597 !important;
}
.modal-edit-btns-group-main .btn-fixed .fa-cog {
  color: #909597 !important;
}
.modal-edit-btns-group-main .btn-default:hover {
  background: #e5e5dd !important;
}
.modal-edit-btns-group-main .btn-danger:hover,
.modal-edit-btns-group-main .btn-success:hover,
.modal-edit-btns-group-main .btn-info:hover,
.modal-edit-btns-group-main .btn-warning:hover {
  color: #ffffff !important;
}
.modal-edit-btns-group-main .btn-danger:hover {
  background: #cf6163 !important;
}
.modal-edit-btns-group-main .btn-warning {
  background: #ed9c28;
  color: #ffffff;
}
.modal-edit-btns-group-main .btn-info {
  background: #1d82c7;
  color: #ffffff;
}
.modal-edit-btns-group-main .btn-danger {
  background: #cf6163;
  color: #ffffff !important;
}
.edit-btns-group-sub {
  margin-right: 0;
  display: block;
  text-align: right;
  margin-top: 15px;
  position: relative;
  z-index: 9998;
}
.edit-btns-group-sub .btn-fixed {
  width: 40px !important;
  height: 40px !important;
  position: relative !important;
  line-height: 2em;
  font-size: 16px;
  background: linear-gradient(45deg, #e9e9e9 25%, #f5f5f5 15%, #f5f5f5 50%, #e9e9e9 50%, #e9e9e9 75%, #f5f5f5 75%, #f5f5f5);
  margin-right: 10px;
  right: 0 !important;
  left: 0;
}
.edit-btns-group-sub .btn-default:hover {
  background: #e5e5dd !important;
}
#show-more-btn {
  border-radius: 4px !important;
  border: 1px dashed #666b72 !important;
}
.tabs-container {
  border-radius: 4px !important;
  background: rgba(0, 0, 0, 0.05);
}
.half-size-btn-group {
  width: 40% !important;
  margin-top: 5px;
  margin-bottom: 5px;
}
.help-btn-group {
  position: absolute;
  right: 35px;
  top: 50px;
  z-index: 999;
}
.help-btn-group a {
  float: right;
  margin-right: 5px !important;
  margin-left: 5px !important;
  width: auto;
  text-align: left;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  font-family: 'Bold', sans-serif !important;
  box-shadow: none !important;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  background: #fff;
}
.help-btn-group a.gt-head-filter {
  border-radius: 4px;
  padding: 6px 12px;
}
.help-btn-group a:last-child {
  margin-right: 0 !important;
}
.help-btn-group a:hover {
  cursor: pointer;
  background: #eeedeb;
  box-shadow: 0px 1px 1px #666b72;
}
.help-btn-group .tooltip:hover::before {
  top: 40px;
  right: 5px;
}
.help-btn-group .item-settings-dropdown {
  float: right;
}
.help-btn-group .item-settings-dropdown a:nth-of-type(1) {
  padding: 6px 12px !important;
}
.help-btn-group .item-settings-dropdown a.gt-head-filter {
  display: none;
}
.help-btn-group .item-settings-dropdown .dropdown-menu .btn,
.help-btn-group .item-settings-dropdown .dropdown-menu a {
  text-align: left !important;
  float: left;
  width: 100%;
}
.page-header-main:hover .help-btn-group a {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  border-radius: 4px;
  padding: 6px 12px;
}
.page-header-main:hover .help-btn-group a span {
  display: inline-block;
}
.page-header-main:hover .payment-details-container {
  display: block;
}
.container-btn-group {
  bottom: -4px;
}
.container-btn-group .btn {
  padding: 6px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  border-top-left-radius: 0.25em !important;
  border-top-right-radius: 0.25em !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none !important;
  font-size: 14px;
}
.container-btn-group .btn::first-letter {
  text-transform: uppercase !important;
}
.container-btn-group .btn-primary {
  box-shadow: none !important;
}
.button-group-container .btn {
  margin-bottom: 2px;
  width: 100%;
  display: block;
}
.well {
  background: rgba(0, 0, 0, 0.05);
  color: #666b72 !important;
  border-radius: 8px;
  border: transparent;
  word-break: break-word;
  box-shadow: none;
}
.well .container-btn-group .btn-primary {
  background: #d4d4c5 !important;
}
.if-navbar-left .help-btn-group {
  top: 10px;
}
.if-navbar-left .alert-applied-filters {
  left: 45px;
}
.if-navbar-left .passports-table tfoot > tr > td:first-child,
.if-navbar-left .passports-table tbody > tr > td:first-child,
.if-navbar-left .passports-table thead > tr > th:first-child {
  left: 62px;
}
.if-navbar-left .create-contract-page .gt-sidebar.col-xs-1 {
  border-right: 1px solid #fff;
  background: #fff;
}
.if-navbar-left .create-contract-page .gt-sidebar.col-xs-1 .toggle-button .fa-bars {
  color: #000000;
}
.if-navbar-left .create-contract-page .gt-sidebar.col-xs-2 {
  background: #fff;
}
.if-navbar-left .create-contract-page .gt-sidebar.col-xs-2 .scrollable-sidebar {
  background: #fff;
}
.if-navbar-left .create-contract-page .gt-sidebar.col-xs-2 .start-page-header {
  color: #000000;
}
.if-navbar-left .create-contract-page .gt-sidebar.col-xs-2 .opened-sidebar {
  background: #fff;
}
.if-navbar-left .toggle-button .fa-bars {
  color: #000000;
}
.if-navbar-left .toggle-button .fa-filter {
  color: #000000;
}
.if-navbar-left .toggle-button.btn-default {
  background: #d2cece;
  color: #000000;
}
.if-navbar-left .sidebar-wrapper .search-wrapper {
  width: 90% !important;
}
.if-navbar-left .sidebar-wrapper .search-wrapper .search-container {
  border: 1px solid #d2cece !important;
}
.if-navbar-left .sidebar-wrapper .search-wrapper .search-container input {
  color: #d2cece !important;
}
.if-navbar-left .sidebar-wrapper .search-wrapper .search-container input::-webkit-input-placeholder {
  color: #d2cece !important;
}
.if-navbar-left .sidebar-wrapper .pagination > li > a,
.if-navbar-left .sidebar-wrapper .pagination > li > span {
  color: #000000 !important;
}
.if-navbar-left .sidebar-wrapper .pagination > li.active > a {
  background: #beb9b9 !important;
  color: #fff !important;
}
.if-navbar-left .gt-table-sidebar tr td {
  border-bottom: 1px solid #e8e6e6 !important;
}
.if-navbar-left .gt-table-sidebar-contracts tr.contract_executed > td:first-child {
  border-left: 5px solid rgba(120, 123, 132, 0.2);
  color: #000000 !important;
}
.if-navbar-left .gt-table-sidebar-contracts tr.contract_calculate > td:first-child {
  border-left: 5px solid #6d6a6f;
  color: #a495b0 !important;
}
.if-navbar-left .gt-table-sidebar-contracts tr.contract_new > td:first-child {
  border-left: 5px solid #0c4d40;
  color: #0c4d40 !important;
}
.if-navbar-left .gt-table-sidebar-contracts tr.contract_processing > td:first-child {
  border-left: 5px solid #685438;
  color: #685438 !important;
}
.if-navbar-left .gt-table-sidebar-contracts tr.contract_cancelled > td:first-child {
  border-left: 5px solid rgba(120, 123, 132, 0.2);
  color: #7f8c8d !important;
  text-decoration: line-through;
}
.btn-group {
  table-layout: auto;
}
.btn-group .btn {
  overflow-x: hidden;
}
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: inherit;
}
.column-config-table {
  width: 100% !important;
}
.column-config-table tr td {
  vertical-align: middle !important;
  font-size: calc(14px / 1.2) !important;
  border-bottom: 1px solid #e8e6e6 !important;
  border-top: 0 !important;
  line-height: 1 !important;
  padding: 2px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.column-config-table tr td input[type='checkbox'],
.column-config-table tr td input[type='radio'] {
  width: 20px !important;
  height: 20px !important;
}
.column-config-table tr td .form-control {
  line-height: 1;
  font-size: calc(14px / 1.2) !important;
  border: 0 !important;
  margin: 0;
  min-height: auto !important;
  min-width: 0 !important;
  background: #f9f9f7;
  font-family: 'Regular', sans-serif !important;
}
.column-config-table tr td .form-control:focus,
.column-config-table tr td textarea.form-control:focus {
  border: 0 !important;
}
.column-config-table tr td .btn {
  line-height: 1;
  padding: 2px;
  font-size: calc(14px / 1.3) !important;
  margin: 0;
}
.column-config-table tr td .ui-select-match-item.btn {
  font-family: 'Regular', sans-serif !important;
}
.column-config-table tr td .fa-arrows {
  visibility: hidden;
}
.column-config-table tr > td:first-child {
  font-family: 'Regular', sans-serif !important;
}
.column-config-table tr > td:first-child span {
  font-family: 'Regular', sans-serif !important;
}
.column-config-table tr:hover td:first-child .fa-arrows {
  visibility: visible;
}
.column-config-table .ui-select-multiple .btn {
  display: flex !important;
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  width: 51%;
}
.column-config-table .ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
  margin-right: 10px;
  font-size: 14px !important;
}
.column-config-table .ui-select-match,
.column-config-table .ui-select-container {
  max-width: 300px;
}
.column-config-table .ui-select-multiple .close {
  float: none !important;
}
.column-config-table .form-control {
  height: auto;
}
.column-config-table .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  float: left;
}
.tooltip-link {
  cursor: pointer;
}
.tooltip-modal {
  position: relative;
  width: 0px;
  left: -5px;
  top: 8px;
}
.tooltip-modal .fa-question-circle {
  font-size: calc(14px / 1.3) !important;
  text-align: right;
  float: right;
  color: #0075ff !important;
}
.tooltip-modal::after {
  border-radius: 8px 8px 8px 0px;
  box-shadow: none;
  color: #fff;
  content: '';
  background: transparent;
  opacity: 0;
  padding: 12px;
  position: absolute;
  visibility: hidden;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: inherit !important;
  line-height: 1.4;
  max-width: 500px;
  min-width: 300px !important;
}
.tooltip-modal:hover::after {
  background: #34495e;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  content: attr(data-tip);
  margin-top: -45px;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.tooltip-centered {
  position: relative;
  margin-left: 10px;
}
.tooltip-centered::after {
  border-radius: 8px 8px 8px 0px;
  box-shadow: none;
  color: #fff;
  content: '';
  background: transparent;
  opacity: 0;
  padding: 12px;
  position: absolute;
  visibility: hidden;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: inherit !important;
  line-height: 1.4;
  max-width: 500px;
  min-width: 300px !important;
}
.tooltip-centered:hover::after {
  background: #34495e;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  content: attr(data-tip);
  margin-top: -45px;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.tooltip {
  position: relative;
}
.tooltip::before {
  content: attr(data-tip);
  width: 1px;
  height: 1px;
  position: absolute;
  display: none;
  z-index: 9999 !important;
  opacity: 0;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.tooltip:hover::before {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  z-index: 9999 !important;
  width: auto !important;
  word-wrap: break-word !important;
  max-width: 400px;
  white-space: normal !important;
  word-break: normal;
  display: inline;
  position: absolute;
  left: 0;
  top: 35px;
  text-transform: inherit !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
  font-weight: 400 !important;
  opacity: 1;
  min-width: max-content;
  border-radius: 4px !important;
  padding: 8px 8px;
  height: auto;
  line-height: 1;
  background: #34495e;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  color: #fff;
}
.label[href]:focus,
.label[href]:hover {
  color: #000000;
}
.badge {
  font-size: 0.8em;
  color: #000000;
  -webkit-text-stroke: 0;
  padding: 4px !important;
  font-family: 'Regular', sans-serif !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.badge::first-letter {
  text-transform: uppercase !important;
}
.label {
  color: #000000;
  padding: 4px !important;
  -webkit-text-stroke: 0;
  font-family: 'Regular', sans-serif !important;
  overflow: hidden;
  border-radius: 4px !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.label::first-letter {
  text-transform: uppercase !important;
}
.label-bold {
  font-family: 'Bold', sans-serif !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.secondary-text {
  color: #666b72 !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  white-space: normal;
  word-break: break-all;
}
.contract-small-label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.bigger-label {
  font-family: 'Regular', sans-serif !important;
}
.label-strong {
  font-family: 'Semibold', sans-serif !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  -webkit-text-stroke: 0;
}
.smaller-label {
  font-size: calc(14px / 1.3) !important;
  text-transform: initial !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.header-badge {
  margin-left: 2px;
  line-height: 1;
  padding: 2px !important;
  vertical-align: text-bottom;
  font-size: calc(14px / 1.3) !important;
  font-family: 'Semibold', sans-serif !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.label-danger-stamp {
  color: #cf6163 !important;
  border: 2px dashed #cf6163 !important;
  padding: 6px 8px !important;
  text-transform: uppercase;
  display: inline-block;
}
.label-calculate-stamp {
  color: #cf6163 !important;
  border: 2px dashed #cf6163 !important;
  padding: 6px 8px !important;
  text-transform: uppercase;
  display: inline-block;
  color: #6d6a6f !important;
  border: 2px dashed #6d6a6f !important;
}
.label-default-stamp {
  padding: 6px 8px !important;
  text-transform: uppercase;
  display: inline-block;
  color: #cf6163 !important;
  border: 2px dashed #cf6163 !important;
}
.link-to-passport {
  font-family: 'Semibold', sans-serif !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  cursor: pointer;
}
.link-to-passport .label {
  float: none !important;
}
.label-date {
  border: 1px solid #e8e6e6;
  border-radius: 4px !important;
  padding: 0;
  display: inline-block;
}
.label-date .label.month {
  font-size: calc(14px / 1.3) !important;
  text-transform: uppercase;
  width: 100%;
  background: rgba(0, 0, 0, 0.01);
  padding: 2px !important;
  text-align: center !important;
  display: block;
  border-radius: 0 !important;
  margin-left: 0;
}
.label-date .day {
  font-size: calc(14px / 1.3);
  text-align: center !important;
  display: block;
  font-family: 'Semibold', sans-serif !important;
  padding: 0 !important;
  background: transparent !important;
}
.label-date .day.label_danger {
  background: #cf6163 !important;
}
.opacity-el {
  opacity: 0.5;
}
.additional_label {
  background: #e5e5dd !important;
  background-color: #e5e5dd !important;
  color: #000000 !important;
  font-size: 14px !important;
  padding: 2px;
  border-radius: 4px !important;
}
.updated_time {
  font-family: 'Regular', sans-serif !important;
  text-transform: initial;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.contract_details {
  font-family: 'Regular', sans-serif !important;
}
.passport-label {
  margin-left: 2px !important;
  display: inline-flex !important;
  margin-top: 0;
}
.passport-label .btn-link {
  font-family: 'Semibold', sans-serif !important;
}
.passport-label .fa {
  margin-right: 2px;
}
.currency_exchange_label {
  padding: 2px !important;
  font-size: calc(14px / 1.4) !important;
}
.currency_exchange_label a,
.currency_exchange_label span {
  font-size: calc(14px / 1.4) !important;
}
.label-none {
  display: none;
}
.label_start {
  font-family: 'Regular', sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding-top: 1px;
  padding-bottom: 1px;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px !important;
}
.documents-date-label {
  color: #bdc3c7;
  font-weight: 500 !important;
}
.label-opacity {
  opacity: 0.6;
  box-shadow: none !important;
  border-color: transparent !important;
  font-size: calc(14px / 1.3) !important;
  background: transparent !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.label-smoke {
  color: #666b72 !important;
}
.label-smoke.smaller-label {
  font-family: 'Regular', sans-serif !important;
  -webkit-text-stroke: 0;
}
.label-invisible {
  color: transparent !important;
  background: transparent !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.label-dashed {
  opacity: 0.3;
  color: #000000;
  box-shadow: none !important;
  font-weight: normal !important;
  border-color: transparent !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.label_cancelled {
  background: rgba(119, 119, 119, 0.2);
  color: #777777 !important;
}
.label-inactive {
  background: rgba(119, 119, 119, 0.2) !important;
  opacity: 0.4;
  color: #777777 !important;
}
.label_brown {
  background-color: rgba(181, 146, 96, 0.4) !important;
  color: #685438 !important;
}
.label_brown a {
  color: #685438 !important;
}
.label_grey {
  background-color: rgba(119, 119, 119, 0.2) !important;
  color: #595b63 !important;
}
.label_grey a {
  color: #595b63 !important;
}
.label_success {
  background-color: rgba(28, 179, 148, 0.4) !important;
  color: #07422d !important;
}
.label_success a {
  color: #07422d !important;
}
.label_calculate {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
.label_calculate a {
  color: #6d6a6f !important;
}
.label_warning {
  background-color: rgba(181, 146, 96, 0.4) !important;
  color: #685438 !important;
}
.label_warning a {
  color: #685438 !important;
}
.label_info {
  background-color: rgba(212, 232, 240, 0.7) !important;
  color: #1d82c7 !important;
}
.label_info a {
  color: #1d82c7 !important;
}
.label_default {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
.label_default a {
  color: #595b63 !important;
}
.label_default a.btn-primary {
  color: #595b63 !important;
}
.label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.label_danger a {
  color: #fff !important;
}
.label_danger span {
  color: #fff !important;
}
.negative-number {
  color: #cf6163 !important;
  -webkit-text-stroke: 0;
}
.positive-number {
  color: #07422d !important;
  -webkit-text-stroke: 0;
}
.alert {
  border-radius: 6px !important;
  border: 0 !important;
}
.alert-danger-dashed,
.ul-inline-row.alert-danger-dashed,
.table-container-wrapper.alert-danger-dashed {
  border-bottom: 2px dashed #d2cece !important;
}
.alert-danger-dashed .border-left,
.ul-inline-row.alert-danger-dashed .border-left,
.table-container-wrapper.alert-danger-dashed .border-left {
  border-left: 6px solid rgba(207, 97, 99, 0.7) !important;
  margin-left: -15px;
  padding-left: 15px;
  padding-top: 5px !important;
  text-align: left;
}
.alert-danger-dashed .border-left-default,
.ul-inline-row.alert-danger-dashed .border-left-default,
.table-container-wrapper.alert-danger-dashed .border-left-default {
  border-left: 6px solid rgba(207, 97, 99, 0.7) !important;
  margin-left: -15px;
  padding-left: 15px;
  padding-top: 5px !important;
  text-align: left;
  border-left: 6px solid rgba(119, 119, 119, 0.1) !important;
}
.alert-danger-dashed .border-left-thin,
.ul-inline-row.alert-danger-dashed .border-left-thin,
.table-container-wrapper.alert-danger-dashed .border-left-thin {
  border-left: 1px dashed #d2cece !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-align: left;
}
.alert-applied-filters {
  position: fixed;
  bottom: 0;
  margin-bottom: 0;
  left: 0;
  right: 0;
  max-height: 60px;
  overflow: auto;
  z-index: 999;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0 !important;
}
.alert-applied-filters .btn {
  margin-left: 5px;
  margin-right: 5px;
  font-size: calc(14px / 1.3);
  line-height: 2;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.alert-applied-filters .label {
  display: inline;
  margin-right: 4px;
  font-size: calc(14px / 1.2);
  line-height: 1;
  padding: 6px !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.alert-applied-filters h5 {
  padding: 0;
  margin: 0;
  line-height: 2;
  font-size: 14px;
}
.dropdown-menu {
  border-radius: 4px !important;
  border: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: #fdfdfd !important;
  color: #91795c;
  box-shadow: 0px 0px 40px #666b72 !important;
}
.dropdown-menu .btn {
  border-radius: 0px !important;
}
.dropdown-menu a:hover,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #666;
  background-color: #c6c7cb !important;
}
.dropdown-menu > li > a {
  color: #91795c;
  text-align: left !important;
}
.daterangepicker.dropdown-menu {
  color: #000000;
  z-index: 9999;
}
.dropdown-menu > li > a:hover {
  color: #666;
  background-color: #c6c7cb;
  border-radius: 0;
}
.bootstrap-datetimepicker-widget th {
  color: #c6c7cb;
}
.bootstrap-datetimepicker-widget td {
  color: #666 !important;
}
.bootstrap-datetimepicker-widget td span {
  background: transparent !important;
}
.bootstrap-datetimepicker-widget .btn-primary {
  border: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
}
.bootstrap-datetimepicker-widget td.active,
.bootstrap-datetimepicker-widget td.active:hover {
  background: #c6c7cb !important;
}
.bootstrap-datetimepicker-widget td.day:hover,
.bootstrap-datetimepicker-widget td.hour:hover,
.bootstrap-datetimepicker-widget td.minute:hover,
.bootstrap-datetimepicker-widget td.second:hover {
  color: #666 !important;
}
input::-webkit-calendar-picker-indicator {
  display: none;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.control-label {
  color: #000000;
  font-weight: normal;
  text-align: left !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-top: 10px;
}
.ui-select-match-item.btn {
  font-family: 'Semibold', sans-serif !important;
}
.input-group.input-group-select .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 10px;
  width: 2%;
  float: left;
}
.input-group.input-group-select .input-group-search-outside {
  padding-left: 2px;
  padding-right: 2px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group.input-group-select .form-control:first-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0;
  display: block;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.select-with-btn {
  width: 85%;
  float: left;
}
.select-with-btn .input-group.input-group-select .form-control:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0 !important;
}
.text-muted {
  text-transform: none !important;
  color: #000000 !important;
  font-size: 14px !important;
  line-height: 1 !important;
  vertical-align: bottom;
}
.form-control .clear-btn,
.input-group .clear-btn {
  display: none;
}
.form-control:hover .clear-btn,
.input-group:hover .clear-btn {
  display: inline-block;
}
.form-control {
  font-family: 'Regular', sans-serif !important;
  box-shadow: none;
  background: transparent;
  border-radius: 4px !important;
  border: 1px solid #beb9b9 !important;
  color: #000000 !important;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100% !important;
  margin-top: 0;
  padding: 0;
  padding-left: 4px;
  padding-right: 4px;
}
.form-control option {
  font-family: 'Regular', sans-serif !important;
}
.form-control .ui-select-match-text {
  font-family: 'Regular', sans-serif !important;
  word-break: keep-all;
  padding-right: 0;
}
.form-control .ui-select-match-text .clear-btn {
  display: none;
}
.form-control .ui-select-match-text:hover .clear-btn {
  display: inline;
  margin: 0;
  padding: 0;
}
.form-control .ui-select-match-text::-webkit-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000 !important;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.form-control .ui-select-match-text::-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000 !important;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.form-control .ui-select-match-text:-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000 !important;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.form-control .ui-select-match-text:-ms-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000 !important;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.form-control::-webkit-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000 !important;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.form-control::-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000 !important;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.form-control:-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000 !important;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.form-control:-ms-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000 !important;
  font-size: 14px;
  text-overflow: ellipsis;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.form-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.form-control:focus::-moz-placeholder {
  color: transparent;
}
.form-control:focus:-moz-placeholder {
  color: transparent;
}
.form-control:focus:-ms-input-placeholder {
  color: transparent;
}
.has-error .form-control {
  box-shadow: none !important;
}
.has-error .form-control,
.ng-invalid {
  border-color: #cf6163 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group .has-error.form-control:first-child,
.input-group .ng-invalid-required.form-control:first-child {
  border-color: #cf6163 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.form-group-container .form-group.not-empty .col-md-7 {
  min-height: 20px;
  height: auto;
  background: transparent !important;
  border: 1px solid transparent !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #e1dede !important;
}
.not-empty .form-group {
  background: #fff !important;
}
.not-empty .object-title {
  text-align: left;
  font-family: 'Bold', sans-serif !important;
  color: #000000;
}
.not-empty .input-group-addon {
  color: #000000 !important;
}
.not-empty .form-control {
  color: #000000 !important;
}
.not-empty .fa-remove {
  color: #cf6163 !important;
}
.not-empty .add-btn {
  color: #000000 !important;
}
.not-empty .form-add-btn {
  display: none;
}
.not-empty .select-with-btn {
  width: 100% !important;
}
.not-empty .btn-default {
  color: #000000 !important;
}
.not-empty .gt-date-select .input-group-addon {
  display: none;
}
.has-error .form-group {
  background: rgba(207, 97, 99, 0.13) !important;
}
.ui-select-bootstrap .ui-select-toggle > a.btn {
  position: absolute;
  height: 10px;
  right: -5px;
  margin-top: 0;
  text-decoration: none !important;
  border: 0 !important;
}
.ui-select-bootstrap > .ui-select-match > .btn {
  margin-top: 0;
  font-family: 'Regular', sans-serif !important;
  text-transform: initial;
  padding: 2px;
  border-radius: 4px !important;
  overflow: visible;
  white-space: normal;
  word-break: keep-all;
  height: auto;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.ui-select-bootstrap > .ui-select-choices {
  width: auto !important;
  background: #eeedeb url(img/top-bg-white.png);
}
.ui-select-choices-row {
  text-align: left;
  font-size: calc(14px / 1.2);
  overflow-y: hidden !important;
}
.ui-select-bootstrap .ui-select-choices-row.active > span {
  background: #eeeee8;
  color: #666;
  font-family: 'Semibold', sans-serif !important;
}
.form-control:focus,
textarea.form-control:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  color: #666;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid #666;
}
.form-group {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
  margin-bottom: 0px !important;
  padding-right: 15px;
  padding-left: 15px;
}
.form-group .start-page-header,
.form-group .pull-left {
  text-align: left !important;
}
.form-group .btn-default {
  border-bottom: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;
  background: #ebebeb !important;
}
.formly-full-width {
  width: 100%;
  clear: both;
  display: block;
}
.formly-half-width {
  display: inline;
  width: 49.5%;
  float: left;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] {
  background-color: rgba(0, 0, 0, 0.01) !important;
  border-color: transparent !important;
  border-radius: 0 !important;
}
.form-control[disabled]::-webkit-input-placeholder,
.form-control[readonly]::-webkit-input-placeholder,
fieldset[disabled]::-webkit-input-placeholder {
  color: #000000;
}
.form-control[disabled]::-moz-placeholder,
.form-control[readonly]::-moz-placeholder,
fieldset[disabled]::-moz-placeholder {
  color: #000000;
}
.form-control[disabled]:-moz-placeholder,
.form-control[readonly]:-moz-placeholder,
fieldset[disabled]:-moz-placeholder {
  color: #000000;
}
.form-control[disabled]:-ms-input-placeholder,
.form-control[readonly]:-ms-input-placeholder,
fieldset[disabled]:-ms-input-placeholder {
  color: #000000;
}
.similar-fields {
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
  text-align: left;
}
.similar-fields ul {
  width: 100%;
  padding: 6px;
}
.similar-fields li {
  font-size: calc(14px / 1.3);
  color: #000000;
  text-align: left;
  display: inline-block;
  margin-right: 10px;
}
.similar-fields .label {
  display: inline-flex !important;
}
.ui-select-match[disabled='disabled'] {
  cursor: not-allowed !important;
}
.ui-select-match[disabled='disabled'] .btn-default {
  background-color: #f9f9f7 !important;
  color: #000000 !important;
}
.ui-select-match[disabled='disabled'] .btn-default .text-muted {
  color: #000000 !important;
}
.label-contract-form {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}
.table-container-wrapper {
  background: #fff;
  border-radius: 8px;
  padding: 20px !important;
  box-shadow: none !important;
  border: 0 !important;
}
.table-container-wrapper table tr th {
  border: 0 !important;
  border-right: 1px solid #dcd9d9 !important;
  background: #f7f7f7 !important;
}
.table-container-wrapper table tr th.alert-danger {
  background: rgba(207, 97, 99, 0.13) !important;
}
.table-container-wrapper table tr th.alert-success {
  background: #ebf1ef !important;
}
.table-container-wrapper table tr th.alert-info {
  background: rgba(0, 117, 255, 0.19) !important;
}
.table-container-wrapper table tr th.alert-warning {
  color: #a5a293 !important;
  background: #fcf8e3 !important;
}
.table-container-wrapper .table-container-wrapper {
  padding-top: 0 !important;
  box-shadow: none !important;
}
.overflow-x::-webkit-scrollbar,
.ul-inline-row::-webkit-scrollbar,
.table-container-wrapper::-webkit-scrollbar {
  height: 10px;
}
.ul-inline-row {
  background: #fff;
  border-radius: 8px;
  padding: 20px !important;
  box-shadow: none !important;
  margin-bottom: 2px !important;
  border: 0 !important;
}
.ul-inline-row .table-container-wrapper,
.ul-inline-row .ul-inline-row,
.ul-inline-row .well {
  box-shadow: none !important;
}
.filters-well,
.analytics-container.filters-well {
  background: transparent !important;
  box-shadow: none !important;
  border: 3px dashed rgba(0, 0, 0, 0.08);
}
.filters-well .ul-inline-row,
.analytics-container.filters-well .ul-inline-row,
.filters-well .analytics-container,
.analytics-container.filters-well .analytics-container,
.filters-well .table-container-wrapper,
.analytics-container.filters-well .table-container-wrapper,
.filters-well .well,
.analytics-container.filters-well .well {
  box-shadow: none !important;
}
.filters-well .form-control,
.analytics-container.filters-well .form-control {
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #b7b1b1 !important;
}
.filters-well .label,
.analytics-container.filters-well .label {
  white-space: normal;
}
.overflow-x {
  overflow-x: auto;
  max-height: 100%;
  max-width: 100%;
  overflow-y: hidden;
}
.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 700px;
  max-width: 100%;
}
.overflow-y-small {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 700px;
  max-width: 100%;
  max-height: 350px;
}
.overflow-x-with-margin {
  overflow-x: auto;
  max-height: 100%;
  max-width: 100%;
  overflow-y: hidden;
  padding-bottom: 250px;
  margin-bottom: -250px;
}
.no-overflow-container .overflow-x {
  overflow: visible !important;
}
.default-overflow {
  overflow-x: visible;
  overflow-y: visible;
}
.li-inline-row {
  display: block;
  border: 1px solid #e5e5dd;
  height: 50px;
  border-radius: 4px !important;
  margin-bottom: 4px;
  padding: 2px;
}
.li-inline-row .contract_details {
  font-size: 14px;
  margin-bottom: 2px;
}
.li-inline-row div {
  padding-left: 2px !important;
  padding-right: 5px !important;
}
select {
  font-size: 0.9em;
  color: #666b72;
  margin-top: 2px;
  border: 0;
  outline: 0;
  cursor: pointer;
  border-bottom: 0 !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}
select option {
  color: #666b72 !important;
  border: 0 !important;
  outline: 0;
  box-shadow: none;
}
select option:hover {
  background: #dcdcdc !important;
}
select[multiple],
select[size] {
  border-radius: 4px !important;
}
select[multiple] option,
select[size] option {
  border-bottom: 1px solid #ddddd1 !important;
}
select::before {
  font-family: 'FontAwesome' !important;
  content: ' \f142 ' !important;
}
input[type='checkbox'],
input[type='radio'] {
  margin: 0;
  width: 20px;
  height: 20px;
  text-align: left !important;
  float: left;
  margin-right: 5px;
}
.buyers-multiselect .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  background: transparent !important;
}
.buyers-multiselect .ng-not-empty.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  border: 1px solid #beb9b9 !important;
}
.checkbox-logistics {
  width: 15px !important;
  height: 15px !important;
  margin: 0 !important;
  float: none !important;
}
input[type='date'] {
  border-radius: 4px !important;
  border: 1px solid #e5e5dd !important;
}
input[type='date']::before {
  font-family: 'FontAwesome';
  content: ' \f073 ';
  margin-right: 0.3em;
}
input[type='email']::before {
  font-family: 'FontAwesome';
  content: ' \f0e0 ';
  margin-right: 0.3em;
}
input[type='tel']::before {
  font-family: 'FontAwesome';
  content: ' \f098 ';
  margin-right: 0.3em;
}
input[type='url']::before {
  font-family: 'FontAwesome';
  content: ' \f0c1 ';
  margin-right: 0.3em;
}
.selectpicker.btn-default {
  background: #e5e5dd !important;
}
.modal-content .selectpicker.btn-default {
  background: #e5e5dd !important;
}
.input-group-addon {
  padding: 6px;
  border: 0 !important;
  background: transparent;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  font-size: calc(14px / 1.3);
}
/*PAGINATION*/
.pagination {
  margin: 5px;
  margin-top: 50px;
}
.pagination nav ul li:hover {
  cursor: pointer !important;
}
.pagination > li > a,
.pagination > li > span {
  padding: 2px 8px;
  border: 0;
  background: transparent !important;
  color: #666b72;
  font-size: calc(14px / 1.2) !important;
}
.pagination > li > a :hover,
.pagination > li > span :hover {
  cursor: pointer;
}
.pagination-select {
  display: inline-block;
  width: 200px;
  margin: 0 auto;
}
.input-block-level form-control,
.bootstrap-select-searchbox input {
  border-bottom: 1px solid #030304 !important;
  color: #000000 !important;
}
input::-webkit-input-placeholder {
  font-size: 14px;
  padding-top: 2px;
}
input:-moz-placeholder {
  font-size: 14px;
  padding-top: 2px;
}
.btn-default .caret {
  border-top-color: #e8e6e6 !important;
  display: none;
}
.note {
  font-size: 14px;
  text-align: right;
  color: #000000 !important;
}
#id_current_progress,
#id_response,
#id_commentary,
#id_additional_info,
#id_confirmation_info,
#id_logistics_info,
#id_history {
  height: 70px;
}
#id_title {
  background: #e5e5dd;
  border: 0;
  outline: 0;
  border-radius: 4px !important;
  color: #000000;
}
#id_title:focus {
  color: #000000;
}
.login-header {
  position: absolute;
  right: 0;
  left: 0;
  height: 60%;
  background: #34495e;
  box-shadow: inset 0 2px 10px #000;
  top: 0;
  z-index: 0;
}
.form-login {
  width: 100%;
  margin: 0 auto;
}
.form-login .input-group {
  margin-bottom: 3px;
}
.form-login .form-control {
  border: 0 !important;
  border-bottom: 2px solid #ededed !important;
  font-family: 'Semibold', sans-serif !important;
}
.form-login .form-control:focus {
  border: 0 !important;
  border-bottom: 3px solid #ededed !important;
}
.form-login .input-group-addon {
  padding: 2px 15px;
  color: #fcc944;
  background: #34495e;
}
#Terms {
  margin-left: 0px;
}
.stage-form .ul-inline-row {
  padding-top: 10px;
  padding-bottom: 10px;
}
.stage-form .form-group {
  margin-bottom: 5px !important;
}
.stage-form .form-control[disabled] {
  border-radius: 4px !important;
}
.stage-form input[type='checkbox'],
.stage-form input[type='radio'] {
  text-align: left;
  float: left;
  width: 20px !important;
}
.small-workflow {
  padding: 0 !important;
  margin: 0 !important;
}
.small-workflow li {
  width: 2px;
  height: 4px;
}
.small-workflow li .fa {
  font-size: calc(14px / 1.3);
}
.page-info-container {
  border-radius: 0.65em;
  border: 1px dashed #d2cece !important;
  padding: 30px;
}
.page-info-container h4,
.page-info-container h5 {
  margin-bottom: 15px;
}
.page-info-container small {
  font-family: 'Regular', sans-serif !important;
}
.page-info-container .btn_default {
  background: rgba(0, 0, 0, 0.08) !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px !important;
}
.page-info-container .btn_link {
  border-bottom: 1px dashed #d2cece !important;
}
.page-info-container .btn-round {
  border-radius: 100% !important;
  height: 30px !important;
  width: 30px !important;
  line-height: 2;
}
.page-info-container .search-container {
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
}
.page-info-container .well {
  margin: 0;
  overflow-x: auto;
}
.page-info-container .well .inside-well-ul {
  margin: 0;
}
.page-info-container .well .inside-well-ul li {
  background: rgba(0, 0, 0, 0.08);
  text-align: left;
  padding: 2px;
  font-size: calc(14px / 1.3);
  font-family: 'Semibold', sans-serif !important;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
  border-radius: 4px !important;
  margin-bottom: 3px;
}
.page-info-container .well .inside-well-ul li:last-of-type {
  border-bottom: none !important;
}
.page-info-container .well .inside-well-table {
  margin: 0;
  border-radius: 0.65em;
}
.page-info-container .well .inside-well-table tr td {
  padding: 2px;
  font-size: calc(14px / 1.3) !important;
  font-family: 'Semibold', sans-serif !important;
  border: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
  white-space: normal;
  word-break: keep-all;
}
.page-info-container .well .inside-well-table tr td .nav-tabs-cut li > a {
  font-family: 'Regular', sans-serif !important;
  font-size: calc(14px / 1.3);
}
.page-info-container .well .inside-well-table tr:last-of-type td {
  border-bottom: none !important;
}
.page-info-container .well .inside-well-table tr td:first-child {
  white-space: nowrap;
}
.page-info-container .well .inside-well-table td.smaller-font-td {
  font-size: calc(14px / 1.3) !important;
}
.start-page-header {
  margin-top: 10px !important;
  padding-bottom: 10px !important;
  font-family: 'Bold', sans-serif !important;
  -webkit-text-stroke: 0;
}
.start-page-header::first-letter {
  text-transform: uppercase !important;
}
.start-page-header small {
  font-size: 65% !important;
  font-family: 'Light', sans-serif !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.dashboard-object-container {
  display: inline-block;
}
.dashboard-object-container .ul-inline-row {
  padding: 20px !important;
}
.dashboard-object-container .dashboard-value {
  text-align: center;
}
.dashboard-object-container .dashboard-value h1 {
  font-family: 'Bold', sans-serif !important;
  text-align: center;
  padding: 0 !important;
  margin: 0;
}
.dashboard-object-container .dashboard-value h3 {
  font-family: 'Bold', sans-serif !important;
  text-align: center;
  padding: 0 !important;
  margin: 0;
  font-size: calc(14px * 1.2);
}
.dashboard-object-container .col-md-4 {
  padding-left: 0;
  padding-right: 5px;
}
.dashboard-object-container .dashboard-label {
  text-align: center;
}
.dashboard-object-container .dashboard-label span {
  font-size: 14px;
  font-family: 'Regular', sans-serif !important;
  text-align: center;
  color: #9e9e9e;
  text-transform: lowercase;
}
.dashboard-start-container h5 {
  text-align: center;
  float: left;
  display: block;
  padding: 6px;
  clear: both;
}
.dashboard-start-container .pagination {
  margin-top: 0;
  margin-bottom: 0;
}
.dashboard-start-container .pagination li a {
  padding: 2px 12px;
}
.dashboard-start-container .ui-grid-canvas {
  height: 170px;
}
.dashboard-start-container .ui-grid-render-container {
  height: 170px;
  border: 0 !important;
}
.dashboard-start-container .ui-grid-cell-contents,
.dashboard-start-container .ui-grid-cell .tooltip,
.dashboard-start-container .ui-grid-cell,
.dashboard-start-container .ui-grid-cell .label {
  font-size: calc(14px / 1.2) !important;
  vertical-align: top !important;
  line-height: 1.1 !important;
  color: #000000;
}
.dashboard-start-container .ui-grid-cell,
.dashboard-start-container .ui-grid-row {
  height: 15px !important;
  background: transparent !important;
}
.dashboard-start-container .ui-grid-cell img,
.dashboard-start-container .ui-grid-cell .no-user-avatar {
  margin-top: 2px;
  font-size: calc(14px / 1.3) !important;
  height: 12px;
}
.dashboard-start-container .ui-grid-row:nth-child(odd) .ui-grid-cell,
.dashboard-start-container .ui-grid-row:nth-child(even) .ui-grid-cell,
.dashboard-start-container .ui-grid-header-cell {
  background: transparent !important;
}
.dashboard-start-container-inner {
  padding-right: 0px;
  padding-left: 10px;
}
.dashboard-start-container-inner .dashboard-start-container-block {
  margin-bottom: 10px;
  border-radius: 8px;
  padding-left: 10px;
}
.dashboard-start-container-inner #update-properties-table tr td {
  font-size: calc(14px / 1.4) !important;
  padding: 2px !important;
}
.dashboard-start-container-inner #update-properties-table tr td .updated_time {
  font-size: calc(14px / 1.3) !important;
}
.dashboard-start-container-inner #update-properties-table tr td.message {
  font-size: calc(14px / 1.3) !important;
}
.start .label_start {
  float: right;
}
.start .start-block-container {
  padding: 5px;
}
.start .start-block-row {
  border-radius: 8px;
  font-family: 'Bold', sans-serif !important;
}
.start .start-block-row a {
  display: inline-block;
  margin-right: 10px;
}
.start .start-block-row h5.start-page-header {
  font-size: calc(14px / 1.3) !important;
}
.start .start-block-container:last-child {
  border-right: 0;
}
.start h4 {
  color: #ffffff;
  text-align: center;
}
.start-block h5 {
  display: block;
}
.start-container {
  height: auto;
  background: #fff;
  border-radius: 4px !important;
  font-family: 'Regular', sans-serif !important;
  margin-right: 22px;
  margin-bottom: 5px;
  max-width: 40px !important;
  min-width: 40px;
  white-space: nowrap;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
a:hover,
a:focus {
  transform: scale(1.05);
  transition: transform 0.2s;
}
.start-block:hover .start-title {
  transform: scale(1.05);
  transition: transform 0.2s;
}
.start-img {
  width: 100%;
  height: 20px;
  background: #fff;
  font-size: 15px;
  color: #9e9e9e;
  text-align: right;
  padding-right: 5px;
}
.start-img:hover {
  color: #9e9e9e !important;
}
.start-img i:hover {
  color: #9e9e9e !important;
}
.start-title {
  background: #fff;
  font-size: calc(14px / 1.4);
  color: #34495e !important;
  text-align: left !important;
  width: 100%;
  border-radius: 8px !important;
  padding: 10px;
  overflow: hidden;
  text-transform: uppercase;
  box-shadow: 0 1px 4px rgba(8, 40, 100, 0.15) !important;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.start-title::first-letter {
  text-transform: uppercase !important;
}
.start-title .fa {
  font-size: calc(14px * 2);
  display: block;
  margin-bottom: 5px;
}
.start-title .label-start {
  color: #8f96a0 !important;
  margin-left: 10px;
  float: right;
  display: none;
}
.start-description {
  font-size: 0.7em;
  color: #7a8089 !important;
  text-align: center !important;
  overflow: hidden;
  background: #fff;
}
.start-contacts p {
  font-size: 0.7em;
  color: #666b72 !important;
  text-align: center !important;
}
/*colorization of start containers*/
.start .clients_tab .start-title {
  border-bottom: 2px solid #81af9f !important;
}
.start .clients_tab .label_start {
  background: transparent !important;
  color: #81af9f !important;
}
.start .clients_tab .fa {
  color: #81af9f !important;
}
.start .prices_tab .start-title {
  border-bottom: 2px solid #d4b668 !important;
}
.start .prices_tab .label_start {
  background: transparent !important;
  color: #d4b668 !important;
}
.start .prices_tab .fa {
  color: #d4b668 !important;
}
.start .logistics_tab .start-title {
  border-bottom: 2px solid #d49091 !important;
}
.start .logistics_tab .label_start {
  background: transparent !important;
  color: #d49091 !important;
}
.start .logistics_tab .fa {
  color: #d49091 !important;
}
.start .payments_tab .start-title {
  border-bottom: 2px solid #9bbacd !important;
}
.start .payments_tab .label_start {
  background: transparent !important;
  color: #9bbacd !important;
}
.start .payments_tab .fa {
  color: #9bbacd !important;
}
.start .pl_tab .start-title {
  border-bottom: 2px solid #cdaf9b !important;
}
.start .pl_tab .label_start {
  background: transparent !important;
  color: #cdaf9b !important;
}
.start .pl_tab .fa {
  color: #cdaf9b !important;
}
.start .crm_tab .start-title {
  border-bottom: 2px solid #9597b1 !important;
}
.start .crm_tab .label_start {
  background: transparent !important;
  color: #9597b1 !important;
}
.start .crm_tab .fa {
  color: #9597b1 !important;
}
.status .start-description {
  padding-top: 10px;
}
.status .start-title:nth-last-child(1) {
  padding-bottom: 10px;
}
.contract-page {
  background: #f9f9f7 !important;
}
.filter-template {
  border-radius: 0 !important;
  word-wrap: break-word;
  background: transparent;
}
.filter-template .btn {
  font-size: calc(14px / 1.3) !important;
  border-radius: 0 !important;
  color: #666b72 !important;
}
.filter-template .ui-select-match .form-control,
.filter-template .ui-select-container .form-control {
  font-size: 14px !important;
  color: #000000 !important;
  display: block;
  margin-top: 0;
}
.bigger-filter-template .btn {
  font-size: 14px !important;
}
.all-filters-block {
  height: auto;
  display: block;
  width: 17%;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  right: 0;
  bottom: 0;
  top: 0;
  box-shadow: none;
  background: #34495e;
  z-index: 1000;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 40px;
  padding-bottom: 40px;
}
.all-filters-block .filter-template {
  font-family: 'Semibold', sans-serif !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  border-bottom: 0 !important;
  word-wrap: break-word;
  margin-bottom: 5px;
}
.all-filters-block .filter-template .btn-primary {
  font-family: 'Bold', sans-serif !important;
  box-shadow: none !important;
  font-size: calc(14px / 1.3) !important;
  color: #ffffff !important;
}
.all-filters-block .filter-template .form-control {
  border: 0 !important;
  border-bottom: 1px solid #e8e6e6 !important;
  border-radius: 0 !important;
}
.all-filters-block .filter-template .btn {
  color: #fff !important;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: calc(14px / 1.3);
  letter-spacing: var(--letter-spacing-mega, 0.05em);
}
.all-filters-block .contract-description-label {
  padding: 6px;
  margin: 0 auto;
  margin-bottom: 0 !important;
  margin-top: 20px !important;
  display: block;
  text-align: center;
  color: #fff;
}
.all-filters-block #daterange5 {
  width: 95%;
  margin: 0 auto;
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
}
.all-filters-block .fixed-button {
  bottom: 0px;
  right: 0;
  position: fixed;
  color: #fff !important;
  width: 17%;
  padding: 6px;
  z-index: 100;
  border-radius: 0;
  background: #0a0e12;
}
.all-filters-block .input-group {
  margin: 0 auto;
  width: 95%;
}
.all-filters-block .input-group .form-control {
  border-bottom: 1px solid #e8e6e6 !important;
  color: #fff !important;
}
.all-filters-block .ui-select-multiple.ui-select-bootstrap {
  color: #fff !important;
  padding: 2px;
}
.all-filters-block .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  height: 16px;
  color: #fff !important;
  font-size: calc(14px / 1.1) !important;
  text-transform: capitalize !important;
  margin-bottom: 0;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.all-filters-block .ui-select-multiple.ui-select-bootstrap input.ui-select-search::-webkit-input-placeholder {
  color: #fff !important;
  font-size: calc(14px / 1.1) !important;
}
.all-filters-block .crop-filters {
  margin-bottom: 5px;
  text-align: left;
}
.all-filters-block .filter-template.crop-filters .btn {
  font-size: calc(14px / 1.2) !important;
  display: inline-block;
  width: auto;
  text-align: left;
  padding: 2px;
  margin: 0;
  color: #fff !important;
  line-height: 1;
}
.all-filters-block .gt_test_filter_date {
  margin-bottom: 5px !important;
}
.all-filters-block .search-container .btn {
  padding: 2px !important;
  color: #fff !important;
}
.all-filters-block .search-container input {
  color: #fff !important;
}
.all-filters-block .search-container input::-webkit-input-placeholder {
  color: #fff !important;
}
.all-filters-block h4 {
  color: #fff !important;
}
.all-filters-block label {
  color: #fff !important;
}
.all-filters-block .form-control {
  font-size: calc(14px / 1.3) !important;
  color: #fff !important;
  line-height: 1;
}
.all-filters-block .form-control::-webkit-input-placeholder {
  color: #fff !important;
}
.all-filters-block .form-control::-webkit-input-placeholder::first-letter {
  text-transform: uppercase !important;
}
.all-filters-block .btn-default {
  color: #fff !important;
}
.all-filters-block .btn-default:hover {
  background: #151e26 !important;
}
.all-filters-block .alert {
  padding: 5px;
  margin: 0;
}
.all-filters-block .alert .input-group .form-control {
  height: 20px;
  background: transparent;
}
.all-filters-block .alert .form-control {
  height: 20px;
  background: transparent;
}
.all-filters-block .alert-warning {
  background: rgba(181, 146, 96, 0.1) !important;
}
.all-filters-block .alert-warning .btn {
  background: rgba(181, 146, 96, 0.4) !important;
  border: 0 !important;
  color: #fff !important;
}
.all-filters-block .btn-danger {
  background: transparent !important;
  color: #fff !important;
}
.all-filters-block .alert-danger {
  color: #0c4d40 !important;
}
.all-filters-block .alert-danger .btn-danger {
  background: #cf6163 !important;
}
.all-filters-block .text-muted {
  color: #fff !important;
}
.all-filters-block .filter-template .ui-select-match .form-control,
.all-filters-block .filter-template .ui-select-container .form-control {
  font-size: calc(14px / 1.3) !important;
  height: 20px;
  padding-left: 5px;
  line-height: 1 !important;
}
.all-filters-block .btn-group.crop-filters {
  margin-bottom: 5px;
}
.all-filters-block .btn-group.crop-filters .btn-default {
  color: #fff !important;
}
.all-filters-block .gt-date-select .dropdown-menu {
  left: -60px !important;
}
.all-filters-block .input-group-addon {
  background: #151e26;
  color: #ffffff;
}
.all-filters-block .form-control .ui-select-match-text {
  color: #ffffff;
}
.all-filters-block .form-control .ui-select-match-text button {
  color: #ffffff;
}
.all-filters-block .form-control .ui-select-match-text button i {
  color: #ffffff;
}
.all-filters-block .ng-not-empty.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  background: transparent !important;
}
.header-filters .btn-link {
  font-size: 0.7em;
}
.filter-template.crop-filters {
  border-radius: 4px !important;
}
.filter-template.crop-filters .btn {
  display: block;
  width: 100%;
  padding-left: 10px;
  text-align: left;
  margin: 0 auto;
  border-radius: 0 !important;
  border-right: 0 !important;
}
.filter-template.crop-filters .btn::first-letter {
  text-transform: uppercase !important;
}
.filter-template.crop-filters .btn-primary {
  border-radius: 4px !important;
}
.btn-group.crop-filters {
  margin-bottom: 10px;
}
.btn-group.crop-filters .btn-default {
  font-family: 'Regular', sans-serif !important;
  margin-right: 2px;
  font-size: calc(14px / 1.3) !important;
}
.btn-group.crop-filters .btn-primary {
  font-family: 'Regular', sans-serif !important;
  border: 1px solid #d2cece !important;
  font-size: calc(14px / 1.3) !important;
  background: rgba(0, 0, 0, 0.15) !important;
  padding: 4px !important;
}
.filters-well {
  border-radius: 8px;
  padding: 12px;
  white-space: normal;
}
.filters-well .btn-group.crop-filters .btn-primary {
  background: #fff !important;
}
.filter-template.filter-elevator-logistic {
  width: 100%;
}
.filter-template.filter-elevator-logistic .btn {
  width: auto;
  margin: 0 auto;
  border-radius: 4px !important;
  padding: 10px;
}
.btn-group-with-hidden-naming .btn span {
  display: none;
}
.btn-group-with-hidden-naming .btn-primary span {
  display: inline-block;
}
.filter-template-with-hidden-naming .btn-default span {
  font-size: calc(14px / 1.3);
}
.filter-template-with-hidden-naming .btn-default i {
  font-size: 14px;
}
.filter-template-with-hidden-naming .btn-primary span {
  font-size: 14px;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.filter-template-with-hidden-naming .btn-primary i {
  font-size: 14px;
}
.vertical-filter-template {
  border-radius: 4px !important;
}
.vertical-filter-template .btn {
  border-radius: 4px !important;
  display: block;
  width: 100%;
  text-align: left;
  padding: 2px;
  overflow: hidden;
  padding-left: 10px;
  white-space: nowrap;
  border-bottom: 0 !important;
}
.vertical-filter-template .btn .label_start {
  position: absolute;
  top: 9px;
  right: 5px;
}
.vertical-filter-template .add-btn {
  color: #ffffff;
  border-color: #1bb394 !important;
  background: #1bb394 !important;
  border-width: 1px;
  box-shadow: none !important;
  border: 1px solid #106856 !important;
}
.vertical-filter-template .add-btn span {
  position: relative;
  top: 0;
  right: 0;
}
.vertical-filter-template .add-btn:hover {
  background: #1bb394 !important;
}
.prices-section-filters {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.prices-section-filters .btn {
  font-size: calc(14px / 1.3) !important;
  padding: 2px;
}
.analytics-page .analytics-container-white {
  background: #fff !important;
}
.analytics-page .analytics-container-white .table-container-wrapper,
.analytics-page .analytics-container-white .ul-inline-row {
  box-shadow: none !important;
  background: transparent;
  border: 0 !important;
  padding: 0 !important;
  border-radius: 4px !important;
}
.analytics-page .analytics-container-white .item-settings-dropdown {
  display: none;
}
.analytics-page h4 {
  padding-left: 15px;
}
.analytics-page .main-table {
  width: 100% !important;
}
.analytics-page .main-table tr td {
  padding: 2px;
  vertical-align: middle !important;
  border: 0 !important;
}
.analytics-page .main-table tr td:nth-child(2),
.analytics-page .main-table tr td:nth-child(3) {
  text-align: right !important;
  white-space: nowrap !important;
}
.analytics-page .chart-graph-block {
  width: 100% !important;
}
.analytics-page .overflow-x-with-margin {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.analytics-page .analytics-container .table tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.analytics-page .analytics-container .table tr td {
  border: 0;
}
.no-borders-table tbody tr td {
  border: 0 !important;
}
.analytics-container {
  background: #fff;
  padding: 20px !important;
  box-shadow: none !important;
  padding-top: 0;
  border-radius: 8px;
}
.analytics-container .start-page-header {
  font-size: 18px;
}
.analytics-container .start-page-header small {
  font-size: 12px;
  font-family: 'Light', sans-serif !important;
}
.analytics-container input[type='checkbox'],
.analytics-container input[type='radio'] {
  width: 20px;
  height: 20px;
}
.analytics-container .table-container-wrapper,
.analytics-container .ul-inline-row {
  box-shadow: none !important;
  background: transparent;
  padding: 0 !important;
  border-radius: 4px !important;
}
.analytics-container .upcoming-updates-list {
  box-shadow: none !important;
  padding: 0 !important;
}
.analytics-container .upcoming-updates-list h4 {
  font-size: 14px !important;
}
.analytics-container .upcoming-updates-list .wrap-text {
  font-size: calc(14px / 1.3) !important;
  text-align: left;
}
.analytics-container .upcoming-updates-list .col-md-2,
.analytics-container .upcoming-updates-list .col-md-3,
.analytics-container .upcoming-updates-list .col-md-4 {
  text-align: left;
  padding-left: 2px;
  padding-right: 2px;
  line-height: 1;
}
.analytics-container canvas {
  border: 0 !important;
}
.analytics-container table tr th {
  border: 0 !important;
  border-right: 1px solid #e8e6e6 !important;
  background: #fafafa !important;
}
.analytics-container table tr td .fa {
  font-size: calc(14px / 1.4);
}
.analytics-container table tr td .fa-trash,
.analytics-container table tr td .fa-arrows {
  font-size: calc(14px / 1.3);
}
.analytics-container table.logistics-table tr th {
  background: #fff !important;
}
.analytics-container .progress {
  background: rgba(0, 0, 0, 0.03);
}
.analytics-container .unscrollable-grid .ui-grid-cell,
.analytics-container .grid .ui-grid-cell {
  background: transparent !important;
  border-bottom: 0 !important;
  font-size: calc(14px / 1.3);
  text-align: right;
}
.analytics-container .unscrollable-grid .ui-grid-cell .fa,
.analytics-container .grid .ui-grid-cell .fa {
  font-size: calc(14px / 1.4);
}
.analytics-container .unscrollable-grid .ui-grid-cell:nth-child(1),
.analytics-container .grid .ui-grid-cell:nth-child(1) {
  text-align: left;
}
.analytics-container .unscrollable-grid .ui-grid-cell-contents,
.analytics-container .grid .ui-grid-cell-contents {
  font-size: calc(14px / 1.3) !important;
  background: transparent !important;
  padding: 0 !important;
}
.analytics-container .unscrollable-grid .ui-grid-header-cell,
.analytics-container .grid .ui-grid-header-cell {
  text-align: right;
  background: transparent !important;
}
.analytics-container .unscrollable-grid .ui-grid-header-cell:nth-child(1),
.analytics-container .grid .ui-grid-header-cell:nth-child(1) {
  text-align: right;
}
.analytics-container .chart-graph-block {
  width: 100%;
  padding: 0;
}
.analytics-container .inset-container {
  background: transparent !important;
}
.analytics-container .inset-container.header-margin-top {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.analytics-container .form-control {
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
}
.analytics-container .input-group .form-control:first-child {
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}
.analytics-container .alert-success .form-control {
  border-color: #1bb394 !important;
  background: #ebf1ef !important;
  margin-bottom: 4px;
}
.analytics-container .alert-success .ui-select-container .form-control {
  border-color: #1bb394 !important;
  background: #ebf1ef !important;
}
.analytics-container.filters-well .input-group .form-control:first-child {
  border-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-bottom: 0 !important;
}
.details-analytics-container .table-container-wrapper {
  border: 0 !important;
}
.crop-passport-analytics-container .table-container-wrapper,
.crop-passport-analytics-container .ul-inline-row {
  border: 1px solid #e8e6e6 !important;
  background: #fff;
  padding: 20px !important;
  border-radius: 8px !important;
}
.purchase-plan-table .ui-grid-cell {
  line-height: 2;
  height: 30px;
}
.users-plan-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.users-plan-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.users-plan-table .datetimepicker thead tr th:nth-child(1),
.users-plan-table .datetimepicker thead tr th:nth-child(2),
.users-plan-table .datetimepicker thead tr th:nth-child(3),
.users-plan-table .datetimepicker tbody tr td:nth-child(1),
.users-plan-table .datetimepicker tbody tr td:nth-child(2),
.users-plan-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.users-plan-table .datetimepicker .table thead tr th:nth-child(6),
.users-plan-table .datetimepicker .table thead tr th:nth-child(7),
.users-plan-table .datetimepicker .table tbody tr td:nth-child(6),
.users-plan-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.users-plan-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.users-plan-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.users-plan-table .ui-grid-cell {
  line-height: 1;
  height: 22px;
  vertical-align: middle;
}
.users-plan-table .main-table {
  border-color: #e8e6e6 !important;
}
.users-plan-table .main-table tr td:first-child a {
  font-family: 'Bold', sans-serif !important;
  text-transform: uppercase;
  color: #000000 !important;
  font-size: calc(14px / 1.3) !important;
  vertical-align: middle !important;
}
.users-plan-table .main-table tr td {
  border-top: 0 / 1.1 !important;
  border-right: 0 !important;
  padding: 2px !important;
  vertical-align: middle !important;
}
.navbar-analytics-container {
  padding: 0 !important;
  border: 0 !important;
}
.settings-filters {
  padding: 0 !important;
}
.settings-filters .btn {
  width: 100% !important;
  margin-left: 0 !important;
  text-transform: uppercase;
  padding: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
.settings-filters .btn .label_start {
  top: 11px;
  font-size: calc(14px * 1.1) !important;
  font-weight: 600 !important;
}
.settings-filters .btn + .btn {
  margin-left: 0 !important;
}
.settings-filters .btn:hover {
  background: #e8e8e8;
  margin-left: 0 !important;
}
.list-filters .btn {
  padding: 2px;
  padding-left: 12px;
  padding-right: 12px;
  background: #dcd9d9 !important;
  border-radius: 12px !important;
  margin-bottom: 2px;
}
.list-filters .btn .label_start {
  top: 3px;
  font-size: 14px !important;
}
.list-filters .btn-primary {
  background: #fff !important;
  border: 1px solid #d2cece !important;
}
.settings-iframe-container {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  border-radius: 8px;
}
.settings-iframe-container iframe {
  margin-top: -100px;
  width: 100%;
  height: 2200px;
}
.documentation-page .settings-iframe-container {
  height: 100vh !important;
}
#clients-vertical-filter {
  background: transparent !important;
}
#clients-vertical-filter .btn {
  background: #eeeee8;
}
#clients-vertical-filter .btn:hover {
  background: #e5e5dd;
}
#clients-vertical-filter .btn:nth-child(1),
#clients-vertical-filter .btn:nth-child(6),
#clients-vertical-filter .btn:nth-last-child(2) {
  margin-bottom: 10px;
  border-bottom: 1px solid #e8e6e6 !important;
}
.trader-page .overlay .fa {
  left: 48% !important;
}
.user-perm-list {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}
.user-perm-list li {
  text-align: left;
  border-bottom: 1px solid #e8e6e6;
}
.consolidated-page .overlay .fa {
  left: 50% !important;
}
.consolidated-page .table-with-total .label {
  padding: 2px !important;
  display: inline-block;
  margin-top: 2px;
  font-size: calc(14px / 1.3);
}
.consolidated-page .table-with-total tr td:first-child {
  text-align: left !important;
}
.contracts-page,
.vessels-page,
.logistics-page,
.request-page,
.create-contract-page,
.farm-page,
.elevator-page,
.payments-page,
.documents-page,
.pl-page {
  background: transparent !important;
}
.contracts-page .filter-template,
.vessels-page .filter-template,
.logistics-page .filter-template,
.request-page .filter-template,
.create-contract-page .filter-template,
.farm-page .filter-template,
.elevator-page .filter-template,
.payments-page .filter-template,
.documents-page .filter-template,
.pl-page .filter-template {
  border: 0 !important;
}
.contracts-page .filter-template a,
.vessels-page .filter-template a,
.logistics-page .filter-template a,
.request-page .filter-template a,
.create-contract-page .filter-template a,
.farm-page .filter-template a,
.elevator-page .filter-template a,
.payments-page .filter-template a,
.documents-page .filter-template a,
.pl-page .filter-template a {
  font-size: 14px;
}
.contracts-page .select-container,
.vessels-page .select-container,
.logistics-page .select-container,
.request-page .select-container,
.create-contract-page .select-container,
.farm-page .select-container,
.elevator-page .select-container,
.payments-page .select-container,
.documents-page .select-container,
.pl-page .select-container {
  width: 50% !important;
}
.contracts-page .half-size-btn-group,
.vessels-page .half-size-btn-group,
.logistics-page .half-size-btn-group,
.request-page .half-size-btn-group,
.create-contract-page .half-size-btn-group,
.farm-page .half-size-btn-group,
.elevator-page .half-size-btn-group,
.payments-page .half-size-btn-group,
.documents-page .half-size-btn-group,
.pl-page .half-size-btn-group {
  width: 40% !important;
}
.contracts-page #outer-USD-table td,
.vessels-page #outer-USD-table td,
.logistics-page #outer-USD-table td,
.request-page #outer-USD-table td,
.create-contract-page #outer-USD-table td,
.farm-page #outer-USD-table td,
.elevator-page #outer-USD-table td,
.payments-page #outer-USD-table td,
.documents-page #outer-USD-table td,
.pl-page #outer-USD-table td,
.contracts-page .export-table td,
.vessels-page .export-table td,
.logistics-page .export-table td,
.request-page .export-table td,
.create-contract-page .export-table td,
.farm-page .export-table td,
.elevator-page .export-table td,
.payments-page .export-table td,
.documents-page .export-table td,
.pl-page .export-table td,
.contracts-page .vessels-table td,
.vessels-page .vessels-table td,
.logistics-page .vessels-table td,
.request-page .vessels-table td,
.create-contract-page .vessels-table td,
.farm-page .vessels-table td,
.elevator-page .vessels-table td,
.payments-page .vessels-table td,
.documents-page .vessels-table td,
.pl-page .vessels-table td,
.contracts-page .inner-UAH-table td,
.vessels-page .inner-UAH-table td,
.logistics-page .inner-UAH-table td,
.request-page .inner-UAH-table td,
.create-contract-page .inner-UAH-table td,
.farm-page .inner-UAH-table td,
.elevator-page .inner-UAH-table td,
.payments-page .inner-UAH-table td,
.documents-page .inner-UAH-table td,
.pl-page .inner-UAH-table td {
  width: auto !important;
}
.contracts-page .cut-table tr > td,
.vessels-page .cut-table tr > td,
.logistics-page .cut-table tr > td,
.request-page .cut-table tr > td,
.create-contract-page .cut-table tr > td,
.farm-page .cut-table tr > td,
.elevator-page .cut-table tr > td,
.payments-page .cut-table tr > td,
.documents-page .cut-table tr > td,
.pl-page .cut-table tr > td {
  border-color: #e8e6e6 !important;
}
.contracts-page .cut-table tr > th,
.vessels-page .cut-table tr > th,
.logistics-page .cut-table tr > th,
.request-page .cut-table tr > th,
.create-contract-page .cut-table tr > th,
.farm-page .cut-table tr > th,
.elevator-page .cut-table tr > th,
.payments-page .cut-table tr > th,
.documents-page .cut-table tr > th,
.pl-page .cut-table tr > th {
  border-color: #e8e6e6 !important;
}
.contracts-page .cut-table .label,
.vessels-page .cut-table .label,
.logistics-page .cut-table .label,
.request-page .cut-table .label,
.create-contract-page .cut-table .label,
.farm-page .cut-table .label,
.elevator-page .cut-table .label,
.payments-page .cut-table .label,
.documents-page .cut-table .label,
.pl-page .cut-table .label {
  color: #000000;
}
.create-contract-page .page-header-main .item-details-block-transparent .item-details-el .value,
.create-request-page .page-header-main .item-details-block-transparent .item-details-el .value,
.create-finance-page .page-header-main .item-details-block-transparent .item-details-el .value,
.create-passport-page .page-header-main .item-details-block-transparent .item-details-el .value {
  color: #ffffff !important;
}
.create-contract-page .page-header-main .item-details-block-transparent .item-details-el .value a,
.create-request-page .page-header-main .item-details-block-transparent .item-details-el .value a,
.create-finance-page .page-header-main .item-details-block-transparent .item-details-el .value a,
.create-passport-page .page-header-main .item-details-block-transparent .item-details-el .value a {
  color: #ffffff !important;
}
.create-contract-page .page-header-main .item-details-block-transparent .item-details-el .value .label,
.create-request-page .page-header-main .item-details-block-transparent .item-details-el .value .label,
.create-finance-page .page-header-main .item-details-block-transparent .item-details-el .value .label,
.create-passport-page .page-header-main .item-details-block-transparent .item-details-el .value .label {
  color: #ffffff !important;
}
.create-contract-page .page-header-main .item-details-block-transparent .item-details-el .property,
.create-request-page .page-header-main .item-details-block-transparent .item-details-el .property,
.create-finance-page .page-header-main .item-details-block-transparent .item-details-el .property,
.create-passport-page .page-header-main .item-details-block-transparent .item-details-el .property {
  color: #ffffff !important;
}
.create-contract-page .page-header-main .item-details-block-transparent .item-details-el .property a,
.create-request-page .page-header-main .item-details-block-transparent .item-details-el .property a,
.create-finance-page .page-header-main .item-details-block-transparent .item-details-el .property a,
.create-passport-page .page-header-main .item-details-block-transparent .item-details-el .property a {
  color: #ffffff !important;
}
.create-contract-page .nav-tabs-cut li > a,
.create-request-page .nav-tabs-cut li > a,
.create-finance-page .nav-tabs-cut li > a,
.create-passport-page .nav-tabs-cut li > a {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.create-contract-page .nav-tabs-cut .thead-filter-btn,
.create-request-page .nav-tabs-cut .thead-filter-btn,
.create-finance-page .nav-tabs-cut .thead-filter-btn,
.create-passport-page .nav-tabs-cut .thead-filter-btn {
  display: block !important;
}
.create-contract-page .nav-tabs-cut li.active > a span,
.create-request-page .nav-tabs-cut li.active > a span,
.create-finance-page .nav-tabs-cut li.active > a span,
.create-passport-page .nav-tabs-cut li.active > a span {
  display: inline-block !important;
}
.cg-notify-message {
  background: #efefef !important;
  top: 0 !important;
  left: 0 !important;
  position: fixed !important;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  text-align: center !important;
  padding: 6px 12px;
  font-family: 'Bold', sans-serif !important;
}
.ui-notification {
  background: #031911 !important;
  opacity: 0.9;
  border-radius: 4px;
  width: auto !important;
}
.ui-notification h3 {
  padding: 0;
  font-family: 'Bold', sans-serif !important;
}
.ui-notification .message {
  font-size: 14px !important;
  padding: 6px;
  margin: 5px;
  font-family: 'Bold', sans-serif !important;
}
.ui-notification .message::before {
  font-family: 'FontAwesome';
  content: '\f058  ';
}
.notifications-table tbody > tr > td {
  padding: 6px !important;
  vertical-align: middle;
  line-height: 1.1 !important;
  border-bottom: 1px solid #e8e6e6 !important;
}
.notifications-table tbody > tr > td .label {
  padding: 2px !important;
  text-align: center;
  margin: 0;
  vertical-align: middle;
}
.ui-notification.error {
  background: #c43e40 !important;
}
.ui-notification.error .message .fa {
  display: none;
}
.ui-notification.error .message::before {
  font-family: 'FontAwesome';
  margin-right: 5px;
  content: ' \f06a ';
}
.ui-notification.warning {
  background: #c43e40 !important;
}
.ui-notification.warning .message .fa {
  display: none;
}
.ui-notification.warning .message::before {
  font-family: 'FontAwesome';
  margin-right: 5px;
  content: ' \f071 ';
}
.ui-notification.info {
  background: #7ca5be !important;
}
.ui-notification.info .message::before {
  font-family: 'FontAwesome';
  margin-right: 5px;
  content: ' \f05a ';
}
.notify-dropdown-btn {
  background: transparent !important;
  position: relative;
  display: block;
  float: none;
  text-align: center;
  margin: -2px;
}
.notify-dropdown-btn span {
  vertical-align: top;
  margin-top: 14px;
  border-radius: 4px !important;
}
.permissions-table tr td {
  padding: 2px !important;
  margin: 0 !important;
  vertical-align: middle;
  line-height: 1 !important;
  white-space: normal;
}
.permissions-table tr td input,
.permissions-table tr td .checkbox,
.permissions-table tr td span,
.permissions-table tr td label {
  margin: 0 !important;
  line-height: 1 !important;
}
.permissions-table .highlighted-tr {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
}
.permissions-table .highlighted-tr td,
.permissions-table .highlighted-tr span {
  font-family: 'Bold', sans-serif !important;
}
.permissions-table .highlighted-tr td {
  background: #f9f9f7 !important;
}
.permissions-table td.label-strong {
  font-family: 'Bold', sans-serif !important;
}
.notify-dropdown-menu table {
  width: 100% !important;
}
.notify-dropdown-menu table tr th {
  text-align: center;
}
.notify-dropdown-menu table tr td {
  white-space: nowrap;
  padding: 6px !important;
  text-align: left;
  font-size: 14px !important;
  color: #000000;
  font-family: 'Regular', sans-serif !important;
  border: 0 !important;
  border-bottom: 1px solid #e8e6e6 !important;
}
.notify-dropdown-menu table tr td .fa {
  color: #000000 !important;
}
.notify-dropdown-menu table tr td.message {
  white-space: normal !important;
  word-wrap: break-word !important;
}
.notify-dropdown-menu .read .fa-check-circle {
  display: none;
}
.notify-dropdown-menu .read:hover {
  cursor: pointer;
}
.notify-dropdown-menu .read:hover .fa-circle-o {
  display: none;
}
.notify-dropdown-menu .read:hover .fa-check-circle {
  display: inline-block;
}
.notify-dropdown-menu .detail:hover {
  cursor: pointer;
}
.preloader {
  height: auto;
  width: 75px;
}
legend {
  color: #bdc3c7;
}
.navbar {
  border-radius: 0;
  margin-bottom: 0 !important;
}
.error-msg {
  font-size: 0.8em;
  color: #cf6163;
}
.help-inline {
  padding: 1px;
  width: 100%;
  display: block;
  font-size: calc(14px / 1.3);
  background: #cf6163;
  color: #fff;
  font-family: 'Semibold', sans-serif !important;
}
.help-inline-hint {
  display: block;
  font-size: calc(14px / 1.3);
  font-family: 'Light', sans-serif !important;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  width: 58.3333%;
  float: right;
  color: #0075ff;
  text-align: left;
  border-top: 0 !important;
  white-space: normal;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.additional-field {
  color: #95a5a6 !important;
  font-size: 0.7em;
}
.vertical-divider {
  width: 1px;
  background-color: #95a5a6;
  height: 20px;
  margin-top: 15px;
}
.horizontal-divider {
  background-color: #dcd9d9;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  page-break-after: always;
}
.last_login {
  font-size: 0.8em;
  color: #666 !important;
  text-align: center;
  border-top: 1px solid #666;
  padding-top: 5px;
  margin-top: 5px;
  overflow-x: hidden;
}
.last_login a {
  color: #666;
}
.header-margin-top {
  margin-top: 5px;
  margin-bottom: 2px;
  padding-left: 0px;
  padding-right: 0px;
}
.header-margin-top small {
  font-size: 75%;
  color: #8a8e97;
  margin-top: 30px;
  margin-left: 5px;
  font-family: 'Light', sans-serif !important;
}
.header-margin-top .row {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}
.small-screen-show {
  display: none !important;
}
.col-sm-10 .header-margin-top {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.contract_detail_table {
  table-layout: fixed !important;
  width: 100%;
}
.contract_detail_table tr td {
  word-break: break-all;
}
.contract_detail_table .label-opacity {
  box-shadow: none !important;
  background: transparent !important;
}
.contract_detail_table .last_update_cell {
  width: 100% !important;
}
.contract_detail_table .last_update_cell .label {
  width: 99.5%;
}
.contract_detail_table .contract_details {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  border-radius: 4px !important;
}
.contract_detail_table tbody {
  box-shadow: none !important;
}
.contract_detail_table ul {
  margin-bottom: 0;
}
.contract-stages-table tr td {
  white-space: normal;
  width: 55px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  vertical-align: top !important;
}
.contract-stages-table tr td .contract-status-block {
  padding: 2px !important;
  margin-bottom: 0 !important;
  word-break: break-all;
}
.contract-stages-table tr td .smaller-label {
  white-space: normal;
  word-wrap: break-word;
}
.contract-stages-table tbody tr:nth-last-child(1) td {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.logistics-payment-table tr td {
  font-size: 14px !important;
}
.multipassport-table {
  border-bottom: 4px solid #e8e6e6 !important;
}
.multipassport-table .super-highlighted-tr {
  background: #eeeee8 !important;
}
.multipassport-table .super-highlighted-tr td {
  background: #eeeee8 !important;
  border-right: 1px solid #e5e5dd !important;
  font-family: 'Regular', sans-serif !important;
}
.multipassport-table .super-highlighted-tr span {
  font-family: 'Regular', sans-serif !important;
}
.multipassport-table .mega-highlighted-tr {
  background: #e5e5dd !important;
}
.multipassport-table .mega-highlighted-tr td {
  background: #e5e5dd !important;
  border-right: 1px solid #ddddd1 !important;
  font-family: 'Semibold', sans-serif !important;
}
.multipassport-table .mega-highlighted-tr span {
  font-family: 'Bold', sans-serif !important;
}
.multipassport-table tr td {
  text-align: left !important;
  vertical-align: top !important;
  font-size: calc(14px / 1.2) !important;
}
.multipassport-table tr td h5,
.multipassport-table tr td h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: #0c4d40;
  font-family: 'Regular', sans-serif !important;
}
.multipassport-table tr td h5 {
  font-size: calc(14px / 1.1);
}
.multipassport-table tr td h6 {
  font-size: calc(14px / 1.1);
}
.multipassport-table thead {
  display: none;
}
.multipassport-table tbody {
  border-bottom: 3px solid #e8e6e6 !important;
}
.multipassport-table tr td:nth-child(1) a,
.multipassport-table tr th:nth-child(1) a {
  font-size: calc(14px / 1.1) !important;
}
.multipassport-table tr td:nth-child(1) a.smaller-label,
.multipassport-table tr th:nth-child(1) a.smaller-label {
  font-size: calc(14px / 1.3) !important;
}
.multipassport-table tr td:nth-child(1),
.multipassport-table tr th:nth-child(1) {
  width: 250px !important;
}
.multipassport-table tr td.product,
.multipassport-table tr th.product {
  width: 110px !important;
}
.multipassport-table tr td.volume,
.multipassport-table tr th.volume {
  width: 80px !important;
}
.multipassport-table tr td.price,
.multipassport-table tr th.price {
  width: 60px !important;
}
.multipassport-table tr td.basis,
.multipassport-table tr th.basis {
  width: 35px !important;
}
.multipassport-table tr td.value,
.multipassport-table tr th.value {
  width: 150px !important;
}
.multipassport-table tr td.charges,
.multipassport-table tr th.charges {
  width: 100px !important;
}
.multipassport-table tr td.debit,
.multipassport-table tr th.debit {
  width: 100px !important;
}
.multipassport-table tr td.invoice,
.multipassport-table tr th.invoice {
  width: 150px !important;
}
.multipassport-table .label {
  display: inline-flex !important;
  padding: 2px !important;
  margin: 0 !important;
}
.multipassport-table .alert {
  padding: 2px !important;
  border-radius: 4px !important;
  margin: 0 !important;
  margin-bottom: 2px !important;
  margin-top: 2px !important;
}
.multipassport-table .alert h5 {
  font-size: calc(14px / 1.1) !important;
  padding: 0 !important;
  margin: 0 !important;
}
.multipassport-table tbody > tr td:nth-child(1),
.multipassport-table thead > tr th:nth-child(1) {
  padding: 2px !important;
}
.multipassport-table tbody > tr td:nth-child(1) .label,
.multipassport-table thead > tr th:nth-child(1) .label {
  display: inline-flex !important;
  padding: 2px !important;
}
.multipassport-table .inside-table-ul {
  margin-bottom: 0;
}
.multipassport-table .inside-table-ul li {
  text-align: left !important;
  font-size: calc(14px / 1.3) !important;
  padding: 2px !important;
  white-space: normal;
}
.multipassport-table .inside-table-ul li .label {
  font-size: calc(14px / 1.3) !important;
}
.multipassport-table .inside-table-ul li:first-child {
  border-bottom: 0 !important;
}
.multipassport-table:nth-of-type(1) thead {
  display: table-header-group;
}
.multipassport-table:last-child {
  border-bottom: 0 !important;
}
.credit-report-table {
  border-collapse: collapse !important;
}
.credit-report-table tr td {
  padding: 4px 6px !important;
  vertical-align: middle !important;
}
.credit-report-table tr td .label {
  font-size: calc(14px / 1.2) !important;
}
.credit-report-table tbody > tr td:nth-child(1),
.credit-report-table thead > tr th:nth-child(1) {
  padding: 4px 6px !important;
}
.credit-report-table tbody > tr > td,
.credit-report-table thead > tr > th {
  padding: 4px 6px !important;
}
.derivatives-position-container .table-container-wrapper,
.derivatives-position-container .ul-inline-row {
  background: #fff !important;
}
.statistics-costs-table {
  table-layout: fixed;
  border-bottom: 0 !important;
}
.statistics-costs-table tbody {
  border-bottom: 1px solid #e8e6e6 !important;
}
.statistics-costs-table h6 {
  padding: 0 !important;
  margin: 0 !important;
}
.statistics-costs-table tr td,
.statistics-costs-table tr th {
  width: 130px !important;
  min-width: 130px !important;
  max-width: 130px !important;
  vertical-align: middle !important;
}
.statistics-costs-table tr td:first-child,
.statistics-costs-table tr th:first-child {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
  white-space: normal !important;
}
.statistics-costs-table tr td:first-child h6,
.statistics-costs-table tr th:first-child h6 {
  font-size: 14px !important;
  color: #000000 !important;
}
.statistics-costs-table tr td:first-child .fa,
.statistics-costs-table tr th:first-child .fa {
  color: #666b72 !important;
}
.statistics-costs-table tr td {
  font-size: calc(14px / 1.1) !important;
  line-height: 1;
  padding: 2px !important;
}
.statistics-costs-table tr td .positive-number,
.statistics-costs-table tr td .negative-number {
  font-family: 'Regular', sans-serif !important;
}
.costs-report-table {
  table-layout: fixed;
  border-bottom: 0 !important;
  overflow: auto;
  width: 100% !important;
  white-space: normal !important;
  vertical-align: middle !important;
  font-size: calc(14px / 1.1);
  background: transparent;
}
.costs-report-table tbody {
  border-bottom: 1px solid #e8e6e6 !important;
}
.costs-report-table tr.highlighted-tr-level-0 {
  background: #e5e5dd !important;
  font-family: 'Bold', sans-serif !important;
}
.costs-report-table tr {
  background: transparent;
  font-family: 'Regular', sans-serif !important;
}
.costs-report-table thead tr {
  height: 51px;
  line-height: 3;
}
.costs-report-table thead tr th {
  position: sticky !important;
  margin: 5px;
  top: 0;
  z-index: 20;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  display: table-cell;
  white-space: nowrap;
  text-transform: capitalize !important;
  padding: 4px !important;
}
.costs-report-table tbody tr td {
  border-color: #e8e6e6 !important;
  color: #000000;
  padding: 2px !important;
  text-align: left;
  overflow: hidden;
  display: table-cell;
  white-space: nowrap;
}
.costs-report-table thead tr th.column-number,
.costs-report-table thead tr th.column-group {
  z-index: 40;
  top: 0 !important;
}
.costs-report-table tbody tr td.column-number,
.costs-report-table tbody tr td.column-group {
  z-index: 30;
}
.costs-report-table thead tr th.column-number,
.costs-report-table tbody tr td.column-number {
  position: sticky !important;
  left: 0 !important;
  width: 35px !important;
  min-width: 35px !important;
  max-width: 35px !important;
  font-size: calc(14px * 1) !important;
  background: #fff;
}
.costs-report-table thead tr th.column-group,
.costs-report-table tbody tr td.column-group {
  position: sticky !important;
  left: 35px !important;
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
  background: #fff;
}
.costs-report-table tbody tr td.column-details,
.costs-report-table thead tr th.column-details,
.costs-report-table tbody tr td.column-agg,
.costs-report-table thead tr th.column-agg {
  width: 150px;
  min-width: 150px;
  max-width: 200px;
}
.costs-report-table tbody tr td.column-agg {
  text-align: right;
}
.costs-report-table tbody tr td.column-details {
  white-space: normal;
}
.report-cross-table {
  table-layout: fixed;
  border-bottom: 0 !important;
  overflow: auto;
  width: 100% !important;
  white-space: normal !important;
  vertical-align: middle !important;
  font-size: calc(14px / 1.2);
  background: transparent;
}
.report-cross-table tbody {
  border-bottom: 1px solid #e8e6e6 !important;
}
.report-cross-table tr {
  background: transparent;
  font-family: 'Regular', sans-serif !important;
}
.report-cross-table thead tr {
  height: 51px;
  line-height: 3;
}
.report-cross-table thead tr th {
  position: sticky !important;
  margin: 5px;
  top: 0;
  z-index: 20;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  display: table-cell;
  white-space: nowrap;
  text-transform: capitalize !important;
  min-height: 51px;
  padding: 4px !important;
}
.report-cross-table thead tr:nth-child(1) th {
  top: 0;
}
.report-cross-table thead tr:nth-child(2) th {
  top: 51px;
}
.report-cross-table thead tr:nth-child(3) th {
  top: 102px;
}
.report-cross-table thead tr:nth-child(4) th {
  top: 153px;
}
.report-cross-table thead tr:nth-child(5) th {
  top: 204px;
}
.report-cross-table tbody tr td {
  border-color: #e8e6e6 !important;
  color: #000000;
  text-align: left;
  overflow: hidden;
  display: table-cell;
  white-space: nowrap;
}
.report-cross-table thead tr th.column-totals,
.report-cross-table thead tr th.column-number,
.report-cross-table thead tr th.column-group {
  z-index: 40;
  top: 0 !important;
}
.report-cross-table tbody tr td.column-totals,
.report-cross-table tbody tr td.column-number,
.report-cross-table tbody tr td.column-group {
  z-index: 30;
}
.report-cross-table thead tr th.column-totals,
.report-cross-table tbody tr td.column-totals {
  position: sticky !important;
  left: 285px !important;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  font-size: calc(14px / 1.2) !important;
  background: #fff;
}
.report-cross-table thead tr th.column-number,
.report-cross-table tbody tr td.column-number {
  position: sticky !important;
  left: 0 !important;
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  font-size: calc(14px / 1.2) !important;
  background: #fff;
}
.report-cross-table thead tr th.column-group,
.report-cross-table tbody tr td.column-group {
  position: sticky !important;
  left: 35px !important;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  background: #fff;
}
.report-cross-table thead tr th.columns-total,
.report-cross-table thead tr td.columns-total,
.report-cross-table thead tr th.columns-number,
.report-cross-table thead tr td.columns-number,
.report-cross-table thead tr th.columns-group,
.report-cross-table tbody tr td.columns-group {
  background: #fff;
}
.report-cross-table tbody tr td.details-info {
  white-space: normal;
}
.report-cross-table tbody tr td.column-details,
.report-cross-table thead tr th.column-details,
.report-cross-table tbody tr td.column-agg,
.report-cross-table thead tr th.column-agg {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}
.report-cross-table tbody tr td.column-resource,
.report-cross-table thead tr th.column-resource {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}
.report-cross-table tbody tr td.column-resource-agg,
.report-cross-table thead tr th.column-resource-agg {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
}
.report-cross-table tbody tr td.column-vertical-group,
.report-cross-table thead tr th.column-vertical-group {
  width: 300px;
  min-width: 300px;
  max-width: 30000px;
}
.report-cross-table thead tr th.column-agg:nth-child(0) {
  top: 51px;
}
.report-cross-table thead tr th.column-agg:nth-child(1) {
  top: 51px;
}
.report-cross-table thead tr th.column-agg:nth-child(2) {
  top: 102px;
}
.report-cross-table thead tr th.column-agg:nth-child(3) {
  top: 153px;
}
.report-cross-table tbody tr td.column-agg {
  text-align: right;
}
.report-cross-table tbody tr td.column-details {
  white-space: normal;
}
.report-cross-table tr td:nth-child(2) {
  padding-left: 55px;
}
.highlighted-tr-level-0 {
  background: #dfdfd4 !important;
  font-family: 'Bold', sans-serif !important;
}
.highlighted-tr-level-0 td:nth-child(1) {
  padding-left: 3px !important;
}
.highlighted-tr-level-0 td:nth-child(2) {
  padding-left: 5px !important;
}
.highlighted-tr-level-1 {
  background: #e3e3da !important;
  font-family: 'Bold', sans-serif !important;
}
.highlighted-tr-level-1 td:nth-child(1) {
  padding-left: 6px !important;
}
.highlighted-tr-level-1 td:nth-child(2) {
  padding-left: 15px !important;
}
.highlighted-tr-level-2 {
  background: #e5e5dd !important;
  font-family: 'Semibold', sans-serif !important;
}
.highlighted-tr-level-2 td:nth-child(1) {
  padding-left: 9px !important;
}
.highlighted-tr-level-2 td:nth-child(2) {
  padding-left: 25px !important;
}
.highlighted-tr-level-3 {
  background: #ecece6 !important;
  font-family: 'Semibold', sans-serif !important;
}
.highlighted-tr-level-3 td:nth-child(1) {
  padding-left: 12px !important;
}
.highlighted-tr-level-3 td:nth-child(2) {
  padding-left: 35px !important;
}
.highlighted-tr-level-4 {
  background: #f2f2ee !important;
  font-family: 'Semibold', sans-serif !important;
}
.highlighted-tr-level-4 td:nth-child(1) {
  padding-left: 15px !important;
}
.highlighted-tr-level-4 td:nth-child(2) {
  padding-left: 45px !important;
}
.highlighted-tr-level-5 {
  background: #f7f7f4 !important;
  font-family: 'Semibold', sans-serif !important;
}
.highlighted-tr-level-5 td:nth-child(1) {
  padding-left: 18px !important;
}
.highlighted-tr-level-5 td:nth-child(2) {
  padding-left: 55px !important;
}
.highlighted-tr-level-details {
  background: #f9f9f7 !important;
  font-family: 'Light', sans-serif !important;
}
.highlighted-tr-level-details td:nth-child(2) {
  padding-left: 5px !important;
}
.super-highlighted-tr {
  background: #eeeee8 !important;
}
.super-highlighted-tr td {
  background: #eeeee8 !important;
  border-right: 1px solid #e5e5dd !important;
  font-family: 'Semibold', sans-serif !important;
}
.super-highlighted-tr span {
  font-family: 'Semibold', sans-serif !important;
}
.mega-highlighted-tr {
  background: #e5e5dd !important;
}
.mega-highlighted-tr td {
  background: #e5e5dd !important;
  border-right: 1px solid #ddddd1 !important;
  font-family: 'Bold', sans-serif !important;
}
.mega-highlighted-tr span {
  font-family: 'Bold', sans-serif !important;
}
td.column-number.highlighted-tr-level-2 {
  padding-left: 10px !important;
}
td.column-number.highlighted-tr-level-3 {
  padding-left: 20px !important;
}
td.column-number.highlighted-tr-level-4 {
  padding-left: 25px !important;
}
td.column-number.highlighted-tr-level-5 {
  padding-left: 30px !important;
}
td.column-number.highlighted-tr-level-6 {
  padding-left: 35px !important;
}
.table-nowrap tr td {
  white-space: nowrap !important;
  word-break: break-word !important;
}
.passports-table td.td-left-align {
  text-align: left !important;
}
.passports-table td.td-right-align {
  text-align: right !important;
}
.passports-table td.td-center-align {
  text-align: center !important;
}
.passports-table h6 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.passports-table div.highlighted {
  border-left: 6px solid #1d82c7;
  margin-bottom: 10px;
  padding-left: 5px;
}
.passports-table .passports-wrapper-light-mode {
  padding: 5px;
}
.passports-table .passports-wrapper-light-mode .request-list {
  margin-right: 5px;
  float: left;
  border-radius: 12px !important;
  max-width: 220px !important;
  overflow: hidden;
  min-width: 220px;
}
.passports-table .contract-details-container-cut h2 {
  font-size: 14px !important;
  margin-bottom: 2px !important;
  margin-top: 0 !important;
  white-space: nowrap !important;
  font-family: 'Regular', sans-serif !important;
}
.passports-table .progress {
  display: inline-flex;
}
.passports-table .progress .progress-bar {
  padding: 2px !important;
}
.passports-table .well {
  margin-top: 5px;
}
.passports-table .table-inside-table {
  background: transparent !important;
  margin: 0 !important;
}
.passports-table .table-inside-table tr th {
  padding: 4px !important;
  border: 0 !important;
  font-size: calc(14px / 1.4) !important;
  background: transparent !important;
  text-align: left !important;
}
.passports-table .table-inside-table tr td {
  font-size: calc(14px / 1.3) !important;
  border: 0 !important;
  padding: 1px !important;
  white-space: nowrap !important;
  vertical-align: middle;
  word-break: keep-all !important;
}
.passports-table .table-inside-table tr td .label {
  font-size: calc(14px / 1.3) !important;
}
.passports-table .table-inside-table .highlighted-tr > td {
  background: rgba(0, 0, 0, 0.15) !important;
}
.passports-table .counterparty_detail_table tr td {
  border-bottom: 0 !important;
  word-break: break-word !important;
  white-space: normal !important;
  font-size: calc(14px / 1.3) !important;
  vertical-align: middle !important;
}
.passports-table .counterparty_detail_table tr td .label {
  font-size: calc(14px / 1.3) !important;
}
.passports-table .counterparty_detail_table tr td .progress {
  border-radius: 4px !important;
}
.passports-table .counterparty_detail_table tr.highlighted-tr td {
  text-align: center !important;
  padding: 6px !important;
  background: #f9f9f7 !important;
}
.passports-table .overflow-y-small .analytics-container {
  padding: 5px !important;
  background: transparent !important;
}
.passports-table .overflow-y-small .upcoming-updates-list,
.passports-table .overflow-y-small .add-new-inline {
  background: #fff !important;
}
.passports-table .overflow-y-small .status-blocks .contract-status-block {
  overflow: hidden;
  font-size: calc(14px / 1.3) !important;
}
.passports-table .overflow-y-small .status-blocks .contract-status-block::first-letter {
  text-transform: uppercase !important;
}
.passports-table .inside-table-ul {
  margin-bottom: 0;
  padding: 0;
}
.passports-table .inside-table-ul li {
  display: block;
  width: 100%;
  clear: both;
  height: 15px;
  padding: 2px;
  text-align: left;
  font-size: calc(14px / 1.3) !important;
}
.passports-table .inside-table-ul li .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.passports-table .inside-table-ul li.li-auto-height {
  height: auto;
}
.passports-table .inside-table-ul li:last-child {
  border-bottom: 0 !important;
}
.passports-table .ul-inside-table {
  margin-bottom: 0;
}
.passports-table .ul-inside-table li {
  display: block;
  width: 100%;
  height: 15px;
  padding: 2px;
  clear: both;
  font-size: calc(14px / 1.3) !important;
}
.passports-table .ul-inside-table li .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.passports-table .ul-inside-table li:last-child {
  border-bottom: 0 !important;
}
.passports-table .ul-inside-table li.li-auto-height {
  height: auto;
}
.passports-table tr th {
  padding: 2px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  height: 50px !important;
  text-align: center !important;
}
.passports-table tr td {
  padding: 2px !important;
  vertical-align: top !important;
  text-align: left;
  font-size: 14px !important;
  border-bottom: 3px solid #d0cbcb !important;
}
.passports-table tr td .alert {
  padding: 4px;
  margin-bottom: 5px;
}
.passports-table tr td .alert .progress {
  float: none;
}
.passports-table tr td.alert-info {
  border-color: rgba(62, 162, 213, 0.19) !important;
}
.passports-table tr td.alert-warning {
  border-color: rgba(181, 146, 96, 0.4) !important;
}
.passports-table td.small-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.passports-table td.small-font-cell a,
.passports-table td.small-font-cell span,
.passports-table td.small-font-cell .label,
.passports-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.passports-table tr td.nowrap {
  white-space: nowrap !important;
}
.passports-table #passport-properties-costs {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
  min-height: 35px;
}
.passports-table #passport-properties-costs .start-page-header,
.passports-table #passport-properties-costs .pull-left {
  text-align: left !important;
}
.passports-table #passport-properties-costs table {
  width: 100%;
  display: inline-table;
}
.passports-table #passport-properties-costs th {
  font-size: calc(14px / 1.3) !important;
  border-bottom: 2px solid #e8e6e6;
  color: #000000;
  text-align: right;
}
.passports-table #passport-properties-costs td {
  padding: 2px !important;
  font-size: 14px !important;
  text-align: right;
}
.passports-table #passport-properties-costs td:first-child {
  color: #685438 !important;
  font-family: 'Bold', sans-serif !important;
  text-transform: uppercase;
}
.passports-table #passport-properties-costs th:first-child,
.passports-table #passport-properties-costs td:first-child {
  text-align: left;
}
.passports-table #passport-properties-costs tr:last-child td {
  border-bottom: 0 !important;
}
.passports-table #passport-properties-result {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
}
.passports-table #passport-properties-result .start-page-header,
.passports-table #passport-properties-result .pull-left {
  text-align: left !important;
}
.passports-table #passport-properties-result table {
  width: 100% !important;
}
.passports-table #passport-properties-result th {
  font-size: calc(14px / 1.3) !important;
  border-bottom: 2px solid #e8e6e6;
  color: #000000;
  text-align: right;
}
.passports-table #passport-properties-result td {
  padding: 2px;
  text-align: right;
}
.passports-table #passport-properties-result td:first-child {
  color: #685438 !important;
  font-family: 'Bold', sans-serif !important;
  text-transform: uppercase;
}
.passports-table #passport-properties-result th:first-child,
.passports-table #passport-properties-result td:first-child {
  text-align: left;
}
.passports-table #passport-properties-result tr:last-child td {
  border-bottom: 0 !important;
}
.passports-table #passport-properties-result .highlighted-td {
  font-family: 'Bold', sans-serif !important;
}
.passports-table .passport-header-badges .label {
  margin-left: 2px;
  padding: 2px !important;
}
.passports-table .passport_executed > td:first-of-type {
  border-left: 5px solid rgba(120, 123, 132, 0.2) !important;
  background: #fff !important;
  color: #000000 !important;
}
.passports-table .passport_executed > td:first-of-type a {
  color: #000000 !important;
}
.passports-table .passport_executed > td:first-of-type .label,
.passports-table .passport_executed > td:first-of-type .badge {
  background-color: rgba(120, 123, 132, 0.2) !important;
  color: #595b63 !important;
}
.passports-table .passport_executed > td:first-of-type .label a,
.passports-table .passport_executed > td:first-of-type .badge a {
  color: #595b63 !important;
}
.passports-table .passport_executed > td:first-of-type .label a.btn-primary,
.passports-table .passport_executed > td:first-of-type .badge a.btn-primary {
  color: #595b63 !important;
}
.passports-table .passport_executed > td:first-of-type .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.passports-table .passport_executed > td:first-of-type .label_danger a {
  color: #fff !important;
}
.passports-table .passport_executed > td:first-of-type .label_danger span {
  color: #fff !important;
}
.passports-table .passport_new > td:first-of-type {
  border-left: 5px solid #0c4d40 !important;
  background: #ebf1ef !important;
  color: #0c4d40 !important;
}
.passports-table .passport_new > td:first-of-type a {
  color: #0c4d40 !important;
}
.passports-table .passport_new > td:first-of-type .label,
.passports-table .passport_new > td:first-of-type .badge {
  background-color: rgba(28, 179, 148, 0.4) !important;
  color: #07422d !important;
}
.passports-table .passport_new > td:first-of-type .label a,
.passports-table .passport_new > td:first-of-type .badge a {
  color: #07422d !important;
}
.passports-table .passport_new > td:first-of-type .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.passports-table .passport_new > td:first-of-type .label_danger a {
  color: #fff !important;
}
.passports-table .passport_new > td:first-of-type .label_danger span {
  color: #fff !important;
}
.passports-table .passport_new > td:first-of-type .dropdown-menu a {
  color: #000000 !important;
}
.passports-table .passport_processing > td:first-of-type {
  border-left: 5px solid #685438 !important;
  background: #fff !important;
  color: #685438 !important;
}
.passports-table .passport_processing > td:first-of-type a {
  color: #685438 !important;
}
.passports-table .passport_processing > td:first-of-type .label,
.passports-table .passport_processing > td:first-of-type .badge {
  background-color: rgba(181, 146, 96, 0.4) !important;
  color: #685438 !important;
}
.passports-table .passport_processing > td:first-of-type .label a,
.passports-table .passport_processing > td:first-of-type .badge a {
  color: #685438 !important;
}
.passports-table .passport_processing > td:first-of-type .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.passports-table .passport_processing > td:first-of-type .label_danger a {
  color: #fff !important;
}
.passports-table .passport_processing > td:first-of-type .label_danger span {
  color: #fff !important;
}
.passports-table .passport_processing > td:first-of-type .dropdown-menu a {
  color: #000000 !important;
}
.executed_payment,
.contract_executed {
  border-top-color: #595b63 !important;
}
.executed_payment .value,
.contract_executed .value {
  color: #000000 !important;
}
.new_payment,
.contract_new {
  border-top-color: #07422d !important;
}
.new_payment .value,
.contract_new .value {
  color: #000000 !important;
}
.process_payment,
.contract_processing {
  border-top-color: #685438 !important;
}
.process_payment .value,
.contract_processing .value {
  color: #000000 !important;
}
.contract_cancelled,
.passport_cancelled {
  border-top-color: #595b63 !important;
}
.contract_cancelled a,
.passport_cancelled a {
  color: #000000 !important;
}
.contract_cancelled .value,
.passport_cancelled .value {
  color: #000000 !important;
}
.passport_approved:first-of-type > td:first-of-type {
  border-left: 5px solid #6d6a6f !important;
  background: #f0ede9 !important;
}
.passport_approved:first-of-type > td:first-of-type a {
  color: #414043 !important;
}
.passport_approved:first-of-type > td:first-of-type .label,
.passport_approved:first-of-type > td:first-of-type .badge {
  background-color: rgba(135, 86, 191, 0.2) !important;
  color: #6d6a6f !important;
}
.passport_approved:first-of-type > td:first-of-type .label a,
.passport_approved:first-of-type > td:first-of-type .badge a {
  color: #6d6a6f !important;
}
.passport_approved:first-of-type > td:first-of-type .label_danger {
  background-color: rgba(207, 97, 99, 0.7) !important;
  color: #fff !important;
}
.passport_approved:first-of-type > td:first-of-type .label_danger a {
  color: #fff !important;
}
.passport_approved:first-of-type > td:first-of-type .label_danger span {
  color: #fff !important;
}
.passport-crop-filter .nav-tabs {
  width: 100%;
  border-bottom: 0;
}
.passport-crop-filter .nav-tabs li {
  display: table-cell;
  width: 1%;
  float: none;
}
.passport-crop-filter .col-sm-6.center-block {
  width: 100% !important;
}
.contract-details-container {
  padding: 0;
  border-radius: 0;
  margin-bottom: 0;
}
.contract-details-container small {
  margin-top: 0 !important;
  font-family: 'Light', sans-serif !important;
}
.contract-details-container small .contract_details {
  margin-bottom: 2px;
  margin-right: 2px;
  float: left;
  white-space: nowrap;
  padding: 2px !important;
}
.contract-details-container small .contract-description-label {
  margin-bottom: 0px;
  font-size: calc(14px / 1.3) !important;
  padding: 6px;
}
.contract-details-container h2 {
  font-size: 22px !important;
  margin-bottom: 2px !important;
  margin-top: 0 !important;
}
.contract-details-container .updated_time {
  background: transparent !important;
  color: #000000 !important;
}
.contract-details-container .label-details {
  font-size: calc(14px / 1.3) !important;
  box-shadow: 0px 0px 1px #000;
  background: transparent !important;
  color: #000000;
  margin-left: 1px;
}
.contract-details-table tr td {
  padding: 2px !important;
  font-size: 14px !important;
  white-space: normal;
  word-break: break-word;
  border: 0 !important;
  line-height: 1;
  text-align: right;
}
.contract-details-table tr td .label {
  float: right !important;
  padding: 2px !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  width: auto !important;
}
.contract-details-table tr td .btn-link {
  font-size: 14px !important;
}
.contract-details-table tr td .btn-group-vertical > .btn,
.contract-details-table tr td .btn-group > .btn {
  padding: 2px 2px;
  font-size: calc(14px / 1.3) !important;
}
.contract-details-table tr td a {
  cursor: pointer;
}
.contract-details-table td:first-child {
  font-size: 14px !important;
  text-align: left;
  white-space: nowrap;
  text-transform: uppercase !important;
  vertical-align: middle;
  font-family: 'Semibold', sans-serif !important;
}
.contract-details-table tr:first-child td {
  border-top: 0 !important;
}
.contract-details-table tr:first-child td .label {
  float: none !important;
}
table.basis-port-prices-table tr td,
table.basis-port-prices-table tr th {
  padding: 2px !important;
  font-size: calc(14px / 1.4) !important;
  height: 10px !important;
  border-right: 0 !important;
  text-align: left !important;
  white-space: nowrap;
  word-wrap: break-word;
  -webkit-text-stroke: 0;
}
table.basis-port-prices-table tr td .form-control .ui-select-match-text,
table.basis-port-prices-table tr th .form-control .ui-select-match-text {
  min-height: auto !important;
  font-size: calc(14px / 1.2) !important;
}
table.basis-port-prices-table tbody {
  box-shadow: none !important;
}
table.basis-port-prices-table tr td {
  background: rgba(0, 0, 0, 0.01) !important;
}
table.basis-port-prices-table thead > tr > th:first-child,
table.basis-port-prices-table tbody > tr > td:first-child {
  display: table-cell !important;
  position: relative !important;
  height: 10px !important;
}
contract-prices-info table.basis-port-prices-table tr td,
contract-prices-info table.basis-port-prices-table tr th {
  white-space: normal;
}
.item-details-block {
  width: 100%;
  display: -webkit-box;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  display: -moz-box;
  -moz-box-pack: justify;
  -moz-box-align: center;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  display: box;
  box-pack: justify;
  box-align: center;
  background: rgba(0, 0, 0, 0.01);
  margin-top: 5px;
  line-height: 1 !important;
}
.item-details-block .table-no-borders tr td {
  font-size: calc(14px / 1.3) !important;
}
.item-details-block .table-no-borders tr td h4 {
  font-size: 14px !important;
}
.item-details-block .item-details-el {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 4px !important;
}
.item-details-block .item-details-el .value {
  font-size: 14px;
  color: #000000;
  font-family: 'Regular', sans-serif !important;
  padding: 0 !important;
}
.item-details-block .item-details-el .value .label {
  font-family: 'Regular', sans-serif !important;
}
.item-details-block .item-details-el .property {
  color: #000000;
  font-size: calc(14px / 1.3);
  text-transform: lowercase;
  line-height: 1 !important;
  font-family: 'Light', sans-serif !important;
}
.item-details-block .item-details-el .label,
.item-details-block .item-details-el .badge {
  font-size: 14px;
  line-height: 1 !important;
  padding: 2px !important;
}
.item-details-block .item-details-el span {
  line-height: 1 !important;
}
.item-details-block-transparent {
  width: 100%;
  display: -webkit-box;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  display: -moz-box;
  -moz-box-pack: justify;
  -moz-box-align: center;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  display: box;
  box-pack: justify;
  box-align: center;
  background: rgba(0, 0, 0, 0.01);
  margin-top: 5px;
  line-height: 1 !important;
  background: transparent !important;
}
.item-details-block-transparent .table-no-borders tr td {
  font-size: calc(14px / 1.3) !important;
}
.item-details-block-transparent .table-no-borders tr td h4 {
  font-size: 14px !important;
}
.item-details-block-transparent .item-details-el {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 4px !important;
}
.item-details-block-transparent .item-details-el .value {
  font-size: 14px;
  color: #000000;
  font-family: 'Regular', sans-serif !important;
  padding: 0 !important;
}
.item-details-block-transparent .item-details-el .value .label {
  font-family: 'Regular', sans-serif !important;
}
.item-details-block-transparent .item-details-el .property {
  color: #000000;
  font-size: calc(14px / 1.3);
  text-transform: lowercase;
  line-height: 1 !important;
  font-family: 'Light', sans-serif !important;
}
.item-details-block-transparent .item-details-el .label,
.item-details-block-transparent .item-details-el .badge {
  font-size: 14px;
  line-height: 1 !important;
  padding: 2px !important;
}
.item-details-block-transparent .item-details-el span {
  line-height: 1 !important;
}
.item-details-block-justified {
  width: 100%;
  display: -webkit-box;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  display: -moz-box;
  -moz-box-pack: justify;
  -moz-box-align: center;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  display: box;
  box-pack: justify;
  box-align: center;
  background: rgba(0, 0, 0, 0.01);
  margin-top: 5px;
  line-height: 1 !important;
  display: block !important;
}
.item-details-block-justified .table-no-borders tr td {
  font-size: calc(14px / 1.3) !important;
}
.item-details-block-justified .table-no-borders tr td h4 {
  font-size: 14px !important;
}
.item-details-block-justified .item-details-el {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 4px !important;
}
.item-details-block-justified .item-details-el .value {
  font-size: 14px;
  color: #000000;
  font-family: 'Regular', sans-serif !important;
  padding: 0 !important;
}
.item-details-block-justified .item-details-el .value .label {
  font-family: 'Regular', sans-serif !important;
}
.item-details-block-justified .item-details-el .property {
  color: #000000;
  font-size: calc(14px / 1.3);
  text-transform: lowercase;
  line-height: 1 !important;
  font-family: 'Light', sans-serif !important;
}
.item-details-block-justified .item-details-el .label,
.item-details-block-justified .item-details-el .badge {
  font-size: 14px;
  line-height: 1 !important;
  padding: 2px !important;
}
.item-details-block-justified .item-details-el span {
  line-height: 1 !important;
}
.item-details-block-justified .item-details-el {
  width: 100%;
  display: block;
  padding: 5px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px !important;
}
.item-details-block-justified .item-details-el .label {
  float: none !important;
}
.item-details-block-justified .item-details-el-inline {
  display: inline-block;
  width: 25%;
}
.related-page .contract-details-container-cut .btn-link {
  font-size: 0.7em !important;
}
.passports-report-table tr th {
  text-align: center !important;
}
.passports-report-table tr th:nth-child(2) {
  background: transparent;
}
.passports-report-table tr th:nth-child(1) {
  background: transparent !important;
}
.passports-report-table tr td:nth-child(1) {
  background: transparent !important;
}
.passports-report-table tr td:nth-child(1).highlighted-td {
  background: #f9f9f7 !important;
}
.passports-report-table tr td {
  border-right: 1px solid #e8e6e6 !important;
}
.passports-report-table td.highlighted-td {
  background: #f9f9f7 !important;
}
.passports-report-table tr td:nth-child(2) {
  background: transparent;
}
.passports-report-table tr td:nth-child(2) .inside-table-ul li {
  text-align: left !important;
}
.passports-report-table tr td:nth-child(2) .inside-table-ul li span:first-child {
  float: none !important;
}
.passports-report-table tr td:nth-child(2) .inside-table-ul li span:last-child {
  float: none !important;
}
.contract-plans-ul li {
  white-space: nowrap !important;
}
.contract-plans-ul li span {
  white-space: nowrap !important;
}
.contracts-report-table tr th,
.tickets-report-table tr th,
.indicators-report-table tr th {
  text-align: center !important;
}
.contracts-report-table tr th:nth-child(2),
.tickets-report-table tr th:nth-child(2),
.indicators-report-table tr th:nth-child(2) {
  background: transparent;
}
.contracts-report-table tr th:nth-child(1),
.tickets-report-table tr th:nth-child(1),
.indicators-report-table tr th:nth-child(1) {
  background: transparent !important;
}
.contracts-report-table tr td:nth-child(1),
.tickets-report-table tr td:nth-child(1),
.indicators-report-table tr td:nth-child(1) {
  background: transparent !important;
}
.contracts-report-table tr td:nth-child(1).highlighted-td,
.tickets-report-table tr td:nth-child(1).highlighted-td,
.indicators-report-table tr td:nth-child(1).highlighted-td {
  background: #f9f9f7 !important;
}
.contracts-report-table tr td,
.tickets-report-table tr td,
.indicators-report-table tr td {
  border-right: 1px solid #e8e6e6 !important;
}
.contracts-report-table td.highlighted-td,
.tickets-report-table td.highlighted-td,
.indicators-report-table td.highlighted-td {
  background: #f9f9f7 !important;
}
.contracts-report-table tr td:nth-child(2),
.tickets-report-table tr td:nth-child(2),
.indicators-report-table tr td:nth-child(2) {
  background: transparent;
}
.contracts-report-table tr td:nth-child(2) .inside-table-ul li,
.tickets-report-table tr td:nth-child(2) .inside-table-ul li,
.indicators-report-table tr td:nth-child(2) .inside-table-ul li {
  text-align: left !important;
}
.contracts-report-table tr td:nth-child(2) .inside-table-ul li span:first-child,
.tickets-report-table tr td:nth-child(2) .inside-table-ul li span:first-child,
.indicators-report-table tr td:nth-child(2) .inside-table-ul li span:first-child {
  float: none !important;
}
.contracts-report-table tr td:nth-child(2) .inside-table-ul li span:last-child,
.tickets-report-table tr td:nth-child(2) .inside-table-ul li span:last-child,
.indicators-report-table tr td:nth-child(2) .inside-table-ul li span:last-child {
  float: none !important;
}
.contracts-report-table .inside-table-ul li,
.tickets-report-table .inside-table-ul li,
.indicators-report-table .inside-table-ul li {
  text-align: center !important;
}
.contracts-report-table .inside-table-ul li span:first-child,
.tickets-report-table .inside-table-ul li span:first-child,
.indicators-report-table .inside-table-ul li span:first-child {
  float: none !important;
}
.contracts-report-table .inside-table-ul li span:last-child,
.tickets-report-table .inside-table-ul li span:last-child,
.indicators-report-table .inside-table-ul li span:last-child {
  float: none !important;
}
.prices-position-inline-container .row div {
  padding-left: 2px;
  padding-right: 2px;
}
.position-report-table tr th {
  text-align: center !important;
}
.position-report-table tr th:nth-child(2) {
  background: transparent;
}
.position-report-table tr th:nth-child(1) {
  background: transparent !important;
}
.position-report-table tr td:nth-child(1) {
  background: transparent !important;
}
.position-report-table tr td:nth-child(1).highlighted-td {
  background: #f9f9f7 !important;
}
.position-report-table tr td {
  border-right: 1px solid #e8e6e6 !important;
}
.position-report-table td.highlighted-td {
  background: #f9f9f7 !important;
}
.position-report-table tr td:nth-child(2) {
  background: transparent;
}
.position-report-table tr td:nth-child(2) .inside-table-ul li {
  text-align: left !important;
}
.position-report-table tr td:nth-child(2) .inside-table-ul li span:first-child {
  float: none !important;
}
.position-report-table tr td:nth-child(2) .inside-table-ul li span:last-child {
  float: none !important;
}
.position-report-table .inside-table-ul li {
  text-align: center !important;
}
.position-report-table .inside-table-ul li span:first-child {
  float: none !important;
}
.position-report-table .inside-table-ul li span:last-child {
  float: none !important;
}
.position-report-table li {
  white-space: nowrap;
}
.position-report-table .label {
  display: initial !important;
}
.position-consolidated-table tr td {
  white-space: nowrap !important;
}
.position-consolidated-table tr td .label,
.position-consolidated-table tr td .label-strong {
  display: inline-flex !important;
}
.position-consolidated-table tr td:first-child {
  width: auto !important;
}
.page-break-el {
  page-break-before: always;
  page-break-after: always;
}
.position-passport-table tbody + tbody {
  border-top: 3px solid #ebebeb !important;
  box-shadow: none !important;
}
.position-passport-table tr th {
  padding: 0 !important;
}
.position-passport-table tr td {
  text-align: right !important;
  vertical-align: top !important;
  font-size: calc(14px / 1.1) !important;
  border-right: 1px solid #e8e6e6 !important;
  padding: 2px !important;
  white-space: nowrap !important;
}
.position-passport-table tr td .label {
  font-size: calc(14px / 1.1) !important;
  display: inline-flex;
}
.position-passport-table tr td .badge {
  font-size: calc(14px / 1.4) !important;
}
.position-passport-table tr td .bigger-label {
  font-size: 14px !important;
}
.position-passport-table tr td .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.position-passport-table tr td .fa {
  font-size: calc(14px / 1.4) !important;
}
.position-passport-table tr td .inside-table-ul {
  margin-bottom: 0 !important;
}
.position-passport-table tr td .alert {
  display: block;
  margin: 0;
  margin-top: 2px;
  padding: 2px;
}
.position-passport-table tr td.td-left-align {
  text-align: left !important;
}
.position-passport-table tr td.td-left-align .label {
  text-align: left !important;
}
.position-passport-table td.smaller-font-td {
  font-size: calc(14px / 1.3) !important;
}
.position-passport-table tbody:nth-last-child(1) .highlighted-td {
  background: #eeedeb !important;
}
.position-passport-table td.no-wrap {
  white-space: nowrap;
  min-width: 80px;
}
.position-passport-table tr td:nth-child(1) {
  color: #000000 !important;
  text-align: left !important;
}
.position-passport-table tr td:nth-child(1) .btn-link {
  text-transform: initial !important;
  color: #1763ad !important;
}
.position-passport-table tr td:nth-child(1) .btn-link:hover {
  text-decoration: underline;
}
.position-passport-table .progress .progress-bar {
  font-size: 14px !important;
}
.position-passport-table tr td:nth-child(1).td-right-align {
  text-align: right !important;
}
.position-passport-table tr td:nth-last-child(1),
.position-passport-table tr td:nth-last-child(2),
.position-passport-table tr td:nth-last-child(3),
.position-passport-table tr td:nth-last-child(5),
.position-passport-table tr td:nth-last-child(6),
.position-passport-table tr td:nth-last-child(7),
.position-passport-table tr td:nth-last-child(8) {
  white-space: nowrap !important;
}
.position-passport-table .highlighted-tr {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
}
.position-passport-table .highlighted-tr td,
.position-passport-table .highlighted-tr span {
  font-family: 'Bold', sans-serif !important;
}
table.inside-table-table tbody > tr > td,
table.inside-table-table thead > tr > th {
  padding: 2px !important;
  border: 0 !important;
  vertical-align: middle !important;
}
.operations-table tr > td {
  white-space: normal !important;
}
.purchase-position-table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.purchase-position-table tr td .bigger-label {
  font-size: 14px !important;
}
.purchase-position-table tr td.smaller-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.purchase-position-table td.highlighted-td {
  background: rgba(0, 0, 0, 0.03) !important;
  border: 0 !important;
}
.purchase-position-table tbody + tbody {
  border-top: 0 !important;
}
.purchase-position-table .purchase-ctr-row {
  border-top: 3px solid #e8e6e6 !important;
}
.position-crop-table tbody + tbody {
  border-top: 3px solid #ebebeb !important;
  box-shadow: none !important;
}
.position-crop-table tr th {
  padding: 0 !important;
}
.position-crop-table tr td {
  text-align: right !important;
  vertical-align: top !important;
  font-size: calc(14px / 1.1) !important;
  border-right: 1px solid #e8e6e6 !important;
  padding: 2px !important;
  white-space: nowrap !important;
}
.position-crop-table tr td .label {
  font-size: calc(14px / 1.1) !important;
  display: inline-flex;
}
.position-crop-table tr td .badge {
  font-size: calc(14px / 1.4) !important;
}
.position-crop-table tr td .bigger-label {
  font-size: 14px !important;
}
.position-crop-table tr td .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.position-crop-table tr td .fa {
  font-size: calc(14px / 1.4) !important;
}
.position-crop-table tr td .inside-table-ul {
  margin-bottom: 0 !important;
}
.position-crop-table tr td .alert {
  display: block;
  margin: 0;
  margin-top: 2px;
  padding: 2px;
}
.position-crop-table tr td.td-left-align {
  text-align: left !important;
}
.position-crop-table tr td.td-left-align .label {
  text-align: left !important;
}
.position-crop-table td.smaller-font-td {
  font-size: calc(14px / 1.3) !important;
}
.position-crop-table tbody:nth-last-child(1) .highlighted-td {
  background: #eeedeb !important;
}
.position-crop-table td.no-wrap {
  white-space: nowrap;
  min-width: 80px;
}
.position-crop-table tr td:nth-child(1) {
  color: #000000 !important;
  text-align: left !important;
}
.position-crop-table tr td:nth-child(1) .btn-link {
  text-transform: initial !important;
  color: #1763ad !important;
}
.position-crop-table tr td:nth-child(1) .btn-link:hover {
  text-decoration: underline;
}
.position-crop-table .progress .progress-bar {
  font-size: 14px !important;
}
.position-crop-table tr td:nth-child(1).td-right-align {
  text-align: right !important;
}
.position-crop-table tr td:nth-last-child(1),
.position-crop-table tr td:nth-last-child(2),
.position-crop-table tr td:nth-last-child(3),
.position-crop-table tr td:nth-last-child(5),
.position-crop-table tr td:nth-last-child(6),
.position-crop-table tr td:nth-last-child(7),
.position-crop-table tr td:nth-last-child(8) {
  white-space: nowrap !important;
}
.position-crop-table .highlighted-tr {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
}
.position-crop-table .highlighted-tr td,
.position-crop-table .highlighted-tr span {
  font-family: 'Bold', sans-serif !important;
}
.position-crop-table tbody + tbody {
  border-top: 0 !important;
}
.position-crop-table tr td {
  padding: 6px !important;
}
.position-crop-table tr td .bigger-label {
  font-size: calc(14px * 1.2) !important;
  font-family: 'Semibold', sans-serif !important;
}
.position-crop-table tr th {
  padding: 6px !important;
}
.position-crop-table tr td:nth-child(1) {
  border-bottom: none !important;
  text-align: left !important;
  font-family: 'Semibold', sans-serif !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
}
.position-crop-table .inside-table-ul {
  margin-bottom: 0 !important;
}
.position-crop-table .inside-table-ul li {
  border: 0 !important;
}
.position-crop-table .inside-table-ul li .badge {
  font-size: calc(14px / 1.3);
}
.position-crop-table .alert-success .label {
  color: #07422d !important;
}
.position-crop-table .alert-danger .label {
  color: #cf6163 !important;
}
.lot-batches-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.lot-batches-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.lot-batches-table .datetimepicker thead tr th:nth-child(1),
.lot-batches-table .datetimepicker thead tr th:nth-child(2),
.lot-batches-table .datetimepicker thead tr th:nth-child(3),
.lot-batches-table .datetimepicker tbody tr td:nth-child(1),
.lot-batches-table .datetimepicker tbody tr td:nth-child(2),
.lot-batches-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.lot-batches-table .datetimepicker .table thead tr th:nth-child(6),
.lot-batches-table .datetimepicker .table thead tr th:nth-child(7),
.lot-batches-table .datetimepicker .table tbody tr td:nth-child(6),
.lot-batches-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.lot-batches-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.lot-batches-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.lot-batches-table table tr:first-child td {
  background: #ebf1ef !important;
  color: #07422d !important;
}
.lot-batches-table table tr:first-child td .filter-template {
  background: #ebf1ef !important;
  border-right: 0 !important;
}
.lot-batches-table table tr:first-child td .filter-template .btn {
  border-right: 0 !important;
}
.lot-batches-table table tr:first-child td .filter-template .btn-primary {
  background: #1bb394 !important;
  color: #fff !important;
}
.lot-batches-table table tr:first-child td .form-control {
  border-color: #1bb394 !important;
  background: #ebf1ef !important;
  margin-bottom: 4px;
}
.lot-batches-table table tr:first-child td .input-group-addon {
  background: #1bb394 !important;
  color: #fff !important;
}
.lot-batches-table table tr:first-child td td.edit,
.lot-batches-table table tr:first-child td .edit {
  background: #ebf1ef !important;
  color: #07422d !important;
}
.lot-batches-table table tr:first-child td .label-strong {
  color: #07422d;
}
.lot-batches-table table tr:first-child td .ui-select-container .form-control {
  border-color: #1bb394 !important;
  background: #ebf1ef !important;
}
.lot-batches-table table tr:first-child td h1,
.lot-batches-table table tr:first-child td h2,
.lot-batches-table table tr:first-child td h5,
.lot-batches-table table tr:first-child td h3,
.lot-batches-table table tr:first-child td h4,
.lot-batches-table table tr:first-child td h6,
.lot-batches-table table tr:first-child td small {
  margin-top: 0;
  margin-bottom: 0;
  color: #07422d !important;
}
.lot-batches-table table tr td {
  border-right: 1px solid #e8e6e6 !important;
}
.futures-position-table tbody + tbody {
  border-top: 3px solid #ebebeb !important;
  box-shadow: none !important;
}
.futures-position-table tr th {
  padding: 0 !important;
}
.futures-position-table tr td {
  text-align: right !important;
  vertical-align: top !important;
  font-size: calc(14px / 1.1) !important;
  border-right: 1px solid #e8e6e6 !important;
  padding: 2px !important;
  white-space: nowrap !important;
}
.futures-position-table tr td .label {
  font-size: calc(14px / 1.1) !important;
  display: inline-flex;
}
.futures-position-table tr td .badge {
  font-size: calc(14px / 1.4) !important;
}
.futures-position-table tr td .bigger-label {
  font-size: 14px !important;
}
.futures-position-table tr td .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.futures-position-table tr td .fa {
  font-size: calc(14px / 1.4) !important;
}
.futures-position-table tr td .inside-table-ul {
  margin-bottom: 0 !important;
}
.futures-position-table tr td .alert {
  display: block;
  margin: 0;
  margin-top: 2px;
  padding: 2px;
}
.futures-position-table tr td.td-left-align {
  text-align: left !important;
}
.futures-position-table tr td.td-left-align .label {
  text-align: left !important;
}
.futures-position-table td.smaller-font-td {
  font-size: calc(14px / 1.3) !important;
}
.futures-position-table tbody:nth-last-child(1) .highlighted-td {
  background: #eeedeb !important;
}
.futures-position-table td.no-wrap {
  white-space: nowrap;
  min-width: 80px;
}
.futures-position-table tr td:nth-child(1) {
  color: #000000 !important;
  text-align: left !important;
}
.futures-position-table tr td:nth-child(1) .btn-link {
  text-transform: initial !important;
  color: #1763ad !important;
}
.futures-position-table tr td:nth-child(1) .btn-link:hover {
  text-decoration: underline;
}
.futures-position-table .progress .progress-bar {
  font-size: 14px !important;
}
.futures-position-table tr td:nth-child(1).td-right-align {
  text-align: right !important;
}
.futures-position-table tr td:nth-last-child(1),
.futures-position-table tr td:nth-last-child(2),
.futures-position-table tr td:nth-last-child(3),
.futures-position-table tr td:nth-last-child(5),
.futures-position-table tr td:nth-last-child(6),
.futures-position-table tr td:nth-last-child(7),
.futures-position-table tr td:nth-last-child(8) {
  white-space: nowrap !important;
}
.futures-position-table .highlighted-tr {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
}
.futures-position-table .highlighted-tr td,
.futures-position-table .highlighted-tr span {
  font-family: 'Bold', sans-serif !important;
}
.futures-position-table tr td {
  padding: 2px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  vertical-align: middle !important;
  font-size: calc(14px / 1.1) !important;
}
.futures-position-table tr td .bigger-label {
  font-size: calc(14px * 1.2) !important;
  font-family: 'Semibold', sans-serif !important;
}
.futures-position-table tr td .form-control {
  min-width: auto;
}
.futures-position-table tr td .form-control-small {
  min-width: auto;
  font-size: 14px !important;
  font-family: 'Regular', sans-serif !important;
  height: 12px !important;
}
.futures-position-table tr th {
  padding: 2px !important;
}
.futures-position-table tr.highlighted-tr td {
  background: #f9f9f7 !important;
}
.futures-position-table tr td:nth-child(1) {
  border-bottom: none !important;
  text-align: left !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: uppercase;
}
.futures-position-table .inside-table-ul {
  margin-bottom: 0 !important;
  background: #f9f9f7;
}
.futures-position-table .inside-table-ul li .badge {
  font-size: calc(14px / 1.3);
}
.futures-position-table .alert-success {
  padding: 2px !important;
}
.futures-position-table .alert-success .label {
  color: #07422d !important;
}
.futures-position-table .alert-danger {
  padding: 2px !important;
  margin: 0 !important;
}
.futures-position-table .alert-danger .label {
  color: #cf6163 !important;
}
.passport-position-crop-table tbody + tbody {
  border-top: 3px solid #ebebeb !important;
  box-shadow: none !important;
}
.passport-position-crop-table tr th {
  padding: 0 !important;
}
.passport-position-crop-table tr td {
  text-align: right !important;
  vertical-align: top !important;
  font-size: calc(14px / 1.1) !important;
  border-right: 1px solid #e8e6e6 !important;
  padding: 2px !important;
  white-space: nowrap !important;
}
.passport-position-crop-table tr td .label {
  font-size: calc(14px / 1.1) !important;
  display: inline-flex;
}
.passport-position-crop-table tr td .badge {
  font-size: calc(14px / 1.4) !important;
}
.passport-position-crop-table tr td .bigger-label {
  font-size: 14px !important;
}
.passport-position-crop-table tr td .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.passport-position-crop-table tr td .fa {
  font-size: calc(14px / 1.4) !important;
}
.passport-position-crop-table tr td .inside-table-ul {
  margin-bottom: 0 !important;
}
.passport-position-crop-table tr td .alert {
  display: block;
  margin: 0;
  margin-top: 2px;
  padding: 2px;
}
.passport-position-crop-table tr td.td-left-align {
  text-align: left !important;
}
.passport-position-crop-table tr td.td-left-align .label {
  text-align: left !important;
}
.passport-position-crop-table td.smaller-font-td {
  font-size: calc(14px / 1.3) !important;
}
.passport-position-crop-table tbody:nth-last-child(1) .highlighted-td {
  background: #eeedeb !important;
}
.passport-position-crop-table td.no-wrap {
  white-space: nowrap;
  min-width: 80px;
}
.passport-position-crop-table tr td:nth-child(1) {
  color: #000000 !important;
  text-align: left !important;
}
.passport-position-crop-table tr td:nth-child(1) .btn-link {
  text-transform: initial !important;
  color: #1763ad !important;
}
.passport-position-crop-table tr td:nth-child(1) .btn-link:hover {
  text-decoration: underline;
}
.passport-position-crop-table .progress .progress-bar {
  font-size: 14px !important;
}
.passport-position-crop-table tr td:nth-child(1).td-right-align {
  text-align: right !important;
}
.passport-position-crop-table tr td:nth-last-child(1),
.passport-position-crop-table tr td:nth-last-child(2),
.passport-position-crop-table tr td:nth-last-child(3),
.passport-position-crop-table tr td:nth-last-child(5),
.passport-position-crop-table tr td:nth-last-child(6),
.passport-position-crop-table tr td:nth-last-child(7),
.passport-position-crop-table tr td:nth-last-child(8) {
  white-space: nowrap !important;
}
.passport-position-crop-table .highlighted-tr {
  background: #f9f9f7 !important;
  font-family: 'Bold', sans-serif !important;
}
.passport-position-crop-table .highlighted-tr td,
.passport-position-crop-table .highlighted-tr span {
  font-family: 'Bold', sans-serif !important;
}
.passport-position-crop-table tbody + tbody {
  border-top: 0 !important;
}
.passport-position-crop-table .thick-bottom-border {
  border-bottom: 3px solid #e8e6e6 !important;
}
.passport-position-crop-table tr td .bigger-label {
  font-size: calc(14px * 1.2) !important;
  font-family: 'Semibold', sans-serif !important;
}
.passport-position-crop-table tr td .not-bold {
  font-family: 'Regular', sans-serif !important;
}
.passport-position-crop-table tr td .form-control {
  min-width: auto;
}
.passport-position-crop-table tr td .form-control-small {
  min-width: auto;
  font-size: 14px !important;
  font-family: 'Regular', sans-serif !important;
  height: 12px !important;
}
.passport-position-crop-table tr td h6 {
  margin-bottom: 5px !important;
  border-bottom: 1px solid #b0a9a9;
}
.passport-position-crop-table tr td:nth-child(1) {
  border-bottom: none !important;
  text-align: left !important;
  font-family: 'Semibold', sans-serif !important;
}
.passport-position-crop-table .inside-table-ul {
  margin-bottom: 0 !important;
}
.passport-position-crop-table .inside-table-ul li .badge {
  font-size: calc(14px / 1.3);
}
.passport-position-crop-table .alert-success .label {
  color: #07422d !important;
}
.passport-position-crop-table .alert-danger .label {
  color: #cf6163 !important;
}
.passport-position-prices-table tr td .form-control {
  font-size: calc(14px / 1.3) !important;
  padding: 2px !important;
  text-overflow: clip;
  min-width: auto !important;
  display: block !important;
  height: 12px !important;
  max-height: 12px !important;
  min-height: 12px !important;
  line-height: 1 !important;
}
.consolidated-position-crop-table h6 {
  margin-bottom: 5px !important;
  margin-top: 10px !important;
  text-align: left;
}
.consolidated-position-crop-table tr td:first-child {
  font-family: 'Regular', sans-serif !important;
  font-size: calc(14px / 1.1) !important;
  word-break: break-all;
}
.consolidated-position-crop-table tr th {
  padding: 2px !important;
}
.consolidated-position-crop-table tr td {
  font-size: calc(14px / 1.1) !important;
  padding: 2px !important;
}
.consolidated-position-crop-table tr td .alert-success,
.consolidated-position-crop-table tr td .alert-danger {
  white-space: nowrap;
  overflow: hidden;
}
.consolidated-position-crop-table tr td .alert-success .fa,
.consolidated-position-crop-table tr td .alert-danger .fa {
  font-size: calc(14px / 1.1) !important;
}
.consolidated-position-crop-table tr td span,
.consolidated-position-crop-table tr td a,
.consolidated-position-crop-table tr td i,
.consolidated-position-crop-table tr td .label {
  font-size: calc(14px / 1.1) !important;
}
.consolidated-position-crop-table tr td .label {
  padding: 2px !important;
  margin: 0;
}
.consolidated-position-crop-table tr td .smaller-label {
  font-size: calc(14px / 1.3) !important;
  padding: 2px !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.consolidated-position-crop-table tr td .inside-headers-big {
  font-size: calc(14px / 1.2) !important;
  background: #fff;
  padding: 4px !important;
  min-width: 150px;
  white-space: nowrap !important;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 5px;
  border-top: 2px dashed #c7c7b4 !important;
}
.consolidated-position-crop-table tr td .inside-headers-big .label {
  white-space: nowrap !important;
}
.consolidated-position-crop-table tr td .inside-headers-small {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Semibold', sans-serif !important;
  margin-bottom: 5px;
  padding-left: 5px;
  white-space: nowrap !important;
  text-align: left;
}
.consolidated-position-crop-table tr td .inside-headers-small .span-right-align {
  font-size: calc(14px / 1.3) !important;
  margin-top: 3px;
}
.consolidated-position-crop-table tr td .span-right-align {
  text-align: right !important;
  float: right;
  margin-left: 15px;
}
.consolidated-position-crop-table tr th.thick-border,
.consolidated-position-crop-table tr td.thick-border {
  border-right: 3px solid #e8e6e6 !important;
}
.consolidated-position-crop-table th.thick-highlighted,
.consolidated-position-crop-table td.thick-highlighted {
  background: #c7c7b4 !important;
}
.consolidated-position-crop-table .inside-table-ul li {
  text-align: left;
  font-size: calc(14px / 1.3);
  white-space: nowrap !important;
  padding-left: 10px;
  border-bottom: 1px dashed #bebea8 !important;
}
.consolidated-position-crop-table .inside-table-ul li:first-child {
  border-top: 0;
}
.consolidated-position-crop-table .inside-table-ul li:last-child {
  border-bottom: 0 !important;
}
.pnl-consolidated-table {
  table-layout: fixed;
}
.pnl-consolidated-table tr td {
  vertical-align: top !important;
}
.pnl-consolidated-table tr td ul {
  margin-bottom: 0;
}
.pnl-consolidated-table tr td .bigger-label {
  font-size: 14px !important;
  font-family: 'Bold', sans-serif !important;
}
.pnl-consolidated-table tr td .horizontal-divider {
  padding: 1px;
  margin: 2px;
  display: block;
  clear: both;
}
.pnl-consolidated-table .inside-table-ul > li {
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid #d2cece !important;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 0;
}
.pnl-consolidated-table .inside-table-ul > li:last-child {
  border-bottom: 0 !important;
}
.pnl-consolidated-table tr th {
  padding: 2px !important;
  font-size: 14px !important;
}
.pnl-consolidated-table tr td:first-child {
  padding-left: 6px !important;
  font-size: 14px !important;
  white-space: normal !important;
  vertical-align: top !important;
}
.pnl-consolidated-table .super-highlighted-tr {
  background: #eeeee8 !important;
}
.pnl-consolidated-table .super-highlighted-tr td {
  background: #eeeee8 !important;
  border-right: 1px solid #d4d4c5 !important;
  font-family: 'Semibold', sans-serif !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.pnl-consolidated-table .super-highlighted-tr span {
  font-family: 'Semibold', sans-serif !important;
}
.pnl-consolidated-table .mega-highlighted-tr {
  background: #e5e5dd !important;
}
.pnl-consolidated-table .mega-highlighted-tr td {
  background: #e5e5dd !important;
  border-right: 1px solid #c7c7b4 !important;
  font-family: 'Bold', sans-serif !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.pnl-consolidated-table .mega-highlighted-tr span {
  font-family: 'Bold', sans-serif !important;
}
.position-analytics-container .item-details-block-transparent .alert-success .label {
  color: #07422d !important;
  font-size: calc(14px * 1.5) !important;
}
.position-analytics-container .item-details-block-transparent .alert-success .property {
  color: #07422d !important;
}
.position-analytics-container .item-details-block-transparent .alert-danger .label {
  color: #cf6163 !important;
  font-size: calc(14px * 1.5) !important;
}
.position-analytics-container .item-details-block-transparent .alert-danger .property {
  color: #cf6163 !important;
}
.position-analytics-container .passport-position-crop-table tr td {
  font-size: 14px !important;
  border-right: 1px solid #d2cece !important;
  border-top: 1px solid #d2cece !important;
}
.position-analytics-container .passport-position-crop-table tr td .label {
  display: inline-flex;
}
.position-analytics-container .passport-position-crop-table tr th:last-child,
.position-analytics-container .passport-position-crop-table tr td:last-child {
  border-right: 0 !important;
}
.position-analytics-container .futures-position-table tr td {
  font-size: 14px !important;
  border-right: none !important;
}
.position-analytics-container .futures-position-table tr th:last-child,
.position-analytics-container .futures-position-table tr td:last-child {
  border-right: 0 !important;
}
.futures-table tr td,
.futures-table div {
  vertical-align: top !important;
}
.futures-table tr td .input-group .input-group-addon,
.futures-table div .input-group .input-group-addon {
  padding: 3px;
}
.futures-table tr td .inside-table-ul li,
.futures-table div .inside-table-ul li {
  text-align: left !important;
}
.futures-table tr td .ui-select-bootstrap > .ui-select-match > .btn,
.futures-table div .ui-select-bootstrap > .ui-select-match > .btn {
  border-radius: 0 !important;
  min-height: 20px !important;
  padding: 6px !important;
  margin-left: 0 !important;
}
.futures-table tr td .alert,
.futures-table div .alert {
  padding: 4px !important;
  margin: 0;
  margin-top: 5px;
}
.futures-table tr td .form-indicator,
.futures-table div .form-indicator {
  color: #1bb394 !important;
  font-size: calc(14px / 1.8) !important;
}
.futures-table tr td .label-input,
.futures-table div .label-input {
  display: block;
  margin-top: 10px;
}
.futures-table tr td .form-block,
.futures-table div .form-block {
  display: block;
}
.futures-table tr td .form-control,
.futures-table div .form-control {
  height: auto !important;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Regular', sans-serif, 'FontAwesome' !important;
  line-height: 1 !important;
  overflow: visible;
  white-space: normal;
  font-size: calc(14px / 1.2) !important;
  min-height: 20px !important;
  padding: 4px;
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid #b0a9a9 !important;
}
.futures-table tr td .form-control::-webkit-input-placeholder,
.futures-table div .form-control::-webkit-input-placeholder {
  font-size: calc(14px / 1.2) !important;
}
.futures-table tr td .form-control::-moz-placeholder,
.futures-table div .form-control::-moz-placeholder {
  font-size: calc(14px / 1.2) !important;
}
.futures-table tr td .form-control:-moz-placeholder,
.futures-table div .form-control:-moz-placeholder {
  font-size: calc(14px / 1.2) !important;
}
.futures-table tr td .form-control:-ms-input-placeholder,
.futures-table div .form-control:-ms-input-placeholder {
  font-size: calc(14px / 1.2) !important;
}
.futures-table tr td .form-control span,
.futures-table div .form-control span {
  font-size: calc(14px / 1.2);
}
.futures-table tr td .form-control .ui-select-match-text,
.futures-table div .form-control .ui-select-match-text {
  color: #000000 !important;
  font-family: 'Regular', sans-serif, 'FontAwesome' !important;
  word-break: break-word;
  font-size: calc(14px / 1.2) !important;
}
.futures-table tr td .form-control.transparent-form,
.futures-table div .form-control.transparent-form {
  background: #fff !important;
}
.futures-table tr td .form-control.narrow-textarea,
.futures-table div .form-control.narrow-textarea {
  text-align: left;
  border-radius: 6px !important;
  height: 30px !important;
  width: 50% !important;
  float: right;
}
.futures-table input,
.futures-table .form-control,
.futures-table .ui-select-match,
.futures-table .ui-select-search {
  font-size: calc(14px / 1.2) !important;
  text-align: left;
  vertical-align: middle !important;
  border-color: #b0a9a9 !important;
}
.futures-table input::-webkit-input-placeholder,
.futures-table .form-control::-webkit-input-placeholder,
.futures-table .ui-select-match::-webkit-input-placeholder,
.futures-table .ui-select-search::-webkit-input-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
.futures-table input::-moz-placeholder,
.futures-table .form-control::-moz-placeholder,
.futures-table .ui-select-match::-moz-placeholder,
.futures-table .ui-select-search::-moz-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
.futures-table input:-moz-placeholder,
.futures-table .form-control:-moz-placeholder,
.futures-table .ui-select-match:-moz-placeholder,
.futures-table .ui-select-search:-moz-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
.futures-table input:-ms-input-placeholder,
.futures-table .form-control:-ms-input-placeholder,
.futures-table .ui-select-match:-ms-input-placeholder,
.futures-table .ui-select-search:-ms-input-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
.futures-table input .text-muted,
.futures-table .form-control .text-muted,
.futures-table .ui-select-match .text-muted,
.futures-table .ui-select-search .text-muted {
  color: #000000 !important;
  font-size: calc(14px / 1.2) !important;
  font-family: 'Regular', sans-serif !important;
}
.futures-table input .caret,
.futures-table .form-control .caret,
.futures-table .ui-select-match .caret,
.futures-table .ui-select-search .caret {
  display: none;
}
.futures-table .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  font-size: calc(14px / 1.2) !important;
}
.futures-table .ui-select-match-text {
  margin: 0 !important;
}
.futures-table .input-group {
  margin-bottom: 2px;
}
.futures-table .form-control .ui-select-match-text {
  font-size: calc(14px / 1.2) !important;
}
.futures-table .ui-select-match .form-control {
  line-height: 1;
  font-size: calc(14px / 1.2) !important;
}
.futures-table tr td .ui-select-match-text {
  min-height: 1px !important;
  margin-top: 7px;
}
.futures-table tr td {
  font-size: calc(14px / 1.2) !important;
}
.futures-table tr td .label {
  font-size: calc(14px / 1.2) !important;
}
.futures-table tr td:first-child {
  font-family: 'Regular', sans-serif !important;
}
.position-analytics-container.ul-inline-row {
  box-shadow: none !important;
  background: rgba(0, 0, 0, 0.03);
  margin-right: 15px !important;
}
.position-analytics-container.ul-inline-row .ul-inline-row,
.position-analytics-container.ul-inline-row .analytics-container,
.position-analytics-container.ul-inline-row .table-container-wrapper,
.position-analytics-container.ul-inline-row .well {
  box-shadow: none !important;
}
.page-total-analytics-container {
  padding: 0 !important;
  border-radius: 4px !important;
}
.page-total-analytics-container .item-details-block-transparent {
  display: block !important;
  margin-top: 0 !important;
  padding: 4px;
  font-size: calc(14px / 1.1) !important;
}
.page-total-analytics-container .item-details-block-transparent .alert,
.page-total-analytics-container .item-details-block-transparent .alert-success,
.page-total-analytics-container .item-details-block-transparent .alert-danger {
  padding: 10px;
  font-size: calc(14px * 1.1) !important;
  box-shadow: none !important;
}
.page-total-analytics-container .item-details-block-transparent .alert .label,
.page-total-analytics-container .item-details-block-transparent .alert-success .label,
.page-total-analytics-container .item-details-block-transparent .alert-danger .label {
  font-size: calc(14px * 1.1) !important;
  line-height: 1 !important;
  padding: 0 !important;
}
.page-total-analytics-container .item-details-block-transparent .alert .property,
.page-total-analytics-container .item-details-block-transparent .alert-success .property,
.page-total-analytics-container .item-details-block-transparent .alert-danger .property {
  font-size: calc(14px / 1.3) !important;
  line-height: 1 !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.page-total-analytics-container .item-details-block-transparent .alert .property span,
.page-total-analytics-container .item-details-block-transparent .alert-success .property span,
.page-total-analytics-container .item-details-block-transparent .alert-danger .property span {
  line-height: 1 !important;
}
.page-total-analytics-container .item-details-block-transparent .alert-default {
  background: rgba(0, 0, 0, 0.03) !important;
  box-shadow: none !important;
}
.page-total-analytics-container .item-details-block-transparent .alert-default .ul-inline-row,
.page-total-analytics-container .item-details-block-transparent .alert-default .analytics-container,
.page-total-analytics-container .item-details-block-transparent .alert-default .table-container-wrapper,
.page-total-analytics-container .item-details-block-transparent .alert-default .well {
  box-shadow: none !important;
}
.page-total-analytics-container .item-details-block-transparent .horizontal-divider {
  margin-top: 5px;
  margin-bottom: 5px;
}
.page-total-analytics-container .item-details-el {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  margin-right: 10px;
}
.page-total-analytics-container-fixed-alert .item-details-block-transparent .alert {
  width: 30%;
  overflow: hidden;
}
.lineup-light-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.lineup-light-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.lineup-light-table .datetimepicker thead tr th:nth-child(1),
.lineup-light-table .datetimepicker thead tr th:nth-child(2),
.lineup-light-table .datetimepicker thead tr th:nth-child(3),
.lineup-light-table .datetimepicker tbody tr td:nth-child(1),
.lineup-light-table .datetimepicker tbody tr td:nth-child(2),
.lineup-light-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.lineup-light-table .datetimepicker .table thead tr th:nth-child(6),
.lineup-light-table .datetimepicker .table thead tr th:nth-child(7),
.lineup-light-table .datetimepicker .table tbody tr td:nth-child(6),
.lineup-light-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.lineup-light-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.lineup-light-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.lineup-light-table tr th {
  text-align: left;
}
.lineup-light-table tr td {
  text-align: left;
  vertical-align: middle !important;
  border-right: 1px solid #e8e6e6 !important;
}
.lineup-light-table tr td .label {
  font-size: 14px;
}
.lineup-light-table tr td .label-strong {
  white-space: normal;
}
.lineup-light-table tr td .fa {
  font-size: calc(14px / 1.2) !important;
}
.lineup-light-table tr td a .fa {
  font-size: 14px !important;
}
.lineup-light-table tr td div .form-contol {
  height: 24px !important;
  font-size: calc(14px / 1.3) !important;
}
.lineup-light-table tr td select {
  text-align: center;
  margin: 0 auto;
  width: 80% !important;
}
.lineup-light-table tr td.nowrap {
  white-space: nowrap;
  text-align: center;
}
.lineup-light-table td.action_addition {
  background: rgba(41, 141, 107, 0.11);
  color: #07422d;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(41, 141, 107, 0.11) !important;
  font-family: 'Bold', sans-serif !important;
}
.lineup-light-table td.action_process {
  background: rgba(229, 218, 203, 0.42);
  color: #7d6643;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(229, 218, 203, 0.42) !important;
  font-family: 'Bold', sans-serif !important;
}
.lineup-light-table td.action_executed {
  background: rgba(120, 123, 132, 0.13);
  color: #595b63;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(120, 123, 132, 0.13) !important;
}
.lineup-container {
  padding-left: 15px;
  padding-right: 15px;
}
.lineup-container thead th {
  font-size: calc(14px / 1.4) !important;
}
.lineup-container .lineup-table thead {
  display: none;
  float: left;
}
.lineup-container .lineup-table tbody {
  float: left;
}
.lineup-container:nth-of-type(1) .lineup-table thead {
  display: table-header-group;
}
.lineup-container:nth-of-type(1) .lineup-table thead th {
  font-size: calc(14px / 1.3) !important;
}
.lineup-table {
  margin-left: 189px !important;
}
.lineup-table thead tr th {
  text-align: center !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  line-height: 1 !important;
  overflow: hidden;
  white-space: nowrap;
  border-right: 1px solid #e8e6e6 !important;
  border-color: #e8e6e6 !important;
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important;
}
.lineup-table tbody tr td {
  vertical-align: middle !important;
  font-size: 14px !important;
  text-align: left;
  white-space: normal;
  word-break: break-all;
  padding: 2px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  line-height: 1 !important;
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important;
  border-right: 1px solid #dcd9d9 !important;
  border-top: 1px solid #dcd9d9 !important;
  background: #fff;
}
.lineup-table tbody tr td .label {
  display: inline-flex;
  padding: 2px !important;
  font-size: calc(14px / 1.3);
}
.lineup-table tbody tr td .clear-btn {
  top: 10px;
  right: -20px;
  position: relative;
}
.lineup-table tbody tr td .ui-select-bootstrap > .ui-select-match > .btn {
  height: 25px !important;
  font-size: 14px !important;
  padding: 2px !important;
  margin-top: 0 !important;
}
.lineup-table tbody tr td ul {
  padding: 0 !important;
  margin: 0 !important;
}
.lineup-table tbody tr td li span {
  white-space: normal;
}
.lineup-table tbody tr td .btn {
  padding: 2px;
  margin: 0;
  float: left;
  line-height: 1;
  font-size: calc(14px / 1.3) !important;
}
.lineup-table tbody tr td a {
  line-height: 1;
}
.lineup-table thead tr th:first-child,
.lineup-table tbody tr td:first-child {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important;
}
.lineup-table thead tr th:first-child span,
.lineup-table tbody tr td:first-child span {
  margin-top: 0 !important;
}
.lineup-table tr th:last-child,
.lineup-table tr td:last-child {
  border-right: 0 !important;
}
.lineup-table .label-strong,
.lineup-table .smaller-label {
  white-space: normal;
}
.lineup-table tr.highlight-new td {
  background: rgba(41, 141, 107, 0.11);
  color: #07422d;
  font-family: 'Bold', sans-serif !important;
}
.lineup-table tr.highlight-new td .highlight-new-label {
  background: rgba(41, 141, 107, 0.21);
  white-space: normal;
}
.lineup-table tr.highlight-process td {
  background: rgba(229, 218, 203, 0.42);
  color: #7d6643;
  font-family: 'Bold', sans-serif !important;
}
.lineup-table tr.highlight-process td .highlight-process-label {
  background: rgba(229, 218, 203, 0.62);
  white-space: normal;
}
.lineup-table tr.highlight-executed td {
  background: rgba(120, 123, 132, 0.13);
}
.lineup-table tr.highlight-executed td .highlight-executed-label {
  background: rgba(120, 123, 132, 0.63);
  white-space: normal;
}
.lineup-table tr.highlight-approved td {
  background: rgba(164, 149, 176, 0.22);
  color: #6d6a6f;
  font-family: 'Bold', sans-serif !important;
}
.lineup-table tr.highlight-approved td .highlight-approved-label {
  background: #a495b0;
  white-space: normal;
}
.lineup-table tr.highlight-cancelled td {
  background: rgba(207, 97, 99, 0.11);
  color: #cf6163;
  font-family: 'Bold', sans-serif !important;
}
.lineup-table tr.highlight-cancelled td .highlight-cancelled-label {
  background: rgba(207, 97, 99, 0.63);
  white-space: normal;
}
.lineup-table tr td .highlight-purchase-label,
.lineup-table tr td .highlight-sale-label {
  white-space: normal;
}
.highlight-new {
  background: rgba(41, 141, 107, 0.11) !important;
  color: #07422d;
  font-family: 'Bold', sans-serif !important;
}
.highlight-new .highlight-new-label {
  background: rgba(41, 141, 107, 0.21);
  white-space: normal;
}
.highlight-process {
  background: rgba(229, 218, 203, 0.42) !important;
  color: #7d6643;
  font-family: 'Bold', sans-serif !important;
}
.highlight-process .highlight-process-label {
  background: rgba(229, 218, 203, 0.62);
  white-space: normal;
}
.highlight-executed {
  background: rgba(120, 123, 132, 0.13) !important;
}
.highlight-executed .highlight-executed-label {
  background: rgba(120, 123, 132, 0.63);
  white-space: normal;
}
.highlight-approved {
  background: rgba(164, 149, 176, 0.22) !important;
  color: #6d6a6f;
  font-family: 'Bold', sans-serif !important;
}
.highlight-approved .highlight-approved-label {
  background: #a495b0;
  white-space: normal;
}
.highlight-cancelled {
  background: rgba(207, 97, 99, 0.11) !important;
  color: #cf6163;
  font-family: 'Bold', sans-serif !important;
}
.highlight-cancelled .highlight-cancelled-label {
  background: rgba(207, 97, 99, 0.63);
  white-space: normal;
}
.physical-position-table tr th:first-child {
  text-align: center !important;
}
.physical-position-table tr th {
  border-right: 1px solid #e8e6e6 !important;
}
.physical-position-table tr th:last-child {
  border-right: 0 !important;
}
.big-physical-position-table tr th {
  font-size: calc(14px / 1.2) !important;
  padding: 2px !important;
}
.big-physical-position-table tr td {
  padding: 2px !important;
  line-height: 1;
  vertical-align: middle !important;
}
.big-physical-position-table tr td .form-control {
  font-size: calc(14px / 1.3) !important;
  height: 20px !important;
  line-height: 1;
  min-width: 80px;
  max-width: 80px;
  padding: 2px !important;
}
.big-physical-position-table tr td .ui-select-bootstrap > .ui-select-match > .btn {
  line-height: 1 !important;
}
.big-physical-position-table tr td .input-group {
  display: block;
  width: auto;
  min-width: max-content;
}
.big-physical-position-table tr td .input-group .form-control {
  margin-left: 5px;
}
.big-physical-position-table tr td input[type='checkbox'] {
  min-width: 20px;
  max-width: 20px;
}
.big-physical-position-table tr td .ui-select-match,
.big-physical-position-table tr td .ui-select-container {
  display: inline;
}
.big-physical-position-table tr td:first-child .btn-danger,
.big-physical-position-table tr td:first-child .btn-success {
  font-size: calc(14px / 1.3);
  line-height: 1;
  margin-top: 2px;
}
.main-table-condensed tr td {
  padding: 2px 6px !important;
  line-height: 1 !important;
}
.main-table-condensed tr td a,
.main-table-condensed tr td .btn,
.main-table-condensed tr td .btn_link {
  margin: 0 !important;
  padding: 0 !important;
}
.main-table-condensed tr td .label,
.main-table-condensed tr td .alert,
.main-table-condensed tr td .alert-success {
  padding: 2px !important;
  margin: 0 !important;
}
.trader-position-crop-table tr th:nth-child(1) {
  text-align: center;
}
.trader-position-crop-table tr th {
  white-space: normal !important;
  word-break: break-all;
}
.trader-position-crop-table tr th .row div {
  white-space: normal !important;
}
.trader-position-crop-table tr th .row span {
  text-align: right;
  white-space: normal !important;
}
.trader-position-crop-table tr td {
  text-align: right;
  white-space: nowrap !important;
  padding: 2px 6px !important;
}
.trader-position-crop-table .col-xs-12 {
  width: max-content;
  min-width: 100%;
}
.trader-position-crop-table .row-no-margin {
  margin-right: 0;
  margin-left: 0;
}
.trader-position-crop-table .row div {
  text-align: right;
  white-space: nowrap !important;
}
.trader-position-crop-table .row div span {
  text-align: right;
  float: right;
}
.trader-position-crop-table .row span {
  text-align: right;
  white-space: nowrap !important;
}
.trader-position-crop-table.wide-columns tr td {
  padding: 2px !important;
}
.inside-table-ul li {
  text-align: right;
}
.inside-table-ul li .label {
  margin: 0 !important;
  padding: 2px;
}
.inside-table-items {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: 4px;
}
.more-details-row .btn-link {
  font-size: 0.7em;
}
.inline-date li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 7px;
}
.inline-date li .label:hover {
  cursor: pointer;
  opacity: 0.8;
}
.inline-date .label-highlighted {
  background: #ffffff !important;
  color: #595b63 !important;
}
.left-sidebar .contract-details-container-cut .btn-link {
  font-size: calc(14px / 1.2);
}
.highlighted-contract {
  background: rgba(0, 0, 0, 0) !important;
}
.unhighlighted-contract {
  background: #fff !important;
}
.progress {
  margin: 0 !important;
  background-color: #f9f9f7 / 1.1;
  box-shadow: none !important;
  height: auto !important;
  border-radius: 2em !important;
  text-transform: uppercase;
  white-space: nowrap;
  font-family: 'Regular', sans-serif !important;
}
.progress .progress-bar {
  font-size: calc(14px / 1.3);
  padding: 0;
  padding-left: 5px;
  text-align: left;
  box-shadow: none !important;
}
.progress .progress_bar_default {
  background-color: rgba(120, 123, 132, 0.4);
  color: #595b63;
}
.progress .progress_bar_success {
  background-color: rgba(41, 141, 107, 0.7);
  color: #07422d;
}
.progress .progress_bar_warning {
  background-color: rgba(229, 218, 203, 0.7);
  color: #7d6643;
}
.progress .progress_bar_calculate {
  background-color: rgba(164, 149, 176, 0.7);
  color: #6d6a6f;
}
.progress .progress_bar_danger {
  background-color: rgba(207, 97, 99, 0.7);
  color: #ffffff;
}
.gt-detail-page .hide {
  display: none !important;
}
.gt-detail-page .central-container {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
}
.gt-detail-page .trader-page {
  margin-top: -22px !important;
}
.gt-detail-page .trader-page .page-header-main {
  padding-top: 15px !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
}
.gt-detail-page .page-header-main {
  margin-top: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
}
.gt-detail-page .page-header-main .payment-details-container {
  max-width: 97.5%;
  margin-left: 15px !important;
  overflow-x: auto;
  margin-bottom: 25px !important;
}
.gt-detail-page .page-header-main .main-nav-tabs li > a {
  font-size: calc(14px / 1.2) !important;
  text-align: center;
}
.gt-detail-page .page-header-main .main-nav-tabs li > a .fa {
  text-align: center;
}
.gt-main-container.col-xs-11 {
  width: 97%;
}
.create-contract-page .help-btn-group a:last-child {
  margin-right: 15px !important;
}
.create-contract-page .gt-sidebar.col-xs-1 {
  background: #34495e;
  width: 3%;
  padding: 0;
  border-right: 1px solid #34495e;
  box-shadow: none !important;
  padding-bottom: 1200px;
}
.create-contract-page .gt-sidebar.col-xs-1 .scrollable-sidebar {
  position: fixed;
  display: inline-table;
}
.create-contract-page .gt-sidebar.col-xs-1 .toggle-button {
  margin: 0 auto;
  display: block;
  position: relative;
  margin-top: 20px;
  padding: 6px;
}
.create-contract-page .gt-sidebar.col-xs-2 {
  padding-right: 0;
  padding-left: 0px;
  padding-top: 10px;
  box-shadow: none !important;
  background: #34495e;
  padding-bottom: 1000px;
}
.create-contract-page .gt-sidebar.col-xs-2 .scrollable-sidebar {
  position: fixed;
  display: inline-table;
  width: 16%;
  max-width: 16%;
  background: #34495e;
}
.create-contract-page .gt-sidebar.col-xs-2 .start-page-header {
  color: #fff;
}
.create-contract-page .gt-sidebar.col-xs-2 .opened-sidebar {
  background: #34495e;
}
.create-contract-page .gt-main-container .help-btn-group {
  top: 20px !important;
  right: 35px;
}
.create-contract-page .gt-main-container .all-filters-block {
  padding-top: 40px !important;
}
.create-contract-page .gt-main-container .contract-details-container-cut {
  margin-top: 20px;
}
.create-contract-page .gt-main-container .page-header-main h1 {
  margin-top: 25px !important;
  padding-bottom: 40px !important;
}
.create-contract-page .toggle-button .fa-bars {
  font-size: calc(14px * 2);
  color: #fff;
}
.create-contract-page .toggle-button .fa-filter {
  font-size: calc(14px * 2);
  color: #fff;
}
.create-contract-page .toggle-button.btn-default {
  background: #000000;
  color: #ffffff;
  box-shadow: none !important;
}
.create-contract-page .sidebar-wrapper {
  height: 100vh;
  display: block;
  padding: 0;
  margin: 0;
}
.create-contract-page .sidebar-wrapper .search-wrapper {
  width: 90% !important;
}
.create-contract-page .sidebar-wrapper .search-wrapper .search-container {
  border: 1px solid #fff !important;
}
.create-contract-page .sidebar-wrapper .search-wrapper .search-container input {
  color: #fff !important;
}
.create-contract-page .sidebar-wrapper .search-wrapper .search-container input::-webkit-input-placeholder {
  color: #fff !important;
}
.create-contract-page .sidebar-wrapper .pagination > li > a,
.create-contract-page .sidebar-wrapper .pagination > li > span {
  font-size: calc(14px / 1.3) !important;
  padding: 6px;
  color: #fff !important;
}
.create-contract-page .sidebar-wrapper .pagination > li > a :hover,
.create-contract-page .sidebar-wrapper .pagination > li > span :hover {
  cursor: pointer;
}
.create-contract-page .sidebar-wrapper .pagination > li.active > a {
  background: #909597 !important;
  color: #fff !important;
}
.create-contract-page .gt-table-sidebar {
  width: 100%;
  background: transparent;
  display: block;
  overflow-y: auto;
  max-height: 25em;
  margin-bottom: 0;
}
.create-contract-page .gt-table-sidebar tbody {
  display: block;
}
.create-contract-page .gt-table-sidebar tbody tr {
  display: grid;
}
.create-contract-page .gt-table-sidebar tr td {
  padding: 6px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid #0a0e12;
  border-top: 0;
  line-height: 1;
  border-right: 0;
  font-size: calc(14px / 1.2) !important;
  word-break: break-all;
}
.create-contract-page .gt-table-sidebar tr td.active-td {
  padding-left: 10px;
}
.create-contract-page .gt-table-sidebar tr td:nth-child(1) {
  font-family: 'Bold', sans-serif !important;
}
.create-contract-page .gt-table-sidebar tr td:nth-child(1) span {
  padding-top: 4px;
}
.create-contract-page .gt-table-sidebar tr.contract_new > td:first-child,
.create-contract-page .gt-table-sidebar tr.contract_processing > td:first-child,
.create-contract-page .gt-table-sidebar tr.contract_executed > td:first-child,
.create-contract-page .gt-table-sidebar tr.contract_cancelled > td:first-child,
.create-contract-page .gt-table-sidebar tr.contract_calculate > td:first-child {
  background: transparent !important;
}
.create-contract-page .gt-table-sidebar tr:hover {
  cursor: pointer;
}
.create-contract-page .gt-table-sidebar tr:hover td {
  background: #f4f7fa;
}
.create-contract-page .gt-table-sidebar tr.selected td {
  background: #f9f9f7 !important;
}
.create-contract-page .gt-table-sidebar-contracts tr.contract_executed > td:first-child {
  border-left: 5px solid rgba(120, 123, 132, 0.2);
  color: #f5f5f5 !important;
}
.create-contract-page .gt-table-sidebar-contracts tr.contract_calculate > td:first-child {
  border-left: 5px solid #a495b0;
  color: #a495b0 !important;
}
.create-contract-page .gt-table-sidebar-contracts tr.contract_new > td:first-child {
  border-left: 5px solid #1bc98e;
  color: #1bc98e !important;
}
.create-contract-page .gt-table-sidebar-contracts tr.contract_processing > td:first-child {
  border-left: 5px solid #e5b878;
  color: #e5b878 !important;
}
.create-contract-page .gt-table-sidebar-contracts tr.contract_cancelled > td:first-child {
  border-left: 5px solid rgba(119, 119, 119, 0.1);
  color: #7f8c8d !important;
  text-decoration: line-through;
}
.create-contract-page .gt-table-sidebar-contracts tr > td.active-td:first-child {
  border-left-width: 18px !important;
}
.table-action-buttons {
  display: block;
}
.table-action-buttons .search-container {
  width: 100% !important;
}
.table-action-buttons .input-group-xs .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.table-action-buttons input[type='checkbox'],
.table-action-buttons input[type='radio'] {
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
}
.table-action-buttons .btn {
  font-family: 'Bold', sans-serif !important;
  text-transform: capitalize !important;
}
.table-action-buttons .btn::first-letter {
  text-transform: capitalize !important;
}
.table-action-buttons .btn-default {
  font-family: 'Bold', sans-serif !important;
  margin-left: 5px !important;
  line-height: 1.6 !important;
  background: transparent;
  font-size: calc(14px / 1) !important;
  letter-spacing: var(--letter-spacing-very-huge, 0.07em);
  border-radius: 4px !important;
  border: 1px solid #c7c7b4 !important;
  color: #666 !important;
  padding: 3px 10px;
}
.table-action-buttons .btn-default:hover {
  color: #fff !important;
  background: #c7c7b4;
}
.table-action-buttons .btn-default::first-letter {
  text-transform: uppercase !important;
}
.table-action-buttons .btn-add-dark {
  margin-left: 5px !important;
  border: 1px solid #c7c7b4 !important;
  padding: 3px 10px;
  line-height: 1.5;
  border-width: 1px;
  border-radius: 4px !important;
  font-size: calc(14px / 1) !important;
}
.table-action-buttons .btn-orange-border,
.table-action-buttons .btn-green-border,
.table-action-buttons .btn-blue-border {
  background: transparent;
  box-shadow: none !important;
  margin-left: 5px !important;
  padding: 3px 10px;
  line-height: 1.5;
  border-width: 1px;
  font-size: calc(14px / 1) !important;
}
.table-action-buttons .btn-success {
  margin-left: 5px !important;
  padding: 3px 10px;
  border-width: 1px;
  line-height: 1.5;
  font-size: calc(14px / 1) !important;
}
.table-action-buttons .filter-template {
  border-bottom: 0 !important;
}
.table-action-buttons .filter-template .btn-default {
  padding: 2px !important;
  border-radius: 4px !important;
}
.table-action-buttons .btn-danger {
  background: #fff;
}
.table-action-buttons .gt-export-button {
  line-height: 1.6;
  padding: 3px 6px;
  font-size: 14px;
}
.table-action-buttons .gt-export-button::first-letter {
  text-transform: uppercase !important;
}
.table-action-buttons .btn-primary {
  border: 1px solid #c7c7b4 !important;
  background: #c7c7b4 !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.table-action-buttons .item-settings-dropdown.navbar-nav .filter-template .btn {
  line-height: 1 !important;
  padding: 2px 10px !important;
}
.table-action-buttons .item-settings-dropdown.navbar-nav .filter-template .btn:hover {
  color: #909597 !important;
}
.table-action-buttons .item-settings-dropdown a:nth-of-type(1) {
  line-height: 1 !important;
  padding: 2px 10px !important;
  -webkit-text-stroke: 0;
  font-family: 'Regular', sans-serif !important;
  letter-spacing: var(--letter-spacing-very-huge, 0.07em);
  text-transform: capitalize !important;
}
.table-action-buttons .item-settings-dropdown a:nth-of-type(1)::first-letter {
  text-transform: uppercase !important;
}
.table-action-buttons .item-settings-dropdown .dropdown-menu {
  left: calc(100% - 49px) !important;
  top: calc(100% + 5px) !important;
  background: #fff !important;
  -webkit-text-stroke: 0;
}
.table-action-buttons .item-settings-dropdown {
  color: #666 !important;
  -webkit-text-stroke: 0;
  background: transparent !important;
  border: 1px solid #c7c7b4 !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  line-height: 1;
  padding: 4px !important;
  margin-left: 5px !important;
}
.table-action-buttons .item-settings-dropdown .user-dropdown-btn {
  background: transparent !important;
  color: #666 !important;
}
.table-action-buttons .item-settings-dropdown .user-dropdown-btn .fa.show-on-hover {
  display: none;
}
.table-action-buttons .item-settings-dropdown:hover {
  color: #fff !important;
  background: #666 !important;
}
.table-action-buttons .item-settings-dropdown:hover .user-dropdown-btn {
  color: #fff !important;
}
.table-action-buttons .item-settings-dropdown:hover .user-dropdown-btn .fa.hide-on-hover {
  display: none;
}
.table-action-buttons .item-settings-dropdown:hover .user-dropdown-btn .fa.show-on-hover {
  display: inline-block;
}
.table-action-buttons .item-settings-dropdown a {
  background: #fff !important;
  -webkit-text-stroke: 0;
}
.table-action-buttons .item-settings-dropdown a::first-letter {
  text-transform: uppercase !important;
}
.table-action-buttons .item-settings-dropdown .gt-export-button {
  width: 100%;
  display: block;
  margin: 0 !important;
  border: 0 !important;
  text-align: left !important;
}
.table-action-buttons .start-page-header {
  margin-top: 2px !important;
  padding-bottom: 5px !important;
}
.table-action-buttons .start-page-header::first-letter {
  text-transform: uppercase !important;
}
.settings-page .btn-group {
  width: 49%;
}
.settings-page .btn-group button {
  width: 33%;
  font-size: calc(14px / 1.3);
  float: right;
}
.settings-page label {
  width: 50%;
}
.settings-page .well {
  padding: 5px !important;
  margin: 5px;
}
.settings-page .btn-primary {
  font-family: 'Bold', sans-serif !important;
  border: 1px solid #d2cece !important;
}
.details-wrapper .well {
  background: #fff !important;
  border-radius: 8px;
  box-shadow: none !important;
  border: 0 !important;
  padding: 20px;
  display: block;
  position: relative;
}
.details-wrapper .well table,
.details-wrapper .well .table {
  margin-bottom: 0 !important;
}
.details-wrapper .well .modal-form-block .ul-inline-row {
  padding: 0 !important;
}
.details-wrapper .well .well,
.details-wrapper .well .analytics-container,
.details-wrapper .well .ul-inline-row,
.details-wrapper .well .table-container-wrapper {
  border: 0 !important;
  box-shadow: none !important;
  padding: 15px !important;
}
.details-wrapper .well .request-list-container {
  padding-top: 10px !important;
}
.details-wrapper .well .form-group-container .form-group.col-xs-12:hover {
  border-left: 0 !important;
}
.details-wrapper .well .nav-tabs {
  border-bottom: 1px solid #e8e6e6 !important;
}
.details-wrapper .well .nav-tabs li.active a {
  box-shadow: none !important;
  border: 1px solid #e8e6e6 !important;
  border-bottom: 0 !important;
}
.details-wrapper .well .table-nav-tabs li.active a {
  bottom: -1px !important;
}
.details-wrapper .well .ul-inline-row,
.details-wrapper .well .table-container-wrapper {
  box-shadow: none;
  padding: 2px !important;
}
.details-wrapper .well .request-list {
  box-shadow: none !important;
}
.details-wrapper .well .upcoming-updates-list,
.details-wrapper .well .add-new-inline {
  box-shadow: none !important;
  border: 1px solid #beb9b9 !important;
  padding: 6px !important;
}
.details-wrapper .well .upcoming-updates-list h4,
.details-wrapper .well .add-new-inline h4,
.details-wrapper .well .upcoming-updates-list h5,
.details-wrapper .well .add-new-inline h5 {
  margin-bottom: 0;
  margin-top: 0;
}
.details-wrapper .well .chart-graph-block .table-container-wrapper {
  border: 0 !important;
  padding: 0 !important;
}
.details-wrapper .well .main-table tr td {
  padding: 2px;
}
.details-wrapper .well .contract-quality-table tr td {
  border-bottom: 1px solid #e8e6e6 !important;
}
.details-wrapper .well .persons-table tr td {
  border-top: 1px solid #e8e6e6 !important;
}
.details-wrapper .well .facts-edit-table tr td {
  border-top: 1px solid #e8e6e6 !important;
}
.details-wrapper .real-well {
  background: rgba(0, 0, 0, 0.05) !important;
}
.details-wrapper .table-action-buttons h5.start-page-header {
  display: none;
}
.details-wrapper .table-action-buttons .col-sm-3,
.details-wrapper .table-action-buttons .col-sm-2 {
  display: none;
}
.details-wrapper .container-btn-group,
.details-wrapper .search-container {
  display: none;
}
.details-wrapper .visible-searchbar .search-container {
  display: -webkit-box;
}
.details-wrapper .main-table.multipassport-table tr td {
  border-top: 1px solid #d2cece !important;
  border-right: 1px solid #d2cece !important;
}
.details-wrapper .main-table.multipassport-table tr:hover td {
  background: inherit !important;
}
.details-wrapper .well-collapsed {
  max-height: 320px;
  overflow: hidden;
}
.details-wrapper .well-expanded {
  max-height: inherit;
  overflow: inherit;
}
.details-wrapper h2.start-page-header {
  font-size: 18px !important;
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 5px !important;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.details-wrapper .start-page-header {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
.details-wrapper .start-page-header::first-letter {
  text-transform: uppercase !important;
}
.details-wrapper .start-page-header small {
  text-transform: initial;
  font-family: 'Light', sans-serif !important;
}
.details-wrapper .btn-show-more {
  font-size: 14px !important;
  font-family: 'Bold', sans-serif !important;
}
.details-wrapper .btn-show-more-floating {
  position: absolute;
  left: 0%;
  bottom: 0%;
  background: #fff;
  box-shadow: none !important;
}
.main-list {
  border: 1px solid #e8e6e6 !important;
  background-color: #fff;
  border-radius: 4px !important;
  margin-top: -1px;
  box-shadow: none !important;
  transition: background-color linear 0.3s;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.main-list .hover-element {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  transform: translate3d(0, 8px, 0);
  width: auto;
  visibility: visible;
}
.main-list:hover {
  box-shadow: none !important;
  cursor: pointer;
}
.main-list:hover .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  visibility: visible;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  width: auto;
}
.main-list:hover .transform-element {
  transform: translate3d(0, 0, 0);
  font-size: 75%;
}
.main-list:hover .fa-sort-desc.hover-element {
  font-size: calc(14px * 1.2) !important;
}
.main-list .nav-tabs li.active a {
  background: #f9f9f7 !important;
}
.main-list .main-list-el {
  background-color: #fff;
  line-height: 1.5;
  font-family: 'Regular', sans-serif !important;
  border-radius: 4px !important;
  text-align: left;
  position: relative;
  padding: 6px;
  width: 100%;
  transition: background-color linear 0.3s;
  font-size: calc(14px / 1.1);
  color: #000000 !important;
}
.main-list .main-list-el .label {
  font-size: calc(14px / 1.3);
  margin-top: 0;
  margin-bottom: 0;
  display: inline-flex;
  padding: 2px !important;
}
.main-list .main-list-el input[type='checkbox'],
.main-list .main-list-el input[type='radio'] {
  width: 15px !important;
  height: 15px;
}
.main-list .lineup-el .label {
  font-size: calc(14px / 1.3);
}
.main-list .lineup-el ul {
  margin-bottom: 0 !important;
}
.main-list .main-list-details-expanded {
  opacity: 1;
  padding: 6px;
  margin: 0;
  border-radius: 4px !important;
  margin-top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: left;
  height: auto;
  background: rgba(0, 0, 0, 0.03);
  transition: background-color linear 0.3s;
  font-size: calc(14px / 1.3);
}
.main-list .main-list-details-expanded h2 {
  font-size: calc(14px * 1.1) !important;
}
.main-list .counterparty_detail_table tr td:first-child {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.main-list .counterparty_detail_table tr td {
  font-size: 14px !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-family: 'Regular', sans-serif !important;
  border: 0 !important;
  vertical-align: middle !important;
}
.main-list .counterparty_detail_table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.main-list .counterparty_detail_table tr td .label-strong {
  font-family: 'Semibold', sans-serif !important;
}
.main-list .counterparty_detail_table tr td .btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.main-list .counterparty_detail_table tr td .progress .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  height: 12px !important;
}
.main-list .counterparty_detail_table tr td .form-control {
  height: 22px !important;
  min-height: 22px !important;
}
.main-list .form-control .ui-select-match-text {
  font-size: calc(14px / 1.3);
  line-height: 1;
}
.main-list .ui-select-bootstrap > .ui-select-match > .btn {
  padding: 2px;
  height: 15px;
  border: 1px solid #b0a9a9 !important;
}
.main-list .text-muted {
  font-size: calc(14px / 1.3);
  vertical-align: middle;
  line-height: 1 !important;
}
.main-list-expanded {
  margin-top: 10px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.03);
}
.main-list-expanded .main-list-el {
  padding: 20px;
}
.main-list-expanded .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  width: auto;
}
.main-list-expanded .transform-element {
  transform: translate3d(0, 0, 0);
  font-size: 75%;
}
.contract-details-container-cut {
  padding: 0;
  border-radius: 0;
  margin-bottom: 0;
  white-space: nowrap;
}
.contract-details-container-cut .counterparty-details-table tr td {
  font-size: calc(14px / 1.3) !important;
}
.contract-details-container-cut small {
  margin-top: 0 !important;
  font-family: 'Light', sans-serif !important;
}
.contract-details-container-cut small .contract_details {
  margin-bottom: 2px;
  margin-right: 2px;
  float: left;
  white-space: nowrap;
  padding: 2px !important;
}
.contract-details-container-cut small .contract-description-label {
  margin-bottom: 0px;
  font-size: calc(14px / 1.3) !important;
  padding: 6px;
}
.contract-details-container-cut h2 {
  font-size: 22px !important;
  margin-bottom: 2px !important;
  margin-top: 0 !important;
}
.contract-details-container-cut .updated_time {
  background: transparent !important;
  color: #000000 !important;
}
.contract-details-container-cut .label-details {
  font-size: calc(14px / 1.3) !important;
  box-shadow: 0px 0px 1px #000;
  background: transparent !important;
  color: #000000;
  margin-left: 1px;
}
.contract-details-container-cut .horizontal-divider {
  margin-top: 5px;
  margin-bottom: 5px;
}
.contract-details-container-cut .progress {
  margin: 0 !important;
  background-color: rgba(0, 0, 0, 0.08);
  box-shadow: none !important;
  height: auto !important;
  border-radius: 2em !important;
}
.contract-details-container-cut .progress .progress-bar {
  padding: 0px;
  padding-left: 5px;
  padding-top: 2px;
  text-align: left;
}
.contract-details-container-cut .progress .progress_bar_default {
  background-color: rgba(120, 123, 132, 0.4);
  color: #595b63;
}
.contract-details-container-cut .progress .progress_bar_success {
  background-color: rgba(41, 141, 107, 0.7);
  color: #07422d;
}
.contract-details-container-cut .progress .progress_bar_warning {
  background-color: rgba(229, 218, 203, 0.7);
  color: #7d6643;
}
.contract-details-container-cut .progress .progress_bar_calculate {
  background-color: rgba(164, 149, 176, 0.7);
  color: #6d6a6f;
}
.contract-details-container-cut .progress .avg-price {
  display: inline;
  position: absolute;
  right: 20px;
}
.contract-details-container-cut .progress-consisted .progress-bar {
  overflow-x: hidden !important;
}
.contract-details-container-cut .progress.col-xs-6,
.contract-details-container-cut .progress.col-xs-4 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.contract-details-container-cut .contract_details {
  margin-bottom: 2px;
  margin-right: 2px;
  float: left;
  white-space: nowrap;
  padding: 2px !important;
}
.contract-details-container-cut .contract-description-label {
  font-family: 'Bold', sans-serif !important;
  margin-top: 5px;
  margin-bottom: 5px;
}
.contract-details-container-cut .btn-details-row {
  padding-top: 2px;
}
.contract-details-container-cut .btn-details-row .btn {
  margin-right: 5px;
}
.contract-details-container-cut .nav-tabs {
  margin-top: 5px;
}
.contract-details-container-cut .label-details {
  font-size: calc(14px / 1.3) !important;
  box-shadow: 0px 0px 1px #000;
  background: transparent !important;
  color: #000000;
  margin-left: 1px;
}
.contract-details-container-cut .label_danger {
  padding: 2px;
  border-radius: 4px !important;
}
.inset-container {
  padding: 10px;
  border-radius: 4px !important;
  margin-bottom: 10px;
  width: 100%;
  white-space: nowrap;
}
.inset-container ::-webkit-scrollbar-thumb {
  background: #263746 !important;
}
.payment-plans-list {
  box-shadow: none !important;
  padding: 2px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  border-bottom: 1px solid #e8e6e6 !important;
  margin-bottom: 0 !important;
}
.payment-plans-list h4 {
  margin: 0;
  font-family: 'Regular', sans-serif !important;
}
.payment-plan-modal-table {
  table-layout: fixed;
}
.payment-plan-finances-table tr td {
  vertical-align: middle !important;
  padding: 4px !important;
  font-size: calc(14px / 1.2) !important;
  border-bottom: 1px solid #e8e6e6 !important;
  border-right: 1px solid #e8e6e6 !important;
}
.payment-plan-finances-table tr td .form-control {
  height: 16px;
}
.payment-plan-finances-table tr th {
  border-bottom: 1px solid #e8e6e6 !important;
  border-right: 1px solid #e8e6e6 !important;
  background: #f9f9f7 !important;
}
.read-only .btn-warning,
.read-only .btn-info,
.read-only .btn-danger,
.read-only .btn-success,
.read-only .search-wrapper,
.read-only .search-container,
.read-only .btn-orange-border,
.read-only .btn-green-border,
.read-only .btn-blue-border,
.read-only tr.edit-mode,
.read-only .edit-mode,
.read-only .position-analytics-container {
  display: none;
}
.read-only .read-only-element {
  display: none;
}
.read-only .close.ui-select-match-close,
.read-only .ui-select-search {
  display: none;
}
.read-only input,
.read-only select,
.read-only .ui-select-bootstrap > .ui-select-match > .btn {
  pointer-events: none;
  background: rgba(0, 0, 0, 0.01);
}
.read-only .copy-clipboard-btn {
  pointer-events: all !important;
  position: absolute;
  right: 0;
  z-index: 9999 !important;
}
.read-only .ui-select-match .copy-clipboard-btn {
  display: none !important;
}
.read-only .ui-select-match:hover .copy-clipboard-btn {
  display: inline-block !important;
}
.read-only .ui-select-match-text {
  margin-top: 0 !important;
}
.read-only .main-table .highlighted-td {
  background: #fff !important;
}
.copy-clipboard-btn {
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  background: transparent !important;
  border: 0 !important;
  border-radius: 100% !important;
}
table ::-webkit-scrollbar-thumb,
.dropdown ::-webkit-scrollbar-thumb,
.ul-inline-row ::-webkit-scrollbar-thumb,
.table-container-wrapper ::-webkit-scrollbar-thumb,
.contract-details-container-cut ::-webkit-scrollbar-thumb {
  background: #b1b197 !important;
}
.passport-inline-items-table .ul-inline-row,
.passport-properties-details .ul-inline-row,
.passport-inline-items-table .table-container-wrapper,
.passport-properties-details .table-container-wrapper {
  background: #fff !important;
}
.passport-inline-items-table .add-new-inline,
.passport-properties-details .add-new-inline {
  border: 1px solid #07422d !important;
  border-left: 6px solid #07422d !important;
  border-radius: 4px !important;
}
.passport-inline-items-table .contract-costs-table tr td,
.passport-properties-details .contract-costs-table tr td,
.passport-inline-items-table .contract-quality-table tr td,
.passport-properties-details .contract-quality-table tr td {
  border-right: 1px solid #e8e6e6 !important;
}
.passport-inline-items-table .contract-costs-table tr td:first-child,
.passport-properties-details .contract-costs-table tr td:first-child,
.passport-inline-items-table .contract-quality-table tr td:first-child,
.passport-properties-details .contract-quality-table tr td:first-child {
  border-left: 0 !important;
}
.passport-inline-items-table .contract-costs-table tr td:last-child,
.passport-properties-details .contract-costs-table tr td:last-child,
.passport-inline-items-table .contract-quality-table tr td:last-child,
.passport-properties-details .contract-quality-table tr td:last-child {
  border-right: 0 !important;
}
.passport-inline-items-table .contract-costs-table tr th.thick-border,
.passport-properties-details .contract-costs-table tr th.thick-border,
.passport-inline-items-table .contract-quality-table tr th.thick-border,
.passport-properties-details .contract-quality-table tr th.thick-border,
.passport-inline-items-table .contract-costs-table tr td.thick-border,
.passport-properties-details .contract-costs-table tr td.thick-border,
.passport-inline-items-table .contract-quality-table tr td.thick-border,
.passport-properties-details .contract-quality-table tr td.thick-border {
  border-right: 3px solid #e8e6e6 !important;
}
.table-passport-result tr td,
.table-passport-result tr th {
  padding: 2px !important;
  white-space: nowrap !important;
}
.table-passport-result tr td .label,
.table-passport-result tr th .label {
  padding: 2px !important;
}
.container-wo-totals .page-total-analytics-container,
.container-wo-totals .min-medium-screens,
.container-wo-totals .highlighted-tr {
  display: none !important;
}
.container-wo-totals .table-nav-tabs li {
  display: none !important;
}
.container-wo-totals .table-nav-tabs li:first-child {
  display: block !important;
}
.container-wo-totals .table-container-wrapper {
  background: #fff !important;
}
.passports-container {
  padding: 0;
  border-radius: 4px !important;
  margin-bottom: 30px;
  white-space: nowrap;
}
.passports-container small {
  margin-top: 0 !important;
}
.passports-container small .contract_details {
  margin-bottom: 2px;
  margin-right: 2px;
  float: left;
  white-space: nowrap;
  padding: 2px !important;
}
.passports-container small .contract-description-label {
  margin-bottom: 0px;
  font-size: calc(14px / 1.3) !important;
  padding: 6px;
}
.passports-container .contract-status-block table tr td {
  padding: 2px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  white-space: nowrap;
  background: transparent !important;
}
.passports-container .contract-status-block table tr td .label {
  padding: 2px !important;
}
.passports-container .progress {
  margin: 0 !important;
  box-shadow: none !important;
  height: auto !important;
  border-radius: 4px !important;
  margin-bottom: 5px !important;
}
.passports-container .progress .progress-bar {
  font-size: calc(14px / 1.3);
  padding: 6px;
  font-family: 'Bold', sans-serif !important;
  text-transform: uppercase;
  text-align: left;
}
.passports-container .progress .progress_bar_default {
  background-color: rgba(120, 123, 132, 0.4);
  color: #595b63;
}
.passports-container .progress .progress_bar_success {
  background-color: rgba(41, 141, 107, 0.7);
  color: #07422d;
}
.passports-container .progress .progress_bar_warning {
  background-color: rgba(229, 218, 203, 0.7);
  color: #7d6643;
}
.passports-container .progress .progress_bar_calculate {
  background-color: rgba(164, 149, 176, 0.7);
  color: #6d6a6f;
}
.passports-container .progress .progress_bar_danger {
  background-color: rgba(207, 97, 99, 0.7);
  color: #ffffff;
}
.passports-container .progress .avg-price {
  display: inline;
  position: absolute;
  right: 20px;
  font-size: calc(14px / 1.3);
  line-height: 1.9;
}
.passports-container h2 {
  font-size: 18px !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.passports-container .label-details {
  font-size: calc(14px / 1.3) !important;
  box-shadow: 0px 0px 1px #000;
  background: transparent !important;
  color: #000000;
  margin-left: 1px;
}
.passports-container .request-list-container {
  width: 100% !important;
}
.passports-container .request-list-container .request-list,
.passports-container .request-list-container .contract-list {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.passports-container .voyage-wrapper-light-mode {
  display: block;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 1 !important;
  -webkit-column-gap: 0 !important;
  -moz-column-count: 1 !important;
  -moz-column-gap: 0 !important;
  column-count: 1 !important;
  column-gap: 0 !important;
}
.passports-container .voyage-wrapper-light-mode .contract-details-container-cut {
  overflow: auto !important;
}
.passports-container .passport-items-container {
  padding-left: 15px;
  padding-right: 15px;
}
.passports-container .passport-label {
  margin-left: 2px !important;
  display: inline-flex !important;
  margin-top: 0;
}
.passports-container .passport-label .fa {
  margin-right: 2px;
}
.passports-container h2 .contract_details {
  width: auto !important;
}
.passports-container .inset-container {
  background: #e5e5dd !important;
  border-radius: 4px !important;
  margin-bottom: 0 !important;
}
.passports-container .passport-nav-tabs li a {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.passports-container .passport-properties-details {
  padding-top: 5px;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-top: 0 !important;
}
.passports-container .passport-properties-details .alert {
  margin-top: 3px;
  margin-bottom: 3px;
}
.passports-container .passport-properties-details .label {
  font-size: calc(14px / 1.3);
  padding: 2px;
}
.passports-container .filter-template .fa-th-list,
.passports-container .filter-template .fa-th-large {
  font-size: calc(14px * 1.2);
}
.contracts-wrapper-light-mode {
  display: flow-root;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 5;
  -webkit-column-gap: 10px;
  -moz-column-count: 5;
  -moz-column-gap: 10px;
  column-count: 5;
  column-gap: 10px;
}
.contracts-wrapper-light-mode .item-settings-dropdown {
  padding: 5px !important;
}
.contracts-wrapper-light-mode .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.contracts-wrapper-light-mode .request-list,
.contracts-wrapper-light-mode .contract-details-container-cut {
  margin-bottom: 10px !important;
  overflow: visible !important;
  border-radius: 8px !important;
  display: inline-block;
  width: 100%;
}
.contracts-wrapper-light-mode .request-list h2,
.contracts-wrapper-light-mode .contract-details-container-cut h2 {
  font-size: calc(14px * 1.2) !important;
}
.contracts-wrapper-light-mode .request-list small,
.contracts-wrapper-light-mode .contract-details-container-cut small {
  margin-left: 5px;
}
.contracts-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
.contracts-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
  font-size: calc(14px / 1.2) !important;
}
.contracts-wrapper-light-mode .request-list .label,
.contracts-wrapper-light-mode .contract-details-container-cut .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.contracts-wrapper-light-mode .request-list .counterparty_detail_table tr td:first-child,
.contracts-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td:first-child {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.contracts-wrapper-light-mode .request-list .counterparty_detail_table tr td,
.contracts-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td {
  font-size: calc(14px / 1.2) !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-family: 'Regular', sans-serif !important;
}
.contracts-wrapper-light-mode .request-list .counterparty_detail_table tr td .label,
.contracts-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.contracts-wrapper-light-mode .request-list .counterparty_detail_table tr td .label-strong,
.contracts-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label-strong {
  font-family: 'Bold', sans-serif !important;
}
.contracts-wrapper-light-mode .request-list .counterparty_detail_table tr td .btn,
.contracts-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.contracts-wrapper-light-mode .request-list .counterparty_detail_table tr td .progress .progress-bar,
.contracts-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .progress .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  height: 12px !important;
}
.contracts-wrapper-light-mode .request-list .form-control,
.contracts-wrapper-light-mode .contract-details-container-cut .form-control {
  height: auto !important;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: 'Bold', sans-serif, 'FontAwesome' !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
.contracts-wrapper-light-mode .request-list .form-control span,
.contracts-wrapper-light-mode .contract-details-container-cut .form-control span {
  font-size: 14px;
}
.contracts-wrapper-light-mode .request-list .ui-select-match-text,
.contracts-wrapper-light-mode .contract-details-container-cut .ui-select-match-text {
  word-break: break-all;
}
.contracts-wrapper-light-mode .request-list .ui-select-match-text div,
.contracts-wrapper-light-mode .contract-details-container-cut .ui-select-match-text div {
  word-break: break-all;
}
.clients-wrapper-light-mode {
  display: flow-root;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 5;
  -webkit-column-gap: 10px;
  -moz-column-count: 5;
  -moz-column-gap: 10px;
  column-count: 5;
  column-gap: 10px;
}
.clients-wrapper-light-mode .item-settings-dropdown {
  padding: 5px !important;
}
.clients-wrapper-light-mode .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.clients-wrapper-light-mode .request-list,
.clients-wrapper-light-mode .contract-details-container-cut {
  margin-bottom: 10px !important;
  overflow: visible !important;
  border-radius: 8px !important;
  display: inline-block;
  width: 100%;
}
.clients-wrapper-light-mode .request-list h2,
.clients-wrapper-light-mode .contract-details-container-cut h2 {
  font-size: calc(14px * 1.2) !important;
}
.clients-wrapper-light-mode .request-list small,
.clients-wrapper-light-mode .contract-details-container-cut small {
  margin-left: 5px;
}
.clients-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
.clients-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
  font-size: calc(14px / 1.2) !important;
}
.clients-wrapper-light-mode .request-list .label,
.clients-wrapper-light-mode .contract-details-container-cut .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.clients-wrapper-light-mode .request-list .counterparty_detail_table tr td:first-child,
.clients-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td:first-child {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.clients-wrapper-light-mode .request-list .counterparty_detail_table tr td,
.clients-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td {
  font-size: calc(14px / 1.2) !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-family: 'Regular', sans-serif !important;
}
.clients-wrapper-light-mode .request-list .counterparty_detail_table tr td .label,
.clients-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.clients-wrapper-light-mode .request-list .counterparty_detail_table tr td .label-strong,
.clients-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label-strong {
  font-family: 'Bold', sans-serif !important;
}
.clients-wrapper-light-mode .request-list .counterparty_detail_table tr td .btn,
.clients-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.clients-wrapper-light-mode .request-list .counterparty_detail_table tr td .progress .progress-bar,
.clients-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .progress .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  height: 12px !important;
}
.clients-wrapper-light-mode .request-list .form-control,
.clients-wrapper-light-mode .contract-details-container-cut .form-control {
  height: auto !important;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: 'Bold', sans-serif, 'FontAwesome' !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
.clients-wrapper-light-mode .request-list .form-control span,
.clients-wrapper-light-mode .contract-details-container-cut .form-control span {
  font-size: 14px;
}
.clients-wrapper-light-mode .request-list .ui-select-match-text,
.clients-wrapper-light-mode .contract-details-container-cut .ui-select-match-text {
  word-break: break-all;
}
.clients-wrapper-light-mode .request-list .ui-select-match-text div,
.clients-wrapper-light-mode .contract-details-container-cut .ui-select-match-text div {
  word-break: break-all;
}
.persons-wrapper-light-mode {
  display: flow-root;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 5;
  -webkit-column-gap: 10px;
  -moz-column-count: 5;
  -moz-column-gap: 10px;
  column-count: 5;
  column-gap: 10px;
}
.persons-wrapper-light-mode .item-settings-dropdown {
  padding: 5px !important;
}
.persons-wrapper-light-mode .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.persons-wrapper-light-mode .request-list,
.persons-wrapper-light-mode .contract-details-container-cut {
  margin-bottom: 10px !important;
  overflow: visible !important;
  border-radius: 8px !important;
  display: inline-block;
  width: 100%;
}
.persons-wrapper-light-mode .request-list h2,
.persons-wrapper-light-mode .contract-details-container-cut h2 {
  font-size: calc(14px * 1.2) !important;
}
.persons-wrapper-light-mode .request-list small,
.persons-wrapper-light-mode .contract-details-container-cut small {
  margin-left: 5px;
}
.persons-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
.persons-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
  font-size: calc(14px / 1.2) !important;
}
.persons-wrapper-light-mode .request-list .label,
.persons-wrapper-light-mode .contract-details-container-cut .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.persons-wrapper-light-mode .request-list .counterparty_detail_table tr td:first-child,
.persons-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td:first-child {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.persons-wrapper-light-mode .request-list .counterparty_detail_table tr td,
.persons-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td {
  font-size: calc(14px / 1.2) !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-family: 'Regular', sans-serif !important;
}
.persons-wrapper-light-mode .request-list .counterparty_detail_table tr td .label,
.persons-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.persons-wrapper-light-mode .request-list .counterparty_detail_table tr td .label-strong,
.persons-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label-strong {
  font-family: 'Bold', sans-serif !important;
}
.persons-wrapper-light-mode .request-list .counterparty_detail_table tr td .btn,
.persons-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.persons-wrapper-light-mode .request-list .counterparty_detail_table tr td .progress .progress-bar,
.persons-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .progress .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  height: 12px !important;
}
.persons-wrapper-light-mode .request-list .form-control,
.persons-wrapper-light-mode .contract-details-container-cut .form-control {
  height: auto !important;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: 'Bold', sans-serif, 'FontAwesome' !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
.persons-wrapper-light-mode .request-list .form-control span,
.persons-wrapper-light-mode .contract-details-container-cut .form-control span {
  font-size: 14px;
}
.persons-wrapper-light-mode .request-list .ui-select-match-text,
.persons-wrapper-light-mode .contract-details-container-cut .ui-select-match-text {
  word-break: break-all;
}
.persons-wrapper-light-mode .request-list .ui-select-match-text div,
.persons-wrapper-light-mode .contract-details-container-cut .ui-select-match-text div {
  word-break: break-all;
}
.bank-account-wrapper-light-mode {
  display: flow-root;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 5;
  -webkit-column-gap: 10px;
  -moz-column-count: 5;
  -moz-column-gap: 10px;
  column-count: 5;
  column-gap: 10px;
}
.bank-account-wrapper-light-mode .item-settings-dropdown {
  padding: 5px !important;
}
.bank-account-wrapper-light-mode .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.bank-account-wrapper-light-mode .request-list,
.bank-account-wrapper-light-mode .contract-details-container-cut {
  margin-bottom: 10px !important;
  overflow: visible !important;
  border-radius: 8px !important;
  display: inline-block;
  width: 100%;
}
.bank-account-wrapper-light-mode .request-list h2,
.bank-account-wrapper-light-mode .contract-details-container-cut h2 {
  font-size: calc(14px * 1.2) !important;
}
.bank-account-wrapper-light-mode .request-list small,
.bank-account-wrapper-light-mode .contract-details-container-cut small {
  margin-left: 5px;
}
.bank-account-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
.bank-account-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
  font-size: calc(14px / 1.2) !important;
}
.bank-account-wrapper-light-mode .request-list .label,
.bank-account-wrapper-light-mode .contract-details-container-cut .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.bank-account-wrapper-light-mode .request-list .counterparty_detail_table tr td:first-child,
.bank-account-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td:first-child {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.bank-account-wrapper-light-mode .request-list .counterparty_detail_table tr td,
.bank-account-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td {
  font-size: calc(14px / 1.2) !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-family: 'Regular', sans-serif !important;
}
.bank-account-wrapper-light-mode .request-list .counterparty_detail_table tr td .label,
.bank-account-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.bank-account-wrapper-light-mode .request-list .counterparty_detail_table tr td .label-strong,
.bank-account-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label-strong {
  font-family: 'Bold', sans-serif !important;
}
.bank-account-wrapper-light-mode .request-list .counterparty_detail_table tr td .btn,
.bank-account-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.bank-account-wrapper-light-mode .request-list .counterparty_detail_table tr td .progress .progress-bar,
.bank-account-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .progress .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  height: 12px !important;
}
.bank-account-wrapper-light-mode .request-list .form-control,
.bank-account-wrapper-light-mode .contract-details-container-cut .form-control {
  height: auto !important;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: 'Bold', sans-serif, 'FontAwesome' !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
.bank-account-wrapper-light-mode .request-list .form-control span,
.bank-account-wrapper-light-mode .contract-details-container-cut .form-control span {
  font-size: 14px;
}
.bank-account-wrapper-light-mode .request-list .ui-select-match-text,
.bank-account-wrapper-light-mode .contract-details-container-cut .ui-select-match-text {
  word-break: break-all;
}
.bank-account-wrapper-light-mode .request-list .ui-select-match-text div,
.bank-account-wrapper-light-mode .contract-details-container-cut .ui-select-match-text div {
  word-break: break-all;
}
.bank-account-wrapper-light-mode .request-list .hover-element,
.bank-account-wrapper-light-mode .contract-details-container-cut .hover-element {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  visibility: hidden;
}
.bank-account-wrapper-light-mode .request-list:hover .hover-element,
.bank-account-wrapper-light-mode .contract-details-container-cut:hover .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  visibility: visible;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  width: auto;
}
.bank-account-wrapper-light-mode .request-list:hover .transform-element,
.bank-account-wrapper-light-mode .contract-details-container-cut:hover .transform-element {
  transform: translate3d(0, 0, 0);
  font-size: 75%;
}
.bank-account-wrapper-flex {
  display: flow-root;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 5;
  -webkit-column-gap: 10px;
  -moz-column-count: 5;
  -moz-column-gap: 10px;
  column-count: 5;
  column-gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}
.bank-account-wrapper-flex .item-settings-dropdown {
  padding: 5px !important;
}
.bank-account-wrapper-flex .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.bank-account-wrapper-flex .request-list,
.bank-account-wrapper-flex .contract-details-container-cut {
  margin-bottom: 10px !important;
  overflow: visible !important;
  border-radius: 8px !important;
  display: inline-block;
  width: 100%;
}
.bank-account-wrapper-flex .request-list h2,
.bank-account-wrapper-flex .contract-details-container-cut h2 {
  font-size: calc(14px * 1.2) !important;
}
.bank-account-wrapper-flex .request-list small,
.bank-account-wrapper-flex .contract-details-container-cut small {
  margin-left: 5px;
}
.bank-account-wrapper-flex .request-list .item-settings-dropdown a:nth-of-type(1),
.bank-account-wrapper-flex .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
  font-size: calc(14px / 1.2) !important;
}
.bank-account-wrapper-flex .request-list .label,
.bank-account-wrapper-flex .contract-details-container-cut .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.bank-account-wrapper-flex .request-list .counterparty_detail_table tr td:first-child,
.bank-account-wrapper-flex .contract-details-container-cut .counterparty_detail_table tr td:first-child {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.bank-account-wrapper-flex .request-list .counterparty_detail_table tr td,
.bank-account-wrapper-flex .contract-details-container-cut .counterparty_detail_table tr td {
  font-size: calc(14px / 1.2) !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-family: 'Regular', sans-serif !important;
}
.bank-account-wrapper-flex .request-list .counterparty_detail_table tr td .label,
.bank-account-wrapper-flex .contract-details-container-cut .counterparty_detail_table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.bank-account-wrapper-flex .request-list .counterparty_detail_table tr td .label-strong,
.bank-account-wrapper-flex .contract-details-container-cut .counterparty_detail_table tr td .label-strong {
  font-family: 'Bold', sans-serif !important;
}
.bank-account-wrapper-flex .request-list .counterparty_detail_table tr td .btn,
.bank-account-wrapper-flex .contract-details-container-cut .counterparty_detail_table tr td .btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.bank-account-wrapper-flex .request-list .counterparty_detail_table tr td .progress .progress-bar,
.bank-account-wrapper-flex .contract-details-container-cut .counterparty_detail_table tr td .progress .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  height: 12px !important;
}
.bank-account-wrapper-flex .request-list .form-control,
.bank-account-wrapper-flex .contract-details-container-cut .form-control {
  height: auto !important;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: 'Bold', sans-serif, 'FontAwesome' !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
.bank-account-wrapper-flex .request-list .form-control span,
.bank-account-wrapper-flex .contract-details-container-cut .form-control span {
  font-size: 14px;
}
.bank-account-wrapper-flex .request-list .ui-select-match-text,
.bank-account-wrapper-flex .contract-details-container-cut .ui-select-match-text {
  word-break: break-all;
}
.bank-account-wrapper-flex .request-list .ui-select-match-text div,
.bank-account-wrapper-flex .contract-details-container-cut .ui-select-match-text div {
  word-break: break-all;
}
.bank-account-wrapper-flex .request-list,
.bank-account-wrapper-flex .contract-details-container-cut {
  min-height: 250px;
}
.bank-account-wrapper-flex .request-list .hover-element,
.bank-account-wrapper-flex .contract-details-container-cut .hover-element {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  visibility: hidden;
}
.bank-account-wrapper-flex .request-list:hover .hover-element,
.bank-account-wrapper-flex .contract-details-container-cut:hover .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  visibility: visible;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  width: auto;
}
.bank-account-wrapper-flex .request-list:hover .transform-element,
.bank-account-wrapper-flex .contract-details-container-cut:hover .transform-element {
  transform: translate3d(0, 0, 0);
  font-size: 75%;
}
.bank-account-wrapper-flex .bank-account-item {
  flex: 0 0 calc(100% / 8 - 14px);
  box-sizing: border-box;
}
.bank-group-name {
  margin-left: 25px;
  margin-bottom: 10px;
  font-family: 'Regular', sans-serif !important;
  letter-spacing: var(--letter-spacing-very-huge, 0.07em);
  -webkit-text-stroke: 0;
}
.bank-wrapper-light-mode {
  display: flow-root;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 5;
  -webkit-column-gap: 10px;
  -moz-column-count: 5;
  -moz-column-gap: 10px;
  column-count: 5;
  column-gap: 10px;
}
.bank-wrapper-light-mode .item-settings-dropdown {
  padding: 5px !important;
}
.bank-wrapper-light-mode .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.bank-wrapper-light-mode .request-list,
.bank-wrapper-light-mode .contract-details-container-cut {
  margin-bottom: 10px !important;
  overflow: visible !important;
  border-radius: 8px !important;
  display: inline-block;
  width: 100%;
}
.bank-wrapper-light-mode .request-list h2,
.bank-wrapper-light-mode .contract-details-container-cut h2 {
  font-size: calc(14px * 1.2) !important;
}
.bank-wrapper-light-mode .request-list small,
.bank-wrapper-light-mode .contract-details-container-cut small {
  margin-left: 5px;
}
.bank-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
.bank-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
  font-size: calc(14px / 1.2) !important;
}
.bank-wrapper-light-mode .request-list .label,
.bank-wrapper-light-mode .contract-details-container-cut .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.bank-wrapper-light-mode .request-list .counterparty_detail_table tr td:first-child,
.bank-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td:first-child {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.bank-wrapper-light-mode .request-list .counterparty_detail_table tr td,
.bank-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td {
  font-size: calc(14px / 1.2) !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-family: 'Regular', sans-serif !important;
}
.bank-wrapper-light-mode .request-list .counterparty_detail_table tr td .label,
.bank-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.bank-wrapper-light-mode .request-list .counterparty_detail_table tr td .label-strong,
.bank-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label-strong {
  font-family: 'Bold', sans-serif !important;
}
.bank-wrapper-light-mode .request-list .counterparty_detail_table tr td .btn,
.bank-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.bank-wrapper-light-mode .request-list .counterparty_detail_table tr td .progress .progress-bar,
.bank-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .progress .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  height: 12px !important;
}
.bank-wrapper-light-mode .request-list .form-control,
.bank-wrapper-light-mode .contract-details-container-cut .form-control {
  height: auto !important;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: 'Bold', sans-serif, 'FontAwesome' !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
.bank-wrapper-light-mode .request-list .form-control span,
.bank-wrapper-light-mode .contract-details-container-cut .form-control span {
  font-size: 14px;
}
.bank-wrapper-light-mode .request-list .ui-select-match-text,
.bank-wrapper-light-mode .contract-details-container-cut .ui-select-match-text {
  word-break: break-all;
}
.bank-wrapper-light-mode .request-list .ui-select-match-text div,
.bank-wrapper-light-mode .contract-details-container-cut .ui-select-match-text div {
  word-break: break-all;
}
.voyage-wrapper-light-mode {
  display: flow-root;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 5;
  -webkit-column-gap: 10px;
  -moz-column-count: 5;
  -moz-column-gap: 10px;
  column-count: 5;
  column-gap: 10px;
}
.voyage-wrapper-light-mode .item-settings-dropdown {
  padding: 5px !important;
}
.voyage-wrapper-light-mode .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.voyage-wrapper-light-mode .request-list,
.voyage-wrapper-light-mode .contract-details-container-cut {
  margin-bottom: 10px !important;
  overflow: visible !important;
  border-radius: 8px !important;
  display: inline-block;
  width: 100%;
}
.voyage-wrapper-light-mode .request-list h2,
.voyage-wrapper-light-mode .contract-details-container-cut h2 {
  font-size: calc(14px * 1.2) !important;
}
.voyage-wrapper-light-mode .request-list small,
.voyage-wrapper-light-mode .contract-details-container-cut small {
  margin-left: 5px;
}
.voyage-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
.voyage-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
  font-size: calc(14px / 1.2) !important;
}
.voyage-wrapper-light-mode .request-list .label,
.voyage-wrapper-light-mode .contract-details-container-cut .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.voyage-wrapper-light-mode .request-list .counterparty_detail_table tr td:first-child,
.voyage-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td:first-child {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.voyage-wrapper-light-mode .request-list .counterparty_detail_table tr td,
.voyage-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td {
  font-size: calc(14px / 1.2) !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-family: 'Regular', sans-serif !important;
}
.voyage-wrapper-light-mode .request-list .counterparty_detail_table tr td .label,
.voyage-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.voyage-wrapper-light-mode .request-list .counterparty_detail_table tr td .label-strong,
.voyage-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label-strong {
  font-family: 'Bold', sans-serif !important;
}
.voyage-wrapper-light-mode .request-list .counterparty_detail_table tr td .btn,
.voyage-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.voyage-wrapper-light-mode .request-list .counterparty_detail_table tr td .progress .progress-bar,
.voyage-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .progress .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  height: 12px !important;
}
.voyage-wrapper-light-mode .request-list .form-control,
.voyage-wrapper-light-mode .contract-details-container-cut .form-control {
  height: auto !important;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: 'Bold', sans-serif, 'FontAwesome' !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
.voyage-wrapper-light-mode .request-list .form-control span,
.voyage-wrapper-light-mode .contract-details-container-cut .form-control span {
  font-size: 14px;
}
.voyage-wrapper-light-mode .request-list .ui-select-match-text,
.voyage-wrapper-light-mode .contract-details-container-cut .ui-select-match-text {
  word-break: break-all;
}
.voyage-wrapper-light-mode .request-list .ui-select-match-text div,
.voyage-wrapper-light-mode .contract-details-container-cut .ui-select-match-text div {
  word-break: break-all;
}
.passport-wrapper-light-mode {
  display: flow-root;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 5;
  -webkit-column-gap: 10px;
  -moz-column-count: 5;
  -moz-column-gap: 10px;
  column-count: 5;
  column-gap: 10px;
}
.passport-wrapper-light-mode .item-settings-dropdown {
  padding: 5px !important;
}
.passport-wrapper-light-mode .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.passport-wrapper-light-mode .request-list,
.passport-wrapper-light-mode .contract-details-container-cut {
  margin-bottom: 10px !important;
  overflow: visible !important;
  border-radius: 8px !important;
  display: inline-block;
  width: 100%;
}
.passport-wrapper-light-mode .request-list h2,
.passport-wrapper-light-mode .contract-details-container-cut h2 {
  font-size: calc(14px * 1.2) !important;
}
.passport-wrapper-light-mode .request-list small,
.passport-wrapper-light-mode .contract-details-container-cut small {
  margin-left: 5px;
}
.passport-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
.passport-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
  font-size: calc(14px / 1.2) !important;
}
.passport-wrapper-light-mode .request-list .label,
.passport-wrapper-light-mode .contract-details-container-cut .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.passport-wrapper-light-mode .request-list .counterparty_detail_table tr td:first-child,
.passport-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td:first-child {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.passport-wrapper-light-mode .request-list .counterparty_detail_table tr td,
.passport-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td {
  font-size: calc(14px / 1.2) !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-family: 'Regular', sans-serif !important;
}
.passport-wrapper-light-mode .request-list .counterparty_detail_table tr td .label,
.passport-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.passport-wrapper-light-mode .request-list .counterparty_detail_table tr td .label-strong,
.passport-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label-strong {
  font-family: 'Bold', sans-serif !important;
}
.passport-wrapper-light-mode .request-list .counterparty_detail_table tr td .btn,
.passport-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.passport-wrapper-light-mode .request-list .counterparty_detail_table tr td .progress .progress-bar,
.passport-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .progress .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  height: 12px !important;
}
.passport-wrapper-light-mode .request-list .form-control,
.passport-wrapper-light-mode .contract-details-container-cut .form-control {
  height: auto !important;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: 'Bold', sans-serif, 'FontAwesome' !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
.passport-wrapper-light-mode .request-list .form-control span,
.passport-wrapper-light-mode .contract-details-container-cut .form-control span {
  font-size: 14px;
}
.passport-wrapper-light-mode .request-list .ui-select-match-text,
.passport-wrapper-light-mode .contract-details-container-cut .ui-select-match-text {
  word-break: break-all;
}
.passport-wrapper-light-mode .request-list .ui-select-match-text div,
.passport-wrapper-light-mode .contract-details-container-cut .ui-select-match-text div {
  word-break: break-all;
}
.agreement-wrapper-light-mode {
  display: flow-root;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 5;
  -webkit-column-gap: 10px;
  -moz-column-count: 5;
  -moz-column-gap: 10px;
  column-count: 5;
  column-gap: 10px;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
}
.agreement-wrapper-light-mode .item-settings-dropdown {
  padding: 5px !important;
}
.agreement-wrapper-light-mode .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.agreement-wrapper-light-mode .request-list,
.agreement-wrapper-light-mode .contract-details-container-cut {
  margin-bottom: 10px !important;
  overflow: visible !important;
  border-radius: 8px !important;
  display: inline-block;
  width: 100%;
}
.agreement-wrapper-light-mode .request-list h2,
.agreement-wrapper-light-mode .contract-details-container-cut h2 {
  font-size: calc(14px * 1.2) !important;
}
.agreement-wrapper-light-mode .request-list small,
.agreement-wrapper-light-mode .contract-details-container-cut small {
  margin-left: 5px;
}
.agreement-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
.agreement-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
  font-size: calc(14px / 1.2) !important;
}
.agreement-wrapper-light-mode .request-list .label,
.agreement-wrapper-light-mode .contract-details-container-cut .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.agreement-wrapper-light-mode .request-list .counterparty_detail_table tr td:first-child,
.agreement-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td:first-child {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.agreement-wrapper-light-mode .request-list .counterparty_detail_table tr td,
.agreement-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td {
  font-size: calc(14px / 1.2) !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-family: 'Regular', sans-serif !important;
}
.agreement-wrapper-light-mode .request-list .counterparty_detail_table tr td .label,
.agreement-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.agreement-wrapper-light-mode .request-list .counterparty_detail_table tr td .label-strong,
.agreement-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label-strong {
  font-family: 'Bold', sans-serif !important;
}
.agreement-wrapper-light-mode .request-list .counterparty_detail_table tr td .btn,
.agreement-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.agreement-wrapper-light-mode .request-list .counterparty_detail_table tr td .progress .progress-bar,
.agreement-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .progress .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  height: 12px !important;
}
.agreement-wrapper-light-mode .request-list .form-control,
.agreement-wrapper-light-mode .contract-details-container-cut .form-control {
  height: auto !important;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: 'Bold', sans-serif, 'FontAwesome' !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
.agreement-wrapper-light-mode .request-list .form-control span,
.agreement-wrapper-light-mode .contract-details-container-cut .form-control span {
  font-size: 14px;
}
.agreement-wrapper-light-mode .request-list .ui-select-match-text,
.agreement-wrapper-light-mode .contract-details-container-cut .ui-select-match-text {
  word-break: break-all;
}
.agreement-wrapper-light-mode .request-list .ui-select-match-text div,
.agreement-wrapper-light-mode .contract-details-container-cut .ui-select-match-text div {
  word-break: break-all;
}
.agreement-wrapper-light-mode .request-list,
.agreement-wrapper-light-mode .contract-details-container-cut {
  width: 48%;
  height: fit-content !important;
}
.agreement-wrapper-light-mode .request-list .ui-select-multiple.ui-select-bootstrap .ui-select-match-item,
.agreement-wrapper-light-mode .contract-details-container-cut .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  overflow: visible;
}
.agreement-wrapper-light-mode .request-list .form-control,
.agreement-wrapper-light-mode .contract-details-container-cut .form-control {
  height: auto !important;
}
.agreement-wrapper-light-mode .contract-details-container-cut-edit {
  height: fit-content !important;
  width: 100%;
  overflow: visible !important;
}
.logistics-wrapper-light-mode {
  display: flow-root;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  -webkit-column-count: 5;
  -webkit-column-gap: 10px;
  -moz-column-count: 5;
  -moz-column-gap: 10px;
  column-count: 5;
  column-gap: 10px;
}
.logistics-wrapper-light-mode .item-settings-dropdown {
  padding: 5px !important;
}
.logistics-wrapper-light-mode .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.logistics-wrapper-light-mode .request-list,
.logistics-wrapper-light-mode .contract-details-container-cut {
  margin-bottom: 10px !important;
  overflow: visible !important;
  border-radius: 8px !important;
  display: inline-block;
  width: 100%;
}
.logistics-wrapper-light-mode .request-list h2,
.logistics-wrapper-light-mode .contract-details-container-cut h2 {
  font-size: calc(14px * 1.2) !important;
}
.logistics-wrapper-light-mode .request-list small,
.logistics-wrapper-light-mode .contract-details-container-cut small {
  margin-left: 5px;
}
.logistics-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
.logistics-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
  font-size: calc(14px / 1.2) !important;
}
.logistics-wrapper-light-mode .request-list .label,
.logistics-wrapper-light-mode .contract-details-container-cut .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.logistics-wrapper-light-mode .request-list .counterparty_detail_table tr td:first-child,
.logistics-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td:first-child {
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', sans-serif !important;
}
.logistics-wrapper-light-mode .request-list .counterparty_detail_table tr td,
.logistics-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td {
  font-size: calc(14px / 1.2) !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-family: 'Regular', sans-serif !important;
}
.logistics-wrapper-light-mode .request-list .counterparty_detail_table tr td .label,
.logistics-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.logistics-wrapper-light-mode .request-list .counterparty_detail_table tr td .label-strong,
.logistics-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .label-strong {
  font-family: 'Bold', sans-serif !important;
}
.logistics-wrapper-light-mode .request-list .counterparty_detail_table tr td .btn,
.logistics-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .btn {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
}
.logistics-wrapper-light-mode .request-list .counterparty_detail_table tr td .progress .progress-bar,
.logistics-wrapper-light-mode .contract-details-container-cut .counterparty_detail_table tr td .progress .progress-bar {
  padding: 2px !important;
  font-size: calc(14px / 1.3) !important;
  height: 12px !important;
}
.logistics-wrapper-light-mode .request-list .form-control,
.logistics-wrapper-light-mode .contract-details-container-cut .form-control {
  height: auto !important;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: 'Bold', sans-serif, 'FontAwesome' !important;
  line-height: 1.5;
  overflow: visible;
  white-space: normal;
}
.logistics-wrapper-light-mode .request-list .form-control span,
.logistics-wrapper-light-mode .contract-details-container-cut .form-control span {
  font-size: 14px;
}
.logistics-wrapper-light-mode .request-list .ui-select-match-text,
.logistics-wrapper-light-mode .contract-details-container-cut .ui-select-match-text {
  word-break: break-all;
}
.logistics-wrapper-light-mode .request-list .ui-select-match-text div,
.logistics-wrapper-light-mode .contract-details-container-cut .ui-select-match-text div {
  word-break: break-all;
}
.logistics-wrapper-light-mode .request-list,
.logistics-wrapper-light-mode .contract-details-container-cut {
  height: 70px;
  width: 9%;
  padding: 2px !important;
  line-height: 1;
  overflow: hidden;
}
.logistics-wrapper-light-mode .request-list h2,
.logistics-wrapper-light-mode .contract-details-container-cut h2 {
  font-size: 14px !important;
  margin: 0 !important;
}
.logistics-wrapper-light-mode .request-list input[type='checkbox'],
.logistics-wrapper-light-mode .contract-details-container-cut input[type='checkbox'],
.logistics-wrapper-light-mode .request-list input[type='radio'],
.logistics-wrapper-light-mode .contract-details-container-cut input[type='radio'] {
  margin: 0;
  width: 13px;
  height: 13px;
}
.logistics-wrapper-light-mode .item-settings-dropdown {
  padding: 2px !important;
}
.passports-wrapper-light-mode {
  overflow-x: auto;
  display: flex;
  padding: 15px;
}
.passports-wrapper-light-mode .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.passports-wrapper-light-mode .request-list {
  min-width: 270px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
  border-radius: 4px !important;
}
.passports-wrapper-light-mode .request-list .progress {
  width: 100%;
  margin: 0 !important;
}
.passports-wrapper-light-mode .request-list .avg-price {
  display: inline;
  position: relative;
  float: right;
  font-family: 'Semibold', sans-serif !important;
}
.passports-wrapper-light-mode .request-list h2,
.passports-wrapper-light-mode .request-list h3,
.passports-wrapper-light-mode .request-list h4,
.passports-wrapper-light-mode .request-list h5,
.passports-wrapper-light-mode .request-list h6 {
  margin-bottom: 0;
  margin-top: 0;
}
.passports-wrapper-light-mode .upcoming-updates-list {
  min-width: 200px;
  max-width: 200px;
  position: relative;
}
.passports-wrapper-light-mode .upcoming-updates-list h4 {
  margin: 0;
}
.passports-wrapper-light-mode .upcoming-updates-list h2,
.passports-wrapper-light-mode .upcoming-updates-list h3,
.passports-wrapper-light-mode .upcoming-updates-list h4,
.passports-wrapper-light-mode .upcoming-updates-list h5,
.passports-wrapper-light-mode .upcoming-updates-list h6 {
  margin-bottom: 0;
  margin-top: 0;
}
.passports-wrapper-light-mode .upcoming-updates-list .wrap-text {
  white-space: normal !important;
  word-wrap: break-word;
  text-align: left;
}
.passports-wrapper-light-mode .upcoming-updates-list .col-md-2,
.passports-wrapper-light-mode .upcoming-updates-list .col-md-3,
.passports-wrapper-light-mode .upcoming-updates-list .col-md-4 {
  text-align: left;
}
.passports-wrapper-light-mode .upcoming-updates-list .well-footer {
  position: absolute;
  bottom: 5px;
  width: 87%;
}
.passports-wrapper-light-mode .upcoming-updates-list .label-big {
  font-size: calc(14px * 1.1) !important;
}
.accordion .card div.ul-inline-row.upcoming-updates-list,
div.ul-inline-row.upcoming-updates-list {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  line-height: 1 !important;
  word-wrap: break-word;
  white-space: normal;
  box-shadow: 0 1px 4px rgba(8, 40, 100, 0.15) !important;
  border-left: 0 !important;
}
.accordion .card div.ul-inline-row.upcoming-updates-list h5,
div.ul-inline-row.upcoming-updates-list h5 {
  margin-bottom: 5px;
}
.accordion .card div.ul-inline-row.upcoming-updates-list .label,
div.ul-inline-row.upcoming-updates-list .label {
  display: inline-flex;
}
.accordion .card div.ul-inline-row.upcoming-updates-list div .form-control.transparent-form,
div.ul-inline-row.upcoming-updates-list div .form-control.transparent-form {
  font-size: calc(14px / 1.3) !important;
  min-height: 10px !important;
  border: 1px solid #e8e6e6 !important;
  border-radius: 6px !important;
  margin-bottom: 5px;
  margin-top: 5px;
}
.executed_request.ul-inline-row.upcoming-updates-list {
  border-top: 0 !important;
  border-radius: 8px !important;
}
.new_request.ul-inline-row.upcoming-updates-list {
  border-top: 0 !important;
  border-radius: 8px !important;
  line-height: 1 !important;
  box-shadow: 0 1px 4px rgba(8, 40, 100, 0.15) !important;
  border-left: 4px solid #07422d !important;
}
.new_request.ul-inline-row.upcoming-updates-list .label {
  display: inline-flex;
}
.warning_request.ul-inline-row.upcoming-updates-list {
  border-top: 0 !important;
  border-radius: 8px !important;
  line-height: 1 !important;
  box-shadow: 0 1px 4px rgba(8, 40, 100, 0.15) !important;
  border-left: 4px solid #595b63 !important;
}
.warning_request.ul-inline-row.upcoming-updates-list .label {
  display: inline-flex;
}
.danger_request.ul-inline-row.upcoming-updates-list {
  border-top: 0 !important;
  border-radius: 8px !important;
  line-height: 1 !important;
  box-shadow: 0 1px 4px rgba(8, 40, 100, 0.15) !important;
  border-left: 4px solid #cf6163 !important;
}
.danger_request.ul-inline-row.upcoming-updates-list .label {
  display: inline-flex;
}
.updates-list-container .executed_request.ul-inline-row.upcoming-updates-list {
  margin-bottom: 15px !important;
  white-space: normal;
}
.updates-list-container .btn-group .btn {
  overflow: visible !important;
}
.updates-list-container .label-warning .gt-date-select .dropdown-menu {
  position: static;
}
.payment-info-wrapper-light-mode {
  overflow-x: auto;
  display: flex;
  padding: 15px;
}
.payment-info-wrapper-light-mode .passports-container {
  min-width: 250px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
}
.payment-info-wrapper-light-mode .request-list {
  min-width: 270px;
  margin-right: 10px;
  float: left;
  overflow: hidden;
  border-radius: 4px !important;
}
.payment-info-wrapper-light-mode .request-list .progress {
  width: 100%;
  margin: 0 !important;
}
.payment-info-wrapper-light-mode .request-list .avg-price {
  display: inline;
  position: relative;
  float: right;
  font-family: 'Semibold', sans-serif !important;
}
.payment-info-wrapper-light-mode .request-list h2,
.payment-info-wrapper-light-mode .request-list h3,
.payment-info-wrapper-light-mode .request-list h4,
.payment-info-wrapper-light-mode .request-list h5,
.payment-info-wrapper-light-mode .request-list h6 {
  margin-bottom: 0;
  margin-top: 0;
}
.payment-info-wrapper-light-mode .upcoming-updates-list {
  min-width: 200px;
  max-width: 200px;
  position: relative;
}
.payment-info-wrapper-light-mode .upcoming-updates-list h4 {
  margin: 0;
}
.payment-info-wrapper-light-mode .upcoming-updates-list h2,
.payment-info-wrapper-light-mode .upcoming-updates-list h3,
.payment-info-wrapper-light-mode .upcoming-updates-list h4,
.payment-info-wrapper-light-mode .upcoming-updates-list h5,
.payment-info-wrapper-light-mode .upcoming-updates-list h6 {
  margin-bottom: 0;
  margin-top: 0;
}
.payment-info-wrapper-light-mode .upcoming-updates-list .wrap-text {
  white-space: normal !important;
  word-wrap: break-word;
  text-align: left;
}
.payment-info-wrapper-light-mode .upcoming-updates-list .col-md-2,
.payment-info-wrapper-light-mode .upcoming-updates-list .col-md-3,
.payment-info-wrapper-light-mode .upcoming-updates-list .col-md-4 {
  text-align: left;
}
.payment-info-wrapper-light-mode .upcoming-updates-list .well-footer {
  position: absolute;
  bottom: 5px;
  width: 87%;
}
.payment-info-wrapper-light-mode .upcoming-updates-list .label-big {
  font-size: calc(14px * 1.1) !important;
}
.payment-info-wrapper-light-mode .request-list {
  min-width: 150px;
}
.payment-info-wrapper-light-mode .request-list h4 {
  width: 100%;
}
.bank-accounts-container .request-list {
  overflow: visible;
  padding: 10px !important;
  padding: 6px;
  box-shadow: none !important;
}
.bank-accounts-container .request-list .counterparty_detail_table .label {
  white-space: nowrap !important;
}
.passport-alert {
  margin-top: -3px;
  margin-left: -5px;
  width: 100%;
}
.upcoming-updates-list h4,
.upcoming-updates-list h5 {
  margin-bottom: 0;
  margin-top: 0;
}
.deals-costs-table .form-control {
  height: 12px !important;
  font-size: calc(14px / 1.3);
}
.deals-costs-table .ui-select-bootstrap > .ui-select-match > .btn {
  line-height: 1 !important;
  padding: 2px;
}
.passport-costs-details {
  border-collapse: separate;
}
.passport-costs-details th {
  font-size: calc(14px / 1.3);
}
.passport-costs-details th label {
  font-size: 14px;
}
.passport-costs-details td {
  padding: 2px !important;
  border-bottom: 1px solid #e8e6e6 !important;
}
.passport-costs-details .highlighted-td {
  background: #fff !important;
  border-right: 1px solid #e8e6e6 !important;
  border-left: 1px solid #e8e6e6 !important;
  border-radius: 0 !important;
}
.passport-costs-details .label {
  float: none;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  font-size: 0.8em;
  padding: 2px !important;
}
.passport-costs-details input[type='checkbox'],
.passport-costs-details input[type='radio'] {
  margin: 0 !important;
  padding: 0 !important;
  width: 20px;
  height: 20px;
}
.loyalty-program-table tr td {
  text-align: right;
}
.loyalty-program-table tr td .label {
  font-size: 14px;
}
.loyalty-program-table tr td.bold {
  font-family: 'Semibold', sans-serif !important;
}
.status-report-table tr td {
  text-align: left;
  font-size: calc(14px / 1.3);
  white-space: nowrap;
}
.status-report-table .label {
  font-size: calc(14px / 1.3) !important;
}
.status-report-table .allow-wrap {
  white-space: normal;
}
.status-report-table tr th.thick-border,
.status-report-table tr td.thick-border {
  border-right: 3px solid #e8e6e6 !important;
}
.status-report-table .highlighted-td {
  background: #f9f9f7 !important;
  border-right: 1px solid #e8e6e6 !important;
  border-left: 0 !important;
}
.status-report-table .alert-danger {
  color: #cf6163 !important;
  font-family: 'Bold', sans-serif !important;
}
.mtm-table-passport tr td {
  font-size: 14px !important;
}
.mtm-table-passport tr th {
  text-align: right !important;
}
.mtm-table-passport tr th:nth-child(1) {
  text-align: left !important;
}
.mtm-table-passport tr th:nth-child(2) {
  text-align: left !important;
}
.passport-process-details tr td:nth-child(2),
.passport-process-details tr td:nth-child(3) {
  width: 10% !important;
}
.passport-process-details tr td {
  vertical-align: top !important;
}
.passport-process-details .form-control {
  margin-bottom: 2px;
  font-size: calc(14px / 1.3) !important;
  padding: 2px;
}
.passport-process-details .form-control[disabled],
.passport-process-details fieldset[disabled] {
  border-radius: 4px !important;
}
.passport-info {
  font-size: calc(14px / 1.3) !important;
}
.passport-info table {
  margin: 0 auto;
}
.passport-info table td {
  padding: 2px;
  font-size: 14px !important;
}
.passport-info table .badge {
  margin-left: 2px;
  margin-right: 2px;
  font-size: 14px !important;
}
.contracts-attached-container-cut {
  display: inline-block;
  margin-right: 5px;
}
.contract-status-container {
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;
  font-size: 14px;
  display: inline-flex;
}
.contract-status-container .fa {
  font-size: 14px !important;
}
.contract-status-container .fa-question-circle {
  font-size: calc(14px / 1.3) !important;
  float: left;
  margin-right: 2px;
}
.contract-status-container .fa-check-circle,
.contract-status-container .fa-circle-o-notch,
.contract-status-container .fa-circle-o,
.contract-status-container .fa-times-circle-o,
.contract-status-container .fa-info-circle {
  float: right;
}
.contract-status-container .tooltip:hover::before {
  top: 45px;
  z-index: 9999 !important;
}
.contract-status-container .inner {
  width: 100%;
  text-align: left;
}
.contract-status-container .inner .label {
  text-align: right;
  float: right;
}
.contract-status-container .inner .label .fa {
  margin-left: 5px;
}
.contract-status-sub-container {
  display: inline;
  vertical-align: top;
}
.contract-status-block {
  text-align: center;
  padding: 2px;
  margin-bottom: 0;
  border-radius: 4px;
  width: 100%;
  font-family: 'Regular', sans-serif !important;
}
.contract-status-block:hover {
  cursor: pointer;
  opacity: 0.9;
}
.contract-status-block .contract_details {
  width: 100%;
  margin-bottom: 2px !important;
  display: block;
}
.contract-status-block .fa-long-arrow-down {
  font-size: calc(14px / 1.3);
  color: #000000;
}
.contract-status-block .sub-status .fa {
  font-size: calc(14px / 1.3) !important;
  padding-left: 10px;
}
.contract-status-block-sub {
  width: 100%;
  min-width: 200px;
  text-align: left;
  border-radius: 4px !important;
  display: block;
  margin-bottom: 2px;
  padding: 2px;
  font-size: calc(14px / 1.3);
  font-family: 'Regular', sans-serif !important;
  text-transform: uppercase;
}
.contract-status-block-td {
  padding: 2px;
  margin-bottom: 0;
  font-size: 0.8em;
  white-space: nowrap;
  width: 100% !important;
  text-transform: uppercase;
}
.contract-status-block-td .contract-status-block {
  width: 98% !important;
  overflow: hidden;
  font-size: 0.7em;
  border-radius: 0.85em;
  box-shadow: none !important;
}
.contract-status-block-td .fa {
  font-size: calc(14px * 1.1);
  display: block;
}
.status_success {
  background: #ebf1ef !important;
  color: #0c4d40 !important;
  opacity: 1 !important;
}
.status_success .fa {
  color: #0c4d40 !important;
}
.status_default {
  color: #595b63;
  opacity: 0.7;
}
.status_default .fa {
  color: #000000;
}
.status_exclamation {
  background: #f0ede9 !important;
  color: #685438 !important;
  opacity: 1 !important;
}
.status_exclamation .fa {
  color: #685438 !important;
}
.status_danger {
  background: rgba(207, 97, 99, 0.13) !important;
  color: #cf6163 !important;
  opacity: 1 !important;
}
.status_danger .fa {
  color: #cf6163 !important;
}
.sorting-filters-row {
  margin-bottom: 5px;
  display: block;
  height: 30px;
}
.sorting-filters-row .btn-link {
  margin-right: 5px;
  padding: 6px 12px;
  font-size: 0.7em !important;
}
.filter-th:first-child {
  border-left: 5px solid #1d82c7 !important;
}
.filter-th {
  border-top: 0 !important;
  border-bottom: 2px solid #e8e6e6 !important;
  background: #fff !important;
}
.filter-th:hover {
  background: #fff !important;
}
.contract-details-small {
  margin-top: 5px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  position: relative;
  display: block;
  overflow-x: auto;
}
.page-header {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.page-header-main {
  margin-right: -35px;
  margin-left: -35px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  margin-bottom: 10px;
}
.page-header-main h1 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  font-family: 'Bold', sans-serif !important;
  font-size: calc(14px * 1.5);
  color: #fff;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  padding: 0px;
}
.page-header-main h1 .fa-caret-down {
  margin-left: 10px;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  transform: translate3d(0, 8px, 0);
  height: 1px;
  display: inline-block;
}
.page-header-main h1 .btn-orange-border {
  color: #fff !important;
  margin-left: 10px !important;
  padding: 2px 6px !important;
}
.page-header-main h1 .user-dropdown-btn {
  padding-left: 0;
  margin-left: 15px;
  font-size: calc(14px * 1.5);
  transition: unset !important;
  transform: unset !important;
  font-family: 'Bold', sans-serif !important;
}
.page-header-main h1 .label_start {
  background: transparent !important;
  color: #fff !important;
  font-size: 14px !important;
  font-family: 'Bold', sans-serif !important;
}
.page-header-main h1 .help-btn-group {
  margin-top: 15px !important;
}
.page-header-main h1 .item-settings-dropdown .dropdown-menu {
  width: 100%;
}
.page-header-main:hover .fa-caret-down {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  height: auto;
}
.page-header-main .payment-details-container {
  border-radius: 4px !important;
  margin-bottom: 10px;
  margin-top: 10px;
}
.page-header-main .payment-details-container .item-details-block-transparent .item-details-el {
  padding: 10px !important;
  margin: 2px !important;
  border-radius: 4px !important;
  background: rgba(0, 0, 0, 0.1) !important;
}
.page-header-main .payment-details-container .item-details-block-transparent .item-details-el .label {
  display: block;
  text-align: center;
  padding: 2px !important;
  float: none;
  margin: 0 auto !important;
}
.page-header-main .payment-details-container .item-details-block-transparent {
  -webkit-box-pack: initial;
  -webkit-box-align: center;
}
.page-header-main .payment-details-container .contract-details-table tr td,
.page-header-main .payment-details-container .contract-details-table tr th {
  color: #fff !important;
  border: 0 !important;
}
.page-header-main .payment-details-container .contract-details-table tr th {
  display: none !important;
}
.page-header-main .updated_time {
  color: #fff !important;
  margin-left: 5px;
  vertical-align: middle;
  line-height: 1;
}
.page-header-main .btn-success {
  background: transparent !important;
  color: #fff !important;
  margin-top: 0;
  border: 0 !important;
  margin-left: 0;
  box-shadow: none !important;
}
.page-header-main .btn-success:hover {
  background: #031911 !important;
  color: #fff !important;
}
.page-header-main .user-dropdown-btn {
  padding-top: 5px;
  padding-bottom: 5px;
}
.page-header-main .nav .btn .label_start {
  background: transparent !important;
}
.page-header-main .nav .open > a {
  background: rgba(0, 0, 0, 0.01);
}
.page-header-main .nav > li > a:focus,
.page-header-main .nav > li > a:hover {
  background: rgba(0, 0, 0, 0.01);
}
.page-header-main .main-nav-tabs .label_start {
  background: transparent !important;
  color: #fff !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.page-header-main .main-nav-tabs li a {
  background: rgba(0, 0, 0, 0.3) !important;
  color: #ffffff;
  padding: 8px !important;
  padding-bottom: 4px !important;
  padding-top: 4px !important;
  font-family: 'Regular', sans-serif !important;
  border-bottom: 1px solid transparent !important;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  font-size: calc(14px / 1.2) !important;
}
.page-header-main .main-nav-tabs li a::first-letter {
  text-transform: uppercase !important;
}
.page-header-main .main-nav-tabs li.active > a {
  color: #666b72 !important;
  background: #eeedeb url(img/top-bg-white.png) !important;
  border-color: transparent !important;
  font-family: 'Semibold', sans-serif !important;
  padding-top: 6px !important;
}
.page-header-main .main-nav-tabs li.active > a .label_start {
  color: #666b72 !important;
  font-family: 'Semibold', sans-serif !important;
}
.page-header-main .main-nav-tabs li.active:hover > a {
  color: #000000 !important;
  background: #eeedeb url(img/top-bg-white.png) !important;
  border-color: transparent !important;
}
.page-header-main .main-nav-tabs li:hover > a {
  background: rgba(0, 0, 0, 0.15) !important;
  border-color: transparent !important;
}
.page-header-main .form-control {
  background: #eeedeb !important;
}
.page-header-main small {
  margin-top: 23px;
  margin-left: 5px;
}
.page-header-main .help-btn-group a {
  background: #fff !important;
}
.page-header-main .help-btn-group a.btn-info {
  background: #1d82c7 !important;
  color: #fff !important;
}
.page-header-main .help-btn-group a:hover {
  background: #eeedeb !important;
}
.page-header-main .help-btn-group a.btn-info:hover {
  background: #1d82c7 !important;
}
.page-header-main .help-btn-group .navbar-nav .user-dropdown-btn {
  color: #eeedeb !important;
}
.page-header-main .user-dropdown-btn {
  color: #fff !important;
}
.page-header-main .user-dropdown-btn:hover {
  color: #fff !important;
}
h1 .user-dropdown-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.page-header-main-info-tab {
  background: #243240 !important;
}
.page-header-main-info-tab .help-btn-group a {
  color: #34495e !important;
}
.page-header-main-pl-tab {
  background: #cdaf9b !important;
}
.page-header-main-pl-tab .help-btn-group a {
  color: #cdaf9b !important;
}
.page-header-main-crm-tab {
  background: #9597b1 !important;
}
.page-header-main-crm-tab .help-btn-group a {
  color: #9597b1 !important;
}
.page-header-main-prices-tab {
  background: #d4b668 !important;
}
.page-header-main-prices-tab .help-btn-group a {
  color: #d4b668 !important;
}
.page-header-main-logistics-tab {
  background: #d49091 !important;
}
.page-header-main-logistics-tab .help-btn-group a {
  color: #d49091 !important;
}
.page-header-main-payments-tab {
  background: #9bbacd !important;
}
.page-header-main-payments-tab .help-btn-group a {
  color: #9bbacd !important;
}
.page-header-main-clients-tab {
  background: #81af9f !important;
}
.page-header-main-clients-tab .help-btn-group a {
  color: #81af9f !important;
}
.left-sidebar {
  padding-top: 60px;
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}
.left-sidebar .contract_details a {
  cursor: pointer;
}
.left-sidebar .badge.contract_details {
  padding: 2px !important;
  margin-bottom: 0px !important;
}
.left-sidebar .cut-table {
  margin-bottom: 30px !important;
}
.central-container .contract_details {
  font-size: calc(14px / 1.3);
}
.central-container .label-opacity.contract_details {
  font-size: calc(14px / 1.3) !important;
  background: transparent !important;
}
.central-container .badge.contract_details {
  font-size: calc(14px / 1.3);
  padding: 2px !important;
  margin-bottom: 5px;
}
table tr td.no-data-row {
  padding: 0;
}
.ui-select-bootstrap > .ui-select-choices,
.ui-select-bootstrap > .ui-select-no-choice {
  border-radius: 4px !important;
  padding: 10px !important;
  min-width: 100%;
  max-height: 350px;
  background: #fff;
}
.ui-select-bootstrap > .ui-select-choices::before {
  border-radius: 4px;
  color: #1d82c7;
  background: rgba(212, 232, 240, 0.7);
  content: 'Start typing to search in the list';
  padding: 4px;
  position: relative;
  opacity: 1;
  visibility: visible;
  white-space: normal;
  word-wrap: break-word;
  font-size: calc(14px / 1.3) !important;
  font-family: 'Semibold', sans-serif !important;
  text-transform: inherit !important;
  line-height: 1;
  margin-bottom: 5px;
  display: block;
  text-align: center;
  width: 100%;
}
.no_content_text {
  margin: 0 !important;
  text-transform: none !important;
  padding: 10px;
  font-size: calc(14px / 1.3) !important;
  color: #666b72 !important;
  white-space: normal;
  font-family: 'Regular', sans-serif !important;
  -webkit-text-stroke: 0;
  border: 0 !important;
  text-align: center;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;
}
.show-more-block {
  background: rgba(0, 0, 0, 0.03);
}
.show-more-block td {
  background: rgba(0, 0, 0, 0.03);
}
.no_content_text td {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  color: #666b72 !important;
  text-align: center !important;
  font-size: calc(14px / 1.3) !important;
  -webkit-text-stroke: 0;
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
}
.no_content_text td:hover {
  cursor: default;
}
#contract_errors {
  color: #cf6163 !important;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
  background: #f7f7f7;
  font-size: 1.5em;
}
.export_img {
  opacity: 0.2;
  background-size: cover;
  margin-right: 15px;
  height: 20px;
  width: 20px;
}
.export_img:hover {
  opacity: 0.7;
}
.print-item {
  display: none;
}
.search-container {
  border: 1px solid #d2cece !important;
  border-radius: 4px !important;
}
.search-container input {
  border-radius: 0 !important;
}
.search-container:focus,
.search-container:active,
.search-container:hover {
  border: 1px solid #beb9b9 !important;
  border-radius: 4px !important;
}
.search-container .btn {
  padding: 2px;
  background: transparent !important;
}
.search {
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  background: transparent;
  font-family: 'Regular', sans-serif, Helvetica, Arial, sans-serif !important;
  color: #000000;
  border: 0 !important;
  outline: none;
  box-shadow: none !important;
  border-bottom: 1px solid #666b72;
  border-radius: 0;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  height: 26px;
}
.filter {
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  background: transparent;
  font-family: 'Regular', sans-serif, Helvetica, Arial, sans-serif !important;
  color: #000000;
  border: 0 !important;
  outline: none;
  box-shadow: none !important;
  border-bottom: 1px solid #666b72;
  border-radius: 0;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  height: 26px;
}
.filter::-moz-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.filter::-webkit-input-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.filter:-ms-input-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.search::-webkit-input-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.search::-moz-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.search:-ms-input-placeholder {
  color: #000000 !important;
  font-size: calc(14px / 1.3) !important;
  font-family: 'Regular', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
/* ie */
.password-form div input::-webkit-input-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f023  ';
}
.password-form div input::-moz-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f023  ';
}
/* firefox 19+ */
.password-form div input:-ms-input-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f023  ';
}
/* ie */
.password-form div input:-moz-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f023  ';
}
.password-form div input::-webkit-input-placeholder {
  color: #000000;
  font-family: 'Light', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.password-form div input::-moz-placeholder {
  color: #000000;
  font-family: 'Light', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
/* firefox 19+ */
.password-form div input:-ms-input-placeholder {
  color: #000000;
  font-family: 'Light', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
/* ie */
.password-form div input:-moz-placeholder {
  color: #000000;
  font-family: 'Light', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.login-form div input::-webkit-input-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f007  ';
}
.login-form div input::-moz-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f007  ';
}
/* firefox 19+ */
.login-form div input:-ms-input-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f007  ';
}
/* ie */
.login-form div input:-moz-placeholder::before {
  font-family: 'FontAwesome';
  content: '\f007  ';
}
.login-form div input::-webkit-input-placeholder {
  color: #000000;
  font-family: 'Extrabld', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.login-form div input::-moz-placeholder {
  color: #000000;
  font-family: 'Extrabld', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
/* firefox 19+ */
.login-form div input:-ms-[input]-placeholder {
  color: #000000;
  font-family: 'Extrabld', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
/* ie */
.login-form div input:-moz-placeholder {
  color: #000000;
  font-family: 'Extrabld', 'FontAwesome';
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.form-login.form-horizontal .checkbox {
  padding-left: 0 !important;
  margin-left: 0 !important;
  text-align: right !important;
  margin-bottom: 7px;
}
.form-login.form-horizontal #Terms {
  margin-left: 25px !important;
}
.form-login.form-horizontal .checkbox-inline {
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  font-family: 'Light', sans-serif !important;
}
.form-login.form-horizontal .form-control {
  height: 44px;
  border: 0 !important;
  background-color: #f2f2ee !important;
  border-left: 0 !important;
}
.form-login.form-horizontal .form-control:focus {
  border: 0 solid #e8e6e6 !important;
  background-color: #e5e5dd !important;
  border-left: 0 !important;
}
.comment-form {
  border: 1px solid #bdc3c7 !important;
  border-radius: 4px !important;
  width: 93%;
  text-align: left !important;
}
#left-sidebar {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.scrollable {
  height: 800px;
  overflow: hidden;
}
.scrollable:hover {
  overflow: auto;
}
/*hack for disabling scrollbar in FF*/
@-moz-document url-prefix() {
  .scrollable {
    height: auto;
    overflow: visible;
  }
}
.left-sidebar-buttons {
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 84% !important;
  margin-bottom: 10px !important;
  font-size: 0.9em;
  padding: 2px 10px;
  display: block;
}
.contract-properties {
  display: none;
}
.contract-description-label {
  color: #bdc3c7;
}
.contract-description-item {
  min-height: 50px;
  font-size: 0.9em;
}
.contract-description-item-value {
  text-align: center !important;
  font-size: 1.2em;
  color: #666;
}
.contract-description-item-value span {
  color: #7f8c8d;
  font-size: 0.7em !important;
}
.contract-description-item-title {
  text-align: center !important;
  font-size: 0.9em / 1.2;
  font-weight: 600;
}
.documents-list-table {
  margin-bottom: 0 !important;
}
.documents-list-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.documents-list-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.documents-list-table .datetimepicker thead tr th:nth-child(1),
.documents-list-table .datetimepicker thead tr th:nth-child(2),
.documents-list-table .datetimepicker thead tr th:nth-child(3),
.documents-list-table .datetimepicker tbody tr td:nth-child(1),
.documents-list-table .datetimepicker tbody tr td:nth-child(2),
.documents-list-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.documents-list-table .datetimepicker .table thead tr th:nth-child(6),
.documents-list-table .datetimepicker .table thead tr th:nth-child(7),
.documents-list-table .datetimepicker .table tbody tr td:nth-child(6),
.documents-list-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.documents-list-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.documents-list-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.documents-list-table tr td {
  font-size: calc(14px / 1.3);
  border: 0 !important;
}
.documents-list-table tr td .ui-select-match-text {
  white-space: nowrap;
}
.documents-list-table .no-data-img {
  width: 50px;
  height: 50px;
}
.docs-check li,
.docs-list li {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: calc(14px / 1.1);
  margin-bottom: 5px !important;
  color: #000000 !important;
}
.docs-list li:hover {
  background: #e8e6e6;
}
.docs-list p {
  margin-right: 10px;
  margin-top: 0;
  line-height: 1.5;
  text-align: right;
  word-break: break-all;
  overflow: hidden;
  color: #000000 !important;
}
.docs-list .docs-ico {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  background-size: contain !important;
}
.docs-list .docs-word {
  background: url(img/word-ico.png);
}
.docs-list .docs-xls {
  background: url(img/excel-ico.png);
}
.docs-list .docs-pdf {
  background: url(img/pdf-ico.png);
}
.table-template .docs-list-small li:hover,
.docs-list-small li:hover {
  background: #e8e6e6;
}
.table-template .docs-list-small p,
.docs-list-small p {
  margin-right: 10px;
  margin-top: 0;
  line-height: 1.5;
  text-align: right;
  word-break: break-all;
  overflow: hidden;
  color: #000000 !important;
}
.table-template .docs-list-small .docs-ico,
.docs-list-small .docs-ico {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  background-size: contain !important;
}
.table-template .docs-list-small .docs-word,
.docs-list-small .docs-word {
  background: url(img/word-ico.png);
}
.table-template .docs-list-small .docs-xls,
.docs-list-small .docs-xls {
  background: url(img/excel-ico.png);
}
.table-template .docs-list-small .docs-pdf,
.docs-list-small .docs-pdf {
  background: url(img/pdf-ico.png);
}
.table-template .docs-list-small td,
.docs-list-small td,
.table-template .docs-list-small td:nth-child(1),
.docs-list-small td:nth-child(1),
.table-template .docs-list-small td:nth-child(2),
.docs-list-small td:nth-child(2) {
  padding: 2px !important;
  border-top: 0 !important;
  border-bottom: 1px solid #d2cece !important;
  vertical-align: middle !important;
  color: #000000 !important;
  border: 0 !important;
  font-size: calc(14px / 1.1);
}
.table-template .docs-list-small td .label,
.docs-list-small td .label,
.table-template .docs-list-small td:nth-child(1) .label,
.docs-list-small td:nth-child(1) .label,
.table-template .docs-list-small td:nth-child(2) .label,
.docs-list-small td:nth-child(2) .label {
  font-size: calc(14px / 1.1);
}
.docs-uploaded-img-small {
  background-size: contain !important;
  width: 20px !important;
  height: 20px !important;
  border: 0 !important;
  margin-left: 0 !important;
}
.docs-uploaded-span-small {
  font-size: calc(14px / 1.3);
  color: #000000 !important;
}
.formly-field .form-group.col-xs-12 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.modal-form-container .modal-form-block {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 10px !important;
}
.modal-form-container .modal-form-block .ul-inline-row {
  background: #fff !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 8px;
}
.modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border-radius: 4px !important;
}
.modal-form-container .modal-form-block .ul-inline-row .btn-add-dark {
  border-radius: 4px !important;
  border: 2px solid #34495e !important;
  color: #34495e !important;
}
.modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover {
  color: #fff !important;
}
.modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border: 2px solid #34495e !important;
  background: #34495e !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.modal-form-container .contract-description-label {
  font-size: 14px;
  color: #1f506b !important;
  font-family: 'Bold', sans-serif !important;
}
.modal-form-container .form-group .btn-default {
  background: transparent !important;
}
.modal-form-container .form-group .input-group-addon {
  border-radius: 0 !important;
  padding: 2px !important;
}
.modal-form-container .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 0;
  padding-top: 5px;
  width: 2%;
  float: left;
  display: none;
}
.modal-form-container .input-group-addon-search .fa-search {
  display: none;
}
.modal-form-container .btn:hover,
.modal-form-container .btn:focus {
  transform: none;
  transition: none;
}
.modal-form-container .copy-clipboard-btn {
  display: none;
}
.modal-form-container .not-empty:hover .copy-clipboard-btn {
  display: block !important;
  position: absolute;
  right: 15px;
  top: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  z-index: 9999 !important;
}
.modal-form-container .not-empty:hover .copy-clipboard-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.modal-form-container .select-with-btn {
  width: 85%;
  float: left;
}
.modal-form-container .select-with-btn .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}
.modal-form-container .gt-date-select .dropdown-menu {
  left: -70px !important;
}
.modal-form-container .add-btn {
  color: #666b72 !important;
}
.modal-form-container .form-add-btn {
  padding: 0;
  width: 12%;
  float: right;
}
.modal-form-container .form-add-btn a {
  height: 10px;
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: calc(14px / 1.3);
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #666b72 !important;
}
.modal-form-container .form-add-btn a:hover {
  color: #07422d !important;
  background: transparent !important;
}
.modal-form-container .form-control .fa-remove {
  font-size: calc(14px / 1.3) !important;
}
.modal-form-container .form-add-btn-fullwidth {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.modal-form-container .form-add-btn-fullwidth a {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px;
  font-size: 14px;
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
}
.modal-form-container .form-add-btn-fullwidth a:hover {
  background: #07422d !important;
}
.modal-form-container .form-dismiss-select {
  position: absolute;
  right: 65px;
  top: 8px;
  font-size: 15px !important;
}
.modal-form-container input[type='date'] {
  width: 100% !important;
  float: left;
}
.modal-form-container .input-group {
  width: 100%;
}
.modal-form-container select {
  background: transparent !important;
}
.modal-form-container select option {
  color: #666b72 !important;
}
.modal-form-container select option[selected] {
  color: #000000 !important;
  background: #fff !important;
  font-family: 'Semibold', sans-serif !important;
}
.modal-form-container .caret {
  display: none;
}
.modal-form-container input[type='checkbox'],
.modal-form-container input[type='radio'] {
  margin: 0;
  width: 15px;
  height: 15px;
}
.modal-form-container .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.modal-form-container input[type='date'] {
  background: transparent !important;
  border-radius: 0 !important;
}
.modal-form-container .counterparty-block .modal-form-block {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.modal-form-container .counterparty-block .form-group {
  width: 55%;
}
.ui-select-container .form-search-select {
  display: none;
}
.ui-select-container .form-control {
  font-family: 'Regular', sans-serif, 'FontAwesome' !important;
  background: transparent !important;
}
.ui-select-container.open .form-search-select {
  display: block;
  color: #000000;
  position: absolute;
  left: 5px;
  top: 12px;
  font-size: 9px !important;
}
.ui-select-multiple {
  overflow: visible !important;
}
.ui-select-multiple .close {
  float: right !important;
}
.ui-select-multiple .btn {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: block;
  width: auto;
}
.ui-select-multiple input {
  width: 100% !important;
}
.analytics-container .ui-select-multiple.ui-select-bootstrap {
  margin-bottom: 5px;
}
.analytics-container label {
  padding-left: 5px;
}
.analytics-container .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  font-size: calc(14px / 1.3) !important;
}
.analytics-container .ui-select-multiple.ui-select-bootstrap input.ui-select-search::-webkit-input-placeholder {
  font-size: calc(14px / 1.3) !important;
}
.analytics-container .ui-select-multiple.ui-select-bootstrap input.ui-select-search::-webkit-input-placeholder::first-letter {
  text-transform: uppercase !important;
}
.ui-select-multiple.ui-select-bootstrap {
  padding: 5px;
}
.form-group-container .form-group .ui-select-multiple {
  padding-left: 0 !important;
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  background: transparent !important;
  font-family: 'Regular', sans-serif, sans-serif !important;
  border-radius: 4px !important;
  margin-bottom: 0;
  font-size: 14px !important;
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search::-webkit-input-placeholder {
  color: #000000 !important;
  text-overflow: ellipsis;
}
.ng-not-empty.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  font-family: 'Semibold', sans-serif sans-serif !important;
  background: #efefef !important;
  border: 1px solid #e1dede !important;
}
.form-group-container {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.form-group-container .form-group {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.form-group-container .form-group .control-label {
  font-size: calc(14px / 1.2) !important;
  padding-top: 0 !important;
  padding-left: 0px !important;
  margin: 0 !important;
  color: #666;
  font-family: 'Regular', sans-serif !important;
  text-align: left !important;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 24px;
  line-height: 1 !important;
  display: flex;
  align-items: center;
  word-break: break-word;
  text-transform: capitalize !important;
}
.form-group-container .form-group .control-label .check-label {
  float: left;
  text-align: left !important;
  padding-right: 4px;
  font-size: calc(14px / 1.5) !important;
}
.form-group-container .form-group .control-label .check-label .fa-circle {
  color: #efefef;
}
.form-group-container .form-group .not-empty .control-label .fa-circle {
  color: #1bb394 !important;
}
.form-group-container .form-group .not-emptyhover .control-label .fa-circle {
  color: #1bb394;
}
.form-group-container .form-group .ui-select-container {
  padding-left: 0 !important;
}
.form-group-container .form-group input {
  padding-left: 0 !important;
}
.form-group-container .form-group input.form-control {
  padding-left: 0 !important;
}
.form-group-container .form-group .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  font-size: 14px !important;
  padding-left: 0;
  margin: 0;
}
.form-group-container .form-group .ui-select-multiple.ui-select-bootstrap .ui-select-match-item::-webkit-input-placeholder {
  display: none !important;
  color: #000000 !important;
  font-size: 14px !important;
  text-overflow: ellipsis;
}
.form-group-container .form-group .ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
  line-height: 1;
}
.form-group-container .form-group .form-control {
  border: 0 !important;
  padding-left: 0 !important;
  height: auto;
  font-size: calc(14px / 1.2) !important;
  overflow: visible;
  white-space: normal;
  line-height: 1;
}
.form-group-container .form-group .form-control::-webkit-input-placeholder {
  display: none !important;
  color: transparent !important;
  font-size: calc(14px / 1.2) !important;
  text-overflow: ellipsis;
}
.form-group-container .form-group .form-control::-moz-placeholder {
  display: none !important;
  color: transparent !important;
  font-size: calc(14px / 1.2) !important;
  text-overflow: ellipsis;
}
.form-group-container .form-group .form-control:-moz-placeholder {
  display: none !important;
  color: transparent !important;
  font-size: calc(14px / 1.2) !important;
  text-overflow: ellipsis;
}
.form-group-container .form-group .form-control:-ms-input-placeholder {
  display: none !important;
  color: transparent !important;
  font-size: calc(14px / 1.2) !important;
  text-overflow: ellipsis;
}
.form-group-container .form-group .form-control .text-muted {
  font-size: calc(14px / 1.2) !important;
  color: transparent !important;
}
.form-group-container .form-group .not-empty .form-control,
.form-group-container .form-group .not-empty .form-control .ui-select-match-text {
  font-family: 'Regular', sans-serif !important;
}
.form-group-container .form-group .not-empty .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  font-family: 'Regular', sans-serif !important;
  font-size: calc(14px / 1.2) !important;
  white-space: normal;
  word-break: break-word;
  line-height: 1;
}
.form-group-container .form-group .not-empty .ui-select-multiple.ui-select-bootstrap .ui-select-match-item .updating {
  display: none;
}
.form-group-container .form-group .not-empty .control-label {
  font-family: 'Regular', sans-serif !important;
}
.form-group-container .form-group.col-xs-12 {
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 2px !important;
  border-radius: 0;
  padding: 0px !important;
}
.form-group-container .form-group.col-xs-12:hover .control-label .fa-circle {
  color: #a5a5a5 !important;
}
.form-group-container .has-error .control-label,
.form-group-container .has-error .form-group .col-md-7 {
  height: 30px !important;
}
.form-group-container .has-error .control-label .fa-circle {
  color: #cf6163 !important;
}
.form-group-container .form-group .col-md-7 {
  height: 20px;
  line-height: 1;
  border-bottom: 1px solid #e1dede !important;
}
.form-group-container .form-group .col-md-7 .input-group-addon {
  color: #666;
}
.form-group-container .form-group .col-md-7 .input-group-addon .fa {
  color: #666;
}
.form-group-container .not-empty .form-group .col-md-7,
.form-group-container .has-error .form-group .col-md-7 {
  height: auto !important;
}
.form-group-container .gt_test_wrapper_contract_prices,
.form-group-container .gt_test_wrapper_derivatives,
.form-group-container .gt_test_wrapper_few_commodity,
.form-group-container .gt_test_wrapper_role_names,
.form-group-container .gt_test_wrapper_extra_actions,
.form-group-container .gt_test_wrapper_contract_consignees {
  height: auto !important;
}
.form-group-container .form-group.col-xs-12.focused {
  background: rgba(0, 0, 0, 0.01) !important;
}
.form-group-container .form-group.not-empty {
  font-family: 'Regular', sans-serif !important;
}
.form-group-container .has-error .form-group {
  background: rgba(207, 97, 99, 0.13) !important;
  border-color: #cf6163 !important;
}
.form-group-container select[multiple],
.form-group-container select[size] {
  overflow: auto !important;
}
.form-group-container textarea.form-control {
  resize: auto !important;
}
.form-group-comparison-container .modal-form-block {
  margin-top: -45px !important;
}
.form-group-comparison-container .ui-select-bootstrap > .ui-select-match > .btn {
  line-height: 1 !important;
}
.form-group-container .form-group .contract-price-widget .form-control {
  line-height: 1 !important;
  border-bottom: 1px solid #d2cece !important;
  text-align: left !important;
  height: 22px !important;
  width: 100% !important;
}
.contract-price-widget {
  background: #ebebeb;
  padding: 12px;
  overflow: visible;
}
.contract-price-widget label {
  font-size: calc(14px / 1.3) !important;
  text-align: right !important;
}
.contract-price-widget .form-group-container .form-group .form-control {
  line-height: 1 !important;
  text-align: left !important;
  width: 100% !important;
  display: block;
  height: auto !important;
  overflow: auto;
}
.contract-price-widget .btn-default {
  line-height: 1 !important;
}
.contract-price-widget .btn-default.form-control {
  line-height: 1 !important;
}
.contract-price-widget .input-group .col-sm-5 {
  text-align: right !important;
}
.contract-price-widget .input-group .btn-default {
  display: inline-block;
}
.contract-price-widget .input-group .col-sm-7 {
  padding-left: 0;
  padding-right: 0;
}
.contract-price-widget .input-group .col-sm-5 {
  padding-left: 0;
}
.gt_test_wrapper_contract_prices,
.gt_test_wrapper_derivatives,
.gt_test_wrapper_few_commodity,
.gt_test_wrapper_contract_consignees {
  width: 100%;
}
.contract-price-widget-table tr td {
  vertical-align: middle !important;
  width: auto;
  font-size: calc(14px / 1.3) !important;
  padding: 2px !important;
}
.contract-price-widget-table tr td .form-control {
  width: 100% !important;
  display: block;
  height: 22px !important;
}
.contract-price-widget-table tr td:nth-child(1) {
  width: 10%;
}
.form-group-container .form-group .contract-price-widget-table .form-control {
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 100% !important;
  display: block;
  height: 22px !important;
  font-size: calc(14px / 1.3) !important;
}
.role-form-group-container .modal-form-block {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.finance-modal-form-container .form-group-container.main-block-wide .modal-form-block {
  width: 100%;
}
.client-modal-form-container .form-group-container.main-block-wide .modal-form-block {
  width: 100%;
}
.form-group-container.main-block-wide {
  display: inline-block;
  margin-bottom: 5px !important;
  margin-left: 5px !important;
  margin-right: 10px !important;
  background: #fff !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 99%;
}
.form-group-container.main-block-wide .modal-form-block {
  width: 33%;
  float: left;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 0 !important;
}
.form-group-container.main-block-wide .modal-form-block .ul-inline-row {
  text-align: left;
  background: transparent !important;
  border: 0 !important;
  box-shadow: none;
}
.formly-field-gt-ui-multiselect .form-control {
  height: auto !important;
}
.input-group .form-control {
  border-radius: 4px !important;
}
.input-group .form-control:first-child {
  border-top-left-radius: 0.25em !important;
  border-bottom-left-radius: 0.25em !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group .form-control:last-child {
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group-inline .form-control {
  width: auto !important;
  display: inline-block;
  height: 30px;
}
.input-group-inline .input-group-btn {
  display: inline-block;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  box-shadow: none !important;
}
.input-group-inline .input-group-btn:last-child > .btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.request-modal-form-container .modal-form-block {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 10px !important;
}
.request-modal-form-container .modal-form-block .ul-inline-row {
  background: #fff !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 8px;
}
.request-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.request-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border-radius: 4px !important;
}
.request-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark {
  border-radius: 4px !important;
  border: 2px solid #34495e !important;
  color: #34495e !important;
}
.request-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover {
  color: #fff !important;
}
.request-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border: 2px solid #34495e !important;
  background: #34495e !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.request-modal-form-container .contract-description-label {
  font-size: 14px;
  color: #1f506b !important;
  font-family: 'Bold', sans-serif !important;
}
.request-modal-form-container .form-group .btn-default {
  background: transparent !important;
}
.request-modal-form-container .form-group .input-group-addon {
  border-radius: 0 !important;
  padding: 2px !important;
}
.request-modal-form-container .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 0;
  padding-top: 5px;
  width: 2%;
  float: left;
  display: none;
}
.request-modal-form-container .input-group-addon-search .fa-search {
  display: none;
}
.request-modal-form-container .btn:hover,
.request-modal-form-container .btn:focus {
  transform: none;
  transition: none;
}
.request-modal-form-container .copy-clipboard-btn {
  display: none;
}
.request-modal-form-container .not-empty:hover .copy-clipboard-btn {
  display: block !important;
  position: absolute;
  right: 15px;
  top: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  z-index: 9999 !important;
}
.request-modal-form-container .not-empty:hover .copy-clipboard-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.request-modal-form-container .select-with-btn {
  width: 85%;
  float: left;
}
.request-modal-form-container .select-with-btn .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}
.request-modal-form-container .gt-date-select .dropdown-menu {
  left: -70px !important;
}
.request-modal-form-container .add-btn {
  color: #666b72 !important;
}
.request-modal-form-container .form-add-btn {
  padding: 0;
  width: 12%;
  float: right;
}
.request-modal-form-container .form-add-btn a {
  height: 10px;
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: calc(14px / 1.3);
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #666b72 !important;
}
.request-modal-form-container .form-add-btn a:hover {
  color: #07422d !important;
  background: transparent !important;
}
.request-modal-form-container .form-control .fa-remove {
  font-size: calc(14px / 1.3) !important;
}
.request-modal-form-container .form-add-btn-fullwidth {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.request-modal-form-container .form-add-btn-fullwidth a {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px;
  font-size: 14px;
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
}
.request-modal-form-container .form-add-btn-fullwidth a:hover {
  background: #07422d !important;
}
.request-modal-form-container .form-dismiss-select {
  position: absolute;
  right: 65px;
  top: 8px;
  font-size: 15px !important;
}
.request-modal-form-container input[type='date'] {
  width: 100% !important;
  float: left;
}
.request-modal-form-container .input-group {
  width: 100%;
}
.request-modal-form-container select {
  background: transparent !important;
}
.request-modal-form-container select option {
  color: #666b72 !important;
}
.request-modal-form-container select option[selected] {
  color: #000000 !important;
  background: #fff !important;
  font-family: 'Semibold', sans-serif !important;
}
.request-modal-form-container .caret {
  display: none;
}
.request-modal-form-container input[type='checkbox'],
.request-modal-form-container input[type='radio'] {
  margin: 0;
  width: 15px;
  height: 15px;
}
.request-modal-form-container .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.request-modal-form-container input[type='date'] {
  background: transparent !important;
  border-radius: 0 !important;
}
.request-modal-form-container .counterparty-block .modal-form-block {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.request-modal-form-container .counterparty-block .form-group {
  width: 55%;
}
.request-modal-form-container input[type='date'] {
  width: 96% !important;
  float: left;
}
.client-modal-form-container .modal-form-block {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 10px !important;
}
.client-modal-form-container .modal-form-block .ul-inline-row {
  background: #fff !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 8px;
}
.client-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.client-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border-radius: 4px !important;
}
.client-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark {
  border-radius: 4px !important;
  border: 2px solid #34495e !important;
  color: #34495e !important;
}
.client-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover {
  color: #fff !important;
}
.client-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border: 2px solid #34495e !important;
  background: #34495e !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.client-modal-form-container .contract-description-label {
  font-size: 14px;
  color: #1f506b !important;
  font-family: 'Bold', sans-serif !important;
}
.client-modal-form-container .form-group .btn-default {
  background: transparent !important;
}
.client-modal-form-container .form-group .input-group-addon {
  border-radius: 0 !important;
  padding: 2px !important;
}
.client-modal-form-container .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 0;
  padding-top: 5px;
  width: 2%;
  float: left;
  display: none;
}
.client-modal-form-container .input-group-addon-search .fa-search {
  display: none;
}
.client-modal-form-container .btn:hover,
.client-modal-form-container .btn:focus {
  transform: none;
  transition: none;
}
.client-modal-form-container .copy-clipboard-btn {
  display: none;
}
.client-modal-form-container .not-empty:hover .copy-clipboard-btn {
  display: block !important;
  position: absolute;
  right: 15px;
  top: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  z-index: 9999 !important;
}
.client-modal-form-container .not-empty:hover .copy-clipboard-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.client-modal-form-container .select-with-btn {
  width: 85%;
  float: left;
}
.client-modal-form-container .select-with-btn .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}
.client-modal-form-container .gt-date-select .dropdown-menu {
  left: -70px !important;
}
.client-modal-form-container .add-btn {
  color: #666b72 !important;
}
.client-modal-form-container .form-add-btn {
  padding: 0;
  width: 12%;
  float: right;
}
.client-modal-form-container .form-add-btn a {
  height: 10px;
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: calc(14px / 1.3);
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #666b72 !important;
}
.client-modal-form-container .form-add-btn a:hover {
  color: #07422d !important;
  background: transparent !important;
}
.client-modal-form-container .form-control .fa-remove {
  font-size: calc(14px / 1.3) !important;
}
.client-modal-form-container .form-add-btn-fullwidth {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.client-modal-form-container .form-add-btn-fullwidth a {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px;
  font-size: 14px;
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
}
.client-modal-form-container .form-add-btn-fullwidth a:hover {
  background: #07422d !important;
}
.client-modal-form-container .form-dismiss-select {
  position: absolute;
  right: 65px;
  top: 8px;
  font-size: 15px !important;
}
.client-modal-form-container input[type='date'] {
  width: 100% !important;
  float: left;
}
.client-modal-form-container .input-group {
  width: 100%;
}
.client-modal-form-container select {
  background: transparent !important;
}
.client-modal-form-container select option {
  color: #666b72 !important;
}
.client-modal-form-container select option[selected] {
  color: #000000 !important;
  background: #fff !important;
  font-family: 'Semibold', sans-serif !important;
}
.client-modal-form-container .caret {
  display: none;
}
.client-modal-form-container input[type='checkbox'],
.client-modal-form-container input[type='radio'] {
  margin: 0;
  width: 15px;
  height: 15px;
}
.client-modal-form-container .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.client-modal-form-container input[type='date'] {
  background: transparent !important;
  border-radius: 0 !important;
}
.client-modal-form-container .counterparty-block .modal-form-block {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.client-modal-form-container .counterparty-block .form-group {
  width: 55%;
}
.role-modal-form-container .modal-form-block {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 10px !important;
}
.role-modal-form-container .modal-form-block .ul-inline-row {
  background: #fff !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 8px;
}
.role-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.role-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border-radius: 4px !important;
}
.role-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark {
  border-radius: 4px !important;
  border: 2px solid #34495e !important;
  color: #34495e !important;
}
.role-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover {
  color: #fff !important;
}
.role-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border: 2px solid #34495e !important;
  background: #34495e !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.role-modal-form-container .contract-description-label {
  font-size: 14px;
  color: #1f506b !important;
  font-family: 'Bold', sans-serif !important;
}
.role-modal-form-container .form-group .btn-default {
  background: transparent !important;
}
.role-modal-form-container .form-group .input-group-addon {
  border-radius: 0 !important;
  padding: 2px !important;
}
.role-modal-form-container .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 0;
  padding-top: 5px;
  width: 2%;
  float: left;
  display: none;
}
.role-modal-form-container .input-group-addon-search .fa-search {
  display: none;
}
.role-modal-form-container .btn:hover,
.role-modal-form-container .btn:focus {
  transform: none;
  transition: none;
}
.role-modal-form-container .copy-clipboard-btn {
  display: none;
}
.role-modal-form-container .not-empty:hover .copy-clipboard-btn {
  display: block !important;
  position: absolute;
  right: 15px;
  top: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  z-index: 9999 !important;
}
.role-modal-form-container .not-empty:hover .copy-clipboard-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.role-modal-form-container .select-with-btn {
  width: 85%;
  float: left;
}
.role-modal-form-container .select-with-btn .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}
.role-modal-form-container .gt-date-select .dropdown-menu {
  left: -70px !important;
}
.role-modal-form-container .add-btn {
  color: #666b72 !important;
}
.role-modal-form-container .form-add-btn {
  padding: 0;
  width: 12%;
  float: right;
}
.role-modal-form-container .form-add-btn a {
  height: 10px;
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: calc(14px / 1.3);
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #666b72 !important;
}
.role-modal-form-container .form-add-btn a:hover {
  color: #07422d !important;
  background: transparent !important;
}
.role-modal-form-container .form-control .fa-remove {
  font-size: calc(14px / 1.3) !important;
}
.role-modal-form-container .form-add-btn-fullwidth {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.role-modal-form-container .form-add-btn-fullwidth a {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px;
  font-size: 14px;
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
}
.role-modal-form-container .form-add-btn-fullwidth a:hover {
  background: #07422d !important;
}
.role-modal-form-container .form-dismiss-select {
  position: absolute;
  right: 65px;
  top: 8px;
  font-size: 15px !important;
}
.role-modal-form-container input[type='date'] {
  width: 100% !important;
  float: left;
}
.role-modal-form-container .input-group {
  width: 100%;
}
.role-modal-form-container select {
  background: transparent !important;
}
.role-modal-form-container select option {
  color: #666b72 !important;
}
.role-modal-form-container select option[selected] {
  color: #000000 !important;
  background: #fff !important;
  font-family: 'Semibold', sans-serif !important;
}
.role-modal-form-container .caret {
  display: none;
}
.role-modal-form-container input[type='checkbox'],
.role-modal-form-container input[type='radio'] {
  margin: 0;
  width: 15px;
  height: 15px;
}
.role-modal-form-container .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.role-modal-form-container input[type='date'] {
  background: transparent !important;
  border-radius: 0 !important;
}
.role-modal-form-container .counterparty-block .modal-form-block {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.role-modal-form-container .counterparty-block .form-group {
  width: 55%;
}
.logistic-modal-form-container .modal-form-block {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 10px !important;
}
.logistic-modal-form-container .modal-form-block .ul-inline-row {
  background: #fff !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 8px;
}
.logistic-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.logistic-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border-radius: 4px !important;
}
.logistic-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark {
  border-radius: 4px !important;
  border: 2px solid #34495e !important;
  color: #34495e !important;
}
.logistic-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover {
  color: #fff !important;
}
.logistic-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border: 2px solid #34495e !important;
  background: #34495e !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.logistic-modal-form-container .contract-description-label {
  font-size: 14px;
  color: #1f506b !important;
  font-family: 'Bold', sans-serif !important;
}
.logistic-modal-form-container .form-group .btn-default {
  background: transparent !important;
}
.logistic-modal-form-container .form-group .input-group-addon {
  border-radius: 0 !important;
  padding: 2px !important;
}
.logistic-modal-form-container .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 0;
  padding-top: 5px;
  width: 2%;
  float: left;
  display: none;
}
.logistic-modal-form-container .input-group-addon-search .fa-search {
  display: none;
}
.logistic-modal-form-container .btn:hover,
.logistic-modal-form-container .btn:focus {
  transform: none;
  transition: none;
}
.logistic-modal-form-container .copy-clipboard-btn {
  display: none;
}
.logistic-modal-form-container .not-empty:hover .copy-clipboard-btn {
  display: block !important;
  position: absolute;
  right: 15px;
  top: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  z-index: 9999 !important;
}
.logistic-modal-form-container .not-empty:hover .copy-clipboard-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.logistic-modal-form-container .select-with-btn {
  width: 85%;
  float: left;
}
.logistic-modal-form-container .select-with-btn .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}
.logistic-modal-form-container .gt-date-select .dropdown-menu {
  left: -70px !important;
}
.logistic-modal-form-container .add-btn {
  color: #666b72 !important;
}
.logistic-modal-form-container .form-add-btn {
  padding: 0;
  width: 12%;
  float: right;
}
.logistic-modal-form-container .form-add-btn a {
  height: 10px;
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: calc(14px / 1.3);
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #666b72 !important;
}
.logistic-modal-form-container .form-add-btn a:hover {
  color: #07422d !important;
  background: transparent !important;
}
.logistic-modal-form-container .form-control .fa-remove {
  font-size: calc(14px / 1.3) !important;
}
.logistic-modal-form-container .form-add-btn-fullwidth {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.logistic-modal-form-container .form-add-btn-fullwidth a {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px;
  font-size: 14px;
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
}
.logistic-modal-form-container .form-add-btn-fullwidth a:hover {
  background: #07422d !important;
}
.logistic-modal-form-container .form-dismiss-select {
  position: absolute;
  right: 65px;
  top: 8px;
  font-size: 15px !important;
}
.logistic-modal-form-container input[type='date'] {
  width: 100% !important;
  float: left;
}
.logistic-modal-form-container .input-group {
  width: 100%;
}
.logistic-modal-form-container select {
  background: transparent !important;
}
.logistic-modal-form-container select option {
  color: #666b72 !important;
}
.logistic-modal-form-container select option[selected] {
  color: #000000 !important;
  background: #fff !important;
  font-family: 'Semibold', sans-serif !important;
}
.logistic-modal-form-container .caret {
  display: none;
}
.logistic-modal-form-container input[type='checkbox'],
.logistic-modal-form-container input[type='radio'] {
  margin: 0;
  width: 15px;
  height: 15px;
}
.logistic-modal-form-container .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.logistic-modal-form-container input[type='date'] {
  background: transparent !important;
  border-radius: 0 !important;
}
.logistic-modal-form-container .counterparty-block .modal-form-block {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.logistic-modal-form-container .counterparty-block .form-group {
  width: 55%;
}
.finance-modal-form-container .modal-form-block {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 10px !important;
}
.finance-modal-form-container .modal-form-block .ul-inline-row {
  background: #fff !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 8px;
}
.finance-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.finance-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border-radius: 4px !important;
}
.finance-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark {
  border-radius: 4px !important;
  border: 2px solid #34495e !important;
  color: #34495e !important;
}
.finance-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover {
  color: #fff !important;
}
.finance-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border: 2px solid #34495e !important;
  background: #34495e !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.finance-modal-form-container .contract-description-label {
  font-size: 14px;
  color: #1f506b !important;
  font-family: 'Bold', sans-serif !important;
}
.finance-modal-form-container .form-group .btn-default {
  background: transparent !important;
}
.finance-modal-form-container .form-group .input-group-addon {
  border-radius: 0 !important;
  padding: 2px !important;
}
.finance-modal-form-container .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 0;
  padding-top: 5px;
  width: 2%;
  float: left;
  display: none;
}
.finance-modal-form-container .input-group-addon-search .fa-search {
  display: none;
}
.finance-modal-form-container .btn:hover,
.finance-modal-form-container .btn:focus {
  transform: none;
  transition: none;
}
.finance-modal-form-container .copy-clipboard-btn {
  display: none;
}
.finance-modal-form-container .not-empty:hover .copy-clipboard-btn {
  display: block !important;
  position: absolute;
  right: 15px;
  top: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  z-index: 9999 !important;
}
.finance-modal-form-container .not-empty:hover .copy-clipboard-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.finance-modal-form-container .select-with-btn {
  width: 85%;
  float: left;
}
.finance-modal-form-container .select-with-btn .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}
.finance-modal-form-container .gt-date-select .dropdown-menu {
  left: -70px !important;
}
.finance-modal-form-container .add-btn {
  color: #666b72 !important;
}
.finance-modal-form-container .form-add-btn {
  padding: 0;
  width: 12%;
  float: right;
}
.finance-modal-form-container .form-add-btn a {
  height: 10px;
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: calc(14px / 1.3);
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #666b72 !important;
}
.finance-modal-form-container .form-add-btn a:hover {
  color: #07422d !important;
  background: transparent !important;
}
.finance-modal-form-container .form-control .fa-remove {
  font-size: calc(14px / 1.3) !important;
}
.finance-modal-form-container .form-add-btn-fullwidth {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.finance-modal-form-container .form-add-btn-fullwidth a {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px;
  font-size: 14px;
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
}
.finance-modal-form-container .form-add-btn-fullwidth a:hover {
  background: #07422d !important;
}
.finance-modal-form-container .form-dismiss-select {
  position: absolute;
  right: 65px;
  top: 8px;
  font-size: 15px !important;
}
.finance-modal-form-container input[type='date'] {
  width: 100% !important;
  float: left;
}
.finance-modal-form-container .input-group {
  width: 100%;
}
.finance-modal-form-container select {
  background: transparent !important;
}
.finance-modal-form-container select option {
  color: #666b72 !important;
}
.finance-modal-form-container select option[selected] {
  color: #000000 !important;
  background: #fff !important;
  font-family: 'Semibold', sans-serif !important;
}
.finance-modal-form-container .caret {
  display: none;
}
.finance-modal-form-container input[type='checkbox'],
.finance-modal-form-container input[type='radio'] {
  margin: 0;
  width: 15px;
  height: 15px;
}
.finance-modal-form-container .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.finance-modal-form-container input[type='date'] {
  background: transparent !important;
  border-radius: 0 !important;
}
.finance-modal-form-container .counterparty-block .modal-form-block {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.finance-modal-form-container .counterparty-block .form-group {
  width: 55%;
}
.passport-modal-form-container .modal-form-block {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 10px !important;
}
.passport-modal-form-container .modal-form-block .ul-inline-row {
  background: #fff !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 8px;
}
.passport-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.passport-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border-radius: 4px !important;
}
.passport-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark {
  border-radius: 4px !important;
  border: 2px solid #34495e !important;
  color: #34495e !important;
}
.passport-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover {
  color: #fff !important;
}
.passport-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border: 2px solid #34495e !important;
  background: #34495e !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.passport-modal-form-container .contract-description-label {
  font-size: 14px;
  color: #1f506b !important;
  font-family: 'Bold', sans-serif !important;
}
.passport-modal-form-container .form-group .btn-default {
  background: transparent !important;
}
.passport-modal-form-container .form-group .input-group-addon {
  border-radius: 0 !important;
  padding: 2px !important;
}
.passport-modal-form-container .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 0;
  padding-top: 5px;
  width: 2%;
  float: left;
  display: none;
}
.passport-modal-form-container .input-group-addon-search .fa-search {
  display: none;
}
.passport-modal-form-container .btn:hover,
.passport-modal-form-container .btn:focus {
  transform: none;
  transition: none;
}
.passport-modal-form-container .copy-clipboard-btn {
  display: none;
}
.passport-modal-form-container .not-empty:hover .copy-clipboard-btn {
  display: block !important;
  position: absolute;
  right: 15px;
  top: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  z-index: 9999 !important;
}
.passport-modal-form-container .not-empty:hover .copy-clipboard-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.passport-modal-form-container .select-with-btn {
  width: 85%;
  float: left;
}
.passport-modal-form-container .select-with-btn .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}
.passport-modal-form-container .gt-date-select .dropdown-menu {
  left: -70px !important;
}
.passport-modal-form-container .add-btn {
  color: #666b72 !important;
}
.passport-modal-form-container .form-add-btn {
  padding: 0;
  width: 12%;
  float: right;
}
.passport-modal-form-container .form-add-btn a {
  height: 10px;
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: calc(14px / 1.3);
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #666b72 !important;
}
.passport-modal-form-container .form-add-btn a:hover {
  color: #07422d !important;
  background: transparent !important;
}
.passport-modal-form-container .form-control .fa-remove {
  font-size: calc(14px / 1.3) !important;
}
.passport-modal-form-container .form-add-btn-fullwidth {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.passport-modal-form-container .form-add-btn-fullwidth a {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px;
  font-size: 14px;
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
}
.passport-modal-form-container .form-add-btn-fullwidth a:hover {
  background: #07422d !important;
}
.passport-modal-form-container .form-dismiss-select {
  position: absolute;
  right: 65px;
  top: 8px;
  font-size: 15px !important;
}
.passport-modal-form-container input[type='date'] {
  width: 100% !important;
  float: left;
}
.passport-modal-form-container .input-group {
  width: 100%;
}
.passport-modal-form-container select {
  background: transparent !important;
}
.passport-modal-form-container select option {
  color: #666b72 !important;
}
.passport-modal-form-container select option[selected] {
  color: #000000 !important;
  background: #fff !important;
  font-family: 'Semibold', sans-serif !important;
}
.passport-modal-form-container .caret {
  display: none;
}
.passport-modal-form-container input[type='checkbox'],
.passport-modal-form-container input[type='radio'] {
  margin: 0;
  width: 15px;
  height: 15px;
}
.passport-modal-form-container .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.passport-modal-form-container input[type='date'] {
  background: transparent !important;
  border-radius: 0 !important;
}
.passport-modal-form-container .counterparty-block .modal-form-block {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.passport-modal-form-container .counterparty-block .form-group {
  width: 55%;
}
.person-form-container .form-group {
  margin-bottom: 5px !important;
}
.person-form-container textarea.form-control {
  min-height: 200px !important;
}
.vessel-modal-form-container .modal-form-block {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 10px !important;
}
.vessel-modal-form-container .modal-form-block .ul-inline-row {
  background: #fff !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 8px;
}
.vessel-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.vessel-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border-radius: 4px !important;
}
.vessel-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark {
  border-radius: 4px !important;
  border: 2px solid #34495e !important;
  color: #34495e !important;
}
.vessel-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover {
  color: #fff !important;
}
.vessel-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border: 2px solid #34495e !important;
  background: #34495e !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.vessel-modal-form-container .contract-description-label {
  font-size: 14px;
  color: #1f506b !important;
  font-family: 'Bold', sans-serif !important;
}
.vessel-modal-form-container .form-group .btn-default {
  background: transparent !important;
}
.vessel-modal-form-container .form-group .input-group-addon {
  border-radius: 0 !important;
  padding: 2px !important;
}
.vessel-modal-form-container .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 0;
  padding-top: 5px;
  width: 2%;
  float: left;
  display: none;
}
.vessel-modal-form-container .input-group-addon-search .fa-search {
  display: none;
}
.vessel-modal-form-container .btn:hover,
.vessel-modal-form-container .btn:focus {
  transform: none;
  transition: none;
}
.vessel-modal-form-container .copy-clipboard-btn {
  display: none;
}
.vessel-modal-form-container .not-empty:hover .copy-clipboard-btn {
  display: block !important;
  position: absolute;
  right: 15px;
  top: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  z-index: 9999 !important;
}
.vessel-modal-form-container .not-empty:hover .copy-clipboard-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.vessel-modal-form-container .select-with-btn {
  width: 85%;
  float: left;
}
.vessel-modal-form-container .select-with-btn .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}
.vessel-modal-form-container .gt-date-select .dropdown-menu {
  left: -70px !important;
}
.vessel-modal-form-container .add-btn {
  color: #666b72 !important;
}
.vessel-modal-form-container .form-add-btn {
  padding: 0;
  width: 12%;
  float: right;
}
.vessel-modal-form-container .form-add-btn a {
  height: 10px;
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: calc(14px / 1.3);
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #666b72 !important;
}
.vessel-modal-form-container .form-add-btn a:hover {
  color: #07422d !important;
  background: transparent !important;
}
.vessel-modal-form-container .form-control .fa-remove {
  font-size: calc(14px / 1.3) !important;
}
.vessel-modal-form-container .form-add-btn-fullwidth {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.vessel-modal-form-container .form-add-btn-fullwidth a {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px;
  font-size: 14px;
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
}
.vessel-modal-form-container .form-add-btn-fullwidth a:hover {
  background: #07422d !important;
}
.vessel-modal-form-container .form-dismiss-select {
  position: absolute;
  right: 65px;
  top: 8px;
  font-size: 15px !important;
}
.vessel-modal-form-container input[type='date'] {
  width: 100% !important;
  float: left;
}
.vessel-modal-form-container .input-group {
  width: 100%;
}
.vessel-modal-form-container select {
  background: transparent !important;
}
.vessel-modal-form-container select option {
  color: #666b72 !important;
}
.vessel-modal-form-container select option[selected] {
  color: #000000 !important;
  background: #fff !important;
  font-family: 'Semibold', sans-serif !important;
}
.vessel-modal-form-container .caret {
  display: none;
}
.vessel-modal-form-container input[type='checkbox'],
.vessel-modal-form-container input[type='radio'] {
  margin: 0;
  width: 15px;
  height: 15px;
}
.vessel-modal-form-container .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.vessel-modal-form-container input[type='date'] {
  background: transparent !important;
  border-radius: 0 !important;
}
.vessel-modal-form-container .counterparty-block .modal-form-block {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.vessel-modal-form-container .counterparty-block .form-group {
  width: 55%;
}
.contract-modal-form-container .modal-form-block,
.request-modal-form-container .modal-form-block {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 10px !important;
}
.contract-modal-form-container .modal-form-block .ul-inline-row,
.request-modal-form-container .modal-form-block .ul-inline-row {
  background: #fff !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 8px;
}
.contract-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.request-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.contract-modal-form-container .modal-form-block .ul-inline-row .btn-primary,
.request-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border-radius: 4px !important;
}
.contract-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.request-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark {
  border-radius: 4px !important;
  border: 2px solid #34495e !important;
  color: #34495e !important;
}
.contract-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover,
.request-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover {
  color: #fff !important;
}
.contract-modal-form-container .modal-form-block .ul-inline-row .btn-primary,
.request-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border: 2px solid #34495e !important;
  background: #34495e !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.contract-modal-form-container .contract-description-label,
.request-modal-form-container .contract-description-label {
  font-size: 14px;
  color: #1f506b !important;
  font-family: 'Bold', sans-serif !important;
}
.contract-modal-form-container .form-group .btn-default,
.request-modal-form-container .form-group .btn-default {
  background: transparent !important;
}
.contract-modal-form-container .form-group .input-group-addon,
.request-modal-form-container .form-group .input-group-addon {
  border-radius: 0 !important;
  padding: 2px !important;
}
.contract-modal-form-container .input-group-addon-search,
.request-modal-form-container .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 0;
  padding-top: 5px;
  width: 2%;
  float: left;
  display: none;
}
.contract-modal-form-container .input-group-addon-search .fa-search,
.request-modal-form-container .input-group-addon-search .fa-search {
  display: none;
}
.contract-modal-form-container .btn:hover,
.request-modal-form-container .btn:hover,
.contract-modal-form-container .btn:focus,
.request-modal-form-container .btn:focus {
  transform: none;
  transition: none;
}
.contract-modal-form-container .copy-clipboard-btn,
.request-modal-form-container .copy-clipboard-btn {
  display: none;
}
.contract-modal-form-container .not-empty:hover .copy-clipboard-btn,
.request-modal-form-container .not-empty:hover .copy-clipboard-btn {
  display: block !important;
  position: absolute;
  right: 15px;
  top: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  z-index: 9999 !important;
}
.contract-modal-form-container .not-empty:hover .copy-clipboard-btn .fa,
.request-modal-form-container .not-empty:hover .copy-clipboard-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.contract-modal-form-container .select-with-btn,
.request-modal-form-container .select-with-btn {
  width: 85%;
  float: left;
}
.contract-modal-form-container .select-with-btn .btn,
.request-modal-form-container .select-with-btn .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}
.contract-modal-form-container .gt-date-select .dropdown-menu,
.request-modal-form-container .gt-date-select .dropdown-menu {
  left: -70px !important;
}
.contract-modal-form-container .add-btn,
.request-modal-form-container .add-btn {
  color: #666b72 !important;
}
.contract-modal-form-container .form-add-btn,
.request-modal-form-container .form-add-btn {
  padding: 0;
  width: 12%;
  float: right;
}
.contract-modal-form-container .form-add-btn a,
.request-modal-form-container .form-add-btn a {
  height: 10px;
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: calc(14px / 1.3);
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #666b72 !important;
}
.contract-modal-form-container .form-add-btn a:hover,
.request-modal-form-container .form-add-btn a:hover {
  color: #07422d !important;
  background: transparent !important;
}
.contract-modal-form-container .form-control .fa-remove,
.request-modal-form-container .form-control .fa-remove {
  font-size: calc(14px / 1.3) !important;
}
.contract-modal-form-container .form-add-btn-fullwidth,
.request-modal-form-container .form-add-btn-fullwidth {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.contract-modal-form-container .form-add-btn-fullwidth a,
.request-modal-form-container .form-add-btn-fullwidth a {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px;
  font-size: 14px;
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
}
.contract-modal-form-container .form-add-btn-fullwidth a:hover,
.request-modal-form-container .form-add-btn-fullwidth a:hover {
  background: #07422d !important;
}
.contract-modal-form-container .form-dismiss-select,
.request-modal-form-container .form-dismiss-select {
  position: absolute;
  right: 65px;
  top: 8px;
  font-size: 15px !important;
}
.contract-modal-form-container input[type='date'],
.request-modal-form-container input[type='date'] {
  width: 100% !important;
  float: left;
}
.contract-modal-form-container .input-group,
.request-modal-form-container .input-group {
  width: 100%;
}
.contract-modal-form-container select,
.request-modal-form-container select {
  background: transparent !important;
}
.contract-modal-form-container select option,
.request-modal-form-container select option {
  color: #666b72 !important;
}
.contract-modal-form-container select option[selected],
.request-modal-form-container select option[selected] {
  color: #000000 !important;
  background: #fff !important;
  font-family: 'Semibold', sans-serif !important;
}
.contract-modal-form-container .caret,
.request-modal-form-container .caret {
  display: none;
}
.contract-modal-form-container input[type='checkbox'],
.request-modal-form-container input[type='checkbox'],
.contract-modal-form-container input[type='radio'],
.request-modal-form-container input[type='radio'] {
  margin: 0;
  width: 15px;
  height: 15px;
}
.contract-modal-form-container .ui-select-multiple.ui-select-bootstrap,
.request-modal-form-container .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.contract-modal-form-container input[type='date'],
.request-modal-form-container input[type='date'] {
  background: transparent !important;
  border-radius: 0 !important;
}
.contract-modal-form-container .counterparty-block .modal-form-block,
.request-modal-form-container .counterparty-block .modal-form-block {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.contract-modal-form-container .counterparty-block .form-group,
.request-modal-form-container .counterparty-block .form-group {
  width: 55%;
}
.contract-modal-form-container textarea.additional-info.form-control,
.request-modal-form-container textarea.additional-info.form-control {
  height: auto !important;
  min-height: 200px;
}
.costs-form-container .form-group {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  border-bottom: 1px dotted #e8e6e6;
}
.costs-form-container .control-label {
  text-align: left !important;
  float: left !important;
  padding-top: 3px !important;
}
.costs-form-container .control-label.label_default {
  margin-top: 7px !important;
  padding-top: 0 !important;
}
.costs-form-container input[type='checkbox'] {
  width: 40% !important;
}
.quality-form-container .form-group {
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}
.contract-modal-form-container {
  display: block;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 0;
}
.contract-modal-form-container .modal-form-block {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 10px !important;
}
.contract-modal-form-container .modal-form-block .ul-inline-row {
  background: #fff !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 8px;
}
.contract-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.contract-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border-radius: 4px !important;
}
.contract-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark {
  border-radius: 4px !important;
  border: 2px solid #34495e !important;
  color: #34495e !important;
}
.contract-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover {
  color: #fff !important;
}
.contract-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border: 2px solid #34495e !important;
  background: #34495e !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.contract-modal-form-container .contract-description-label {
  font-size: 14px;
  color: #1f506b !important;
  font-family: 'Bold', sans-serif !important;
}
.contract-modal-form-container .form-group .btn-default {
  background: transparent !important;
}
.contract-modal-form-container .form-group .input-group-addon {
  border-radius: 0 !important;
  padding: 2px !important;
}
.contract-modal-form-container .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 0;
  padding-top: 5px;
  width: 2%;
  float: left;
  display: none;
}
.contract-modal-form-container .input-group-addon-search .fa-search {
  display: none;
}
.contract-modal-form-container .btn:hover,
.contract-modal-form-container .btn:focus {
  transform: none;
  transition: none;
}
.contract-modal-form-container .copy-clipboard-btn {
  display: none;
}
.contract-modal-form-container .not-empty:hover .copy-clipboard-btn {
  display: block !important;
  position: absolute;
  right: 15px;
  top: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  z-index: 9999 !important;
}
.contract-modal-form-container .not-empty:hover .copy-clipboard-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.contract-modal-form-container .select-with-btn {
  width: 85%;
  float: left;
}
.contract-modal-form-container .select-with-btn .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}
.contract-modal-form-container .gt-date-select .dropdown-menu {
  left: -70px !important;
}
.contract-modal-form-container .add-btn {
  color: #666b72 !important;
}
.contract-modal-form-container .form-add-btn {
  padding: 0;
  width: 12%;
  float: right;
}
.contract-modal-form-container .form-add-btn a {
  height: 10px;
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: calc(14px / 1.3);
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #666b72 !important;
}
.contract-modal-form-container .form-add-btn a:hover {
  color: #07422d !important;
  background: transparent !important;
}
.contract-modal-form-container .form-control .fa-remove {
  font-size: calc(14px / 1.3) !important;
}
.contract-modal-form-container .form-add-btn-fullwidth {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.contract-modal-form-container .form-add-btn-fullwidth a {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px;
  font-size: 14px;
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
}
.contract-modal-form-container .form-add-btn-fullwidth a:hover {
  background: #07422d !important;
}
.contract-modal-form-container .form-dismiss-select {
  position: absolute;
  right: 65px;
  top: 8px;
  font-size: 15px !important;
}
.contract-modal-form-container input[type='date'] {
  width: 100% !important;
  float: left;
}
.contract-modal-form-container .input-group {
  width: 100%;
}
.contract-modal-form-container select {
  background: transparent !important;
}
.contract-modal-form-container select option {
  color: #666b72 !important;
}
.contract-modal-form-container select option[selected] {
  color: #000000 !important;
  background: #fff !important;
  font-family: 'Semibold', sans-serif !important;
}
.contract-modal-form-container .caret {
  display: none;
}
.contract-modal-form-container input[type='checkbox'],
.contract-modal-form-container input[type='radio'] {
  margin: 0;
  width: 15px;
  height: 15px;
}
.contract-modal-form-container .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.contract-modal-form-container input[type='date'] {
  background: transparent !important;
  border-radius: 0 !important;
}
.contract-modal-form-container .counterparty-block .modal-form-block {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.contract-modal-form-container .counterparty-block .form-group {
  width: 55%;
}
.contract-modal-form-container input[type='date'] {
  max-width: 95%;
}
.contract-modal-form-container .form-dismiss-select {
  right: 55px;
}
.task-modal-form-container .modal-form-block {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 10px !important;
}
.task-modal-form-container .modal-form-block .ul-inline-row {
  background: #fff !important;
  padding: 30px !important;
  border: 0 !important;
  border-radius: 8px;
}
.task-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark,
.task-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border-radius: 4px !important;
}
.task-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark {
  border-radius: 4px !important;
  border: 2px solid #34495e !important;
  color: #34495e !important;
}
.task-modal-form-container .modal-form-block .ul-inline-row .btn-add-dark:hover {
  color: #fff !important;
}
.task-modal-form-container .modal-form-block .ul-inline-row .btn-primary {
  border: 2px solid #34495e !important;
  background: #34495e !important;
  color: #fff !important;
  margin-left: 5px !important;
}
.task-modal-form-container .contract-description-label {
  font-size: 14px;
  color: #1f506b !important;
  font-family: 'Bold', sans-serif !important;
}
.task-modal-form-container .form-group .btn-default {
  background: transparent !important;
}
.task-modal-form-container .form-group .input-group-addon {
  border-radius: 0 !important;
  padding: 2px !important;
}
.task-modal-form-container .input-group-addon-search {
  font-size: calc(14px / 1.3);
  color: #000000;
  padding: 0;
  padding-top: 5px;
  width: 2%;
  float: left;
  display: none;
}
.task-modal-form-container .input-group-addon-search .fa-search {
  display: none;
}
.task-modal-form-container .btn:hover,
.task-modal-form-container .btn:focus {
  transform: none;
  transition: none;
}
.task-modal-form-container .copy-clipboard-btn {
  display: none;
}
.task-modal-form-container .not-empty:hover .copy-clipboard-btn {
  display: block !important;
  position: absolute;
  right: 15px;
  top: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  font-size: calc(14px / 1.3);
  color: #0075ff;
  z-index: 9999 !important;
}
.task-modal-form-container .not-empty:hover .copy-clipboard-btn .fa {
  font-size: calc(14px / 1.3) !important;
}
.task-modal-form-container .select-with-btn {
  width: 85%;
  float: left;
}
.task-modal-form-container .select-with-btn .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}
.task-modal-form-container .gt-date-select .dropdown-menu {
  left: -70px !important;
}
.task-modal-form-container .add-btn {
  color: #666b72 !important;
}
.task-modal-form-container .form-add-btn {
  padding: 0;
  width: 12%;
  float: right;
}
.task-modal-form-container .form-add-btn a {
  height: 10px;
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: calc(14px / 1.3);
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #666b72 !important;
}
.task-modal-form-container .form-add-btn a:hover {
  color: #07422d !important;
  background: transparent !important;
}
.task-modal-form-container .form-control .fa-remove {
  font-size: calc(14px / 1.3) !important;
}
.task-modal-form-container .form-add-btn-fullwidth {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.task-modal-form-container .form-add-btn-fullwidth a {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: -2px;
  font-size: 14px;
  box-shadow: none !important;
  background: transparent !important;
  border-color: transparent !important;
}
.task-modal-form-container .form-add-btn-fullwidth a:hover {
  background: #07422d !important;
}
.task-modal-form-container .form-dismiss-select {
  position: absolute;
  right: 65px;
  top: 8px;
  font-size: 15px !important;
}
.task-modal-form-container input[type='date'] {
  width: 100% !important;
  float: left;
}
.task-modal-form-container .input-group {
  width: 100%;
}
.task-modal-form-container select {
  background: transparent !important;
}
.task-modal-form-container select option {
  color: #666b72 !important;
}
.task-modal-form-container select option[selected] {
  color: #000000 !important;
  background: #fff !important;
  font-family: 'Semibold', sans-serif !important;
}
.task-modal-form-container .caret {
  display: none;
}
.task-modal-form-container input[type='checkbox'],
.task-modal-form-container input[type='radio'] {
  margin: 0;
  width: 15px;
  height: 15px;
}
.task-modal-form-container .ui-select-multiple.ui-select-bootstrap {
  padding: 0;
}
.task-modal-form-container input[type='date'] {
  background: transparent !important;
  border-radius: 0 !important;
}
.task-modal-form-container .counterparty-block .modal-form-block {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.task-modal-form-container .counterparty-block .form-group {
  width: 55%;
}
/*CLIENTS*/
.clients-page .overlay .fa {
  left: 48% !important;
}
.clients-page .filter-template.crm-relations-filter {
  border-radius: 4px !important;
}
.status_container {
  height: 100px;
  display: inline-block;
  width: auto;
}
.crm-relations-filter {
  border-radius: 4px !important;
}
.crm-relations-filter .btn {
  border-left: 3px solid !important;
  border-radius: 4px !important;
}
.crm-relations-filter .btn:hover {
  border-left: 6px solid !important;
}
.crm-relations-filter .btn:nth-child(1) {
  border-left-color: #f9f9f7 !important;
}
.crm-relations-filter .btn:nth-child(2) {
  border-left-color: #cf6163 !important;
}
.crm-relations-filter .btn:nth-child(3) {
  border-left-color: #685438 !important;
}
.crm-relations-filter .btn:nth-child(4) {
  border-left-color: #595b63 !important;
}
.crm-relations-filter .btn:nth-child(5) {
  border-left-color: #6d6a6f !important;
}
.crm-relations-filter .btn:nth-child(6) {
  border-left-color: #07422d !important;
}
.crm-relations-filter .btn:nth-child(1).btn-primary {
  border-left: 1px solid #f9f9f7 !important;
}
.crm-relations-filter .btn:nth-child(2).btn-primary {
  background-color: #cf6163 !important;
  color: #ffffff !important;
}
.crm-relations-filter .btn:nth-child(3).btn-primary {
  background-color: #685438 !important;
  color: #ffffff !important;
}
.crm-relations-filter .btn:nth-child(4).btn-primary {
  background-color: #595b63 !important;
  color: #ffffff !important;
}
.crm-relations-filter .btn:nth-child(5).btn-primary {
  background-color: #6d6a6f !important;
  color: #ffffff !important;
}
.crm-relations-filter .btn:nth-child(6).btn-primary {
  background-color: #07422d !important;
  color: #ffffff !important;
}
.status-filter {
  border-radius: 4px !important;
}
.status-filter .btn {
  border-left: 3px solid !important;
  border-radius: 4px !important;
}
.status-filter .btn:hover {
  border-left: 6px solid !important;
}
.status-filter .btn:nth-child(1) {
  border-left-color: #f9f9f7 !important;
}
.status-filter .btn:nth-child(2) {
  border-left-color: #cf6163 !important;
}
.status-filter .btn:nth-child(3) {
  border-left-color: #685438 !important;
}
.status-filter .btn:nth-child(4) {
  border-left-color: #595b63 !important;
}
.status-filter .btn:nth-child(5) {
  border-left-color: #6d6a6f !important;
}
.status-filter .btn:nth-child(6) {
  border-left-color: #07422d !important;
}
.status-filter .btn:nth-child(1).btn-primary {
  border-left: 1px solid #f9f9f7 !important;
}
.status-filter .btn:nth-child(2).btn-primary {
  background-color: #cf6163 !important;
  color: #ffffff !important;
}
.status-filter .btn:nth-child(3).btn-primary {
  background-color: #685438 !important;
  color: #ffffff !important;
}
.status-filter .btn:nth-child(4).btn-primary {
  background-color: #595b63 !important;
  color: #ffffff !important;
}
.status-filter .btn:nth-child(5).btn-primary {
  background-color: #6d6a6f !important;
  color: #ffffff !important;
}
.status-filter .btn:nth-child(6).btn-primary {
  background-color: #07422d !important;
  color: #ffffff !important;
}
.status-filter .btn:nth-child(2) {
  border-left-color: #595b63 !important;
}
.status-filter .btn:nth-child(2).btn-primary {
  background-color: #595b63 !important;
  color: #ffffff !important;
}
.status-filter .btn:nth-child(3) {
  border-left-color: #07422d !important;
}
.status-filter .btn:nth-child(3).btn-primary {
  background-color: #07422d !important;
  color: #ffffff !important;
}
.status-filter .btn:nth-child(4) {
  border-left-color: #07422d !important;
}
.status-filter .btn:nth-child(4).btn-primary {
  background-color: #07422d !important;
  color: #ffffff !important;
}
.status-filter .btn:nth-child(5) {
  border-left-color: #685438 !important;
}
.status-filter .btn:nth-child(5).btn-primary {
  background-color: #685438 !important;
  color: #ffffff !important;
}
.status-filter .btn:nth-child(6) {
  border-left-color: #cf6163 !important;
}
.status-filter .btn:nth-child(6).btn-primary {
  background-color: #cf6163 !important;
  color: #ffffff !important;
}
.priority-filter {
  border-radius: 4px !important;
}
.priority-filter .btn {
  border-left: 3px solid !important;
  border-radius: 4px !important;
}
.priority-filter .btn:hover {
  border-left: 6px solid !important;
}
.priority-filter .btn:nth-child(1) {
  border-left-color: #f9f9f7 !important;
}
.priority-filter .btn:nth-child(2) {
  border-left-color: #cf6163 !important;
}
.priority-filter .btn:nth-child(3) {
  border-left-color: #685438 !important;
}
.priority-filter .btn:nth-child(4) {
  border-left-color: #595b63 !important;
}
.priority-filter .btn:nth-child(5) {
  border-left-color: #6d6a6f !important;
}
.priority-filter .btn:nth-child(6) {
  border-left-color: #07422d !important;
}
.priority-filter .btn:nth-child(1).btn-primary {
  border-left: 1px solid #f9f9f7 !important;
}
.priority-filter .btn:nth-child(2).btn-primary {
  background-color: #cf6163 !important;
  color: #ffffff !important;
}
.priority-filter .btn:nth-child(3).btn-primary {
  background-color: #685438 !important;
  color: #ffffff !important;
}
.priority-filter .btn:nth-child(4).btn-primary {
  background-color: #595b63 !important;
  color: #ffffff !important;
}
.priority-filter .btn:nth-child(5).btn-primary {
  background-color: #6d6a6f !important;
  color: #ffffff !important;
}
.priority-filter .btn:nth-child(6).btn-primary {
  background-color: #07422d !important;
  color: #ffffff !important;
}
.type-filter {
  border-radius: 4px !important;
}
.type-filter .btn {
  border-left: 3px solid !important;
  border-radius: 4px !important;
}
.type-filter .btn:hover {
  border-left: 6px solid !important;
}
.type-filter .btn:nth-child(1) {
  border-left-color: #f9f9f7 !important;
}
.type-filter .btn:nth-child(2) {
  border-left-color: #cf6163 !important;
}
.type-filter .btn:nth-child(3) {
  border-left-color: #685438 !important;
}
.type-filter .btn:nth-child(4) {
  border-left-color: #595b63 !important;
}
.type-filter .btn:nth-child(5) {
  border-left-color: #6d6a6f !important;
}
.type-filter .btn:nth-child(6) {
  border-left-color: #07422d !important;
}
.type-filter .btn:nth-child(1).btn-primary {
  border-left: 1px solid #f9f9f7 !important;
}
.type-filter .btn:nth-child(2).btn-primary {
  background-color: #cf6163 !important;
  color: #ffffff !important;
}
.type-filter .btn:nth-child(3).btn-primary {
  background-color: #685438 !important;
  color: #ffffff !important;
}
.type-filter .btn:nth-child(4).btn-primary {
  background-color: #595b63 !important;
  color: #ffffff !important;
}
.type-filter .btn:nth-child(5).btn-primary {
  background-color: #6d6a6f !important;
  color: #ffffff !important;
}
.type-filter .btn:nth-child(6).btn-primary {
  background-color: #07422d !important;
  color: #ffffff !important;
}
.clients-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  width: 100% !important;
}
.clients-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.clients-table th::first-letter {
  text-transform: uppercase !important;
}
.clients-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.clients-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.clients-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.clients-table tr td:nth-child(1) {
  vertical-align: middle;
}
.clients-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.clients-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.clients-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.clients-table .label::first-letter {
  text-transform: uppercase !important;
}
.clients-table .label .fa {
  margin-right: 3px;
}
.clients-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.clients-table .badge {
  padding: 4px !important;
}
.clients-table .badge::first-letter {
  text-transform: uppercase !important;
}
.clients-table a {
  cursor: pointer;
}
.clients-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.clients-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.clients-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.clients-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.clients-table .datetimepicker thead tr th:nth-child(1),
.clients-table .datetimepicker thead tr th:nth-child(2),
.clients-table .datetimepicker thead tr th:nth-child(3),
.clients-table .datetimepicker tbody tr td:nth-child(1),
.clients-table .datetimepicker tbody tr td:nth-child(2),
.clients-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.clients-table .datetimepicker .table thead tr th:nth-child(6),
.clients-table .datetimepicker .table thead tr th:nth-child(7),
.clients-table .datetimepicker .table tbody tr td:nth-child(6),
.clients-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.clients-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.clients-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.clients-table tr td {
  width: auto;
  font-size: calc(14px / 1.1) !important;
  white-space: nowrap;
  padding: 2px !important;
  line-height: 1 !important;
}
.clients-table tr td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.clients-table tr td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.clients-table tr td .label {
  white-space: nowrap;
  width: auto !important;
  font-size: calc(14px / 1.1) !important;
  text-align: left;
  padding: 4px !important;
}
.clients-table tr td .label_default.label-opacity {
  background: transparent !important;
}
.clients-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.clients-table td.td-left-align {
  text-align: left !important;
}
.clients-table td.td-right-align {
  text-align: right !important;
}
.clients-table td.td-center-align {
  text-align: center !important;
}
.clients-table td.nowrap {
  white-space: nowrap !important;
}
.clients-table td.update_time {
  white-space: nowrap !important;
  color: #000000 !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: initial;
  min-width: 140px;
}
.clients-table td.update_time .label {
  margin-left: 0;
  text-align: left !important;
  display: inline !important;
}
.clients-table td.updates {
  min-width: 110px;
}
.clients-table td.company {
  vertical-align: middle;
  white-space: nowrap !important;
  text-align: left !important;
  font-family: 'Regular', sans-serif !important;
}
.clients-table td.company::first-letter {
  text-transform: uppercase !important;
}
.clients-table td.company .btn-link {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
}
.clients-table td.company .btn-link:hover {
  text-decoration: underline;
}
.clients-table td.company .fa-arrow-right {
  font-size: calc(14px / 1.4) !important;
}
.clients-table td.company .label {
  text-align: left;
  margin-left: 0 !important;
}
.clients-table td.small-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.clients-table td.small-font-cell a,
.clients-table td.small-font-cell span,
.clients-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.clients-table td.info {
  white-space: nowrap;
}
.clients-table .contacts,
.clients-table .deals,
.clients-table .dates {
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.01) !important;
}
.clients-table a.modal-btn {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
  -webkit-text-stroke: 0;
}
.clients-table a.modal-btn:hover {
  border-bottom: 1px dashed #000000 !important;
  transform: scale(1.05);
  transition: transform 0.2s;
}
.clients-table .item-settings-dropdown a:nth-of-type(1) .fa-cog {
  font-size: 14px !important;
}
tr td:nth-child(1) span.smaller-label {
  font-size: calc(14px / 1.3) !important;
  padding: 0;
  margin: 0;
}
.persons-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  width: 100% !important;
}
.persons-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.persons-table th::first-letter {
  text-transform: uppercase !important;
}
.persons-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.persons-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.persons-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.persons-table tr td:nth-child(1) {
  vertical-align: middle;
}
.persons-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.persons-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.persons-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.persons-table .label::first-letter {
  text-transform: uppercase !important;
}
.persons-table .label .fa {
  margin-right: 3px;
}
.persons-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.persons-table .badge {
  padding: 4px !important;
}
.persons-table .badge::first-letter {
  text-transform: uppercase !important;
}
.persons-table a {
  cursor: pointer;
}
.persons-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.persons-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.persons-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.persons-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.persons-table .datetimepicker thead tr th:nth-child(1),
.persons-table .datetimepicker thead tr th:nth-child(2),
.persons-table .datetimepicker thead tr th:nth-child(3),
.persons-table .datetimepicker tbody tr td:nth-child(1),
.persons-table .datetimepicker tbody tr td:nth-child(2),
.persons-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.persons-table .datetimepicker .table thead tr th:nth-child(6),
.persons-table .datetimepicker .table thead tr th:nth-child(7),
.persons-table .datetimepicker .table tbody tr td:nth-child(6),
.persons-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.persons-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.persons-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.persons-table tr td {
  width: auto;
  font-size: calc(14px / 1.1) !important;
  white-space: nowrap;
  padding: 2px !important;
  line-height: 1 !important;
}
.persons-table tr td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.persons-table tr td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.persons-table tr td .label {
  white-space: nowrap;
  width: auto !important;
  font-size: calc(14px / 1.1) !important;
  text-align: left;
  padding: 4px !important;
}
.persons-table tr td .label_default.label-opacity {
  background: transparent !important;
}
.persons-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.persons-table td.td-left-align {
  text-align: left !important;
}
.persons-table td.td-right-align {
  text-align: right !important;
}
.persons-table td.td-center-align {
  text-align: center !important;
}
.persons-table td.nowrap {
  white-space: nowrap !important;
}
.persons-table td.update_time {
  white-space: nowrap !important;
  color: #000000 !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: initial;
  min-width: 140px;
}
.persons-table td.update_time .label {
  margin-left: 0;
  text-align: left !important;
  display: inline !important;
}
.persons-table td.updates {
  min-width: 110px;
}
.persons-table td.company {
  vertical-align: middle;
  white-space: nowrap !important;
  text-align: left !important;
  font-family: 'Regular', sans-serif !important;
}
.persons-table td.company::first-letter {
  text-transform: uppercase !important;
}
.persons-table td.company .btn-link {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
}
.persons-table td.company .btn-link:hover {
  text-decoration: underline;
}
.persons-table td.company .fa-arrow-right {
  font-size: calc(14px / 1.4) !important;
}
.persons-table td.company .label {
  text-align: left;
  margin-left: 0 !important;
}
.persons-table td.small-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.persons-table td.small-font-cell a,
.persons-table td.small-font-cell span,
.persons-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.persons-table td.info {
  white-space: nowrap;
}
.persons-table .contacts,
.persons-table .deals,
.persons-table .dates {
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.01) !important;
}
.persons-table a.modal-btn {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
  -webkit-text-stroke: 0;
}
.persons-table a.modal-btn:hover {
  border-bottom: 1px dashed #000000 !important;
  transform: scale(1.05);
  transition: transform 0.2s;
}
.persons-table .item-settings-dropdown a:nth-of-type(1) .fa-cog {
  font-size: 14px !important;
}
.persons-table tr td {
  text-align: left;
}
.persons-table tr td h5 {
  padding: 0;
  margin: 0;
  color: #000000;
}
.persons-table tr td:first-child span {
  font-family: 'Regular', sans-serif !important;
}
.persons-table tr td:first-child .btn-link {
  font-family: 'Semibold', sans-serif !important;
}
.confirmations-table {
  border-collapse: separate;
  /*    tr td:first-child {
        text-align: left !important;
    }*/
  width: 100% !important;
  margin-left: -2px !important;
}
.confirmations-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.confirmations-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.confirmations-table .datetimepicker thead tr th:nth-child(1),
.confirmations-table .datetimepicker thead tr th:nth-child(2),
.confirmations-table .datetimepicker thead tr th:nth-child(3),
.confirmations-table .datetimepicker tbody tr td:nth-child(1),
.confirmations-table .datetimepicker tbody tr td:nth-child(2),
.confirmations-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.confirmations-table .datetimepicker .table thead tr th:nth-child(6),
.confirmations-table .datetimepicker .table thead tr th:nth-child(7),
.confirmations-table .datetimepicker .table tbody tr td:nth-child(6),
.confirmations-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.confirmations-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.confirmations-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.confirmations-table th {
  font-size: calc(14px / 1.2) !important;
  text-align: center !important;
  cursor: pointer;
  border-top: 0 !important;
  height: 50px !important;
  word-break: normal;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.confirmations-table th::first-letter {
  text-transform: uppercase !important;
}
.confirmations-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.confirmations-table tr td {
  font-size: calc(14px / 1.2) !important;
  padding: 4px !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  -webkit-text-stroke: 0.2px #000;
}
.confirmations-table tr td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.confirmations-table tr td:nth-child(1) {
  vertical-align: middle;
}
.confirmations-table tr td:nth-child(1) span {
  font-family: 'Semibold', sans-serif !important;
}
.confirmations-table tr td:nth-child(1) .smaller-label span {
  font-size: calc(14px / 1.5) !important;
  padding: 0;
  margin: 0;
}
.confirmations-table .label {
  /*display: inline-block !important;*/
  display: inline-block;
  font-size: calc(14px / 1.6) !important;
  overflow-x: hidden;
  white-space: normal;
  margin-bottom: 0 !important;
  padding: 4px !important;
  margin: 0 auto;
  vertical-align: middle !important;
}
.confirmations-table .label::first-letter {
  text-transform: uppercase !important;
}
.confirmations-table .label .fa {
  margin-right: 3px;
}
.confirmations-table .label-opacity {
  font-size: 14px / 1.8 !important;
}
.confirmations-table .badge {
  padding: 4px !important;
}
.confirmations-table .badge::first-letter {
  text-transform: uppercase !important;
}
.confirmations-table a {
  cursor: pointer;
}
.confirmations-table .btn-link {
  text-transform: initial !important;
  text-decoration: none;
  line-height: 1;
}
.confirmations-table .item-settings-dropdown li > a {
  line-height: 5px;
}
.confirmations-table tr:hover td {
  opacity: 1 !important;
  background: #f4f7fa;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.confirmations-table .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.confirmations-table .datetimepicker thead tr th:nth-child(1),
.confirmations-table .datetimepicker thead tr th:nth-child(2),
.confirmations-table .datetimepicker thead tr th:nth-child(3),
.confirmations-table .datetimepicker tbody tr td:nth-child(1),
.confirmations-table .datetimepicker tbody tr td:nth-child(2),
.confirmations-table .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.confirmations-table .datetimepicker .table thead tr th:nth-child(6),
.confirmations-table .datetimepicker .table thead tr th:nth-child(7),
.confirmations-table .datetimepicker .table tbody tr td:nth-child(6),
.confirmations-table .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.confirmations-table .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.confirmations-table .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.confirmations-table tr td {
  width: auto;
  font-size: calc(14px / 1.1) !important;
  white-space: nowrap;
  padding: 2px !important;
  line-height: 1 !important;
}
.confirmations-table tr td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.confirmations-table tr td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.confirmations-table tr td .label {
  white-space: nowrap;
  width: auto !important;
  font-size: calc(14px / 1.1) !important;
  text-align: left;
  padding: 4px !important;
}
.confirmations-table tr td .label_default.label-opacity {
  background: transparent !important;
}
.confirmations-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.confirmations-table td.td-left-align {
  text-align: left !important;
}
.confirmations-table td.td-right-align {
  text-align: right !important;
}
.confirmations-table td.td-center-align {
  text-align: center !important;
}
.confirmations-table td.nowrap {
  white-space: nowrap !important;
}
.confirmations-table td.update_time {
  white-space: nowrap !important;
  color: #000000 !important;
  font-family: 'Regular', sans-serif !important;
  text-transform: initial;
  min-width: 140px;
}
.confirmations-table td.update_time .label {
  margin-left: 0;
  text-align: left !important;
  display: inline !important;
}
.confirmations-table td.updates {
  min-width: 110px;
}
.confirmations-table td.company {
  vertical-align: middle;
  white-space: nowrap !important;
  text-align: left !important;
  font-family: 'Regular', sans-serif !important;
}
.confirmations-table td.company::first-letter {
  text-transform: uppercase !important;
}
.confirmations-table td.company .btn-link {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
}
.confirmations-table td.company .btn-link:hover {
  text-decoration: underline;
}
.confirmations-table td.company .fa-arrow-right {
  font-size: calc(14px / 1.4) !important;
}
.confirmations-table td.company .label {
  text-align: left;
  margin-left: 0 !important;
}
.confirmations-table td.small-font-cell {
  font-size: calc(14px / 1.3) !important;
}
.confirmations-table td.small-font-cell a,
.confirmations-table td.small-font-cell span,
.confirmations-table td.small-font-cell .btn-link {
  font-size: calc(14px / 1.3) !important;
}
.confirmations-table td.info {
  white-space: nowrap;
}
.confirmations-table .contacts,
.confirmations-table .deals,
.confirmations-table .dates {
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.01) !important;
}
.confirmations-table a.modal-btn {
  font-family: 'Regular', sans-serif !important;
  color: #1763ad !important;
  -webkit-text-stroke: 0;
}
.confirmations-table a.modal-btn:hover {
  border-bottom: 1px dashed #000000 !important;
  transform: scale(1.05);
  transition: transform 0.2s;
}
.confirmations-table .item-settings-dropdown a:nth-of-type(1) .fa-cog {
  font-size: 14px !important;
}
.confirmations-table tr th {
  padding: 6px !important;
  padding-right: 2px !important;
  padding-left: 2px !important;
  border-right: 1px solid #e8e6e6 !important;
}
.confirmations-table tr td {
  border-right: 1px solid #e8e6e6 !important;
}
.confirmations-table tr td .label {
  font-size: calc(14px / 1.3) !important;
}
.confirmations-table tr th:nth-child(3) {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.confirmations-table tr th:nth-child(1),
.confirmations-table tr th:nth-child(2) {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  border: 0 !important;
}
.confirmations-table tr th:nth-child(1) span,
.confirmations-table tr th:nth-child(2) span {
  text-transform: uppercase !important;
  font-family: 'Regular', sans-serif !important;
}
.confirmations-table tr td:nth-child(2) span {
  text-transform: uppercase !important;
}
.confirmations-table tr td:nth-child(1) .btn-link,
.confirmations-table tr td:nth-child(2) .btn-link,
.confirmations-table tr td:nth-child(1) a,
.confirmations-table tr td:nth-child(2) a {
  font-size: calc(14px / 1.3) !important;
}
.confirmations-table tr:nth-last-child(1) td:nth-child(1),
.confirmations-table tr:nth-last-child(1) td:nth-child(2) {
  line-height: 2 !important;
}
.tasks-table,
.persons-table {
  width: 100% !important;
}
.tasks-table th,
.persons-table th {
  text-align: center !important;
  text-transform: uppercase !important;
  cursor: pointer;
  min-width: 20px;
}
.tasks-table th:hover,
.persons-table th:hover {
  background: rgba(0, 0, 0, 0.01);
}
.tasks-table tr td,
.persons-table tr td {
  font-size: 14px;
  text-align: left !important;
}
.tasks-table tr td .companies_list,
.persons-table tr td .companies_list {
  display: block;
}
.tasks-extended-table tr td {
  font-size: calc(14px / 1.3);
  padding: 2px !important;
  border-radius: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #dcd9d9 !important;
}
.tasks-extended-table tr td .label,
.tasks-extended-table tr td .badge,
.tasks-extended-table tr td a {
  margin: 0 auto !important;
}
.tasks-extended-table .status_new {
  background: rgba(41, 141, 107, 0.21) !important;
  color: #07422d !important;
}
.tasks-extended-table .status_new .badge {
  background: rgba(41, 141, 107, 0.21) !important;
}
.tasks-extended-table .status_process {
  background: rgba(229, 218, 203, 0.62) !important;
  color: #7d6643 !important;
}
.tasks-extended-table .status_process .badge {
  background: rgba(229, 218, 203, 0.62) !important;
}
.tasks-extended-table .status_review {
  background: rgba(164, 149, 176, 0.22) !important;
  color: #6d6a6f !important;
}
.tasks-extended-table .status_review .badge {
  background: #a495b0 !important;
}
.tasks-extended-table .status_failed {
  background: rgba(207, 97, 99, 0.11) !important;
  color: #ffffff !important;
}
.tasks-extended-table .status_failed .badge {
  background: rgba(207, 97, 99, 0.11) !important;
}
/*clients relations labels*/
.label_hot {
  background-color: #cf6163 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.label_warm {
  background-color: #685438 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.label_neutral {
  background-color: #595b63 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.label_cold {
  background-color: #6d6a6f !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.label_angry {
  background-color: #6d6a6f !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.gt-calendar .fc-state-default {
  color: #000000;
}
.gt-calendar .fc-view-container {
  background: #fff;
  border-radius: 4px !important;
  font-family: 'Regular', sans-serif !important;
}
.gt-calendar .calendar .fc-state-active {
  border-radius: 4px !important;
  border: 1px solid #e8e6e6 !important;
}
.gt-calendar .fc th {
  border: 0;
}
.gt-calendar .fc-left h2 {
  font-family: 'Bold', sans-serif !important;
}
.gt-calendar .fc-state-default {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}
.gt-calendar .fc-unthemed th,
.gt-calendar .fc-unthemed td,
.gt-calendar .fc-unthemed thead,
.gt-calendar .fc-unthemed tbody,
.gt-calendar .fc-unthemed .fc-divider,
.gt-calendar .fc-unthemed .fc-row,
.gt-calendar .fc-unthemed .fc-content,
.gt-calendar .fc-unthemed .fc-popover,
.gt-calendar .fc-unthemed .fc-list-view,
.gt-calendar .fc-unthemed .fc-list-heading td {
  border-left: 0 !important;
}
.gt-calendar .fc-unthemed td:last-child {
  border-right: 0 !important;
}
.gt-calendar .fc-unthemed th {
  text-align: right;
}
.gt-calendar .calendar-btn-group .btn-primary {
  border: 1px solid transparent !important;
}
.gt-calendar .fc-event-container {
  padding: 2px !important;
}
.gt-calendar .fc-event-container .fc-title {
  font-family: 'Bold', sans-serif !important;
  white-space: normal;
}
.gt-calendar .fc-event-container .fc-time {
  color: #ffffff;
  font-family: 'Bold', sans-serif !important;
}
.gt-calendar .fc-event-container .fc-day-grid-event {
  padding: 6px;
  box-shadow: 0 0 6px rgba(8, 40, 100, 0.15);
}
.gt-calendar .fc-day-number {
  font-family: 'Bold', sans-serif !important;
  padding: 0 !important;
  padding-right: 2px !important;
}
.gt-calendar .fc-basic-view .fc-body .fc-row {
  height: auto !important;
}
.gt-calendar .fc-scroller.fc-day-grid-container {
  height: auto !important;
}
.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  overflow: hidden;
  text-align: left;
  border-radius: 2px !important;
}
.ui-select-multiple.ui-select-bootstrap .ui-select-match-item::first-letter {
  text-transform: uppercase !important;
}
/*datarangepicker*/
#daterange5 {
  width: auto;
}
#reportrange {
  padding: 6px;
  height: auto;
  color: #000000;
  outline: 0;
  cursor: pointer;
  border-bottom: 0 !important;
  box-shadow: none !important;
  border-radius: 0px;
  border: 0 !important;
  margin: 0 !important;
  background: rgba(0, 0, 0, 0.08);
  overflow-x: visible;
}
#reportrange::-webkit-input-placeholder {
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
}
#reportrange::-moz-placeholder {
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
}
#reportrange:-moz-placeholder {
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
}
#reportrange:-ms-input-placeholder {
  color: #000000;
  font-size: 14px;
  text-overflow: ellipsis;
}
.daterangepicker.opensleft {
  position: fixed !important;
  margin-top: 0;
  z-index: 9999 !important;
}
.daterangepicker_start_input {
  float: left;
  display: block;
  width: 100%;
}
.daterangepicker_end_input {
  float: left;
  padding-left: 0px !important;
  width: 100%;
}
.daterangepicker .daterangepicker_input i.glyphicon-calendar {
  display: none;
}
.daterangepicker .ranges .input-mini {
  width: 100%;
}
.daterangepicker .btn {
  border-radius: 4px !important;
}
.daterangepicker .calendar-date {
  border: 0 !important;
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background: #f9f9f7;
  border-color: #f9f9f7;
  color: #000000;
}
.daterangepicker .ranges li {
  font-size: calc(14px / 1.3);
  padding: 2px 6px;
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  min-width: 18px;
  width: 30px;
  height: 12px;
  line-height: 12px;
  font-size: calc(14px / 1.3);
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: calc(14px / 1.3);
}
.table-hover .datetimepicker tbody tr td {
  background: #fff !important;
  opacity: inherit !important;
}
.table-hover .datetimepicker thead tr th:nth-child(1),
.table-hover .datetimepicker thead tr th:nth-child(2),
.table-hover .datetimepicker thead tr th:nth-child(3),
.table-hover .datetimepicker tbody tr td:nth-child(1),
.table-hover .datetimepicker tbody tr td:nth-child(2),
.table-hover .datetimepicker tbody tr td:nth-child(3) {
  background: #fff !important;
  opacity: inherit !important;
}
.table-hover .datetimepicker .table thead tr th:nth-child(6),
.table-hover .datetimepicker .table thead tr th:nth-child(7),
.table-hover .datetimepicker .table tbody tr td:nth-child(6),
.table-hover .datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
}
.table-hover .datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  border-radius: 4px !important;
}
.table-hover .datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  border-radius: 4px !important;
}
.datetimepicker {
  margin-top: 0;
  width: auto;
  padding: 12px;
  background: #fff !important;
}
.datetimepicker .table tbody tr td {
  background: #fff !important;
  display: table-cell !important;
  text-align: center !important;
  width: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
  font-size: calc(14px / 1.3) !important;
  vertical-align: middle !important;
  color: #000000 !important;
  font-family: 'Semibold', sans-serif !important;
  margin: 0 !important;
  position: relative !important;
  border: 0 !important;
  line-height: 1 !important;
  border-radius: 0 !important;
  white-space: nowrap !important;
}
.datetimepicker .table thead tr th:nth-child(1),
.datetimepicker .table thead tr th:nth-child(2),
.datetimepicker .table thead tr th:nth-child(3),
.datetimepicker .table tbody tr td:nth-child(1),
.datetimepicker .table tbody tr td:nth-child(2),
.datetimepicker .table tbody tr td:nth-child(3) {
  margin-left: 0 !important;
  margin-top: 0 !important;
  text-align: center !important;
  background: #fff !important;
  position: relative !important;
  width: 20px !important;
  min-width: 20px !important;
  display: table-cell !important;
}
.datetimepicker .hour,
.datetimepicker .minute {
  white-space: nowrap !important;
}
.datetimepicker .table tbody tr:hover td {
  background: #fff !important;
}
.datetimepicker .table tbody tr:last-child td {
  background: #fff !important;
  line-height: 1 !important;
}
.datetimepicker .table tbody tr:hover td {
  background: inherit !important;
}
.datetimepicker .table thead tr th:nth-child(6),
.datetimepicker .table thead tr th:nth-child(7),
.datetimepicker .table tbody tr td:nth-child(6),
.datetimepicker .table tbody tr td:nth-child(7) {
  background: #e5e5dd !important;
  border: 0 !important;
  font-family: 'Regular', sans-serif !important;
  line-height: 1 !important;
  margin-left: 0 !important;
  position: relative !important;
  width: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
}
.datetimepicker .table tbody tr:nth-last-child(1) td {
  font-family: 'Regular', sans-serif !important;
  line-height: 1 !important;
  padding: 2px !important;
}
.datetimepicker .table thead tr th,
.datetimepicker .table thead tr th.right,
.datetimepicker .table thead tr th.left,
.datetimepicker .table thead tr th.switch {
  border: 0 !important;
  border-radius: 0 !important;
  margin: 0 !important;
  display: table-cell !important;
  color: #909597 !important;
  position: relative !important;
  font-size: 14px !important;
  font-family: 'Semibold', sans-serif !important;
}
.datetimepicker .table thead tr th.right,
.datetimepicker .table thead tr th.left,
.datetimepicker .table thead tr th.switch {
  line-height: 1 !important;
  padding: 2px !important;
}
.datetimepicker .hour-view,
.datetimepicker .minute-view {
  width: 250px;
}
.datetimepicker .table tbody tr td .month,
.datetimepicker .table tbody tr td .year,
.datetimepicker .table tbody tr td .hour,
.datetimepicker .table tbody tr td .minute {
  font-family: 'Semibold', sans-serif !important;
  padding: 2px;
}
.datetimepicker .datetimepicker .active:hover,
.datetimepicker .datetimepicker .active:hover:hover,
.datetimepicker .datetimepicker .active.disabled:hover,
.datetimepicker .datetimepicker .active.disabled:hover:hover,
.datetimepicker .datetimepicker .active:active,
.datetimepicker .datetimepicker .active:hover:active,
.datetimepicker .datetimepicker .active.disabled:active,
.datetimepicker .datetimepicker .active.disabled:hover:active,
.datetimepicker .datetimepicker .active.active,
.datetimepicker .datetimepicker .active:hover.active,
.datetimepicker .datetimepicker .active.disabled.active,
.datetimepicker .datetimepicker .active.disabled:hover.active,
.datetimepicker .datetimepicker .active.disabled,
.datetimepicker .datetimepicker .active:hover.disabled,
.datetimepicker .datetimepicker .active.disabled.disabled,
.datetimepicker .datetimepicker .active.disabled:hover.disabled,
.datetimepicker .datetimepicker td.active[disabled],
.datetimepicker .datetimepicker td.active:hover[disabled],
.datetimepicker .datetimepicker td.active.disabled[disabled],
.datetimepicker .datetimepicker td.active.disabled:hover[disabled],
.datetimepicker .datetimepicker span.active[disabled],
.datetimepicker .datetimepicker span.active:hover[disabled],
.datetimepicker .datetimepicker span.active.disabled[disabled],
.datetimepicker .datetimepicker span.active.disabled:hover[disabled] {
  background: #f9f9f7;
  border-color: #f9f9f7;
  color: #000000;
}
.datetimepicker .table tbody tr td.current {
  background: #1d82c7 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
}
.datetimepicker .table tbody tr td.active {
  background: #07422d !important;
  color: #ffffff !important;
  border-radius: 4px !important;
}
.datetimepicker .table tbody tr td.future {
  opacity: 0.2 !important;
}
.datetimepicker .table tbody tr td.past {
  opacity: 0.2 !important;
}
.datetimepicker .table tbody tr td.disabled {
  opacity: 0.2 !important;
}
.datetimepicker .table tbody tr td.active,
.datetimepicker .table tbody tr td.active:hover {
  background-color: #32ae84 !important;
  border-color: #32ae84 !important;
}
.datetimepicker span {
  height: auto !important;
}
.datetimepicker .table tbody tr td[colspan] {
  width: 100% !important;
}
.datetimepicker .table thead tr th {
  white-space: nowrap !important;
}
.label-opacity.tooltip-modal::after {
  border-radius: 8px 8px 8px 0px;
  box-shadow: none;
  color: #fff;
  content: '';
  background: transparent;
  padding: 2px 7px;
  position: absolute;
  visibility: hidden;
  white-space: normal;
  word-wrap: break-word;
  opacity: 1 !important;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  font-size: calc(14px / 1.3) !important;
  font-family: 'Bold', sans-serif !important;
  text-transform: inherit !important;
  line-height: 1.4;
  max-width: 300px;
}
.label-opacity.tooltip-modal:hover::after {
  background: #888b94;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  content: attr(data-tip);
  margin-top: -24px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999 !important;
  background: rgba(0, 0, 0, 0.2);
}
.overlay .fa {
  top: 50%;
  left: 50%;
  font-size: 30px;
  position: fixed;
  color: #2f3138;
  z-index: 9999 !important;
}
.opacity {
  opacity: 0.5;
}
.permanent-hover div div {
  padding-bottom: 300px !important;
}
.permanent-hover div div .tooltip {
  position: inherit !important;
}
.permanent-hover div div .sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-condensed tfoot > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-expanded tfoot > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-expanded-position tfoot > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-condensed tbody > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-ultra-condensed tbody > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-expanded tbody > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-expanded-position tbody > tr > td:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-condensed thead > tr > th:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-ultra-condensed thead > tr > th:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-expanded thead > tr > th:first-child .item-settings-dropdown .dropdown-menu,
.permanent-hover div div .sticky-table-first-td-expanded-position thead > tr > th:first-child .item-settings-dropdown .dropdown-menu {
  left: calc(100% + 1px) !important;
}
.permanent-hover div div .counterparty_detail_table tbody > tr > td:first-child {
  background: inherit !important;
}
.table-with-addon {
  outline: none !important;
}
.table-with-addon tbody:nth-of-type(1) tr:first-child td:nth-child(1) {
  border-left: 5px solid #34495e !important;
}
.table-with-addon tr:first-child td,
.table-with-addon td.edit td {
  border-bottom: 0 !important;
}
.table-with-addon tr:first-child td .filter-template,
.table-with-addon td.edit td .filter-template {
  background: #f7f7f7 !important;
  border-right: 0 !important;
}
.table-with-addon tr:first-child td .filter-template .btn,
.table-with-addon td.edit td .filter-template .btn {
  border-right: 0 !important;
}
.table-with-addon tr:first-child td .filter-template .btn-primary,
.table-with-addon td.edit td .filter-template .btn-primary {
  background: #1bb394 !important;
  color: #fff !important;
}
.table-with-addon tr:first-child td .ui-select-multiple.ui-select-bootstrap,
.table-with-addon td.edit td .ui-select-multiple.ui-select-bootstrap {
  padding: 0 !important;
  padding-left: 2px !important;
}
.table-with-addon tr:first-child td .form-control,
.table-with-addon td.edit td .form-control {
  border: 0 !important;
  background: #f7f7f7;
  border-radius: 4px !important;
}
.table-with-addon tr:first-child td .input-group-addon,
.table-with-addon td.edit td .input-group-addon {
  background: #f7f7f7;
  color: #fff !important;
}
.table-with-addon tr:first-child td select[multiple],
.table-with-addon td.edit td select[multiple],
.table-with-addon tr:first-child td select[size],
.table-with-addon td.edit td select[size] {
  width: 100%;
  border: 0 !important;
  background: #f7f7f7 !important;
}
.table-with-addon-container {
  outline: none !important;
}
.table-with-addon-container table {
  outline: none !important;
}
.table-with-addon-container table tbody:nth-of-type(1) tr:first-child td:nth-child(1) {
  border-left: 5px solid #34495e !important;
}
.table-with-addon-container table tr:first-child td,
.table-with-addon-container table td.edit td {
  border-bottom: 0 !important;
}
.table-with-addon-container table tr:first-child td .filter-template,
.table-with-addon-container table td.edit td .filter-template {
  background: #f7f7f7 !important;
  border-right: 0 !important;
}
.table-with-addon-container table tr:first-child td .filter-template .btn,
.table-with-addon-container table td.edit td .filter-template .btn {
  border-right: 0 !important;
}
.table-with-addon-container table tr:first-child td .filter-template .btn-primary,
.table-with-addon-container table td.edit td .filter-template .btn-primary {
  background: #1bb394 !important;
  color: #fff !important;
}
.table-with-addon-container table tr:first-child td .ui-select-multiple.ui-select-bootstrap,
.table-with-addon-container table td.edit td .ui-select-multiple.ui-select-bootstrap {
  padding: 0 !important;
  padding-left: 2px !important;
}
.table-with-addon-container table tr:first-child td .form-control,
.table-with-addon-container table td.edit td .form-control {
  border: 0 !important;
  background: #f7f7f7;
  border-radius: 4px !important;
}
.table-with-addon-container table tr:first-child td .input-group-addon,
.table-with-addon-container table td.edit td .input-group-addon {
  background: #f7f7f7;
  color: #fff !important;
}
.table-with-addon-container table tr:first-child td select[multiple],
.table-with-addon-container table td.edit td select[multiple],
.table-with-addon-container table tr:first-child td select[size],
.table-with-addon-container table td.edit td select[size] {
  width: 100%;
  border: 0 !important;
  background: #f7f7f7 !important;
}
.persons-table tr:first-child td .form-control {
  height: auto !important;
}
.no-outline {
  outline: none !important;
}
.hidden-element {
  height: 1px;
}
.visible-element {
  height: auto;
}
.gt-date-select .input-group {
  text-align: left !important;
}
.gt-date-select input.form-control {
  float: left !important;
}
.gt-date-select .clear-btn {
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
}
.details-wrapper .process-container-well {
  background: #263746 !important;
  box-shadow: none !important;
  margin-bottom: 15px !important;
  padding: 40px !important;
}
.details-wrapper .process-container-well .start-page-header {
  color: #fff;
}
.details-wrapper .process-container-well .item-process-container {
  border-left: 1px solid #e8e6e6;
}
.details-wrapper .process-container-well .item-process-container .process-item {
  box-shadow: none !important;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-left: 10px;
  overflow-x: auto;
}
.details-wrapper .process-container-well .item-process-container .process-item .col-xs-5,
.details-wrapper .process-container-well .item-process-container .process-item .btn-add-dark {
  overflow: hidden;
}
.details-wrapper .process-container-well .item-process-container .process-item .alert {
  overflow-x: auto;
}
.details-wrapper .process-container-well .item-process-container .label {
  margin-right: 5px;
}
.details-wrapper .process-container-well .item-process-container h5 {
  background: #263746;
  font-family: 'Regular', sans-serif !important;
  color: #fff;
  margin-left: -18px;
  border-radius: 6px;
  padding-left: 10px;
  display: flex;
}
.details-wrapper .process-container-well .item-process-container h5 .smaller-label {
  vertical-align: bottom;
}
.details-wrapper .process-container-well .item-process-container h5 .label_default {
  background: #b0a9a9 !important;
}
.details-wrapper .process-container-well .item-process-container h5 .label {
  display: inline-block;
}
.details-wrapper .process-container-well .item-process-container h5:hover,
.details-wrapper .process-container-well .item-process-container h5:focus {
  transform: scale(1.05);
  transition: transform 0.2s;
  cursor: pointer;
}
.details-wrapper .process-container-well .item-process-container h5.positive-number {
  color: #1bb394 !important;
}
.details-wrapper .process-container-well .item-process-container h5.positive-number .fa {
  color: #1bb394 !important;
}
.updates-process-container .process-container-well .item-process-container {
  border-left: 0;
}
.updates-process-container .process-container-well .item-process-container h5 {
  background: #fff;
  width: 100% !important;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 5px;
}
.updates-process-container .process-container-well .item-process-container h5 .label {
  font-size: 14px !important;
}
.updates-process-container .process-container-well .item-process-container h5 .col-xs-3,
.updates-process-container .process-container-well .item-process-container h5 .col-xs-2,
.updates-process-container .process-container-well .item-process-container h5 .col-xs-6,
.updates-process-container .process-container-well .item-process-container h5 .col-xs-4 {
  padding-left: 2px !important;
  padding-right: 2px !important;
  text-align: left;
}
.updates-process-container .process-container-well .item-process-container .process-item {
  padding: 10px;
  border-left: 1px solid #d2cece;
  margin-left: 90px;
  border-radius: 0;
}
.updates-process-container .process-container-well .item-process-container:last-child .process-item {
  border-left: 0;
}
.simplified-updates .item-process-container .fa-ellipsis {
  display: none;
}
.simplified-updates .item-process-container .label_info.smaller-label {
  display: none !important;
}
.simplified-updates .item-process-container .btn-link {
  font-size: calc(14px / 1.4) !important;
}
.simplified-updates .item-process-container .process-item {
  display: none;
}
.padding-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.padding-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.padding-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.modal-success-alert {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  border: 2px dashed rgba(0, 0, 0, 0.08) !important;
}
table.contract-pnl-detalization-table thead > tr > th {
  border-right: 1px solid #beb9b9 !important;
  border-bottom: 1px solid #beb9b9 !important;
}
table.contract-pnl-detalization-table tbody:first-child {
  border-bottom: 3px solid #beb9b9 !important;
}
table.contract-pnl-detalization-table tr th:last-child {
  border-right: 0 !important;
}
table.contract-pnl-detalization-table tr td {
  text-align: right;
}
table.contract-pnl-detalization-table tr td .smaller-label {
  font-size: calc(14px / 1.3) !important;
}
table.contract-pnl-detalization-table tr td:first-child {
  text-align: left;
}
.no-data-img {
  background: url(img/no-data-img.jpeg);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  float: none;
  display: block;
  margin-top: 10px;
  background-position: center;
  background-size: cover;
}
.no-data-img-small {
  width: 50px;
  height: 50px;
}
.no-data-img-small .no-data-img {
  width: 50px;
  height: 50px;
}
.read-only-resource .ui-select-match > .btn {
  padding: 0;
  min-height: 0 !important;
}
.user-dropdown-btn .fa.show-on-hover,
.table-nav-tabs li .fa.show-on-hover,
.user-dropdown-btn .fa.bounce-on-hover,
.table-nav-tabs li .fa.bounce-on-hover,
.user-dropdown-btn .fa.spin-on-hover,
.table-nav-tabs li .fa.spin-on-hover {
  display: none;
}
.user-dropdown-btn:hover .fa.hide-on-hover,
.table-nav-tabs li:hover .fa.hide-on-hover,
.user-dropdown-btn:hover .fa.hide-on-bounce,
.table-nav-tabs li:hover .fa.hide-on-bounce,
.user-dropdown-btn:hover .fa.hide-on-spin,
.table-nav-tabs li:hover .fa.hide-on-spin {
  display: none;
}
.user-dropdown-btn:hover .fa.show-on-hover,
.table-nav-tabs li:hover .fa.show-on-hover,
.user-dropdown-btn:hover .fa.bounce-on-hover,
.table-nav-tabs li:hover .fa.bounce-on-hover,
.user-dropdown-btn:hover .fa.spin-on-hover,
.table-nav-tabs li:hover .fa.spin-on-hover {
  display: inline-block;
}
.quick-modal .form-group-container {
  display: block;
  width: 100% !important;
}
.quick-modal .quick-modal-hide {
  display: none;
}
.invoice-inside-table {
  table-layout: fixed;
  margin-bottom: 0 !important;
}
.invoice-inside-table tr td {
  border: 0 !important;
  font-size: calc(14px / 1.3);
  border-bottom: 1px dashed #e8e6e6 !important;
  white-space: normal;
  text-align: left;
}
.invoice-inside-table tr:last-child td {
  border-bottom: 0 !important;
}
.approvals-by-config-container .col-xs-12 .btn {
  margin: 5px;
}
.passport-block-mode {
  background: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-left: 15px !important;
  padding-top: 15px !important;
}
.passport-block-mode .alert-danger-dashed {
  padding: 30px !important;
  overflow: hidden !important;
  line-height: 1.5 !important;
  height: 380px;
  margin-bottom: 10px !important;
  border-bottom: 2px solid #d2cece !important;
  overflow: visible;
}
.passport-block-mode .alert-danger-dashed .rounded-with-background {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 100%;
  padding: 10px;
  width: 100px;
  margin: 0 auto;
}
.passport-block-mode .alert-danger-dashed .rounded-with-background .fa-ship {
  color: #847979;
}
.passport-block-mode .alert-danger-dashed .rounded-with-dark-background {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 100%;
  padding: 10px;
  width: 100px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.08);
}
.passport-block-mode .alert-danger-dashed .rounded-with-dark-background .fa-ship {
  color: #847979;
}
.passport-block-mode .alert-danger-dashed .rounded-with-dark-background .fa-ship {
  color: #d2cece;
}
.passport-block-mode .alert-danger-dashed .item-settings-dropdown:hover .dropdown-menu {
  left: -260px !important;
}
.passport-block-mode .alert-danger-dashed h1,
.passport-block-mode .alert-danger-dashed h2,
.passport-block-mode .alert-danger-dashed h3,
.passport-block-mode .alert-danger-dashed h4,
.passport-block-mode .alert-danger-dashed h5 {
  text-align: center;
  white-space: normal;
}
.passport-block-mode .invoice-inside-table tr td {
  padding: 2px !important;
  border: 1px dashed #e8e6e6 !important;
  text-align: center !important;
  vertical-align: middle !important;
}
.passport-block-mode .invoice-inside-table-no-border tr td {
  border: 0 !important;
}
.contracts-wrapper-light-mode.passport-block-mode .request-list,
.contracts-wrapper-light-mode .contract-details-container-cut {
  margin-bottom: 15px !important;
  display: inline-block;
}
.request-table .item-settings-dropdown:hover .dropdown-menu,
.table-template .item-settings-dropdown:hover .dropdown-menu {
  top: -8px;
  left: 15px;
}
.table-fix-head {
  overflow-y: auto;
  height: 500px;
}
.table-fix-head thead th {
  position: sticky;
  top: 0;
  z-index: 999;
}
.table-fix-head table {
  border-collapse: collapse;
  width: 100%;
}
.bigger-invoice-list .alert-danger-dashed h4 {
  font-size: calc(14px * 1.2) !important;
}
.bigger-invoice-list .alert-danger-dashed h6 {
  font-size: 14px !important;
  font-family: 'Regular', sans-serif !important;
}
.bigger-invoice-list .alert-danger-dashed .invoice-inside-table tr td {
  font-size: 14px !important;
}
.bigger-invoice-list .alert-danger-dashed .invoice-inside-table tr td .smaller-label {
  font-size: calc(14px / 1.2) !important;
}
.ui-select-no-choice:empty {
  padding: 0 !important;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.space-between .actions-btn,
.space-between .indent-left {
  margin-left: 10px !important;
}
.space-between .item-settings-dropdown .dropdown-menu:after {
  content: '';
  position: absolute;
  top: 0;
  left: -40px;
  height: 32px;
  width: 40px;
  cursor: pointer;
  pointer-events: none;
}
.space-between .item-settings-dropdown .open .dropdown-menu {
  top: -8px;
  left: 15px !important;
}
.space-between .item-settings-dropdown .open .dropdown-menu:after {
  pointer-events: initial;
}
.space-between .item-settings-dropdown .fa {
  min-width: 12px;
}
.space-between .label {
  align-self: flex-start;
}
.fa-file-text {
  min-width: 8px;
}
.fa-sort-desc {
  min-width: 12px;
}
.passport-recap-table tr > td {
  text-align: left !important;
  white-space: normal !important;
  word-wrap: break-word;
}
.table-highlighted tr td {
  background: #f9f9f7 !important;
  border-right: 1px solid #d4e0ec !important;
  border-left: 1px solid #d4e0ec !important;
  border-bottom: 1px solid #d4e0ec !important;
  border-radius: 0 !important;
  font-family: 'Semibold', sans-serif !important;
}
.help-btn-group .item-settings-dropdown .dropdown-menu {
  left: calc(100% - 20px) !important;
}
.new-cashflow-table tr td {
  font-size: calc(14px / 1.2) !important;
  white-space: nowrap;
}
.new-cashflow-table tr td .fa {
  margin-left: 5px;
  font-size: calc(14px / 1.5);
}
.new-cashflow-table tr td .negative-number .fa {
  color: #cf6163 !important;
}
.new-cashflow-table tr td .label {
  display: inline-block !important;
  font-size: calc(14px / 1.2) !important;
}
.new-cashflow-table tr td .detalization-unit {
  padding: 4px;
  margin-bottom: 5px;
  border-radius: 6px;
}
.new-cashflow-table tr td .detalization-unit a {
  font-family: 'Regular', sans-serif !important;
}
.new-cashflow-table tr td input[type='checkbox'],
.new-cashflow-table tr td input[type='radio'] {
  width: 15px;
  height: 15px;
  margin-right: 0;
  float: none;
}
.simplified-view {
  background: #fff !important;
}
.simplified-view .page-header-main {
  background: #435c75 !important;
  margin-bottom: 0;
}
.simplified-view .page-header-main .main-nav-tabs li.active > a {
  background: #fff !important;
}
.simplified-view .page-header-main .main-nav-tabs li > a {
  font-size: calc(14px * 1.1) !important;
}
.simplified-view .nav-tabs-with-hidden-naming li .to-hide,
.simplified-view .main-nav-tabs li .to-hide {
  font-size: calc(14px / 1.2) !important;
}
.simplified-view .help-btn-group a {
  color: #34495e !important;
}
.simplified-view .main-nav-tabs li.active > a .help-btn-group a span {
  display: none;
}
.simplified-view .help-btn-group a span {
  display: none;
}
.simplified-view .help-btn-group a.gt-head-filter span {
  display: inline-block;
}
.simplified-view .container-btn-group,
.simplified-view .small-screen-hide,
.simplified-view .pagination-select {
  display: none;
}
.simplified-view .create-contract-page .page-header-main h1 {
  display: block;
}
.simplified-view .object-details-container .form-group-container .form-group.col-xs-12 {
  border-bottom: 1px solid transparent !important;
}
.simplified-view .page-info-container {
  display: none;
}
.simplified-view .control-label .tooltip-modal {
  display: none;
}
.simplified-view .help-inline-hint {
  display: none;
}
.simplified-view .details-wrapper .well {
  margin-bottom: 0;
}
.simplified-view .page-header-main h1 .label_start {
  display: none;
}
.simplified-view .page-header-main .main-nav-tabs .label_start {
  display: none;
}
.simplified-view .page-header-main h1 .user-dropdown-btn {
  font-size: calc(14px * 1.1) !important;
}
.simplified-view .table-action-buttons .item-settings-dropdown {
  display: none;
}
.simplified-view .filters-well,
.simplified-view .analytics-container.filters-well {
  margin-top: 10px;
}
.accordion .card {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  padding: 0;
  border-bottom: 1px solid #e6e3e3 !important;
  background: #fff;
  border-radius: 6px !important;
}
.accordion .card .well,
.accordion .card .ul-inline-row,
.accordion .card .table-container-wrapper,
.accordion .card .analytics-container {
  padding: 20px !important;
}
.accordion .card .request-list-container .ul-inline-row,
.accordion .card .invoice-container .ul-inline-row,
.accordion .card .invoice-container .ul-inline-row .table-container-wrapper,
.accordion .card .finance-modal-container .ul-inline-row .table-container-wrapper {
  padding: 0 !important;
}
.accordion .card .invoice-container .ul-inline-row .table-action-buttons,
.accordion .card .finance-modal-container .ul-inline-row .start-page-header,
.accordion .card .table-action-buttons thead {
  display: none;
}
.accordion .card intermodal-transports-list-container .table-action-buttons thead {
  display: table-header-group;
}
.accordion .card:last-child {
  border-bottom: 0 !important;
}
.accordion .card-expanded {
  padding: 20px;
  margin-bottom: 25px;
  border-bottom: 0 !important;
}
.accordion .card-expanded:hover,
.accordion .card-expanded:focus {
  transform: none;
  cursor: pointer;
}
.accordion .card-header {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.accordion .card-header .start-page-header {
  margin-bottom: 0 !important;
}
.accordion .card-header .btn-add-dark {
  border-width: 0;
}
.accordion .card-header .btn-primary {
  background: #34495e !important;
  color: #fff !important;
  border: 1px solid #34495e !important;
}
.accordion .card-header:hover,
.accordion .card-header:focus {
  transform: scale(1.01);
  cursor: pointer;
}
.accordion .card-header:hover .btn-add-dark,
.accordion .card-header:focus .btn-add-dark {
  background: #34495e !important;
  color: #fff !important;
}
.accordion .collapse.in {
  margin-top: 0;
}
.accordion .collapse.in .favorite-filter-container {
  display: none;
}
.accordion .collapse .well .start-page-header,
.accordion .collapse .analytics-container .start-page-header,
.accordion .collapse .ul-inline-row .start-page-header,
.accordion .collapse .table-container-wrapper .start-page-header {
  font-size: 14px !important;
  color: #666b72;
}
.accordion .card .analytics-container .table-container-wrapper.gt-table__hovering {
  padding-bottom: 100px !important;
}
.accordion .card documents-container .table-container-wrapper.gt-table__hovering {
  padding-bottom: 350px !important;
}
.modal-content .accordion .card {
  background: transparent;
}
.additional-agreements-modal .copy-clipboard-btn {
  display: none;
}
.divflex {
  display: inline-flex !important;
}
.item-settings-dropdown.dropdown-left .dropdown-menu {
  left: calc(100% - 300px) !important;
  top: calc(100% - 20px) !important;
}
.overflow-y-small .contracts-wrapper-light-mode,
.alert .contracts-wrapper-light-mode,
.well .contracts-wrapper-light-mode,
.overflow-y-small .clients-wrapper-light-mode,
.alert .clients-wrapper-light-mode,
.well .clients-wrapper-light-mode,
.overflow-y-small .persons-wrapper-light-mode,
.alert .persons-wrapper-light-mode,
.well .persons-wrapper-light-mode {
  padding: 5px;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
}
.table-quality tr td {
  white-space: wrap !important;
  word-wrap: break-word !important;
}
.details-wrapper .passport-details-well.well {
  padding: 0 !important;
}
.details-wrapper .passport-details-well.well .item-settings-dropdown.actions-btn {
  display: none;
}
.details-wrapper .passport-details-well.well .passports-wrapper-light-mode {
  padding: 0 !important;
  margin-top: 15px !important;
}
.details-wrapper .passport-details-well.well .bank-accounts-container .request-list {
  padding: 0 !important;
}
.details-wrapper .passport-details-well.well .create-contract-page .gt-main-container .contract-details-container-cut {
  margin-top: 0 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table {
  margin-bottom: 0 !important;
  border-collapse: separate;
  width: 100% !important;
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 0.9em;
  text-align: center;
  font-size: 0.9em / 1.1;
  text-overflow: ellipsis;
  background: transparent !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td {
  border-top: 1px solid #e8e6e6 !important;
  border-right: 1px solid #f8f6f6 !important;
  line-height: 1 !important;
  vertical-align: middle !important;
  -webkit-text-stroke: 0.2px #000;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .hover-element {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
  opacity: 0.001;
  transform: translate3d(0, 8px, 0);
  visibility: hidden;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .fa-arrow-right.hover-element {
  font-size: calc(14px / 1.4) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .actions-btn {
  display: inline-flex;
  padding: 1px !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .actions-btn .fa-ellipsis {
  font-size: calc(14px * 1.1) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .fa.show-on-hover {
  display: none;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .btn-link {
  font-family: 'Regular', sans-serif !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td span {
  line-height: 1 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .text-muted {
  line-height: 1 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .input-group-table-edit-btn {
  display: flex;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .table-edit-btn {
  padding: 0 !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  margin: 0;
  line-height: 1 !important;
  margin-left: 5px;
  font-size: 14px !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .table-edit-btn .fa {
  font-size: 14px !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .table-edit-btn:first-of-type {
  margin-left: 0;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .info-table-btn:hover {
  border: 1px solid #1d82c7 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .danger-table-btn:hover {
  border: 1px solid rgba(207, 97, 99, 0.7) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .warning-table-btn:hover {
  border: 1px solid #7d6643 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .small {
  color: #666b72 !important;
  margin-top: 5px;
  text-align: left;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .small .fa,
.details-wrapper .passport-details-well.well .table-inside-table tr > td .small .updated_time,
.details-wrapper .passport-details-well.well .table-inside-table tr > td .small a,
.details-wrapper .passport-details-well.well .table-inside-table tr > td .small span {
  color: #666b72 !important;
  font-size: calc(14px / 1.3) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .small .btn-link {
  color: #1763ad !important;
  font-size: calc(14px / 1.3) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td .small .btn-link:hover {
  text-decoration: underline;
}
.details-wrapper .passport-details-well.well .table-inside-table tr:hover .fa.hide-on-hover {
  display: none;
}
.details-wrapper .passport-details-well.well .table-inside-table tr:hover .fa.show-on-hover {
  display: inline-block;
}
.details-wrapper .passport-details-well.well .table-inside-table tr:hover td .hover-element {
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
  width: auto;
}
.details-wrapper .passport-details-well.well .table-inside-table tr:hover td .fa-sort-desc.hover-element {
  font-size: calc(14px * 1.2) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > th {
  border-bottom: 1px solid #e8e6e6 !important;
  border-right: 0;
  letter-spacing: var(--letter-spacing-mega, 0.05em);
}
.details-wrapper .passport-details-well.well .table-inside-table tr > th::first-letter {
  text-transform: uppercase !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr th.filter-th {
  background: #f9f9f7 !important;
  border-bottom: 0 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr:hover th.filter-th {
  background: #f9f9f7 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr > td:last-child,
.details-wrapper .passport-details-well.well .table-inside-table tr > th:last-child {
  border-right: 0 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table th {
  color: #666b72;
  font-size: calc(14px / 1.3);
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
  font-family: 'Regular', sans-serif !important;
  cursor: pointer !important;
  vertical-align: middle !important;
}
.details-wrapper .passport-details-well.well .table-inside-table th .filter-template .ui-select-match .form-control,
.details-wrapper .passport-details-well.well .table-inside-table th .filter-template .ui-select-container .form-control,
.details-wrapper .passport-details-well.well .table-inside-table th .form-control {
  font-size: calc(14px / 1.3) !important;
  padding: 0 !important;
  /*  height:20px !important;*/
  width: 100%;
  padding-left: 5px !important;
  max-width: 100%;
  min-width: 100%;
  border-radius: 4px !important;
}
.details-wrapper .passport-details-well.well .table-inside-table th .fa.fa-sort {
  display: none;
}
.details-wrapper .passport-details-well.well .table-inside-table th .filter-template .ui-select-match .form-control {
  line-height: 2.4;
  width: 100% !important;
}
.details-wrapper .passport-details-well.well .table-inside-table th .input-group-addon {
  font-size: calc(14px / 1.3) !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-transform: none !important;
}
.details-wrapper .passport-details-well.well .table-inside-table th .form-group {
  padding: 2px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 5px !important;
  width: 100% !important;
}
.details-wrapper .passport-details-well.well .table-inside-table th .input-group {
  margin-bottom: 5px;
}
.details-wrapper .passport-details-well.well .table-inside-table th select {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.details-wrapper .passport-details-well.well .table-inside-table th .caret {
  display: none;
}
.details-wrapper .passport-details-well.well .table-inside-table th .text-muted {
  text-transform: none !important;
  color: #000000 !important;
  line-height: 1;
}
.details-wrapper .passport-details-well.well .table-inside-table th .big-font {
  font-size: calc(14px * 1.2) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table th .btn-link {
  -webkit-text-stroke: 0;
  padding: 0 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table th:hover {
  color: #666;
}
.details-wrapper .passport-details-well.well .table-inside-table .filter-th:hover {
  background: #fff !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td:first-child {
  text-align: left;
  font-family: 'Regular', sans-serif !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td:first-child .btn-link {
  color: #1763ad !important;
  border-bottom: 0 !important;
  -webkit-text-stroke: 0;
}
.details-wrapper .passport-details-well.well .table-inside-table td:first-child .btn-link:hover {
  text-decoration: underline;
}
.details-wrapper .passport-details-well.well .table-inside-table td:first-child .btn-link .fa {
  font-size: calc(14px / 1.3) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td {
  color: #0c4d40;
  text-align: center;
  vertical-align: top;
  border-radius: 0;
  font-family: 'Regular', sans-serif !important;
  line-height: 1;
}
.details-wrapper .passport-details-well.well .table-inside-table .item-settings-dropdown:hover .dropdown-menu {
  top: -8px;
  left: 15px;
}
.details-wrapper .passport-details-well.well .table-inside-table td:first-child {
  border-left: 5px solid rgba(255, 255, 255, 0) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tbody > tr > th,
.details-wrapper .passport-details-well.well .table-inside-table tfoot > tr > th,
.details-wrapper .passport-details-well.well .table-inside-table thead > tr > th {
  border-top: 0;
}
.details-wrapper .passport-details-well.well .table-inside-table td {
  border-color: #e8e6e6 !important;
  color: #000000;
  vertical-align: middle !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr td .form-control,
.details-wrapper .passport-details-well.well .table-inside-table tr th .form-control,
.details-wrapper .passport-details-well.well .table-inside-table tr td .form-control .ui-select-match-text,
.details-wrapper .passport-details-well.well .table-inside-table tr th .form-control .ui-select-match-text {
  min-height: 34px;
  min-width: 50px !important;
  overflow: hidden;
  margin-bottom: 2px;
  font-size: calc(14px / 1.2) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr td input.valid-date,
.details-wrapper .passport-details-well.well .table-inside-table tr th input.valid-date,
.details-wrapper .passport-details-well.well .table-inside-table tr td .gt-date-select input.form-control,
.details-wrapper .passport-details-well.well .table-inside-table tr th .gt-date-select input.form-control {
  margin-bottom: 0 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr td .ui-select-bootstrap > .ui-select-match > .btn,
.details-wrapper .passport-details-well.well .table-inside-table tr th .ui-select-bootstrap > .ui-select-match > .btn {
  min-width: 50px !important;
  min-height: 34px;
  height: auto;
  overflow: hidden;
  line-height: 1 !important;
  margin-bottom: 2px;
  letter-spacing: var(--letter-spacing-huge, 0.04em);
  font-size: calc(14px / 1.2) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr th .form-control,
.details-wrapper .passport-details-well.well .table-inside-table tr th .form-control .ui-select-match-text,
.details-wrapper .passport-details-well.well .table-inside-table tr th .text-muted {
  font-family: 'Regular', sans-serif !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr th .ui-select-bootstrap > .ui-select-match > .btn,
.details-wrapper .passport-details-well.well .table-inside-table tr th .form-control {
  min-height: auto;
  height: auto;
  padding: 4px !important;
}
.details-wrapper .passport-details-well.well .table-inside-table .td-with-border {
  border-right: 1px solid #e8e6e6 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td:last-child {
  border-right: 0 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table .highlighted-tr td {
  background: #f9f9f7 !important;
  border-color: #f0f0eb !important;
  color: #000000 !important;
  border-radius: 0 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table .highlighted-tr td:first-child {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-left: 0 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table .highlighted-tr td:last-child {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-right: 0 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td.highlighted {
  background: #f9f9f7 !important;
  border-right: 1px solid #f0f0eb !important;
  border-left: 1px solid #f0f0eb !important;
  border-bottom: 1px solid #f0f0eb !important;
  border-radius: 0 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td.th-left-align {
  text-align: left !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td.th-left-align div {
  text-align: left !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td.th-left-align .no-user-avatar {
  text-align: center !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td.th-center-align {
  text-align: center !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td.th-center-align div {
  text-align: center !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td.td-left-align {
  text-align: left !important;
}
.details-wrapper .passport-details-well.well .table-inside-table td.td-right-align {
  text-align: right !important;
}
.details-wrapper .passport-details-well.well .table-inside-table .highlighted-tr-first-row td {
  font-family: 'Bold', sans-serif !important;
  border-right-color: transparent !important;
}
.details-wrapper .passport-details-well.well .table-inside-table .highlighted-tr-first-row td:first-child {
  background: #ebebeb !important;
  border-bottom: 1px solid #e5e5dd !important;
  color: #000000 !important;
  border-right-color: #e8e6e6 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table .highlighted-td {
  background: #f9f9f7 !important;
  border-radius: 0 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table .highlighted-th {
  background: rgba(0, 0, 0, 0.01) !important;
  vertical-align: top !important;
}
.details-wrapper .passport-details-well.well .table-inside-table .highlighted-td.danger-highlighted {
  background: rgba(207, 97, 99, 0.13) !important;
}
.details-wrapper .passport-details-well.well .table-inside-table .highlighted-td.warning-highlighted {
  background: #f0ede9 !important;
}
.details-wrapper .passport-details-well.well .table-inside-table .highlighted-td.success-highlighted {
  background: #ebf1ef !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr td .ui-select-bootstrap .ui-select-toggle > .caret {
  display: none !important;
}
.details-wrapper .passport-details-well.well .table-inside-table tr td,
.details-wrapper .passport-details-well.well .table-inside-table tr th {
  font-size: calc(14px / 1.3) !important;
  padding: 2px !important;
  word-break: normal;
}
.config-presets-name-input {
  margin-top: 8px !important;
}
.disabled-button {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
}
.disabled-button .btn {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
}
ul.form-stepper {
  counter-reset: section;
  margin-bottom: 3rem;
}
ul.form-stepper .form-stepper-circle {
  position: relative;
}
ul.form-stepper .form-stepper-circle span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.form-stepper-horizontal {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
ul.form-stepper > li:not(:last-of-type) {
  margin-bottom: 0.625rem;
  -webkit-transition: margin-bottom 0.4s;
  -o-transition: margin-bottom 0.4s;
  transition: margin-bottom 0.4s;
}
.form-stepper-horizontal > li:not(:last-of-type) {
  margin-bottom: 0 !important;
}
.form-stepper-horizontal li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.form-stepper-horizontal li:not(:last-child):after {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 1px;
  content: '';
  top: 32%;
}
.form-stepper-horizontal li:after {
  background-color: #fff;
}
.form-stepper-horizontal li.form-stepper-completed:after {
  background-color: #7d6643;
}
.form-stepper-horizontal li:last-child {
  flex: unset;
}
ul.form-stepper li a .form-stepper-circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 0;
  line-height: 1.7rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.01);
  border-radius: 50%;
}
.form-stepper .form-stepper-active .form-stepper-circle {
  background-color: #263746 !important;
  color: #fff;
}
.form-stepper .form-stepper-active .stepper-label {
  color: #fff !important;
}
.form-stepper .form-stepper-active .form-stepper-circle:hover {
  background-color: #263746 !important;
  color: #fff !important;
}
.form-stepper .form-stepper-unfinished .form-stepper-circle {
  background-color: #fff;
}
.form-stepper .form-stepper-completed .form-stepper-circle {
  background-color: #263746 !important;
  color: #fff;
}
.form-stepper .form-stepper-completed .stepper-label {
  color: #263746 !important;
}
.form-stepper .form-stepper-completed .form-stepper-circle:hover {
  background-color: #263746 !important;
  color: #fff !important;
}
.form-stepper .form-stepper-active span.text-muted {
  color: #fff !important;
}
.form-stepper .form-stepper-completed span.text-muted {
  color: #fff !important;
}
.form-stepper .stepper-label {
  font-size: 1rem;
  margin-top: 0.5rem;
}
.form-stepper a {
  cursor: default;
}
.currency-symbol {
  text-transform: uppercase !important;
}
.btn-payment-plan-reset {
  margin-right: 4px !important;
}
.search-inside-ui-select {
  position: absolute;
  left: 10px;
  font-size: calc(14px / 1.3);
}
.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
  z-index: 999;
  position: sticky;
  opacity: 1;
  background: #e5e5e4 !important;
  font-size: 14px;
  line-height: 1 !important;
  color: #cf6163 !important;
  -webkit-text-stroke: 0;
}
.ui-select-multiple.ui-select-bootstrap .ui-select-match .close .fa-times {
  font-size: 14px !important;
}
.analytical-plan-fact-pnl-report-table tr td .fa {
  margin-left: 5px;
  color: #666b72 !important;
  font-size: calc(14px / 1.5);
}
.analytical-plan-fact-pnl-report-table tr td .negative-number .fa {
  color: #cf6163 !important;
}
.payment-plan-warning-icon-wrapper {
  position: absolute;
  left: -25px;
  top: calc(50% - 10px);
}
.payment-plan-warning-icon {
  color: orange;
  margin-right: 4px;
}
.payment-plan-warning-icon:before {
  font-size: 20px;
}
.quality-rules-table tbody .highlighted-tr td {
  border-top: 2px solid #e8e6e6 / 1.1 !important;
  border-radius: 0 !important;
  vertical-align: middle !important;
}
.quality-rules-table tbody .highlighted-tr td:first-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.quality-rules-table tbody .highlighted-tr td:last-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.update-required-field .help-inline {
  text-align: center;
  border-radius: 0 0 4px 4px !important;
}
.analytics-container .update-required-field textarea.ng-invalid.ng-touched,
.analytics-container .update-required-field textarea.ng-pristine {
  background: rgba(207, 97, 99, 0.13) !important;
  border: 1px solid #cf6163 !important;
  margin-bottom: 0 !important;
  border-radius: 4px 4px 0 0 !important;
  border-bottom: 0;
}
.analytics-container .update-required-field textarea.ng-invalid.ng-touched + .help-inline,
.analytics-container .update-required-field textarea.ng-pristine + .help-inline {
  display: block;
}
.analytics-container .update-required-field textarea + .help-inline {
  display: none;
}
.td-width-payment-cell,
.td-width-vehicle-number-cell {
  min-width: 170px;
}
.td-width-buyer-cell,
.td-width-purchase-price-cell,
.td-width-sale-price-cell,
.td-width-import-contract-cell,
.td-width-volume-recived-cell,
.td-width-volume-departed-real-cell,
.td-width-buyer-total-invoiced-plan-cell,
.td-width-buyer-total-invoiced-cell,
.td-width-volume-departed-cell {
  min-width: 115px;
}
.td-width-sale-date-cell,
.td-width-supplier-invoiced-balance-cell,
.td-width-supplaier-paid-balance-cell {
  min-width: 75px;
}
.td-width-export-contract-cell,
.td-width-volume-borded-cell,
.td-width-supplier-to-be-invoiced-balance-cell,
.td-width-supplier-paid-cell,
.td-width-supplier-total-paid-cell,
.td-width-byuyer-to-be-invoiced-balance-cell,
.td-width-buyer-invoiced-balance-cell,
.td-width-buyer-paid-cell,
.td-width-buyer-paid-balance-cell,
.td-width-buyer-total-paid-cell {
  min-width: 90px;
}
.td-width-volume-lost-cell,
.td-width-volume-departed-cell,
.td-width-buyer-to-be-invoiced-cell {
  min-width: 145px;
}
.td-width-supplier-to-be-invoiced-cell,
.td-width-supplier-total-invoiced-plan-cell {
  min-width: 170px;
}
.td-width-supplier-invoiced-cell,
.td-width-buyer-invoiced-cell {
  min-width: 140px;
}
.td-width-supplier-total-invoiced-cell {
  min-width: 150px;
}
.td-width-penalties-cell {
  min-width: 100px;
}
.td-width-approval-status-cell {
  min-width: 130px;
}
.td-width-status-cell {
  min-width: 120px;
}
gt-resource-select .ui-select-choices.submenu {
  max-height: 120px;
  top: calc(20px - 100%) !important;
}
contract-list-table-view .gt-table__hovering {
  padding-bottom: 380px !important;
}
a.disabled-link {
  pointer-events: none;
  cursor: default;
}
prices-position-inline-create .form-control {
  height: auto;
  min-height: 34px;
}
.table-form {
  margin-bottom: 15px;
}
.table-form .table-form-field .form-group.col-xs-12 .row {
  display: flex;
  flex-direction: column;
}
.table-form .table-form-field .form-group.col-xs-12 .row .control-label-wrapper {
  display: block;
  width: 100%;
  min-height: 15px;
}
.table-form .table-form-field .form-group.col-xs-12 .row .control-label-wrapper .position-row-detail-container {
  width: 40%;
  display: table-cell;
}
.table-form .table-form-field .form-group.col-xs-12 .row .input-wrapper {
  width: 100%;
  margin-bottom: 5px;
}
.table-form .table-form-field .form-group.col-xs-12 .row .input-wrapper .position-row-detail-container {
  width: 60%;
}
.tooltip-left.tooltip:hover::before {
  top: 0px;
  left: 0px;
}
.custom-values-container .table-nav-tabs {
  display: none;
}
.custom-values-table thead {
  display: none;
}
.custom-values-table tr td {
  white-space: normal;
}
.custom-values-table tr td:first-child {
  border-top: 0;
  text-align: left !important;
  text-overflow: ellipsis;
  color: #666b72;
  width: 40%;
}
.object-details-container .custom-values-table tr td {
  border: 0 !important;
  border-bottom: 1px dashed #e8e6e6 !important;
}
.object-details-container .custom-values-table tr td .edit-button {
  display: none !important;
}
.object-details-container .custom-values-table tr td:first-child {
  text-align: right !important;
  width: 40%;
}
.object-details-container .custom-values-table .not-empty {
  display: none;
}
.object-details-container .no-data-img,
.object-details-container .no-data-row {
  display: none;
}
.multiposition-form .help-inline-hint {
  width: 100%;
}
.text-highlighted {
  background-color: #f3ffa2 !important;
}
table.inline-edit-table {
  padding-bottom: 300px;
}
table.inline-edit-table tr td {
  padding: 0 !important;
}
table.inline-edit-table tr td .form-group {
  padding: 0 !important;
}
table.inline-edit-table tr td .help-inline-hint,
table.inline-edit-table tr td .input-group-addon-search,
table.inline-edit-table tr td .control-label {
  display: none !important;
}
table.inline-edit-table tr td .input-wrapper {
  width: 100% !important;
}
table.inline-edit-table tr td .input-wrapper .copy-clipboard-btn {
  visibility: hidden;
}
table.inline-edit-table tr td .input-wrapper:hover .copy-clipboard-btn {
  visibility: visible;
}
table.inline-edit-table tr td .form-control,
table.inline-edit-table tr td textarea,
table.inline-edit-table tr td .ui-select-bootstrap > .ui-select-match > .btn {
  margin: 0 !important;
  padding: 4px !important;
  width: 100% !important;
  line-height: 1.2 !important;
  min-height: 20px !important;
  max-height: 20px !important;
  min-width: 80px !important;
  white-space: nowrap;
  font-size: calc(14px / 1.3) !important;
  border: 1px solid #e8e6e6 !important;
}
table.inline-edit-table tr td .form-control::-webkit-input-placeholder,
table.inline-edit-table tr td textarea::-webkit-input-placeholder,
table.inline-edit-table tr td .ui-select-bootstrap > .ui-select-match > .btn::-webkit-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: calc(14px / 1.3) !important;
  text-overflow: ellipsis;
}
table.inline-edit-table tr td .form-control::-moz-placeholder,
table.inline-edit-table tr td textarea::-moz-placeholder,
table.inline-edit-table tr td .ui-select-bootstrap > .ui-select-match > .btn::-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: calc(14px / 1.3) !important;
  text-overflow: ellipsis;
}
table.inline-edit-table tr td .form-control:-moz-placeholder,
table.inline-edit-table tr td textarea:-moz-placeholder,
table.inline-edit-table tr td .ui-select-bootstrap > .ui-select-match > .btn:-moz-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: calc(14px / 1.3) !important;
  text-overflow: ellipsis;
}
table.inline-edit-table tr td .form-control:-ms-input-placeholder,
table.inline-edit-table tr td textarea:-ms-input-placeholder,
table.inline-edit-table tr td .ui-select-bootstrap > .ui-select-match > .btn:-ms-input-placeholder {
  font-family: 'Regular', sans-serif !important;
  color: #000000;
  font-size: calc(14px / 1.3) !important;
  text-overflow: ellipsis;
}
table.inline-edit-table tr td .form-control .ui-select-match-text {
  margin: 0 !important;
  min-height: 20px !important;
  max-height: 20px !important;
  min-width: 80px !important;
  padding: 4px !important;
  white-space: nowrap;
}
table.inline-edit-table tr td textarea.form-control {
  min-height: 20px !important;
  max-height: 20px !important;
  min-width: 120px !important;
  width: 120px !important;
}
table.inline-edit-table tr td select.form-control {
  padding: 0 !important;
}
table.inline-edit-table tr td .ui-select-bootstrap .ui-select-choices-row > span {
  padding: 2px 4px !important;
  font-size: calc(14px / 1.3) !important;
}
table.inline-edit-table tr td .text-muted {
  color: #000000;
  display: block;
  padding: 4px !important;
  line-height: 1.2 !important;
  font-size: calc(14px / 1.3) !important;
}
table.inline-edit-table tr td .ui-select-bootstrap > .ui-select-match > .btn {
  padding: 0 !important;
}
table.inline-edit-table tr td .ui-select-multiple.ui-select-bootstrap {
  margin: 0 !important;
}
table.inline-edit-table tr td .copy-clipboard-btn {
  position: absolute;
  top: 0;
  right: 5px;
}
table.inline-edit-table tr td .clear-btn {
  position: absolute;
  top: 0;
  right: 0;
}
table.inline-edit-table tr td .add-btn {
  padding: 0 2px !important;
  position: absolute;
  top: 0;
  right: 0;
  width: auto !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border: 0 !important;
  color: #000000 !important;
}
table.inline-edit-table tr td .add-btn .fa {
  display: none;
}
table.inline-edit-table tr td .select-with-btn {
  float: none;
  width: 100%;
}
table.inline-edit-table tr:first-child {
  background-color: #ebf1ef;
}
.embedded-superset iframe {
  width: 100%;
  height: 1750px;
}
.passports-table .progress .negative-number {
  color: #b94a48 !important;
  background-color: #f2dede !important;
}
.multicontract-fixed-cols-table tfoot > tr > td:nth-child(1),
.multicontract-fixed-cols-table tbody > tr > td:nth-child(1),
.multicontract-fixed-cols-table thead > tr > th:nth-child(1) {
  position: sticky;
  left: 0 !important;
  width: 290px !important;
  min-width: 290px !important;
  z-index: 30 !important;
  height: 30px;
  min-height: 30px;
  vertical-align: middle !important;
  background: #fff;
}
.multicontract-fixed-cols-table tfoot > tr > td:nth-child(1) .item-settings-dropdown .dropdown-menu,
.multicontract-fixed-cols-table tbody > tr > td:nth-child(1) .item-settings-dropdown .dropdown-menu,
.multicontract-fixed-cols-table thead > tr > th:nth-child(1) .item-settings-dropdown .dropdown-menu {
  left: calc(100% + 7px) !important;
}
.multicontract-fixed-cols-table tfoot > tr > td:nth-child(2),
.multicontract-fixed-cols-table tbody > tr > td:nth-child(2),
.multicontract-fixed-cols-table thead > tr > th:nth-child(2) {
  position: sticky;
  left: 290px;
  width: 260px !important;
  vertical-align: middle !important;
  background: #fff;
  z-index: 12;
}
.multicontract-fixed-cols-table tfoot > tr > td:nth-child(2) .item-settings-dropdown .dropdown-menu,
.multicontract-fixed-cols-table tbody > tr > td:nth-child(2) .item-settings-dropdown .dropdown-menu,
.multicontract-fixed-cols-table thead > tr > th:nth-child(2) .item-settings-dropdown .dropdown-menu {
  left: calc(100% + 1px) !important;
}
.basis-doc-table tr td .ui-select-bootstrap > .ui-select-match > .btn,
.basis-doc-table tr td .form-control .ui-select-match-text,
.basis-doc-table tr td .form-control,
.basis-doc-table tr th .ui-select-bootstrap > .ui-select-match > .btn {
  min-height: 20px;
  max-height: 20px;
  white-space: nowrap;
  margin: 0 !important;
}
.payment-overdue-alert {
  position: fixed;
  top: 0;
  width: 100%;
  height: 20px;
  padding: 2px;
  text-align: center;
  margin: 0;
  z-index: 9999;
}
.payment-overdue-padding {
  padding-top: 20px !important;
}
.payment-overdue-top {
  top: 20px !important;
}
.fixed-date-select input {
  height: 20px;
}
.green-text {
  color: #1bcc1b;
}
.all-info-container h2 {
  font-size: 18px !important;
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 5px !important;
  letter-spacing: var(--letter-spacing-normal, 0.02em);
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search[disabled] {
  cursor: not-allowed !important;
}
/*--- MEDIA QUERIES --*/
/* min3200 */
/* max991 */
@media (max-width: 991px) {
  .object-details-container select[multiple] option,
  .object-details-container select[size] option {
    padding: 2px !important;
  }
  .position-row-detail-container select[multiple] option,
  .position-row-detail-container select[size] option {
    padding: 2px !important;
  }
  .clients-logo-start img {
    max-height: 70px;
  }
  .page-info-container {
    display: none;
  }
  .analytics-container .start-page-header,
  .ul-inline-row .start-page-header,
  .table-container-wrapper .start-page-header {
    margin: 0 !important;
  }
  .modal-template-third-width .modal-dialog,
  .modal-template-three-thirds-width .modal-dialog,
  .modal-template-half-width .modal-dialog {
    width: 100% !important;
    float: none;
  }
  .details-wrapper .process-container-well .item-process-container .process-item {
    font-size: 14px / 1.1 !important;
  }
  .passports-container .passport-properties-details .alert {
    font-size: 14px / 1.1 !important;
  }
  .start .start-title .fa {
    font-size: 28px !important;
  }
  .modal-content .alert-info {
    position: relative;
  }
  #login .logo-login {
    width: 400px !important;
    height: 250px !important;
    display: block;
    margin-bottom: 100px;
    margin-top: 300px;
  }
  #login .btn-link {
    font-size: 35px !important;
  }
  #login .alert {
    font-size: 28px !important;
  }
  .navbar-nav {
    margin: 0;
  }
  .navbar-user .navbar-top-grouped {
    margin: 0;
    margin-top: 10px !important;
  }
  .navbar-user .navbar-top-grouped li {
    display: inline-block;
  }
  .navbar-user .navbar-top-grouped li.inside-navbar {
    display: block;
  }
  .navbar-collapse.in {
    overflow-y: inherit;
  }
  .if-navbar-top .central-container {
    padding-top: 68px !important;
  }
  .navbar-default .navbar-collapse {
    background: #34495e;
    border-bottom-right-radius: 12px;
    overflow: hidden;
    padding: 12px !important;
    margin-top: -1px;
  }
  .navbar-brand {
    display: none !important;
  }
  .notify-dropdown-li {
    position: absolute !important;
    right: 100px !important;
    top: 13px !important;
  }
  .user-dropdown-li {
    position: relative !important;
    right: inherit !important;
    top: inherit !important;
    float: left;
  }
  .navbar-user-dark li {
    display: inline-block !important;
  }
  .notify-dropdown-btn span {
    margin-top: 0 !important;
  }
  .page-header-main h1 .user-dropdown-btn span {
    display: inline-block !important;
  }
  .nav-tabs.table-nav-tabs li {
    display: inline-block !important;
  }
  .nav-tabs.table-nav-tabs li a {
    font-size: 16.8px !important;
    padding: 10px !important;
  }
  .nav-tabs.table-nav-tabs li.active > a {
    font-size: 16.8px !important;
    padding: 10px !important;
  }
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    background-color: #fff;
    overflow: hidden;
  }
  .item-settings-dropdown li {
    display: block;
  }
  .item-settings-dropdown li span {
    display: inline-block !important;
  }
  .modal-form-block .control-label {
    border-right: 0 !important;
  }
}
/* max991 */
@media (max-width: 991px) and (orientation: portrait) {
  .start .start-block-row {
    display: block;
  }
  .help-btn-group .item-settings-dropdown a:nth-of-type(1) {
    margin: 0 !important;
  }
  .navbar-nav .open .dropdown-menu {
    position: absolute !important;
    background-color: #fff !important;
    right: 0;
    overflow: hidden;
  }
  .table-action-buttons .col-sm-3 {
    padding: 0;
  }
  .wizard {
    display: none;
  }
  .card-header h2.start-page-header {
    margin-bottom: 15px !important;
  }
  .card-body .space-between .item-settings-dropdown {
    font-size: 14px;
  }
  .create-contract-page .gt-main-container .page-header-main h1 {
    padding-bottom: 5px !important;
  }
  .alert {
    line-height: 1;
    font-size: 14px;
  }
  .navbar-header {
    height: 80px;
    background: #34495e;
  }
  .navbar-collapse.in {
    overflow-y: auto;
  }
  .if-navbar-top .central-container {
    padding-top: 75px !important;
  }
  .page-header-main {
    padding-right: 15px;
    padding-left: 15px;
  }
  br {
    font-size: 12px !important;
  }
  .page-header-main .main-nav-tabs li.active > a {
    padding-top: 22px !important;
  }
  .gt-main-container.col-xs-11,
  .gt-main-container-with-sidebar.col-xs-10 {
    width: 100%;
  }
  .help-btn-group a {
    display: block;
    width: 100% !important;
    border-radius: 0.25em !important;
    margin-bottom: 2px;
    height: 30px !important;
  }
  .help-btn-group a span {
    display: inline-block !important;
  }
  .item-settings-dropdown a:nth-of-type(1) {
    white-space: nowrap;
  }
  .item-settings-dropdown .dropdown-toggle.long-title {
    white-space: normal;
  }
  .item-settings-dropdown {
    padding: 0;
    width: 100%;
    display: block;
    margin: 0;
    margin-bottom: 5px;
  }
  table tr td .item-settings-dropdown {
    width: auto;
  }
  .create-contract-page .gt-main-container .help-btn-group {
    margin: 20px;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    display: inline-block;
    position: relative;
    width: 100%;
  }
  .create-contract-page .gt-main-container .help-btn-group a:last-child {
    margin-right: 5px !important;
  }
  .create-contract-page .gt-main-container .help-btn-group .item-settings-dropdown {
    float: left;
    margin: 1px !important;
    margin-left: -5px !important;
    margin-bottom: 3px !important;
  }
  .table-action-buttons {
    margin-bottom: 5px;
  }
  .table-action-buttons div.pull-right {
    float: none !important;
    margin: 0;
  }
  .table-action-buttons .item-settings-dropdown {
    margin-left: 0 !important;
    margin: 0 !important;
    margin-bottom: 5px !important;
  }
  .table-action-buttons .btn-group {
    margin-right: 0 !important;
    margin-left: 0;
    width: 100%;
    display: block;
    margin-bottom: 5px !important;
  }
  .table-action-buttons .btn {
    margin-left: 0 !important;
    margin-bottom: 5px !important;
  }
  .table-action-buttons .col-md-2,
  .table-action-buttons .col-xs-2,
  .table-action-buttons .col-sm-2,
  .table-action-buttons .pull-left {
    margin: 0;
    width: 100%;
    display: block;
    padding: 0;
  }
  .table-action-buttons .alert-applied-filters .btn {
    padding: 4px !important;
  }
  .table-action-buttons .item-settings-dropdown a:nth-of-type(1) {
    padding: 12px;
    text-align: center;
  }
  .col-sm-12.col-md-5 {
    padding-left: 0;
    padding-right: 0;
  }
  .table-fixed-layout,
  .contract_detail_table {
    table-layout: auto !important;
  }
  .main-list {
    margin-top: 10px;
  }
  .lineup-header {
    display: none;
  }
  .col-md-6.pull-left,
  .col-md-6.pull-right,
  .col-sm-6.pull-left,
  .col-sm-6.pull-right,
  .col-sm-4 {
    padding-right: 0;
    padding-left: 0;
  }
  .well .btn.btn-info,
  .well .btn.btn-success,
  .well .btn.btn-warning,
  .well .btn.btn-danger {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .gt-detail-page .central-container {
    padding-top: 0 !important;
  }
  .create-contract-page .gt-sidebar.col-xs-2 {
    display: none !important;
  }
  .col-md-7.pull-right {
    float: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .passports-wrapper-light-mode .request-list .avg-price {
    font-size: 14px;
  }
  .client-name-header {
    display: none;
  }
  .navbar-nav {
    margin: 0;
  }
  .item-settings-dropdown .dropdown-menu .btn,
  .item-settings-dropdown .dropdown-menu a {
    padding: 8px !important;
  }
  .page-total-analytics-container .item-details-block-transparent .alert {
    display: grid;
    margin-bottom: 10px;
    padding: 10px !important;
    width: 100%;
  }
  .page-total-analytics-container .item-details-block-transparent .alert .label {
    font-size: 18.2px !important;
  }
  .page-total-analytics-container .item-details-block-transparent .alert .property {
    font-size: 14px !important;
  }
  .contracts-wrapper-light-mode,
  .clients-wrapper-light-mode,
  .persons-wrapper-light-mode {
    padding: 5px;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .notify-dropdown-li {
    display: inline-block !important;
  }
  .notify-dropdown-li {
    position: absolute !important;
    right: 100px !important;
    top: 28px !important;
  }
  .user-dropdown-li {
    position: relative !important;
    right: inherit !important;
    top: inherit !important;
  }
  .navbar-user-dark li {
    display: inline-block !important;
  }
  .navbar-nav.user-dropdown.navbar-user-dark {
    display: block;
    margin: 2px;
  }
  .notify-dropdown-btn,
  .user-dropdown-li {
    font-size: 14px !important;
  }
  .nav .navbar-nav .user-dropdown .navbar-user-dark {
    display: none !important;
  }
  .navbar-user .navbar-top-grouped {
    width: 100%;
  }
  .navbar-user .navbar-top-grouped li {
    display: block;
    text-align: left !important;
  }
  .navbar-user .navbar-top-grouped li.inside-navbar {
    display: block;
  }
  .navbar-user .navbar-top-grouped li.inside-navbar a {
    display: block;
    text-align: left !important;
  }
  .navbar-user .navbar-top-grouped li > a {
    font-size: 16.8px !important;
    height: auto !important;
    text-align: left !important;
  }
  .navbar-user .navbar-top-grouped li > a span {
    font-size: 14px !important;
  }
  .navbar-user .navbar-top-grouped {
    min-height: 100px !important;
  }
  .navbar-user .navbar-top-grouped li {
    border-right: 0 !important;
    font-size: 16.8px !important;
    height: auto !important;
    text-align: left !important;
  }
  .navbar-user .navbar-top-grouped li .fa {
    font-size: 14px !important;
  }
  .navbar-user .user-dropdown-li .fa {
    font-size: 16.8px !important;
  }
  .col-sm-2 {
    width: 100% !important;
  }
  .col-sm-3 {
    width: 100% !important;
  }
  .col-sm-4 {
    width: 100% !important;
  }
  .small-screen-show {
    display: inline-block !important;
  }
  .small-screen-hide {
    display: none !important;
  }
  .table-action-buttons .btn,
  .table-action-buttons span .btn {
    padding: 12px !important;
    width: 100%;
    display: block;
    clear: both;
  }
  .main-table tr td,
  .table-template tr td,
  table tr td {
    white-space: nowrap !important;
  }
  .main-table tr th .fa-info-circle,
  .table-template tr th .fa-info-circle,
  table tr th .fa-info-circle {
    display: none;
  }
  .clients-table tr td:nth-child(1) .smaller-label span {
    font-size: 14px !important;
  }
  .clients-table tr td:nth-child(1) .smaller-label {
    font-size: 14px !important;
  }
  .item-settings-dropdown .dropdown-menu .btn,
  .item-settings-dropdown .dropdown-menu a {
    font-size: 14px !important;
  }
  .contract-details-container-cut {
    overflow-x: auto;
  }
  .modal-content .modal-title {
    padding: 12px !important;
  }
  .modal-form-container .gt-date-select .dropdown-menu {
    left: -150px !important;
  }
  .main-nav-tabs li {
    display: inline-flex !important;
  }
  .main-nav-tabs li > a {
    padding-bottom: 15px !important;
    padding-top: 15px !important;
    overflow-x: hidden;
    max-width: 100px;
  }
  .main-nav-tabs li > a .label_start {
    padding: 2px !important;
    display: none;
  }
  .main-nav-tabs li.active > a {
    padding-bottom: 15px !important;
    padding-top: 15px !important;
  }
  .table-nav-tabs li a {
    padding: 10px !important;
  }
  .trader-nav-tabs {
    overflow-x: auto;
  }
  .trader-nav-tabs li a {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .passports-container .nav-tabs span {
    display: none !important;
  }
  .passports-container small {
    display: none;
  }
  .if-navbar-left .central-container {
    padding-left: 10px;
  }
  .global-container {
    overflow: visible;
  }
  .left-sidebar,
  .central-container,
  .right-sidebar {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .create-contract-page .gt-sidebar.col-xs-1 {
    display: none;
  }
  .if-navbar-left .left-sidebar {
    padding-left: 0 !important;
  }
  .right-sidebar {
    box-shadow: 0 !important;
  }
  .all-filters-block {
    width: 100%;
  }
  .navbar-collapse {
    max-height: 2000px;
  }
  .client-name-header {
    margin-left: 110px;
    margin-top: 55px !important;
  }
  .page-header-main h1 .label_start {
    font-size: 14px !important;
  }
  .navbar-user {
    margin: 0 !important;
  }
  .navbar-user .btn {
    padding: 0;
    margin: 0;
    border: 0 !important;
  }
  .navbar-user li {
    display: inline-block;
  }
  .navbar-user .user-dropdown-li .btn {
    padding: 2px !important;
  }
  body {
    font-size: 16.8px;
  }
  .details-wrapper .well {
    padding: 20px;
  }
  .details-wrapper h2.start-page-header {
    font-size: 15.4px;
  }
  #attach_btn,
  #grain_quality_btn,
  #notes_btn,
  .add-new-payment,
  .costs-btn {
    float: none;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  input[type='date'] {
    border-radius: 4px !important;
  }
  .btn {
    border-radius: 6px !important;
    padding: 8px !important;
    font-size: 14px / 1.1 !important;
  }
  .btn-fixed {
    border-radius: 100% !important;
    right: 1%;
    bottom: 1%;
    width: 80px !important;
    height: 80px !important;
    font-size: 28px !important;
    padding: 0 !important;
  }
  .edit-btns-group-main .btn-fixed {
    background: linear-gradient(45deg, #e9e9e9 25%, #f5f5f5 15%, #f5f5f5 50%, #e9e9e9 50%, #e9e9e9 75%, #f5f5f5 75%, #f5f5f5);
    border-radius: 100% !important;
    width: 80px;
    height: 80px;
    line-height: 1;
    font-size: 14px;
  }
  .edit-btns-group-main .btn-fixed:nth-child(1) {
    bottom: 2%;
  }
  .edit-btns-group-main .btn-fixed:nth-child(2) {
    bottom: 24%;
  }
  .edit-btns-group-main .btn-fixed:nth-child(3) {
    bottom: 52%;
  }
  .modal-edit-btns-group-main {
    width: 100% !important;
  }
  .modal-edit-btns-group-main .btn-fixed {
    padding: 0 !important;
    font-size: 16.8px !important;
    width: 35px !important;
    height: 35px !important;
    line-height: 2;
  }
  .modal-edit-btns-group-main .btn-success {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
  .edit-btns-group-sub .btn-fixed {
    line-height: 2 !important;
    font-size: 14px !important;
    padding: 0 !important;
  }
  input[type='checkbox'],
  input[type='radio'] {
    width: 40px;
    height: 40px;
  }
  .contract-costs-table .form-control,
  .contract-quality-table .form-control {
    height: auto !important;
  }
  .btn-no-data {
    border-radius: 4px !important;
  }
  .badge,
  .label {
    padding: 4px !important;
    border-radius: 2px !important;
  }
  .logo {
    height: 125px;
    margin-left: 15px;
  }
  .navbar-user .logo {
    width: 80px;
    height: 70px;
  }
  .navbar-user .user-dropdown .navbar-user-dark {
    display: none !important;
  }
  .month-selector h4 {
    font-size: 14px !important;
  }
  .logistics-page .my-plans-checkbox input {
    width: auto !important;
  }
  .logistics-page .my-plans-checkbox label {
    margin-left: 0 !important;
  }
  .planning-table .label {
    width: 100% !important;
  }
  .page-header-main h1 {
    margin-top: 20px !important;
    font-size: 21px !important;
  }
  .main-table td {
    font-size: 14px !important;
    vertical-align: middle !important;
  }
  .main-table td:first-child .btn-link,
  .main-table td .btn-link {
    font-size: 14px !important;
    border-radius: 0 !important;
    margin: 0 !important;
    margin-bottom: 3px !important;
  }
  .clients-table tr td {
    font-size: 14px !important;
    vertical-align: middle !important;
  }
  .clients-table tr td .label-opacity {
    font-size: 14px !important;
  }
  .no_content_text {
    font-size: 14px !important;
  }
  .counterparties-dropdown .btn {
    font-size: 16.8px !important;
  }
  .ui-notification .message {
    font-size: 14px !important;
  }
  .thead-filters .filter-template {
    overflow: visible !important;
  }
  .offers-table tr td .form-control {
    height: 50px;
  }
  .contract-costs-table .form-control,
  .contract-quality-table .form-control,
  .contract-charges-table .form-control {
    width: auto !important;
  }
  .contract-costs-table tr td,
  .contract-quality-table tr td,
  .contract-charges-table tr td {
    font-size: 14px !important;
    width: auto !important;
  }
  .contract-costs-table tr td .label,
  .contract-quality-table tr td .label,
  .contract-charges-table tr td .label {
    display: inline-block !important;
  }
  .contract-costs-table tr th,
  .contract-quality-table tr th,
  .contract-charges-table tr th {
    font-size: 14px !important;
    width: auto !important;
  }
  .contract-costs-table .input-group-addon,
  .contract-quality-table .input-group-addon,
  .contract-charges-table .input-group-addon {
    font-size: 14px !important;
  }
  .costs-form-container input[type='checkbox'] {
    width: 100% !important;
  }
  .passport-costs-details input[type='checkbox'],
  .passport-costs-details input[type='radio'] {
    height: 80px !important;
    width: 80px !important;
  }
  .ui-select-bootstrap .ui-select-choices-row > span {
    padding: 6px !important;
    font-size: 14px !important;
  }
  #purchase-properties-table .label {
    font-size: 14px !important;
  }
  .cut-table td:first-child .btn-link {
    font-size: 14px !important;
    border-radius: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 3px !important;
  }
  .logistics-table tr td:nth-child(1) {
    font-size: 14px !important;
  }
  .table-template,
  .table-details,
  .table-responsive,
  .table-with-total,
  .cut-contract-table,
  .cut-table,
  #report-farms-table,
  .consolidated-contracts-table,
  .competitors-table {
    max-width: none;
    font-size: 14px !important;
    overflow-x: auto !important;
  }
  .table-template .label,
  .table-details .label,
  .table-responsive .label,
  .table-with-total .label,
  .cut-contract-table .label,
  .cut-table .label,
  #report-farms-table .label,
  .consolidated-contracts-table .label,
  .competitors-table .label,
  .table-template .badge,
  .table-details .badge,
  .table-responsive .badge,
  .table-with-total .badge,
  .cut-contract-table .badge,
  .cut-table .badge,
  #report-farms-table .badge,
  .consolidated-contracts-table .badge,
  .competitors-table .badge {
    max-width: 300px !important;
    padding: 6px !important;
    display: block !important;
  }
  .table-template td,
  .table-details td,
  .table-responsive td,
  .table-with-total td,
  .cut-contract-table td,
  .cut-table td,
  #report-farms-table td,
  .consolidated-contracts-table td,
  .competitors-table td {
    white-space: nowrap;
    word-break: normal;
    font-size: 14px !important;
    padding: 4px !important;
  }
  .table-template td .label,
  .table-details td .label,
  .table-responsive td .label,
  .table-with-total td .label,
  .cut-contract-table td .label,
  .cut-table td .label,
  #report-farms-table td .label,
  .consolidated-contracts-table td .label,
  .competitors-table td .label {
    margin-right: 2px;
    margin-left: 2px;
    padding: 4px !important;
    display: block !important;
  }
  .table-template th,
  .table-details th,
  .table-responsive th,
  .table-with-total th,
  .cut-contract-table th,
  .cut-table th,
  #report-farms-table th,
  .consolidated-contracts-table th,
  .competitors-table th {
    white-space: nowrap;
    font-size: 14px !important;
  }
  .analytics-container .table-responsive td {
    font-size: 14px !important;
  }
  .request-table tr td .label,
  .request-table tr td .badge {
    margin-right: 2px;
    margin-left: 2px;
    padding: 6px !important;
    display: block !important;
  }
  .indicators-table tr td .fa {
    font-size: 14px !important;
  }
  .persons-table tr td {
    font-size: 14px !important;
  }
  .persons-table tr td .label {
    font-size: 14px !important;
  }
  .competitors-table tr td {
    font-size: 14px !important;
  }
  .competitors-table tr td .form-control {
    height: 44px !important;
  }
  .competitors-table tr td .label {
    font-size: 14px !important;
  }
  .analytics-container .unscrollable-grid {
    width: 100% !important;
  }
  .analytics-container .unscrollable-grid .ui-grid-cell,
  .analytics-container .grid .ui-grid-cell {
    font-size: 14px !important;
  }
  .analytics-container .unscrollable-grid .ui-grid-cell .btn-link,
  .analytics-container .grid .ui-grid-cell .btn-link {
    font-size: 14px !important;
  }
  .contract-price-widget-table tr td {
    font-size: 14px !important;
  }
  .contract-price-widget-table .form-control,
  .contract-price-widget-table .ui-select-match {
    font-size: 14px !important;
  }
  .contract-price-widget-table .clear-btn {
    display: none !important;
  }
  .form-group-container .form-group .contract-price-widget-table .form-control {
    font-size: 14px !important;
  }
  .form-group-container .form-group .contract-price-widget-table .ui-select-toggle {
    font-size: 14px !important;
    line-height: 1 !important;
    padding-top: 0 !important;
  }
  .contract-stages-table tr td {
    width: 170px !important;
  }
  .contract-stages-table tr td .smaller-label {
    white-space: nowrap;
  }
  .contract-status-block-td .contract-status-block {
    font-size: 14px !important;
    white-space: nowrap;
  }
  .contract-stages-table tr td:nth-child(1) span.smaller-label {
    font-size: 14px !important;
  }
  .item-details-block-transparent .item-details-el .value {
    font-size: 14px !important;
  }
  .item-details-block-transparent .item-details-el .property {
    font-size: 14px / 1.1 !important;
  }
  .position-crop-table tr td .label {
    font-size: 14px !important;
  }
  .position-crop-table tr td .badge {
    font-size: 14px !important;
  }
  .position-crop-table tr td {
    font-size: 14px !important;
  }
  .passport-position-crop-table tr td {
    font-size: 14px !important;
  }
  .passport-position-crop-table tr td .btn-link,
  .passport-position-crop-table tr td .label,
  .passport-position-crop-table tr td span {
    font-size: 14px !important;
  }
  .position-crop-table tr th {
    font-size: 14px !important;
  }
  .position-crop-table .details-btn {
    margin-right: 10px;
  }
  .position-crop-table .details-btn .fa {
    font-size: 14px !important;
  }
  .column-config-table tr td {
    font-size: 14px !important;
  }
  .column-config-table tr td .form-control {
    font-size: 14px !important;
  }
  .column-config-table tr td .btn {
    font-size: 14px !important;
  }
  .column-config-table tr th {
    font-size: 14px !important;
    white-space: normal !important;
    word-wrap: break-word !important;
  }
  .column-config-table tr th div {
    font-size: 14px !important;
    white-space: normal !important;
    word-wrap: break-word !important;
  }
  .position-passport-table tr td {
    font-size: 14px !important;
  }
  .position-passport-table tr td .btn-link,
  .position-passport-table tr td .label,
  .position-passport-table tr td span {
    font-size: 14px !important;
  }
  .position-passport-table tr td .bigger-label {
    font-size: 14px !important;
  }
  .position-passport-table tr td .smaller-label {
    font-size: 14px !important;
  }
  #pl-cash-flow-table tr td {
    font-size: 14px !important;
  }
  #pl-cash-flow-table tr td .btn-link,
  #pl-cash-flow-table tr td .label,
  #pl-cash-flow-table tr td span {
    font-size: 14px !important;
  }
  #pl-cash-flow-table tr td:nth-child(1) {
    overflow-x: hidden !important;
  }
  .payments_table .bigger-label.negative-number,
  .payments_table .bigger-label.positive-number {
    font-size: 14px !important;
  }
  .modal-template .modal-dialog {
    width: 100% !important;
  }
  .modal-template .modal-dialog .ui-select-bootstrap > .ui-select-choices,
  .modal-template .modal-dialog .ui-select-bootstrap > .ui-select-no-choice {
    max-height: fit-content;
  }
  .modal-body {
    padding: 0;
  }
  .datetimepicker .table thead tr th {
    font-size: 14px !important;
  }
  .datetimepicker .table thead tr th.right,
  .datetimepicker .table thead tr th.left,
  .datetimepicker .table thead tr th.switch {
    font-size: 14px !important;
  }
  .datetimepicker .table tbody tr td {
    font-size: 14px !important;
  }
  .contract-details-container-cut .label-details {
    font-size: 14px !important;
  }
  .contract-details-container-cut .contract-description-label {
    font-size: 14px !important;
  }
  .contracts-page .contract-description-label,
  .vessels-page .contract-description-label,
  .logistics-page .contract-description-label,
  .request-page .contract-description-label,
  .create-contract-page .contract-description-label,
  .farm-page .contract-description-label,
  .elevator-page .contract-description-label,
  .payments-page .contract-description-label,
  .documents-page .contract-description-label,
  .pl-page .contract-description-label {
    font-size: 14px !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .payment-details-table tr td {
    white-space: nowrap !important;
  }
  .payment-details-table td:last-child {
    white-space: nowrap !important;
  }
  .left-sidebar .cut-contract-table .label,
  .left-sidebar .cut-contract-table .badge {
    max-width: 800px !important;
    padding: 10px;
  }
  .crops-table-container {
    overflow-x: auto;
    margin-right: 15px;
    margin-left: 15px;
  }
  .client-name-header-dark .label {
    font-size: 14px !important;
  }
  .help-btn-group {
    top: 0;
    right: 0;
    display: block;
    position: relative;
  }
  .help-btn-group a {
    padding: 5px !important;
    height: 40px;
    width: 40px;
    font-size: 14px !important;
  }
  .help-btn-group .fa {
    font-size: 14px !important;
  }
  .header-filters .btn {
    font-size: 1.1em !important;
  }
  .select-date span {
    font-size: 14px;
  }
  .daterangepicker select {
    font-size: 14px !important;
  }
  .daterangepicker .ranges {
    font-size: 14px !important;
  }
  .daterangepicker .ranges li {
    font-size: 14px !important;
  }
  .daterangepicker .btn {
    font-size: 14px !important;
  }
  .daterangepicker .calendar {
    max-width: 800px !important;
  }
  .daterangepicker .ranges ul {
    width: 500px;
  }
  .datarangepicker .form-control {
    font-size: 14px !important;
  }
  table tr th .thead-filters .form-control {
    font-size: 14px !important;
    height: 60px !important;
  }
  .main-table th .input-group-addon {
    font-size: 14px !important;
  }
  .main-table th .filter-template .ui-select-match .form-control,
  .main-table th .filter-template .ui-select-container .form-control,
  .main-table th .form-control {
    font-size: 14px !important;
  }
  .alert-filter {
    top: 65px;
    max-width: 400px;
  }
  .modal-dialog {
    width: 100% !important;
  }
  .right-sidebar .table-template tr,
  .right-sidebar .table-template td {
    width: 100%;
  }
  .filter-template {
    width: 100%;
    display: block;
    border-radius: 4px !important;
    overflow-x: auto;
  }
  .all-filters-block .filter-template {
    display: table;
  }
  .modal-header .col-xs-8.center-block {
    width: 100% !important;
    margin-top: 20px;
  }
  .modal-header .label {
    display: none;
  }
  .modal-title .label {
    display: none;
  }
  .modal-content .filter-template {
    overflow-x: auto;
  }
  .modal-content .updated_time {
    display: none;
  }
  .modal-form-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .modal-form-container input[type='checkbox'],
  .modal-form-container input[type='radio'] {
    width: 50px !important;
    height: 50px !important;
  }
  .modal-form-container .ui-select-choices-row {
    font-size: 14px;
  }
  .modal-form-container .input-group-addon-search {
    display: none !important;
  }
  .modal-form-container .input-group {
    width: 100% !important;
  }
  .modal-form-container .form-add-btn {
    margin-top: 0 !important;
  }
  .modal-form-container .form-add-btn a {
    line-height: 1;
    font-size: 14px !important;
    height: 100% !important;
    margin-top: 0 !important;
    padding: 0 !important;
    padding-top: 10px !important;
  }
  .modal-form-container .form-add-btn-fullwidth a {
    line-height: 1;
    font-size: calc(14px / 1.3) !important;
  }
  .modal-form-container .form-group .input-group-addon {
    display: none !important;
  }
  .modal-form-container .ul-inline-row {
    overflow: visible !important;
  }
  .client-modal-form-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .client-modal-form-container input[type='checkbox'],
  .client-modal-form-container input[type='radio'] {
    width: 50px !important;
    height: 50px !important;
  }
  .client-modal-form-container .ui-select-choices-row {
    font-size: 14px;
  }
  .client-modal-form-container .input-group-addon-search {
    display: none !important;
  }
  .client-modal-form-container .input-group {
    width: 100% !important;
  }
  .client-modal-form-container .form-add-btn {
    margin-top: 0 !important;
  }
  .client-modal-form-container .form-add-btn a {
    line-height: 1;
    font-size: 14px !important;
    height: 100% !important;
    margin-top: 0 !important;
    padding: 0 !important;
    padding-top: 10px !important;
  }
  .client-modal-form-container .form-add-btn-fullwidth a {
    line-height: 1;
    font-size: calc(14px / 1.3) !important;
  }
  .client-modal-form-container .form-group .input-group-addon {
    display: none !important;
  }
  .client-modal-form-container .ul-inline-row {
    overflow: visible !important;
  }
  .client-modal-form-container .ul-inline-row {
    overflow-x: visible !important;
  }
  .client-modal-form-container .clear-btn {
    font-size: 16.8px !important;
  }
  .role-modal-form-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .role-modal-form-container input[type='checkbox'],
  .role-modal-form-container input[type='radio'] {
    width: 50px !important;
    height: 50px !important;
  }
  .role-modal-form-container .ui-select-choices-row {
    font-size: 14px;
  }
  .role-modal-form-container .input-group-addon-search {
    display: none !important;
  }
  .role-modal-form-container .input-group {
    width: 100% !important;
  }
  .role-modal-form-container .form-add-btn {
    margin-top: 0 !important;
  }
  .role-modal-form-container .form-add-btn a {
    line-height: 1;
    font-size: 14px !important;
    height: 100% !important;
    margin-top: 0 !important;
    padding: 0 !important;
    padding-top: 10px !important;
  }
  .role-modal-form-container .form-add-btn-fullwidth a {
    line-height: 1;
    font-size: calc(14px / 1.3) !important;
  }
  .role-modal-form-container .form-group .input-group-addon {
    display: none !important;
  }
  .role-modal-form-container .ul-inline-row {
    overflow: visible !important;
  }
  .role-modal-form-container .ul-inline-row {
    overflow-x: visible !important;
  }
  .role-modal-form-container .clear-btn {
    font-size: 16.8px !important;
  }
  .finance-modal-form-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .finance-modal-form-container input[type='checkbox'],
  .finance-modal-form-container input[type='radio'] {
    width: 50px !important;
    height: 50px !important;
  }
  .finance-modal-form-container .ui-select-choices-row {
    font-size: 14px;
  }
  .finance-modal-form-container .input-group-addon-search {
    display: none !important;
  }
  .finance-modal-form-container .input-group {
    width: 100% !important;
  }
  .finance-modal-form-container .form-add-btn {
    margin-top: 0 !important;
  }
  .finance-modal-form-container .form-add-btn a {
    line-height: 1;
    font-size: 14px !important;
    height: 100% !important;
    margin-top: 0 !important;
    padding: 0 !important;
    padding-top: 10px !important;
  }
  .finance-modal-form-container .form-add-btn-fullwidth a {
    line-height: 1;
    font-size: calc(14px / 1.3) !important;
  }
  .finance-modal-form-container .form-group .input-group-addon {
    display: none !important;
  }
  .finance-modal-form-container .ul-inline-row {
    overflow: visible !important;
  }
  .finance-modal-form-container .ul-inline-row {
    overflow-x: visible !important;
  }
  .finance-modal-form-container .clear-btn {
    font-size: 16.8px !important;
  }
  .contract-modal-form-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .contract-modal-form-container input[type='checkbox'],
  .contract-modal-form-container input[type='radio'] {
    width: 50px !important;
    height: 50px !important;
  }
  .contract-modal-form-container .ui-select-choices-row {
    font-size: 14px;
  }
  .contract-modal-form-container .input-group-addon-search {
    display: none !important;
  }
  .contract-modal-form-container .input-group {
    width: 100% !important;
  }
  .contract-modal-form-container .form-add-btn {
    margin-top: 0 !important;
  }
  .contract-modal-form-container .form-add-btn a {
    line-height: 1;
    font-size: 14px !important;
    height: 100% !important;
    margin-top: 0 !important;
    padding: 0 !important;
    padding-top: 10px !important;
  }
  .contract-modal-form-container .form-add-btn-fullwidth a {
    line-height: 1;
    font-size: calc(14px / 1.3) !important;
  }
  .contract-modal-form-container .form-group .input-group-addon {
    display: none !important;
  }
  .contract-modal-form-container .ul-inline-row {
    overflow: visible !important;
  }
  .contract-modal-form-container .ul-inline-row {
    overflow-x: visible !important;
  }
  .contract-modal-form-container .clear-btn {
    font-size: 16.8px !important;
  }
  .client-modal-form-container input[type='checkbox'],
  .client-modal-form-container input[type='radio'] {
    width: 50px;
    height: 50px;
  }
  .passport-modal-form-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .passport-modal-form-container input[type='checkbox'],
  .passport-modal-form-container input[type='radio'] {
    width: 50px !important;
    height: 50px !important;
  }
  .passport-modal-form-container .ui-select-choices-row {
    font-size: 14px;
  }
  .passport-modal-form-container .input-group-addon-search {
    display: none !important;
  }
  .passport-modal-form-container .input-group {
    width: 100% !important;
  }
  .passport-modal-form-container .form-add-btn {
    margin-top: 0 !important;
  }
  .passport-modal-form-container .form-add-btn a {
    line-height: 1;
    font-size: 14px !important;
    height: 100% !important;
    margin-top: 0 !important;
    padding: 0 !important;
    padding-top: 10px !important;
  }
  .passport-modal-form-container .form-add-btn-fullwidth a {
    line-height: 1;
    font-size: calc(14px / 1.3) !important;
  }
  .passport-modal-form-container .form-group .input-group-addon {
    display: none !important;
  }
  .passport-modal-form-container .ul-inline-row {
    overflow: visible !important;
  }
  .passport-modal-form-container .ul-inline-row {
    overflow-x: visible !important;
  }
  .passport-modal-form-container .clear-btn {
    font-size: 16.8px !important;
  }
  .request-modal-form-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .request-modal-form-container input[type='checkbox'],
  .request-modal-form-container input[type='radio'] {
    width: 50px !important;
    height: 50px !important;
  }
  .request-modal-form-container .ui-select-choices-row {
    font-size: 14px;
  }
  .request-modal-form-container .input-group-addon-search {
    display: none !important;
  }
  .request-modal-form-container .input-group {
    width: 100% !important;
  }
  .request-modal-form-container .form-add-btn {
    margin-top: 0 !important;
  }
  .request-modal-form-container .form-add-btn a {
    line-height: 1;
    font-size: 14px !important;
    height: 100% !important;
    margin-top: 0 !important;
    padding: 0 !important;
    padding-top: 10px !important;
  }
  .request-modal-form-container .form-add-btn-fullwidth a {
    line-height: 1;
    font-size: calc(14px / 1.3) !important;
  }
  .request-modal-form-container .form-group .input-group-addon {
    display: none !important;
  }
  .request-modal-form-container .ul-inline-row {
    overflow: visible !important;
  }
  .request-modal-form-container .ul-inline-row {
    overflow-x: visible !important;
  }
  .request-modal-form-container .clear-btn {
    font-size: 16.8px !important;
  }
  .contract-modal-form-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .contract-modal-form-container input[type='checkbox'],
  .contract-modal-form-container input[type='radio'] {
    width: 50px !important;
    height: 50px !important;
  }
  .contract-modal-form-container .ui-select-choices-row {
    font-size: 14px;
  }
  .contract-modal-form-container .input-group-addon-search {
    display: none !important;
  }
  .contract-modal-form-container .input-group {
    width: 100% !important;
  }
  .contract-modal-form-container .form-add-btn {
    margin-top: 0 !important;
  }
  .contract-modal-form-container .form-add-btn a {
    line-height: 1;
    font-size: 14px !important;
    height: 100% !important;
    margin-top: 0 !important;
    padding: 0 !important;
    padding-top: 10px !important;
  }
  .contract-modal-form-container .form-add-btn-fullwidth a {
    line-height: 1;
    font-size: calc(14px / 1.3) !important;
  }
  .contract-modal-form-container .form-group .input-group-addon {
    display: none !important;
  }
  .contract-modal-form-container .ul-inline-row {
    overflow: visible !important;
  }
  .contract-modal-form-container .ul-inline-row {
    overflow-x: visible !important;
  }
  .contract-modal-form-container .clear-btn {
    font-size: 16.8px !important;
  }
  .contract-modal-form-container input[type='date'] {
    max-width: 100%;
    width: 100% !important;
  }
  .ui-select-container .btn {
    font-size: 14px !important;
  }
  .request-modal-form-container .form-add-btn a {
    font-size: 14px !important;
    height: auto;
  }
  .request-modal-form-container .form-dismiss-select {
    right: 125px;
    top: 18px;
  }
  .request-modal-form-container .form-add-btn-fullwidth a {
    font-size: calc(14px / 1.2) !important;
    line-height: 1;
    height: auto;
  }
  .request-modal-form-container .ul-inline-row {
    padding: 15px !important;
  }
  .form-group-container.main-block-wide .modal-form-block {
    width: 100% !important;
  }
  .filter-template .btn {
    padding: 3px 6px !important;
    font-size: 14px !important;
  }
  .filter-template .btn .label_start {
    display: none;
  }
  .sticky-table-first-td,
  .sticky-table-first-td-condensed,
  .sticky-table-first-td-ultra-condensed,
  .sticky-table-first-td-expanded,
  .sticky-table-first-td-expanded-position,
  table.sticky-table-first-td,
  table.sticky-table-first-td-condensed,
  table.sticky-table-first-td-ultra-condensed,
  table.sticky-table-first-td-expanded,
  table.sticky-table-first-td-expanded-position {
    margin-left: 0 !important;
  }
  .sticky-table-first-td tfoot > tr > td:first-child,
  .sticky-table-first-td-condensed tfoot > tr > td:first-child,
  .sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child,
  .sticky-table-first-td-expanded tfoot > tr > td:first-child,
  .sticky-table-first-td-expanded-position tfoot > tr > td:first-child,
  table.sticky-table-first-td tfoot > tr > td:first-child,
  table.sticky-table-first-td-condensed tfoot > tr > td:first-child,
  table.sticky-table-first-td-ultra-condensed tfoot > tr > td:first-child,
  table.sticky-table-first-td-expanded tfoot > tr > td:first-child,
  table.sticky-table-first-td-expanded-position tfoot > tr > td:first-child,
  .sticky-table-first-td tbody > tr > td:first-child,
  .sticky-table-first-td-condensed tbody > tr > td:first-child,
  .sticky-table-first-td-ultra-condensed tbody > tr > td:first-child,
  .sticky-table-first-td-expanded tbody > tr > td:first-child,
  .sticky-table-first-td-expanded-position tbody > tr > td:first-child,
  table.sticky-table-first-td tbody > tr > td:first-child,
  table.sticky-table-first-td-condensed tbody > tr > td:first-child,
  table.sticky-table-first-td-ultra-condensed tbody > tr > td:first-child,
  table.sticky-table-first-td-expanded tbody > tr > td:first-child,
  table.sticky-table-first-td-expanded-position tbody > tr > td:first-child,
  .sticky-table-first-td thead > tr > th:first-child,
  .sticky-table-first-td-condensed thead > tr > th:first-child,
  .sticky-table-first-td-ultra-condensed thead > tr > th:first-child,
  .sticky-table-first-td-expanded thead > tr > th:first-child,
  .sticky-table-first-td-expanded-position thead > tr > th:first-child,
  table.sticky-table-first-td thead > tr > th:first-child,
  table.sticky-table-first-td-condensed thead > tr > th:first-child,
  table.sticky-table-first-td-ultra-condensed thead > tr > th:first-child,
  table.sticky-table-first-td-expanded thead > tr > th:first-child,
  table.sticky-table-first-td-expanded-position thead > tr > th:first-child {
    position: relative !important;
    left: 0 !important;
    width: auto !important;
    vertical-align: middle !important;
    height: auto !important;
    max-height: auto !important;
    min-height: auto !important;
  }
  .payment-partial-table .form-control {
    height: auto;
    font-size: 14px !important;
  }
  .payment-partial-table .form-control span {
    height: auto;
    font-size: 14px !important;
  }
  .payment-partial-table tr td .fa,
  .payment-partial-table tr td .btn {
    font-size: 14px !important;
  }
  .commentaries-table tr td.edit,
  .commentaries-table tr td {
    font-size: 14px !important;
  }
  .form-group-container select[multiple],
  .form-group-container select[size] {
    height: 300px !important;
  }
  .task-modal-form-container .ul-inline-row {
    overflow-x: visible !important;
  }
  .modal-form-container .ul-inline-row {
    overflow-x: visible !important;
  }
  .ui-select-bootstrap > .ui-select-choices,
  .ui-select-bootstrap > .ui-select-no-choice {
    max-height: 700px;
  }
  .vertical-filter-template .btn {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
  }
  .prices-section-filters .btn {
    font-size: 14px !important;
  }
  .contract-details-container small .contract_details {
    padding: 8px;
  }
  .updated_time {
    font-size: 14px !important;
  }
  .no_content_text .fa.fa-exclamation-circle {
    margin-right: 10px;
  }
  .left-sidebar .contract-details-container-cut .btn-link {
    font-size: 14px !important;
  }
  .left-sidebar .cut-contract-table .label,
  .left-sidebar .cut-contract-table .badge {
    font-size: 14px !important;
  }
  .contract-details-container-cut small .contract_details {
    font-size: 14px !important;
  }
  .contract-details-container-cut .btn-link {
    font-size: 14px !important;
  }
  .contract_details {
    font-size: calc(14px / 1.3) !important;
  }
  #cash-flow-table tr td {
    font-size: 14px !important;
  }
  .contract-page .table-template tr td,
  .contract-page .cut-table tr td,
  .contract-page .table-with-total tr td {
    font-size: 14px !important;
  }
  .form-horizontal .form-group {
    margin-left: 1%;
    margin-right: 1%;
  }
  .form-group {
    font-size: 16.8px !important;
    margin-bottom: 5px !important;
  }
  .form-control {
    font-size: 14px !important;
    padding: 12px;
    border-radius: 0px !important;
    height: auto;
    margin: 0 !important;
  }
  .form-control option {
    font-size: 14px !important;
    color: #666 !important;
  }
  .form-control::-webkit-input-placeholder {
    font-size: 14px !important;
  }
  .form-control::-moz-placeholder {
    font-size: 14px !important;
  }
  .form-control:-moz-placeholder {
    font-size: 14px !important;
  }
  .form-control:-ms-input-placeholder {
    font-size: 14px !important;
  }
  .input-group-addon {
    font-size: 14px;
  }
  .contract-description-label {
    font-size: 14px;
  }
  .header-badge {
    font-size: 14px !important;
  }
  .my-plans-checkbox input {
    width: 70px !important;
    height: 70px !important;
  }
  .link-to-passport {
    font-size: 14px;
  }
  .form-group-container .form-group.col-xs-12 {
    margin-bottom: 20px !important;
  }
  .form-group-container .form-group .form-control {
    height: 40px;
    font-size: 16.8px !important;
  }
  .form-group-container .text-muted {
    font-size: 16.8px !important;
  }
  .modal-form-container .clear-btn {
    font-size: 16.8px !important;
  }
  .form-group-container .form-group .control-label {
    font-size: 14px !important;
    margin-bottom: 10px !important;
  }
  .btn-group-justified {
    overflow-x: auto;
    width: 100%;
    display: block;
  }
  .nav-tabs-cut .thead-filter-btn {
    display: none !important;
  }
  .ui-select-bootstrap .ui-select-match-text {
    margin-top: 10px !important;
  }
  .modal-form-block .control-label {
    border-right: 0 !important;
  }
  h4 small {
    display: none;
  }
  .form-group-container.main-block-wide {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .form-group-container.main-block-wide .modal-form-block .formly-field {
    display: block;
    width: 100% !important;
  }
  .form-group-container.main-block-wide .modal-form-block .control-label {
    font-size: 14px !important;
  }
  .form-group-container.main-block-wide .select-with-btn {
    width: 83% !important;
  }
  .form-group-container.main-block-wide .form-add-btn {
    width: 15% !important;
  }
  .form-group-container.main-block-wide .form-add-btn .add-btn,
  .form-group-container.main-block-wide .form-add-btn a {
    padding-top: 20px;
    font-size: 14px !important;
  }
  .form-group-container.main-block-wide .form-group.col-xs-12 {
    margin-bottom: 0 !important;
  }
  .form-group-container.main-block-wide .form-group .form-control {
    font-size: 16.8px !important;
    height: 50px;
    padding: 4px !important;
  }
  .form-group-container.main-block-wide .form-group .form-control .add-btn {
    font-size: 14px !important;
  }
  .form-group-container.main-block-wide .form-group .form-control::-webkit-input-placeholder {
    font-size: 16.8px !important;
  }
  .form-group-container.main-block-wide .form-group .form-control::-moz-placeholder {
    font-size: 16.8px !important;
  }
  .form-group-container.main-block-wide .form-group .form-control:-moz-placeholder {
    font-size: 16.8px !important;
  }
  .form-group-container.main-block-wide .form-group .form-control:-ms-input-placeholder {
    font-size: 16.8px !important;
  }
  .form-group-container.main-block-wide .text-muted {
    font-size: 16.8px !important;
  }
  .form-group-container.main-block-wide .help-inline {
    top: 35px;
  }
  .form-group-container.main-block-wide .multiselect .help-inline {
    top: 285px;
  }
  .modal-content .modal-header .filter-template .btn .fa {
    font-size: 16.8px !important;
  }
  .form-group-container.main-block-wide .modal-form-block .ul-inline-row {
    padding: 0 !important;
  }
  .search-container {
    max-height: 100px;
    margin-bottom: 5px;
  }
  .search-container .search {
    padding: 10px;
    height: 100%;
    font-size: 14px !important;
    display: block;
  }
  .search-container .btn {
    font-size: 14px !important;
    padding: 20px !important;
  }
  .form-control.search {
    border-radius: 0px !important;
    width: 100% !important;
  }
  .ul-inline-row {
    width: 100% !important;
    overflow-x: auto !important;
  }
  .crops-edit-table {
    table-layout: auto !important;
  }
  .crops-edit-table .form-control {
    width: auto !important;
    padding: 17px;
    height: 70px;
  }
  .crops-edit-table h5 {
    font-size: 14px;
  }
  .datetimepicker .table thead tr th:nth-child(1),
  .datetimepicker .table thead tr th:nth-child(2),
  .datetimepicker .table thead tr th:nth-child(3),
  .datetimepicker .table tbody tr td:nth-child(1),
  .datetimepicker .table tbody tr td:nth-child(2),
  .datetimepicker .table tbody tr td:nth-child(3) {
    width: 40px !important;
    min-width: 40px !important;
    height: 40px !important;
  }
  .datetimepicker .table tbody tr td {
    width: 40px !important;
    min-width: 40px !important;
    height: 40px !important;
  }
  .modal-form-block {
    margin-bottom: 0 !important;
  }
  .form-group-container .form-group.col-xs-12 {
    margin-bottom: 20px !important;
  }
  .modal-form-container .modal-form-block .ul-inline-row {
    padding: 10px !important;
  }
  .container-btn-group .btn {
    padding: 16px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .form-group-container {
    padding: 0 !important;
  }
  .formly-field.formly-field-group {
    line-height: 1 !important;
  }
  .modal-content .modal-header .filter-template .btn {
    font-size: 14px !important;
  }
  .central-home-purchase .ui-select-match,
  .central-home-purchase .ui-select-container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .payment-plans-list {
    font-size: 14px !important;
    text-align: left;
    border: 1px dashed #b0a9a9 !important;
    padding: 25px !important;
    margin-bottom: 15px !important;
    border-radius: 6px;
  }
  .payment-plans-list .pull-left {
    display: block;
    text-align: left;
    float: none !important;
  }
  .payment-plans-list .pull-right {
    display: block;
    text-align: left;
    float: none !important;
  }
  .tariffs-table {
    table-layout: auto !important;
  }
  .tariffs-table tr td {
    padding: 6px !important;
  }
  .tariffs-table .form-add-btn a {
    height: 48px !important;
    line-height: 1;
    padding: 0 !important;
    padding-top: 15px !important;
    padding-left: 2px;
    padding-right: 2px;
  }
  .tariffs-table .select-with-btn {
    margin-bottom: 5px;
  }
  .tariffs-table .form-control {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  input {
    border-radius: 0 !important;
  }
  .control-label {
    font-size: calc(14px / 1.2) !important;
    margin: 0 !important;
  }
  .help-inline {
    font-size: 14px !important;
  }
  select[multiple],
  select[size] {
    border-radius: 4px !important;
  }
  select[multiple] option,
  select[size] option {
    font-size: 14px !important;
    padding: 12px;
  }
  .bootstrap-select,
  select {
    font-size: 14px !important;
  }
  .bootstrap-select option,
  select option {
    font-size: 14px !important;
  }
  .page-header .dropdown-toggle span {
    font-size: 21px !important;
  }
  .page-header .dropdown-toggle .label-info {
    display: none !important;
  }
  .page-header-main h1 .user-dropdown-btn {
    white-space: normal;
    font-size: 21px !important;
  }
  .page-header-main h1 .user-dropdown-btn span.initial-transform {
    font-size: 28px !important;
    margin-bottom: 15px !important;
  }
  .border-left-thin {
    border-left: 0 !important;
  }
  .alert-danger-dashed .border-left,
  .ul-inline-row.alert-danger-dashed .border-left,
  .table-container-wrapper.alert-danger-dashed .border-left {
    border-top: 6px solid rgba(207, 97, 99, 0.7) !important;
    border-left: 0 !important;
    overflow: hidden;
  }
  .passport-block-mode .alert-danger-dashed {
    height: 500px !important;
  }
  .process-container-well {
    display: none !important;
  }
  .updates-process-container .process-container-well {
    display: block !important;
  }
  .updates-process-container .process-container-well .item-process-container h5 {
    text-align: left;
  }
  .details-wrapper .process-container-well .item-process-container h5 {
    text-align: left;
    margin-bottom: 10px;
    border-bottom: 1px solid #d2cece;
    padding-bottom: 5px;
  }
  .help-inline-hint,
  .tooltip-modal {
    display: none !important;
  }
  td .dropdown-toggle {
    font-size: 14px !important;
  }
  .bootstrap-select.btn-group .dropdown-menu li {
    font-size: 14px !important;
  }
  .settings-page label {
    font-size: 14px;
  }
  .settings-page .btn {
    font-size: 14px;
  }
  .consolidated-page .filter-template .btn {
    border-radius: 2px !important;
    display: inline-block;
    width: auto;
    text-align: center;
    padding: 8px;
    padding-left: 10px;
    border-top: 1px solid #e8e6e6 !important;
  }
  .contracts-page input,
  .vessels-page input,
  .logistics-page input,
  .request-page input,
  .farm-page input,
  .elevator-page input {
    width: 100% !important;
  }
  .search {
    font-size: 14px !important;
  }
  small {
    font-size: 14px !important;
    line-height: 1.2;
    display: block;
  }
  small span,
  small div,
  small p,
  small .label {
    line-height: 1;
  }
  .btn {
    padding: 20px;
  }
  td .btn {
    font-size: 14px !important;
    padding: 4px !important;
  }
  .page-header-main .main-nav-tabs li > a {
    padding: 20px !important;
  }
  .nav-tabs a {
    max-width: 100px;
    overflow-x: hidden;
  }
  .nav-tabs a span {
    display: none;
  }
  .nav-tabs-cut li.active > a span {
    display: none;
  }
  .request-table tr td .fa,
  .clients-table tr td .fa {
    font-size: 14px !important;
  }
  .request-table tr td .fa-pencil-square,
  .clients-table tr td .fa-pencil-square,
  .request-table tr td .fa-trash,
  .clients-table tr td .fa-trash {
    font-size: 14px !important;
  }
  .request-table td.small-font-cell {
    font-size: 14px !important;
  }
  .request-table td.small-font-cell a,
  .request-table td.small-font-cell span,
  .request-table td.small-font-cell .btn-link {
    font-size: 14px !important;
  }
  .contract-details-table tr td,
  .contract-costs-table tr td,
  .contract-quality-table tr td,
  .contract-details-table td th,
  .contract-costs-table td th,
  .contract-quality-table td th {
    font-size: 14px !important;
  }
  .no-user-avatar,
  .user-avatar {
    font-size: 14px !important;
  }
  .tags-list li .label {
    padding: 6px;
    font-size: 14px !important;
    margin: 6px !important;
  }
  .payments_table .bigger-label {
    font-size: 14px !important;
  }
  .notify-dropdown-menu {
    min-width: 100% !important;
    width: 800px !important;
  }
  .all-filters-block .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    font-size: 14px !important;
  }
  .all-filters-block .input-group .form-control {
    height: 60px;
  }
  .all-filters-block .contract-description-label {
    font-size: 14px !important;
  }
  .table-container-wrapper::-webkit-scrollbar {
    height: 40px;
  }
  .overflow-x::-webkit-scrollbar {
    height: 40px;
  }
  .nav-tabs-cut li a span {
    display: none;
  }
  .nav-tabs-cut li a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sticky-table-first-td-ultra-condensed thead > tr > th:first-child div {
    padding: 0 !important;
  }
  .sticky-table-first-td thead > tr > th:first-child div,
  table.sticky-table-first-td thead > tr > th:first-child div {
    padding: 0 !important;
  }
  .table-nav-tabs {
    display: none;
  }
  .ui-select-choices-row {
    font-size: 14px !important;
  }
  .btn-sm {
    padding: 16px;
  }
  .btn-xs {
    padding: 10px;
  }
  .checkbox-inline.no_indent {
    font-size: 22px;
  }
  .checkbox-inline.no_indent .btn-link {
    font-size: 22px !important;
  }
  #Terms {
    padding: 5px;
    height: 50px;
    width: 50px;
    margin: 0;
  }
  #show-more-btn {
    clear: both !important;
    padding: 14px 40px;
    margin: 40px;
  }
  .docs-list .fa.fa-download {
    font-size: 14px !important;
  }
  .docs-list p {
    font-size: 14px;
  }
  .right-sidebar {
    height: auto !important;
    clear: both;
  }
  .right-sidebar select {
    height: auto;
  }
  .central-container {
    border: none;
  }
  .central-container label {
    font-size: 14px;
  }
  .header-margin-top.container {
    max-width: 100% !important;
  }
  .page-header-main .btn-success {
    display: none;
  }
  .page-header-main small {
    display: none;
  }
  .bigger-label {
    font-size: 14px !important;
  }
  .smaller-label {
    font-size: 14px !important;
  }
  .label-opacity {
    font-size: 14px !important;
  }
  .request-list-container .label {
    font-size: 14px !important;
  }
  .passports-wrapper-light-mode .request-list {
    min-width: 450px;
  }
  table tr th {
    font-size: 14px !important;
  }
  table tr th div {
    white-space: nowrap !important;
    font-size: calc(14px / 1.3) !important;
  }
  .clients-table tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .clients-table tr td .label {
    font-size: calc(14px / 1.2) !important;
  }
  .clients-table a.modal-btn {
    font-size: calc(14px / 1.2) !important;
  }
  .tariffs-table tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .tariffs-table tr td label,
  .tariffs-table tr td .label,
  .tariffs-table tr td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .tariffs-table .inside-table-ul li span {
    font-size: calc(14px / 1.2) !important;
  }
  .tariffs-table .form-control {
    min-width: 200px !important;
  }
  .tariffs-table .form-add-btn a {
    height: 72px !important;
    line-height: 2.5;
    display: none;
  }
  .tariffs-table .btn-success {
    font-size: calc(14px / 1.2) !important;
  }
  .tariffs-table .select-with-btn {
    overflow: visible;
    width: auto !important;
  }
  .pagination > li > a,
  .pagination > li > span {
    padding: 14px;
    font-size: 14px !important;
  }
  #log-properties-table tr td {
    font-size: calc(14px / 1.2) !important;
  }
  #log-properties-table tr td label,
  #log-properties-table tr td .label,
  #log-properties-table tr td .badge {
    font-size: calc(14px / 1.2) !important;
    display: block !important;
  }
  #log-properties-table .inside-table-ul li span {
    font-size: calc(14px / 1.2) !important;
  }
  .request-table tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .request-table tr td label,
  .request-table tr td .label,
  .request-table tr td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .request-table .inside-table-ul li span {
    font-size: calc(14px / 1.2) !important;
  }
  .email-template-table tr td {
    word-wrap: break-word !important;
    white-space: normal !important;
  }
  .email-template-table td input[type='checkbox'],
  .email-template-table td input[type='radio'] {
    width: 40px;
    height: 40px;
  }
  .request-table .item-settings-dropdown .fa-cog,
  .request-table .item-settings-dropdown .fa-align-left {
    font-size: calc(14px / 1.2) !important;
  }
  .places-table .inner-span {
    font-size: calc(14px / 1.2) !important;
  }
  .manager-table tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .manager-table tr td label,
  .manager-table tr td .label,
  .manager-table tr td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .manager-table .inside-table-ul li span {
    font-size: calc(14px / 1.2) !important;
  }
  .task-block tr td,
  .task-item-container tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .task-block tr td .btn-default,
  .task-item-container tr td .btn-default,
  .task-block tr td label,
  .task-item-container tr td label,
  .task-block tr td .label,
  .task-item-container tr td .label,
  .task-block tr td .badge,
  .task-item-container tr td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .task-block .inside-table-ul li span,
  .task-item-container .inside-table-ul li span {
    font-size: calc(14px / 1.2) !important;
  }
  .task-block .task-update-time,
  .task-item-container .task-update-time {
    font-size: calc(14px / 1.2) !important;
  }
  .task-block .smaller-label,
  .task-item-container .smaller-label {
    font-size: calc(14px / 1.2) !important;
  }
  .task-table {
    margin-bottom: 0 !important;
  }
  .crops-edit-table h5 .input-group .form-control {
    height: 70px !important;
  }
  .counterparty_detail_table tr td:first-child {
    font-size: calc(14px / 1.2) !important;
    width: auto;
  }
  .counterparty_detail_table tr td:first-child label,
  .counterparty_detail_table tr td:first-child .label,
  .counterparty_detail_table tr td:first-child .btn-link,
  .counterparty_detail_table tr td:first-child .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .counterparty_detail_table tr td {
    font-size: calc(14px / 1.2) !important;
    padding: 4px;
  }
  .counterparty_detail_table tr td label,
  .counterparty_detail_table tr td .label,
  .counterparty_detail_table tr td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .counterparty_detail_table .inside-table-ul li span {
    font-size: calc(14px / 1.2) !important;
  }
  .contract-details-table td:first-child {
    font-size: calc(14px / 1.2) !important;
  }
  .contract-details-table tr td .btn-link {
    font-size: calc(14px / 1.2) !important;
  }
  .request-logistic-table tr td:first-child {
    font-size: calc(14px / 1.2) !important;
  }
  .payment-partial-table tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .indicators-table tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .indicators-table tr td label,
  .indicators-table tr td .label,
  .indicators-table tr td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .indicators-table .inside-table-ul li span {
    font-size: calc(14px / 1.2) !important;
  }
  .contract-details-container-cut .progress .progress-bar {
    height: auto !important;
  }
  .contract-details-container-cut h2 {
    font-size: calc(14px / 1.2) !important;
  }
  .passports-table {
    overflow-x: auto;
  }
  .passports-table tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .passports-table tr td label,
  .passports-table tr td .label,
  .passports-table tr td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .passports-table .inside-table-ul li span {
    font-size: calc(14px / 1.2) !important;
  }
  .logistics-table tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .logistics-table tr td label,
  .logistics-table tr td .label,
  .logistics-table tr td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .table-with-total .total-row td {
    font-size: calc(14px / 1.2) !important;
  }
  .table-with-total .total-row td label,
  .table-with-total .total-row td .label,
  .table-with-total .total-row td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .progress .progress-bar {
    font-size: calc(14px / 1.2) !important;
  }
  .dashboard-table tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .dashboard-table tr td label,
  .dashboard-table tr td .label,
  .dashboard-table tr td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .planning-table tr th {
    font-size: calc(14px / 1.2) !important;
  }
  .planning-table tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .planning-table tr td label,
  .planning-table tr td .label,
  .planning-table tr td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  .planning-table tr td .form-control {
    font-size: calc(14px / 1.2) !important;
    width: 70px !important;
  }
  .daterangepicker td,
  .daterangepicker th {
    width: 50px;
    height: 50px;
    font-size: calc(14px / 1.2) !important;
  }
  .datetimepicker {
    width: auto !important;
  }
  .datetimepicker tr th {
    font-size: calc(14px / 1.2) !important;
  }
  .datetimepicker tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .dashboard-start-container .ui-grid-cell-contents,
  .dashboard-start-container .ui-grid-cell .tooltip,
  .dashboard-start-container .ui-grid-cell,
  .dashboard-start-container .ui-grid-cell .label {
    font-size: calc(14px / 1.2) !important;
  }
  .dashboard-start-container .ui-grid-cell,
  .dashboard-start-container .ui-grid-row {
    height: 40px !important;
  }
  .navbar-user .dropdown-menu li,
  .navbar-user .dropdown-menu li > a {
    height: 40px !important;
  }
  .navbar-user .dropdown-menu li > a {
    padding: 10px !important;
  }
  .navbar-user .dropdown-menu .pagination li {
    display: inline !important;
  }
  .navbar-user .dropdown-menu .pagination li > a {
    width: auto !important;
  }
  .notify-dropdown-menu {
    max-height: 1000px;
  }
  .notify-dropdown-menu table tr td {
    padding: 6px;
    font-size: calc(14px / 1.2) !important;
  }
  .pagination-select {
    display: none;
  }
  .bank-accounts-container .request-list {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  .list-filters .btn .label_start {
    display: none;
  }
  .item-settings-dropdown a:nth-of-type(1) .fa {
    font-size: calc(14px / 1.2) !important;
  }
  .item-settings-dropdown .fa-sort-desc {
    display: none;
  }
  .item-settings-dropdown .dropdown-menu {
    min-width: 250px;
  }
  .all-filters-block {
    margin-top: 250px;
  }
  .all-filters-block .dropdown-menu {
    position: relative;
  }
  .all-filters-block .fa-times-circle {
    font-size: calc(14px / 1.2) !important;
  }
  .add-indicator-start-btn {
    margin-right: 205px;
  }
  .add-ticket-start-btn {
    margin-right: 105px;
  }
  .add-indicator-calculator-start-btn {
    margin-right: 305px;
  }
  .filter-template .ui-select-match .form-control,
  .filter-template .ui-select-container .form-control {
    font-size: calc(14px / 1.2) !important;
  }
  .ui-notification h3 {
    padding: 12px;
    font-family: 'SourceSansProBold' !important;
  }
  .ui-notification .message {
    font-size: calc(14px / 1.2);
    padding: 12px;
    font-family: 'SourceSansProBold' !important;
  }
  .ui-grid-cell .tooltip {
    font-size: calc(14px / 1.2) !important;
    line-height: 1;
  }
  .ui-grid-header-cell-label {
    font-size: calc(14px / 1.2) !important;
  }
  .ui-grid-cell-contents {
    font-size: calc(14px / 1.2) !important;
    line-height: 1;
  }
  .ui-grid-cell .label {
    font-size: calc(14px / 1.2) !important;
  }
  .btn-link.start-btn-link {
    font-size: calc(14px / 1.2) !important;
  }
  .navbar-default .small-screen-show .user-avatar,
  .navbar-default .small-screen-show .no-user-avatar {
    font-size: calc(14px / 1.2) !important;
    margin-top: 15px;
  }
  .dashboard-start-container .pagination li a {
    padding: 14px !important;
  }
  .inside-table-btn {
    font-size: calc(14px / 1.2) !important;
  }
  .author-me-checkbox,
  .assigned-to-me-checkbox {
    font-size: calc(14px / 1.2) !important;
  }
  .author-me-checkbox label,
  .assigned-to-me-checkbox label {
    font-size: calc(14px / 1.2) !important;
  }
  .task-block tr td.comments,
  .task-item-container tr td.comments {
    white-space: normal !important;
  }
  .task-modal-form-container .input-group-addon-search {
    display: none !important;
  }
  .task-block tr td,
  .task-item-container tr td {
    font-size: calc(14px / 1.2) !important;
  }
  .task-block tr td .btn,
  .task-item-container tr td .btn {
    font-size: calc(14px / 1.2) !important;
  }
  .fc-event {
    font-size: calc(14px / 1.2) !important;
  }
  #update-properties-table tr td {
    font-size: calc(14px / 1.2) !important;
    white-space: normal !important;
    word-wrap: break-word !important;
  }
  #update-properties-table tr td .fa {
    font-size: calc(14px / 1.2) !important;
  }
  #update-properties-table tr td .fa-pencil-square {
    font-size: calc(14px / 1.2) !important;
  }
  #update-properties-table tr td textarea.form-control {
    font-size: calc(14px / 1.2) !important;
    width: 300px !important;
  }
  #update-properties-table tr td label,
  #update-properties-table tr td .label,
  #update-properties-table tr td .badge {
    font-size: calc(14px / 1.2) !important;
  }
  #update-properties-table .btn-link {
    font-size: calc(14px / 1.2) !important;
  }
  #update-properties-table tr td.message {
    font-size: calc(14px / 1.2) !important;
    width: 70% !important;
  }
  .chart-block-wrapper .form-control {
    height: auto !important;
  }
  .label-date .label.month {
    font-size: calc(14px / 1.2) !important;
  }
  .label-date .day {
    font-size: calc(14px / 1.2) !important;
  }
  .select-date {
    font-size: calc(14px / 1.2);
  }
  .table-responsive {
    border: none !important;
  }
  .start-title {
    font-size: 14px / 1.5 !important;
    padding: 12px;
    border-radius: 6px !important;
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    height: 70px;
    max-height: 70px;
    min-height: 70px;
    overflow: hidden;
  }
  .start-container {
    margin-right: 15px;
  }
  .dashboard-object-container {
    display: block;
    padding: 0 !important;
    margin: 15px;
  }
  .dashboard-object-container .ul-inline-row {
    margin: 0 !important;
    padding: 10px !important;
  }
  .analytics-container,
  .ul-inline-row,
  .table-container-wrapper {
    padding: 15px !important;
    margin: 0 !important;
    margin-bottom: 15px !important;
  }
  .analytics-container .analytics-container,
  .ul-inline-row .analytics-container,
  .table-container-wrapper .analytics-container,
  .analytics-container .ul-inline-row,
  .ul-inline-row .ul-inline-row,
  .table-container-wrapper .ul-inline-row,
  .analytics-container .table-container-wrapper,
  .ul-inline-row .table-container-wrapper,
  .table-container-wrapper .table-container-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
  .dashboard-object-container .dashboard-label {
    line-height: 1;
  }
  .dashboard-start-container-inner {
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .dashboard-start-container-inner .col-sm-12.col-md-6 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .dashboard-start-container-inner br {
    display: none;
  }
  .position-page-container .col-md-6.col-sm-12,
  .position-page-container .col-md-4.col-sm-12,
  .position-page-container .col-md-3.col-sm-12,
  .position-page-container .col-md-6.col-xs-12,
  .position-page-container .col-md-4.col-xs-12,
  .position-page-container .col-md-3.col-xs-12,
  .position-page-container .col-xs-12.col-md-9,
  .position-page-container .col-xs-12.col-sm-4,
  .position-page-container .col-xs-12.col-sm-6,
  .position-page-container .col-xs-12.col-sm-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .analytics-page .col-md-6.col-sm-12,
  .analytics-page .col-md-4.col-sm-12,
  .analytics-page .col-md-3.col-sm-12,
  .analytics-page .col-md-6.col-xs-12,
  .analytics-page .col-md-4.col-xs-12,
  .analytics-page .col-md-3.col-xs-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .start-page-header .col-xs-3,
  .start-page-header .col-xs-2,
  .start-page-header .col-sm-3,
  .start-page-header .col-sm-2 {
    width: 100% !important;
  }
  .gt-table__hovering {
    overflow-x: auto !important;
  }
  .analytics-container {
    margin-bottom: 10px;
  }
  .start-img {
    padding-top: 10px;
    padding-bottom: 40px;
  }
  .start-img .fa {
    font-size: 14px !important;
    text-align: right;
  }
  .item-details-block-transparent .table-no-borders tr td {
    font-size: 14px !important;
  }
  .contracts-wrapper-light-mode .request-list,
  .contracts-wrapper-light-mode .contract-details-container-cut {
    border-bottom: 1px solid #d2cece !important;
    border-radius: 8px !important;
    padding: 16px !important;
    margin-bottom: 15px !important;
  }
  .contracts-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
  .contracts-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
    font-size: 15.4px !important;
  }
  .contracts-wrapper-light-mode .request-list .invoice-inside-table tr td,
  .contracts-wrapper-light-mode .contract-details-container-cut .invoice-inside-table tr td {
    white-space: normal !important;
  }
  .contracts-wrapper-light-mode .request-list .item-settings-dropdown,
  .contracts-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown {
    width: auto !important;
    display: inline-flex;
  }
  .persons-wrapper-light-mode .request-list,
  .persons-wrapper-light-mode .contract-details-container-cut {
    border-bottom: 1px solid #d2cece !important;
    border-radius: 0 !important;
  }
  .persons-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
  .persons-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
    font-size: 15.4px !important;
  }
  .clients-wrapper-light-mode .request-list,
  .clients-wrapper-light-mode .contract-details-container-cut {
    border-bottom: 1px solid #d2cece !important;
    border-radius: 0 !important;
  }
  .clients-wrapper-light-mode .request-list .item-settings-dropdown a:nth-of-type(1),
  .clients-wrapper-light-mode .contract-details-container-cut .item-settings-dropdown a:nth-of-type(1) {
    font-size: 15.4px !important;
  }
  .table-action-buttons .item-settings-dropdown .dropdown-menu {
    left: 0 !important;
  }
  .container.start .start-block {
    width: 100% !important;
    float: none;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px;
  }
  .start-description {
    font-size: calc(14px / 1.2) !important;
  }
  .start-logo-login {
    height: 150px;
    background-size: 100% !important;
    margin-right: auto !important;
    margin-left: auto !important;
    float: none;
    display: block;
  }
  .form-login {
    width: 100%;
  }
  .form-login .input-group {
    height: 130px;
  }
  .form-login .form-control {
    height: 130px !important;
    font-size: 49px !important;
    line-height: 1 !important;
  }
  .form-login .form-control::-webkit-input-placeholder {
    font-size: 49px !important;
    text-overflow: ellipsis;
  }
  .form-login .form-control::-moz-placeholder {
    font-size: 49px !important;
    text-overflow: ellipsis;
  }
  .form-login .form-control:-moz-placeholder {
    font-size: 49px !important;
    text-overflow: ellipsis;
  }
  .form-login .form-control:-ms-input-placeholder {
    font-size: 49px !important;
    text-overflow: ellipsis;
  }
  .form-login .input-group-addon {
    font-size: 49px !important;
  }
  .form-login .checkbox-inline.no_indent {
    font-size: 35px !important;
  }
  .start-contacts p {
    font-size: 35px !important;
  }
  .form-login input {
    font-size: 49px !important;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .btn-login {
    font-size: 42px !important;
    height: 130px;
  }
  .btn-fixed {
    right: 1%;
    bottom: 1% !important;
    height: 80px;
    width: 80px;
    line-height: 2.5;
  }
  body {
    font-size: 16.8px;
  }
  form[name='SaleContractForm'],
  form[name='PurchaseContractForm'] {
    -webkit-column-count: 1;
    -webkit-column-gap: 10px;
    -webkit-column-fill: auto;
    -moz-column-count: 0;
    -moz-column-gap: 10px;
    column-count: 1;
    column-gap: 15px;
    column-fill: auto;
  }
  form[name='SaleContractForm'] .ul-inline-row:nth-of-type(2),
  form[name='PurchaseContractForm'] .ul-inline-row:nth-of-type(2) {
    margin-bottom: 0;
  }
  .item-settings-dropdown {
    margin: 0 !important;
  }
  .counterparties-dropdown .btn {
    padding: 2px !important;
    margin: 0 !important;
  }
  .table-responsive {
    border: none !important;
    overflow: auto;
  }
  .cut-table {
    overflow-x: auto;
  }
  .cut-table td {
    white-space: normal !important;
    font-size: 14px !important;
  }
  .all-filters-block {
    width: 100%;
  }
  .left-sidebar {
    height: auto !important;
  }
  .left-sidebar table td {
    font-size: 14px !important;
  }
  .search {
    width: 100% !important;
    border-radius: 0 !important;
  }
  .print-item {
    display: table-cell;
  }
  /*   .chart-template {width:350px!important;height:250px!important;}*/
  .header-margin-top {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .header-margin-top small {
    display: block;
    margin-top: 5px !important;
    margin-bottom: 15px !important;
    line-height: 2;
  }
  .header-margin-top span {
    margin-right: 2px;
  }
  .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    height: 2.5em !important;
    font-size: 14px !important;
  }
}
/* max480*/
@media (max-width: 480px) {
  body {
    font-size: 39.2px;
  }
}
/* print*/
@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  input[type='date'] {
    min-height: 20px !important;
  }
  img {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }
  a[href]:after {
    content: none !important;
  }
  body {
    font-size: 14px !important;
    background-image: none;
    background: transparent !important;
    color: #000 !important;
    font-family: 'Regular', sans-serif, sans-serif !important;
    overflow: visible !important;
  }
  .overflow-x {
    overflow-x: visible !important;
  }
  .sticky-table-first-td,
  table.sticky-table-first-td {
    margin-left: 0 !important;
  }
  .sticky-table-first-td tr > td,
  table.sticky-table-first-td tr > td {
    height: auto;
    min-height: auto;
  }
  .sticky-table-first-td thead > tr > th div,
  table.sticky-table-first-td thead > tr > th div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .sticky-table-first-td tfoot > tr > td:first-child,
  table.sticky-table-first-td tfoot > tr > td:first-child,
  .sticky-table-first-td tbody > tr > td:first-child,
  table.sticky-table-first-td tbody > tr > td:first-child,
  .sticky-table-first-td thead > tr > th:first-child,
  table.sticky-table-first-td thead > tr > th:first-child {
    position: relative;
    left: 0;
    width: auto !important;
  }
  .sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown,
  table.sticky-table-first-td tfoot > tr > td:first-child .item-settings-dropdown,
  .sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown,
  table.sticky-table-first-td tbody > tr > td:first-child .item-settings-dropdown,
  .sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown,
  table.sticky-table-first-td thead > tr > th:first-child .item-settings-dropdown {
    padding-top: 0 !important;
  }
  .sticky-table-first-td tfoot > tr > td:first-child .label,
  table.sticky-table-first-td tfoot > tr > td:first-child .label,
  .sticky-table-first-td tbody > tr > td:first-child .label,
  table.sticky-table-first-td tbody > tr > td:first-child .label,
  .sticky-table-first-td thead > tr > th:first-child .label,
  table.sticky-table-first-td thead > tr > th:first-child .label,
  .sticky-table-first-td tfoot > tr > td:first-child span,
  table.sticky-table-first-td tfoot > tr > td:first-child span,
  .sticky-table-first-td tbody > tr > td:first-child span,
  table.sticky-table-first-td tbody > tr > td:first-child span,
  .sticky-table-first-td thead > tr > th:first-child span,
  table.sticky-table-first-td thead > tr > th:first-child span {
    margin-top: 0 !important;
  }
  .sticky-table-first-second-third-td {
    margin-left: 0 !important;
  }
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(1),
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(1),
  .sticky-table-first-second-third-td thead > tr > th:nth-child(1),
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(2),
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(2),
  .sticky-table-first-second-third-td thead > tr > th:nth-child(2),
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(3),
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(3),
  .sticky-table-first-second-third-td thead > tr > th:nth-child(3) {
    position: relative;
    left: 0;
    width: auto !important;
  }
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(1) .item-settings-dropdown,
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(1) .item-settings-dropdown,
  .sticky-table-first-second-third-td thead > tr > th:nth-child(1) .item-settings-dropdown,
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(2) .item-settings-dropdown,
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(2) .item-settings-dropdown,
  .sticky-table-first-second-third-td thead > tr > th:nth-child(2) .item-settings-dropdown,
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(3) .item-settings-dropdown,
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(3) .item-settings-dropdown,
  .sticky-table-first-second-third-td thead > tr > th:nth-child(3) .item-settings-dropdown {
    padding-top: 0 !important;
  }
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(1) .label,
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(1) .label,
  .sticky-table-first-second-third-td thead > tr > th:nth-child(1) .label,
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(2) .label,
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(2) .label,
  .sticky-table-first-second-third-td thead > tr > th:nth-child(2) .label,
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(3) .label,
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(3) .label,
  .sticky-table-first-second-third-td thead > tr > th:nth-child(3) .label,
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(1) span,
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(1) span,
  .sticky-table-first-second-third-td thead > tr > th:nth-child(1) span,
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(2) span,
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(2) span,
  .sticky-table-first-second-third-td thead > tr > th:nth-child(2) span,
  .sticky-table-first-second-third-td tfoot > tr > td:nth-child(3) span,
  .sticky-table-first-second-third-td tbody > tr > td:nth-child(3) span,
  .sticky-table-first-second-third-td thead > tr > th:nth-child(3) span {
    margin-top: 0 !important;
  }
  .main-table tr td,
  .table-template tr td,
  table tr td {
    color: #000 !important;
    padding: 2px !important;
    white-space: normal !important;
    word-break: break-word !important;
  }
  .main-table tr td label,
  .table-template tr td label,
  table tr td label,
  .main-table tr td .label,
  .table-template tr td .label,
  table tr td .label {
    border: 0 !important;
    color: #000 !important;
  }
  .main-table tr td .negative-number,
  .table-template tr td .negative-number,
  table tr td .negative-number,
  .main-table tr td .positive-number,
  .table-template tr td .positive-number,
  table tr td .positive-number {
    color: #000 !important;
  }
  .main-table tr td .label_success,
  .table-template tr td .label_success,
  table tr td .label_success,
  .main-table tr td .label_danger,
  .table-template tr td .label_danger,
  table tr td .label_danger {
    color: #000 !important;
    background: transparent !important;
  }
  .main-table tr td .label-success,
  .table-template tr td .label-success,
  table tr td .label-success {
    border: 1px solid #07422d !important;
  }
  .main-table tr td .label-danger,
  .table-template tr td .label-danger,
  table tr td .label-danger {
    border: 1px solid #cf6163 !important;
  }
  .main-table tr td .label_default,
  .table-template tr td .label_default,
  table tr td .label_default,
  .main-table tr td .label-default,
  .table-template tr td .label-default,
  table tr td .label-default {
    background: transparent !important;
  }
  .position-passport-table tr td .label-opacity {
    font-size: 14px / 1.1 !important;
    color: #000 !important;
  }
  .position-passport-table tr td:nth-last-child(1),
  .position-passport-table tr td:nth-last-child(2),
  .position-passport-table tr td:nth-last-child(3),
  .position-passport-table tr td:nth-last-child(5),
  .position-passport-table tr td:nth-last-child(6),
  .position-passport-table tr td:nth-last-child(7),
  .position-passport-table tr td:nth-last-child(8) {
    white-space: nowrap !important;
    min-width: 80px !important;
  }
  .header-margin-top,
  .page-header {
    font-size: 14px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .search {
    font-size: 14px !important;
    border-bottom: 1px solid #000 !important;
  }
  .select-date {
    font-size: 14px !important;
  }
  h1 {
    font-size: 26px !important;
  }
  .print-item {
    display: table-cell;
  }
  .print-hide {
    display: none;
  }
  a.btn.btn-xs {
    display: none;
  }
  .fa.fa-pencil-square {
    display: none;
  }
  .btn.btn-fixed {
    display: none;
  }
  .help-btn-group {
    margin: 20px;
    margin-bottom: 20px !important;
    margin-top: 20px;
    display: inline-block;
    position: relative;
  }
  .help-btn-group a:last-child {
    margin-right: 0 !important;
  }
  .page-header-main small {
    display: none;
  }
  .input-group.search-container {
    display: none;
  }
  .filter-template {
    display: none;
  }
  tr th .fa.fa-sort {
    display: none;
  }
  .nav.nav-tabs.main-nav-tabs,
  .nav.nav-tabs.table-nav-tabs {
    display: none;
  }
  .page-header-main h1 .label_start {
    display: none;
  }
}
